import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { scheduling } from "/apollo/client/local";
import {
  ContinueDecisionSupport_continueDecisionSupport_flowResponse,
  ContinueDecisionSupport_continueDecisionSupport_flowResponse_elementSet_elements,
  PreviousDecisionSupport_previousDecisionSupport_flowResponse,
  PreviousDecisionSupport_previousDecisionSupport_flowResponse_elementSet_elements,
  QuestionResponseInput,
  StartDecisionSupport_startDecisionSupport_flowResponse,
  StartDecisionSupport_startDecisionSupport_flowResponse_elementSet_elements,
} from "/apollo/schema/types";
import { useToastContext } from "/component/provider/ToastProvider";
import routes from "/constant/url.constant";
import { useTranslation } from "/hook";

import {
  getStartingFields,
  useAbandonOnRouteChange,
  useContinueDecisionSupport,
  usePreviousDecisionSupport,
  useStartDecisionSupport,
} from "./DecisionSupport.util";

type Elements =
  | ContinueDecisionSupport_continueDecisionSupport_flowResponse_elementSet_elements
  | PreviousDecisionSupport_previousDecisionSupport_flowResponse_elementSet_elements
  | StartDecisionSupport_startDecisionSupport_flowResponse_elementSet_elements;
type FlowResponse =
  | ContinueDecisionSupport_continueDecisionSupport_flowResponse
  | PreviousDecisionSupport_previousDecisionSupport_flowResponse
  | StartDecisionSupport_startDecisionSupport_flowResponse;

const useDecisionSupport = (flowId: string, athenaId?: string | null, fromSearch = false) => {
  const history = useHistory();
  const { t } = useTranslation("scheduling");
  const { showToast } = useToastContext();
  const [flowStep, setFlowStep] = useState(0);

  // Setup local state
  const [flowResponse, setFlowResponse] = useState<{
    elements: Elements[];
    kickOutMessage?: string | null;
    validationMessages: string[];
    elementSetRef?: string | null;
    flowSessionId?: string | null;
    title?: string | null;
    isFlowFinished?: boolean | null;
  }>({
    elements: [],
    kickOutMessage: null,
    validationMessages: [],
    elementSetRef: null,
    flowSessionId: null,
    title: null,
    isFlowFinished: null,
  });

  // When descision support is started, we make the call to start DS.
  // This should only happen once
  useEffect(() => {
    // Ensure these are cleared upon starting DS
    scheduling.reset();
    startDecisionSupportFlow({
      variables: { input: { fields: getStartingFields(athenaId, fromSearch), flowId } },
    });
  }, []);

  // Handles the completion of a start or continue decision support request.
  // The response dictates the state of the decision support process, which usually
  // informs what should be rendered on the decision support page (e.g. the next
  // set of questions, a kickout message, errors, etc). In the case of finishing
  // the flow, the user will be redirected to the availabilities page.
  const handleComplete = (resFlowResponse: FlowResponse | null) => {
    setFlowResponse({
      elements: resFlowResponse?.elementSet?.elements ?? ([] as Elements[]),
      kickOutMessage: resFlowResponse?.kickoutMessage,
      validationMessages: resFlowResponse?.validationMessages ?? ([] as string[]),
      elementSetRef: resFlowResponse?.elementSet?.elementSetRef,
      flowSessionId: resFlowResponse?.flowSessionId,
      title: resFlowResponse?.elementSet?.title,
      isFlowFinished: resFlowResponse?.isFlowFinished,
    });

    if (resFlowResponse?.isFlowFinished && !resFlowResponse?.finishedDueToKickout) {
      const calendarIds = resFlowResponse.calendarIds;

      // Setup state that will be used in availabilities and booking
      scheduling.update({
        calendarIds: calendarIds?.recommended || [],
        enableUrgentCare: resFlowResponse?.enableUrgentCare,
        flowSessionId: resFlowResponse?.flowSessionId,
        isEstablishedPatient: resFlowResponse?.isEstablishedPatient,
        querySelectedCalendarIds: !!calendarIds?.selected?.length,
        reasonForVisit: resFlowResponse?.reasonForVisit ?? undefined,
        selectedCalendarIds: calendarIds?.selected || [],
      });

      if (calendarIds?.selected?.length && !calendarIds?.recommended?.length) {
        history.push(routes.scheduling + routes.availabilitiesSelected);
      } else {
        history.push(routes.scheduling + routes.availabilities);
      }
    }
  };

  const [startDecisionSupportFlow, { error: startDSError }] = useStartDecisionSupport({
    onCompleted: ({ startDecisionSupport: { flowResponse } }) => {
      handleComplete(flowResponse);
      setFlowStep(flowStep + 1);
    },
    onError: () => {
      showToast({
        message: t("decisionSupport.error.unableToStart"),
        type: "error",
      });
      history.replace(routes.scheduling);
    },
  });

  const [continueDecisionSupport] = useContinueDecisionSupport({
    onCompleted: ({ continueDecisionSupport: { flowResponse } }) => {
      handleComplete(flowResponse);
      setFlowStep(flowStep + 1);
    },
    onError: () => {
      showToast({
        message: t("decisionSupport.error.unableToContinue"),
        type: "error",
      });
    },
  });

  const handleSubmit = async (
    flowSessionId: string,
    responses: QuestionResponseInput[],
    elementSetRef: string,
  ) => {
    await continueDecisionSupport({
      variables: { input: { flowSessionId, responses, elementSetRef } },
    });
  };

  const [previousDecisionSupport, { loading: previousLoading }] = usePreviousDecisionSupport({
    onCompleted: ({ previousDecisionSupport: { flowResponse } }) => {
      handleComplete(flowResponse);
      setFlowStep(flowStep - 1);
    },
    onError: () => undefined,
  });

  useAbandonOnRouteChange(
    flowResponse.flowSessionId,
    !!flowResponse.kickOutMessage,
    !!flowResponse.isFlowFinished,
  );

  const handleClose = async () => {
    history.replace(routes.root);
  };

  const handlePrevious = async () => {
    if (flowStep > 1 && flowResponse.flowSessionId) {
      previousDecisionSupport({ variables: { flowSessionId: flowResponse.flowSessionId } });
    } else {
      history.goBack();
    }
  };

  return {
    // ContinueDS loading state is handled in the form button, so we are only
    // conerned with the StartDS loading state here
    isLoading: (!startDSError && !flowResponse.flowSessionId) || previousLoading,
    // I think it's correct for the user to still be promptd to exit DS if they get an error
    // during the continueDecisionSupport mutation. But if they get an error when trying to
    // start decision support we will use this `hasError` to ignore the <Prompt>
    hasError: !!startDSError,
    handleSubmit,
    handleClose,
    handlePrevious,
    ...flowResponse,
  };
};

export default useDecisionSupport;
