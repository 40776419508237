import React from "react";

import { Link, Text } from "/component/base";
import { layout } from "/styles";

import { Base, Button } from "./InlineEmptyState.styles";
import { Props } from "./InlineEmptyState.types";

/**
 * Render an empty state that is meant to be used inline in content. If you are looking
 * for a fullscreen empty state you probably want to use EmptyStateCta.
 */
const InlineEmptyState = ({
  width = 360,
  className,
  title,
  subtitle,
  ctaText,
  ctaOnClick,
  ctaTo,
}: Props) => {
  let cta;
  if (ctaOnClick) {
    cta = (
      <Button variant="tertiary" onClick={ctaOnClick}>
        {ctaText}
      </Button>
    );
  } else if (ctaTo) {
    cta = (
      <Button variant="tertiary" to={ctaTo} as={Link.Button}>
        {ctaText}
      </Button>
    );
  }

  return (
    <Base className={className} $width={width}>
      <Text variant="title2" css={layout.margin("skip", "skip", "standard")} element="div">
        {title}
      </Text>
      <Text variant="body2" color="textSecondary">
        {subtitle}
      </Text>

      {cta}
    </Base>
  );
};

export default InlineEmptyState;
