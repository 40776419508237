import { rem } from "polished";

import { TextVariants } from "./default.types";

export const fontSizes: { [Key in TextVariants]: string } = {
  body1: rem(16),
  body1Medium: rem(16),
  body1Bold: rem(16),
  body1Italic: rem(16),
  body1BoldItalic: rem(16),
  body2: rem(14),
  body2Medium: rem(14),
  body2Bold: rem(14),
  body2Italic: rem(14),
  body2BoldItalic: rem(14),
  body3: rem(12),
  body3Bold: rem(12),
  body3Italic: rem(12),
  body3BoldItalic: rem(12),
  headline: rem(40),
  navigation: rem(12),
  navigationBold: rem(12),
  title1: rem(28),
  title2: rem(24),
  title3: rem(19),
  buttonTitle: rem(19),
};
