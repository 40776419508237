import styled from "styled-components";

import Nav from "/component/layout/Nav";
import { layout } from "/styles";

export const Base = styled.div(layout.absoluteFill, ({ theme }) => ({
  overflowY: "auto",
  backgroundColor: "#f7f9ff",
  [theme.media.tabletPortrait]: {
    display: "flex",
    alignItems: "stretch",
  },
}));

export const DesktopNavMenu = styled(Nav)(({ theme }) => ({
  display: "none",

  [theme.media.tabletPortrait]: {
    display: "flex",
  },
}));

export const MobileNavHeader = styled.nav(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.colors.backgroundPrimary,
  boxShadow: theme.shadows.small,
  display: "flex",
  height: theme.sizes.mobileHeader,
  justifyContent: "space-between",
  padding: `${theme.spacing.mobileNavTop}px ${theme.spacing.gutter}px`,
  position: "fixed",
  width: "100%",
  zIndex: theme.zIndex.mobileNav,

  [theme.media.tabletPortrait]: {
    display: "none",
  },
}));
