import { gql, useQuery } from "@apollo/client";

import { GetSelectedCalendars, GetSelectedCalendarsVariables } from "/apollo/schema/types";

import { ProviderAvailabilitiesListFragment } from "../component/ProviderAvailabilitiesList";

const GET_AVAILABILITIES = gql`
  query GetSelectedCalendars(
    $after: String
    $daysOfWeek: [DayOfWeek!]
    $flowSessionId: ID
    $gender: ProviderGender
    $language: Language
    $maxStartTime: DateTime
    $minStartDate: DateTime
    $minStartTime: DateTime
    $geo: GeoCircleInput
    $calendarIds: [ID!]
  ) {
    ...ProviderAvailabilitiesListFragment
  }
  ${ProviderAvailabilitiesListFragment}
`;

export default (variables: GetSelectedCalendarsVariables) => {
  return useQuery<GetSelectedCalendars, GetSelectedCalendarsVariables>(GET_AVAILABILITIES, {
    variables,
    fetchPolicy: "no-cache",
  });
};
