import { useReactiveVar } from "@apollo/client";
import React from "react";
import { useHistory } from "react-router";

import { scheduling } from "/apollo/client/local";
import MainContent from "/component/layout/MainContent";
import routes from "/constant/url.constant";
import { AnalyticsEvent, ButtonClickParams, logEvent } from "/util/firebase.util";

import { Props } from "./AvailabilitiesBackButton.types";

/**
 * This special back button will attempt to go back when `canGoBack` is true, and there is a route
 * to go back to, otherwise it will redirect to `scheduling`.
 *
 * You cannot go back to Decision Support from the Availabiltiies screens.
 */
const AvailabilitiesBackButton = ({ canGoBack, analyticsSource }: Props) => {
  const history = useHistory();
  const { analyticsFlow } = useReactiveVar(scheduling.var);

  const logButtonClickEvent = () => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: analyticsFlow,
      source: analyticsSource,
      button_name: "Close (icon)",
    } as ButtonClickParams);
  };

  const goBack = history.action === "PUSH" && canGoBack;
  const handleBack = () => {
    logButtonClickEvent();
    if (goBack) {
      history.goBack();
    } else {
      history.push(routes.scheduling);
    }
  };

  return <MainContent.BackButton onClick={handleBack} icon={goBack ? "back" : "close"} />;
};

export default AvailabilitiesBackButton;
