import { useMutation } from "@apollo/client";
import awsdk from "awsdk";
import { isEqual } from "date-fns";
import { toLower } from "lodash";
import React, { createContext, useEffect, useState } from "react";

import CREATE_AMWELL_AUTH_TOKEN from "/apollo/mutation/createAmWellAuthToken";
import {
  AdministrativeGender,
  createAmWellAuthToken,
  createAmWellAuthTokenVariables,
} from "/apollo/schema";
import { useAuth } from "/hook";

import { AddDependencyParams, Context, Props } from "./AmwellProvider.types";

const AUTH_RETRY_LIMIT = 7;
const AUTH_RETRY_DELAY = 2500;

export const AmwellProviderContext = createContext<Context>({
  enrollDependent: async () => null,
  activeVisit: undefined,
  isReady: false,
  login: async () => undefined,
  logout: () => {
    return;
  },
  renewAuth: async () => undefined,
  setActiveVisit: () => undefined,
  toggleDependent: () => undefined,
  updateConsumer: () => undefined,
});

/**
 * Amwell provider adds React context values for Amwell's web SDK. Given an
 * instance of the web SDK, it will attempt initialization and authentication
 * with Amwell, and will add the SDK library itself, as well as values for
 * checking if the SDK is ready to be interacted with, and the current Amwell
 * consumer. Finally, it includes a convenience method for logging out of
 * Amwell.
 *
 * The SDK itself provides many features for interacting with various Amwell
 * features. If necessary, it may be useful to add additional, feature-specific
 * hooks that retreive the appropriate methods from the SDK context, and provide
 * appropriate wrappers for them separately.
 */
const AmwellProvider = ({ children, sdk }: Props): JSX.Element => {
  const { isAuthenticated, isReady: authReady } = useAuth();
  const [consumer, setConsumer] = useState<awsdk.AWSDKConsumer>();
  const [activeVisit, setActiveVisit] = useState<awsdk.AWSDKVisit | undefined>(undefined);
  const [isInitialized, setIsInitialized] = useState(sdk?.initialized || false);
  const [isReady, setIsReady] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const [createAmwellAuthToken, { data, loading }] = useMutation<
    createAmWellAuthToken,
    createAmWellAuthTokenVariables
  >(CREATE_AMWELL_AUTH_TOKEN, {
    fetchPolicy: "no-cache",
    variables: {
      useMyAccount: true,
    },
  });

  const token = data?.createAmWellAuthToken.token;
  const shouldAttemptAmwellAuth =
    !loading && !!token && !consumer && isAuthenticated && isInitialized;

  const initialize = async () => {
    if (sdk && !sdk.initialized) {
      try {
        await sdk.initialize({
          sdkApiKey: `${process.env.AMWELL_WEB_SDK_API_KEY}`,
          baseUrl: `${process.env.AMWELL_WEB_SDK_BASE_URL}`,
          locale: "en_US",
        });
      } catch (e: any) {
        console.error("Amwell SDK Initialization failed.", e);
      }
    }

    setIsInitialized(!!sdk?.initialized);
  };

  const getDependent = async (
    sdk: awsdk.AWSDK,
    checkConsumer: awsdk.AWSDKConsumer,
    registrationRequest: AddDependencyParams,
  ) => {
    const dependents = await sdk.consumerService.getDependents(checkConsumer);

    const existingDependent = dependents.find(
      (dependentConsumer) =>
        toLower(dependentConsumer.gender) === toLower(registrationRequest.gender) &&
        toLower(dependentConsumer.firstName) === toLower(registrationRequest.firstName) &&
        toLower(dependentConsumer.lastName) === toLower(registrationRequest.lastName) &&
        isEqual(
          new Date(dependentConsumer.dob).getUTCDate(),
          new Date(registrationRequest.dob).getUTCDate(),
        ),
    );

    if (existingDependent) return existingDependent;
    return null;
  };

  const enrollDependent = async (
    registrationRequest: awsdk.AWSDKDependentRegistration,
    parentConsumer?: awsdk.AWSDKConsumer,
  ) => {
    try {
      const currentConsumer = parentConsumer || consumer;
      if (!sdk || !currentConsumer) throw new Error("Sdk and consumer not defined");

      const existingDependent = await getDependent(sdk, currentConsumer, registrationRequest);

      if (existingDependent) return existingDependent;
      else {
        let dependent = await sdk.consumerService.registerDependent(
          currentConsumer,
          registrationRequest,
        );
        dependent = await sdk.consumerService.getUpdatedConsumer(dependent);
        return dependent;
      }
    } catch (e: any) {
      console.log(e);
      return null;
    }
  };

  const toggleDependent = async ({ firstName, lastName, dob, gender }: AddDependencyParams) => {
    const { data } = await createAmwellAuthToken({
      fetchPolicy: "no-cache",
      variables: {
        useMyAccount: true,
      },
    });

    const newToken = data?.createAmWellAuthToken.token;

    if (!newToken) return;

    const loginResults = await login(newToken);
    const parentConsumer = loginResults?.consumer;

    if (!sdk || !parentConsumer) return;

    const genderEnum =
      gender === AdministrativeGender.MALE
        ? "M"
        : gender === AdministrativeGender.FEMALE
        ? "F"
        : "U";

    const dependent = await getDependent(sdk, parentConsumer, {
      firstName,
      lastName,
      dob,
      gender: genderEnum,
    });

    if (!dependent) setConsumer(undefined);
    else setConsumer(dependent);
  };

  const login = async (loginToken?: string | null) => {
    if (loginToken) {
      let result: awsdk.AWSDKAuthentication | undefined;

      try {
        result = await sdk?.authenticationService.authenticateMutualAuthWithToken(loginToken);
      } catch (e: any) {
        console.error("Amwell SDK Authentication failed.", e);
      }

      if (result?.fullyAuthenticated) {
        setConsumer(result?.consumer);
        setIsReady(isInitialized && isAuthenticated && !!result?.consumer);
        setRetryCount(0);
        return result;
      } else {
        setRetryCount(retryCount + 1);
        return result;
      }
    }

    return;
  };

  const logout = () => {
    if (isReady) {
      sdk?.authenticationService.clearAuthentication(consumer as awsdk.AWSDKConsumer);
      setConsumer(undefined);
      setIsReady(false);
      setRetryCount(0);
    }
  };

  const renewAuth = async () => {
    logout();

    await createAmwellAuthToken({
      fetchPolicy: "no-cache",
      variables: {
        useMyAccount: false,
      },
    });
    return await login();
  };

  useEffect(() => {
    if (authReady && isAuthenticated && isInitialized) {
      createAmwellAuthToken({
        fetchPolicy: "no-cache",
      });
    } else {
      setConsumer(undefined);
    }
  }, [authReady, isAuthenticated, isInitialized]);

  // Timeout and retry functionality was ported from the mobile app. After
  // signup, it takes some time for Amwell (or whoever) to create the user, so
  // Amwell authentication may initially fail. Failure to authenticate causes
  // the retry count to increment, allowing us to re-attempt Amwell auth after a
  // delay — until either success or the retry limit is reached.
  useEffect(() => {
    if (retryCount < AUTH_RETRY_LIMIT && shouldAttemptAmwellAuth) {
      setTimeout(() => login(token), retryCount === 0 ? 0 : AUTH_RETRY_DELAY);
    } else if (retryCount >= AUTH_RETRY_DELAY || (!isAuthenticated && authReady)) {
      setIsReady(true);
    }
  }, [retryCount, shouldAttemptAmwellAuth, isAuthenticated, authReady]);

  useEffect(() => {
    initialize();
  }, []);

  return (
    <AmwellProviderContext.Provider
      value={{
        activeVisit,
        consumer,
        enrollDependent,
        isReady,
        login,
        logout,
        toggleDependent,
        renewAuth,
        sdk,
        setActiveVisit: (visit: awsdk.AWSDKVisit | undefined) => setActiveVisit(visit),
        updateConsumer: (consumer: awsdk.AWSDKConsumer) => setConsumer(consumer),
      }}
    >
      {children}
    </AmwellProviderContext.Provider>
  );
};

export default AmwellProvider;
