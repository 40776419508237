import styled from "styled-components";

import logoWordMark from "/asset/image/logo-wordmark-horizontal.svg";
import Text from "/component/base/Text";
import BackButtonBase from "/component/partial/BackButton";
import FormContentBase from "/component/partial/FormContent";
import { layout } from "/styles";

export const Base = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.backgroundPrimary,
  // Full screen on mobile
  [theme.media.max.tabletPortrait]: {
    ...layout.absoluteFill,
    width: "100%",
    padding: theme.spacing.gutter,
  },

  // Will be centred on tablet in a "card"
  [theme.media.tablet]: {
    position: "relative",
    borderRadius: 20,
    padding: theme.spacing[40],
    width: 470,
  },
}));

export const Title = styled(Text)(({ theme }) => ({
  marginTop: theme.spacing.standard,
  [theme.media.tablet]: {
    marginTop: theme.spacing.base * 11,
    textAlign: "center",
  },
}));

export const Description = styled(Text)(({ theme }) => ({
  marginTop: theme.spacing.standard,
  [theme.media.tablet]: {
    marginTop: theme.spacing.base * 11,
  },
}));

export const FormContent = styled(FormContentBase)(({ theme }) => ({
  marginTop: theme.spacing.base * 5,
  [theme.media.tablet]: {
    marginTop: theme.spacing.standard,
  },
}));

export const LogoWordmark = styled.img.attrs(() => ({ src: logoWordMark }))(({ theme }) => ({
  display: "none",
  [theme.media.tablet]: {
    margin: "auto",
    display: "block",
  },
}));

export const Header = styled.header({
  display: "block",
  position: "relative" as const,
});

export const BackButton = styled(BackButtonBase)<{ show?: boolean }>(({ theme, show }) => ({
  display: "block",
  visibility: show ? "visible" : "hidden",
  [theme.media.tablet]: {
    position: "absolute",
    top: 0,
    left: 0,
  },
}));
