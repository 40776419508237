import styled from "styled-components";

import { layout } from "/styles";

export const SelectedItem = styled.div<{ $hasError?: boolean }>(
  layout.padding(12, "standard"),
  layout.spacedChildrenHorizontal("condensed"),
  ({ theme, $hasError }) => ({
    alignItems: "center",
    backgroundColor: $hasError ? theme.colors.brandSecondaryLight : theme.colors.transparent,
    border: `solid 1.5px ${
      $hasError ? theme.colors.brandSecondary : theme.colors.brandActionBlueLight
    }`,
    borderRadius: 30,
    display: "flex",
    minWidth: 200,
  }),
);

export const SelectedItemsContainer = styled.ul(layout.spacedChildrenVertical("condensed"), {
  display: "inline-block",
  maxWidth: "100%",
});
