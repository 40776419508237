import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import noMessages from "/asset/image/secureMessagingNoMessages.png";
import { List, Loading, Spinner, Text, Toggle } from "/component/base";
import {
  getFormattedOptions,
  notificationCategories,
  shouldDisable,
  StoreObject,
} from "/component/page/Account/page/Notifications/Notifications.util";
import EmptyStateCta from "/component/partial/EmptyStateCta";
import { useToastContext } from "/component/provider/ToastProvider";
import Transition from "/component/util/Transition";
import { card, layout, transition } from "/styles";

import {
  useCaregiverNotificationPreference,
  useSetCaregiverNotificationPreference,
} from "./useCaregiverNotification";

const CaregiverNotificationForm = () => {
  const category = notificationCategories.caregivers;
  const { client, data, error, loading } = useCaregiverNotificationPreference({
    fetchPolicy: "network-only",
  });
  const { showToast } = useToastContext();
  const { t } = useTranslation("notifications");
  const [edited, setEdited] = useState<string[]>([]);
  const notificationPreference = data?.viewer?.user.notificationPreference;
  const pendingMutation = useRef(false);

  const categorySettings = {
    appointmentReminders: false,
    patientInformation: false,
    prescriptions: false,
    dealsAndPromotions: false,
    ...notificationPreference?.[category],
  };

  const isInitialLoad = !categorySettings && !error && loading;
  const [setNotificationPreference] = useSetCaregiverNotificationPreference({
    onError: () => {
      showToast({ icon: "alert", message: t("error.updateNotification") });
    },
  });

  const modifyNotificationPreferenceCache = (updatedSettings = {}) => {
    client.cache.modify({
      fields: {
        [category]: (cachedSettings) => {
          return { ...cachedSettings, ...(updatedSettings || {}) };
        },
      },
      id: client.cache.identify(notificationPreference as StoreObject),
    });
  };

  const handleChangeToggle = ({ type, value }: { type: string; value: boolean }) => {
    modifyNotificationPreferenceCache({ [type]: value });
    setEdited([...edited, type]);
  };

  useEffect(() => {
    if (!!edited.length && !pendingMutation.current) {
      const setPreference = async () => {
        pendingMutation.current = true;
        const modifiedSetting = edited[0];
        const input = { caregivers: { [modifiedSetting]: !categorySettings?.[modifiedSetting] } };

        const { data: result } = await setNotificationPreference({ variables: { input } });

        modifyNotificationPreferenceCache(
          result?.updateNotificationPreference.notificationPreference?.[category],
        );

        pendingMutation.current = false;
        setEdited(edited.filter((_, i) => i !== 0));
      };

      setPreference();
    }
  }, [categorySettings, edited]);

  return (
    <>
      {isInitialLoad && <Loading variant="page" />}

      {!isInitialLoad && error && (
        <EmptyStateCta
          imageSource={noMessages}
          subtitle={t("error.subtitle")}
          title={t("error.title")}
        />
      )}

      {!isInitialLoad && !error && categorySettings && (
        <div css={{ position: "relative" }}>
          <List
            as="Divs"
            data={getFormattedOptions({
              ...categorySettings,
              appointmentReminders: false,
              patientInformation: false,
              prescriptions: false,
              dealsAndPromotions: false,
            })}
            hideLastChildBorder
            renderItem={({ label, type, value }) => (
              <label
                css={[
                  layout.flexItemAuto,
                  layout.flexSpaceHorizontal,
                  layout.padding("standard", "skip"),
                ]}
              >
                <Text color={pendingMutation.current ? "textSecondary" : "textPrimary"}>
                  {label}
                </Text>

                <Toggle
                  checked={value}
                  disabled={pendingMutation.current || shouldDisable({ category, type })}
                  onChange={({ target }) => {
                    handleChangeToggle({ type, value: target.checked });
                  }}
                />
              </label>
            )}
            showChevron={false}
          />

          <Transition show={pendingMutation.current}>
            <div css={[layout.absoluteCenter, layout.flexCenter, transition.fadeDown]}>
              <span css={[card.base, layout.padding("standard")]}>
                <Spinner />
              </span>
            </div>
          </Transition>
        </div>
      )}
    </>
  );
};

export default CaregiverNotificationForm;
