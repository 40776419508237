import styled from "styled-components";

import Button from "/component/base/Button";
import Text from "/component/base/Text";
import { layout } from "/styles";

export const AttachmentListContainer = styled.div(layout.margin("gutter", "skip", "gutter"));

export const AttachmentsButtonHorizontalContainer = styled.div(({ theme }) => ({
  ...layout.flexSpaceHorizontal,
  ...layout.spacedChildrenHorizontal()({ theme }),
  [theme.media.max.mobile]: {
    ...layout.flexVertical,
    ...layout.spacedChildrenHorizontal("none")({ theme }),
    "& > *": {
      width: "100%",
      marginTop: theme.spacing.standard,
    },
  },
}));

export const AttachmentsButtonVerticalContainer = styled.div(
  layout.flexVertical,
  layout.spacedChildrenVertical(),
);

export const ButtonContainer = styled.div(
  layout.flexVertical,
  layout.spacedChildrenVertical(),
  layout.margin("expanded", "skip"),
);

export const SkipButton = styled(Button)(
  layout.flexCenterHorizontal,
  layout.margin("expanded", "skip", "skip", "skip"),
  { alignSelf: "center" },
);

export const CheckboxContainer = styled.div(layout.margin(40, "skip", 60));

export const CouponButton = styled(Button)(({ theme }) => ({
  flexGrow: 1,
  marginLeft: theme.spacing[12],
  height: 48,
  maxWidth: 227,
}));

export const CreditCardNoteContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginTop: theme.spacing.standard,
}));

export const EmailContainer = styled.div(layout.margin("condensed", "skip"), ({ theme }) => ({
  "& > *": {
    marginTop: theme.spacing.standard,
  },
}));

export const IdentificationContainer = styled.div(
  layout.padding("skip", "skip", "gutter"),
  layout.spacedChildrenVertical("gutter"),
);

export const MedicationListContainer = styled.div(layout.margin("gutter", "skip", "gutter"), {
  minHeight: 200,
});

export const NameContainer = styled.div(layout.flexLTR, {
  "& > div": {
    flex: 1,
  },
});

export const NoticePrivacyLink = styled.a(({ theme }) => ({
  fontFamily: theme.fontVariants.body1Bold,
  textDecoration: "underline",
  "&:visited": {
    color: "inherit",
  },
}));

export const Container = styled.div(layout.margin("gutter", "none", "none", "none"));

export const PaymentContainer = styled.div(
  layout.flexVertical,
  layout.padding("skip", "skip", 12, "skip"),
  {
    alignItems: "flex-start",
  },
);

export const Title = styled(Text).attrs({
  variant: "title2",
  element: "h2",
  color: "textTitle",
})(layout.margin("skip", "skip", "expanded"));

export const VisitCostCheckboxContainer = styled.div(layout.margin(80, "skip", "skip"));

export const VitalsHeightContainer = styled.ul(layout.flexLTR, layout.spacedChildrenHorizontal(), {
  "& > li": {
    flex: 1,
  },
});
