import styled from "styled-components";

import { Text } from "/component/base";
import AvatarComponent, { styles as AvatarStyles } from "/component/base/Avatar";
import BaseButton from "/component/base/Button";
import { layout } from "/styles";

export const ProviderListItem = styled.li(
  // Applying these margins for when the list of providers wraps on mobile
  layout.margin("skip", "standard", "condensed", "skip"),
  ({ theme }) => ({
    width: theme.sizes.avatar.large,
    display: "inline-block",
    verticalAlign: "top",
    textAlign: "center",

    // Make Image avatars display: block. The "Initials" avatar already have display: flex, which we want to keep
    [AvatarStyles.Image]: {
      display: "block",
    },
  }),
);

export const Avatar = styled(AvatarComponent)(
  layout.margin("skip", "skip", "condensed"),
  ({ theme }) => ({
    boxShadow: theme.shadows.small,
  }),
);

// Clamp the provider name at 2 lines in webkit browsers. It has good support so I'm ok using it and
// worst case on non-webkit browsers users will just see more than 2 lines
export const Name = styled(Text)({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  "-webkit-line-clamp": "2",
  "-webkit-box-orient": "vertical",
});

const activePadding = 6;

export const Button = styled(BaseButton)(({ theme }) => ({
  position: "relative",

  // Make all children position relative to put them in the zIndex stack, and so they appear
  // in front of the `::before` background
  "& > *": {
    position: "relative",
  },
  // This is a subtle background that will appear behind the list item when the user clicks on the item.
  // I am using a pseudo element so that we can position the list items relative to eachother, without
  // needing to worry about putting padding around the items themselves to show the background
  "&::before": {
    content: "''",
    position: "absolute",
    // Give the box this padding, to make it a little bit bigger than the button itself
    padding: activePadding,
    top: -activePadding,
    left: -activePadding,
    width: "100%",
    height: "100%",
    borderRadius: 8,
    backgroundColor: theme.colors.brandPrimaryLight,
    zIndex: 0,
    opacity: 0,
  },
  "&:active::before": {
    opacity: 1,
  },
}));
