import React, { useState } from "react";
import { useTheme } from "styled-components";

import { GetDiagnosticReport_diagnosticReport_observations } from "/apollo/schema";
import { Button, Text } from "/component/base";
import ObservationNotesModal from "/component/modal/ObservationNotesModal";
import ObservationValue from "/component/partial/ObservationValue";
import { useTranslation, useViewportSize } from "/hook";
import { layout } from "/styles";

import { TableBody, TableCell } from "./DiagnosticReportObservationsTable.styles";
import { Props } from "./DiagnosticReportObservationsTable.types";

const DiagnosticReportObservationsTable = ({ observations }: Props) => {
  const { t } = useTranslation("result");
  const { width } = useViewportSize();
  const [selected, setSelected] = useState<GetDiagnosticReport_diagnosticReport_observations>();
  const { screenSizes } = useTheme();
  const isMobile = !!width && width < screenSizes.tabletPortrait;
  const truncationIndex = isMobile ? 20 : 50;

  return (
    <>
      <table css={layout.flexVertical}>
        <thead>
          <tr css={[layout.padding("standard", "skip"), layout.flexSpaceHorizontal]}>
            <th>
              <Text color="textSecondary" variant="body2Bold">
                {t("label.name")}
              </Text>
            </th>

            <th>
              <Text color="textSecondary" variant="body2Bold">
                {t("label.result")}
              </Text>
            </th>
          </tr>
        </thead>

        <TableBody>
          {observations.map((observation) => {
            const truncateName = observation.name && observation.name.length > truncationIndex;

            return (
              <tr
                css={[
                  layout.padding("standard", "skip"),
                  layout.flexSpaceHorizontal,
                  layout.flexStartHorizontal,
                ]}
                key={observation.id}
              >
                <TableCell
                  css={[
                    layout.flexStartHorizontal,
                    { height: 65, justifyContent: "space-between" },
                  ]}
                >
                  <Text css={{ textTransform: "capitalize" }}>
                    {truncateName
                      ? `${observation.name?.substring(0, truncationIndex + 1)}…`
                      : observation.name}
                  </Text>

                  {truncateName && (
                    <Button onClick={() => setSelected(observation)} variant="borderBottom">
                      {t("button.viewReport")}
                    </Button>
                  )}
                </TableCell>

                <TableCell $align="end" css={{ height: 65, justifyContent: "space-between" }}>
                  <ObservationValue
                    observation={observation}
                    onSelect={() => setSelected(observation)}
                    truncate={!truncateName}
                  />
                </TableCell>
              </tr>
            );
          })}
        </TableBody>
      </table>

      <ObservationNotesModal
        close={() => setSelected(undefined)}
        isOpen={!!selected}
        observation={selected}
      />
    </>
  );
};

export default DiagnosticReportObservationsTable;
