import { useMutation } from "@apollo/client";
import React, { useEffect } from "react";

import resendVerificationGql from "/apollo/mutation/sendVerification";
import updateVerificationGql from "/apollo/mutation/updateVerification";
import {
  SendVerification_sendVerification,
  SendVerificationVariables,
  UpdateVerification,
} from "/apollo/schema";
import { Button } from "/component/base";
import { Mail } from "/component/base/Icon";
import { useAccountQuery } from "/component/page/Account/page/Settings/useSettings";
import EmptyStateCta from "/component/partial/EmptyStateCta";
import { useSignUpProvider } from "/component/provider/SignUpProvider";
import { SignUpFormType } from "/constant/signUp.constant";
import { useTranslation } from "/hook";
import { layout } from "/styles";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  logEvent,
} from "/util/firebase.util";

import { FormContentContainer } from "../SignUpForms.styles";
import { SignUpFormProps } from "../SignUpForms.types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EmailVerification = ({ handleSubmit }: SignUpFormProps<any>) => {
  const { t } = useTranslation("form-signUp");

  const [resendVerification] = useMutation<
    SendVerification_sendVerification,
    SendVerificationVariables
  >(resendVerificationGql);
  const [updateVerification] = useMutation<UpdateVerification>(updateVerificationGql);

  const { data } = useAccountQuery();
  const { emailVerificationCode, isEmailVerificationCodeSent, setEmailVerificationCodeSent } =
    useSignUpProvider();

  const { firstName, lastName, email } = data?.viewer?.user.account || {};
  const patientName = `${firstName} ${lastName}`;

  const validateCode = async (code: string) => {
    const response = await updateVerification({
      variables: {
        input: {
          type: "email",
          code,
        },
      },
    });

    if (response.data?.updateVerification?.verification?.status === "approved") {
      handleSubmit(SignUpFormType.VerificationStatus);
    }
  };

  const confirmEmailLater = () => {
    logButtonClickEvent("I'll do it later");
    handleSubmit(SignUpFormType.VerificationStatus);
  };

  const resendEmail = async () => {
    if (email) {
      logButtonClickEvent("Resend email");
      await resendVerification({
        variables: {
          name: patientName,
          type: "email",
          to: email,
        },
      });
    }
  };

  useEffect(() => {
    if (emailVerificationCode) {
      validateCode(emailVerificationCode);
    } else if (patientName && email && !isEmailVerificationCodeSent) {
      resendVerification({
        variables: {
          name: patientName,
          type: "email",
          to: email,
        },
      });
      setEmailVerificationCodeSent(true);
    }
  }, [emailVerificationCode, patientName, email]);

  const logButtonClickEvent = (buttonName: string) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.ONBOARDING_NEW,
      source: AnalyticsSource.ONBOARDING_EMAIL_VERIFICATION,
      button_name: buttonName,
    } as ButtonClickParams);
  };
  return (
    <FormContentContainer>
      <EmptyStateCta
        icon={<Mail color="brandPrimary" size={80} />}
        title={t("emailVerification.title")}
        subtitle={
          <div css={[layout.flexVertical, layout.spacedChildrenVertical()]}>
            <div>{t("emailVerification.subtitle1")}</div>
            <div>{t("emailVerification.subtitle2")}</div>
          </div>
        }
      />

      <div css={[layout.flexVertical, layout.spacedChildrenVertical()]}>
        <Button
          variant="primary"
          onClick={resendEmail}
          title={t("emailVerification.resendEmail")}
        />
        <Button
          variant="secondary"
          title={t("emailVerification.doLater")}
          onClick={confirmEmailLater}
        />
      </div>
    </FormContentContainer>
  );
};

export default EmailVerification;
