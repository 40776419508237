import { gql, useQuery } from "@apollo/client";

import { GetDiagnosticReports, GetDiagnosticReportsVariables } from "/apollo/schema";

export const diagnosticReportFragment = gql`
  fragment diagnosticReportFragment on DiagnosticReport {
    id
    receivedDateTime
    reportTitle
    testSummary
    type
  }
`;

const GET_REPORTS = gql`
  query GetDiagnosticReports(
    $after: String = null
    $categories: [DiagnosticReportCategory!]
    $endDate: DateTime
    $first: Float
    $sort: SortDirection
    $startDate: DateTime
    $lat: Float!
    $lng: Float!
    $radius: Float = 50
  ) {
    address @client {
      lat @export(as: "lat")
      lng @export(as: "lng")
    }

    viewer {
      user {
        patient {
          diagnosticReports(
            after: $after
            categories: $categories
            endDate: $endDate
            first: $first
            sort: $sort
            startDate: $startDate
          ) {
            pageInfo {
              endCursor
              hasNextPage
            }
            edges {
              node {
                ...diagnosticReportFragment
              }
            }
          }
        }
      }
    }

    smgFacilities: facilities(org: SMG, geo: { radius: $radius, lat: $lat, lng: $lng }) {
      id
    }
  }

  ${diagnosticReportFragment}
`;

export const useResults = (variables: Omit<GetDiagnosticReportsVariables, "lat" | "lng">) =>
  useQuery<GetDiagnosticReports>(GET_REPORTS, { variables, fetchPolicy: "cache-and-network" });
