import awsdk from "awsdk";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import EmptyStateImage from "/asset/image/secureMessagingNoMessages.png";
import ListItem from "/component/base/ListItem";
import EmptyStateCta from "/component/partial/EmptyStateCta";
import MessageListItem from "/component/partial/MessageListItem";
import { parsePersistentMessageId } from "/util/awsdk.util";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  logEvent,
} from "/util/firebase.util";

import { EmptyStateContainer, ListContainer, ListSpinner } from "./MessageList.styles";
import { Props } from "./MessageList.types";

const IMAGE_SIZE = 274;

const MessageList = <T extends awsdk.AWSDKInboxMessage | awsdk.AWSDKSentMessage>({
  emptyStateSubtitle,
  emptyStateTitle,
  error,
  errorStateSubtitle,
  errorStateTitle,
  getIconForMessage,
  inbox = true,
  messages,
  onFetchMore,
  total,
}: Props<T>) => {
  const hasMore = !!messages && !!total && messages?.length < total;

  const logButtonClickEvent = (messageId: string) => {
    const buttonName = inbox ? "Message (inbox)" : "Message (sent)";
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.MESSAGES,
      source: AnalyticsSource.MESSAGES,
      button_name: buttonName,
      message_id: messageId,
    } as ButtonClickParams);
  };

  if (error) {
    return (
      <EmptyStateContainer>
        <EmptyStateCta
          imageHeight={IMAGE_SIZE}
          imageWidth={IMAGE_SIZE}
          imageSource={EmptyStateImage}
          title={errorStateTitle}
          subtitle={errorStateSubtitle}
        />
      </EmptyStateContainer>
    );
  }

  return messages?.length ? (
    <InfiniteScroll
      dataLength={messages?.length || 0}
      hasChildren
      hasMore={hasMore}
      loader={<ListSpinner />}
      next={onFetchMore}
    >
      <ListContainer>
        {messages?.map((message, index) => (
          <li key={index}>
            <ListItem
              onClick={() => {
                logButtonClickEvent(message.id.persistentId);
              }}
              to={`/account/messages/${inbox ? "" : "sent/"}${parsePersistentMessageId(
                message.id.persistentId,
              )}`}
            >
              <MessageListItem
                date={message.date}
                preview={message.bodyPreview}
                senderName={message.senderName}
                subject={message.subject}
                icon={getIconForMessage(message)}
              />
            </ListItem>
          </li>
        ))}
      </ListContainer>
    </InfiniteScroll>
  ) : (
    <EmptyStateContainer>
      <EmptyStateCta
        imageHeight={IMAGE_SIZE}
        imageWidth={IMAGE_SIZE}
        imageSource={EmptyStateImage}
        title={emptyStateTitle}
        subtitle={emptyStateSubtitle}
      />
    </EmptyStateContainer>
  );
};

export default MessageList;
