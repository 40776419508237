import { useEffect, useState } from "react";

const SCRIPT_TAG_ID = btoa(new Date().toDateString());
const USED_LIBRARIES: ("drawing" | "geometry" | "localContext" | "places" | "visualization")[] = [
  "places",
];

/**
 * useGoogleMaps loads the Google Maps JS API library by appending a newly
 * created script tag to the document's `<head>`. It stores a reference to the
 * global `google.maps` variable in state, which it returns once the library
 * has loaded.
 */
const useGoogleMaps = () => {
  const [mapsApi, setMapsApi] = useState<typeof google.maps | undefined>(window?.google?.maps);

  const onLoad = () => {
    if (window?.google?.maps) {
      setMapsApi(window.google.maps);
    }
  };

  useEffect(() => {
    const existingScriptTag: HTMLScriptElement | null = document.head.querySelector(
      `#${SCRIPT_TAG_ID}`,
    );
    const scriptTag = existingScriptTag || document.createElement("script");

    if (window?.google?.maps) {
      setMapsApi(window.google.maps);
      return;
    }

    if (existingScriptTag) {
      scriptTag.addEventListener("load", onLoad);
    } else {
      scriptTag.async = true;
      scriptTag.defer = true;
      scriptTag.setAttribute("id", SCRIPT_TAG_ID);
      scriptTag.src = `https://maps.googleapis.com/maps/api/js?key=${
        process.env.GOOGLE_MAPS_API_KEY
      }&libraries=${USED_LIBRARIES.join(",")}&v=weekly`;

      scriptTag.addEventListener("load", onLoad);
      document.head.appendChild(scriptTag);
    }

    return () => {
      scriptTag.removeEventListener("load", onLoad);
    };
  }, []);

  return mapsApi;
};

export default useGoogleMaps;
