import { gql, LazyQueryHookOptions } from "@apollo/client";

import { useB2CLazyQuery } from "../client";
import { PHARMACY_SEARCH, PHARMACY_SEARCHVariables } from "../schema/types";

export const GET_PHARMACIES_LIST = gql`
  query PHARMACY_SEARCH($search: String!) {
    pharmacies(name: $search) {
      name
      facilityId
      address
      city
      state
      zip
      distance
      phoneNumber
      faxNumber
      ncpdpId
      pharmacyType
    }
  }
`;

export const usePharmacySearch = (
  options: LazyQueryHookOptions<PHARMACY_SEARCH, PHARMACY_SEARCHVariables>,
) => useB2CLazyQuery<PHARMACY_SEARCH, PHARMACY_SEARCHVariables>(GET_PHARMACIES_LIST, options);
