import { sizes } from "./sizes";
import { spacing } from "./spacing";

/**
 * `screenSizes` represent the min-width for each break point
 */
export const screenSizes = {
  /**
   * `tabletPortrait` is when we go from mobile designs (nav in a hamburger) to a view
   * that is laid out very veritcally like mobile, but with a condensed tab bar.
   *
   * For the most part page content will be laid out exactly the same as mobile, except with the
   * addition of the small navbar.
   */
  tabletPortrait: 520,
  /**
   * `tablet` is a landscape view with condensed navbar, but a more horizontal layout
   */
  tablet: 1024,
  /**
   * `desktop` views will have a full navbar and horiztonal layout
   */
  desktop: 1440,
  /**
   * `superwide` is very similar to `desktop` except the main content will be centered in the
   * viewport, as if the navbar has `position: absolute`.
   */
  superwide: sizes.nav * 2 + sizes.mainContent + spacing.base * 80,
} as const;

export const breakpoints = {
  /**
   * min-width breakpoints represent the lower bound for each breakpoint
   */
  min: {
    tabletPortrait: `(min-width: ${screenSizes.tabletPortrait}px)`,
    tablet: `(min-width: ${screenSizes.tablet}px)`,
    desktop: `(min-width: ${screenSizes.desktop}px)`,
    superwide: `(min-width: ${screenSizes.superwide}px)`,
  },
  /**
   * max-width breakpoints represent the upper bound for each breakpoint
   */
  max: {
    mobile: `(max-width: ${screenSizes.tabletPortrait - 1}px)`,
    tabletPortrait: `(max-width: ${screenSizes.tablet - 1}px)`,
    tablet: `(max-width: ${screenSizes.desktop - 1}px)`,
    desktop: `(max-width: ${screenSizes.superwide - 1}px)`,
  },
};

/**
 * Pre-defined media queries that you should try to use as much as possible.
 *
 * By default we will do `mobile-first` designs. Which means the default styles of a component
 * without any media queries will correspond to a mobile layout. Then you will add your media queries
 * to adjust content based on the larger screen size
 */
export const media = {
  tabletPortrait: `@media ${breakpoints.min.tabletPortrait}`,
  tablet: `@media ${breakpoints.min.tablet}`,
  desktop: `@media ${breakpoints.min.desktop}`,
  superwide: `@media ${breakpoints.min.superwide}`,

  max: {
    mobile: `@media ${breakpoints.max.mobile}`,
    tabletPortrait: `@media ${breakpoints.max.tabletPortrait}`,
    tablet: `@media ${breakpoints.max.tablet}`,
    desktop: `@media ${breakpoints.max.desktop}`,
  },

  range: {
    small: `@media ${breakpoints.max.mobile}`,
    medium: `@media ${breakpoints.min.tabletPortrait} and ${breakpoints.max.tablet}`,
    large: `@media ${breakpoints.min.desktop}`,
  },
} as const;
