import styled from "styled-components";

import { layout } from "/styles";

export const Selected = styled.div(({ theme }) => ({
  width: 16,
  height: 16,
  borderRadius: "50%",
  backgroundColor: theme.colors.brandPrimary,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: theme.spacing.gutter,
}));

export const CaregiverInfoContainer = styled.div(
  layout.flexLTR,
  layout.spacedChildrenHorizontal("base"),
  {
    maxWidth: 215,
    width: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    display: "inline",
    textAlign: "start",
  },
);

export const PlusIcon = styled.div(layout.flexCenter, ({ theme }) => ({
  height: 48,
  width: 48,
  borderRadius: "50%",
  borderStyle: "dashed",
  borderColor: theme.colors.black,
  borderWidth: "1px",
}));
