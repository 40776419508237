import React from "react";

import * as Icons from "/component/base/Icon";
import Text from "/component/base/Text";
import { layout } from "/styles";
import { formatDate } from "/util/date";

import { ContentWrapper, TextContent, TitleContent } from "./DocumentListItem.styles";

interface Props {
  document: {
    title: string;
    providerName: string;
    dateString: string;
  };
}

const DocumentListItem = ({ document }: Props) => {
  const { title, providerName, dateString } = document;
  const subTitle = providerName;
  const docDate = formatDate(dateString, "MMMM dd, yyyy");

  return (
    <div
      css={[
        layout.flexCenterHorizontal,
        layout.margin("standard", "none"),
        layout.spacedChildrenHorizontal("standard"),
      ]}
    >
      <div>
        <Icons.FileText
          color="brandSecondary"
          background={{ size: 48, color: "brandSecondaryLight" }}
        />
      </div>
      <ContentWrapper>
        <TextContent>
          <TitleContent>
            <Text color={"textPrimary"} variant="body1Bold">
              {title}
            </Text>
          </TitleContent>

          {subTitle && (
            <Text color="textSecondary" variant="body2">
              {subTitle}
            </Text>
          )}

          <Text variant="body2" color="textSecondary">
            {docDate}
          </Text>
        </TextContent>
      </ContentWrapper>
    </div>
  );
};

export default DocumentListItem;
