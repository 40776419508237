import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React from "react";
import { Helmet } from "react-helmet-async";
import { RouteComponentProps } from "react-router";

import { GetFaqQuery_faqCategories } from "/apollo/schema";
import { Loading } from "/component/base";
import Accordion from "/component/base/Accordion";
import {
  BillPayment,
  Calendar,
  CityMD,
  FeatherIcon,
  MyAccountComplete,
  MyHealth,
  Video,
} from "/component/base/Icon";
import List from "/component/base/List";
import Text from "/component/base/Text";
import MainContent from "/component/layout/MainContent";
import routes from "/constant/url.constant";
import { useTranslation } from "/hook";
import { layout } from "/styles";
import { reactPlugin } from "/util/appInsights.util";

import useFaqQuery from "../useFaqQuery";
import {
  CardContainer,
  Container,
  FaqCategoryWrapper,
  FaqItem,
  IconWrapper,
} from "./FaqIndex.styles";

const ICONS = {
  "bills-payment": (
    <BillPayment color="brandSecondary" background={{ size: 52, color: "brandSecondaryLight" }} />
  ),
  calendar: (
    <Calendar color="brandSecondary" background={{ size: 52, color: "brandSecondaryLight" }} />
  ),
  video: <Video color="brandSecondary" background={{ size: 52, color: "brandSecondaryLight" }} />,
  "user-thin-border": (
    <MyAccountComplete
      color="brandSecondary"
      background={{ size: 52, color: "brandSecondaryLight" }}
    />
  ),
  "sheild-plus": (
    <MyHealth color="brandSecondary" background={{ size: 52, color: "brandSecondaryLight" }} />
  ),
  "logo-location": (
    <CityMD color="brandSecondary" background={{ size: 52, color: "brandSecondaryLight" }} />
  ),
  citymd: <CityMD color="brandSecondary" background={{ size: 52, color: "brandSecondaryLight" }} />,
};

const FaqIndex = ({ match }: RouteComponentProps) => {
  const { t } = useTranslation("faqIndex");
  const { data, loading } = useFaqQuery();

  const topQuestions = data?.faqContent
    .filter((faqContent) => faqContent.isTopQuestion)
    .map((faq) => ({ ...faq, to: `${match.url}${routes.faqDetail}/${faq.id}` }));

  const categories = data?.faqCategories.map((category) => ({
    ...category,
    to: `${match.url}${routes.faqCategory}/${category.id}`,
  }));

  const renderCategory = (category: GetFaqQuery_faqCategories) => (
    <FaqItem>
      {category.icon && (
        <IconWrapper>
          {ICONS[category.icon] ? (
            ICONS[category.icon]
          ) : (
            <FeatherIcon
              color="brandSecondary"
              name={category.icon}
              background={{ size: 52, color: "brandSecondaryLight" }}
            />
          )}
        </IconWrapper>
      )}
      <FaqCategoryWrapper>
        <Text variant="body1Bold">{category.title}</Text>
        <Text variant="body2" color="textSecondary">
          {category.description}
        </Text>
      </FaqCategoryWrapper>
    </FaqItem>
  );

  const getChild = () => {
    if (loading) {
      return <Loading />;
    }
    const accordionSections = topQuestions?.map((item) => ({
      title: item.question,
      content: item.body,
    }));
    return (
      <>
        {accordionSections && (
          <>
            <Text variant="body2Bold" color="textSecondary">
              {t("topQuestionHeader")}
            </Text>
            <CardContainer>
              <Accordion accordionSections={accordionSections} />
            </CardContainer>
          </>
        )}
        <Container css={layout.marginVertical(60)}>
          <Text variant="body2Bold" color="textSecondary">
            {t("category.header")}
          </Text>
          {categories && (
            <CardContainer>
              <List as="Links" hideLastChildBorder data={categories} renderItem={renderCategory} />
            </CardContainer>
          )}
        </Container>
      </>
    );
  };

  return (
    <MainContent pageTitle={t("headline")}>
      <Helmet>
        <title>{t("title")}</title>
      </Helmet>
      {getChild()}
    </MainContent>
  );
};

export default withAITracking(reactPlugin, FaqIndex, undefined, "ai-tracking");
