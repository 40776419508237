import { useField } from "formik";
import React from "react";
import ReactInputMask from "react-input-mask";

import InputWrapper from "/component/base/InputWrapper";
import MaskedInput from "/component/base/MaskedInput";

import Props from "./FormattedTextField.types";

const TextField = React.forwardRef<ReactInputMask, Props>(
  ({ after, before, className, label, inputWrapperVariant = "primary", mask, ...props }, ref) => {
    const [field, meta] = useField(props);
    return (
      <InputWrapper
        className={className}
        error={meta.error}
        htmlFor={field.name}
        label={label}
        touched={meta.touched}
        variant={inputWrapperVariant}
      >
        <MaskedInput
          {...field}
          {...props}
          value={field.value ? field.value : ""}
          hasError={meta.touched && !!meta.error}
          before={before}
          after={after}
          mask={mask}
          ref={ref}
        />
      </InputWrapper>
    );
  },
);

export default TextField;
