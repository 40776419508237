import { RadioGroup } from "@headlessui/react";
import React from "react";
import styled from "styled-components";

import { getTextStyles, TextProps } from "/component/base/Text";

type LabelProps = Pick<TextProps, "color" | "variant" | "truncate" | "dangerouslySetInnerHTML"> & {
  children?: React.ReactNode;
};

const BaseLabel = (props: LabelProps) => <RadioGroup.Label {...props} />;

export const Label = styled(BaseLabel)<LabelProps>(getTextStyles);
