import { Form, Formik } from "formik";
import React from "react";
import * as yup from "yup";

import { Button, Loading } from "/component/base";
import { ArrowLeft, Send } from "/component/base/Icon";
import { closeButtonSize } from "/component/base/Modal/Modal.styles";
import MessageContactsForm from "/component/form/MessageContactsForm";
import MessageForm from "/component/form/MessageForm";
import MessageTopicsForm from "/component/form/MessageTopicsForm";
import { useToastContext } from "/component/provider/ToastProvider";
import { useAmwell, useTranslation } from "/hook";

import { Modal } from "./ComposeMessageModal.styles";
import {
  ComposeFormValues,
  HeaderLeftProps,
  HeaderRightProps,
  Props,
} from "./ComposeMessageModal.types";
import { useAmwellTopics } from "./useAmwellTopics";

const BYTES_PER_MEGABYTE = 1048576;

const ComposeMessageModal = ({
  close,
  contacts,
  initialContactValue,
  isOpen,
  ...modalProps
}: Props) => {
  const { data: topics, error, loading } = useAmwellTopics();
  const { sdk, isReady, consumer } = useAmwell();
  const { showToast } = useToastContext();
  const { t } = useTranslation("composeMessage");

  const validationSchema = yup.object().shape({
    attachment: yup
      .mixed()
      .test("is-file-too-large", t("error.attachmentSize"), ({ data } = {}) =>
        data?.size ? data.size / BYTES_PER_MEGABYTE <= 20 : true,
      ),
    includeSummary: yup.boolean().required(t("error.required")),
    message: yup
      .string()
      .trim()
      .min(
        1,
        t("error.empty", {
          defaultValue: "{{field}} cannot be empty",
          field: t("field.message", "Message"),
        }),
      )
      .required(t("error.required")),
    subject: yup
      .string()
      .trim()
      .min(
        1,
        t("error.empty", {
          defaultValue: "{{field}} cannot be empty",
          field: t("field.subject", "Subject"),
        }),
      )
      .required(t("error.required")),
    topic: yup.object().required(t("error.required")),
    contact: yup.object().required(t("error.required")),
  });

  const handleSubmit = async (
    { attachment, contact, includeSummary, message, topic, subject }: ComposeFormValues,
    { resetForm }: { resetForm: () => void },
  ) => {
    if (consumer && sdk) {
      const messageDraft = sdk.secureMessageService.getNewMessageDraft();

      if (subject) {
        messageDraft.subject = subject;
      }

      if (contact) {
        messageDraft.recipient = contact;
      }

      if (topic) {
        messageDraft.topicType = topic;
      }

      if (message) {
        messageDraft.body = message;
      }

      if (typeof includeSummary === "boolean") {
        messageDraft.attachHealthSummary = includeSummary;
      }

      if (attachment) {
        messageDraft.uploadAttachment = attachment;
      }

      try {
        await sdk.secureMessageService.sendMessage(consumer, messageDraft);
        resetForm();
        close?.();
        showToast({ icon: "checkmark", message: t("alert.sent") });
      } catch (e: any) {
        showToast({
          icon: "alert",
          message: t("alert.sendError.subtitle"),
          title: t("alert.sendError.title"),
          type: "error",
        });
      }
    }
  };

  const renderTitle = ({ contact, topic }: ComposeFormValues) => {
    if (contact && topic) {
      return `${contact?.firstName} ${contact?.lastName}`;
    }

    if (contact && !topic) {
      return t("title.chooseTopic");
    }

    return t("title.chooseProvider");
  };

  const renderChildContent = ({ contact, topic }: ComposeFormValues) => {
    if (contact && topic) {
      return <MessageForm />;
    }

    if (contact && !topic) {
      return <MessageTopicsForm topics={topics} name="topic" />;
    }

    return <MessageContactsForm contacts={contacts} name="contact" />;
  };

  const renderHeaderLeft = ({ contact, onClose }: HeaderLeftProps) =>
    contact ? (
      <Button aria-label={t("button.goBack")} onClick={onClose} variant="unstyled">
        <ArrowLeft
          aria-hidden
          background={{ color: "objectInverse", size: closeButtonSize }}
          color="brandSecondary"
        />
      </Button>
    ) : undefined;

  const renderHeaderRight = (props: HeaderRightProps) => (
    <Button {...props} aria-label={t("button.send")} type="submit" variant="unstyled">
      <Send aria-hidden background={{ color: "brandSecondary", size: 40 }} color="objectInverse" />
    </Button>
  );

  return (
    <Formik
      initialValues={{
        attachment: undefined,
        contact: initialContactValue,
        includeSummary: false,
        message: "",
        subject: "",
        topic: undefined,
      }}
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ getFieldHelpers, handleReset, submitForm, isSubmitting, isValid, values }) => {
        const handleClose = () => {
          handleReset();
          close?.();
        };

        const handleGoBackOrClose = () => {
          if (values.contact && !values.topic) {
            const { setValue } = getFieldHelpers("contact");
            setValue(undefined);

            return;
          }

          if (values.contact && values.topic) {
            const { setValue } = getFieldHelpers("topic");
            setValue(undefined);

            return;
          }

          handleClose();
        };

        return (
          <Form>
            <Modal
              {...modalProps}
              close={handleClose}
              $isComposing={!!(values.contact && values.topic)}
              header={
                <Modal.Header
                  close={handleClose}
                  headerLeft={renderHeaderLeft({ onClose: handleGoBackOrClose, ...values })}
                  headerRight={
                    values.contact && values.topic
                      ? renderHeaderRight({
                          isDisabled: !isValid,
                          isLoading: isSubmitting,
                          onClick: submitForm,
                        })
                      : undefined
                  }
                  title={renderTitle(values)}
                />
              }
              isOpen={isOpen}
            >
              {!isReady && loading && <Loading variant="modal" />}
              {isReady && !loading && !error && renderChildContent(values)}
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ComposeMessageModal;
