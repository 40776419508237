import { breakpoints, media, screenSizes } from "./breakpoints";
import { colors } from "./colors";
import { fontFamilies, fontVariants } from "./fontFamilies";
import { fontSizes } from "./fontSizes";
import { fontWeights } from "./fontWeights";
import { lineHeights, lineHeightsInPixels } from "./lineHeights";
import { shadows } from "./shadows";
import { sizes } from "./sizes";
import { spacing } from "./spacing";
import { zIndex } from "./zIndex";

const theme = {
  breakpoints,
  colors,
  fontFamilies,
  fontSizes,
  fontVariants,
  fontWeights,
  lineHeightsInPixels,
  lineHeights,
  media,
  screenSizes,
  shadows,
  sizes,
  spacing,
  zIndex,
} as const;

export interface ThemeType {
  theme: typeof theme;
}

export * from "./default.types";

export default theme;
