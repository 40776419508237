import React from "react";

import { GET_LAB_DETAILS_patient_labResult_analytes } from "/b2c/schema/types";
import { Text } from "/component/base";
import { useTranslation } from "/hook";
import { layout } from "/styles";

import ResultListItem from "../ResultListItem";

interface Props {
  analytes: GET_LAB_DETAILS_patient_labResult_analytes[];
}

const LabResultDetails = ({ analytes }: Props) => {
  const { t } = useTranslation("result");

  if (analytes.length === 0)
    return (
      <div css={layout.margin("skip", "skip", "standard", "skip")}>
        <Text variant="title2">{t("resultDetails.title")}</Text>
      </div>
    );

  return (
    <div>
      <div css={layout.margin("skip", "skip", "standard", "skip")}>
        <Text variant="title2">{"Result details"}</Text>
      </div>

      <div css={layout.flexSpaceHorizontal}>
        <Text color="textSecondary" variant="body1Bold">
          {t("resultDetails.name")}
        </Text>
        <Text color="textSecondary" variant="body1Bold">
          {t("resultDetails.result")}
        </Text>
      </div>

      {analytes.map((analyte, index) => (
        <ResultListItem key={index} analyte={analyte} />
      ))}
    </div>
  );
};

export default LabResultDetails;
