import styled from "styled-components";

import OnboardingLocationImage from "/asset/image/onboardingLocation.png";
import BaseModal from "/component/base/Modal";
import Text from "/component/base/Text";
import { layout } from "/styles";

export const StepTwoTitle = styled(Text)(() => ({
  maxWidth: 435,
}));

export const StepTwoSubtitle = styled(Text)(() => ({
  display: "block",
  maxWidth: 435,
}));

export const StepOneContent = styled.div(layout.spacedChildrenVertical(), ({ theme }) => ({
  paddingTop: theme.spacing.gutter,
  paddingBottom: theme.spacing.gutter,
  paddingLeft: theme.spacing[80],
  paddingRight: theme.spacing[80],
  [theme.media.max.mobile]: {
    paddingLeft: theme.spacing.gutter,
    paddingRight: theme.spacing.gutter,
  },
}));

export const StepTwoContent = styled.div(layout.flexVertical, () => ({
  alignItems: "center",
  textAlign: "center",
  "& :first-child": {
    marginTop: 0,
  },
}));

export const StepOneFooter = styled.div(layout.flexSpaceHorizontal, ({ theme }) => ({
  marginTop: theme.spacing.base * 20,
  [theme.media.max.mobile]: {
    ...layout.flexVertical,
    ...layout.spacedChildrenVertical()({ theme }),
    [`& > *:first-child`]: {
      width: "100%",
    },
  },
}));

export const StepOneFooterVertical = styled.div(layout.flexVerticalCenter, ({ theme }) => ({
  marginTop: theme.spacing.base * 8,
  [`& > *`]: {
    width: "100%",
  },
}));

export const Modal = styled(BaseModal)(() => ({
  [`& > *`]: {
    padding: 0,
  },
}));

export const Title = BaseModal.Title;

export const Description = BaseModal.Description;

export const StepTwoFooter = styled.div(layout.flexVertical, ({ theme }) => ({
  alignItems: "stretch",
  alignSelf: "stretch",
  padding: "60px",
  height: "435px",
  justifyContent: "center",
  backgroundImage: `url(${OnboardingLocationImage})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "100% auto",
  width: "100%",
  [theme.media.tablet]: {
    alignSelf: "center",
  },
}));
export const StepTwoHeader = StepOneContent;
