import React from "react";

import { Modal, Text } from "/component/base";
import ObservationValue from "/component/partial/ObservationValue/ObservationValue";
import { getContentfulNotes } from "/component/partial/ObservationValue/ObservationValue.util";
import { useTranslation } from "/hook";
import { layout } from "/styles";

import { Props } from "./ObservationNotesModal.types";

const ObservationNotesModal = ({ close, isOpen, observation }: Props) => {
  const { t } = useTranslation("result");
  const notes = getContentfulNotes(observation || {});

  return (
    <Modal
      close={close}
      header={<Modal.Header close={close} title={t("title.report")} />}
      isOpen={isOpen}
    >
      <div css={[layout.spacedChildrenVertical(), layout.flexVertical]}>
        {observation && (
          <>
            <Text css={layout.flexItemIntrinsic}>{observation?.name}</Text>

            <div css={[layout.flexVertical]}>
              <ObservationValue observation={observation} truncate={false} />
            </div>
          </>
        )}

        {notes.map((note, i) =>
          note.split(/\r\n/).map(
            (innerNote, index) =>
              innerNote && (
                <Text element="p" key={`${i}${index}`}>
                  {innerNote}
                </Text>
              ),
          ),
        )}
      </div>
    </Modal>
  );
};

export default ObservationNotesModal;
