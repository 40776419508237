import awsdk from "awsdk";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import { Spinner } from "/component/base";
import Button from "/component/base/Button";
import Combobox from "/component/base/Combobox";
import { Medicine, Search } from "/component/base/Icon";
import Loading from "/component/base/Loading";
import SelectedItemsList from "/component/base/SelectedItemsList";
import FormContent from "/component/partial/FormContent";
import {
  MedicationsProps,
  useAmwellPrevisitContext,
} from "/component/provider/AmwellPrevisitProvider";
import { useAmwellMedications, useAmwellMedicationsSearch, useTranslation } from "/hook";
import { layout } from "/styles";

import { ButtonContainer, MedicationListContainer, SkipButton, Title } from "../Telehealth.styles";
import { TelehealthFormProps } from "../Telehealth.types";

interface MedicationSelectedItemProps {
  label: string;
  value: string;
}

const Medications = ({
  handleError,
  handleSubmit,
  initialValues,
}: TelehealthFormProps<MedicationsProps>) => {
  const { t } = useTranslation("form-telehealth-previsit");
  const [medSearchInput, setMedSearchInput] = useState("");
  const [currentMedications, setCurrentMedications] = useState<awsdk.AWSDKMedication[]>([]);

  const { data: medicationList, loading: searchLoading } =
    useAmwellMedicationsSearch(medSearchInput);
  const { data, loading } = useAmwellMedications();
  const { consumer, sdk } = useAmwellPrevisitContext();

  useEffect(() => {
    if (!loading && data && data.medications.length > 0) {
      setCurrentMedications(data.medications);
    }
  }, [data, loading]);

  const medicationSchema = Yup.object({
    medications: Yup.array(Yup.string()),
  });

  const getMedicationString = (medication: awsdk.AWSDKMedication | null) => {
    return medication?.displayName || "";
  };

  const getMedicationsMedList = () => {
    let medList = [] as MedicationSelectedItemProps[];

    if (!loading && currentMedications) {
      medList = currentMedications
        .map((medication) => {
          return medication
            ? {
                label: medication?.displayName,
                value: medication.code,
              }
            : null;
        })
        .filter((medication) => !!medication) as MedicationSelectedItemProps[];
    }

    return medList;
  };

  const submitForm = async () => {
    try {
      if (sdk && data && consumer) {
        await sdk.consumerService.updateMedications(consumer, currentMedications);
        handleSubmit({
          medications: currentMedications.map((m) => m.code),
        });
      }
    } catch (e: any) {
      handleError(e);
    }
  };

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={initialValues}
      validationSchema={medicationSchema}
      onSubmit={submitForm}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <FormContent css={layout.margin("gutter", "skip")}>
            <Title>{t("medications.title")}</Title>
            {loading ? (
              <Loading />
            ) : (
              <>
                <div css={layout.margin(40, "skip", 40)}>
                  <Combobox
                    before={<Search />}
                    after={searchLoading ? <Spinner /> : undefined}
                    itemToString={getMedicationString}
                    items={medicationList?.medications || []}
                    onChangeSelectedItem={(medicationSelected?: awsdk.AWSDKMedication | null) => {
                      if (
                        medicationSelected &&
                        values.medications.indexOf(medicationSelected.code) < 0
                      ) {
                        setCurrentMedications([...currentMedications, medicationSelected]);
                        setMedSearchInput("");
                      }
                    }}
                    onChangeText={(input) => setMedSearchInput(input || "")}
                    placeholder={t("medications.placeholder")}
                    inputValue={medSearchInput}
                  />
                  <MedicationListContainer>
                    <SelectedItemsList
                      icon={<Medicine size={16} />}
                      items={getMedicationsMedList()}
                      onRemove={(medicationCode: string) => {
                        const updatedList = currentMedications.filter(
                          (med) => med.code !== medicationCode,
                        );

                        setCurrentMedications(updatedList);
                      }}
                    />
                  </MedicationListContainer>
                </div>
                <ButtonContainer>
                  <Button
                    css={layout.flexItemAuto}
                    variant="primary"
                    type="submit"
                    isLoading={isSubmitting}
                  >
                    {t("continue")}
                  </Button>
                  {currentMedications.length === 0 && (
                    <SkipButton onClick={() => handleSubmit()} variant="borderBottom">
                      {t("skip")}
                    </SkipButton>
                  )}
                </ButtonContainer>
              </>
            )}
          </FormContent>
        </Form>
      )}
    </Formik>
  );
};

export default Medications;
