import React from "react";

import { Avatar, Divider, Loading, Modal, Text } from "/component/base";
import ReadMore from "/component/partial/ReadMore";
import { useTranslation } from "/hook";
import { layout } from "/styles";

import {
  Header,
  HeaderSection,
  Name,
  QualificationHeader,
} from "./ProviderDetailsModalBase.styles";
import { Props } from "./ProviderDetailsModalBase.types";

const getQualificationItems = (qualifications: Props["qualifications"]) => {
  const children: React.ReactElement[] = [];

  qualifications.map(({ label, values }) => {
    if (!values.length) return;

    // If there is only one value in the `values` array return that directly as a child of the
    // QualificationValue. If there's more than one `value` in the array return a list (ul), nested
    // inside of the QualificationValue
    let value: React.ReactNode = values[0];
    if (values.length > 1) {
      value = (
        <ul css={layout.spacedChildrenVertical("base")}>
          {values.map((val, i) => (
            <li key={i}>{val}</li>
          ))}
        </ul>
      );
    }

    children.push(
      <Text key={label} element="li" variant="body1Bold">
        <QualificationHeader>{label}</QualificationHeader>
        {value}
      </Text>,
    );
  });

  return children;
};

const ProviderDetailsModalBase = ({
  primarySpecialty,
  nameWithDesignations,
  avatarUrl,
  initials,
  cta,
  bio,
  location,
  qualifications,
  languages,
  loading,
  close,
  ...modalProps
}: Props) => {
  const { t } = useTranslation("modal-providerDetails");

  const qualificationItems = getQualificationItems(qualifications);

  const modalContent = (
    <>
      <HeaderSection $hasCta={!!cta}>
        <Avatar
          initials={initials}
          src={avatarUrl}
          alt={nameWithDesignations || "Provider avatar"}
          size="xLarge"
        />
        <div css={layout.flexCenter}>
          <Header>{primarySpecialty}</Header>
        </div>
        <Name forwardedAs={Text} variant="title1" element="h2">
          {nameWithDesignations}
        </Name>
        {cta}
      </HeaderSection>

      {!!bio && (
        <>
          <Header>{t("bio")}</Header>
          <ReadMore lines={2} textVariant="body1" text={bio || ""} />
          <Divider css={layout.marginVertical("standard")} />
        </>
      )}

      {!!location && (
        <>
          <Header>{t("location")}</Header>
          {location}
          <Divider css={layout.marginVertical("standard")} />
        </>
      )}

      {!!qualificationItems.length && (
        <>
          <Header>{t("qualifications")}</Header>
          <ul css={layout.spacedChildrenVertical()}>{qualificationItems}</ul>

          <Divider css={layout.marginVertical("standard")} />
        </>
      )}

      {!!languages && (
        <>
          <Header>{t("languages")}</Header>
          <Text>{languages.join(", ")}</Text>
        </>
      )}
    </>
  );

  return (
    <Modal {...modalProps} close={close} header={<Modal.Header close={close} />}>
      {loading ? <Loading variant="modal" /> : modalContent}
    </Modal>
  );
};

export default ProviderDetailsModalBase;
