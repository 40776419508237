import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";

import noMessages from "/asset/image/secureMessagingNoMessages.png";
import { InlineMessage, List, Loading, Spinner, Text, Toggle } from "/component/base";
import { AlertCircleOpen } from "/component/base/Icon";
import MainContent from "/component/layout/MainContent";
import {
  getFormattedOptions,
  getTextContentForCategory,
  NotificationCategory,
  shouldDisable,
  StoreObject,
} from "/component/page/Account/page/Notifications/Notifications.util";
import EmptyStateCta from "/component/partial/EmptyStateCta";
import { useToastContext } from "/component/provider/ToastProvider";
import Transition from "/component/util/Transition";
import { card, layout, transition } from "/styles";
import { reactPlugin } from "/util/appInsights.util";

import {
  useNotificationPreference,
  useSetNotificationPreference,
} from "./useNotificationPreference";

const IMAGE_SIZE = 274;

const Notifications = ({
  match,
}: RouteComponentProps<{
  category: NotificationCategory;
}>) => {
  const { category } = match.params;
  const { client, data, error, loading } = useNotificationPreference();
  const { showToast } = useToastContext();
  const { t } = useTranslation("notifications");
  const { disclaimer, title } = getTextContentForCategory(category);
  const [edited, setEdited] = useState<string[]>([]);
  const notificationPreference = data?.viewer?.user.notificationPreference;
  const pendingMutation = useRef(false);

  const categorySettings = notificationPreference?.[category];

  const isInitialLoad = !categorySettings && !error && loading;
  const [setNotificationPreference] = useSetNotificationPreference({
    onError: () => {
      showToast({ icon: "alert", message: t("error.updateNotification"), type: "error" });
    },
  });

  const modifyNotificationPreferenceCache = (updatedSettings = {}) => {
    client.cache.modify({
      fields: {
        [category]: (cachedSettings: any) => {
          return { ...cachedSettings, ...(updatedSettings || {}) };
        },
      },
      id: client.cache.identify(notificationPreference as StoreObject),
    });
  };

  const handleChangeToggle = ({ type, value }: { type: string; value: boolean }) => {
    modifyNotificationPreferenceCache({ [type]: value });
    setEdited([...edited, type]);
  };

  useEffect(() => {
    if (!!edited.length && !pendingMutation.current) {
      const setPreference = async () => {
        pendingMutation.current = true;
        const modifiedSetting = edited[0];
        const input = { [category]: { [modifiedSetting]: !categorySettings?.[modifiedSetting] } };

        const { data: result } = await setNotificationPreference({ variables: { input } });

        modifyNotificationPreferenceCache(
          result?.updateNotificationPreference.notificationPreference?.[category],
        );

        pendingMutation.current = false;
        setEdited(edited.filter((_, i) => i !== 0));
      };

      setPreference();
    }
  }, [categorySettings, edited]);

  return (
    <MainContent pageTitle={title} parentPage="notifications">
      {disclaimer && (
        <InlineMessage
          variant="important"
          css={layout.margin("skip", "skip", "expanded")}
          icon={<AlertCircleOpen />}
        >
          <Text color="inherit" variant="body2">
            {disclaimer}
          </Text>
        </InlineMessage>
      )}

      {isInitialLoad && <Loading variant="page" />}

      {!isInitialLoad && error && (
        <EmptyStateCta
          imageHeight={IMAGE_SIZE}
          imageWidth={IMAGE_SIZE}
          imageSource={noMessages}
          subtitle={t("error.subtitle")}
          title={t("error.title")}
        />
      )}

      {!isInitialLoad && !error && categorySettings && (
        <div css={{ position: "relative" }}>
          <List
            as="Divs"
            data={getFormattedOptions(categorySettings)}
            hideLastChildBorder
            renderItem={({ label, type, value }) => (
              <label
                css={[
                  layout.flexItemAuto,
                  layout.flexSpaceHorizontal,
                  layout.padding("standard", "skip"),
                ]}
              >
                <Text color={pendingMutation.current ? "textSecondary" : "textPrimary"}>
                  {label}
                </Text>

                <Toggle
                  checked={value}
                  disabled={pendingMutation.current || shouldDisable({ category, type })}
                  onChange={({ target }) => {
                    handleChangeToggle({ type, value: target.checked });
                  }}
                />
              </label>
            )}
            showChevron={false}
          />

          <Transition show={pendingMutation.current}>
            <div css={[layout.absoluteCenter, layout.flexCenter, transition.fadeDown]}>
              <span css={[card.base, layout.padding("standard")]}>
                <Spinner />
              </span>
            </div>
          </Transition>
        </div>
      )}
    </MainContent>
  );
};

export default withAITracking(reactPlugin, Notifications, undefined, "ai-tracking");
