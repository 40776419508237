import { gql } from "@apollo/client";

export default gql`
  mutation SendPasswordReset($email: String!) {
    sendPasswordReset(email: $email) {
      errors {
        message
        code
      }
    }
  }
`;
