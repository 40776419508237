import { rem } from "polished";

import { LineHeights } from "./default.types";

/**
 * Note: `lineHeightsInPixels` should not be used to set the lineHeight of text. These values should
 * be used if you need to calculate heights of objects based on `number_of_lines * line_height`
 */
export const lineHeightsInPixels: { [Key in LineHeights]: number } = {
  body1: 24,
  body1Medium: 24,
  body1Bold: 24,
  body1Italic: 24,
  body1BoldItalic: 24,
  body2: 20,
  body2Medium: 20,
  body2Bold: 20,
  body2Italic: 20,
  body2BoldItalic: 20,
  body3: 16,
  body3Bold: 16,
  body3Italic: 16,
  body3BoldItalic: 16,
  headline: 48,
  navigation: 16,
  navigationBold: 16,
  title1: 32,
  title2: 32,
  title3: 24,
  buttonTitle: 24,
} as const;

export const lineHeights: { [Key in LineHeights]: string } = {
  body1: rem(lineHeightsInPixels.body1),
  body1Medium: rem(lineHeightsInPixels.body1Medium),
  body1Bold: rem(lineHeightsInPixels.body1Bold),
  body1Italic: rem(lineHeightsInPixels.body1Italic),
  body1BoldItalic: rem(lineHeightsInPixels.body1BoldItalic),
  body2: rem(lineHeightsInPixels.body2),
  body2Medium: rem(lineHeightsInPixels.body2Medium),
  body2Bold: rem(lineHeightsInPixels.body2Bold),
  body2Italic: rem(lineHeightsInPixels.body2Italic),
  body2BoldItalic: rem(lineHeightsInPixels.body2BoldItalic),
  body3: rem(lineHeightsInPixels.body2),
  body3Bold: rem(lineHeightsInPixels.body2Bold),
  body3Italic: rem(lineHeightsInPixels.body2Italic),
  body3BoldItalic: rem(lineHeightsInPixels.body2BoldItalic),
  headline: rem(lineHeightsInPixels.headline),
  navigation: rem(lineHeightsInPixels.navigation),
  navigationBold: rem(lineHeightsInPixels.navigationBold),
  title1: rem(lineHeightsInPixels.title1),
  title2: rem(lineHeightsInPixels.title2),
  title3: rem(lineHeightsInPixels.title3),
  buttonTitle: rem(lineHeightsInPixels.buttonTitle),
};
