import styled from "styled-components";

import { Text } from "/component/base";
import { getVariantStyle, VariantStyleProps } from "/component/base/Button/Button.styles";
import Map from "/component/base/Map";
import { layout } from "/styles";
import { ThemeType } from "/theme/default";

export const MapStyled = styled(Map)(({ theme }) => ({
  marginTop: 40,
  marginBottom: 40,
  [theme.media.max.mobile]: {
    marginTop: 0,
    marginBottom: 32,
  },
}));

export const ContentContainer = styled.div(({ theme }) => ({
  display: "flex",
  [theme.media.max.tabletPortrait]: {
    display: "flex",
    flexDirection: "column-reverse",
  },
}));

export const DetailsContainer = styled.div(({ theme }) => ({
  flex: "50%",
  [theme.media.max.tabletPortrait]: {
    marginTop: theme.spacing.gutter,
  },
}));

export const AddressContainer = styled.div(
  layout.flexVertical,
  layout.margin("standard", "skip", "skip", "skip"),
);

export const SectionHeader = styled(Text).attrs({
  color: "textPrimary",
  variant: "body1Bold",
  element: "h3",
})(layout.margin("skip", "skip", "base"));

export const VerticalContainer = styled.div(
  layout.flexVertical,
  layout.margin("gutter", "skip", "skip", "skip"),
);

export const ServiceText = styled.li(({ theme }) => ({
  color: theme.colors.textPrimary,
  paddingRight: theme.spacing.standard,
  paddingTop: theme.spacing[12],
}));

export const ButtonsContainer = styled.div(({ theme }) => ({
  marginTop: theme.spacing.standard,
  [theme.media.tabletPortrait]: {
    ...layout.flexStartHorizontal,
  },
}));

export const UrgentCareButton = styled.a<ThemeType & VariantStyleProps & { $isLast?: boolean }>(
  layout.flexCenterHorizontal,
  ({ theme, variant }) => getVariantStyle({ theme, $variant: variant || "primary" }),
  ({ theme }) => ({
    justifyContent: "center",
    textDecoration: "none",
    paddingRight: theme.spacing.gutter,
    paddingLeft: theme.spacing.gutter,
  }),
);

export const LiveImage = styled.img(layout.margin("skip", "skip", "skip", "condensed"), {
  height: 22,
});
