import React from "react";

import Text from "/component/base/Text";

import { Base } from "./InlineMessage.styles";
import { Props } from "./InlineMessage.types";

const InlineMessage = ({
  className,
  children,
  icon,
  iconPosition = "left",
  variant = "primary",
}: Props) => {
  return (
    <Base className={className} variant={variant}>
      {iconPosition === "left" && icon}

      <Text variant="body2" color="inherit">
        {children}
      </Text>

      {iconPosition === "right" && icon}
    </Base>
  );
};

export default InlineMessage;
