export const actionTypes = {
  FAILURE: "FAILURE",
  INIT: "INIT",
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
} as const;

export type Actions<T> =
  | { type: typeof actionTypes.FAILURE }
  | { type: typeof actionTypes.INIT }
  | { type: typeof actionTypes.LOADING }
  | {
      type: typeof actionTypes.SUCCESS;
      payload: T;
    };

export type State<T> = {
  data?: T;
  error: boolean;
  loading: boolean;
};
