import { withAITracking } from "@microsoft/applicationinsights-react-js";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as html2pdf from "html2pdf.js";
import { Base64 } from "js-base64";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import client from "/apollo/client";
import updateDocumentReadStatusMutation from "/apollo/mutation/updateDocumentReadStatus";
import { UpdateDocumentReadStatus_updateDocumentReadStatus } from "/apollo/schema";
import {
  DocumentType,
  GetDocuments_viewer_user_patient_documents as Document,
  GetDocuments_viewer_user_patient_documents,
  UpdateDocumentReadStatusVariables,
} from "/apollo/schema/types";
import noResultsFound from "/asset/image/noResultsFound.png";
import { Text } from "/component/base";
import MainContent from "/component/layout/MainContent";
import DocumentCard from "/component/partial/DocumentCard";
import EmptyStateCta from "/component/partial/EmptyStateCta";
import SegmentedControl from "/component/partial/SegmentedControl";
import { useDocumentProvider } from "/component/provider/DocumentProvider";
import { useToastContext } from "/component/provider/ToastProvider";
import { useTranslation } from "/hook";
import { useAnalytics } from "/hook/useAnalytics/useAnalytics";
import { layout } from "/styles";
import { reactPlugin } from "/util/appInsights.util";
import { replaceHtmlImgTag } from "/util/documents.util";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  FileDownloadParams,
  logEvent,
} from "/util/firebase.util";

import { useQueryDocument } from "../Documents/useQueryDocument";
import { CaregiverItemCard, DocsWrapper, SegmentWrapper } from "./Documents.styles";

const IMAGE_HEIGHT = 229;
const IMAGE_WIDTH = 331;

const Documents = () => {
  const { t } = useTranslation("documents");
  const { showToast } = useToastContext();

  const { updateDocumentReadStatus: updateReadStatus, documents } = useDocumentProvider();

  const careSummaries = documents.filter((x) => x.type === DocumentType.VISIT_SUMMARY);
  const medicalForms = documents.filter((x) => x.type !== DocumentType.VISIT_SUMMARY);

  const newMedicalFormsAvailable = medicalForms.filter((x) => !x.isRead).length > 0;
  const newCareSummariesAvailable = careSummaries.filter((x) => !x.isRead).length > 0;

  const [documentType, setDocumentType] = useState<string | undefined>("careSummaries");
  const [documentsData, setDocumentsData] = useState<Document[]>([]);
  const { recordAnalyticsEvent } = useAnalytics();

  const menuItems = [
    {
      value: "careSummaries",
      label: t("segmentedControl.careSummaries"),
      isBadge: newCareSummariesAvailable,
    },
    {
      value: "medicalForms",
      label: t("segmentedControl.medicalForms"),
      isBadge: newMedicalFormsAvailable,
    },
  ];

  useEffect(() => {
    recordAnalyticsEvent("viewDocuments", { sharedModule: false });
  }, [recordAnalyticsEvent]);

  useEffect(() => {
    if (documentType === "careSummaries") {
      setDocumentsData(careSummaries);
    } else {
      setDocumentsData(medicalForms);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentType, documents]);

  const handleItemSelected = (type: string | undefined) => {
    setDocumentType(type);

    if (type === "careSummaries") {
      setDocumentsData(careSummaries);
    } else {
      setDocumentsData(medicalForms);
    }
  };

  const [queryDocument, { data: documentData, variables: documentVariables }] = useQueryDocument({
    onCompleted: () => {
      logEvent(AnalyticsEvent.FILE_DOWNLOAD, {
        user_flow: AnalyticsUserFlow.MY_HEALTH,
        source: AnalyticsSource.DOCUMENTS,
        document_id: documentVariables?.id,
        file_name: documentData?.document?.title,
        file_type: documentData?.document?.contentType,
      } as FileDownloadParams);

      showDocument();
    },
    onError: () => {
      handleDocumentError();
    },
  });

  const handlePressDocument = (document: GetDocuments_viewer_user_patient_documents) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.MY_HEALTH,
      source: AnalyticsSource.DOCUMENTS,
      button_name: "Document",
    } as ButtonClickParams);

    recordAnalyticsEvent("viewDocument", { sharedModule: false });

    if (documentVariables?.id === document.documentId) {
      showDocument();
    } else {
      queryDocument({
        variables: {
          id: document.documentId,
        },
      });
    }
    if (!document.isRead) {
      updateDocumentReadStatus(document);
      updateReadStatus(document.documentId);
    }
  };

  const showDocument = useCallback(async () => {
    if (!documentData) {
      handleDocumentError();
      return;
    }

    // construct the filename from the title
    const documentTitle = documentData.document?.title || "";

    if (documentData.document?.contentType === "application/pdf") {
      const downloadLink = document.createElement("a");
      downloadLink.href = `data:${documentData.document?.contentType};base64,${documentData.document?.file}`;
      downloadLink.download = `${documentTitle}.pdf`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      downloadLink.remove();
    } else if (
      documentData.document?.contentType === "text/html" ||
      documentData.document?.contentType === "text/plain"
    ) {
      // decode base64 data
      let htmlString = Base64.decode(documentData.document?.file || "");

      // replace unknown img with SummitHealth logo
      htmlString = replaceHtmlImgTag(htmlString);

      html2pdf()
        .set({
          margin: 10,
          html2canvas: { scale: 2, width: 825 },
        })
        .from(htmlString)
        .toPdf()
        .save(documentTitle)
        .catch(() => {
          handleDocumentError();
        });
    } else {
      handleDocumentError();
    }
  }, [documentData, showToast]);

  const handleDocumentError = () => {
    showToast({
      message: t("documentLoadError"),
      icon: "alert",
      type: "error",
    });
  };

  const updateDocumentReadStatus = async (document: GetDocuments_viewer_user_patient_documents) => {
    if (document.documentNumber && document.enteredAtCode) {
      await client.mutate<
        UpdateDocumentReadStatus_updateDocumentReadStatus,
        UpdateDocumentReadStatusVariables
      >({
        mutation: updateDocumentReadStatusMutation,
        variables: {
          input: {
            documentNumber: document.documentNumber,
            enteredAtCode: document.enteredAtCode,
          },
        },
      });
    }
  };

  const showEmptyState =
    (documentType === "careSummaries" && careSummaries.length === 0) ||
    (documentType === "medicalForms" && medicalForms.length === 0);

  return (
    <MainContent pageTitle={t("pageTitle")} parentPage="myHealth">
      <Helmet>
        <title>{t("pageTitle")}</title>
      </Helmet>

      <Text variant="body1" color="textSecondary">
        {t("subtitle")}
      </Text>
      <SegmentWrapper>
        <SegmentedControl
          css={layout.margin("expanded", "none", "none", "none")}
          onItemSelected={handleItemSelected}
          options={menuItems}
          value={documentType}
        />
      </SegmentWrapper>
      <DocsWrapper>
        {showEmptyState && (
          <div css={layout.flexVerticalCenter}>
            <EmptyStateCta
              imageHeight={IMAGE_HEIGHT}
              imageWidth={IMAGE_WIDTH}
              imageSource={noResultsFound}
              title={t("emptyState.title")}
              subtitle={t("emptyState.visitDocs.subtitle")}
            />
          </div>
        )}
        <ul css={layout.spacedChildrenVertical()}>
          {documentsData?.map((docs) => {
            return (
              <CaregiverItemCard onClick={() => handlePressDocument(docs)} showChevron>
                <DocumentCard document={docs} />
              </CaregiverItemCard>
            );
          })}
        </ul>
      </DocsWrapper>
    </MainContent>
  );
};

export default withAITracking(reactPlugin, Documents, undefined, "ai-tracking");
