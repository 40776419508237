import { useReactiveVar } from "@apollo/client";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { RouteComponentProps } from "react-router";

import { address } from "/apollo/client/local";
import { Button, Text } from "/component/base";
import Loading from "/component/base/Loading";
import MainContent from "/component/layout/MainContent";
import AddressModal from "/component/modal/AddressModal";
import { useAmwellPrevisitContext } from "/component/provider/AmwellPrevisitProvider";
import routes from "/constant/url.constant";
import { useAmwellLocation, useTranslation } from "/hook";
import { reactPlugin } from "/util/appInsights.util";
import { AnalyticsUserFlow, ScreenViewParams } from "/util/firebase.util";

import { ButtonContainer, ProviderListContainer } from "./TelehealthLocationVerification.styles";
import TelehealthProviderCard from "./TelehealthProviderCard";

const TelehealthLocationVerification = ({ history, location }: RouteComponentProps) => {
  const { t } = useTranslation("telehealth");
  const storedAddress = useReactiveVar(address.var);
  const [isAddressModalOpen, setAddressModalOpen] = useState(!storedAddress.state);
  const close = () => setAddressModalOpen(false);
  const { provider: currentProvider } = useAmwellPrevisitContext();

  const { data: country } = useAmwellLocation();

  //Retrieve state information from Amwell
  const stateName =
    country?.states.find(
      (state) => state.code === storedAddress.state || state.name === storedAddress.city,
    )?.name || storedAddress.state;

  const handleLocationVerified = () => {
    history.push(routes.telehealthPrevisit, location.state);
  };

  const handleAddressChange = () => {
    history.goBack();
  };

  const renderVerification = () => (
    <>
      <ProviderListContainer>
        {currentProvider && <TelehealthProviderCard provider={currentProvider} />}
      </ProviderListContainer>

      <ButtonContainer>
        <Button onClick={handleLocationVerified} variant="primary">
          {t("locationVerification.yes", { state: storedAddress.state })}
        </Button>
        <Button onClick={() => setAddressModalOpen(true)} variant="secondary">
          {t("locationVerification.no")}
        </Button>
      </ButtonContainer>
    </>
  );

  const showLoader = !currentProvider;

  return (
    <MainContent
      parentPage={"telehealth"}
      pageTitle={t("locationVerification.headline", { state: stateName })}
    >
      <Helmet>
        <title>{t("locationVerification.title")}</title>
      </Helmet>

      <Text color="textSecondary" variant="body2">
        {t("locationVerification.regulatoryReason")}
      </Text>
      {showLoader && <Loading />}
      {!showLoader && renderVerification()}

      <AddressModal
        handleDone={handleAddressChange}
        dismissable={!!storedAddress.state}
        isOpen={isAddressModalOpen}
        close={close}
        analyticsParams={
          {
            user_flow: AnalyticsUserFlow.GET_VIRTUAL_URGENT_CARE,
            source: "TelehealthLocationCatch",
          } as ScreenViewParams
        }
      />
    </MainContent>
  );
};

export default withAITracking(
  reactPlugin,
  TelehealthLocationVerification,
  undefined,
  "ai-tracking",
);
