import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
// translations are already at
// '../public/locales/en/translation.json'
// which is the default for the xhr backend to load from
i18n
  .use(detector)
  .use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: "en",
    nsSeparator: "-",
    fallbackLng: "en", // use en if detected lng is not available
    react: {
      useSuspense: true,
      wait: true,
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

// Explicitly load namespaces for use outside React components
// https://github.com/i18next/react-i18next/issues/909#issuecomment-514604843
i18n.loadNamespaces([
  "dateUtil",
  "geolocation",
  "notifications",
  "schedulingInputs",
  "facilityUtil",
]);

export default i18n;
