import gql from "graphql-tag";
import React from "react";

import { formatText } from "/util";

import { Avatar, Button, Name, ProviderListItem } from "./ProviderAvatarList.styles";
import { Props } from "./ProviderAvatarList.types";

const ProviderAvatarList = ({ className, providers, onProviderClick, ...rest }: Props) => {
  return (
    <ul className={className} {...rest}>
      {providers.map((provider) => (
        <ProviderListItem key={provider.id}>
          <Button
            aria-label={provider.nameWithDesignations}
            onClick={onProviderClick ? () => onProviderClick(provider) : undefined}
            variant="unstyled"
          >
            <Avatar
              alt={provider.nameWithDesignations}
              initials={formatText.parseInitials(provider?.name)}
              size="large"
              src={provider.photoUrl}
            />
            <Name variant="navigation" color="textSecondary" element="div">
              {provider.nameWithDesignations}
            </Name>
          </Button>
        </ProviderListItem>
      ))}
    </ul>
  );
};

export const ProviderAvatarListFragment = gql`
  fragment ProviderAvatarListFragment on Provider {
    athenaId
    id
    nameWithDesignations
    photoUrl
    name {
      firstName
      lastName
    }
  }
`;

export default ProviderAvatarList;
