import { gql, QueryResult } from "@apollo/client";

import { GetEncountersSummit, GetEncountersSummitVariables } from "../../b2c/schema/types";
import { useB2CQuery } from "../client";

const encountersQuerySummit = gql`
  query GetEncountersSummit($limit: Int, $offset: Int) {
    patient {
      summaryCareRecords: letters(
        limit: $limit
        offset: $offset
        letterType: LETTER_SUMMARYCARERECORD
      ) {
        totalCount
        items {
          letterId
          createdDatetime
          html
          letterText
          paperformName
          documentSubclass
          provider {
            firstName
            lastName
            displayName
          }
        }
      }
      letterEpisodes: letters(limit: $limit, offset: $offset, letterType: LETTER_EPISODE) {
        totalCount
        items {
          letterId
          createdDatetime
          html
          letterText
          paperformName
          documentSubclass
          provider {
            firstName
            lastName
            displayName
          }
        }
      }
      patientsCareSummaries: letters(
        limit: $limit
        offset: $offset
        letterType: LETTER_PATIENTCARESUMMARY
      ) {
        totalCount
        items {
          letterId
          createdDatetime
          html
          letterText
          paperformName
          documentSubclass
          provider {
            firstName
            lastName
            displayName
          }
        }
      }
    }
  }
`;

export const useEncountersSummit = ({ limit = 150, offset = 0 } = {}): QueryResult<
  GetEncountersSummit,
  GetEncountersSummitVariables
> =>
  useB2CQuery<GetEncountersSummit, GetEncountersSummitVariables>(encountersQuerySummit, {
    variables: { limit, offset },
    notifyOnNetworkStatusChange: true,
  });
