import { useMutation } from "@apollo/client";
import { omit } from "lodash";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { RouteComponentProps } from "react-router";

import { auth } from "/apollo/client/local";
import {
  CaregiverAccessDetails_viewer_user_caregiverAccess,
  CaregiverAccessDetails_viewer_user_caregivers,
  CaregiverStatus,
  DeleteCaregiverAccess,
  DeleteCaregiverAccessVariables,
  GetCaregiverAccess_viewer_user_caregiverAccess,
} from "/apollo/schema";
import { Button, ListItem, Loading, Text } from "/component/base";
import MainContent from "/component/layout/MainContent";
import CaregiverRemoveModal from "/component/modal/CaregiverRemoveModal";
import {
  CaregiverContactType,
  CaregiverStatusType,
  CaregiverType,
  useCaregiverProvider,
} from "/component/provider/CaregiverProvider";
import { useToastContext } from "/component/provider/ToastProvider";
import routes from "/constant/url.constant";
import { useAmwell, useTranslation } from "/hook";
import { layout } from "/styles";
import { addYears, formatDate, removeOffset } from "/util/date";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  logEvent,
} from "/util/firebase.util";

import deleteCaregiverAccessGql from "../../deleteCaregiverAccess";
import { useCaregiverAccess } from "../../useCaregiverAccess";
import { CaregiverDetailContainer } from "./CaregiverDetails.styles";
import { CaregiverDetailItemProps } from "./CaregiverDetails.types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CaregiverDetails = ({ history, location }: RouteComponentProps<{ data: any }>) => {
  const { t } = useTranslation("page-Caregiver-CaregiverDetails");

  const state = location.state as {
    data: CaregiverAccessDetails_viewer_user_caregivers &
      CaregiverAccessDetails_viewer_user_caregiverAccess;
    allowSwitch: boolean;
  };
  const data = state?.data || {};
  const allowSwitch = state?.allowSwitch || false;

  const [loading, setLoading] = useState(true);
  const [isSwitching, setIsSwitching] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  // const address = formatAddress(data.address);

  const { showToast } = useToastContext();
  const { caregiverMode, setCaregiver, setCaregiverToggle } = useCaregiverProvider();
  const { refetch } = useCaregiverAccess();

  const { renewAuth, toggleDependent } = useAmwell();

  // prevent 1 off issue by removing the TZ offset
  const birthDate = removeOffset(data.birthDate) || "";
  const acceptedDate = removeOffset(data.statusDetails?.acceptedDate) || "";

  const [deleteCaregiverAccessMutation] = useMutation<
    DeleteCaregiverAccess,
    DeleteCaregiverAccessVariables
  >(deleteCaregiverAccessGql);

  const logButtonClickEvent = (buttonName: string) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.CAREGIVER,
      source: AnalyticsSource.CAREGIVER_ACCESS_DETAILS,
      button_name: buttonName,
    } as ButtonClickParams);
  };

  useEffect(() => {
    if (!location.state) {
      history.replace(routes.caregiver);
    } else {
      setLoading(false);
    }
  }, []);

  const switchCaregiver = async (user: GetCaregiverAccess_viewer_user_caregiverAccess) => {
    setIsSwitching(true);
    logButtonClickEvent("Switch Account");

    // switch from caregiver adult to caregiver adult, need reauth
    if (!user.isMinor) {
      await renewAuth();
    } else {
      // switch from caregiver adult to child dependent, we need to reauth with the main account
      // otherwise we are just switching from child to child or main account to child
      if (data.id && !data.isMinor) {
        await renewAuth();
      }

      // switch the consumer object context on Amwell side by matching dependent info
      await toggleDependent({
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        dob: new Date(user.birthDate),
        gender: user.gender || "",
      });
    }
    const _caregiver = omit(user, "__typename") as CaregiverType;
    _caregiver.contact = omit(user.contact, "__typename") as CaregiverContactType;
    _caregiver.statusDetails = omit(user.statusDetails, "__typename") as CaregiverStatusType;
    setCaregiver(_caregiver);
    setCaregiverToggle(true);
    auth.setCaregiver(user.id);

    setTimeout(() => {
      setIsSwitching(false);
      history.push(routes.root);
    }, 500);
  };

  const CaregiverDetailItem = ({ label, value = "" }: CaregiverDetailItemProps) => (
    <ListItem.Div showChevron={false}>
      <CaregiverDetailContainer>
        <Text variant="body1">{label}</Text>
        <Text variant="body1" color="textSecondary">
          {value}
        </Text>
      </CaregiverDetailContainer>
    </ListItem.Div>
  );

  const removeProxy = async () => {
    if (!data.id) return;
    const isCancelled = data.isMinor || data.statusDetails.status === CaregiverStatus.INVITED;
    const response = await deleteCaregiverAccessMutation({
      variables: {
        data: {
          caregiverAccessId: data.id,
          isCancelled,
        },
      },
    });
    if (response.errors?.length || response.data?.deleteCaregiverAccess?.errors?.length) {
      showToast({
        message: t("error"),
        icon: "alert",
      });
    } else {
      showToast({
        message: t("success"),
        icon: "alert",
      });
    }
    refetch();
    history.push(routes.caregiver);
  };

  // const _renderTelecom = (
  //   <>
  //     {/* Caregiver contact information */}
  //     <Text
  //       css={layout.margin("expanded", "none", "none", "none")}
  //       variant="body2Bold"
  //       color="textSecondary"
  //     >
  //       {t("contactInfo")}
  //     </Text>
  //     <CaregiverDetailItem label={t("phone")} value={formatPhone(data.contact?.phone)} />
  //     <CaregiverDetailItem label={t("email")} value={data.contact?.email} />
  //   </>
  // );

  // const _renderAddress = (
  //   <>
  //     <Text
  //       css={layout.margin("expanded", "none", "none", "none")}
  //       variant="body2Bold"
  //       color="textSecondary"
  //     >
  //       {t("addresses")}
  //     </Text>
  //     <CaregiverDetailItem label={t("addressType")} value={address} />
  //   </>
  // );

  if (loading) return <Loading />;

  return (
    <MainContent parentPage="caregiver" pageTitle={`${data.firstName} ${data.lastName}`}>
      <Helmet>
        <title>{t("title")}</title>
      </Helmet>
      <div css={layout.flexVertical}>
        {/* Caregiver information */}

        <CaregiverDetailItem label={t("dob")} value={formatDate(birthDate, "LLLL dd, yyyy")} />
        <CaregiverDetailItem
          label={t("acceptedDate")}
          value={formatDate(acceptedDate, "LLLL dd, yyyy")}
        />
        {data.isMinor && data.statusDetails.acceptedDate && (
          <CaregiverDetailItem
            label={t("expirationDate")}
            value={formatDate(
              addYears(data.birthDate, 18), // prevent 1 off issue by removing the TZ offset
              "MMM dd, yyyy",
            )}
          />
        )}

        <div>
          {!caregiverMode && (
            <Button
              onClick={() => {
                logButtonClickEvent("Remove caregiver");
                setShowDeleteModal(true);
              }}
              variant="borderBottomSecondary"
              css={layout.margin("expanded", "none", "none", "none")}
            >
              {t("removeProxy")}
            </Button>
          )}
        </div>
        {!caregiverMode && allowSwitch && (
          <Button
            onClick={() => {
              switchCaregiver(data);
            }}
            css={layout.margin("expanded", "none", "none", "none")}
            fullWidth="percent"
            isLoading={isSwitching}
            isDisabled={isSwitching}
          >
            {t("switchAccount")}
          </Button>
        )}
      </div>
      <CaregiverRemoveModal
        isOpen={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={removeProxy}
      />
    </MainContent>
  );
};

export default CaregiverDetails;
