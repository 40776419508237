import awsdk from "awsdk";
import { getDaysInMonth, isPast } from "date-fns";
import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";

import Button from "/component/base/Button";
import Divider from "/component/base/Divider";
import Modal from "/component/base/Modal";
import FormContent from "/component/partial/FormContent";
import TextField from "/component/partial/formik/TextField";
import { useTranslation } from "/hook";
import { layout } from "/styles";

import { PaymentMethod } from "./TelehealthPaymentModal.types";

interface Props {
  close: () => void;
  isOpen: boolean;
  paymentMethod: awsdk.AWSDKPaymentMethod | undefined;
  handleSubmit: (payment: PaymentMethod) => void;
  cost: string;
}

const TelehealthPaymentModal = ({ isOpen, close, handleSubmit, paymentMethod, cost }: Props) => {
  const { t } = useTranslation("modal-telehealthPayment");

  const initialValues = {
    billingName: paymentMethod?.billingName ? paymentMethod?.billingName : "",
    cardNumber: "",
    expirationDate: "",
    securityCode: "",
    address1: paymentMethod?.billingAddress.address1 ? paymentMethod?.billingAddress.address1 : "",
    address2: paymentMethod?.billingAddress.address2 ? paymentMethod?.billingAddress.address2 : "",
    city: paymentMethod?.billingAddress.city ? paymentMethod?.billingAddress.city : "",
    state: paymentMethod?.billingAddress.stateCode ? paymentMethod?.billingAddress.stateCode : "",
    zip: paymentMethod?.billingAddress.zipCode ? paymentMethod?.billingAddress.zipCode : "",
  };

  const removeWhitespace = (value: string, originalValue: string) =>
    originalValue.replace(/\s/g, "");

  const schema = Yup.object().shape({
    billingName: Yup.string().required(t("error.required")),
    /*
      Amwell supported card types - regex - cvvLength:
        Amex (amex) - ^3[47]\d{13}$ - cvvLength = 4
        Discover (discover) - ^((6011\d{15})|(6011\d{12})|(65\d{14}))$ - cvvLength = 3
        MasterCard (master) - ^((5[1-5])|(2[2-7]))((\d{14})|(\d{17}))$ - cvvLength = 3
        Visa (visa) - ^((4\d{18})|(4\d{15})|(4\d{12}))$ - cvvLength = 3
    */
    cardNumber: Yup.string()
      .transform(removeWhitespace)
      .matches(
        /(3[47]\d{13})|((6011\d{15})|(6011\d{12})|(65\d{14}))|((5[1-5])|(2[2-7]))((\d{14})|(\d{17}))|((4\d{18})|(4\d{15})|(4\d{12}))/,
        { message: t("error.creditCardInvalid") },
      )
      .max(19, t("error.creditCardInvalid"))
      .required(t("error.required")),
    expirationDate: Yup.string()
      .matches(/\d{2,2}\/\d{2,2}/, { message: t("error.expirationDateInvalid") }) // i.e. MM/YY
      .test("expirationDateTest", t("error.expirationDateExpired"), (date) => {
        if (date) {
          const month = parseInt(date.split("/")[0], 10) - 1; // convert 01/21 into 0
          const year = parseInt("20" + date.split("/")[1], 10); // convert 01/21 into 2021
          const daysInMonth = getDaysInMonth(new Date(year, month));
          const _expirationDate = new Date(year, month, daysInMonth);
          return !isPast(_expirationDate);
        }
        return true;
      })
      .required(t("error.required")),
    securityCode: Yup.number().typeError(t("error.nan")).required(t("error.required")),
    address1: Yup.string().required(t("error.required")),
    address2: Yup.string().nullable(),
    city: Yup.string().required(t("error.required")),
    state: Yup.string().required(t("error.required")),
    zip: Yup.string().required(t("error.required")),
  });

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {({ isValid }) => {
        return (
          <Form id="telehealth_payment">
            <Modal
              isOpen={isOpen}
              key="PaymentModal"
              close={close}
              header={
                <Modal.Header
                  aria-label={t("title", { cost })}
                  title={t("title", { cost })}
                  close={close}
                />
              }
              footer={
                <Modal.FooterWithLargeButton>
                  <Button
                    disabled={!isValid}
                    type="submit"
                    form="telehealth_payment"
                    variant="primary"
                    css={{ width: "100%" }}
                  >
                    {t("updateCreditCard")}
                  </Button>
                </Modal.FooterWithLargeButton>
              }
            >
              <FormContent>
                <TextField label={t("label.name")} name="billingName" type="text" />
                <TextField label={t("label.cardNumber")} name="cardNumber" type="text" />
                <div
                  css={[layout.flexSpaceHorizontal, layout.spacedChildrenHorizontal("condensed")]}
                >
                  <TextField
                    css={{ flex: 1 }}
                    label={t("label.expirationDate")}
                    placeholder={t("placeholder.expirationDate")}
                    name="expirationDate"
                    type="text"
                    maxLength={5}
                  />
                  <TextField
                    css={{ flex: 1 }}
                    label={t("label.securityCode")}
                    name="securityCode"
                    type="number"
                  />
                </div>

                <Divider />
                <TextField label={t("label.line1")} name="address1" type="text" />
                <TextField label={t("label.line2")} name="address2" type="text" />
                <div
                  css={[layout.flexSpaceHorizontal, layout.spacedChildrenHorizontal("condensed")]}
                >
                  <TextField css={{ flex: 2 }} label={t("label.city")} name="city" type="text" />
                  <TextField css={{ flex: 1 }} label={t("label.state")} name="state" type="text" />
                  <TextField
                    css={{ flex: 1 }}
                    label={t("label.postalCode")}
                    name="zip"
                    type="number"
                  />
                </div>
              </FormContent>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default TelehealthPaymentModal;
