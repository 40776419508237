// Modules
import { clamp } from "lodash";
import React from "react";

import BackButton from "/component/partial/BackButton";

// Styles
import { ProgressBarContainer, ProgressBarFill, ProgressBarMain } from "./ProgressBar.styles";
import { Props } from "./ProgressBar.types";

const ProgressBar = ({ icon = "back", completedScreens = 0, totalScreens = 1, close }: Props) => {
  const widthPercentage = clamp((completedScreens / totalScreens) * 100, 0, 100);

  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    setValue(widthPercentage);
  }, [widthPercentage]);

  return (
    <ProgressBarContainer>
      {close && (
        <BackButton
          css={{ backgroundColor: "transparent" }}
          onClick={close}
          variant="primary"
          icon={icon}
        />
      )}
      <ProgressBarMain>
        <ProgressBarFill css={{ width: `${value}%` }} />
      </ProgressBarMain>
    </ProgressBarContainer>
  );
};

export default ProgressBar;
