import styled from "styled-components";

import Text from "/component/base/Text";
import BackButtonBase from "/component/partial/BackButton";
import { layout } from "/styles";

export const Base = styled.div(({ theme }) => ({
  flex: "1 1 auto",
  minHeight: "100vh",
  position: "relative",

  // Account for width of Nav for tablet screens (icon only)
  [theme.media.range.medium]: {
    marginLeft: theme.sizes.navSmall,
  },

  // Account for width of Nav for desktop screens (icon & label)
  [theme.media.desktop]: {
    marginLeft: theme.sizes.nav,
  },

  // When the screen size is really big we want to center the MainContent in the screen's entire
  // viewport, as if the left navigation had `position: absolute`. To accomplish this apply a
  // marginRight equal to the width of the navbar, and keep the content centered in the Base.
  [theme.media.superwide]: {
    marginLeft: theme.sizes.nav,
  },
}));

export const ContentColumn = styled.div<{ hasBackButton: boolean }>(({ hasBackButton, theme }) => {
  const mobilePaddingTop = theme.spacing.base * 6 + theme.sizes.mobileHeader;
  const tabletPaddingTop = hasBackButton ? theme.spacing[40] : theme.spacing.base * 24;

  return {
    // On screens smaller than `tablet` the ContentColumn will take up all available
    // horizontal space
    maxWidth: "100vw",
    padding: `${mobilePaddingTop}px ${theme.spacing.gutter}px`,
    margin: "0 auto",

    [theme.media.tabletPortrait]: {
      maxWidth: `calc(100vw - ${theme.sizes.navSmall}px)`,
      paddingTop: theme.spacing.base * 8,
    },

    // After `tablet` the column will have a width and will sit in the center of `Base`
    [theme.media.tablet]: {
      padding: `${tabletPaddingTop}px 0`,
      width: theme.sizes.mainContent,
      maxWidth: theme.sizes.mainContent,
    },
  };
});

export const Header = styled.header(({ theme }) => ({
  marginBottom: theme.spacing.standard,
  ...layout.flexVertical,

  [theme.media.tabletPortrait]: {
    marginBottom: theme.spacing.expanded,
  },

  [theme.media.tablet]: {
    marginBottom: theme.spacing[40],
    flexDirection: "row",
    alignItems: "center",
  },
}));

export const Title = styled(Text)(({ theme }) => ({
  order: 2,
  color: theme.colors.textTitle,

  [theme.media.tablet]: {
    order: 1,
    flex: "1 1 auto",
  },
}));

export const AccessoryWrapper = styled.div<{ $renderBelowTitleOnMobile: boolean }>(
  ({ theme, $renderBelowTitleOnMobile }) => ({
    // Change the order to be greater than tht Title order to render the button below the title
    order: $renderBelowTitleOnMobile ? 3 : 1,

    // When vertically stacked add some space between the accessory and title, but only add the
    // margin if there is a title
    [`${Title} + &`]: {
      marginBottom: $renderBelowTitleOnMobile ? 0 : theme.spacing.gutter,
      marginTop: $renderBelowTitleOnMobile ? theme.spacing.gutter : 0,
    },

    [theme.media.tablet]: {
      order: 2,

      [`${Title} + &`]: {
        marginBottom: 0,
        marginTop: 0,
      },
    },
  }),
);

export const BackButton = styled(BackButtonBase)(({ theme }) => ({
  marginBottom: theme.spacing.gutter,

  [theme.media.tablet]: {
    marginBottom: theme.spacing.base * 8,
  },
}));
