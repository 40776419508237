import * as React from "react";

import Text from "/component/base/Text";
import { useTranslation } from "/hook";

import { Base, ContactUsLink } from "./ContactUs.styles";
import { Props } from "./ContactUs.types";

const ContactUs = ({ email, label, className }: Props) => {
  const { t } = useTranslation("partial-contactUs");

  const defaultSupportEmail = `AppSupport@summithealth.com`;

  return (
    <Base className={className}>
      <Text variant="body1" color="textSecondary">
        {label || t("title")}
      </Text>

      <ContactUsLink target="#" href={`mailto:${email || defaultSupportEmail}`}>
        <Text color="brandPrimary" variant="body1Bold">
          {t("contactUsText")}
        </Text>
      </ContactUsLink>
    </Base>
  );
};

export default ContactUs;
