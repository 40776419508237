import React, { createContext, useContext, useEffect, useRef } from "react";

type Listener = (willRedirect: boolean) => void;

type ContextValue = {
  addListener: (fn: Listener) => void;
  removeListener: (fn: Listener) => void;
  dispatch: Listener;
};

const RedirectListenerProviderContext = createContext<ContextValue>({
  addListener: () => null,
  removeListener: () => null,
  dispatch: () => null,
});

/**
 * `useRedirectListener` allows a component to subscribe to the result of the navigation Prompt, if
 * they need to, for example, do some cleanup when the user confirms navigation. There may be many
 * concurrent subscriptions, and each one will be called with confirmation state.
 *
 * ### Usage:
 * ```js
 * useRedirectListener((willRedirect) => {
 *   if (willRedirect) {
 *     cleanupState()
 *   }
 * })
 * ```
 */
export const useRedirectListener = (fn: Listener) => {
  const { addListener, removeListener } = useContext(RedirectListenerProviderContext);

  useEffect(() => {
    addListener(fn);

    return () => {
      removeListener(fn);
    };
  }, [fn]);
};

/**
 * **Warning:** `useRouterDispatch` should really only ever be used by the BrowserRouter in AppRoot.
 * You should probably never use this hook outside of that.
 */
export const useRouterDispatch = () => {
  const { dispatch } = useContext(RedirectListenerProviderContext);
  return { dispatch };
};

const RedirectListenerProvider = ({ children }: { children?: React.ReactNode }) => {
  // Store all of our listeners in a ref array
  const listeners = useRef<Listener[]>([]);

  /**
   * Push a new listener onto the listeners array, but only if it doesn't already exist
   */
  const addListener = (listener: Listener) => {
    if (!listeners.current.find((cb) => cb === listener)) {
      listeners.current = [...listeners.current, listener];
    }
  };

  /**
   * Remove a listener from the listeners array
   */
  const removeListener = (listener: Listener) => {
    listeners.current = listeners.current.filter((cb) => cb !== listener);
  };

  /**
   * Fire off the listener for each subscribed component with the result of the navigation prompt.
   */
  const dispatch = (willRedirect: boolean) => {
    listeners.current.forEach((cb) => cb(willRedirect));
  };

  return (
    <RedirectListenerProviderContext.Provider value={{ dispatch, addListener, removeListener }}>
      {children}
    </RedirectListenerProviderContext.Provider>
  );
};

export default RedirectListenerProvider;
