import { useReactiveVar } from "@apollo/client";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { Form, Formik } from "formik";
import React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { scheduling } from "/apollo/client/local";
// import { ReasonForVisit } from "/component/form/Telehealth";
import { Button } from "/component/base";
import MainContent from "/component/layout/MainContent";
import useHandleBackWhenBooking from "/component/page/Scheduling/hook/useHandleBackWhenBooking";
import FormContent from "/component/partial/FormContent";
import TextAreaField from "/component/partial/formik/TextAreaField";
import routes from "/constant/url.constant";
import { useTranslation } from "/hook";
import { layout } from "/styles";
import { reactPlugin } from "/util/appInsights.util";

import SchedulingPrompt from "../Availabilities/component/SchedulingPrompt";

const VisitReason = () => {
  const { t } = useTranslation("visitReason");
  const { reasonForVisit } = useReactiveVar(scheduling.var);
  const history = useHistory();

  const handleBack = useHandleBackWhenBooking();

  const handleSubmit = (values: { reason: string }) => {
    scheduling.update({
      reasonForVisit: values.reason,
    });
    history.push(`${routes.scheduling}${routes.confirm}`);
  };

  const formReasonSchema = Yup.object({
    reason: Yup.string().required("Required"),
  });

  return (
    <MainContent
      pageTitle={t("title")}
      backButton={<MainContent.BackButton onClick={() => handleBack()} />}
    >
      {/* <ReasonForVisit
        initialValues={{ reason: reasonForVisit || "" }}
        handleSubmit={(values) => values && handleSubmit(values.reason)}
        handleError={() => undefined}
        handleBack={() => undefined}
        // we handle this in the main content header
        showTitle={false}
      /> */}
      <Formik
        validateOnMount
        enableReinitialize
        initialValues={{ reason: reasonForVisit || "" }}
        validationSchema={formReasonSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <FormContent css={layout.margin("gutter", "skip")}>
              <div css={layout.margin(40, "skip", 40)}>
                <TextAreaField
                  rows={4}
                  label={t("reason")}
                  placeholder={t("placeholder")}
                  maxLength={230}
                  name="reason"
                />
              </div>

              <FormContent.ButtonGroup>
                <Button
                  variant="primary"
                  isDisabled={!isValid}
                  type="submit"
                  isLoading={isSubmitting}
                >
                  {t("continue")}
                </Button>
              </FormContent.ButtonGroup>
            </FormContent>
          </Form>
        )}
      </Formik>
      <SchedulingPrompt when={true} />
    </MainContent>
  );
};

export default withAITracking(reactPlugin, VisitReason, undefined, "ai-tracking");
