import { Dialog } from "@headlessui/react";
import React, { useState } from "react";

import { useModalContext } from "/component/provider/ModalProvider";
import Transition from "/component/util/Transition";
import { layout } from "/styles";

import { NavMenu } from "./MobileNavModal.styles";
import { Props } from "./MobileNavModal.types";

const MobileNavModal = ({ close, isOpen, isAuthenticated, logout }: Props) => {
  const { unshiftModalEl, removeModalEl } = useModalContext();
  const [lastModalEl, setLastModalEl] = useState<HTMLDivElement>();

  // Because this modal is not based on our root modal component, we must
  // manually set the current modalʼs containing element on the ModalProvider.
  // For more info, see `base/modal/modal.tsx`.
  const innerRef = (modalEl: HTMLDivElement) => {
    if (modalEl === null) {
      removeModalEl(lastModalEl);
    } else {
      unshiftModalEl(modalEl);
    }

    setLastModalEl(modalEl);

    return () => removeModalEl(lastModalEl);
  };

  return (
    <Transition show={isOpen}>
      <Dialog
        css={[layout.absoluteFill, { position: "fixed", zIndex: 1 }]}
        open={isOpen}
        onClose={() => close && close()}
        ref={innerRef}
      >
        <Transition.Child>
          <NavMenu onClose={close} isAuthenticated={isAuthenticated} logout={logout} />
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

export default MobileNavModal;
