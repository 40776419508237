import styled from "styled-components";

import Text from "/component/base/Text";
import { card, layout } from "/styles";

export const Container = styled.div(({ theme }) => ({
  marginTop: theme.spacing.gutter,
}));

export const CardContainer = styled.div(card.wrapper);

export const FaqCategoryWrapper = styled.div(layout.flexVertical, {
  textAlign: "left",
});

export const FaqItem = styled.div(layout.flexCenterHorizontal);

export const FaqText = styled(Text)(({ theme }) => ({
  paddingTop: theme.spacing.gutter,
  paddingBottom: theme.spacing.gutter,
}));

export const IconWrapper = styled.div(({ theme }) => ({
  marginRight: theme.spacing.standard,
  paddingTop: theme.spacing.base * 5,
  paddingBottom: theme.spacing.base * 5,
}));
