import styled from "styled-components";

import { card, layout, transition } from "/styles";

export const AccessoryWrapper = styled.div(layout.flexSpaceHorizontal, {
  flexGrow: 1,
});

export const HeaderContainer = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  [theme.media.max.tablet]: {
    justifyContent: "space-between",
  },
}));

export const UnreadBadge = styled.div(
  layout.margin("none", "standard"),
  layout.padding("base"),
  transition.enterFromRight,
  ({ theme }) => ({
    backgroundColor: theme.colors.brandSecondary,
    borderRadius: 8,
    paddingLeft: theme.spacing.base * 3,
    paddingRight: theme.spacing.base * 3,
  }),
);

export const ListContainer = styled.div(card.wrapper);

export const FullWidthAnchor = styled.a`
  width: 100%;
`;

export const MyAccountItem = styled.div(
  layout.flexCenterHorizontal,
  layout.padding(20, "skip"),
  layout.spacedChildrenHorizontal(),
  {
    flexGrow: 1,
  },
);
