import { onError } from "@apollo/client/link/error";

import { datadogLogs, datadogRum } from "./datadog";

const logErrorInDatadog = (message: string, error: Error) => {
  if (!(process.env.APP_ENV === "uat" || process.env.APP_ENV === "prod")) {
    return;
  }

  datadogLogs.logger.error(message, { error });
  datadogRum.addError(error);
};

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  const errors = [] as string[];

  if (graphQLErrors)
    graphQLErrors.forEach((error) => {
      const { message } = error;
      errors.push(`[GraphQL error]: ${message}`);
      logErrorInDatadog(`[GraphQL error]: ${message}`, error);
    });

  if (networkError) {
    errors.push(`[Network error]: ${networkError}`);
    logErrorInDatadog(`[Network error]: ${networkError}`, networkError);
  }

  console.log(errors.join("\n\n"));
});
