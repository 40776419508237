import awsdk from "awsdk";

import { useAmwell, useAmwellQuery } from "/hook";

/**
 * This hook allows for the retrieval list of medications
 *
 */

const useAmwellMedications = () => {
  const { consumer, sdk } = useAmwell();
  const fetch = async () =>
    sdk && consumer ? await sdk.consumerService.getMedications(consumer) : undefined;

  return useAmwellQuery<awsdk.AWSDKMedicationList>(fetch);
};

export default useAmwellMedications;
