import React from "react";

import Avatar from "/component/base/Avatar/Avatar";
import Text from "/component/base/Text/Text";

import * as Styled from "./Note.styles";

type Props = {
  avatar?: string | null;
  title?: string | null;
  subTitle?: string | null;
  body: string;
};

const Note = ({ avatar, title, subTitle, body }: Props) => {
  return (
    <Styled.Note>
      {title && (
        <Styled.Header>
          <Styled.Avatar>
            <Avatar alt={"Avatar"} src={avatar} size="small40" />
          </Styled.Avatar>
          <Styled.HeaderContent>
            {title && <Text variant="body1Bold">{title}</Text>}
            {subTitle && (
              <Text color="textSecondary" variant="body2">
                {subTitle}
              </Text>
            )}
          </Styled.HeaderContent>
        </Styled.Header>
      )}
      <Text>{body}</Text>
    </Styled.Note>
  );
};
export default Note;
