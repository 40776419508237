import styled from "styled-components";

import { getTextStyles } from "/component/base/Text/Text.styles";
import { layout } from "/styles";

import { DayVariant } from "./Calendar.types";

const CELL_SIZE = 36;

export const Base = styled.div({
  maxWidth: "max-content",
  position: "relative",
});

export const NavGroup = styled.div({
  flexDirection: "row",
  display: "flex",
  justifyContent: "space-between",
});

export const DaysOfWeek = styled.div(layout.margin("gutter", "skip", "skip"), {
  display: "grid",
  gridTemplateColumns: "repeat(7, 1fr)",
  gridTemplateRows: 20,
  gap: 2,
  justifyItems: "center",
  alignItems: "center",
});

export const CalendarGrid = styled.div<{ firstDayOfMonth?: number }>(
  layout.margin(12, "skip", "skip", "skip"),
  ({ firstDayOfMonth = 0 }) => ({
    display: "grid",
    gridTemplateColumns: "repeat(7, 1fr)",
    gap: 0,
    justifyItems: "center",
    alignItems: "center",
    "& > button:first-child": {
      gridColumn: firstDayOfMonth + 1,
    },
  }),
);

export const Day = styled.button<{ variant?: DayVariant }>(({ theme, variant = "default" }) => {
  const baseVariantStyle = {
    marginLeft: theme.spacing.base,
    marginRight: theme.spacing.base,
    borderRadius: CELL_SIZE,
    border: "1px solid transparent",
    height: CELL_SIZE,
    width: CELL_SIZE,
    [theme.media.tabletPortrait]: {
      marginLeft: theme.spacing.condensed,
      marginRight: theme.spacing.condensed,
    },
    ...getTextStyles({
      theme,
      variant: "body1",
      color: "textPrimary",
    }),
    "&:disabled": {
      color: theme.colors.textDisabled,
    },
    // For reasons unknown, the default focus style was not being applied
    // in some circumstances (e.g. Clicking toggle button and programmatically
    // setting focus on the today/selected date)
    "&:focus": {
      outline: "-webkit-focus-ring-color auto 5px",
    },
  };

  switch (variant) {
    case "default":
      return {
        ...baseVariantStyle,
      };
    case "selected":
      return {
        ...baseVariantStyle,
        backgroundColor: theme.colors.brandPrimary,
        ...getTextStyles({
          theme,
          variant: "body1",
          color: "textInverse",
        }),
      };
    case "today":
      return {
        ...baseVariantStyle,
        border: `1px solid ${theme.colors.brandPrimary}`,
      };
    case "range":
      return {
        ...baseVariantStyle,
        backgroundColor: theme.colors.brandPrimaryLight,
        ...getTextStyles({
          theme,
          variant: "body1",
          color: "black",
        }),
      };
    case "start":
    case "end":
      return {
        ...baseVariantStyle,
        backgroundColor: theme.colors.brandPrimary,
        ...getTextStyles({
          theme,
          variant: "body1",
          color: "textInverse",
        }),
      };
  }
});

export const DayContainer = styled.div<{ variant?: DayVariant }>(
  ({ theme, variant = "default" }) => {
    const baseVariantStyle = {
      height: CELL_SIZE,
      marginTop: 1.5,
      marginBottom: 1.5,
      backgroundColor: theme.colors.transparent,
    };

    switch (variant) {
      case "start":
        return {
          ...baseVariantStyle,
          background: `linear-gradient(90deg, ${theme.colors.transparent} 50%, ${theme.colors.brandPrimaryLight} 50%)`,
        };
      case "end":
        return {
          ...baseVariantStyle,
          background: `linear-gradient(90deg, ${theme.colors.brandPrimaryLight} 50%, ${theme.colors.transparent} 50%)`,
        };
      case "range":
        return {
          ...baseVariantStyle,
          backgroundColor: theme.colors.brandPrimaryLight,
        };
      case "default":
      case "selected":
      case "today":
        return {
          ...baseVariantStyle,
        };
    }
  },
);

export const CalendarFooter = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop: theme.spacing.standard,
}));
