import { useEffect } from "react";

type EventListener = (this: Window, ev: KeyboardEvent) => unknown;

export enum Arrow {
  LEFT,
  UP,
  RIGHT,
  DOWN,
}

// inspired by https://usehooks.com/useKeyPress/
const useOnArrowPressed = (
  target: Arrow,
  onDown: () => void = () => undefined,
  onUp: () => void = () => undefined,
): void => {
  const keyPressedHandler = (callback: () => void) => (event: KeyboardEvent) => {
    let keyMatch = false;

    switch (event.key) {
      case "Down": // IE/Edge specific value
      case "ArrowDown":
        if (target === Arrow.DOWN) {
          callback();
          keyMatch = true;
        }
        break;
      case "Up": // IE/Edge specific value
      case "ArrowUp":
        if (target === Arrow.UP) {
          callback();
          keyMatch = true;
        }
        break;
      case "Left": // IE/Edge specific value
      case "ArrowLeft":
        if (target === Arrow.LEFT) {
          callback();
          keyMatch = true;
        }
        break;
      case "Right": // IE/Edge specific value
      case "ArrowRight":
        if (target === Arrow.RIGHT) {
          callback();
          keyMatch = true;
        }
        break;
    }

    if (keyMatch) {
      event.preventDefault();
    }
  };

  const downHandler: EventListener = keyPressedHandler(onDown);

  const upHandler: EventListener = keyPressedHandler(onUp);
  // Add event listeners
  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, []);
};

export default useOnArrowPressed;
