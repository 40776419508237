import { useMutation } from "@apollo/client";
import { omit } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useHistory } from "react-router-dom";

import { auth } from "/apollo/client/local";
import {
  CaregiverStatus,
  ConfirmCaregiverAccess,
  ConfirmCaregiverAccessVariables,
  GetCaregiverAccess_viewer_user_caregiverAccess,
} from "/apollo/schema";
import { Alert, Avatar, Divider, Link } from "/component/base";
import Button from "/component/base/Button";
import { ChevronDown, Cross } from "/component/base/Icon";
import Text from "/component/base/Text";
import AddressModal from "/component/modal/AddressModal";
import CaregiverProxyModal from "/component/modal/CaregiverProxyModal";
import usePersonalInformationQuery from "/component/page/Account/page/PersonalInformation/usePersonalInformationQuery";
import { useCaregiverProvider } from "/component/provider/CaregiverProvider";
import { useModalContext } from "/component/provider/ModalProvider";
import { useToastContext } from "/component/provider/ToastProvider";
import Transition from "/component/util/Transition";
import routes from "/constant/url.constant";
import { useAmwell, useContactInfo, useTranslation } from "/hook";
import usePopover from "/hook/usePopover";
import { layout } from "/styles";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  AppModalParams,
  ButtonClickParams,
  logEvent,
} from "/util/firebase.util";
import { parseInitials } from "/util/formatText";

import confirmRevokedCaregiverAccessGql from "../../page/Account/page/CaregiverIndex/confirmRevokedCaregiverAccess";
import { CaregiverStatusType, CaregiverType } from "../../provider/CaregiverProvider";
import CaregiverCard from "../CaregiverCard";
import {
  CaregiverButtonBlank,
  CaregiverButtonUnstyled,
  CaregiverItemsContainer,
  CaregiverModal,
} from "./SetCaregiver.styles";
import { CaregiverAccess, Props } from "./SetCaregiver.types";

const SetCaregiver = ({
  header,
  buttonStyle,
  variant = "blank",
  textVariant = "body2Bold",
  textColor = "textSecondary",
  analyticsParams,
  isCaregiverModalOpen,
  onCloseCaregiverModal, // used to close CaregiverProxyModal from Dashboard
}: Props) => {
  const { modalEl } = useModalContext();
  const divRef = useRef<HTMLDivElement>();

  const { t } = useTranslation("partial-caregiver");

  const { showToast } = useToastContext();
  const { refetch: refetchInfo } = usePersonalInformationQuery(true);
  const { contactByName } = useContactInfo();
  const { renewAuth, toggleDependent } = useAmwell();
  const history = useHistory();

  const accountImageUrl = null;
  const MAX_CAREGIVER_ACCESS = 10;

  const {
    caregiver,
    caregiverMode,
    clearCaregiver,
    setCaregiver,
    setCaregiverToggle,
    caregiverAccess: { caregiverAccounts, numCaregiverAccounts, caregiverAccountsRefetch },
    userAccount,
    userAccountLoading,
    userAccountError,
  } = useCaregiverProvider();
  const caregiverAccesses = caregiverAccounts?.viewer?.user.caregiverAccess || [];
  const name = caregiverMode
    ? { firstName: caregiver.firstName, lastName: caregiver.lastName }
    : userAccount?.viewer?.user?.account || { firstName: "", lastName: "" };

  const [isOpen, setIsOpen] = useState(false);
  const [isProxyModalOpen, setIsProxyModalOpen] = useState(false);
  const [navigateToTelehealth, setNavigateToTelehealth] = useState(false);
  const [addressModalOpen, setAddressModalOpen] = useState(false);
  const CaregiverButton = buttonStyle === "blank" ? CaregiverButtonBlank : CaregiverButtonUnstyled;

  const [revokedUser, setRevokedUser] = useState<CaregiverAccess>();
  const [initialAddress, setInitialAddress] = useState<string>();
  const [isAccessRevokedAlertOpen, setAccessRevokedAlert] = useState(false);

  const closeAccessRevokedAlert = () => setAccessRevokedAlert(false);

  const [confirmInviteCaregiverAccess] = useMutation<
    ConfirmCaregiverAccess,
    ConfirmCaregiverAccessVariables
  >(confirmRevokedCaregiverAccessGql);

  const confirmRevokedCaregiverAccess = async () => {
    closeAccessRevokedAlert();
    if (!revokedUser?.id) return;
    const response = await confirmInviteCaregiverAccess({
      variables: {
        data: {
          caregiverAccessId: revokedUser.id,
        },
      },
    });
    if (response.errors?.length || response.data?.confirmInviteCaregiverAccess?.errors?.length) {
      showToast({
        message: t("error"),
        icon: "alert",
      });
    }
    caregiverAccountsRefetch();
  };

  const {
    setAnchorElement,
    setPopoverElement,
    popoverStyles,
    updatePopoverPosition,
    popoverState,
  } = usePopover({
    strategy: "fixed",
    placement: "bottom-end",
    modifiers: [{ name: "offset", options: { offset: [0, 0] } }],
  });

  const getButtonRef = (_ref: HTMLButtonElement | null) => {
    setAnchorElement(_ref);
  };

  const close = () => {
    setIsOpen(false);
  };

  const handleClickOutside = React.useCallback(
    (event: MouseEvent) => {
      // eslint-disable-next-line
      // @ts-ignore
      if (!divRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    },
    [isOpen],
  );

  useEffect(() => {
    //Handle clicking outside of the modal component
    if (divRef && isOpen) {
      logEvent(AnalyticsEvent.APP_MODAL_VIEW, {
        user_flow: AnalyticsUserFlow.CAREGIVER,
        modal_name: AnalyticsSource.MODAL_USER_PROXY,
      } as AppModalParams);

      //Bind and unbind the mouse clicking for this component
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
    return;
  }, [divRef, isOpen]);

  useEffect(() => {
    if (isCaregiverModalOpen !== undefined) {
      if (isCaregiverModalOpen) {
        setNavigateToTelehealth(true);
      }
      setIsProxyModalOpen(isCaregiverModalOpen);
    }
  }, [isCaregiverModalOpen]);

  const switchMyAccount = async () => {
    // switching to already selected account - no need for code below
    if (!caregiver.id) {
      setIsOpen(false);
      setIsProxyModalOpen(false);
      onCloseCaregiverModal && onCloseCaregiverModal();
      return;
    }

    logButtonClickEvent("Account", "My account");

    clearCaregiver();
    auth.setCaregiver(null);
    renewAuth();

    const { data } = await refetchInfo();
    let addressString = "";
    const address = data?.viewer?.user?.patient?.homeAddress;

    if (address) {
      const { line1, city, state } = address;
      addressString = [line1, city, state].filter(Boolean).join(", ");
    }

    setIsOpen(false);
    setIsProxyModalOpen(false);
    setInitialAddress(addressString);
    setAddressModalOpen(true);
    onCloseCaregiverModal && onCloseCaregiverModal();
  };

  const switchCaregiver = async (user: GetCaregiverAccess_viewer_user_caregiverAccess) => {
    // switching to already selected account - no need for code below
    if (user.id === caregiver.id) {
      setIsOpen(false);
      setIsProxyModalOpen(false);
      onCloseCaregiverModal && onCloseCaregiverModal();
      return;
    }

    logButtonClickEvent("Account", "Caregiver account");

    if (user.statusDetails?.status === CaregiverStatus.REVOKED) {
      setRevokedUser({
        firstName: user.firstName,
        lastName: user.lastName,
        id: user.id,
        isMinor: !!user.isMinor,
      });
      setAccessRevokedAlert(true);
      setIsOpen(false);
      return;
    }
    const _caregiver = omit(user, "__typename") as CaregiverType;
    _caregiver.statusDetails = omit(user.statusDetails, "__typename") as CaregiverStatusType;
    setCaregiver(_caregiver);
    setCaregiverToggle(true);
    auth.setCaregiver(user.id);

    if (user.isMinor && user.birthDate)
      await toggleDependent({
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        dob: new Date(user.birthDate),
        gender: user.gender?.toString() || "",
      });
    else await renewAuth();

    let addressString;

    if (user.address) {
      const { line1, city, state } = user.address;
      addressString = [line1, city, state].filter(Boolean).join(", ");
    }

    setIsOpen(false);
    setIsProxyModalOpen(false);
    setInitialAddress(addressString);
    setAddressModalOpen(true);
    onCloseCaregiverModal && onCloseCaregiverModal();
  };

  const renderAccessRevokedAlert = () => {
    const complianceEmail = contactByName("compliance")?.emailAddress;

    let contactButton;
    if (revokedUser?.isMinor) {
      if (complianceEmail) {
        contactButton = (
          <Link.Anchor target="#" href={`mailto:${complianceEmail}`}>
            {t("dialog.accessRevoked.contactPrivacy")}
          </Link.Anchor>
        );
      }
    } else {
      contactButton = (
        <Link.Anchor target="#" href={`mailto:AppSupport@summithealth.com`}>
          {t("dialog.accessRevoked.contactSupport")}
        </Link.Anchor>
      );
    }

    return (
      <Alert
        close={closeAccessRevokedAlert}
        footer={
          <Button fullWidth="flex" onClick={confirmRevokedCaregiverAccess}>
            {t("dialog.accessRevoked.continue")}
          </Button>
        }
        title={t("dialog.accessRevoked.title", {
          name: `${revokedUser?.firstName} ${revokedUser?.lastName} `,
        })}
        isOpen={isAccessRevokedAlertOpen}
      >
        <Text css={{ whiteSpace: "pre-line" }} variant="body1" color="textSecondary">
          {t("dialog.accessRevoked.subtitle")}
        </Text>
        {contactButton}
      </Alert>
    );
  };

  useEffect(() => {
    if (isAccessRevokedAlertOpen) {
      logEvent(AnalyticsEvent.APP_MODAL_VIEW, {
        user_flow: AnalyticsUserFlow.CAREGIVER,
        modal_name: AnalyticsSource.MODAL_CAREGIVER_ACCESS_REVOKED,
      } as AppModalParams);
    }
  }, [isAccessRevokedAlertOpen]);

  const handleAddAccount = () => {
    history.push(routes.caregiver);
    setIsOpen(false);
  };

  const logButtonClickEvent = (buttonName: string, buttonDescription?: string) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      ...analyticsParams,
      button_name: buttonName,
      button_description: buttonDescription,
    } as ButtonClickParams);
  };

  if (userAccountLoading || userAccountError || numCaregiverAccounts === 0) return null;

  return (
    <div>
      {header && (
        <Text variant="body2" color="textSecondary">
          {header}
        </Text>
      )}
      <CaregiverButton
        aria-label={name.firstName || ""}
        onClick={() => setIsOpen(true)}
        variant={variant}
        ref={getButtonRef}
      >
        <Avatar
          alt={t("imageAlt", { firstName: name.firstName })}
          size="xSmall"
          src={accountImageUrl}
          initials={parseInitials(name)}
        />

        <Text variant={textVariant} color={textColor} align="left">
          {name.firstName}
        </Text>
        <ChevronDown
          color="brandSecondary"
          size={16}
          css={layout.margin("skip", "skip", "skip", "condensed")}
        />
      </CaregiverButton>
      {createPortal(
        <div
          ref={(el) => {
            setPopoverElement(el);
            if (divRef && el) divRef.current = el;
          }}
          style={popoverStyles}
        >
          <Transition show={isOpen} beforeEnter={updatePopoverPosition}>
            <CaregiverModal
              css={layout.flexVerticalAlignStart}
              $animateFromBottom={!!popoverState?.placement.match(/^top/)}
            >
              <CaregiverItemsContainer>
                <Button variant="unstyled" onClick={close}>
                  <Cross color="brandSecondary" />
                </Button>
                <CaregiverCard
                  isSelected={!caregiver.id}
                  name={userAccount?.viewer?.user?.account || { firstName: "", lastName: "" }}
                  onClick={() => switchMyAccount()}
                />
                {caregiverAccesses.map((proxyProp, idx) => (
                  <React.Fragment key={idx}>
                    <Divider />
                    <CaregiverCard
                      key={idx}
                      isSelected={caregiver.id === proxyProp.id}
                      name={{
                        firstName: proxyProp.firstName,
                        lastName: proxyProp.lastName,
                      }}
                      onClick={() => switchCaregiver(proxyProp)}
                    />
                  </React.Fragment>
                ))}
                {!caregiverMode && numCaregiverAccounts < MAX_CAREGIVER_ACCESS && (
                  <>
                    <Divider />
                    <CaregiverCard isAddition={true} onClick={handleAddAccount} />
                  </>
                )}
              </CaregiverItemsContainer>
            </CaregiverModal>
          </Transition>
        </div>,
        modalEl || document.body,
      )}
      <AddressModal
        initialValue={initialAddress}
        isOpen={addressModalOpen}
        close={() => {
          setAddressModalOpen(false);
          if (navigateToTelehealth) {
            history.push(routes.telehealth);
          }
        }}
      />
      <CaregiverProxyModal
        caregiverId={caregiver.id}
        caregiverAccesses={caregiverAccesses}
        isOpen={isProxyModalOpen}
        name={userAccount?.viewer?.user?.account || { firstName: "", lastName: "" }}
        onClose={() => {
          setIsProxyModalOpen(false);
          onCloseCaregiverModal && onCloseCaregiverModal();
          if (navigateToTelehealth) {
            history.push(routes.telehealth);
          }
        }}
        onCancel={() => {
          setIsProxyModalOpen(false);
          onCloseCaregiverModal && onCloseCaregiverModal();
        }}
      />
      {renderAccessRevokedAlert()}
    </div>
  );
};

export default SetCaregiver;
