import { gql } from "@apollo/client";

export default gql`
  mutation UpdateDocumentReadStatus($input: UpdateDocumentReadStatusInput!) {
    updateDocumentReadStatus(data: $input) {
      errors {
        message
        code
      }
    }
  }
`;
