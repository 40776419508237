import React, { useState } from "react";
import { usePopper } from "react-popper";

import theme from "/theme/default";

import Tooltip from "./Tooltip";
import { borderRadius, PopoverButton, PopoverPanel, PopoverWrapper } from "./Tooltip.styles";
import { ControllerProps, TooltipLocation } from "./Tooltip.types";

type LocationConfig = {
  arrowPadding: number;
  offset: [number, number];
};

/**
 * Get any custom configuration that we want to pass to popper, based on our tooltip.
 */
const getConfig = (location: TooltipLocation, offset = 0): LocationConfig => {
  // See comments below where these values are used for details on what they are doing.
  switch (location) {
    case "top-start":
      return {
        arrowPadding: 24,
        offset: [-24, 11 + offset],
      };
    case "top-end":
      return {
        arrowPadding: 24,
        offset: [0, 11 + offset],
      };
    case "top":
    case "right":
    case "left":
    case "bottom":
      return {
        // Setting `arrowPadding` to 16 makes it so the arrow won't scroll past the tooltip body if
        // the tooltip scrolls off the edge of the screen. Note: we use this in combination with
        // preventOverflow.tetherOffset to ensure that the arrow stays centred on small, single line tooltips
        arrowPadding: borderRadius,
        offset: [0, 11 + offset],
      };
  }
};

/**
 * Render a Tooltip and an Anchor that will show and hide the Tooltip. This component handles its own
 * state for open/close state. It will reposition the tooltip if it is trying to be rendered off screen
 * and will follow the anchor element while scrolling.
 */
const TooltipController = ({
  offset,
  location,
  forwardedAs,
  anchor,
  children,
  hideClose,
  variant,
  showOnHover,
  className,
}: ControllerProps) => {
  const [isSmall, setIsSmall] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);

  const configuration = getConfig(location, offset);

  const { styles, attributes, state } = usePopper(referenceElement, popperElement, {
    strategy: "fixed",
    modifiers: [
      {
        name: "arrow",
        options: {
          element: arrowElement,
          /**
           * # From popper docs:
           * If you don't want the arrow to reach the very edge of the popper (this is common
           * if your popper has rounded corners using border-radius), you can apply some padding to it.
           * ------------------------
           * Small tooltips are only a single line, so if we applied padding the arrow would look super
           * janky because it wouldn't actually touch the tooltip (try tweaking the value if you're curious)
           */
          padding: isSmall && location.match(/^(left|right)/) ? 0 : configuration.arrowPadding,
        },
      },
      {
        name: "offset",
        options: {
          /**
           * Offset allows us to manually move the tooltip in relation to the anchor. `offset` is
           * a tuple with two elements.
           *
           * The first element of the tuple will move the tooltip parallel to the anchor. So in the
           * example of a tooltip that is positioned to the `top` of the anchor it changing the first
           * value will move the tooltip to the left or right.
           *
           * The second element in the tuple will move the tooltip perpendicular to the anchor. If
           * the tooltip is positioned to the `top` of the anchor changing the second value will
           * result in the tooltip moving up and down: away from/towards the anchor.
           */
          offset: configuration.offset,
        },
      },
      {
        name: "preventOverflow",
        options: {
          // Set the tetherOffset to 16 so that the arrow won't overlap with the border radius.
          // To see the effect try setting it to 0 and scroll a **single line** right or left tooltip
          // off the bottom/top of the screen.
          tetherOffset: borderRadius,
          // Give the tooltip some padding so it doesn't touch the edge of the screen.
          altAxis: true,
          padding: 16,
        },
      },
    ],
    // The default location to try rendering the tooltip to
    placement: location,
  });

  // We need to measure the popper element to figure out if it's a one line tooltip or not. If it's
  // a one-liner we need to tweak the position of the arrow.
  const setPopoverRef = (ref: HTMLDivElement | null) => {
    setPopperElement(ref);
    const height = ref?.getBoundingClientRect().height;
    if (height) {
      setIsSmall(height < 50);
    }
  };

  // We want to support Anchor children that are functions that accept a `setRef` so that we can
  // have fine grained control over which part of the Anchor the tooltip focuses on.
  let anchorChild;
  const ariaAttrs = { "aria-haspopup": true };

  if (typeof anchor === "function") {
    anchorChild = anchor(setReferenceElement);
    anchorChild = React.cloneElement(anchorChild, ariaAttrs);
  } else {
    anchorChild = React.cloneElement(anchor, { ...ariaAttrs, ref: setReferenceElement });
  }

  return (
    <PopoverWrapper forwardedAs={forwardedAs} $showOnHover={!!showOnHover}>
      {anchorChild}
      <PopoverPanel
        ref={setPopoverRef}
        style={{ ...styles.popper, zIndex: theme.zIndex.tooltip }}
        {...attributes.popper}
        focus
        static={!!showOnHover}
      >
        <Tooltip
          className={className}
          arrow={{ styles: styles.arrow, setRef: setArrowElement }}
          placement={state?.placement || location}
          hideClose={hideClose}
          variant={variant}
          isSmall={isSmall}
        >
          {children}
        </Tooltip>
      </PopoverPanel>
    </PopoverWrapper>
  );
};

TooltipController.Anchor = PopoverButton;

export default TooltipController;
