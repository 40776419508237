import { ApolloError, ApolloQueryResult, gql, useQuery } from "@apollo/client";
import { useEffect } from "react";

import {
  PrescriptionsQuery,
  PrescriptionsQuery_viewer,
  PrescriptionsQueryVariables,
} from "/apollo/schema/types";

export const prescriptionsQuery = gql`
  query PrescriptionsQuery($active: Boolean) {
    viewer {
      user {
        patient {
          prescriptions(active: $active) {
            documentDescription
            priority
            prescriptionId
            createdDateTime
            facilityId
            status
            mrn
          }
        }
      }
    }
  }
`;

const useQueryPrescriptions = (
  active = true,
): {
  error: ApolloError | undefined;
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<PrescriptionsQuery>>;
  viewer: PrescriptionsQuery_viewer | null | undefined;
} => {
  const { data, error, loading, refetch } = useQuery<
    PrescriptionsQuery,
    PrescriptionsQueryVariables
  >(prescriptionsQuery, {
    variables: {
      active,
    },
  });

  const refetchWrapper = () => {
    return refetch({ active });
  };

  useEffect(() => {
    refetch({ active });
  }, [refetch, active]);

  return {
    error,
    loading,
    refetch: refetchWrapper,
    viewer: data?.viewer,
  };
};

export default useQueryPrescriptions;
