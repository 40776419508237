import styled from "styled-components";

import Text from "/component/base/Text";
import FormContent from "/component/partial/FormContent";
import { layout } from "/styles";

export const Container = styled.div(layout.marginVertical("gutter"), {
  maxWidth: 550,
  width: "100%",
});

export const FormContentContainer = styled(FormContent)(layout.marginVertical("gutter"), {
  maxWidth: 550,
  width: "100%",
});

export const Title = styled(Text).attrs({
  variant: "title1",
  element: "h2",
  color: "textTitle",
})(layout.margin("skip", "skip", "standard"));

export const SubTitle = styled(Text).attrs({
  variant: "body2",
  color: "textPrimary",
})(layout.padding("skip"));

export const InlineFormItems = styled.div(layout.flexLTR, layout.spacedChildrenHorizontal(), {
  "& > *": {
    flexGrow: 1,
  },
});
