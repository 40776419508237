import styled from "styled-components";

import Text from "/component/base/Text";
import { layout } from "/styles";

const IMAGE_MAX_WIDTH = 450;
const IMAGE_MAX_HEIGHT = 350;

export const GeneralTutorialsContainer = styled.div(
  layout.paddingVertical("gutter"),
  layout.flexVertical,
  ({ theme }) => ({
    alignContent: "center",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme.colors.brandNavy,
    maxWidth: IMAGE_MAX_WIDTH,
    flex: 1,
  }),
);

export const TutorialImage = styled.img(
  { alignSelf: "center", maxWidth: IMAGE_MAX_WIDTH, maxHeight: IMAGE_MAX_HEIGHT },
  layout.margin("gutter", "skip"),
);

export const SubtitleText = styled(Text)(({ theme }) => ({ fontSize: theme.fontSizes.title3 }));

export const LogoContainer = styled.div(
  { alignSelf: "flex-start" },
  layout.margin("expanded", "standard"),
  layout.spacedChildrenHorizontal("condensed"),
  layout.flexCenterHorizontal,
);
