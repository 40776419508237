import { gql, LazyQueryHookOptions } from "@apollo/client";

import { useB2CLazyQuery } from "../client";
import { GET_LAB_RESULT_PAGE, GET_LAB_RESULT_PAGEVariables } from "../schema/types";

export const labResultPageQuery = gql`
  query GET_LAB_RESULT_PAGE($labResultId: Int!, $pageId: Int!) {
    patient {
      labResultPage(labResultId: $labResultId, pageId: $pageId)
    }
  }
`;

export const useLabResultPage = (
  options: LazyQueryHookOptions<GET_LAB_RESULT_PAGE, GET_LAB_RESULT_PAGEVariables>,
) =>
  useB2CLazyQuery<GET_LAB_RESULT_PAGE, GET_LAB_RESULT_PAGEVariables>(labResultPageQuery, options);
