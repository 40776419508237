import { NavLink } from "react-router-dom";
import styled from "styled-components";

import Text from "/component/base/Text";
import { layout } from "/styles";

export const LinkWrapper = styled.li(({ theme }) => ({
  "& + &": {
    marginTop: theme.spacing.expanded,
  },
}));

export const Link = styled(NavLink)(
  ({ theme }) => ({
    color: theme.colors.objectSecondary,
  }),
  layout.flexCenterHorizontal,
);

export const Label = styled(Text)(({ theme }) => ({
  marginLeft: theme.spacing.condensed,

  [theme.media.range.medium]: {
    display: "none",
  },
}));

export const IconWrapper = styled.div({
  position: "relative",
});

export const Badge = styled.div(({ theme }) => ({
  width: 6,
  height: 6,
  borderRadius: 6,
  backgroundColor: theme.colors.brandPrimary,
  position: "absolute",
  top: 6,
  right: -240, //TODO need to update the hardcoding
  [theme.media.range.medium]: {
    top: -theme.spacing.base,
    right: -theme.spacing.base,
  },
}));
