import styled from "styled-components";

import { layout } from "/styles";

export const DisclaimerOuterContainer = styled.div({
  textAlign: "center",
});

export const DisclaimerInnerContainer = styled.div(layout.marginVertical("expanded"), {
  textAlign: "left",
});
