/*!
 * American Well Consumer Web SDK Sample App
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

import React from "react";
import awsdk from "awsdk";
import PropTypes from "prop-types";

import "./TranscriptComponent.css";

class TranscriptComponent extends React.Component {
  buildNonAdminMessage(item, className) {
    const initials = item.firstName.charAt(0) + item.lastName.charAt(0);
    return (
      <div key={item.ordinal} className={`chatMessage ${className}`}>
        <div className="flex">
          {className === "providerMessage" && (
            <div className="senderNameOuter">
              <div className="senderName verticalCenter">{initials}</div>
            </div>
          )}
          <div className="messageBody">
            <text>{item.message}</text>
          </div>
        </div>
      </div>
    );
  }

  buildMessage(item, index) {
    if (
      item.userType === awsdk.AWSDKUserType.PROVIDER ||
      item.userType === awsdk.AWSDKUserType.ASSISTANT
    ) {
      return this.buildNonAdminMessage(item, "providerMessage");
    }
    if (item.userType === awsdk.AWSDKUserType.CONSUMER) {
      return this.buildNonAdminMessage(item, "consumerMessage");
    }

    let itemMessage = item.message;
    if (index > 0 && item.messageType === "AssistantEnter") {
      itemMessage = item.message.split(",")[0] + " has re-entered the room.";
    }

    return (
      <div key={item.ordinal} className="adminMessage">
        {itemMessage}
      </div>
    );
  }

  render() {
    const transcript = [];

    this.props.chatItems.forEach((item, index) => {
      transcript.push(this.buildMessage(item, index));
    });

    return <div className="chatTranscript">{transcript}</div>;
  }
}
TranscriptComponent.propTypes = {
  chatItems: PropTypes.array.isRequired,
  sentMessage: PropTypes.object,
  onAssistantEnteredRoom: PropTypes.func.isRequired,
  onAssistantLeftRoom: PropTypes.func.isRequired,
};

export default TranscriptComponent;
