import React, { useState } from "react";
import OTPInput from "react-otp-input";

import { Button } from "/component/base";

import {
  ButtonGroup,
  Container,
  Description,
  errorText,
  focusText,
  Header,
  inputText,
  SubTitle,
} from "./OTPComponent.styles";

const INPUT_COUNT = 6;

interface Props {
  headerTitle?: string;
  subTitle?: string;
  description?: string;
  resendTitle?: string;
  submitTitle?: string;
  errorView?: React.ReactNode;
  onSubmit: (passcode: string) => void;
  onResendClick?: () => void;
}
const OTPComponent: React.FC<Props> = ({
  headerTitle,
  subTitle,
  description,
  resendTitle,
  submitTitle,
  errorView,
  onSubmit,
  onResendClick,
}) => {
  const [otpText, setOtpText] = useState<string>("");
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  return (
    <>
      <Container>
        <Header>{headerTitle}</Header>
        <SubTitle>{subTitle}</SubTitle>
        <OTPInput
          value={otpText}
          numInputs={INPUT_COUNT}
          isInputNum={true}
          focusStyle={focusText}
          inputStyle={inputText}
          errorStyle={errorText}
          hasErrored={!!errorView}
          onChange={(otp: string) => {
            setOtpText(otp);
            setSubmitDisabled(otp.length < INPUT_COUNT);
          }}
        />
        {errorView}
        <Description>{description}</Description>
      </Container>
      <ButtonGroup>
        {resendTitle && (
          <Button variant="borderBottom" onClick={onResendClick}>
            {resendTitle}
          </Button>
        )}
        {submitTitle && (
          <Button
            variant="primary"
            fullWidth="percent"
            isDisabled={submitDisabled}
            onClick={() => {
              onSubmit(otpText);
            }}
          >
            {submitTitle}
          </Button>
        )}
      </ButtonGroup>
    </>
  );
};

export default OTPComponent;
