import { gql } from "@apollo/client";

export default gql`
  mutation ConfirmCaregiverAccess($data: ConfirmInviteCaregiverAccessInput!) {
    confirmInviteCaregiverAccess(data: $data) {
      errors {
        message
        code
      }
      name
      status
    }
  }
`;
