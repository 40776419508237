import React from "react";
import { Trans } from "react-i18next";

import { Link, Text } from "/component/base";
import { useTranslation } from "/hook";
import { layout } from "/styles";
import { colors } from "/theme/default/colors";

import { NegativeResultsWrapper } from "./NegativeResultGuide.styles";

const NegativeResultGuide = () => {
  const { t } = useTranslation("partial-negativeResultsGuide");

  return (
    <NegativeResultsWrapper>
      <Text variant="body2Bold" color="textSecondary">
        {t("title")}
      </Text>

      <Text
        variant="body2"
        color="textSecondary"
        css={layout.margin("condensed", "none", "none", "none")}
      >
        <Trans
          components={{
            viewGuide: (
              <Link.Anchor
                href={process.env.NEGATIVE_LAB_RESULT_PDF_URL}
                variant="tertiary"
                css={{ textDecoration: "underline", color: colors.textSecondary }}
              />
            ),
          }}
          i18nKey="description"
          t={t}
        />
      </Text>
    </NegativeResultsWrapper>
  );
};

export default NegativeResultGuide;
