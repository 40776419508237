const BASE_UNIT = 4;

export const spacing = {
  base: BASE_UNIT,
  condensed: BASE_UNIT * 2,
  expanded: BASE_UNIT * 8,
  gutter: BASE_UNIT * 6,
  none: 0,
  standard: BASE_UNIT * 4,
  20: BASE_UNIT * 5,
  12: BASE_UNIT * 3,
  40: 40,
  60: 60,
  80: 80,
  2: 2,
  mobileNavTop: BASE_UNIT * 3,
} as const;
