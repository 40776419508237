import React from "react";

import Transition from "/component/util/Transition";

import { Base, Body, Container, Footer, IconContainer, Overlay, Title } from "./Alert.styles";
import { Props } from "./Alert.types";

// Normally you would want the `className` prop to be on the outer-most tag in your component.
// But modal is different, if a dev wanted to position or change the size of a Modal they would
// need to apply their class to `Container`
const Alert = ({ className, children, isOpen, close, footer, title, icon }: Props) => {
  return (
    <Transition show={isOpen}>
      {() => (
        <Base open={isOpen} onClose={close}>
          <Transition.Child>
            <Overlay />
          </Transition.Child>

          <Transition.Child>
            <Container className={className}>
              {!!icon && <IconContainer>{icon}</IconContainer>}
              <Title variant="title2">{title}</Title>
              <Body>{children}</Body>
              <Footer>{footer}</Footer>
            </Container>
          </Transition.Child>
        </Base>
      )}
    </Transition>
  );
};

export default Alert;
