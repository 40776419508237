/*!
 * American Well Consumer Web SDK Sample App
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { AnalyticsEvent, logEvent } from "/util/firebase.util";

import "./ChatWindowComponent.css";
import TranscriptComponent from "./TranscriptComponent";

class ChatWindowComponent extends React.Component {
  constructor(props) {
    super(props);
    this.bottomRef = null;
    this.state = {
      isCollapsed: false,
      inputEnabled: true,
    };
  }

  scrollToBottom() {
    this.bottomRef.scrollIntoView({ behavior: "smooth" });
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.chatReport.lastOrdinal > prevProps.chatReport.lastOrdinal ||
        this.props.sentMessage) &&
      !this.state.isCollapsed
    ) {
      this.scrollToBottom();
    }
  }

  toggleIsCollapsed(e) {
    e.preventDefault();
    this.setState((prevState) => ({
      isCollapsed: !prevState.isCollapsed,
    }));
  }

  render() {
    return (
      <div className={classNames("chatContainer", { collapsed: this.state.isCollapsed })}>
        <div className={classNames("chatWindow", { collapsed: this.state.isCollapsed })}>
          <div className="chatWindowHeading">
            <div className="vertical-center">
              <p>{"Chat"}</p>
              <button
                onClick={(e) => {
                  if (this.state.isCollapsed) {
                    logEvent(AnalyticsEvent.AMWELL_CHAT_OPEN);
                  }
                  this.toggleIsCollapsed(e);
                }}
              />
            </div>
          </div>
          <div className="transcriptWindow">
            <TranscriptComponent
              chatItems={this.props.chatReport.chatItems}
              sentMessage={this.props.sentMessage}
            />
            <div
              ref={(element) => {
                this.bottomRef = element;
              }}
            />
          </div>
          {this.props.chatInputEnabled && (
            <form className="chatWindowInput">
              <input
                type="text"
                placeholder={"Type here..."}
                value={this.props.currentMessage}
                onChange={(e) => this.props.updateCurrentMessage(e.target.value)}
              />

              <button type="submit" onClick={(e) => this.props.handleSendMessageClick(e)} />
            </form>
          )}
        </div>
      </div>
    );
  }
}
ChatWindowComponent.propTypes = {
  messages: PropTypes.any.isRequired,
  chatReport: PropTypes.object.isRequired,
  currentMessage: PropTypes.string.isRequired,
  updateCurrentMessage: PropTypes.func.isRequired,
  handleSendMessageClick: PropTypes.func.isRequired,
  sentMessage: PropTypes.object,
  chatInputEnabled: PropTypes.bool,
};
ChatWindowComponent.defaultProps = {};

export default ChatWindowComponent;
