import styled from "styled-components";

import { Spinner } from "/component/base";
import { layout } from "/styles";

export const EmptyStateContainer = styled.div(layout.padding("expanded", "skip"));

export const ListContainer = styled.ul(layout.padding("standard", "none", "expanded"));

export const ListSpinner = styled(Spinner)(layout.flexCenter, layout.margin("standard", "auto"));
