import { gql, useQuery } from "@apollo/client";

import { PersonalInfo } from "/apollo/schema/types";
import { contactSupportFragment } from "/hook/useContactInfo/useContactInfo";

const GET_PERSONAL_INFO = gql`
  ${contactSupportFragment}

  query PersonalInfo {
    contactInfo {
      ...contactSupportFragment
    }
    viewer {
      user {
        patient {
          name {
            firstName
            lastName
          }
          gender
          birthDate
          homeAddress {
            line1
            line2
            city
            state
            postalCode
          }
        }
      }
    }
  }
`;

export default (skipInitial = false) =>
  useQuery<PersonalInfo>(GET_PERSONAL_INFO, {
    fetchPolicy: "network-only",
    skip: skipInitial,
  });
