import { gql, useLazyQuery } from "@apollo/client";

import { PharmacySearch, PharmacySearchVariables } from "/apollo/schema/types";

const GET_PHARMACIES_LIST = gql`
  query PharmacySearch($search: String!) {
    pharmacies(name: $search) {
      id
      pharmacyType
      name
      facilityId
      address {
        line1
        line2
        city
        state
        postalCode
      }
      contact {
        email
        phoneNumber
        extension
        faxNumber
      }
    }
  }
`;

export default () => useLazyQuery<PharmacySearch, PharmacySearchVariables>(GET_PHARMACIES_LIST);
