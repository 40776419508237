import React from "react";

import mapPin from "/asset/svg/location-filled.svg";

import { Link, MapImage, MarkerImage } from "./Map.styles";
import { Props } from "./Map.types";

/**
 * The Map component renders a static map image using GoogleMaps API. Pressing
 * the map will open a Google Maps link in a new tab. The `center` prop
 * determines the location rendered by the map, and a marker will be placed at
 * the address given.
 */
export const Map = ({
  alt = "Map",
  center = "",
  className,
  format = "JPEG",
  height = 160,
  maptype = "roadmap",
  scale = 2,
  width = 640,
  zoom = 16,
  link,
  ...otherProps
}: Props): JSX.Element => (
  <Link
    $height={height}
    $width={width}
    className={className}
    href={link ? link : `https://www.google.com/maps/search/?api=1&query=${center}`}
    rel="noopener noreferrer"
    target="_blank"
  >
    <MapImage
      alt={alt}
      src={`https://maps.googleapis.com/maps/api/staticmap?center=${center}&style=feature:poi|visibility:off&zoom=${zoom}&size=${width}x${height}&scale=${scale}&format=${format}&maptype=${maptype}&key=${process.env.GOOGLE_MAPS_API_KEY}`}
      {...otherProps}
    />
    <MarkerImage aria-hidden src={mapPin} />
  </Link>
);

export default Map;
