import { useField } from "formik";
import React from "react";

import InputWrapper from "/component/base/InputWrapper";
import TextInput from "/component/base/TextInput";

import Props from "./TextField.types";

const TextField = React.forwardRef<HTMLInputElement, Props>(
  (
    { variant, after, before, className, label, inputWrapperVariant = "primary", ...props },
    ref,
  ) => {
    const [field, meta] = useField(props);
    return (
      <InputWrapper
        className={className}
        error={meta.error}
        htmlFor={field.name}
        label={label}
        touched={meta.touched}
        variant={inputWrapperVariant}
      >
        <TextInput
          variant={variant}
          {...field}
          {...props}
          value={field.value ? field.value : ""}
          hasError={meta.touched && !!meta.error}
          before={before}
          after={after}
          ref={ref}
        />
      </InputWrapper>
    );
  },
);

export default TextField;
