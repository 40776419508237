import React from "react";
import ContentLoader from "react-content-loader";

import { Base } from "./LoadingCard.styles";
import { Props } from "./LoadingCard.types";

// react-content-loader does not work well with transparent colours. In the designs the
// background color is set to brandPrimary with 10% opacity. I grabed the computed hex color value
// to use in this component
const backgroundColor = "#e3e5e9";
// I picked a random FG colour that looks nice with the above
const foregroundColor = "#f0f0f0";

const LoadingCard = ({ className, animate = true, ...loaderProps }: Props) => {
  return (
    <Base className={className}>
      <ContentLoader
        speed={2}
        width={320}
        height={72}
        viewBox="0 0 320 72"
        backgroundColor={backgroundColor}
        foregroundColor={animate ? foregroundColor : backgroundColor}
        animate={animate}
        {...loaderProps}
      >
        <rect x="0" y="8" rx="16" ry="16" width="56" height="56" />
        <rect x="72" y="8" rx="4" ry="4" width="248" height="16" />
        <rect x="72" y="40" rx="4" ry="4" width="124" height="16" />
      </ContentLoader>
    </Base>
  );
};

export default LoadingCard;
