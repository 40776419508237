import React from "react";

import {
  ActiveBadge,
  ControlContainer,
  InlineText,
  SegmentButton,
} from "./SegmentedControl.styles";
import { Props } from "./SegmentedControl.types";

const SegmentedControl: <T>(props: Props<T>) => React.ReactElement = ({
  options = [],
  value,
  onItemSelected,
  title,
}) => {
  return (
    <ControlContainer aria-label={title}>
      {options.map((option, idx) => {
        return (
          <SegmentButton
            key={idx}
            onClick={() => {
              onItemSelected(option.value);
            }}
            isSelected={value === option.value}
            variant={"secondary"}
            type="button"
          >
            <InlineText
              color="textNavyBlue"
              variant={"body1Bold"}
              isSelected={value === option.value}
            >
              {option.label}
              {option.isBadge && <ActiveBadge />}
            </InlineText>
          </SegmentButton>
        );
      })}
    </ControlContainer>
  );
};

export default SegmentedControl;
