import { some } from "lodash";
import React from "react";
import { Prompt } from "react-router";

import { scheduling } from "/apollo/client/local";
import { useRedirectListener } from "/component/provider/RedirectListenerProvider";
import routes from "/constant/url.constant";
import { useTranslation } from "/hook";

import { Props } from "./SchedulingPrompt.types";

const validRoutes = [
  `${routes.scheduling}${routes.availabilities}`,
  `${routes.signUp}`,
  `${routes.scheduling}${routes.confirm}`,
  `${routes.scheduling}${routes.visitReason}`,
];

const SchedulingPrompt = ({ when }: Props) => {
  const { t } = useTranslation("scheduling");
  useRedirectListener((willRedirect) => {
    if (willRedirect) {
      scheduling.reset();
    }
  });
  return (
    <Prompt
      when={when}
      message={(location) => {
        return some(validRoutes, (route) => location.pathname.startsWith(route))
          ? true
          : (t("decisionSupport.confirmAvailabilitiesAbandon") as string);
      }}
    />
  );
};

export default SchedulingPrompt;
