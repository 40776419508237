import styled from "styled-components";

import { MapPin as MapPinBase } from "/component/base/Icon";
import { Star as StarBase } from "/component/base/Icon";
import { VideoFilled as VideoFilledBase } from "/component/base/Icon";
import { PersonCheck as PersonCheckBase } from "/component/base/Icon";
import Text from "/component/base/Text";
import { layout } from "/styles";

export const Base = styled.div(layout.padding("standard", "skip"), ({ theme }) => ({
  borderBottom: `1px solid ${theme.colors.objectSubtle}`,
}));

export const TimeSlots = styled.div(({ theme }) => ({
  marginTop: theme.spacing.standard,
  display: "grid",
  gridGap: theme.spacing.condensed,
  gridTemplateColumns: "repeat(auto-fit, 102px)",
}));

export const MapPin = styled(MapPinBase)(layout.margin("skip", "base", "skip", "skip"));

export const StarRating = styled(StarBase)(layout.margin("skip", "base", "skip", "skip"));

export const VirtualVisit = styled(VideoFilledBase)(layout.margin("base", "base", "skip", "skip"));

export const PersonCheck = styled(PersonCheckBase)(layout.margin("base", "base", "skip", "skip"));

export const Attributes = styled(Text)(
  ({ theme }) => ({
    color: theme.colors.brandSecondary,
  }),
  layout.lineClamp(1),
);

export const RatingAttribute = styled.div(({ theme }) => ({
  fontFamily: theme.fontFamilies.CeraProRegular,
  fontSize: theme.fontSizes.body1,
  color: theme.colors.textPrimary,
}));

export const AttributeSet = styled.div(({ theme }) => ({
  marginRight: theme.spacing.standard,
  display: "inline-flex",
}));

export const ProviderAttribute = styled.div(({ theme }) => ({
  marginTop: `${theme.spacing[12]}px !important`,
}));

export const SubtitleFont = styled.text(({ theme }) => ({
  fontSize: theme.fontSizes.body1,
  color: theme.colors.textPrimary,
}));
