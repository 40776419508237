import React from "react";

import ListItem from "/component/base/ListItem";
import Text from "/component/base/Text";
import { layout } from "/styles";

import { Props } from "./SettingsItem.types";

const SettingsItem = ({ listItemLabel, listItemValue }: Props) => {
  return (
    <ListItem.Div showBorder={false} showChevron={false} css={[layout.paddingVertical("standard")]}>
      <div
        css={[
          layout.padding("skip", "standard", "skip", "skip"),
          layout.flexSpaceHorizontal,
          layout.flexItemAuto,
        ]}
      >
        <Text>{listItemLabel}</Text> <Text truncate={true}>{listItemValue}</Text>
      </div>
    </ListItem.Div>
  );
};

export default SettingsItem;
