import { useAmwell, useAmwellQuery } from "/hook";

import { Variables } from "./useAmwellProviderDetails.types";

/**
 * This hook allows for the retrieval of the details for a given provider. A
 * search may only be performed when authenticated, and when an AWSDKProvider is
 * provided via parameters. In addition to the response data, it also includes
 * boolean values to indicate loading and error states.
 */
export const useAmwellProviderDetails = ({ provider }: Variables = {}) => {
  const { consumer, sdk } = useAmwell();
  const fetch = async () =>
    consumer && provider && sdk
      ? await sdk.providerService.getProviderDetails(provider, consumer)
      : undefined;

  return useAmwellQuery(fetch, [provider]);
};

export default useAmwellProviderDetails;
