import React, { useState } from "react";
import { Accordion, AccordionItemHeading } from "react-accessible-accordion";

import { Close, Plus } from "/component/base/Icon";

import {
  AccordionHeadingContent,
  AccordionItemWrapper,
  AccordionPanelContent,
} from "./Accordion.style";
import { Props } from "./Accordion.types";

const AccordionView = ({
  accordionSections,
  allowZeroExpanded = true,
  allowMultipleExpanded = true,
}: Props) => {
  const [expandedItems, setExpandedItems] = useState<Array<number>>([]);
  return (
    <Accordion
      allowZeroExpanded={allowZeroExpanded}
      allowMultipleExpanded={allowMultipleExpanded}
      onChange={(expandedItems: number[]) => {
        setExpandedItems(expandedItems);
      }}
    >
      {accordionSections.map((section, index) => (
        <AccordionItemWrapper
          key={index}
          uuid={index}
          $hideLastChildBorder={index === accordionSections.length - 1}
        >
          <AccordionItemHeading>
            <AccordionHeadingContent>
              <div>{section.title}</div>
              {expandedItems.includes(index) ? (
                <Close color="brandSecondary" size={16} />
              ) : (
                <Plus color="brandSecondary" size={16} />
              )}
            </AccordionHeadingContent>
          </AccordionItemHeading>
          <AccordionPanelContent>
            <p>{section.content}</p>
          </AccordionPanelContent>
        </AccordionItemWrapper>
      ))}
    </Accordion>
  );
};

export default AccordionView;
