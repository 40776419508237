import React from "react";

import { Radio, Text } from "/component/base";

interface Props {
  label: string;
  checked?: boolean;
  onSelect: () => void;
}

const VerificationOption = ({ label, checked = false, onSelect }: Props) => {
  return (
    <div>
      <Radio
        label={<Text variant="body1">{label}</Text>}
        checked={checked}
        onClick={onSelect}
        onChange={() => null}
        labelBefore={false}
      />
    </div>
  );
};

export default VerificationOption;
