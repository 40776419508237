import styled from "styled-components";

import ModalBase, { styles as ModalStyles } from "/component/base/Modal";

export const Modal = styled(ModalBase)<{ $isComposing: boolean }>(({ $isComposing }) => {
  if (!$isComposing) return {};

  // Make the compose modal as tall as possible (note: modals have max-height: 80vh) and
  // have the Body fill all available space
  return {
    height: "80vh",

    [`& > ${ModalStyles.Body}`]: {
      flex: "1 1 auto",
      display: "flex",
      flexDirection: "column",
    },

    // Make the textarea fill all available space
    "& textarea": {
      // By default this textarea has a height of 800px, override that
      height: "auto",
      flex: "1 1 auto",
      // Give it a min-height so that it's visible on small screens like an iphone 5
      minHeight: 160,
    },
  };
});
