import { DefaultTheme } from "styled-components";

import { Spacings, ThemeType } from "/theme/default";

type SpacingValues = Spacings | "skip" | "auto";
type Gap = SpacingValues | number;

export const getSpacingStyles = (
  property: "margin" | "padding",
  value: (SpacingValues | undefined)[],
  theme: DefaultTheme,
) => {
  const getResolvedValue = (value?: SpacingValues) => {
    switch (value) {
      case "auto":
        return property === "margin" ? "auto" : 0;
      case "skip":
      case undefined:
        return undefined;
      default:
        return `${theme.spacing[value]}px`;
    }
  };

  if (value.includes("skip")) {
    return {
      [`${property}Bottom`]: getResolvedValue(value[2] || value[0]),
      [`${property}Left`]: getResolvedValue(value[3] || value[1]),
      [`${property}Right`]: getResolvedValue(value[1]),
      [`${property}Top`]: getResolvedValue(value[0]),
    };
  }

  const valueString = value
    .map((spacingValue) => (spacingValue ? getResolvedValue(spacingValue) : ""))
    .join(" ");

  return { [property]: valueString };
};

export const absoluteFill = {
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
} as const;

export const absoluteCenter = {
  ...absoluteFill,
  margin: "auto",
} as const;

export const flexLTR = {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
} as const;

export const flexStart = {
  alignItems: "flex-start",
  display: "flex",
  justifyContent: "flex-start",
};

export const flexStartVertical = {
  display: "flex",
  justifyContent: "flex-start",
} as const;

export const flexStartHorizontal = {
  alignItems: "flex-start",
  display: "flex",
} as const;

export const flexEnd = {
  alignItems: "flex-end",
  display: "flex",
  justifyContent: "flex-end",
};

export const flexEndVertical = {
  display: "flex",
  justifyContent: "flex-end",
} as const;

export const flexEndHorizontal = {
  alignItems: "flex-end",
  display: "flex",
} as const;

export const flexCenter = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
} as const;

export const flexCenterHorizontal = {
  display: "flex",
  alignItems: "center",
} as const;

export const flexSpaceHorizontal = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
} as const;

export const flexVertical = {
  display: "flex",
  flexDirection: "column",
} as const;

export const flexVerticalAlignStart = {
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
} as const;

export const flexVerticalCenter = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
} as const;

/**
 *  Grow or shrink to fill available space
 */
export const flexItemAuto = {
  flex: "1 1 auto",
} as const;

/**
 * The element will be sized according to its intrinsic size and will not grow or shrink
 */
export const flexItemIntrinsic = {
  flex: "0 0 auto",
} as const;

export const lineClamp = (lines: number) => ({
  display: "-webkit-box",
  ["-webkit-box-orient"]: "vertical",
  ["-webkit-line-clamp"]: `${lines}`,
  overflow: "hidden",
});

export const margin =
  (top: SpacingValues, right?: SpacingValues, bottom?: SpacingValues, left?: SpacingValues) =>
  ({ theme }: { theme: DefaultTheme }) =>
    getSpacingStyles("margin", [top, right, bottom, left], theme);

export const padding =
  (top: SpacingValues, right?: SpacingValues, bottom?: SpacingValues, left?: SpacingValues) =>
  ({ theme }: { theme: DefaultTheme }) =>
    getSpacingStyles("padding", [top, right, bottom, left], theme);

export const spacedChildrenVertical =
  (gap: Gap = "standard") =>
  ({ theme }: ThemeType) => ({
    "& > :first-child": {
      marginTop: 0,
    },
    "> *": {
      marginTop: typeof gap === "string" ? theme.spacing[gap] : gap,
    },
  });

export const spacedChildrenHorizontal =
  (gap: Gap = "standard") =>
  ({ theme }: ThemeType) => ({
    "& > :first-child": {
      marginLeft: 0,
    },
    "> *": {
      marginLeft: typeof gap === "string" ? theme.spacing[gap] : gap,
    },
  });

export const marginVertical =
  (space: Spacings) =>
  ({ theme }: ThemeType) => ({
    marginTop: theme.spacing[space],
    marginBottom: theme.spacing[space],
  });

export const marginHorizontal =
  (space: Spacings) =>
  ({ theme }: ThemeType) => ({
    marginLeft: theme.spacing[space],
    marginRight: theme.spacing[space],
  });

export const paddingVertical =
  (space: Spacings) =>
  ({ theme }: ThemeType) => ({
    paddingTop: theme.spacing[space],
    paddingBottom: theme.spacing[space],
  });

export const paddingHorizontal =
  (space: Spacings) =>
  ({ theme }: ThemeType) => ({
    paddingLeft: theme.spacing[space],
    paddingRight: theme.spacing[space],
  });

export const flexStartImage = () => ({
  "& > :first-child": {
    alignItems: "flex-start",
  },
});

export const visuallyHidden = {
  clip: "rect(0 0 0 0)",
  height: 0,
  opacity: 0,
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  width: 0,
} as const;
