import { ApolloError, ApolloQueryResult, gql } from "@apollo/client";
import { useEffect } from "react";

import { useB2CQuery } from "../client";
import { GET_IMMUNIZATIONS, GET_IMMUNIZATIONS_patient_immunizations } from "../schema/types";

export const immunizationQuery = gql`
  query GET_IMMUNIZATIONS {
    patient {
      immunizations {
        id
        administeredDate
        route
        units
        expirationDate
        lotNumber
        manufacturer
        name
        quantity
        site
      }
    }
  }
`;

export const useImmunizations = (): {
  error: ApolloError | undefined;
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<GET_IMMUNIZATIONS>>;
  data: GET_IMMUNIZATIONS_patient_immunizations[] | null | undefined;
} => {
  const { data, error, loading, refetch } = useB2CQuery<GET_IMMUNIZATIONS>(immunizationQuery);

  const refetchWrapper = () => {
    return refetch({});
  };

  useEffect(() => {
    refetch({});
  }, [refetch]);

  return {
    error,
    loading,
    refetch: refetchWrapper,
    data: data?.patient?.immunizations,
  };
};
