import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";

import { InsuranceList_viewer_user_patient_insuranceInfo } from "/apollo/schema";
import insuranceNoResultsImage from "/asset/image/insuranceNoResults.png";
import List from "/component/base/List";
import ListItem from "/component/base/ListItem";
import Loading from "/component/base/Loading";
import MainContent from "/component/layout/MainContent";
import InsuranceDetailsModal from "/component/modal/InsuranceDetailsModal";
import EmptyStateCta from "/component/partial/EmptyStateCta";
import { useTranslation } from "/hook";
import { reactPlugin } from "/util/appInsights.util";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  logEvent,
} from "/util/firebase.util";

import { ListContainer } from "./Insurance.styles";
import { convertToPolicyOrderString } from "./Insurance.utils";
import InsuranceItem from "./InsuranceItem";
import { useInsuranceListQuery } from "./useInsuranceListQuery";

const IMAGE_SIZE = 344;

const Insurance = () => {
  const { t } = useTranslation("insurance");
  const [isEdit, setIsEdit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [insuranceSelected, setInsuranceSelected] =
    useState<InsuranceList_viewer_user_patient_insuranceInfo | null>(null);
  const { insuranceList, loading } = useInsuranceListQuery();
  // Not sure if this issue exists outside DEV but there is a case where an insurance has all null fields
  const filteredInsuranceList = insuranceList?.filter((insurance) => insurance.memberId != null);

  // Removing functionality for adding policies as this is not available currently.
  // This feature is planned for the near future so it makes sense to leave this code in
  // so this doesn't have to be re-written.
  const allowAddPolicy = false; // insuranceList && insuranceList?.length < 3;
  const addPolicyOrderString = convertToPolicyOrderString(
    t,
    insuranceList ? insuranceList?.length + 1 : null,
  );
  const close = () => setIsOpen(false);

  const handleInsurancePress = (insuranceInfo: InsuranceList_viewer_user_patient_insuranceInfo) => {
    if (insuranceInfo.order) {
      const buttonName = `Edit ${convertToPolicyOrderString(
        t,
        insuranceInfo?.order,
      ).toLowerCase()}`;
      logEvent(AnalyticsEvent.BUTTON_CLICK, {
        user_flow: AnalyticsUserFlow.MY_ACCOUNT,
        source: AnalyticsSource.INSURANCE,
        button_name: buttonName,
      } as ButtonClickParams);
    }
  };

  const renderAddPolicy = () => (
    <ListItem.Button
      onClick={() => {
        setInsuranceSelected(null);
        setIsOpen(true);
        setIsEdit(true);
      }}
      showChevron={false}
      showBorder={false}
    >
      <InsuranceItem
        isAddPolicy={true}
        companyName={t("addPolicy")}
        policyOrder={addPolicyOrderString}
      ></InsuranceItem>
    </ListItem.Button>
  );

  return (
    <MainContent parentPage="account" pageTitle={t("title")}>
      <Helmet>
        <title>{t("title")}</title>
      </Helmet>

      {loading && <Loading />}

      {!loading &&
        (!insuranceList || insuranceList.length === 0 ? (
          <EmptyStateCta
            imageHeight={IMAGE_SIZE}
            imageWidth={IMAGE_SIZE}
            imageSource={insuranceNoResultsImage}
            title={t("emptyStateTitle")}
            subtitle={t("emptyStateSubtitle")}
          />
        ) : (
          <>
            <ListContainer>
              <List
                as="Buttons"
                data={filteredInsuranceList || []}
                onClick={(insuranceItem) => {
                  setInsuranceSelected(insuranceItem);
                  setIsOpen(true);
                  setIsEdit(false);
                  handleInsurancePress(insuranceItem);
                }}
                renderItem={({
                  companyName,
                  order,
                }: InsuranceList_viewer_user_patient_insuranceInfo) => (
                  <InsuranceItem
                    companyName={companyName || ""}
                    policyOrder={convertToPolicyOrderString(t, order)}
                  />
                )}
              />
            </ListContainer>
            {allowAddPolicy && renderAddPolicy()}
          </>
        ))}

      <InsuranceDetailsModal
        isOpen={isOpen}
        close={close}
        isEditable={isEdit}
        details={insuranceSelected}
      />
    </MainContent>
  );
};

export default withAITracking(reactPlugin, Insurance, undefined, "ai-tracking");
