import awsdk from "awsdk";
import { Form, Formik } from "formik";
import { uniq } from "lodash";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import Button from "/component/base/Button";
import CheckBox from "/component/base/CheckBox";
import List from "/component/base/List";
import Loading from "/component/base/Loading";
import { Label } from "/component/base/Text";
import FormContent from "/component/partial/FormContent";
import {
  AllergiesProps,
  useAmwellPrevisitContext,
} from "/component/provider/AmwellPrevisitProvider";
import { useTranslation } from "/hook";
import useAmwellAllergies from "/hook/useAmwellAllergies";
import { layout } from "/styles";

import { ButtonContainer, SkipButton, Title } from "../Telehealth.styles";
import { TelehealthFormProps } from "../Telehealth.types";

const Allergies = ({
  handleError,
  handleSubmit,
  initialValues,
}: TelehealthFormProps<AllergiesProps>) => {
  const { t } = useTranslation("form-telehealth-previsit");

  const { data, loading } = useAmwellAllergies();
  const { consumer, sdk } = useAmwellPrevisitContext();
  const [selectedAllergies, setSelectedAllergies] = useState<string[]>(initialValues.allergicList);

  const allergiesSchema = Yup.object({
    allergicList: Yup.array(Yup.string()),
  });

  useEffect(() => {
    if (!loading && data && data.length > 0) {
      //should remove duplicates when reloading a page
      const initialAllergiesSelected = initialValues.allergicList;
      const currentAllergies = data.filter((allergy) => allergy.isCurrent).map((c) => c.name);

      setSelectedAllergies(uniq([...initialAllergiesSelected, ...currentAllergies]));
    }
  }, [data, loading, initialValues]);

  const submitForm = async (values: AllergiesProps) => {
    try {
      if (consumer && sdk && data) {
        const allergies = data.map((allergy) => {
          allergy.isCurrent = values.allergicList.indexOf(allergy.name) >= 0;
          return allergy;
        });
        await sdk.consumerService.updateAllergies(consumer, allergies);
        handleSubmit(values);
      }
    } catch (e: any) {
      handleError(e);
    }
  };

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={{ allergicList: selectedAllergies }}
      validationSchema={allergiesSchema}
      onSubmit={submitForm}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form>
          <FormContent css={layout.margin("gutter", "skip")}>
            <Title>{t("allergies.title")}</Title>
            {loading ? (
              <Loading />
            ) : (
              <>
                <div css={layout.margin(40, "skip", 40)}>
                  <List
                    as="Divs"
                    showChevron={false}
                    showBorder={true}
                    hideLastChildBorder={true}
                    data={
                      data
                        ? data?.filter(
                            ({ name }) => name.toLowerCase() !== "no known drug allergies",
                          )
                        : []
                    }
                    renderItem={(item: awsdk.AWSDKAllergy) => {
                      const isChecked = values.allergicList.indexOf(item.name) >= 0;
                      return (
                        <CheckBox
                          css={layout.paddingVertical("standard")}
                          labelBefore={false}
                          checked={isChecked}
                          onChange={(e) => {
                            if (e.currentTarget.checked) {
                              setFieldValue(
                                "allergicList",
                                uniq([...values.allergicList, item.name]),
                              );
                            } else {
                              setFieldValue(
                                "allergicList",
                                values.allergicList.filter(
                                  (allergyName) => allergyName !== item.name,
                                ),
                              );
                            }
                          }}
                          label={<Label variant="body1Bold">{item.name}</Label>}
                        />
                      );
                    }}
                  />
                </div>
                <ButtonContainer>
                  <Button
                    css={layout.flexItemAuto}
                    variant="primary"
                    type="submit"
                    isLoading={isSubmitting}
                  >
                    {t("continue")}
                  </Button>
                  <SkipButton onClick={() => handleSubmit()} variant="borderBottom">
                    {t("skip")}
                  </SkipButton>
                </ButtonContainer>
              </>
            )}
          </FormContent>
        </Form>
      )}
    </Formik>
  );
};

export default Allergies;
