/* I have used a // @ts-nocheck comment at the top of this to disable type-checking for this file 
because we need styleLight prop to override btn-background variable but it wasnt available in 
React-add-to-calendar wrapper's prop list */

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { AddToCalendarButton } from "add-to-calendar-button-react";
import React from "react";

import { Props } from "./AddToCalendar.types";

const AddToCalendar = ({
  btnLabel,
  name,
  options,
  location,
  startDate,
  endDate,
  startTime,
  endTime,
  timeZone,
}: Props) => {
  return (
    <AddToCalendarButton
      label={btnLabel}
      name={name}
      options={options}
      location={location}
      startDate={startDate}
      endDate={endDate}
      startTime={startTime}
      endTime={endTime}
      timeZone={timeZone}
      buttonStyle={"round"}
      hideBackground={true}
      hideCheckmark={true}
      hideBranding={true}
      hideIconButton={true}
      size={"4"}
      styleLight="--btn-background: #060B34; --btn-text: #fff; --btn-background-hover: #060B34;"
    ></AddToCalendarButton>
  );
};

export default AddToCalendar;
