import styled from "styled-components";

import { card, layout } from "/styles";

export const LoadingCardsWrapper = styled.div(layout.flexSpaceHorizontal, ({ theme }) => ({
  paddingTop: theme.spacing.gutter,
  gap: theme.spacing.standard,
  // Hide the loading cards on mobile
  [theme.media.max.tabletPortrait]: {
    display: "none",
  },
}));

export const LoadingListItemsWrapper = styled.div(layout.flexVerticalAlignStart, card.wrapper);
