import { isAfter, isBefore } from "date-fns";
import React from "react";

import Live from "/asset/svg/live.svg";
import Text from "/component/base/Text";
import HoursOfOperation from "/component/partial/HoursOfOperation";
import { useTranslation } from "/hook";
import { layout } from "/styles";
import { parseDateTime } from "/util/date";
import { getEstimatedWaitTime } from "/util/facility.util";

import {
  AddressContainer,
  FacilityContainer,
  LiveImage,
  LogoImg,
  UrgentCareItemContainer,
} from "./UrgentCareItem.styles";
import { Props } from "./UrgentCareItem.types";

const UrgentCareItem = ({ facility, miles }: Props) => {
  const { t } = useTranslation("urgentCare");

  const { hoursOfOperation } = facility;
  const today = new Date();
  const hoursToday = hoursOfOperation && hoursOfOperation[today.getDay()];

  let isOpen = false;
  if (hoursToday && hoursToday.openingTime && hoursToday.closingTime) {
    const openingDateTime = parseDateTime(hoursToday.openingTime);
    const closingDateTime = parseDateTime(hoursToday.closingTime);

    if (isAfter(today, openingDateTime) && isBefore(today, closingDateTime)) {
      isOpen = true;
    }
  }

  return (
    <UrgentCareItemContainer>
      <LogoImg
        size={30}
        color="brandSecondary"
        background={{
          size: 48,
          color: "objectInverse",
        }}
      />
      <FacilityContainer>
        <Text variant="title3">{facility.facilityNameLong}</Text>
        <AddressContainer>
          <Text variant="body2">
            {[facility.address?.line1, facility.address?.city].filter(Boolean).join(", ")}
          </Text>
          <div css={layout.margin("standard", "skip", "skip", "skip")}>
            {isOpen && !!facility.waitTimeSeconds && (
              <div css={layout.flexCenterHorizontal}>
                <Text variant="body2">
                  {t("estimatedWait")}
                  <Text variant="body2Bold">{getEstimatedWaitTime(facility.waitTimeSeconds)}</Text>
                </Text>
                <LiveImage src={Live} alt="" />
              </div>
            )}
            <HoursOfOperation
              canShowAllHours={false}
              hoursOfOperation={facility.hoursOfOperation}
            />
          </div>
        </AddressContainer>
      </FacilityContainer>
      <Text variant="body2" color="textSecondary">
        {t("milesAway", { miles })}
      </Text>
    </UrgentCareItemContainer>
  );
};

export default UrgentCareItem;
