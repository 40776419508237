import styled from "styled-components";

import ButtonBase from "/component/base/Button";
import { layout } from "/styles";

export const Base = styled.div<{ $width: number }>(({ $width }) => ({
  maxWidth: $width,
  "& > *": {
    width: "100%",
  },
}));

export const Button = styled(ButtonBase)(
  layout.margin("gutter", "skip", "skip"),
  layout.padding("skip", 40),
  {
    alignSelf: "start",
  },
);
