import { gql } from "@apollo/client";

export default gql`
  mutation UpdateVerification($input: UpdateTwilioVerificationInput!) {
    updateVerification(input: $input) {
      verification {
        id
        status
        to
        type
      }
    }
  }
`;
