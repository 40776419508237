import { ThemeType } from "/theme/default";

import * as layout from "./layout";

/**
 * Base card stylings including a white background, border-radius, and shadow
 */
export const base = ({ theme }: ThemeType) => ({
  boxShadow: theme.shadows.small,
  borderRadius: 16,
  backgroundColor: theme.colors.objectInverse,
});

export const wrapper = ({ theme }: ThemeType) => ({
  borderRadius: theme.spacing.standard,
  backgroundColor: theme.colors.objectInverse,
  boxShadow: theme.shadows.small,
  marginTop: theme.spacing.condensed,
  ...layout.padding("standard", "none")({ theme }),
});

/**
 * An interactive card has the same stylings as `card.base` but also includes hover, active, and focus states
 */
export const interactive = ({ theme }: ThemeType) => ({
  ...base({ theme }),
  cursor: "pointer",
  outline: "none",
  "&:hover, &:focus": {
    boxShadow: `${theme.shadows.small}, inset 0px 0px 0px 2px ${theme.colors.objectOverlay}`,
  },
  "&:active": {
    // Remove the inset border from hover/focus to only show the outer shadow
    boxShadow: theme.shadows.small,
    backgroundColor: theme.colors.brandPrimaryLight,
  },
});
