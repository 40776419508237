import styled from "styled-components";

import { layout } from "/styles";

import Divider from "../Divider";
import { ListItemWrapperProps } from "./List.types";

export const BaseLi = styled.li<ListItemWrapperProps>`
  ${({ theme }) => layout.paddingHorizontal("gutter")({ theme })};
  ${({ theme, $hideResponsiveStates }) =>
    !$hideResponsiveStates &&
    `
    &:hover {
      background-color: ${theme.colors.backgroundSurfaceHover};
    }
    &:active {
      background-color: ${theme.colors.backgroundSurfaceActive};
    }
  `}
`;

export const ListDivider = styled(Divider)<{ $isListItemClickable?: boolean }>`
  ${({ theme, $isListItemClickable = true }) =>
    $isListItemClickable
      ? layout.margin("none", "none", "none", "gutter")({ theme })
      : layout.marginHorizontal("gutter")({ theme })}
`;
