import styled from "styled-components";

import { layout } from "/styles";

export const SignUpContainer = styled.div(layout.absoluteFill, ({ theme }) => ({
  [theme.media.tablet]: {
    display: "grid",
    gridTemplateColumns: "1fr 2fr",
    gridTemplateRows: "1fr",
    height: "100vh",
  },
  [theme.media.max.tabletPortrait]: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    "& > :first-child": {
      display: "none",
    },
    "& > *": {
      width: "100%",
    },
  },
}));
