import styled from "styled-components";

export const Base = styled.div({
  display: "flex",
  alignItems: "center",
  padding: "0.3rem",
});

export const RatingText = styled.span(({ theme }) => ({
  paddingLeft: "0.5rem",
  fontFamily: theme.fontFamilies.CeraProRegular,
  fontSize: theme.fontSizes.body2,
  color: theme.colors.textPrimary,
  letterSpacing: "0.01em",
  fontWeight: theme.fontWeights.body2Bold,
}));
