import { gql, useQuery } from "@apollo/client";

import { PharmacyPreferred } from "/apollo/schema/types";

const GET_PREFERRED_PHARMACY = gql`
  query PharmacyPreferred {
    viewer {
      user {
        patient {
          id
          preferredPharmacy {
            name
            pharmacyType
            address {
              line1
              line2
              postalCode
              state
              city
            }
            contact {
              phoneNumber
            }
          }
        }
      }
    }
  }
`;

export default () => useQuery<PharmacyPreferred>(GET_PREFERRED_PHARMACY);
