import styled from "styled-components";

import { Text } from "/component/base";
import { layout } from "/styles";

export const ContentWrapper = styled.div(layout.flexLTR, {
  width: "100%",
});

export const TextContent = styled.div(
  layout.flexVertical,
  layout.spacedChildrenVertical("condensed"),
  { width: "100%" },
);

export const TitleContent = styled.div(layout.flexSpaceHorizontal);

export const TitleBadge = styled(Text)(
  ({ theme }) => ({
    color: "#7D6920",
    backgroundColor: "#FEF5D5",
    borderRadius: theme.spacing.base,
    marginRight: theme.spacing.standard,
  }),
  layout.padding("base", "condensed", "base", "condensed"),
);
