// Fixes unescaped characters and improper formatting in Message previews.
export const parseBodyPreview = (preview?: string) =>
  preview
    ?.replace(/&ldquo;/gi, "“")
    .replace(/&nbsp;/gi, " ")
    .replace(/&quot;/gi, '"')
    .replace(/&rdquo;/gi, "”")
    .replace(/&rsquo;/gi, "’")
    .replace(/([^\s\d]),(\w)/gi, "$1, $2")
    .replace(/([^\s\d])\.([^\W])/gi, "$1. $2")
    .trimEnd()
    .trimStart();
