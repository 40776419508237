import React from "react";

import background from "/asset/image/myHealthSignedOut.png";
import ContactUs from "/component/partial/ContactUs";
import EmptyStateCta from "/component/partial/EmptyStateCta";
import { useTranslation } from "/hook";
import { layout } from "/styles";

import SignUpSignInButton from "../SignUpSignInButton";
import { Base, ContactUsWrapper, EmptyBase } from "./MainContentLoginCta.styles";
import { Props } from "./MainContentLoginCta.types";

const SIGN_UP_V2_ENABLED = process.env.SIGN_UP_V2_ENABLED === "true";
const IMAGE_MAX_WIDTH = 500;
const IMAGE_WIDTH = Math.min(window.screen.width, IMAGE_MAX_WIDTH);
const IMAGE_HEIGHT = (350 / IMAGE_MAX_WIDTH) * IMAGE_WIDTH;
const MainContentLoginCta = ({
  className,
  login,
  subtitle,
  ctaText,
  title,
  analyticsParams,
}: Props) => {
  const { t } = useTranslation("partial-main-content-login-cta");

  if (SIGN_UP_V2_ENABLED) {
    return (
      <EmptyBase className={className}>
        <EmptyStateCta
          imageSource={background}
          title={title ?? t("title")}
          subtitle={subtitle ?? ""}
          imageWidth={IMAGE_WIDTH}
          imageHeight={IMAGE_HEIGHT}
        />
        <div
          css={[
            layout.marginVertical("standard"),
            layout.flexVertical,
            layout.spacedChildrenVertical("condensed"),
          ]}
        >
          <SignUpSignInButton analyticsParams={analyticsParams} />
        </div>
        <ContactUsWrapper>
          <ContactUs />
        </ContactUsWrapper>
      </EmptyBase>
    );
  }

  return (
    <Base className={className}>
      <EmptyStateCta
        imageSource={background}
        title={title ?? t("title")}
        subtitle={subtitle ?? ""}
        ctaText={ctaText ?? t("ctaText")}
        onClickCta={login}
        imageWidth={IMAGE_WIDTH}
        imageHeight={IMAGE_HEIGHT}
      />
      <ContactUsWrapper>
        <ContactUs />
      </ContactUsWrapper>
    </Base>
  );
};

export default MainContentLoginCta;
