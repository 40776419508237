import styled from "styled-components";

import PickerFieldBase from "/component/partial/formik/PickerField";

export const PickerField = styled(PickerFieldBase)({
  width: "100%",
  "& > *": {
    width: "100%",
  },
});
