const getHumanPharmacyType = (pharmacyType?: string | null): string | null => {
  let humanPharmacyType: string | null;
  switch (pharmacyType?.toLowerCase()) {
    case "retail":
      humanPharmacyType = "Retail";
      break;
    case "mailorder":
      humanPharmacyType = "Home delivery";
      break;
    default:
      humanPharmacyType = null;
      break;
  }

  return humanPharmacyType;
};

export { getHumanPharmacyType };
