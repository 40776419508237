import { PersonalInfo_viewer_user_patient_homeAddress } from "/apollo/schema/types";

export const buildAddressValue = (address: PersonalInfo_viewer_user_patient_homeAddress | null) => {
  if (address) {
    const line1 = [address.line1, address.line2].filter((el) => el).join(", ");
    const line2 = [address.city, address.state, address.postalCode].filter((el) => el).join(", ");
    return `${line1}\n${line2}`;
  } else {
    return null;
  }
};
