import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { GET_IMMUNIZATIONS_patient_immunizations } from "/b2c/schema/types";
import { List, Text } from "/component/base";
import MainContent from "/component/layout/MainContent";
import { useAnalytics } from "/hook/useAnalytics/useAnalytics";
import { reactPlugin } from "/util/appInsights.util";

import { InfoListItem, ListContainer } from "./Immunization.styles";

const Immunization = () => {
  const { t } = useTranslation("immunizations");

  const location = useLocation<{
    immunization: GET_IMMUNIZATIONS_patient_immunizations;
  }>();
  const { recordAnalyticsEvent } = useAnalytics();

  const { immunization } = location.state || {};

  useEffect(() => {
    recordAnalyticsEvent("viewImmunizationDetails");
  }, [recordAnalyticsEvent]);

  const details = [
    { label: t("administeredDate"), value: immunization.administeredDate },
    { label: t("manufacturer"), value: immunization.manufacturer },
    { label: t("lotNumber"), value: immunization.lotNumber },
    {
      label: t("expirationDate"),
      value: immunization.expirationDate || "-",
    },
    { label: t("route"), value: immunization.route },
    { label: t("site"), value: immunization.site },
  ];

  return (
    <MainContent parentPage="immunizations" pageTitle={immunization.name}>
      <Helmet>
        <title>{immunization.name}</title>
      </Helmet>
      <ListContainer>
        <List
          as="Divs"
          data={details}
          showChevron={false}
          hideLastChildBorder
          renderItem={({ label, value }) => (
            <InfoListItem>
              <Text>{label}</Text>
              <Text color="textSecondary">{value}</Text>
            </InfoListItem>
          )}
        />
      </ListContainer>
    </MainContent>
  );
};

export default withAITracking(reactPlugin, Immunization, undefined, "ai-tracking");
