import styled from "styled-components";

import { layout } from "/styles";

export const InsuranceItemContainer = styled.div(
  layout.flexStartHorizontal,
  layout.padding("expanded", "skip", "standard", "skip"),
);

export const TextContainer = styled.div(
  layout.flexVertical,
  layout.spacedChildrenVertical("base"),
  layout.margin("skip", "skip", "skip", "gutter"),
);

export const InactiveContainer = styled.div(layout.padding("base"), ({ theme }) => ({
  backgroundColor: theme.colors.backgroundSurfaceHover,
  alignSelf: "baseline",
}));
