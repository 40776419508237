import styled from "styled-components";

import Button from "/component/base/Button";
import { layout, transition } from "/styles";

export const CaregiverButtonBlank = styled(Button)(({ theme }) => ({
  borderRadius: 8,
  paddingTop: theme.spacing.base,
  paddingRight: theme.spacing.condensed,
  paddingBottom: theme.spacing.base,
  paddingLeft: theme.spacing.base * 3,
  display: "inline-flex",
  alignItems: "center",
  background: theme.colors.white,
  [`& > :first-child`]: {
    marginRight: theme.spacing.condensed,
  },
}));

export const CaregiverButtonUnstyled = styled(Button)(layout.flexCenterHorizontal);

export const CaregiverModal = styled.div<{ $animateFromBottom: boolean }>(
  layout.flexVertical,
  ({ $animateFromBottom }) => transition.accordian($animateFromBottom),
  ({ theme }) => ({
    backgroundColor: theme.colors.backgroundPrimary,
    borderRadius: 16,
    boxShadow: theme.shadows.base,
    justifyContent: "flex-start",
    maxHeight: 600,
    maxWidth: 350,
  }),
);

export const CaregiverItemsContainer = styled.div(
  layout.spacedChildrenVertical("condensed"),
  layout.flexVerticalAlignStart,
  layout.padding("standard"),
  () => ({
    overflowY: "auto",
  }),
);
