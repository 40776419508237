import awsdk from "awsdk";

import { useAmwell, useAmwellQuery } from "/hook";

/**
 * This hook allows for the retrieval of information pertianing to consumers allergies
 * on certian medications.
 */

const useAmwellAllergies = () => {
  const { consumer, sdk } = useAmwell();
  const fetch = async () =>
    sdk && consumer ? await sdk.consumerService.getAllergies(consumer) : undefined;

  return useAmwellQuery<awsdk.AWSDKAllergy[]>(fetch);
};

export default useAmwellAllergies;
