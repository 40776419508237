import styled from "styled-components";

import MainContent from "/component/layout/MainContent";
import { layout } from "/styles";

export const Wrapper = styled(MainContent)({
  textAlign: "center",
});

export const ContentWrapper = styled.div(
  layout.flexVertical,
  layout.margin("none", "auto"),
  ({ theme }) => ({
    width: 300,
    maxWidth: "100%",
    alignItems: "stretch",
    paddingTop: theme.spacing.expanded,
    [theme.media.tabletPortrait]: {
      paddingTop: theme.spacing[60],
    },
  }),
);
