import styled from "styled-components";

import { styles } from "/component/base/Button";
import { layout } from "/styles";

export const ContactUsWrapper = styled.div(({ theme }) => ({
  marginTop: theme.spacing.gutter,
}));

export const Base = styled.div(layout.flexVerticalCenter, ({ theme }) => ({
  flex: 1,
  [`${styles.Base}`]: {
    marginTop: theme.spacing.gutter,
  },
}));

export const EmptyBase = styled.div(layout.flexVerticalCenter, {
  flex: 1,
});
