import styled from "styled-components";

import { layout } from "/styles";

export const Base = styled.div({
  flex: "1 1 auto",
  minHeight: "100vh",
});

export const TelehealthWaitingContainer = styled.div(layout.margin("gutter"), {
  flex: "1 1 auto",
  minHeight: "100vh",
});
