import { useMutation } from "@apollo/client";
import { omit } from "lodash";
import React, { useEffect, useState } from "react";

import { auth } from "/apollo/client/local";
import {
  CaregiverStatus,
  ConfirmCaregiverAccess,
  ConfirmCaregiverAccessVariables,
  GetCaregiverAccess_viewer_user_caregiverAccess,
} from "/apollo/schema/types";
import { Alert, Button, Divider, Link, Loading, Modal, Text } from "/component/base";
import {
  CaregiverStatusType,
  CaregiverType,
  useCaregiverProvider,
} from "/component/provider/CaregiverProvider";
import { useToastContext } from "/component/provider/ToastProvider";
import { useAmwell, useContactInfo, useTranslation } from "/hook";
import { layout } from "/styles";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  AppModalParams,
  ButtonClickParams,
  logEvent,
} from "/util/firebase.util";

import confirmRevokedCaregiverAccessGql from "../../page/Account/page/CaregiverIndex/confirmRevokedCaregiverAccess";
import CaregiverCard from "../../partial/CaregiverCard";
import { Props } from "./CaregiverProxyModal.types";

export interface CaregiverAccess {
  firstName: string | null;
  lastName: string | null;
  id: string | null;
  isMinor: boolean;
}

const CaregiverProxyModal = ({
  caregiverId,
  caregiverAccesses,
  isOpen,
  name,
  onCancel,
  onClose,
}: Props) => {
  const { t } = useTranslation("partial-CaregiverProxyModal");

  const { renewAuth, toggleDependent } = useAmwell();
  const { contactByName } = useContactInfo();
  const { showToast } = useToastContext();
  const {
    clearCaregiver,
    setCaregiver,
    setCaregiverToggle,
    caregiverAccess: { caregiverAccountsRefetch },
  } = useCaregiverProvider();

  const [revokedUser, setRevokedUser] = useState<CaregiverAccess>();
  const [isAccessRevokedAlertOpen, setAccessRevokedAlert] = useState(false);
  const closeAccessRevokedAlert = () => setAccessRevokedAlert(false);

  const [loading, setLoading] = useState(false);

  const logButtonClickEvent = (buttonName: string, buttonDescription?: string) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.FIND_CITYMD,
      source: AnalyticsSource.MODAL_USER_PROXY,
      button_name: buttonName,
      button_description: buttonDescription,
    } as ButtonClickParams);
  };

  const switchMyAccount = async () => {
    setLoading(true);

    logButtonClickEvent("Account", "My account");

    clearCaregiver();
    auth.setCaregiver(null);
    renewAuth();
  };

  const switchCaregiver = async (user: GetCaregiverAccess_viewer_user_caregiverAccess) => {
    setLoading(true);

    logButtonClickEvent("Account", "Caregiver account");

    if (user.statusDetails?.status === CaregiverStatus.REVOKED) {
      setRevokedUser({
        firstName: user.firstName,
        lastName: user.lastName,
        id: user.id,
        isMinor: !!user.isMinor,
      });
      setAccessRevokedAlert(true);
      return;
    }

    const _caregiver = omit(user, "__typename") as CaregiverType;
    _caregiver.statusDetails = omit(user.statusDetails, "__typename") as CaregiverStatusType;
    setCaregiver(_caregiver);
    setCaregiverToggle(true);
    auth.setCaregiver(user.id);

    if (user.isMinor && user.birthDate)
      await toggleDependent({
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        dob: new Date(user.birthDate),
        gender: user.gender?.toString() || "",
      });
    else await renewAuth();
  };

  const renderAccessRevokedAlert = () => {
    const complianceEmail = contactByName("compliance")?.emailAddress;

    let contactButton;
    if (revokedUser?.isMinor) {
      if (complianceEmail) {
        contactButton = (
          <Link.Anchor target="#" href={`mailto:${complianceEmail}`}>
            {t("dialog.accessRevoked.contactPrivacy")}
          </Link.Anchor>
        );
      }
    } else {
      contactButton = (
        <Link.Anchor target="#" href={`mailto:AppSupport@summithealth.com`}>
          {t("dialog.accessRevoked.contactSupport")}
        </Link.Anchor>
      );
    }

    return (
      <Alert
        close={closeAccessRevokedAlert}
        footer={
          <Button fullWidth="flex" onClick={confirmRevokedCaregiverAccess}>
            {t("dialog.accessRevoked.continue")}
          </Button>
        }
        title={t("dialog.accessRevoked.title", {
          name: `${revokedUser?.firstName} ${revokedUser?.lastName} `,
        })}
        isOpen={isAccessRevokedAlertOpen}
      >
        <Text css={{ whiteSpace: "pre-line" }} variant="body1" color="textSecondary">
          {t("dialog.accessRevoked.subtitle")}
        </Text>
        {contactButton}
      </Alert>
    );
  };

  const confirmRevokedCaregiverAccess = async () => {
    closeAccessRevokedAlert();
    if (!revokedUser?.id) return;
    const response = await confirmInviteCaregiverAccess({
      variables: {
        data: {
          caregiverAccessId: revokedUser.id,
        },
      },
    });
    if (response.errors?.length || response.data?.confirmInviteCaregiverAccess?.errors?.length) {
      showToast({
        message: t("error"),
        icon: "alert",
      });
    }
    caregiverAccountsRefetch();
  };

  const [confirmInviteCaregiverAccess] = useMutation<
    ConfirmCaregiverAccess,
    ConfirmCaregiverAccessVariables
  >(confirmRevokedCaregiverAccessGql);

  useEffect(() => {
    if (isAccessRevokedAlertOpen) {
      logEvent(AnalyticsEvent.APP_MODAL_VIEW, {
        user_flow: AnalyticsUserFlow.CAREGIVER,
        modal_name: AnalyticsSource.MODAL_CAREGIVER_ACCESS_REVOKED,
      } as AppModalParams);
    }
  }, [isAccessRevokedAlertOpen]);

  return (
    <Modal
      css={loading ? { maxWidth: 100 } : { maxWidth: 400 }}
      key="CaregiverProxyModal"
      header={
        !loading ? (
          <Modal.Header
            hideHeaderLeft={false}
            close={() => {
              if (onCancel) {
                onCancel();
              } else {
                onClose();
              }
            }}
          />
        ) : (
          <></>
        )
      }
      isOpen={isOpen}
    >
      {loading ? (
        <Loading />
      ) : (
        <div css={layout.spacedChildrenVertical("standard")}>
          <div css={layout.flexVerticalCenter}>
            <Text
              css={{ textAlign: "center", marginBottom: 32 }}
              color="textNavyBlue"
              variant="title3"
            >
              {t("title")}
            </Text>
          </div>

          <CaregiverCard isSelected={!caregiverId} name={name} onClick={switchMyAccount} />
          {caregiverAccesses.map((proxyProp, idx) => (
            <React.Fragment key={idx}>
              <Divider />
              <CaregiverCard
                key={idx}
                isSelected={caregiverId === proxyProp.id}
                name={{
                  firstName: proxyProp.firstName,
                  lastName: proxyProp.lastName,
                }}
                onClick={() => switchCaregiver(proxyProp)}
              />
            </React.Fragment>
          ))}
        </div>
      )}
      {renderAccessRevokedAlert()}
    </Modal>
  );
};

export default CaregiverProxyModal;
