import { ApolloError, ApolloQueryResult, gql, useQuery } from "@apollo/client";

import {
  InsuranceList,
  InsuranceList_viewer_user_patient_insuranceInfo,
} from "/apollo/schema/types";

const GET_PERSONAL_INSURANCE_LIST = gql`
  query InsuranceList {
    viewer {
      user {
        patient {
          insuranceInfo {
            companyName
            memberId
            groupNumber
            order
          }
        }
      }
    }
  }
`;

export const useInsuranceListQuery = (): {
  error: ApolloError | undefined;
  loading: boolean;
  insuranceList: InsuranceList_viewer_user_patient_insuranceInfo[] | null | undefined;
  refetch: () => Promise<ApolloQueryResult<InsuranceList>>;
} => {
  const { data, error, loading, refetch } = useQuery<InsuranceList>(GET_PERSONAL_INSURANCE_LIST, {
    fetchPolicy: "no-cache",
  });

  return {
    error,
    loading,
    insuranceList: data?.viewer?.user?.patient?.insuranceInfo,
    refetch,
  };
};

export default useInsuranceListQuery;
