import styled from "styled-components";

import { Logo } from "/component/base/Icon";
import { card, layout } from "/styles";

export const AddressContainer = styled.div(({ theme }) => ({
  marginTop: theme.spacing.base,
  "& > svg": {
    marginRight: theme.spacing.base * 1.5,
  },
}));

export const FacilityContainer = styled.div(layout.flexVertical, ({ theme }) => ({
  flexGrow: 1,
  alignItems: "flex-start",
  marginLeft: theme.spacing.standard,
}));

export const UrgentCareItemContainer = styled.div(
  card.base,
  layout.padding("standard", "gutter", "standard", "standard"),
  ({ theme }) => ({
    flexGrow: 1,
    display: "flex",
    marginBottom: theme.spacing.standard,
  }),
);

export const LiveImage = styled.img(layout.margin("skip", "skip", "skip", "condensed"));

export const LogoImg = styled(Logo)(({ theme }) => ({
  [theme.media.max.mobile]: {
    display: "none",
  },
}));
