import styled from "styled-components";

import { Text } from "/component/base";
import { TriangleDown } from "/component/base/Icon";
import { layout } from "/styles";

export const ElevatedOrLoweredIcon = styled(TriangleDown)<{ $elevated?: boolean | null }>(
  ({ $elevated }) => ({ transform: $elevated ? "rotate(180deg)" : "none" }),
);

export const HighlightedValue = styled(Text).attrs({ variant: "body1Bold" })(
  layout.lineClamp(2),
  layout.margin("skip", "skip", "base"),
  layout.padding("base", "condensed"),
  layout.spacedChildrenHorizontal("base"),
  ({ theme }) => ({
    background: theme.colors.statusWarning,
    borderRadius: 4,
    color: "#7D6920",
    display: "inline-block",
  }),
);

export const TooltipWrapper = styled.div(
  layout.flexCenterHorizontal,
  layout.spacedChildrenHorizontal("condensed"),
  { whiteSpace: "nowrap" },
);
