// Modules
import styled from "styled-components";

import Text from "/component/base/Text";
import { layout } from "/styles";
import { ThemeType } from "/theme/default";

import { Props } from "./Avatar.types";

/**
 * This config comes from the designs, but you can feel free to override the styles
 * if you need to change something.
 */
const config = {
  xSmall: {
    borderWidth: 0,
  },
  small40: {
    borderWidth: 0,
  },
  small: {
    borderWidth: 0,
  },
  large: {
    borderWidth: 3,
  },
  xLarge: {
    borderWidth: 0,
  },
} as const;

const baseStyles = ({ theme, size: sizeProp = "small" }: ThemeType & Omit<Props, "alt">) => {
  const { borderWidth } = config[sizeProp];
  const size = theme.sizes.avatar[sizeProp];

  return {
    height: size,
    width: size,
    borderRadius: "50%",
    border: `solid ${borderWidth}px ${theme.colors.objectInverse}`,
  };
};

export const Image = styled.img(baseStyles, {
  objectFit: "cover",
  objectPosition: "0 0",
});

export const ImageText = styled(Text)(baseStyles, layout.flexCenter, ({ theme }) => ({
  textTransform: "uppercase",
  backgroundColor: theme.colors.objectSubtle,
}));
