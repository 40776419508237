import React from "react";

import Spinner from "/component/base/Spinner";
import Text from "/component/base/Text";

import { List, Modal, Page } from "./Loading.styles";
import { Props } from "./Loading.types";

const getComponent = (variant: Props["variant"]) => {
  switch (variant) {
    case "list":
      return List;
    case "modal":
      return Modal;
    default:
      return Page;
  }
};

/**
 * Displays a shared page loading state.
 */
const Loading = ({ className, message, variant = "page" }: Props) => {
  const Component = getComponent(variant);

  return (
    <Component className={className}>
      <Spinner />
      {!!message && <Text aria-label={`Loading ${message}`}>{message}</Text>}
    </Component>
  );
};

export default Loading;
