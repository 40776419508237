import styled from "styled-components";

import { layout } from "/styles";

export const Inputs = styled.div(({ theme }) => ({
  [theme.media.max.tabletPortrait]: {
    ...layout.flexVertical,
    ...layout.spacedChildrenVertical()({ theme }),
  },
  [theme.media.tablet]: {
    ...layout.flexStartHorizontal,
    ...layout.spacedChildrenHorizontal()({ theme }),
  },
}));
