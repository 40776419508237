import styled from "styled-components";

import Button from "/component/base/Button";
import { layout } from "/styles";

export const ButtonStyled = styled(Button)(layout.flexCenter, ({ theme }) => {
  const baseStyles = {
    width: 40,
    height: 40,
    borderRadius: 30,
  };

  return {
    ...baseStyles,
    border: "none",
    backgroundColor: theme.colors.objectInverse,
    "&:focus, &:hover": {
      border: `solid 1px ${theme.colors.objectInverse}`,
    },
    "& > *": {
      // The underlying button style will shrink the icon down to fix in the 40x40 box.
      // This prevents it.
      flex: "0 0 auto",
    },
  };
});
