import styled from "styled-components";

import AppointmentCardBase from "/component/partial/AppointmentCard";
import { layout } from "/styles";

export const Base = styled.ol(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  [theme.media.max.tabletPortrait]: {
    ...layout.spacedChildrenVertical()({ theme }),
  },

  [theme.media.tablet]: {
    display: "grid",
    gridTemplateColumns: "2fr 2fr",
    gridGap: theme.spacing.standard,
  },
}));

export const AppointmentCard = styled(AppointmentCardBase)(({ theme }) => ({
  [theme.media.tabletPortrait]: {
    flexBasis: "50%",
  },
}));
