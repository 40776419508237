import { useEffect, useReducer, useState } from "react";

import { useDebounce, useGoogleMaps, useMounted } from "/hook";
import { reducer } from "/util";

import { Parameters, State } from "./usePlaceAutocomplete.types";

/**
 * usePlaceAutoComplete — Given an input string, this hook returns a state
 * object that contains a list of suggested places, using response data from the
 * Google Maps Place library. In addition to the response data, it
 * also includes boolean values to indicate loading and error states.
 *
 * It accepts parameters as an object with keys representing an input string and
 * a session token ID, which is used for billing purposes. See more about
 * session tokens here:
 * https://developers.google.com/maps/documentation/places/web-service/autocomplete?hl=en#session_tokens
 **/
const usePlaceAutoComplete = ({ input, sessionToken }: Parameters) => {
  const [autocompleteService, setAutocompleteService] =
    useState<google.maps.places.AutocompleteService>();
  const debouncedInput = useDebounce(input);
  const [state, dispatch] = useReducer(reducer.reducer, reducer.initialState);
  const googleMaps = useGoogleMaps();
  const mountedRef = useMounted();

  useEffect(() => {
    dispatch({ type: reducer.actionTypes.INIT });
  }, []);

  useEffect(() => {
    if (!mountedRef.current) {
      return;
    }

    if (!debouncedInput) {
      dispatch({ type: reducer.actionTypes.INIT });
      return;
    }

    if (autocompleteService) {
      dispatch({ type: reducer.actionTypes.LOADING });

      autocompleteService.getPlacePredictions(
        {
          componentRestrictions: { country: "us" },
          input: debouncedInput,
          sessionToken,
        },
        (predictions, status) => {
          if (mountedRef.current) {
            if (!predictions || status !== google.maps.places.PlacesServiceStatus.OK) {
              dispatch({ type: reducer.actionTypes.FAILURE });
            } else {
              dispatch({ payload: predictions, type: reducer.actionTypes.SUCCESS });
            }
          }
        },
      );
    }
  }, [debouncedInput, sessionToken]);

  useEffect(() => {
    if (googleMaps && !autocompleteService) {
      setAutocompleteService(new googleMaps.places.AutocompleteService());
    }
  }, [googleMaps]);

  return state as State;
};

export default usePlaceAutoComplete;
