import { createContext } from "react";

import { CountlyKey, CountlySegmentsPublic } from "/util/countly";

export interface AnalyticsContextProps {
  recordAnalyticsEvent: (key: CountlyKey, segments?: Omit<CountlySegmentsPublic, "auth">) => void;
}

const AnalyticsContext = createContext<AnalyticsContextProps | undefined>(undefined);

AnalyticsContext.displayName = "AnalyticsContext";

export { AnalyticsContext };
