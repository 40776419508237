import { times } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import medicalInfoImage from "/asset/image/medicalInfo.png";
import telehealthImage from "/asset/image/telehealth.png";
import wellnessImage from "/asset/image/wellness.png";
import { Text } from "/component/base";
import { layout } from "/styles";

import { GeneralTutorialsContainer } from "./GeneralTutorials.styles";
import TutorialScreen from "./TutorialScreen";

const MAX_SCREENS = 3;

const GeneralTutorials = () => {
  const { t } = useTranslation("screen-generalTutorials");
  const [currentScreenIndex, setCurrentScreenIndex] = useState(0);
  const tutorialScreens = [
    {
      title: t("page1.title"),
      subTitle: t("page1.subTitle"),
      image: wellnessImage,
    },
    {
      title: t("page2.title"),
      subTitle: t("page2.subTitle"),
      image: medicalInfoImage,
    },
    {
      title: t("page3.title"),
      subTitle: t("page3.subTitle"),
      image: telehealthImage,
    },
  ];

  const setupTutorialScreen = () => {
    setInterval(() => {
      setCurrentScreenIndex((currentScreenIndex) => (currentScreenIndex + 1) % MAX_SCREENS);
    }, 5000);
  };

  useEffect(() => {
    setupTutorialScreen();
  }, []);

  const currentScreen = tutorialScreens[currentScreenIndex || 0];

  return (
    <GeneralTutorialsContainer>
      <TutorialScreen
        title={currentScreen.title}
        subtitle={currentScreen.subTitle}
        image={currentScreen.image}
      />
      <div css={[{ bottom: 0 }, layout.spacedChildrenHorizontal("base")]}>
        {times(MAX_SCREENS).map((idx) => (
          <Text
            key={idx}
            color={idx === currentScreenIndex ? "objectInverse" : "brandNavyLight"}
            variant="title1"
          >
            .
          </Text>
        ))}
      </div>
    </GeneralTutorialsContainer>
  );
};

export default GeneralTutorials;
