import React, { useState } from "react";

import BackButton from "/component/partial/BackButton";
import { useToastContext } from "/component/provider/ToastProvider";
import { CaregiverFormType } from "/constant/caregiver.constant";
import { layout } from "/styles";

import { AddCaregiver, AddChild, ChildVerification, InviteCaregiver } from "../CaregiverForms";
import { CaregiverFormProps, CaregiverMetadata } from "../CaregiverForms/CaregiverForms.types";
import { CaregiverContainer, ProgressBarContainer } from "./CaregiverWizard.styles";

interface CaregiverFormsConfigProps {
  [CareGiverForms: string]: {
    index: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    form: (props: CaregiverFormProps<any>) => JSX.Element;
  };
}

export const CaregiverFormsConfig = {
  [CaregiverFormType.AddCaregiver]: {
    index: 1,
    form: AddCaregiver,
  },
  [CaregiverFormType.InviteCaregiver]: {
    index: 2,
    form: InviteCaregiver,
  },
  [CaregiverFormType.AddChild]: {
    index: 3,
    form: AddChild,
  },
  [CaregiverFormType.ChildVerification]: {
    index: 4,
    form: ChildVerification,
  },
};

interface Props {
  currentForm: string;
  setForm: (formName: CaregiverFormType) => void;
  completeWizard: () => void;
  cancelWizard: () => void;
  formConfig?: CaregiverFormsConfigProps;
  metadata?: CaregiverMetadata;
}
const CaregiverWizard = ({
  currentForm,
  formConfig = CaregiverFormsConfig,
  setForm,
  completeWizard,
  cancelWizard,
  metadata: initialMeta,
}: Props) => {
  const { form: CaregiverForm } = formConfig[currentForm];
  const { showToast } = useToastContext();
  const [metadata, setMetadata] = useState<CaregiverMetadata | undefined>(initialMeta);

  const handleError = (e: Error) => {
    showToast({
      icon: "alert",
      message: e.message,
    });
  };

  const formSubmit = (nextForm?: CaregiverFormType) => {
    if (nextForm) setForm(nextForm);
    else completeWizard();
  };

  const closeWizard = () => {
    cancelWizard();
  };

  return (
    <CaregiverContainer>
      <div css={[layout.flexVerticalCenter, layout.spacedChildrenVertical("condensed")]}>
        <ProgressBarContainer>
          {closeWizard && (
            <BackButton
              css={{ backgroundColor: "transparent" }}
              onClick={closeWizard}
              variant="primary"
              icon="close"
            />
          )}
        </ProgressBarContainer>
      </div>
      <div
        css={[
          layout.flexVerticalCenter,
          layout.margin("expanded", "skip", "skip", "skip"),
          {
            width: "100%",
          },
        ]}
      >
        <CaregiverForm
          metadata={metadata}
          formName={currentForm}
          setMetadata={setMetadata}
          handleError={handleError}
          handleSubmit={formSubmit}
        />
      </div>
    </CaregiverContainer>
  );
};

export default CaregiverWizard;
