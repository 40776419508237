import React from "react";
import { useTheme } from "styled-components";

import { GetDiagnosticReport_diagnosticReport_observations } from "/apollo/schema";
import badgeElevated from "/asset/image/badgeElevated.png";
import badgeLowered from "/asset/image/badgeLowered.png";
import { Button, Text } from "/component/base";
import Tooltip from "/component/base/Tooltip";
import { useTranslation, useViewportSize } from "/hook";
import { layout } from "/styles";

import { ElevatedOrLoweredIcon, HighlightedValue, TooltipWrapper } from "./ObservationValue.styles";
import { getContentfulNotes } from "./ObservationValue.util";

const ObservationValue = ({
  onSelect,
  observation,
  truncate = true,
}: {
  observation: GetDiagnosticReport_diagnosticReport_observations;
  onSelect?: (observation: GetDiagnosticReport_diagnosticReport_observations) => void;
  truncate?: boolean;
}) => {
  const {
    elevated,
    highlight,
    lowered,
    name,
    notes: notesProp,
    reference,
    value,
    valueString,
  } = observation;
  const { screenSizes } = useTheme();
  const { t } = useTranslation("result");
  const { width } = useViewportSize();
  const notes = getContentfulNotes({ name, notes: notesProp, value, valueString });
  const hasNotes = !!notes.length;
  const hasValue = !!value || !!valueString;
  const isElevatedOrLowered = !!(elevated || lowered);
  const isMobile = !!width && width > screenSizes.tabletPortrait;
  const truncationIndex = isMobile ? 20 : 50;
  const shouldHighlight = (highlight || isElevatedOrLowered) && hasValue;
  const shouldTruncate =
    name && name.length >= truncationIndex && (valueString || `${value}`)?.length >= 15;

  if (truncate && (hasNotes || shouldTruncate)) {
    return (
      <Button onClick={() => onSelect?.(observation)} variant="borderBottom">
        {t("button.viewReport")}
      </Button>
    );
  }

  const renderedValue = shouldHighlight ? (
    <HighlightedValue>
      {isElevatedOrLowered && (
        <ElevatedOrLoweredIcon
          aria-label={elevated ? t("elevated") : t("lowered")}
          $elevated={elevated}
          size={12}
        />
      )}
      <span>{value || valueString || "—"}</span>
    </HighlightedValue>
  ) : (
    <Text css={layout.lineClamp(2)} variant="body2Bold">
      {value || valueString || "—"}
    </Text>
  );

  const renderedReference = reference ? (
    <Text color="textSecondary" variant="body2">
      {t("normalRange", { reference })}
    </Text>
  ) : undefined;

  if (isElevatedOrLowered) {
    const anchor = <Tooltip.Anchor>{renderedValue}</Tooltip.Anchor>;

    return (
      <>
        <Tooltip anchor={anchor} location="top-end" variant="secondary">
          <TooltipWrapper>
            <img
              alt=""
              aria-hidden
              height={32}
              src={elevated ? badgeElevated : badgeLowered}
              width={32}
            />

            <div css={[layout.flexVertical, layout.flexStart]}>
              <Text variant="body2Bold">{elevated ? t("elevated") : t("lowered")}</Text>

              <Text variant="body2">{t("outsideRange")}</Text>
            </div>
          </TooltipWrapper>
        </Tooltip>

        {renderedReference}
      </>
    );
  }

  return (
    <>
      {renderedValue}
      {renderedReference}
    </>
  );
};

export default ObservationValue;
