import styled from "styled-components";

import { ThemeType } from "/theme/default";

import { Props, StyleProps } from "./Text.types";

/**
 * Apply our theme to generate text styles
 */
export const getTextStyles = ({
  align,
  color = "textPrimary",
  truncate = false,
  variant = "body1",
  theme,
}: ThemeType & Omit<Props, "children">) => ({
  fontFamily: theme.fontVariants[variant],
  fontSize: theme.fontSizes[variant],
  fontWeight: theme.fontWeights[variant],
  lineHeight: theme.lineHeights[variant],
  textAlign: align,
  color: theme.colors[color] || "inherit",

  // Show ellipsis if we want this text to truncate instead of wrap
  ...(truncate
    ? ({
        maxWidth: "100%",
        overflowX: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      } as const)
    : {}),
});

export const Base = styled.span<StyleProps>(
  ({ $align, $color = "textPrimary", $truncate, $variant = "body1", theme }) =>
    getTextStyles({ align: $align, color: $color, truncate: $truncate, variant: $variant, theme }),
);
