import { useEffect, useReducer } from "react";

import useAmwell from "/hook/useAmwell";
import useMounted from "/hook/useMounted";
import { actionTypes, initialState, reducer, State } from "/util/reducer";

/**
 * useAmwellQuery is a hook for fetching data asynchronously from the Amwell
 * SDK. It accept two arguments:
 *   1. An `onFetch` fn that, when called, returns a promise that resolves to
 *      the expected payload.
 *
 *   2. A `dependencies` array. This is spread to a `useEffect` hook internally,
 *      and is used to control when the above `onFetch` fn is called across
 *      renders. By default, this is an empty array.
 *
 * `useAmwellQuery` returns a state object which includes the payload, if
 * available, as well as boolean values for `loading` and `error` states.
 *
 * It is recommended that `useAmwellQuery` is composed with other hooks, for
 * creating a declarative interface, similar to a `useQuery` hook, like so:
 *
 * @example
 * ```js
 * const useMyHook = ({ variable1, variable2 }) => {
 *   const { sdk } = useAmwell();
 *   const onFetch = async () => sdk.someAmwellMethod({variable1, variable2 });
 *
 *   return useAmwellQuery(onFetch, [variable1, variable2]);
 * };
 *
 * //...elsewhere...
 *
 * const { data, error, loading } = useMyHook(variables);
 * ```
 */
const useAmwellQuery = <T>(onFetch: () => Promise<T | undefined>, dependencies: unknown[] = []) => {
  const { consumer, renewAuth, sdk, isReady } = useAmwell();
  const [state, dispatch] = useReducer(reducer, initialState);
  const mountedRef = useMounted();

  useEffect(() => {
    dispatch({ type: actionTypes.INIT });
  }, []);

  const fetchData = async () => {
    try {
      if (sdk && isReady) {
        const payload = await onFetch();
        dispatch({ payload, type: actionTypes.SUCCESS });
      }
    } catch (e: any) {
      console.error("Unable to fetch from Amwell SDK", e);

      if (consumer && e.__errorCode === "authenticationSessionExpired") {
        const result = await renewAuth();

        if (result?.fullyAuthenticated) {
          fetchData();
        }

        return;
      }

      dispatch({ type: actionTypes.FAILURE });
    }
  };

  useEffect(() => {
    if (!mountedRef.current || !isReady) {
      return;
    }

    dispatch({ type: actionTypes.LOADING });
    fetchData();
  }, [isReady, ...dependencies]);

  return state as State<T>;
};

export default useAmwellQuery;
