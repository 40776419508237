import { gql } from "@apollo/client";

export default gql`
  mutation createReservation($availabilityId: ID!) {
    createReservation(availabilityId: $availabilityId) {
      errors {
        code
        message
      }
      reservation {
        id
        expirationTime
      }
    }
  }
`;
