import { useReactiveVar } from "@apollo/client";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";

import { address } from "/apollo/client/local";
import scheduling, { initialState, miles, SchedulingState } from "/apollo/client/local/scheduling";
import { DayOfWeek, Language, ProviderGender } from "/apollo/schema";
import { Button, Divider, InputWrapper, Radio, Text } from "/component/base";
import { Globe } from "/component/base/Icon";
import CheckListField from "/component/partial/formik/CheckListField";
import DatePickerField from "/component/partial/formik/DatePickerField";
import RadioGroupField from "/component/partial/formik/RadioGroupField";
import SegmentedControlField from "/component/partial/formik/SegmentedControlField";
import { useTranslation } from "/hook";
import { layout } from "/styles";
import { date as dateUtil, languages } from "/util";
import { formatDate } from "/util/date";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  FilterParams,
  logEvent,
  ScreenViewParams,
} from "/util/firebase.util";

import { Modal, PickerField } from "./ProviderFiltersModal.styles";
import { Props } from "./ProviderFiltersModal.types";

type FormValues = {
  radius: (typeof miles)[number];
  minStartDate: Date | null;
  daysOfWeek: DayOfWeek[];
  gender: ProviderGender | null;
  timeOfDay: "any" | "morning" | "afternoon" | "evening";
  language: Language | null;
};

const daysOfWeek = [
  DayOfWeek.MONDAY,
  DayOfWeek.TUESDAY,
  DayOfWeek.WEDNESDAY,
  DayOfWeek.THURSDAY,
  DayOfWeek.FRIDAY,
  DayOfWeek.SATURDAY,
  DayOfWeek.SUNDAY,
];

// The API expects an ISO date string, but discards information other than time.
// As these dates don't matter, they're simply set to the Unix epoch.
const timePeriods = {
  morning: {
    startTimeString: "1970-01-01T09:00:00.000Z",
    endTimeString: "1970-01-01T12:00:00.000Z",
  },
  afternoon: {
    startTimeString: "1970-01-01T12:00:00.000Z",
    endTimeString: "1970-01-01T17:00:00.000Z",
  },
  evening: {
    startTimeString: "1970-01-01T17:00:00.000Z",
    endTimeString: "1970-01-01T20:00:00.000Z",
  },
};

const ProviderFilters = ({ isOpen, close, hideGeoRadius, hideGender, hideLanguage }: Props) => {
  const { t } = useTranslation("modal-providerFiltersModal");
  const [showAllLanguages, setShowAllLanguages] = useState(false);
  const { line1, city } = useReactiveVar(address.var);

  // =================================
  // Build list options
  // =================================
  const radiusOptions = miles.map((mi) => ({ label: t("withinMiles", { count: mi }), value: mi }));
  const genderOptions = [
    { label: t("genderAny"), value: null },
    { label: t("genderFemale"), value: ProviderGender.FEMALE },
    { label: t("genderMale"), value: ProviderGender.MALE },
  ];

  const timeOptions = [
    {
      value: "any",
      label: t("time.any.label"),
      description: t("time.any.description"),
    },
    {
      value: "morning",
      label: t("time.morning.label"),
      description: t("time.morning.description"),
    },
    {
      value: "afternoon",
      label: t("time.afternoon.label"),
      description: t("time.afternoon.description"),
    },
    {
      value: "evening",
      label: t("time.evening.label"),
      description: t("time.evening.description"),
    },
  ] as const;

  const dayOptions = daysOfWeek.map((day) => ({
    label: dateUtil.translateWeekday(day) || "",
    value: day,
  }));

  // =================================
  // Form utils
  // =================================
  const initialValues: FormValues = {
    ...initialState.filters,
    minStartDate: null,
    timeOfDay: "any",
  };

  const handleSubmit = (values: FormValues) => {
    const newTimePeriod = timePeriods[values.timeOfDay];

    logEvent(AnalyticsEvent.FILTER, {
      user_flow: AnalyticsUserFlow.SCHEDULE_VISIT,
      source: AnalyticsSource.PROVIDER_FILTER,
      radius: values.radius.toString(),
      start_date: values.minStartDate ? formatDate(values.minStartDate, "MM/dd/yyyy") : null,
      days_of_week: values.daysOfWeek?.join(",") || null,
      time_of_day: values.timeOfDay?.toUpperCase() || null,
      gender: values.gender,
      languages: values.language,
      filter_type: "provider",
    } as FilterParams);

    const filters: SchedulingState["filters"] = {
      minStartDate: values.minStartDate ? values.minStartDate.toISOString() : null,
      minStartTime: newTimePeriod?.startTimeString,
      maxStartTime: newTimePeriod?.endTimeString,
      daysOfWeek: values.daysOfWeek,
      gender: values.gender,
      radius: values.radius,
      language: values.language,
    };

    scheduling.updateFilters(filters);
    close?.();
  };

  const toggleLanguages = () => {
    setShowAllLanguages(!showAllLanguages);
  };

  useEffect(() => {
    if (isOpen) {
      logEvent(AnalyticsEvent.SCREEN_VIEW, {
        screenName: AnalyticsSource.PROVIDER_FILTER,
      } as ScreenViewParams);
    }
  }, [isOpen]);

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ resetForm }) => {
        return (
          <Modal
            isOpen={isOpen}
            close={close}
            header={<Modal.Header close={close} title={t("title")} />}
            footer={
              <Modal.Footer>
                <Button
                  variant="borderBottom"
                  onClick={() => {
                    resetForm();
                    scheduling.resetFilters();
                    close?.();
                  }}
                >
                  {t("clear")}
                </Button>
                <Button type="submit" form="provider_filters_modal_form">
                  {t("filter")}
                </Button>
              </Modal.Footer>
            }
          >
            <Form id="provider_filters_modal_form">
              {!hideGeoRadius && (
                <PickerField
                  css={[layout.margin("skip", "skip", "expanded")]}
                  inputWrapperVariant="large-label"
                  label={t("distance", { address: line1 || city })}
                  name="radius"
                  options={radiusOptions}
                  before={<Globe size={24} />}
                />
              )}

              <DatePickerField
                inputWrapperVariant="large-label"
                label={t("fromDate")}
                minDate={new Date()}
                name="minStartDate"
                placeholder={t("earliestAvailable")}
              />

              <Divider css={layout.margin("expanded", "skip")} />

              <CheckListField
                inputWrapperVariant="large-label"
                label={t("dayOfWeek")}
                name="daysOfWeek"
                options={dayOptions}
              />

              <Divider css={layout.margin("expanded", "skip")} />

              <InputWrapper variant="large-label" label={t("timeOfDay")}>
                <RadioGroupField name="timeOfDay" css={layout.spacedChildrenVertical("standard")}>
                  {timeOptions.map(({ value, label, description }) => (
                    <RadioGroupField.Option value={value} key={value}>
                      {({ checked }) => (
                        <Radio
                          label={
                            <RadioGroupField.Label css={layout.flexVerticalAlignStart}>
                              <Text variant="body1Bold">{label}</Text>
                              <Text variant="body2" color="textSecondary">
                                {description}
                              </Text>
                            </RadioGroupField.Label>
                          }
                          checked={checked}
                          onChange={() => null}
                        />
                      )}
                    </RadioGroupField.Option>
                  ))}
                </RadioGroupField>
              </InputWrapper>

              {!hideGender && (
                <>
                  <Divider css={layout.margin("expanded", "skip")} />
                  <SegmentedControlField
                    inputWrapperVariant="large-label"
                    name="gender"
                    label={t("providerGender")}
                    options={genderOptions}
                  />
                </>
              )}

              {!hideLanguage && (
                <>
                  <Divider css={layout.margin("expanded", "skip")} />
                  <InputWrapper variant="large-label" label={t("language")}>
                    <RadioGroupField
                      name="language"
                      css={layout.spacedChildrenVertical("standard")}
                    >
                      {languages.languageKeys
                        .slice(0, showAllLanguages ? undefined : 3)
                        .map((language) => (
                          <RadioGroupField.Option value={language} key={language}>
                            {({ checked }) => (
                              <Radio
                                label={
                                  <RadioGroupField.Label css={layout.flexVerticalAlignStart}>
                                    <Text variant="body1Bold">
                                      {languages.getLanguageName(language)}
                                    </Text>
                                  </RadioGroupField.Label>
                                }
                                checked={checked}
                                onChange={() => null}
                              />
                            )}
                          </RadioGroupField.Option>
                        ))}
                    </RadioGroupField>
                  </InputWrapper>
                  <Button
                    variant="borderBottom"
                    onClick={toggleLanguages}
                    css={layout.margin("standard", "skip", "skip")}
                    type="button"
                  >
                    {showAllLanguages ? t("hideLanguages") : t("showLanguages")}
                  </Button>
                </>
              )}
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default ProviderFilters;
