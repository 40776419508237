import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";

// Components
import Button from "/component/base/Button";
import { Eye, EyeOff } from "/component/base/Icon";
import Modal from "/component/base/Modal";
import Text from "/component/base/Text";
import TextField from "/component/partial/formik/TextField";
import { useToastContext } from "/component/provider/ToastProvider";
import { useTranslation } from "/hook";

import PasswordValidations from "./PasswordValidation";
import {
  FormContent,
  Header,
  LogoWordmark,
  StepOneContent,
} from "./UpdateUserPasswordModal.styles";
import { Props, SubmitProps } from "./UpdateUserPasswordModal.types";
import useUpdateUserPassword from "./useUpdateUserPassword";

const initialValues: SubmitProps = {
  password: "",
  confirmPassword: "",
};

const UpdateUserPasswordModal = ({ token, close, isOpen }: Props) => {
  const { t } = useTranslation("update-user-password");
  const { showToast } = useToastContext();

  const [passwordFocused, setPasswordFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const [validations, setValidations] = useState<Array<boolean>>([]);
  const { handleSubmit } = useUpdateUserPassword(token);
  const [serverError, setServerError] = useState("");
  const [formValues, setFormValues] = useState<SubmitProps>(initialValues);

  const [UpdateUserPasswordIsOpen, setUpdateUserPasswordIsOpen] = useState<boolean>(!!token);

  React.useEffect(() => {
    if (isOpen != undefined) setUpdateUserPasswordIsOpen(isOpen);
  }, [isOpen]);

  const pwdErrorTexts: Array<string> = [
    t("passwordRule.lowerCase"),
    t("passwordRule.upperCase"),
    t("passwordRule.digit"),
    t("passwordRule.symbol"),
    t("passwordRule.minLength"),
  ];

  const updatePasswordSchema = Yup.object({
    password: Yup.string()
      .test("passwordValidTest", "", function (value: string | null | undefined): boolean {
        const val = value;
        if (val && val !== "") {
          const validationResults: boolean[] = [];
          validationResults.push(/(?=.*[a-z])/.test(val));
          validationResults.push(/(?=.*[A-Z])/.test(val));
          validationResults.push(/(?=.*[0-9])/.test(val));
          validationResults.push(/(?=.*[!@#$&*.])/.test(val));
          validationResults.push(val.length >= 8);
          setValidations(validationResults);
          return validationResults.indexOf(false) < 0;
        }
        return false;
      })
      .required(t("error.required")),
    confirmPassword: Yup.string()
      .test(
        "confirmPasswordValidation",
        t("error.confirmPasswordError"),
        function (value: string | null | undefined): boolean {
          const contextPassword = this?.parent?.password;
          return value && value !== contextPassword ? false : true;
        },
      )
      .required(t("error.required")),
  });
  const handleEmailSubmit = async (values: SubmitProps) => {
    // Clear error message on resubmit
    setServerError("");
    try {
      /**
       * Show the toast here
       *
       */
      const res = await handleSubmit(values?.password);

      if (!res.data?.updateUserPassword?.errors.length) {
        showToast({
          icon: "checkmark",
          message: t("toast.passwordUpdated"),
        });
        setFormValues(initialValues);
        closeModal();
      } else {
        res?.data?.updateUserPassword.errors.forEach((e) => {
          setServerError(e.code == 400 || e.code >= 500 ? t("error.generic") : e.message);
        });
      }
    } catch (e: any) {
      setServerError(e.message);
    } finally {
      setFormValues(values);
    }
  };

  const closeModal = () => {
    setUpdateUserPasswordIsOpen(false);
    close();
  };

  return (
    <Modal isOpen={UpdateUserPasswordIsOpen} close={closeModal}>
      <Header>
        <LogoWordmark />
      </Header>
      <StepOneContent>
        <Modal.Title>
          <Text variant="title1" color="textTitle">
            {t("heading.createPassword")}
          </Text>
        </Modal.Title>
        <Modal.Description>
          <Text color="textPrimary">{t("description.createPassword")}</Text>
        </Modal.Description>
        <Formik
          validateOnMount
          enableReinitialize
          initialValues={formValues}
          validationSchema={updatePasswordSchema}
          onSubmit={handleEmailSubmit}
        >
          {({ isSubmitting, isValid, setFieldTouched }) => (
            <Form>
              <FormContent>
                <TextField
                  label={t("label.password")}
                  placeholder={t("placeholder.password")}
                  name="password"
                  onFocus={() => {
                    setPasswordFocused(true);
                  }}
                  onBlur={() => {
                    setPasswordFocused(false);
                    setFieldTouched("password");
                  }}
                  type={showPassword ? "text" : "password"}
                  after={
                    <button type="button" onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <Eye /> : <EyeOff />}
                    </button>
                  }
                />
                {passwordFocused && (
                  <PasswordValidations
                    title={t("error.password")}
                    validationMappings={pwdErrorTexts}
                    validations={validations}
                  />
                )}
                <TextField
                  label={t("label.confirmPassword")}
                  placeholder={t("placeholder.confirmPassword")}
                  name="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  after={
                    <button
                      type="button"
                      onClick={() => setConfirmShowPassword(!showConfirmPassword)}
                    >
                      {showConfirmPassword ? <Eye /> : <EyeOff />}
                    </button>
                  }
                />
                {!!serverError && (
                  <Text color="statusNegative" variant="body2">
                    {serverError}
                  </Text>
                )}
                <FormContent.ButtonGroup>
                  <Button
                    variant="primary"
                    isDisabled={!isValid}
                    type="submit"
                    isLoading={isSubmitting}
                  >
                    {t("submitText.createPassword")}
                  </Button>
                </FormContent.ButtonGroup>
              </FormContent>
            </Form>
          )}
        </Formik>
      </StepOneContent>
    </Modal>
  );
};

export default UpdateUserPasswordModal;
