// Types
import {
  DocumentType,
  GetDocuments_viewer_user_patient_documents as Document,
  GetDocumentsVariables,
} from "/apollo/schema/types";
import summitHealthLogoNew from "/asset/image/summitHealthLogoNew.png";

export interface DocumentDetail {
  title: string;
  documents: Document[];
}

export interface DocumentVariables extends GetDocumentsVariables {
  types: DocumentType[];
  providers: string[];
}

// matches screen-DocumentFilter.translation.en.json
const DISCHARGE_INSTRUCTIONS_SECTION_TITLE = "Post-visit instructions";
const VISIT_SUMMARY_SECTION_TITLE = "Visit summary";
const WORK_SCHOOL_NOTE_SECTION_TITLE = "Return to work/school";

export const parseDocumentType = (type: DocumentType): string => {
  switch (type) {
    case DocumentType.DISCHARGE_INSTRUCTIONS:
      return DISCHARGE_INSTRUCTIONS_SECTION_TITLE;
    case DocumentType.VISIT_SUMMARY:
      return VISIT_SUMMARY_SECTION_TITLE;
    case DocumentType.WORK_SCHOOL_NOTE:
      return WORK_SCHOOL_NOTE_SECTION_TITLE;
    default:
      return "Other";
  }
};

export const getProviderNames = (documents: Document[]): string[] => {
  const providers = [
    ...new Set(
      documents
        .map((doc: Document) => {
          const firstName = doc.appointment?.provider?.name?.firstName;
          const lastName = doc.appointment?.provider?.name?.lastName;
          if (firstName && lastName) {
            return `${firstName} ${lastName}`;
          }
          return "";
        })
        .filter((providerName) => !!providerName),
    ),
  ];
  return providers;
};

export const parseVisitDocsByEncounter = (documents: Document[]): Record<string, Document[]> => {
  const visitDocsMap = {} as Record<string, Document[]>;

  documents.forEach((document) => {
    if (document.encounterId && !!document.appointment && document.type !== DocumentType.OTHER) {
      // construct a key that is a combination of date and
      // encounter id so that we can sort by date
      let date = document.date as string;
      date = date.substring(0, 10);
      const key = `${date}_${document.encounterId}`;
      if (!visitDocsMap[key]) {
        visitDocsMap[key] = [];
      }
      visitDocsMap[key].push(document);
    }
  });

  // sort the docs by date desc
  const visitDocsMapReversed = Object.keys(visitDocsMap)
    .sort()
    .reverse()
    .reduce((obj: Record<string, Document[]>, key: string) => {
      obj[key] = visitDocsMap[key];
      return obj;
    }, {});

  return visitDocsMapReversed;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseVisitDocsByType = (documents: any[]): DocumentDetail[] => {
  // group documents by type
  const sectionDataAccumulator: DocumentDetail[] = [];

  const newSectionData = documents.reduce((acc, document) => {
    const existingSection = acc.find((section: { title: string }) => {
      return section.title === parseDocumentType(document.type);
    });

    if (existingSection) {
      existingSection.documents = [...existingSection.documents, document];
    } else {
      acc.push({
        title: parseDocumentType(document.type),
        documents: [document],
      });
    }

    return acc;
  }, sectionDataAccumulator);

  // custom sort the sections
  const newSectionDataSorted: DocumentDetail[] = [];
  for (let i = 0; i < newSectionData.length; i++) {
    if (newSectionData[i].title === DISCHARGE_INSTRUCTIONS_SECTION_TITLE) {
      newSectionDataSorted[0] = newSectionData[i];
    } else if (newSectionData[i].title === VISIT_SUMMARY_SECTION_TITLE) {
      newSectionDataSorted[1] = newSectionData[i];
    } else if (newSectionData[i].title === WORK_SCHOOL_NOTE_SECTION_TITLE) {
      newSectionDataSorted[2] = newSectionData[i];
    }
  }

  return newSectionDataSorted;
};

export const replaceHtmlImgTag = (htmlString: string): string => {
  const imgIndexStart = htmlString.indexOf("<img");
  if (imgIndexStart >= 0) {
    const imgIndexEnd = htmlString.indexOf(">", imgIndexStart);
    const img = `<img id='letterhead' src='${summitHealthLogoNew}' border='0' height='0' width='auto' />`;
    htmlString =
      htmlString.substring(0, imgIndexStart) + img + htmlString.substring(imgIndexEnd + 1);
    htmlString = htmlString.replace("â¢", "&#8226;");
  }
  return htmlString;
};
