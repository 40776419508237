import { useAmwell, useAmwellQuery } from "/hook";

import { Variables } from "./useAmwellProviders.types";

const US_COUNTRY_CODE = "US";

/**
 * This hook allows for the retrieval of an array of AWSDKProviders based on
 * the provided arguments. The arguments allow for the filtering of results
 * based on gender, language, or the state in which they are licensed to
 * practice.
 *
 * A search may only be performed when authenticated, and all arguments are
 * optional. In addition to the response data, it also includes boolean values
 * to indicate loading and error states.
 */
export const useAmwellProviders = ({ gender, language, state }: Variables = {}) => {
  const { consumer, sdk } = useAmwell();

  const fetch = async () => {
    if (sdk && consumer) {
      const languages = await sdk.getLanguages();
      const countries = await sdk.getCountries();
      const usa = countries.find(({ code }) => code === US_COUNTRY_CODE);
      const awsdkState = state ? usa?.states.find(({ code }) => code === state) : undefined;
      const awsdkLang = language ? languages.find(({ value }) => value === language) : undefined;

      // Parameters for filtering providers in the Amwell SDK are defined by
      // argument index. Unused filters are skipped here by explicitly passing
      // `undefined` in the appropriate locations.
      const providers = await sdk.providerService.findProviders(
        consumer,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        awsdkState,
        awsdkLang,
      );

      return gender ? providers.filter((provider) => provider.genderEnum === gender) : providers;
    }

    return;
  };

  return useAmwellQuery(fetch, [gender, language, consumer?.legalResidence.code]);
};

export default useAmwellProviders;
