import _ from "lodash";
import React, { useState } from "react";
import { Trans } from "react-i18next";
import { useHistory } from "react-router";

import {
  useCreateCodeVerification,
  useUpdateCodeVerification,
} from "/apollo/mutation/codeVerification";
import childAdded from "/asset/image/child-added.png";
import noProfileFound from "/asset/image/noResultsFound.png";
import { Button, Text } from "/component/base";
import CaregiverEndModal from "/component/modal/CaregiverEndModal";
import ChildNotificationModal from "/component/modal/ChildNotificationModal";
import OTPVerification from "/component/partial/OTPVerification";
import { useCaregiverProvider } from "/component/provider/CaregiverProvider";
import { useToastContext } from "/component/provider/ToastProvider";
import routes from "/constant/url.constant";
import { useTranslation } from "/hook";
import { layout } from "/styles";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  logEvent,
  ScreenViewParams,
} from "/util/firebase.util";

import { CaregiverFormProps } from "../CaregiverForms.types";
import { EmptyStateContainer } from "./ChildVerification.styles";
import { VerificationState, VerificationType } from "./ChildVerification.types";
import { useConfirmChildCaregiverAccess } from "./ConfirmChildCaregiverAccess";
import VerificationOption from "./VerificationOption";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ChildVerification = ({ handleSubmit, metadata }: CaregiverFormProps<any>) => {
  const { t } = useTranslation("form-ChildVerification");
  const history = useHistory();

  const { showToast } = useToastContext();
  const {
    caregiverAccess: { caregiverAccountsRefetch },
  } = useCaregiverProvider();

  const [verificationSid, setVerificationSid] = useState<string | null>(null);
  const [verification, setVerification] = useState<VerificationState | null>(null);
  const [, setIsLoading] = useState<boolean>(false);
  const [showChildNotificationSettings, setShowChildNotificationSettings] = useState(false);
  const [showEndModal, setShowEndModal] = useState(false);

  const [createCode] = useCreateCodeVerification();
  const [confirmVerification] = useUpdateCodeVerification();
  const [confirmChildAccess] = useConfirmChildCaregiverAccess();

  const { name, accessId, email, phone } = metadata?.verificationTelecom || {};

  const phoneOptions = phone
    ? [
        {
          label: t("call", { phone: phone.slice(-4) }),
          value: VerificationType.CALL,
        },
        {
          label: t("sms", { phone: phone.slice(-4) }),
          value: VerificationType.TEXT,
        },
      ]
    : [];

  const logButtonClickEvent = (
    buttonName: string,
    buttonDescription?: string,
    source?: AnalyticsSource,
  ) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.CAREGIVER,
      source,
      button_name: buttonName,
      button_description: buttonDescription,
    } as ButtonClickParams);
  };

  const emailOptions = email
    ? [
        {
          label: t("email", { email }),
          value: VerificationType.EMAIL,
        },
      ]
    : [];

  const sendVerification = async () => {
    if (!verification) return;
    setIsLoading(true);

    const resp = await createCode({
      variables: {
        name: name as string,
        type: verification.verificationType,
        to: verification.value,
      },
    });
    if (resp.data?.createCodeVerification?.verification?.status === "pending") {
      setVerificationSid(resp.data.createCodeVerification.verification.id);
    } else {
      showToast({
        message: t("toast.error"),
        type: "error",
      });
    }

    setIsLoading(false);
  };

  const onOtpVerificationSubmit = async (passcode: string) => {
    if (!verification) return false;
    logButtonClickEvent("Submit");
    const verificationResponse = await confirmVerification({
      variables: {
        input: {
          code: passcode,
          type: verification.verificationType,
          sid: verificationSid,
        },
      },
    });

    if (verificationResponse?.data?.updateCodeVerification?.verification?.status === "approved") {
      const childAccessGranted = await confirmChildAccess({
        variables: {
          data: {
            caregiverAccessId: accessId as string,
          },
        },
      });

      const errors =
        childAccessGranted.data?.confirmChildCaregiverAccess.errors ||
        childAccessGranted.errors ||
        [];
      if (errors.length > 0) {
        showToast({
          type: "error",
          message: t("toast.error"),
        });
      } else {
        logEvent(AnalyticsEvent.CAREGIVER_CHILD_ADD);
        setShowChildNotificationSettings(true);
        logEvent(AnalyticsEvent.SCREEN_VIEW, {
          screenName: AnalyticsSource.CAREGIVER_ACCESS_NOTIFICATIONS,
        } as ScreenViewParams);
        return true;
      }
    }
    return false;
  };

  const onResendOtpSubmit = async () => {
    logButtonClickEvent(
      "Request a new code",
      undefined,
      AnalyticsSource.CAREGIVER_ACCESS_CHILD_CONFIRM_VERIFICATION,
    );
    if (!verification) return;

    const verificationResponse = await createCode({
      variables: {
        name: name as string,
        to: verification.value,
        type: verification.verificationType,
      },
    });

    if (verificationResponse.data?.createCodeVerification.verification) {
      setVerificationSid(verificationResponse.data.createCodeVerification.verification.id);
    }
  };

  const renderOTPVerification = (
    <OTPVerification onSubmit={onOtpVerificationSubmit} onResendClick={onResendOtpSubmit} />
  );

  const renderVerificationOption = (option: { label: string; value: VerificationType }) => {
    const checked = option.value === verification?.verificationType;
    return (
      <VerificationOption
        label={option.label}
        onSelect={() =>
          setVerification({
            verificationType: option.value,
            value: (option.value === VerificationType.EMAIL ? email : phone) as string,
          })
        }
        checked={checked}
      />
    );
  };

  const verificationOptions = [...emailOptions, ...phoneOptions];

  if (verificationOptions.length === 0) {
    logEvent(AnalyticsEvent.SCREEN_VIEW, {
      screenName: AnalyticsSource.CAREGIVER_ACCESS_NO_PROFILE,
    } as ScreenViewParams);
    return (
      <EmptyStateContainer>
        <img src={noProfileFound} width={331} height={275} alt="no profile found" aria-hidden />
        <Text variant="title2" color="brandNavy">
          {t("profileNotFound.title")}
        </Text>

        <Text css={layout.padding("condensed", "gutter")} variant="body1" color="textSecondary">
          <Trans
            components={{
              bold: <Text variant="body1Bold" />,
            }}
            values={{
              name: name,
            }}
            i18nKey="profileNotFound.subtitle"
            t={t}
          />
        </Text>
        <Button
          css={layout.margin("expanded", "skip", "skip", "skip")}
          onClick={() => {
            caregiverAccountsRefetch();
            history.push(routes.telehealthIntro);
          }}
          variant="primary"
        >
          {t("profileNotFound.startVirtualVisit", "Start virtual visit")}
        </Button>

        <Button
          css={layout.margin("expanded", "skip", "skip", "skip")}
          onClick={() => handleSubmit()}
          variant="secondary"
        >
          {t("profileNotFound.backToAccount")}
        </Button>
      </EmptyStateContainer>
    );
  }

  return (
    <div css={layout.spacedChildrenVertical("standard")}>
      {verificationSid ? (
        renderOTPVerification
      ) : (
        <>
          <div css={[layout.flexVertical, layout.spacedChildrenVertical("condensed")]}>
            <Text variant="title1">{t("title")}</Text>
            <Text variant="body2" color="textSecondary">
              <Trans
                components={{
                  bold: <Text variant="body1Bold" />,
                }}
                values={{
                  name: name,
                }}
                i18nKey="subtitle"
                t={t}
              />
            </Text>
          </div>
          {verificationOptions.map(renderVerificationOption)}
          <Button onClick={() => sendVerification()}>{t("continue")}</Button>
        </>
      )}
      <ChildNotificationModal
        isOpen={showChildNotificationSettings}
        close={() => {
          setShowChildNotificationSettings(false);
          setShowEndModal(true);
        }}
      />
      <CaregiverEndModal
        title={t("completeModal.title")}
        subtitle={
          <div css={[layout.flexVertical, layout.spacedChildrenVertical("condensed")]}>
            <div>
              <Trans
                components={{ bold: <Text color="textSecondary" variant="body2Bold" /> }}
                i18nKey="completeModal.subtitle"
                t={t}
                values={{ success: name }}
              />
            </div>
          </div>
        }
        isOpen={showEndModal}
        imageSource={childAdded}
        close={() => {
          setShowEndModal(false);
          handleSubmit();
        }}
        caregiverType={"child"}
      />
    </div>
  );
};

export default ChildVerification;
