import { useMutation } from "@apollo/client";

import createCedarPaymentMutation from "/apollo/mutation/createCedarPayment";
import createCedarPaymentScheduleMutation from "/apollo/mutation/createCedarPaymentSchedule";
import { PaymentScheduleFrequency, ScmdOrganization } from "/apollo/schema/types";
import {
  PaymentPlanFrequency,
  PaymentType,
} from "/component/page/Account/page/BillingAccounts/page/BillingAccount/component/PaymentActions/PaymentActions.types";
import { GET_BILLING_ACCOUNT } from "/constant/apolloQueries.constant";
import { useCedarPendingInvoiceCount } from "/hook";

const useCedarStripePayment = (
  patientMrn: string,
  organization: ScmdOrganization,
  paymentAmount: number,
  paymentType: PaymentType,
  paymentPlanFirstPaymentDate?: string,
  paymentPlanFrequency?: PaymentPlanFrequency,
  paymentPlanTotalAmount?: number,
): {
  pay: (token: string, saveCard: boolean) => Promise<void>;
} => {
  const { updateInvoiceCount } = useCedarPendingInvoiceCount();
  const [createCedarPayment] = useMutation(createCedarPaymentMutation);
  const [createCedarPaymentSchedule] = useMutation(createCedarPaymentScheduleMutation);

  const createPayment = async (token: string, saveCard: boolean) => {
    const { data } = await createCedarPayment({
      variables: {
        amount: paymentAmount.toString(),
        mrn: patientMrn,
        org: organization,
        paymentMethodId: token,
        savePaymentMethod: saveCard,
      },
      refetchQueries: [GET_BILLING_ACCOUNT],
      awaitRefetchQueries: true,
    });

    if (data && data.createCedarPayment.errors.length > 0) {
      throw new Error(data.createCedarPayment.errors[0].message as string);
    }
  };

  const createPaymentSchedule = async (token: string, saveCard: boolean) => {
    if (!paymentPlanFrequency) {
      return;
    }

    let frequency: PaymentScheduleFrequency;
    switch (paymentPlanFrequency) {
      case "monthly":
        frequency = PaymentScheduleFrequency.MONTHLY_DAY_OF_MONTH;
        break;
      case "biweekly":
        frequency = PaymentScheduleFrequency.BIWEEKLY;
        break;
      case "weekly":
        frequency = PaymentScheduleFrequency.WEEKLY;
        break;
    }

    const { data } = await createCedarPaymentSchedule({
      variables: {
        mrn: patientMrn,
        org: organization,
        paymentMethodId: token,
        savePaymentMethod: saveCard,
        installmentAmount: paymentAmount.toString(),
        totalAmount: paymentPlanTotalAmount?.toString() as string,
        dateOfFirstPayment: new Date(paymentPlanFirstPaymentDate as string).toISOString(),
        frequency,
      },
      refetchQueries: [GET_BILLING_ACCOUNT],
      awaitRefetchQueries: true,
    });

    if (data && data.createCedarPaymentSchedule.errors.length > 0) {
      throw new Error(data.createCedarPaymentSchedule.errors[0].message as string);
    }
  };

  const pay = async (token: string, saveCard: boolean) => {
    if (paymentType === "paymentPlan") {
      await createPaymentSchedule(token, saveCard);
    } else {
      await createPayment(token, saveCard);
    }

    updateInvoiceCount();
  };

  return {
    pay,
  };
};

export default useCedarStripePayment;
