import styled from "styled-components";

export const Base = styled.textarea<{ $hasError?: boolean }>(({ theme, $hasError }) => {
  let borderColor = "transparent";
  let backgroundColor: string = theme.colors.brandPrimaryLight;

  if ($hasError) {
    borderColor = theme.colors.brandSecondary;
    backgroundColor = theme.colors.brandSecondaryLight;
  }

  return {
    fontFamily: theme.fontVariants.body1,
    fontSize: theme.fontSizes.body1,
    lineHeight: theme.lineHeights.body1,
    color: theme.colors.textPrimary,
    alignSelf: "stretch",
    backgroundColor: backgroundColor,
    borderColor: borderColor,
    borderRadius: 30,
    padding: theme.spacing.base * 3,
    // Resets
    outline: "none",
    resize: "none",
    width: "100%",
    "&:focus": {
      borderColor: theme.colors.objectOverlay,
    },
  };
});
