import { gql, MutationHookOptions, QueryHookOptions, useMutation, useQuery } from "@apollo/client";

import {
  GetCaregiverNotificationPreference,
  SetCaregiverNotificationPreference,
  SetCaregiverNotificationPreferenceVariables,
} from "/apollo/schema";

const GET_CAREGIVER_NOTIFICATION_PREFERENCE = gql`
  query GetCaregiverNotificationPreference {
    viewer {
      user {
        notificationPreference {
          caregivers {
            appointmentReminders
            patientInformation
            prescriptions
            dealsAndPromotions
          }
        }
      }
    }
  }
`;

const SET_CAREGIVER_NOTIFICATION_PREFERENCE = gql`
  mutation SetCaregiverNotificationPreference($input: UpdateNotificationPreferenceInput!) {
    updateNotificationPreference(notificationData: $input, useMyAccount: true) {
      errors {
        message
        type
      }
      notificationPreference {
        caregivers {
          appointmentReminders
          patientInformation
          prescriptions
          dealsAndPromotions
        }
      }
    }
  }
`;

export const useCaregiverNotificationPreference = (
  options?: QueryHookOptions<GetCaregiverNotificationPreference>,
) => useQuery<GetCaregiverNotificationPreference>(GET_CAREGIVER_NOTIFICATION_PREFERENCE, options);

export const useSetCaregiverNotificationPreference = (
  options?: MutationHookOptions<
    SetCaregiverNotificationPreference,
    SetCaregiverNotificationPreferenceVariables
  >,
) =>
  useMutation<SetCaregiverNotificationPreference, SetCaregiverNotificationPreferenceVariables>(
    SET_CAREGIVER_NOTIFICATION_PREFERENCE,
    options,
  );
