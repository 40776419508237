import { Form, Formik } from "formik";
import React, { useRef } from "react";
import * as Yup from "yup";

import Button from "/component/base/Button";
import Modal from "/component/base/Modal";
import Text from "/component/base/Text";
import FormContent from "/component/partial/FormContent";
import TextField from "/component/partial/formik/TextField";
import { useTranslation } from "/hook";

import { FormProps, Props } from "./InsuranceDetailsModal.types";

const InsuranceDetailsModal = ({ details, isEditable = false, isOpen, close }: Props) => {
  const { t } = useTranslation("insurance");

  const { memberId, companyName, groupNumber } = details || {};
  const formNameSchema = Yup.object({
    companyName: Yup.string().required(t("modal.companyName.required")),
    memberId: Yup.string().required(t("modal.memberId.required")),
    groupNumber: Yup.string(),
  });

  const submitRef = useRef<HTMLButtonElement | null>(null);

  //TODO: Needs an insurance API call to add/remove/update
  const handleSubmit = async (values: Partial<FormProps>) => {
    console.log(values);
    close();
  };
  const handleRemove = () => {
    console.log(details?.groupNumber);
    close();
  };

  const submitClicked = () => {
    submitRef.current?.click();
  };

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={{
        companyName: companyName || "",
        memberId: memberId || "",
        groupNumber: groupNumber || "",
      }}
      validationSchema={formNameSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, isValid }) => (
        <Modal
          key="insuranceDetailsModal"
          isOpen={isOpen}
          close={close}
          header={
            <Modal.Header
              title={isEditable ? t("modal.header.edit") : t("modal.header.view")}
              close={close}
            />
          }
          footer={
            isEditable ? (
              <Modal.Footer>
                <Button variant="borderBottom" onClick={handleRemove}>
                  <Text variant="body1Bold">{t("modal.remove")}</Text>
                </Button>
                <Button
                  onClick={submitClicked}
                  isDisabled={!isValid || isSubmitting}
                  isLoading={isSubmitting}
                >
                  {t("modal.save")}
                </Button>
              </Modal.Footer>
            ) : undefined
          }
        >
          <Form>
            <FormContent>
              <TextField
                label={isEditable ? t("modal.companyName.edit") : t("modal.companyName.view")}
                name="companyName"
                type="text"
                disabled={!isEditable}
              />

              <TextField
                label={isEditable ? t("modal.memberId.edit") : t("modal.memberId.view")}
                name="memberId"
                type="text"
                disabled={!isEditable}
              />
              <TextField
                label={t("modal.groupNumber")}
                name="groupNumber"
                type="text"
                disabled={!isEditable}
              />

              <button css={{ display: "hidden" }} type="submit" ref={submitRef} />
            </FormContent>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default InsuranceDetailsModal;
