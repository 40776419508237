import React, { useCallback, useState } from "react";
import { useHistory } from "react-router";

import { useSetDefaultPharmacy } from "/b2c/mutation/usePharmacyMutation.b2c";
import { Button, Modal, Text } from "/component/base";
import { CornerUpRight } from "/component/base/Icon";
import Map from "/component/base/Map";
import useMapLocation from "/component/base/Map/useMapLocation";
import { formatAddress, getGoogleDirectionsLink } from "/component/base/Map/utils";
import { useToastContext } from "/component/provider/ToastProvider";
import routes from "/constant/url.constant";
import { useTranslation } from "/hook";
import { useAnalytics } from "/hook/useAnalytics/useAnalytics";
import { layout } from "/styles";
import { formatText } from "/util";
import { getHumanPharmacyType } from "/util/pharmacyUtils";

import { PharmacyButton } from "./PharmacyDetailModalB2C.styles";
import { Props } from "./PharmacyDetailModalB2C.types";

const PharmacyDetailModalB2C = ({
  close,
  dismissable = true,
  isOpen,
  pharmacy,
  variant,
}: Props) => {
  const { t } = useTranslation("modal-pharmacyDetails");
  const history = useHistory();
  const { showToast } = useToastContext();
  const { recordAnalyticsEvent } = useAnalytics();
  const { address, city, state, zip, name, phoneNumber, pharmacyType, ncpdpId } = pharmacy;
  const [settingPharmacy, setSettingPharmacy] = useState(false);

  const [setDefaultPharmacy] = useSetDefaultPharmacy({
    onError: async (e) => {
      showToast({
        icon: "alert",
        ttl: 5000,
        message: e.message,
      });
      setSettingPharmacy(false);
    },
    onCompleted: async () => {
      recordAnalyticsEvent("preferredPharmacyChanged");
      setSettingPharmacy(false);
      close?.();
      history.replace(routes.medications);
      showToast({
        icon: "checkmark",
        ttl: 5000,
        message: "You've updated your preferred pharmacy",
      });
    },
  });

  const handleChangePharmacy = useCallback(() => {
    setSettingPharmacy(true);
    if (ncpdpId) {
      setDefaultPharmacy({ variables: { ncpdpId } });
    } else {
      throw "Missing ncpdpId";
    }
  }, [ncpdpId, setDefaultPharmacy]);

  const { location: pharmacyLocation } = useMapLocation({
    address,
    city,
    state,
    zip,
    phoneNumber,
    placeName: name,
  });
  const mapCenter = `${pharmacyLocation?.coordinate.latitude},${pharmacyLocation?.coordinate.longitude}`;

  const directionsLink = getGoogleDirectionsLink({
    address: formatAddress({ address, city, state, zip }),
    placeId: pharmacyLocation?.placeId,
    coordinate: pharmacyLocation?.coordinate,
    placeName: name,
  });

  const handleGetPharmacyDirections = () => {
    recordAnalyticsEvent("pharmacyDirections");
  };

  const getFooter = () => {
    if (variant === "search") {
      return (
        <Modal.FooterWithLargeButton shadow={false} background={false}>
          <Button isLoading={settingPharmacy} fullWidth="percent" onClick={handleChangePharmacy}>
            {t("button.setPreferredPharmacy")}
          </Button>
        </Modal.FooterWithLargeButton>
      );
    }

    return;
  };

  const getPharmacyInfo = () => {
    if (variant === "medication") {
      return (
        <>
          <div css={[layout.flexVertical, layout.spacedChildrenVertical("base")]}>
            <Text variant="body1Bold">{t("pharmacyInformation.title")}</Text>
            <Text variant="body1">{t("pharmacyInformation.subtitle")}</Text>
          </div>

          <div css={[layout.flexVertical, layout.spacedChildrenVertical("base")]}>
            <Text variant="body1Bold">{t("prescriptionTransfers.title")}</Text>
            <Text variant="body1">{t("prescriptionTransfers.subtitle")}</Text>
          </div>
        </>
      );
    }

    return;
  };

  return (
    <Modal
      close={dismissable ? close : undefined}
      header={<Modal.Header hideHeaderLeft={!dismissable} close={close} title={t("title")} />}
      footer={getFooter()}
      isOpen={isOpen}
      key="PharmacyDetailModal"
    >
      <div css={layout.spacedChildrenVertical("gutter")}>
        <Map alt={t("button.directions")} link={directionsLink} center={mapCenter} />

        <div css={layout.flexVerticalCenter}>
          <Text color="textSecondary" variant="body2">
            {getHumanPharmacyType(pharmacyType)}
          </Text>
          <Text css={layout.margin("condensed", "skip", "skip", "skip")} variant="title2">
            {name}
          </Text>
        </div>

        <div>
          <Text variant="body1Bold">{t("address")}</Text>
          <p css={layout.flexVertical}>
            <Text variant="body1">{address}</Text>
            <Text variant="body1">{`${city}, ${state} ${zip}`}</Text>
          </p>
        </div>

        {phoneNumber && (
          <div>
            <Text variant="body1Bold">{t("phone")}</Text>
            <p css={layout.flexVertical}>
              <Text variant="body1"> {formatText.parsePhone(phoneNumber)}</Text>
            </p>
          </div>
        )}

        <div css={[layout.flexVerticalCenter, layout.spacedChildrenVertical()]}>
          <PharmacyButton
            href={directionsLink}
            onClick={handleGetPharmacyDirections}
            target="_blank"
            variant="secondary"
          >
            <CornerUpRight
              aria-hidden
              css={layout.margin("skip", "condensed", "skip")}
              size={16}
              color="brandPrimary"
            />

            <Text color="brandPrimary" variant="title3">
              {t("button.directions")}
            </Text>
          </PharmacyButton>
        </div>
        {getPharmacyInfo()}
      </div>
    </Modal>
  );
};

export default PharmacyDetailModalB2C;
