import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";

import { Button } from "/component/base";
import FormContent from "/component/partial/FormContent";
import TextAreaField from "/component/partial/formik/TextAreaField";
import {
  ReasonForVisitProps,
  useAmwellPrevisitContext,
} from "/component/provider/AmwellPrevisitProvider";
import { useTranslation } from "/hook";
import { layout } from "/styles";

import { Title } from "../Telehealth.styles";
import { TelehealthFormProps } from "../Telehealth.types";

const ReasonForVisit = ({
  handleError,
  handleSubmit,
  initialValues,
  showTitle = true,
}: TelehealthFormProps<ReasonForVisitProps>) => {
  const { t } = useTranslation("form-telehealth-previsit");

  const { sdk, setVisit, visitContext, setVisitContext } = useAmwellPrevisitContext();

  const formReasonSchema = Yup.object({
    reason: Yup.string().required("Required"),
  });

  const submitForm = async (values: ReasonForVisitProps) => {
    try {
      if (sdk && visitContext) {
        visitContext.otherTopic = values.reason;
        setVisitContext(visitContext);
        setVisit(await sdk.visitService.createOrUpdateVisit(visitContext));
        handleSubmit(values);
      }
    } catch (e: any) {
      handleError(e);
    }
  };

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={initialValues}
      validationSchema={formReasonSchema}
      onSubmit={submitForm}
    >
      {({ isSubmitting, isValid }) => (
        <Form>
          <FormContent css={layout.margin("gutter", "skip")}>
            {showTitle && <Title>{t("reasonForVisit.title")}</Title>}
            <div css={layout.margin(40, "skip", 40)}>
              <TextAreaField
                rows={4}
                label={t("reasonForVisit.reason")}
                placeholder={t("reasonForVisit.placeholder")}
                maxLength={230}
                name="reason"
              />
            </div>

            <FormContent.ButtonGroup>
              <Button
                variant="primary"
                isDisabled={!isValid}
                type="submit"
                isLoading={isSubmitting}
              >
                {t("continue")}
              </Button>
            </FormContent.ButtonGroup>
          </FormContent>
        </Form>
      )}
    </Formik>
  );
};

export default ReasonForVisit;
