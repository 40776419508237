import React from "react";

import { Button } from "/component/base";
import { MyAccountComplete } from "/component/base/Icon";
import EmptyStateCta from "/component/partial/EmptyStateCta";
import { SignUpFormType } from "/constant/signUp.constant";
import { useAuth, useTranslation } from "/hook";
import { layout } from "/styles";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  logEvent,
} from "/util/firebase.util";

import { FormContentContainer } from "../SignUpForms.styles";

const AccountCreated = () => {
  const { t } = useTranslation("form-signUp");
  const { loginNoMFA } = useAuth();

  const continueForm = () => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.ONBOARDING_NEW,
      source: AnalyticsSource.ONBOARDING_ACCOUNT_CREATED,
      button_name: "Sign in",
    } as ButtonClickParams);
    loginNoMFA(`${window.location.origin}/signup?form=${SignUpFormType.PhoneVerification}`);
  };

  return (
    <FormContentContainer>
      <EmptyStateCta
        title={t("accountCreated.title")}
        subtitle={
          <div css={[layout.spacedChildrenVertical("standard"), layout.marginVertical("standard")]}>
            {t("accountCreated.subtitle")}
            <br />
            {t("accountCreated.subtitle2")}
          </div>
        }
        icon={<MyAccountComplete size={80} color="brandPrimary" />}
      />

      <FormContentContainer.ButtonGroup>
        <Button onClick={continueForm} variant="primary">
          {t("accountCreated.signIn")}
        </Button>
      </FormContentContainer.ButtonGroup>
    </FormContentContainer>
  );
};

export default AccountCreated;
