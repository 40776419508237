import { ApolloError, ApolloQueryResult, gql } from "@apollo/client";
import { compareAsc } from "date-fns";

import { useB2CQuery } from "../client";
import { GET_MEDICATIONS, GET_MEDICATIONS_patient } from "../schema/types";

export const medicationsQuery = gql`
  query GET_MEDICATIONS {
    patient {
      activeMedications: medications(medicationType: ACTIVE, onlyMostRecentInstance: true) {
        medicationName
        medicationId
        datePrescribed
        medicationType
        medicationEntryId
        patientNote
        quantity
        quantityUnit
        status
        unstructuredSig
        pharmacyFacility {
          address
          city
          facilityId
          name
          ncpdpId
          pharmacyType
          phoneNumber
          state
          zip
        }
      }
      historicalMedications: medications(medicationType: HISTORICAL, onlyMostRecentInstance: true) {
        medicationName
        medicationId
        datePrescribed
        medicationType
        medicationEntryId
        patientNote
        quantity
        quantityUnit
        status
        unstructuredSig
        pharmacyFacility {
          address
          city
          facilityId
          name
          ncpdpId
          pharmacyType
          phoneNumber
          state
          zip
        }
      }
    }
  }
`;

const sortByPrescribedDate = (data: GET_MEDICATIONS | undefined) => {
  return (
    data && {
      ...data.patient,
      activeMedications: data.patient.activeMedications
        ? [...data.patient.activeMedications].sort((a, b) =>
            compareAsc(new Date(b.datePrescribed), new Date(a.datePrescribed)),
          )
        : null,
      historicalMedications: data.patient.historicalMedications
        ? [...data.patient.historicalMedications].sort((a, b) =>
            compareAsc(new Date(b.datePrescribed), new Date(a.datePrescribed)),
          )
        : null,
    }
  );
};

const useMedications = (): {
  error: ApolloError | undefined;
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<GET_MEDICATIONS>>;
  data: GET_MEDICATIONS_patient | null | undefined;
} => {
  const { data, error, loading, refetch } = useB2CQuery<GET_MEDICATIONS>(medicationsQuery);

  const refetchWrapper = () => {
    return refetch({});
  };

  return {
    error,
    loading,
    refetch: refetchWrapper,
    data: sortByPrescribedDate(data),
  };
};

export default useMedications;
