import { useField } from "formik";
import React, { forwardRef } from "react";

import Combobox from "/component/base/Combobox";
import InputWrapper from "/component/base/InputWrapper";

import { ComboboxFieldType } from "./ComboboxField.types";

const ComboboxField: ComboboxFieldType = forwardRef(
  (
    {
      variant,
      className,
      getItemForInputValue,
      inputWrapperVariant = "primary",
      itemToFormikValue,
      items = [],
      label,
      name,
      onChangeSelectedItem,
      onChangeText,
      isHTML,
      ...otherProps
    },
    ref,
  ) => {
    const [field, meta, helpers] = useField(name);

    const handleChangeText = (changedText?: string) => {
      const matchingItem = getItemForInputValue?.(changedText);

      if (matchingItem) {
        helpers.setValue(itemToFormikValue?.(matchingItem));
      } else {
        helpers.setValue(undefined);
      }

      onChangeText?.(changedText);
    };

    return (
      <InputWrapper
        className={className}
        error={meta.error}
        htmlFor={field.name}
        label={label}
        isHTML={isHTML}
        touched={meta.touched}
        variant={inputWrapperVariant}
      >
        <Combobox
          variant={variant}
          {...otherProps}
          items={items}
          name={name}
          onChangeSelectedItem={(item) => {
            onChangeSelectedItem?.(item);
            helpers.setValue(itemToFormikValue?.(item));
          }}
          onChangeText={handleChangeText}
          ref={ref}
        />
      </InputWrapper>
    );
  },
);

export default ComboboxField;
