import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React from "react";
import { useTranslation } from "react-i18next";

import { Text } from "/component/base";
import { Phone } from "/component/base/Icon";
import PharmacyDetailModal from "/component/modal/PharmacyDetailModal";
import PharmacyItem from "/component/partial/PharmacyItem";
import { card, layout } from "/styles";
import { reactPlugin } from "/util/appInsights.util";

import { CallClinicButton } from "./ClinicDetails.styles";
import { Props } from "./ClinicDetails.types";

const ClinicDetails = ({ pharmacy }: Props) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const { t } = useTranslation("prescription");

  const phoneNumber = pharmacy.contact.phoneNumber ? pharmacy.contact.phoneNumber : undefined;

  return pharmacy ? (
    <div css={layout.marginVertical("expanded")}>
      <Text color="textSecondary" variant="body2Bold">
        {t("pharmacyOrderedFrom")}
      </Text>
      <button
        css={[
          card.base,
          layout.flexLTR,
          layout.margin("standard", "skip"),
          layout.padding("skip", "standard"),
          { width: "100%" },
        ]}
        onClick={() => setModalOpen(!modalOpen)}
      >
        <PharmacyItem name={pharmacy.name} address={pharmacy.address.line1} />
      </button>
      {phoneNumber && (
        <CallClinicButton target="_blank" href={`tel:${phoneNumber.replace(/[^\d]/g, "")}`}>
          <Phone background={{ size: 16, color: "transparent" }} />
          <span css={layout.margin("skip", "skip", "skip", "condensed")}>{phoneNumber}</span>
        </CallClinicButton>
      )}
      <PharmacyDetailModal
        isOpen={modalOpen}
        close={() => setModalOpen(false)}
        pharmacy={pharmacy}
      />
    </div>
  ) : null;
};

export default withAITracking(reactPlugin, ClinicDetails, undefined, "ai-tracking");
