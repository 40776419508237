import styled from "styled-components";

import { layout } from "/styles";

export const InsuranceItemContainer = styled.div(
  layout.flexSpaceHorizontal,
  layout.paddingVertical("standard"),
  ({ theme }) => ({
    flex: 1,
    marginRight: theme.spacing.condensed,
  }),
);
