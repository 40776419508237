import { Link as LinkBase } from "react-router-dom";
import styled from "styled-components";

import AlertBase from "/component/base/Alert";
import { ThemeType } from "/theme/default";

const linkStyleFn = ({ theme }: ThemeType) => ({
  color: theme.colors.brandPrimary,
  textDecoration: "none",
  fontFamily: theme.fontVariants.body1Bold,
});

export const Link = styled(LinkBase)(linkStyleFn);

export const Anchor = styled.a(linkStyleFn);

export const Alert = styled(AlertBase)({
  width: 389,
});
