import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { useCallback } from "react";

import { auth, cedarPayments } from "/apollo/client/local";
import { GetPendingInvoiceCount, GetPendingInvoiceCountVariables } from "/apollo/schema/types";

const GET_PENDING_INVOICE_COUNT = gql`
  query GetPendingInvoiceCount($isAuthenticated: Boolean!) {
    viewer @include(if: $isAuthenticated) {
      user {
        billingAccounts {
          patientMrn
          summary {
            invoiceCount
          }
        }
      }
    }
  }
`;

const useCedarPendingInvoiceCount = () => {
  const { isAuthenticated } = useReactiveVar(auth.var);
  const { pendingInvoiceCount } = useReactiveVar(cedarPayments.var);
  const { refetch } = useQuery<GetPendingInvoiceCount, GetPendingInvoiceCountVariables>(
    GET_PENDING_INVOICE_COUNT,
    {
      skip: true,
    },
  );
  const updateInvoiceCount = useCallback(async () => {
    if (process.env.FEATURE_ENABLE_CEDAR_PAYMENTS !== "true") {
      return;
    }

    const { data } = await refetch({
      isAuthenticated,
    });
    const newCount =
      data?.viewer?.user.billingAccounts?.reduce((acc, account) => {
        return acc + (account.summary?.invoiceCount || 0);
      }, 0) || undefined;
    cedarPayments.update({ pendingInvoiceCount: newCount });
  }, [isAuthenticated, pendingInvoiceCount]);

  return {
    pendingInvoiceCount,
    updateInvoiceCount,
  };
};

export default useCedarPendingInvoiceCount;
