import styled from "styled-components";

import { layout } from "/styles";

export const ToastContainer = styled.aside(
  layout.flexCenter,
  layout.padding("skip", "standard"),
  ({ theme }) => ({
    position: "fixed",
    top: theme.spacing.expanded,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.toast,

    // Make the container non-clickable, but let the user click on a child (the toast) to dismiss
    pointerEvents: "none",
    "& > *": {
      pointerEvents: "all",
      cursor: "pointer",
    },
  }),
);
