import { Popover } from "@headlessui/react";
import { format } from "date-fns";
import React, { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { ThemeContext } from "styled-components";

import { Calendar as CalendarIcon } from "/component/base/Icon";
import TextInput from "/component/base/TextInput";
import DropdownChevron from "/component/partial/DropdownChevron";
import { useModalContext } from "/component/provider/ModalProvider";
import Transition from "/component/util/Transition";
import { useTranslation } from "/hook";
import usePopover from "/hook/usePopover";

import Calendar from "./Calendar";
import { Panel, PopoverButton } from "./DatePicker.styles";
import { Props } from "./DatePicker.types";
import { isValid } from "./DatePicker.utils";

const DATE_INPUT_FORMAT = "MM/dd/yyyy";

/**
 * A component that allows a user to input a single date either via a Calendar dialog or via
 * a text input. The Calendar dialog will open when the user clicks the input field or toggle button
 * (the right chevron button).
 * Currently the only supported format for text input is `MM/dd/yyyy`.
 *
 * Many of the props used in this component are from
 * [React Dayzed](https://github.com/deseretdigital/dayzed). See that repo
 * for detailed description of props.
 */
const DatePicker = ({
  className,
  disabled,
  hasError,
  minDate,
  maxDate,
  onChange,
  placeholder,
  value,
  ...rest
}: Props) => {
  const { spacing } = useContext(ThemeContext);
  const { t } = useTranslation("base-datePicker");

  const {
    setAnchorElement,
    setPopoverElement,
    popoverStyles,
    updatePopoverPosition,
    anchorElement,
    popoverState,
  } = usePopover({
    strategy: "fixed",
    placement: "bottom-start",
    modifiers: [{ name: "offset", options: { offset: [0, spacing.condensed] } }],
  });

  const inputRef = useRef<HTMLInputElement | null>(null);

  const { modalEl } = useModalContext();

  // This component only allows single date selections currently, though the underlying
  // dayzed hook can support multi date selection
  const selectedDate = Array.isArray(value) ? value[0] : value;

  const [textInputDate, setTextInputDate] = useState(
    selectedDate ? format(selectedDate, DATE_INPUT_FORMAT) : "",
  );

  // If the incoming value gets cleared, set the input text to an empty string to match
  useEffect(() => {
    if (!value) {
      setTextInputDate("");
    }
  }, [value]);

  return (
    <Popover className={className}>
      {({ open }) => (
        <>
          <PopoverButton
            disabled={disabled}
            ref={setAnchorElement}
            aria-label={t("buttonLabel", "Open Calendar Dialog")}
          >
            <TextInput
              onClick={(e) => {
                // By stopping the click event from propogating up to `PopoverButton`
                // we can control the focus state after the click occurs. Not that
                // PopoverButton seems to ignore any `onClick` events that we add.
                e.stopPropagation();
                anchorElement?.click();
                inputRef.current?.focus();
              }}
              disabled={disabled}
              before={<CalendarIcon aria-hidden />}
              hasError={hasError}
              placeholder={placeholder || t("placeholder")}
              value={textInputDate}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setTextInputDate(e.currentTarget.value);
              }}
              onBlur={(e: ChangeEvent<HTMLInputElement>) => {
                if (isValid(e.currentTarget.value)) {
                  const date = new Date(e.currentTarget.value);
                  const result = date.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  });
                  onChange(new Date(result), result);
                  setTextInputDate(result);
                } else {
                  onChange(undefined, e.currentTarget.value);
                }
              }}
              after={<DropdownChevron $open={open} />}
              ref={inputRef}
            />
          </PopoverButton>

          {createPortal(
            <div ref={setPopoverElement} style={popoverStyles}>
              <Transition show={open} beforeEnter={updatePopoverPosition}>
                <Panel $animateFromBottom={!!popoverState?.placement.match(/^top/)}>
                  <Calendar
                    selected={value}
                    date={selectedDate}
                    minDate={minDate || undefined}
                    maxDate={maxDate || undefined}
                    onDateSelected={({ date }) => {
                      const inputDate = format(date, DATE_INPUT_FORMAT);
                      onChange(date, inputDate);
                      setTextInputDate(inputDate);

                      // This will exit the dialog upon selecting the date
                      anchorElement?.click();
                    }}
                    {...rest}
                  />
                </Panel>
              </Transition>
            </div>,
            modalEl || document.body,
          )}
        </>
      )}
    </Popover>
  );
};

export default DatePicker;
