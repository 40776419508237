// Modules
import React from "react";

import { AnalyticsSource, AnalyticsUserFlow, ButtonClickParams } from "/util/firebase.util";

import { AppointmentCard, Base } from "./HorizontalAppointmentList.styles";
import { Props } from "./HorizontalAppointmentList.types";

const HorizontalAppointmentList = ({ appointments, className, ...rest }: Props) => {
  return (
    <Base className={className} {...rest}>
      {appointments.map((appointment) => (
        <AppointmentCard
          key={appointment.appointmentId}
          appointment={appointment}
          analyticsParams={
            {
              user_flow: AnalyticsUserFlow.HOME,
              source: AnalyticsSource.HOME,
            } as ButtonClickParams
          }
        />
      ))}
    </Base>
  );
};

export default HorizontalAppointmentList;
