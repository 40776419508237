import React from "react";
import { useTranslation } from "react-i18next";

import logoSmall from "/asset/image/logo-white.svg";
import Text from "/component/base/Text";
import { layout } from "/styles";

import { LogoContainer, SubtitleText, TutorialImage } from "./GeneralTutorials.styles";

interface Props {
  title: string;
  subtitle: string;
  image?: string;
  imageAlt?: string;
}

const TutorialScreen = ({ title, subtitle, image, imageAlt }: Props) => {
  const { t } = useTranslation("screen-generalTutorials");

  return (
    <>
      <LogoContainer>
        <img height={68} alt={t("logo")} src={logoSmall} />
      </LogoContainer>
      <div css={[layout.flexVertical, layout.spacedChildrenVertical("condensed")]}>
        <div css={[layout.margin("standard")]}>
          <Text variant="title1" color="objectInverse">
            {title}
          </Text>
        </div>
        <div css={[layout.flexLTR, layout.margin("standard")]}>
          <SubtitleText color="objectInverse">{subtitle}</SubtitleText>
        </div>
        {image && <TutorialImage alt={imageAlt} src={image} />}
      </div>
    </>
  );
};

export default TutorialScreen;
