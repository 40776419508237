import { gql } from "@apollo/client";

export default gql`
  mutation CreateFeedback($data: CreateFeedbackInput!) {
    createFeedback(data: $data) {
      errors {
        message
        code
      }
    }
  }
`;
