import styled from "styled-components";

import { Button } from "/component/base";
import { getTextStyles } from "/component/base/Text";

export const SendButton = styled(Button)({ height: 40 });

export const TextArea = styled.textarea(
  ({ theme }) => getTextStyles({ color: "textPrimary", theme, variant: "body1" }),
  ({ theme }) => ({
    background: "none",
    border: "none",
    height: 800,
    margin: 0,
    padding: 0,
    resize: "none",
    width: "100%",

    "&::placeholder": {
      color: theme.colors.textSecondary,
    },
  }),
);
