import { ScmdOrganization } from "/apollo/schema";

export const getEntityName = (entity?: ScmdOrganization | null): string => {
  switch (entity) {
    case "SUMMIT_HEALTH":
      return "Summit Health";
    case "CITY_MD":
      return "CityMD";
    default:
      return "";
  }
};
