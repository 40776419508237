import awsdk from "awsdk";
import { useField } from "formik";
import React from "react";

import { List, Text } from "/component/base";
import { layout } from "/styles";

import { Props } from "./MessageTopicsForm.types";

const MessageTopicsForm = ({ topics = [], name }: Props) => {
  const [, , helpers] = useField({ name });
  const handleSetTopic = (topic: awsdk.AWSDKTopicType) => {
    helpers.setValue(topic);
  };

  return topics?.length ? (
    <List
      as="Buttons"
      data={topics}
      onClick={(topic: awsdk.AWSDKTopicType) => {
        handleSetTopic(topic);
      }}
      renderItem={(topic) => (
        <Text css={layout.padding(20, "skip")} variant="body1Bold">
          {topic.name}
        </Text>
      )}
    />
  ) : null;
};

export default MessageTopicsForm;
