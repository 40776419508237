import React from "react";

import Button from "/component/base/Button";
import { layout } from "/styles";

import { EmptyStateContainer, imageSize, SubtitleText } from "./EmptyStateCta.styles";
import { EmptyStateCtaProps } from "./EmptyStateCta.types";

const EmptyStateCta = ({
  imageSource,
  title,
  subtitle,
  ctaText,
  icon,
  onClickCta,
  imageLocation,
  ctaVariant = "tertiary",
  imageHeight = imageSize,
  imageWidth = imageSize,
}: EmptyStateCtaProps) => {
  const setImageAfter = imageLocation === "after";
  return (
    <EmptyStateContainer>
      {!setImageAfter && imageSource && (
        <img
          src={imageSource}
          width={imageWidth}
          height={imageHeight}
          alt="empty state"
          aria-hidden
        />
      )}

      {icon}

      <SubtitleText css={layout.margin("gutter", "skip", "skip", "skip")} variant="title2">
        {title}
      </SubtitleText>

      {setImageAfter && imageSource && (
        <img
          src={imageSource}
          css={layout.margin("standard", "skip", "skip", "skip")}
          width={imageWidth}
          height={imageHeight}
          alt="empty state"
          aria-hidden
        />
      )}

      {subtitle && (
        <SubtitleText variant="body1" color="textSecondary">
          {subtitle}
        </SubtitleText>
      )}

      {/* Only show the button if there is an action linked */}
      {onClickCta && (
        <Button onClick={onClickCta} variant={ctaVariant || "tertiary"}>
          {ctaText}
        </Button>
      )}
    </EmptyStateContainer>
  );
};

export default EmptyStateCta;
