export const AddressStorageKey = "address";
export interface AddressState {
  city?: string | null;
  lat?: number;
  line1?: string | null;
  lng?: number;
  state?: string | null;
  postalCode?: string | null;
  radius?: number;
  placeId?: string;
  formattedAddress?: string;
}
