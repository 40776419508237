import { gql, useQuery } from "@apollo/client";

import { GetDiagnosticReport, GetDiagnosticReportVariables } from "/apollo/schema";
import { contactSupportFragment } from "/hook/useContactInfo/useContactInfo";

const GET_REPORT = gql`
  ${contactSupportFragment}

  query GetDiagnosticReport($id: ID!) {
    contactInfo {
      ...contactSupportFragment
    }
    diagnosticReport(id: $id) {
      id
      observations {
        elevated
        helperText {
          description
          title
        }
        effectiveDate
        highlight
        id
        lowered
        name
        notes
        reference
        value
        valueString
      }
      receivedDateTime
      reportTitle
      reportLocation
      orderedBy
      requestedByProvider {
        id
        name {
          firstName
          lastName
        }
        nameWithDesignations
        photoUrl
        primarySpecialty
      }
      resultMedia {
        contentType
        data
      }
      sourceOrg
      testSummary
      type
    }
  }
`;

export const useResult = (variables: GetDiagnosticReportVariables) =>
  useQuery<GetDiagnosticReport, GetDiagnosticReportVariables>(GET_REPORT, { variables });
