import { camelCase, startCase } from "lodash";
import { compose } from "lodash/fp";

import { Language } from "/apollo/schema/types";

const languageNames = {
  [Language.AFRIKAANS]: "Afrikaans",
  [Language.AMHARIC]: "አማርኛ",
  [Language.ARABIC]: "عربى",
  [Language.ASL]: "ASL",
  [Language.BENGALI]: "বাংলা",
  [Language.CHINESE]: "中文",
  [Language.CROATIAN]: "Hrvatski",
  [Language.CANTONESE]: "粵語",
  [Language.CREOLE]: "Creole",
  [Language.CZECH]: "čeština",
  [Language.DUTCH]: "Nederlands",
  [Language.ENGLISH]: "English",
  [Language.FILIPINO]: "Pilipino",
  [Language.FINNISH]: "Suomalainen",
  [Language.FRENCH]: "Français",
  [Language.FRYSIAN]: "فريزيان",
  [Language.GERMAN]: "Deutsche",
  [Language.GREEK]: "Ελληνικά",
  [Language.GUJARATI]: "ગુજરાતી",
  [Language.HEBREW]: "עִברִית",
  [Language.HINDI]: "हिंदी",
  [Language.ITALIAN]: "Italiano",
  [Language.JAPANESE]: "日本語",
  [Language.KOREAN]: "한국어",
  [Language.LITHUANIAN]: "Lietuvis",
  [Language.MARATHI]: "मराठी",
  [Language.NORWEGIAN]: "Norsk",
  [Language.POLISH]: "Polskie",
  [Language.PORTUGUESE]: "Português",
  [Language.PUNJABI]: "ਪੰਜਾਬੀ",
  [Language.ROMANIAN]: "Română",
  [Language.RUSSIAN]: "русский",
  [Language.SANSKRIT]: "Sanskrit",
  [Language.SERBIAN]: "Српски",
  [Language.SPANISH]: "Español",
  [Language.SWEDISH]: "Svenska",
  [Language.TAGALOG]: "Tagalog",
  [Language.TAIWANESE]: "Taiwanese",
  [Language.TAMIL]: "தமிழ்",
  [Language.TELUGU]: "తెలుగు",
  [Language.UKRANIAN]: "Український",
  [Language.URDU]: "اردو",
};

type languageKeys = keyof typeof Language;

const pascalCase = (lang: string) => {
  return compose(startCase, camelCase)(lang);
};

export const languageKeys = Object.keys(languageNames).sort((langA, langB) =>
  langA === Language.ENGLISH || (langA === Language.SPANISH && langB !== Language.ENGLISH) ? -1 : 1,
) as languageKeys[];

export const getLanguageName = (language: languageKeys): string => {
  return languageNames[language];
};

export const getLanguageNameString = (language: languageKeys): string => {
  return pascalCase(language);
};
