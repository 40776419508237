import { gql, useQuery } from "@apollo/client";

import { AccountQuery } from "/apollo/schema/types";

export const accountQuery = gql`
  query AccountQuery {
    viewer {
      user {
        account {
          firstName
          lastName
          email
          phoneNumber
        }
      }
    }
  }
`;

export const useAccountQuery = () => {
  return useQuery<AccountQuery>(accountQuery, {
    fetchPolicy: "network-only",
  });
};
