import awsdk from "awsdk";
import React, { useEffect, useState } from "react";

import { Alert, Button, Text } from "/component/base";
import { AlertCircle } from "/component/base/Icon";
import Link from "/component/base/Link";
import Transition from "/component/util/Transition";
import { useAmwellMessageAttachment, useTranslation } from "/hook";
import { layout } from "/styles";

import { Spinner } from "./MessageAttachmentsList.styles";
import { Props } from "./MessageAttachmentsList.types";

/**
 * Renders a list of AWSDKAttachments, and handles support for requesting and
 * loading them in a new browser window.
 */
const MessageAttachmentsList = ({ attachments }: Props) => {
  const [selectedAttachment, setSelectedAttachment] = useState<awsdk.AWSDKAttachment>();
  const { t } = useTranslation("partialMessageAttachmentsList");
  const {
    data: attachmentDetails,
    error,
    loading,
  } = useAmwellMessageAttachment({
    attachment: selectedAttachment,
  });

  const handleClose = () => {
    setSelectedAttachment(undefined);
  };

  const renderAttachmentListItem = (attachment: awsdk.AWSDKAttachment) => {
    const isSelected = selectedAttachment === attachment;
    const showSpinner = isSelected && loading;

    return (
      <li css={layout.flexCenterHorizontal} key={attachment.id.encryptedId}>
        <Link.Anchor
          disabled={isSelected}
          onClick={() => setSelectedAttachment(attachment)}
          variant="secondary"
        >
          {attachment.name}
        </Link.Anchor>

        <Transition show={showSpinner}>
          <Spinner />
        </Transition>
      </li>
    );
  };

  useEffect(() => {
    if (attachmentDetails) {
      if (window.navigator && "msSaveOrOpenBlob" in window.navigator) {
        (window.navigator.msSaveOrOpenBlob as unknown as (b: Blob) => void)(attachmentDetails);
      } else {
        const newTab = window.open("");
        newTab && newTab.location.replace(URL.createObjectURL(attachmentDetails));
      }
    }
  }, [attachmentDetails]);

  useEffect(() => {
    if (attachmentDetails && selectedAttachment) {
      setSelectedAttachment(undefined);
    }
  }, [attachmentDetails, selectedAttachment]);

  return attachments.length ? (
    <aside css={layout.padding("expanded", "none")}>
      <Text element="h3" variant="body1Bold">
        {t("attachment")}
      </Text>

      <ul>{attachments.map(renderAttachmentListItem)}</ul>

      {/** TODO: This should be a toast message. */}
      <Alert
        close={handleClose}
        footer={
          <Button fullWidth="flex" onClick={handleClose}>
            Okay
          </Button>
        }
        icon={<AlertCircle />}
        isOpen={error && !!selectedAttachment}
        title=""
      >
        {t("error")}
      </Alert>
    </aside>
  ) : null;
};

export default MessageAttachmentsList;
