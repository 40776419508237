import React from "react";
import { Trans } from "react-i18next";

import { GetBillingAccountSettings_viewer_user_billingAccounts_settings } from "/apollo/schema";
import { Text } from "/component/base";
import i18n from "/i18n";

export const notificationCategories = {
  appointments: "appointmentReminders",
  dealsAndPromo: "dealsAndPromotions",
  patientInfo: "patientInformation",
  prescriptions: "prescriptions",
  referrals: "referrals",
  caregivers: "caregivers",
} as const;

interface NotificationOptions {
  email?: boolean | null;
  emailNotification?: boolean | null;
  paperMail?: boolean | null;
  phoneCall?: boolean | null;
  phoneText?: boolean | null;
  ringlessCall?: boolean | null;
  smsNotification?: boolean | null;
  appointmentReminders?: boolean | null;
  prescriptions?: boolean | null;
  patientInformation?: boolean | null;
  dealsAndPromotions?: boolean | null;
}

type NotificationKey =
  | "email"
  | "emailNotification"
  | "paperMail"
  | "phoneCall"
  | "phoneText"
  | "ringlessCall"
  | "smsNotification"
  | "appointmentReminders"
  | "prescriptions"
  | "patientInformation"
  | "dealsAndPromotions";

type SettingsState = { label: string; type: NotificationKey; value: boolean };
export type StoreObject = { [key: string]: any }; // eslint-disable-line @typescript-eslint/no-explicit-any
type GetFormattedOptions = (options: NotificationOptions | null) => SettingsState[];
export type NotificationCategory =
  | typeof notificationCategories.appointments
  | typeof notificationCategories.dealsAndPromo
  | typeof notificationCategories.patientInfo
  | typeof notificationCategories.prescriptions
  | typeof notificationCategories.caregivers;

/**
 * Given the name of a NotificationPreferenceOption, return the appropriate text
 * content (ie, title and disclaimer, if any).
 */
export const getTextContentForCategory = (category: NotificationCategory) => {
  switch (category) {
    case notificationCategories.appointments:
      return {
        disclaimer: (
          <Trans
            components={{ bold: <Text color="inherit" variant="body2Bold" /> }}
            i18n={i18n}
            i18nKey="disclaimer.notificationsOn"
            ns="notifications"
            values={{
              category: i18n.t("disclaimer.appointments", { ns: "notifications" }),
              type: i18n.t("disclaimer.emailSms", { ns: "notifications" }),
            }}
          />
        ),
        title: i18n.t("title.appointments", { ns: "notifications" }),
      };
    case notificationCategories.dealsAndPromo:
      return {
        title: i18n.t("title.updates", { ns: "notifications" }),
      };
    case notificationCategories.caregivers:
      return {
        disclaimer: (
          <Trans
            components={{ bold: <Text color="inherit" variant="body2Bold" /> }}
            i18n={i18n}
            i18nKey="disclaimer.caregiver"
            ns="notifications"
          />
        ),
        title: i18n.t("title.caregivers", { ns: "notifications" }),
      };
    case notificationCategories.patientInfo:
      return {
        disclaimer: (
          <Trans
            components={{ bold: <Text color="inherit" variant="body2Bold" /> }}
            i18nKey="disclaimer.notificationsOn"
            i18n={i18n}
            ns="notifications"
            values={{
              category: i18n.t("disclaimer.patientInfo", { ns: "notifications" }),
              type: i18n.t("disclaimer.email", { ns: "notifications" }),
            }}
          />
        ),
        title: i18n.t("title.patientInfo", { ns: "notifications" }),
      };
    case notificationCategories.prescriptions:
      return {
        disclaimer: (
          <Trans
            components={{ bold: <Text color="inherit" variant="body2Bold" /> }}
            i18nKey="disclaimer.notificationsOn"
            i18n={i18n}
            ns="notifications"
            values={{
              category: i18n.t("disclaimer.prescriptions", { ns: "notifications" }),
              type: i18n.t("disclaimer.email", { ns: "notifications" }),
            }}
          />
        ),
        title: i18n.t("title.prescriptions", { ns: "notifications" }),
      };
    default:
      return { title: i18n.t("title.notifications", { ns: "notifications" }) };
  }
};

/**
 * getDisablePaperMail - Given a set of billing account settings, this fn
 * returns a boolean that represents whether the `paperMail` setting should be
 * disabled in the UI.
 *
 * This is because the `paperMail` setting must be enabled when all other
 * settings are toggled off.
 */
export const getDisablePaperMail = (
  settings?: GetBillingAccountSettings_viewer_user_billingAccounts_settings | null,
) =>
  !settings
    ? false
    : Object.keys(settings).every(
        (key) => ["paperMail", "__typename"].includes(key) || !settings[key],
      );

/**
 * getFormattedOptions - given either a billingAccount's settings, or a
 * Notification Preference Option, this fn returns an array options that may be
 * rendered as toggles in the UI, including a label, the current value, and the
 * type of the toggle (ie, which specific setting it represents).
 */
export const getFormattedOptions: GetFormattedOptions = (options) =>
  options
    ? (Object.keys(options)
        .map((key) => {
          switch (key) {
            case "emailNotification":
            case "email":
              return {
                label: i18n.t("label.email", { ns: "notifications" }),
                type: key,
                value: options[key],
              };
            case "smsNotification":
            case "phoneText":
              return {
                label: i18n.t("label.sms", { ns: "notifications" }),
                type: key,
                value: options[key],
              };
            case "ringlessCall":
              return {
                label: i18n.t("label.ringlessCall", { ns: "notifications" }),
                type: key,
                value: options[key],
              };
            case "phoneCall":
              return {
                label: i18n.t("label.phoneCall", { ns: "notifications" }),
                type: key,
                value: options[key],
              };
            case "paperMail":
              return {
                label: i18n.t("label.paperMail", { ns: "notifications" }),
                type: key,
                value: options[key],
              };
            case "appointmentReminders":
              return {
                label: i18n.t("label.appointmentReminders", { ns: "notifications" }),
                type: key,
                value: options[key],
              };
            case "patientInformation":
              return {
                label: i18n.t("label.patientInformation", { ns: "notifications" }),
                type: key,
                value: options[key],
              };
            case "prescriptions":
              return {
                label: i18n.t("label.prescriptions", { ns: "notifications" }),
                type: key,
                value: options[key],
              };
            case "dealsAndPromotions":
              return {
                label: i18n.t("label.dealsAndPromotions", { ns: "notifications" }),
                type: key,
                value: options[key],
              };
            default:
              return undefined;
          }
        })
        .filter((option) => !!option) as SettingsState[])
    : [];

/**
 * shouldDisable - Given the name of a Notification Preference Option, and the
 * name of a specific setting it contains, this fn returns a boolean that
 * represents if the setting should be disabled. This is because some settings,
 * for some categories, must always be enabled, and may not be disabled by the
 * user.
 */
export const shouldDisable = ({
  category,
  type,
}: {
  category: NotificationCategory;
  type: NotificationKey;
}) => {
  switch (category) {
    case notificationCategories.appointments:
      return ["emailNotification", "smsNotification"].includes(type);
    case notificationCategories.patientInfo:
    case notificationCategories.prescriptions:
      return "emailNotification" === type;
    default:
      return false;
  }
};
