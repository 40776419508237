import { gql, useMutation } from "@apollo/client";

import {
  CreateCodeVerification,
  CreateCodeVerificationVariables,
  UpdateCodeVerification,
  UpdateCodeVerificationVariables,
} from "/apollo/schema/types";

const CREATE_CODE_GQL = gql`
  mutation CreateCodeVerification($type: String!, $to: String!, $name: String!) {
    createCodeVerification(type: $type, to: $to, name: $name) {
      errors {
        code
        message
        type
      }
      verification {
        id
        status
        to
        type
      }
    }
  }
`;

const UPDATE_CODE_GQL = gql`
  mutation UpdateCodeVerification($input: UpdateCodeVerificationInput!) {
    updateCodeVerification(input: $input) {
      errors {
        code
        message
        type
      }
      verification {
        id
        status
        to
        type
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useCreateCodeVerification = () =>
  useMutation<CreateCodeVerification, CreateCodeVerificationVariables>(CREATE_CODE_GQL);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useUpdateCodeVerification = () =>
  useMutation<UpdateCodeVerification, UpdateCodeVerificationVariables>(UPDATE_CODE_GQL);
