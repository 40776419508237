import React from "react";

import { Avatar, Text } from "/component/base";
import { Check, Plus } from "/component/base/Icon";
import { useTranslation } from "/hook";
import { layout } from "/styles";
import { parseInitials } from "/util/formatText";

import { CaregiverInfoContainer, PlusIcon, Selected } from "./CaregiverCard.styles";

interface Props {
  children?: React.ReactElement;
  name?: {
    firstName: string | null;
    lastName: string | null;
  };
  isSelected?: boolean;
  imageUrl?: string;
  onClick?: () => void;
  hasActionButton?: React.ReactNode;
  disabled?: boolean;
  isAddition?: boolean;
}

const CaregiverCard = ({
  name = { firstName: "", lastName: "" },
  imageUrl,
  isSelected = false,
  onClick,
  children,
  hasActionButton,
  disabled,
  isAddition,
}: Props) => {
  const { t } = useTranslation("partial-caregiver");
  const fullName = `${name.firstName} ${name.lastName}`;
  const renderBody = (
    <div css={layout.padding("skip", "skip", "skip", "standard")}>
      {isAddition ? (
        <div css={[layout.flexLTR]}>
          <PlusIcon>
            <Plus size={34} color={"black"} />
          </PlusIcon>
          <div
            css={[
              layout.flexVerticalCenter,
              layout.padding("condensed"),
              layout.marginVertical("base"),
            ]}
          >
            <Text variant="body2Bold">{t("addAnother")}</Text>
          </div>
        </div>
      ) : (
        <>
          <div css={layout.flexSpaceHorizontal} style={{ opacity: disabled ? 0.5 : 1 }}>
            <div css={layout.flexCenterHorizontal}>
              <Avatar initials={parseInitials(name)} alt={fullName} src={imageUrl} />

              <div css={[layout.flexVerticalAlignStart, layout.padding("condensed")]}>
                <Text align="left" variant="body2Bold">
                  {fullName}
                </Text>
                {children && <CaregiverInfoContainer>{children}</CaregiverInfoContainer>}
              </div>
            </div>
            {isSelected && (
              <Selected>
                <Check size={12} color="white" />
              </Selected>
            )}
          </div>
          <div css={[layout.flexLTR, layout.spacedChildrenHorizontal("condensed")]}>
            {hasActionButton}
          </div>
        </>
      )}
    </div>
  );
  return onClick ? (
    <button css={{ diplay: "flex", width: "100%" }} onClick={onClick}>
      {renderBody}
    </button>
  ) : (
    <div css={{ diplay: "flex", width: "100%" }}>{renderBody}</div>
  );
};

export default CaregiverCard;
