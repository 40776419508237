import { useAmwell, useAmwellQuery } from "/hook";

import { Variables } from "./useAmwellMessageAttachment.types";

/**
 * This hook allows for the retrieval of the details for a given message.
 * In addition to the response data, it also includes boolean values to indicate
 * loading and error states.
 */
export const useAmwellMessageAttachment = ({ attachment }: Variables) => {
  const { consumer, sdk } = useAmwell();
  const fetch = async () =>
    sdk && consumer && attachment
      ? await sdk.secureMessageService.getAttachment(consumer, attachment)
      : undefined;

  return useAmwellQuery<Blob>(fetch, [attachment]);
};

export default useAmwellMessageAttachment;
