import styled from "styled-components";

import { card, layout } from "/styles";

export const ProviderCard = styled.li(card.base, ({ theme }) => ({
  padding: theme.spacing.standard,
  marginBottom: theme.spacing.condensed,
}));

export const ProviderContainer = styled.div(layout.flexLTR);

export const ProviderDetailsContainer = styled.div(layout.flexVertical, ({ theme }) => ({
  flexGrow: 1,
  alignItems: "flex-start",
  marginLeft: theme.spacing.standard,
}));
