import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useHistory, useLocation } from "react-router-dom";

import background from "/asset/image/prescriptionEmptyState.png";
import { Link, Loading, Text } from "/component/base";
import MainContent from "/component/layout/MainContent";
import EmptyStateCta from "/component/partial/EmptyStateCta";
import routes from "/constant/url.constant";
import { useTranslation } from "/hook";
import { layout } from "/styles";
import { reactPlugin } from "/util/appInsights.util";

import PrescriptionCard from "./PrescriptionCard";
import useQueryPrescriptions from "./useQueryPrescriptions";

const IMAGE_WIDTH = 375;
const IMAGE_HEIGHT = 309;

const Prescriptions = () => {
  const { t } = useTranslation("prescriptions");
  const query = new URLSearchParams(useLocation().search);
  const active = query.get("past") !== "true";
  const history = useHistory();

  const { viewer, error, loading, refetch } = useQueryPrescriptions(active);

  const data = viewer?.user.patient?.prescriptions;
  const hasPrescriptions = data && data.length > 0;

  const getChild = () => {
    if (loading) {
      return <Loading variant="page" />;
    }

    if (error) {
      return (
        <EmptyStateCta
          imageHeight={IMAGE_HEIGHT}
          imageWidth={IMAGE_WIDTH}
          ctaText={t("error.cta")}
          imageSource={background}
          onClickCta={() => refetch()}
          subtitle={t("error.subtitle")}
          title={t("error.title")}
        />
      );
    }

    if (!hasPrescriptions || !data) {
      return (
        <EmptyStateCta
          imageHeight={IMAGE_HEIGHT}
          imageWidth={IMAGE_WIDTH}
          ctaText={active ? t("empty.active.cta") : t("empty.past.cta")}
          imageSource={background}
          onClickCta={() => {
            history.push(active ? `${routes.prescriptions}?past=true` : routes.prescriptions);
          }}
          subtitle={active ? t("empty.active.subtitle") : t("empty.past.subtitle")}
          title={active ? t("empty.active.title") : t("empty.past.title")}
        />
      );
    }

    return (
      <>
        <ul css={layout.spacedChildrenVertical("standard")}>
          {data.map((prescription) => {
            return (
              <li key={prescription.prescriptionId}>
                <Link
                  to={{
                    pathname: `${routes.prescriptions}/${prescription.prescriptionId}`,
                    state: { patientMrn: prescription.mrn },
                  }}
                >
                  <PrescriptionCard prescription={prescription} />
                </Link>
              </li>
            );
          })}
        </ul>
      </>
    );
  };

  return (
    <MainContent parentPage="myHealth" pageTitle={t("headline")}>
      <Helmet>
        <title>{t("title")}</title>
      </Helmet>

      <div css={[layout.margin("none", "none", "expanded"), layout.flexSpaceHorizontal]}>
        <Text variant="title3"> </Text>

        <Link to={active ? `${routes.prescriptions}?past=true` : routes.prescriptions}>
          {active ? t("cta.viewPast") : t("cta.viewActive")}
        </Link>
      </div>

      {getChild()}
    </MainContent>
  );
};

export default withAITracking(reactPlugin, Prescriptions, undefined, "ai-tracking");
