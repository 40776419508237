import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "styled-components";

// This hook was shamelessly borrowed from https://usehooks.com/useWindowSize/

/**
 * This hook will listen for window resize events and update the width/height when it changes
 */
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<{ width?: number; height?: number }>({});
  const { screenSizes } = useContext(ThemeContext);

  useEffect(() => {
    // Handler to call on window resize
    const handleResize = () => {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return {
    width: windowSize.width,
    height: windowSize.height,
    isTablet: windowSize.width && windowSize.width < screenSizes.desktop,
    isMobile: windowSize.width && windowSize.width < screenSizes.tablet,
  };
};

export default useWindowSize;
