import styled from "styled-components";

import Text from "/component/base/Text";
import { layout } from "/styles";

export const IMAGE_WIDTH = 375;
export const IMAGE_HEIGHT = 278;

export const CaregiverIntroContainer = styled.div(
  layout.flexVerticalCenter,
  layout.margin("skip", "auto"),
  ({ theme }) => ({
    maxWidth: 480,
    textAlign: "center",
    "& > button": {
      marginTop: `${theme.spacing.condensed}px`,
      width: "100%",
    },
  }),
);

export const Title = styled(Text)(layout.padding("condensed", "gutter", "skip", "gutter"));
export const SubtitleAbove = styled(Text)(layout.padding("standard", "gutter", "gutter", "gutter"));
export const SubtitleBelow = styled(Text)(layout.padding("gutter", "gutter", "expanded", "gutter"));
