import React from "react";

import { Text } from "/component/base";
import { ChevronRight, Clipboard } from "/component/base/Icon";
import { card, layout } from "/styles";
import { date as dateUtil } from "/util";

import { Props } from "./DiagnosticReportItem.types";

const DiagnosticReportItem = ({ className, title, date }: Props) => (
  <div
    className={className}
    css={[card.interactive, layout.flexSpaceHorizontal, layout.padding("standard")]}
  >
    <Clipboard
      aria-hidden
      background={{ color: "brandSecondaryLight", size: 48 }}
      css={layout.flexItemIntrinsic}
      color="brandSecondary"
    />

    <div css={[layout.flexVertical, layout.padding("skip", "standard"), layout.flexItemAuto]}>
      <Text
        css={[layout.padding("skip", "skip", "base"), { textTransform: "capitalize" }]}
        variant="body1Bold"
      >
        {title}
      </Text>

      <Text color="textSecondary" variant="body2">
        {date && dateUtil.formatDate(date, "MMMM d, y")}
      </Text>
    </div>

    <ChevronRight css={layout.flexItemIntrinsic} size={16} color="brandSecondary" />
  </div>
);

export default DiagnosticReportItem;
