import styled from "styled-components";

import { ListItem } from "/component/base";
import { card, layout } from "/styles";

export const DocsWrapper = styled.div(
  layout.flexVertical,
  layout.margin("expanded", "none", "none", "none"),
);

export const SegmentWrapper = styled.div(layout.margin("expanded", "none", "none", "none"));

export const CaregiverItemCard = styled(ListItem.Button)(card.wrapper, { width: "100%" });
