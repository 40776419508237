import { Link } from "react-router-dom";
import styled from "styled-components";

import { Button } from "/component/base";
import { card, layout } from "/styles";

export const Base = styled.li(card.interactive, layout.flexVertical, {
  width: "100%",
});

export const DetailsLink = styled(Link)(layout.flexLTR, layout.padding("standard"), {
  textAlign: "start",
  width: "100%",
});

export const Content = styled.div(
  layout.flexVertical,
  layout.spacedChildrenVertical("base"),
  layout.margin("skip", "skip", "skip", "standard"),
);

export const AppointmentButton = styled(Button)(
  layout.margin("none", "standard", "standard"),
  layout.flexCenter,
);
