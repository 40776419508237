import styled from "styled-components";

import { layout } from "/styles";

export const List = styled.div(layout.flexCenter, layout.margin("standard", "auto"));

export const Page = styled.div(
  layout.absoluteFill,
  layout.flexVerticalCenter,
  layout.spacedChildrenVertical(),
);

export const Modal = styled.div(layout.flexVerticalCenter, layout.spacedChildrenVertical(), {
  margin: "96px auto",
});
