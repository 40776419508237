import React from "react";

import { Link, Text } from "/component/base";
import { layout } from "/styles";
import { AnalyticsEvent, ButtonClickParams, logEvent } from "/util/firebase.util";

import { Base, Links } from "./CtaCard.styles";
import { Props } from "./CtaCard.types";

const CtaCard = ({ className, links, title, subtitle }: Props) => {
  const handleCtaCardClick = (analyticsParams: ButtonClickParams) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      ...analyticsParams,
    } as ButtonClickParams);
  };

  return (
    <Base aria-hidden className={className}>
      <Text variant="title3" element="div">
        {title}
      </Text>

      <Text
        color="textSecondary"
        css={layout.margin("condensed", "skip", "skip", "skip")}
        element="div"
      >
        {subtitle}
      </Text>

      {links && (
        <Links css={layout.margin("standard", "skip", "skip", "skip")}>
          {links?.map((link) => {
            if (link.to) {
              return (
                <li key={link.title}>
                  <Link.Button
                    to={link.to}
                    variant={link?.variant || "primary"}
                    onClick={() => link.analyticsParams && handleCtaCardClick(link.analyticsParams)}
                  >
                    {link.title}
                  </Link.Button>
                </li>
              );
            } else if (link.href) {
              return (
                <li key={link.title}>
                  <Link.Button
                    as="a"
                    to=""
                    onClick={() => link.analyticsParams && handleCtaCardClick(link.analyticsParams)}
                    href={link.href}
                    variant={link?.variant || "primary"}
                    // NOTE: Using `css` here causes the links to lose styles
                    style={{ width: "100%" }}
                  >
                    {link.title}
                  </Link.Button>
                </li>
              );
            }

            return null;
          })}
        </Links>
      )}
    </Base>
  );
};

export default CtaCard;
