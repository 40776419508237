// eslint-disable-next-line no-shadow
export enum VerificationType {
  EMAIL = "email",
  TEXT = "sms",
  CALL = "call",
}

export interface VerificationState {
  verificationType: VerificationType;
  value: string;
}
