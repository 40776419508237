import React from "react";

import { Calendar, CityMD, Video } from "/component/base/Icon";
import routes from "/constant/url.constant";
import { useAuth, useTranslation } from "/hook";
import useAmwell from "/hook/useAmwell";
import useWindowSize from "/hook/useWindowSize";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  logEvent,
} from "/util/firebase.util";

import DashboardCard from "../DashboardCard";
import { Base, desktopIconSize, mobileIconSize, Spinner } from "./DashboardLinks.styles";
import { Props } from "./DashboardLinks.types";

const DashboardLinks = ({ onLinkClick, hasSmgFacilities, hasCmdFacilities, isLoading }: Props) => {
  const { t } = useTranslation("dashboardLinks");

  const { isAuthenticated } = useAuth();
  const { isMobile } = useWindowSize();
  const { consumer } = useAmwell();

  const amwellAuthenticationFailed = isAuthenticated && !consumer;
  const amwellToolTip = amwellAuthenticationFailed
    ? t("telehealth.authenticationFailed")
    : !isAuthenticated
    ? t("telehealth.needsLogin")
    : "";

  const cards = [
    {
      Icon: Calendar,
      title: t("scheduling.title"),
      description: t("scheduling.description"),
      url: routes.scheduling,
      disabled: !hasSmgFacilities,
      tooltipText: t("scheduling.tooltip"),
      isTelehealth: false,
    },
    {
      Icon: Video,
      title: t("telehealth.title"),
      description: t("telehealth.description"),
      url: routes.telehealthIntro,
      tooltipText: amwellToolTip,
      isTelehealth: true,
    },
    {
      Icon: CityMD,
      title: t("urgentCare.title"),
      description: t("urgentCare.description"),
      url: routes.urgentCare,
      disabled: !hasCmdFacilities,
      tooltipText: t("urgentCare.tooltip"),
      isTelehealth: false,
    },
  ];

  const iconBg = (isDisabled = false) =>
    isMobile
      ? ({
          size: mobileIconSize,
          color: isDisabled ? "objectSubtle" : "brandSecondaryLight",
        } as const)
      : undefined;
  const iconSize = isMobile ? 24 : desktopIconSize;

  const logButtonClickEvent = (buttonName: string) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.HOME,
      source: AnalyticsSource.HOME,
      button_name: buttonName,
    } as ButtonClickParams);
  };

  return (
    <>
      <Base>
        {cards.map(({ Icon, title, description, url, disabled, tooltipText }, i) => {
          const isDisabled = disabled || isLoading;
          // Render a loading indicator while the card, but only on subsequent loads after the first.
          // For the initial load we will show a single spinner on the dashboard
          const icon = isLoading ? (
            <Spinner $isMobile={!!isMobile} />
          ) : (
            <Icon
              size={iconSize}
              color={isDisabled ? "objectPrimary" : "brandSecondary"}
              background={iconBg(isDisabled)}
            />
          );

          const handleClick = () => {
            if (url === routes.scheduling) {
              logButtonClickEvent("Schedule a visit");
            } else if (url === routes.telehealthIntro) {
              logButtonClickEvent("Get virtual urgent care");
            } else if (url === routes.urgentCare) {
              logButtonClickEvent("Find a CityMD");
            }

            onLinkClick(url);
          };

          return (
            <DashboardCard
              key={i}
              title={title}
              description={description}
              disabled={isDisabled}
              onClick={handleClick}
              isMobile={!!isMobile}
              tooltipText={tooltipText}
              icon={icon}
            />
          );
        })}
      </Base>
    </>
  );
};

export default DashboardLinks;
