import { makeVar } from "@apollo/client";

import {
  DiagnosticReportCategory,
  GetDiagnosticReportsVariables,
  SortDirection,
} from "/apollo/schema";

export const initialValues = {
  categories: [DiagnosticReportCategory.LAB, DiagnosticReportCategory.RAD],
  endDate: null,
  first: 15,
  sort: SortDirection.DESC,
  startDate: null,
};

const reactiveVar = makeVar<Omit<GetDiagnosticReportsVariables, "lat" | "lng">>(initialValues);

export default {
  var: reactiveVar,
  update(value: Partial<GetDiagnosticReportsVariables>) {
    return reactiveVar({ ...reactiveVar(), ...value });
  },
  read() {
    return reactiveVar();
  },
  reset() {
    return reactiveVar(initialValues);
  },
};
