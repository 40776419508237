import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React from "react";
import { useTranslation } from "react-i18next";

import { Text } from "/component/base";
import { Check, Medicine } from "/component/base/Icon";
import { layout } from "/styles";
import { reactPlugin } from "/util/appInsights.util";
import { formatDate } from "/util/date";
import { toUserFriendlyStatus } from "/util/prescription.util";

import { Base, Metadata } from "./PrescriptionCard.styles";
import { Props } from "./PrescriptionCard.types";

const PrescriptionCard = ({ prescription, ...restProps }: Props) => {
  const { t } = useTranslation("prescriptions");

  return (
    <Base {...restProps}>
      <Medicine color="brandSecondary" background={{ size: 48, color: "brandSecondaryLight" }} />

      <Metadata>
        <Text variant="body1Bold" element="h3" css={layout.margin("none", "none", "base", "none")}>
          {prescription.documentDescription}
        </Text>
        <div css={layout.flexSpaceHorizontal}>
          <Check color="textGreen" size={16} />
          <Text
            variant="body2Bold"
            element="p"
            color="textGreen"
            css={layout.margin("none", "none", "none", "base")}
          >
            {toUserFriendlyStatus(prescription.status || undefined)}
          </Text>
        </div>
        {prescription.createdDateTime && (
          <Text variant="body2" element="p">
            {t("date", { date: formatDate(prescription.createdDateTime, "LLL dd, yyyy") })}
          </Text>
        )}
      </Metadata>
    </Base>
  );
};

export default withAITracking(reactPlugin, PrescriptionCard, undefined, "ai-tracking");
