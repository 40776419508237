import React from "react";

import { Base, LinkButton } from "./Link.styles";
import { AnchorProps, LinkButtonProps, Props } from "./Link.types";

/**
 * A link provides an interactive reference to a resource. This `Link` component
 * renders a themed version of the react-router's own `Link` component. If you
 * need a version of this component that renders a native HTML anchor tag,
 * instead use Link.Anchor, eg:
 * `<Link.Anchor href={someUrl}>`.
 *
 * If you need a link that is styled to appear as a Button, you may want to use
 * the `Link.Button` component, eg:
 * `<Link.Button to={somePath}>`.
 *
 * All unhandled props are forwarded to the root element. This includes all
 * appropriate HTML attributes or aria tags.
 */
const Link = ({ variant = "primary", ...rest }: Props) => <Base $variant={variant} {...rest} />;

Link.Anchor = ({ variant = "primary", ...rest }: AnchorProps) => (
  <Base as="a" $variant={variant} {...rest} />
);

/**
 * Link.Button is a Link (<a> tag) that is styled like a button. It accepts all the same props
 * as a link (eg: to, target) but also accepts a button `variant`.
 */
Link.Button = ({ children, variant = "primary", ...rest }: LinkButtonProps) => (
  <LinkButton $variant={variant} {...rest}>
    {children}
  </LinkButton>
);

export default Link;
