import { makeVar } from "@apollo/client";

import { DocumentType } from "/apollo/schema";
import { DocumentVariables } from "/util/documents.util";

export const initialValues = {
  types: [
    DocumentType.DISCHARGE_INSTRUCTIONS,
    DocumentType.WORK_SCHOOL_NOTE,
    DocumentType.VISIT_SUMMARY,
  ],
  endDate: undefined,
  startDate: undefined,
  providers: [],
};

const reactiveVar = makeVar<DocumentVariables>(initialValues);

export default {
  var: reactiveVar,
  update(value: Partial<DocumentVariables>) {
    return reactiveVar({ ...reactiveVar(), ...value });
  },
  read() {
    return reactiveVar();
  },
  reset() {
    return reactiveVar(initialValues);
  },
};
