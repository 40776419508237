import { useField } from "formik";
import React from "react";

import InputWrapper from "/component/base/InputWrapper";
import TextArea from "/component/base/TextArea";

import Props from "./TextAreaField.types";

const TextAreaField = React.forwardRef<HTMLTextAreaElement, Props>(
  ({ className, label, inputWrapperVariant = "primary", ...props }, ref) => {
    const [field, meta] = useField(props);

    const lengthString = props.maxLength
      ? ` ${field.value ? field.value.length : 0}/${props.maxLength} characters`
      : "";

    return (
      <InputWrapper
        className={className}
        error={meta.error}
        htmlFor={field.name}
        touched={meta.touched}
        variant={inputWrapperVariant}
        label={label}
        accessoryText={lengthString}
      >
        <TextArea
          {...field}
          {...props}
          value={field.value ? field.value : ""}
          $hasError={meta.touched && !!meta.error}
          ref={ref}
        />
      </InputWrapper>
    );
  },
);

export default TextAreaField;
