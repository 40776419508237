import { gql, useQuery } from "@apollo/client";

import { GetAmwellFlag } from "/apollo/schema/types";

const GET_COUPON_CODE_ENABLED = gql`
  query GetAmwellFlag {
    amwellCouponEnabled
  }
`;

// useQuery is fully typed and return value is inferred.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useShowAmwellCoupon = () =>
  useQuery<GetAmwellFlag>(GET_COUPON_CODE_ENABLED, {
    fetchPolicy: "no-cache",
  });
