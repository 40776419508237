import qs from "qs";

import { formatText } from "/util";

type Coordinates = { lat?: number; lng?: number };

type FacilityLike = {
  coordinates?: Coordinates | null;
  address?: formatText.Address | null;
};

/**
 * Given a Facility-like object return a link to google maps, with a pin in the facility
 */
export const getGoogleMapsLink = (facility: FacilityLike | null | undefined) => {
  if (!(facility?.coordinates || facility?.address)) return;

  // Cast to Address below because we know one of coordinates or address is truthy, and if it's not
  // `facility.coordinates`, that means it's the address
  const query = facility.coordinates
    ? `${facility.coordinates.lat},${facility.coordinates.lng}`
    : Object.values(facility.address as formatText.Address).join(" ");

  const params = {
    api: 1,
    query,
  };

  // Docs on how this link was constructed
  // https://developers.google.com/maps/documentation/urls/get-started#search-action
  return `https://www.google.com/maps/search/?${qs.stringify(params)}`;
};
