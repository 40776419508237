import { TextVariants } from "./default.types";

const CeraProRegular = "CeraPro-Regular";
const CeraProMedium = "CeraPro-Medium";
const CeraProBold = "CeraPro-Bold";
const CeraProRegularItalic = "CeraPro-RegularItalic";
const CeraProBoldItalic = "CeraPro-BoldItalic";

export const fontFamilies = {
  CeraProRegular,
  CeraProBold,
  CeraProRegularItalic,
  CeraProBoldItalic,
} as const;

export const fontVariants: { [Key in TextVariants]: string } = {
  body1: CeraProRegular,
  body1Medium: CeraProMedium,
  body1Bold: CeraProBold,
  body1Italic: CeraProRegularItalic,
  body1BoldItalic: CeraProBoldItalic,
  body2: CeraProRegular,
  body2Medium: CeraProMedium,
  body2Bold: CeraProBold,
  body2Italic: CeraProRegularItalic,
  body2BoldItalic: CeraProBoldItalic,
  body3: CeraProRegular,
  body3Bold: CeraProBold,
  body3Italic: CeraProRegularItalic,
  body3BoldItalic: CeraProBoldItalic,
  headline: CeraProBold,
  navigation: CeraProRegular,
  navigationBold: CeraProBold,
  title1: CeraProBold,
  title2: CeraProBold,
  title3: CeraProBold,
  buttonTitle: CeraProRegular,
};
