import styled from "styled-components";
import { Swiper as SwiperBase } from "swiper/react";

import Button from "/component/base/Button";
import { layout } from "/styles";

export const Swiper = styled(SwiperBase)(({ theme }) => ({
  display: "flex",
  ".swiper-slide": {
    width: "fit-content",
  },
  marginTop: theme.spacing.standard,
  "div.swiper-button-prev": {
    display: "none",
  },
  "div.swiper-button-next": {
    display: "none",
  },
}));

export const NavButton = styled(Button)<{ $direction: "start" | "end" }>(
  layout.flexCenter,
  ({ $direction }) => ({
    position: "absolute",
    // Ensure that the button appears over the fade overlay
    zIndex: 3,
    right: $direction === "start" ? "initial" : 0,
    left: $direction === "end" ? "initial" : 0,
    top: 0,
    height: 40,
    width: 40,
    borderRadius: 30,
    overflow: "visible",
  }),
);

export const FadeOverlay = styled.div<{ $direction: "start" | "end" }>(({ theme, $direction }) => ({
  position: "absolute",
  // Swiper will set the zIndex of the element that wraps the slides to zIndex 1,
  // we want this to appear above the slides
  zIndex: 2,
  right: $direction === "start" ? "initial" : 0,
  left: $direction === "end" ? "initial" : 0,
  top: 0,
  height: 40,
  width: 100,
  "button:disabled + &": {
    display: "none",
  },
  background: `linear-gradient(${$direction === "start" ? "90deg" : "270deg"}, ${
    theme.colors.backgroundPrimary
  } 45.74%, rgba(250, 250, 250, 0) 100%)`,
}));

export const TimeButton = styled(Button)(({ theme }) => ({
  height: 36,
  width: 102,
  // Add margin of 2 to top and bottom so overall size matches navigation button
  margin: "2px 0",
  color: theme.colors.textPrimary,
}));
