import styled from "styled-components";

import { ListItem } from "/component/base";
import Text from "/component/base/Text";
import { layout } from "/styles";

export const AddressText = styled(Text)(layout.padding("standard", "skip"), ({ theme }) => ({
  marginLeft: theme.spacing.standard,
  flexGrow: 1,
  textAlign: "start",
}));

export const TextInputIconWrapper = styled.div(layout.flexCenter, ({ theme }) => ({
  marginRight: theme.spacing.condensed,
}));

export const CurrentLocationButton = styled(ListItem.Button)(
  layout.margin("gutter", "skip", "skip"),
  {
    height: 56,
  },
);
