import { withAITracking } from "@microsoft/applicationinsights-react-js";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as html2pdf from "html2pdf.js";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { RouteComponentProps } from "react-router";

import { FacilityOrg } from "/apollo/schema";
import myHealthNoResults from "/asset/image/myHealthNoResults.png";
import { Avatar, Button, Loading, Text } from "/component/base";
import Divider from "/component/base/Divider";
import { ChevronRight, Clipboard, ClipboardHeart, FeatherIcon } from "/component/base/Icon";
import MainContent from "/component/layout/MainContent";
import ResultSummaryModal from "/component/modal/ResultSummaryModal";
import usePersonalInformationQuery from "/component/page/Account/page/PersonalInformation/usePersonalInformationQuery";
import CtaCard from "/component/partial/CtaCard";
import DiagnosticReportObservationsTable from "/component/partial/DiagnosticReportObservationsTable";
import EmptyStateCta from "/component/partial/EmptyStateCta";
import { useToastContext } from "/component/provider/ToastProvider";
import { useTranslation } from "/hook";
import { card, layout } from "/styles";
import { date, formatText } from "/util";
import { reactPlugin } from "/util/appInsights.util";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  IndentifierParams,
  logEvent,
} from "/util/firebase.util";

import NegativeResultGuide from "../ResultB2C/components/NegativeResultGuide";
import { ButtonStyled } from "./Result.styles";
import { generateResultsHTML } from "./Result.util";
import { useResult } from "./useResult";

const READ_MORE_THRESHOLD = 100;

const IMAGE_HEIGHT = 229;
const IMAGE_WIDTH = 331;

const Result = ({ match }: RouteComponentProps<{ id: string }>) => {
  const { t } = useTranslation("result");
  const resultId = match.params.id;

  const { data, loading } = useResult({ id: resultId });
  const { diagnosticReport } = data || {};
  const { contactInfo } = data || {};
  const isSummitSource = diagnosticReport?.sourceOrg === FacilityOrg.SMG;
  const afterCarePhone = contactInfo?.find(
    ({ name }) => name === (isSummitSource ? "summitCallCenter" : "cityMdAftercare"),
  )?.phoneNumber;

  const { data: patientData } = usePersonalInformationQuery();
  const patient = patientData?.viewer?.user?.patient;

  const showReadMore = diagnosticReport?.testSummary
    ? diagnosticReport.testSummary.length > READ_MORE_THRESHOLD
    : true;

  const { showToast } = useToastContext();
  const [showNotes, setShowNotes] = useState(false);
  const [generatingPDF, setGeneratingPDF] = useState(false);

  const handlePDFDownload = async () => {
    if (diagnosticReport && patientData) {
      setGeneratingPDF(true);
      const {
        observations,
        receivedDateTime,
        reportTitle,
        testSummary,
        reportLocation,
        sourceOrg,
        orderedBy,
      } = diagnosticReport;
      const fileName = reportTitle ? reportTitle.replace(/[/\\?%*:|"<>]/g, "") : "Test Results";

      const html = generateResultsHTML(
        patient,
        observations,
        receivedDateTime,
        fileName,
        testSummary || "",
        reportLocation,
        sourceOrg,
        orderedBy,
      );

      html2pdf()
        .set({
          margin: [8, 8, 8, 8],
        })
        .from(html)
        .toPdf()
        .save(fileName)
        .catch(() => {
          showToast({
            icon: "alert",
            message: t("pdfError"),
            type: "error",
          });
        })
        .finally(() => {
          setGeneratingPDF(false);
        });
    }
  };

  const logButtonClickEvent = (buttonName: string, params?: IndentifierParams) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.MY_HEALTH,
      source: AnalyticsSource.RESULT_DETAILS,
      button_name: buttonName,
      ...params,
    } as ButtonClickParams);
  };

  if (!data && loading) {
    return (
      <MainContent parentPage="results">
        <Loading variant="page" />
      </MainContent>
    );
  }

  if (diagnosticReport) {
    return (
      <MainContent
        pageTitle={
          <div
            css={[layout.flexVertical, layout.flexLTR, layout.spacedChildrenVertical("condensed")]}
          >
            <Text
              css={[layout.margin("none"), { textTransform: "capitalize" }]}
              element="h1"
              variant="title1"
              color="textTitle"
            >
              {diagnosticReport.reportTitle || t("headline.results")}
            </Text>

            <Text color="textSecondary">
              {t("receivedOn", {
                date: date.formatDate(diagnosticReport.receivedDateTime, "MMMM d, y"),
                context: diagnosticReport.receivedDateTime ? "hasDate" : undefined,
              })}
            </Text>
          </div>
        }
        headerAccessory={
          <ButtonStyled
            aria-label={t("downloadResults")}
            isLoading={generatingPDF}
            onClick={() => {
              logButtonClickEvent("Download (icon)", { result_id: resultId });
              handlePDFDownload();
            }}
            variant="secondary"
          >
            <FeatherIcon size={24} name="share" color="brandSecondary" />
          </ButtonStyled>
        }
        parentPage="results"
      >
        <Helmet>
          <title>
            {t("pageTitle", {
              title: diagnosticReport.reportTitle,
              context: diagnosticReport.reportTitle ? "hasTitle" : undefined,
            })}
          </title>
        </Helmet>
        {diagnosticReport.requestedByProvider && (
          <aside
            css={[layout.padding("skip", "skip", "expanded"), layout.spacedChildrenVertical()]}
          >
            <Text variant="title3">{t("title.requestedBy")}</Text>

            <div css={[layout.flexLTR, layout.spacedChildrenHorizontal()]}>
              <Avatar
                alt={diagnosticReport.requestedByProvider.nameWithDesignations}
                css={layout.flexItemIntrinsic}
                initials={formatText.parseInitials(diagnosticReport.requestedByProvider.name)}
                src={diagnosticReport.requestedByProvider.photoUrl}
              />

              <div css={layout.flexVertical}>
                <Text>{diagnosticReport.requestedByProvider.nameWithDesignations}</Text>

                <Text color="textSecondary" variant="body2">
                  {diagnosticReport.requestedByProvider.primarySpecialty}
                </Text>
              </div>
            </div>
          </aside>
        )}

        <aside
          css={[
            card.base,
            layout.padding("expanded"),
            layout.flexSpaceHorizontal,
            layout.spacedChildrenHorizontal(),
          ]}
        >
          <div css={[layout.flexVertical, layout.flexLTR, layout.spacedChildrenVertical()]}>
            <Text color="textSecondary" variant="body2Bold">
              {diagnosticReport.testSummary
                ? t("title.noteFromProvider")
                : t("title.noteOnResults")}
            </Text>
            <Text css={layout.lineClamp(3)}>
              {diagnosticReport.testSummary ? diagnosticReport.testSummary : t("disclaimer.header")}
            </Text>

            {showReadMore && (
              <Button
                onClick={() => {
                  logButtonClickEvent("Read more");
                  setShowNotes(true);
                }}
                variant="borderBottom"
              >
                {t("button.showSummary")}
              </Button>
            )}
          </div>

          <ClipboardHeart
            aria-hidden
            background={{ size: 48, color: "brandSecondaryLight" }}
            color="brandSecondary"
            css={layout.flexItemIntrinsic}
          />
        </aside>

        <article css={layout.padding("expanded", "skip", "skip")}>
          <Text css={layout.padding("skip", "skip", "standard")} element="h3" variant="title3">
            {t("title.resultDetails")}
          </Text>

          {diagnosticReport.resultMedia && (
            <a
              css={[card.base, layout.flexSpaceHorizontal, layout.padding("standard")]}
              download={diagnosticReport.reportTitle}
              href={`data:${diagnosticReport.resultMedia.contentType};base64,${diagnosticReport.resultMedia.data}`}
              rel="noreferrer"
              target="_blank"
            >
              <Clipboard
                aria-hidden
                background={{ color: "brandSecondaryLight", size: 48 }}
                css={layout.flexItemIntrinsic}
                color="brandSecondary"
              />
              <span
                css={[layout.flexVertical, layout.padding("skip", "standard"), layout.flexItemAuto]}
              >
                <Text variant="body1Bold">{diagnosticReport.reportTitle}</Text>
                <Text color="textSecondary" variant="body2">
                  {date.formatDate(diagnosticReport.receivedDateTime, "MMMM d, y")}
                </Text>
              </span>

              <ChevronRight color="brandSecondary" size={16} />
            </a>
          )}

          {!!diagnosticReport.observations?.length && (
            <>
              <DiagnosticReportObservationsTable observations={diagnosticReport.observations} />
              <Button
                fullWidth="percent"
                css={layout.margin("skip", "skip", "standard")}
                onClick={() => {
                  logButtonClickEvent("Download & share", { result_id: resultId });
                  handlePDFDownload();
                }}
                variant="tertiary"
              >
                {t("download")}
              </Button>

              <NegativeResultGuide />
            </>
          )}

          {diagnosticReport?.sourceOrg && (
            <>
              <Divider />
              <CtaCard
                css={[layout.marginVertical(40), { maxWidth: 327 }]}
                links={[
                  {
                    href: `tel:${afterCarePhone}`,
                    title: isSummitSource ? t("cta.buttonSmg") : t("cta.buttonCmd"),
                    analyticsParams: {
                      user_flow: AnalyticsUserFlow.MY_HEALTH,
                      source: AnalyticsSource.RESULT_DETAILS,
                      button_name: "Call aftercare",
                    } as ButtonClickParams,
                  },
                ]}
                subtitle={
                  isSummitSource
                    ? t("cta.subtitleSmg", { phoneNumber: formatText.parsePhone(afterCarePhone) })
                    : t("cta.subtitleCmd")
                }
                title={t("cta.title")}
              />
            </>
          )}
        </article>

        <ResultSummaryModal
          close={() => setShowNotes(false)}
          isOpen={showNotes}
          testSummary={diagnosticReport.testSummary}
        />
      </MainContent>
    );
  }

  return (
    <MainContent pageTitle={t("headline.results")} parentPage="results">
      <Helmet>
        <title>{t("pageTitle")}</title>
      </Helmet>

      <div
        css={[
          layout.margin("expanded", "skip"),
          layout.padding("expanded", "skip"),
          layout.flexCenter,
        ]}
      >
        <EmptyStateCta
          imageHeight={IMAGE_HEIGHT}
          imageWidth={IMAGE_WIDTH}
          imageSource={myHealthNoResults}
          title={t("alert.fetchError.title")}
          subtitle={t("alert.fetchError.subtitle")}
        />
      </div>
    </MainContent>
  );
};

export default withAITracking(reactPlugin, Result, undefined, "ai-tracking");
