import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  CaregiverAccessDetails_viewer_user_caregiverAccess,
  CaregiverAccessDetails_viewer_user_caregivers,
  CaregiverStatus,
} from "/apollo/schema";
import { Button, Loading, Text } from "/component/base";
import { ArrowUpRight } from "/component/base/Icon";
import CaregiverCard from "/component/partial/CaregiverCard";
import { useCaregiverProvider } from "/component/provider/CaregiverProvider";
import { CaregiverFormType } from "/constant/caregiver.constant";
import routes from "/constant/url.constant";
import { layout } from "/styles";
import { formatDate } from "/util/date";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  logEvent,
} from "/util/firebase.util";

import { CaregiverItemCard } from "./CaregiverAccessList.styles";

interface Props {
  caregivers: CaregiverAccessDetails_viewer_user_caregivers[];
  caregiverAccessList: CaregiverAccessDetails_viewer_user_caregiverAccess[];
  sendCaregiverReminder: (caregiverAccessId: string, pendingAccountCreation: boolean) => void;
  deletePendingCaregiver: (caregiverAccessId: string, pendingAccountCreation: boolean) => void;
  goToForms: (formName: CaregiverFormType) => void;
  caregiverClick: (
    caregiverDetails:
      | CaregiverAccessDetails_viewer_user_caregivers
      | CaregiverAccessDetails_viewer_user_caregiverAccess,
  ) => void;
  continuePending: (caregiverDetails: CaregiverAccessDetails_viewer_user_caregiverAccess) => void;
}

const MAX_CAREGIVER_ACCESS = 10;

const CaregiverAccessList = ({
  caregivers = [],
  caregiverAccessList = [],
  deletePendingCaregiver,
  goToForms,
  sendCaregiverReminder,
  continuePending,
}: Props) => {
  const { t, ready } = useTranslation("caregiverAccessList");
  const { caregiverMode } = useCaregiverProvider();
  const history = useHistory();

  enum ActionType {
    PENDINGINVITE = "pending_invite",
    PENDINGVERIFICATION = "pending_verification",
  }

  if (!ready) return <Loading />;

  const caregiverGranted = caregivers.filter(
    (c) => c.statusDetails.status === CaregiverStatus.GRANTED,
  );
  const caregiverInvited = caregivers.filter(
    (c) => c.statusDetails.status === CaregiverStatus.INVITED,
  );
  const caregiverAccessGranted = caregiverAccessList.filter(
    (c) => c.statusDetails.status === CaregiverStatus.GRANTED,
  );
  const caregiverAccessInvited = caregiverAccessList.filter(
    (c) => c.statusDetails.status === CaregiverStatus.INVITED && c.isMinor,
  );

  const totalAccess = caregiverAccessGranted.length + caregiverAccessInvited.length;

  const logButtonClickEvent = (buttonName: string) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.CAREGIVER,
      source: AnalyticsSource.CAREGIVER_ACCESS_LIST,
      button_name: buttonName,
    } as ButtonClickParams);
  };

  const handlePendingVerification = (item: CaregiverAccessDetails_viewer_user_caregiverAccess) => {
    continuePending(item);
  };

  const goToDetail = (
    caregiver:
      | CaregiverAccessDetails_viewer_user_caregiverAccess
      | CaregiverAccessDetails_viewer_user_caregivers,
    isMinor?: boolean,
    switchEnabled?: boolean,
  ) => {
    const allowSwitch =
      switchEnabled &&
      ((isMinor && !caregiver.hasAccount) ||
        (!isMinor &&
          (caregiver.statusDetails.status === CaregiverStatus.GRANTED ||
            caregiver.statusDetails.status === CaregiverStatus.INVITED)));

    history.push(routes.caregiverDetails, {
      data: caregiver,
      allowSwitch: allowSwitch,
    });
  };

  const generateCaregiverItem = (
    caregiverDetails: CaregiverAccessDetails_viewer_user_caregivers,
    isDisabled?: boolean,
    actionType?: ActionType,
    isMinor?: boolean,
    switchEnabled?: boolean,
  ) => {
    return (
      <CaregiverItemCard
        disabled={isDisabled}
        onClick={() => goToDetail(caregiverDetails, isMinor, switchEnabled)}
      >
        <CaregiverCard
          disabled={isDisabled}
          name={{
            firstName: caregiverDetails.firstName,
            lastName: caregiverDetails.lastName,
          }}
          hasActionButton={
            !caregiverMode &&
            actionType === ActionType.PENDINGINVITE && (
              <div css={[layout.flexLTR, layout.spacedChildrenHorizontal("condensed")]}>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    logButtonClickEvent("Cancel invitation");
                    caregiverDetails.id &&
                      deletePendingCaregiver(
                        caregiverDetails.id,
                        "pendingAccountCreation" in caregiverDetails &&
                          !!caregiverDetails.pendingAccountCreation,
                      );
                  }}
                  variant="borderBottomSecondary"
                >
                  {t("cancelInvite")}
                </Button>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    logButtonClickEvent("Send a reminder");
                    caregiverDetails.id &&
                      sendCaregiverReminder(
                        caregiverDetails.id,
                        "pendingAccountCreation" in caregiverDetails &&
                          !!caregiverDetails.pendingAccountCreation,
                      );
                  }}
                  variant="borderBottomSecondary"
                >
                  {t("sendReminder")}
                  <ArrowUpRight size={14} />
                </Button>
              </div>
            )
          }
        >
          <Text color="textSecondary" variant="body3">
            {formatDate(caregiverDetails.statusDetails.createdDate, "LLLL dd, yyyy")}
          </Text>
        </CaregiverCard>
      </CaregiverItemCard>
    );
  };

  const generateCaregiverAccessItem = (
    caregiverDetails: CaregiverAccessDetails_viewer_user_caregiverAccess,
    isDisabled?: boolean,
    actionType?: ActionType,
    isMinor?: boolean,
    switchEnabled?: boolean,
  ) => {
    return (
      <CaregiverItemCard
        disabled={isDisabled}
        onClick={() => goToDetail(caregiverDetails, isMinor, switchEnabled)}
      >
        <CaregiverCard
          disabled={isDisabled}
          name={{
            firstName: caregiverDetails.firstName,
            lastName: caregiverDetails.lastName,
          }}
          hasActionButton={
            !caregiverMode &&
            actionType === ActionType.PENDINGVERIFICATION &&
            caregiverDetails.isMinor && (
              <div css={[layout.flexLTR, layout.spacedChildrenHorizontal("condensed")]}>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    caregiverDetails.id && handlePendingVerification(caregiverDetails);
                  }}
                  variant="borderBottomSecondary"
                >
                  {t("pendingVerification")}
                </Button>
              </div>
            )
          }
        >
          <Text color="textSecondary" variant="body3">
            {formatDate(caregiverDetails.statusDetails.createdDate, "LLLL dd, yyyy")}
          </Text>
        </CaregiverCard>
      </CaregiverItemCard>
    );
  };

  const showMyAccountsAccess = caregiverAccessList && caregiverAccessList.length > 0;
  const showCaregiver = caregivers && caregivers.length > 0;

  return (
    <>
      <div css={[layout.flexVertical, layout.spacedChildrenVertical("standard")]}>
        <Text variant="title1" color="brandNavy">
          {t("title")}
        </Text>
        <Text
          css={layout.margin("condensed", "none", "standard", "none")}
          variant="body1"
          color="textSecondary"
        >
          {t("subtitle")}
        </Text>

        <Text variant="title3">{t("listCaregiverTitle")}</Text>

        {showMyAccountsAccess ? (
          <div
            css={[
              layout.paddingVertical("standard"),
              layout.flexVertical,
              layout.spacedChildrenVertical("standard"),
            ]}
          >
            {caregiverAccessInvited.map((caregiver) => (
              <div
                key={caregiver.id}
                css={[layout.flexVertical, layout.spacedChildrenVertical("standard")]}
              >
                {generateCaregiverAccessItem(
                  caregiver,
                  false,
                  ActionType.PENDINGVERIFICATION,
                  !!caregiver.isMinor,
                  true,
                )}
              </div>
            ))}

            {caregiverAccessGranted.map((caregiver) => (
              <div
                key={caregiver.id}
                css={[layout.flexVertical, layout.spacedChildrenVertical("standard")]}
              >
                {generateCaregiverAccessItem(
                  caregiver,
                  false,
                  undefined,
                  !!caregiver.isMinor,
                  true,
                )}
              </div>
            ))}
          </div>
        ) : (
          <Text
            css={layout.margin("condensed", "none", "standard", "none")}
            variant="body2"
            color="textSecondary"
          >
            {t("emptyRecordSubtitle")}
          </Text>
        )}

        {!caregiverMode && totalAccess < MAX_CAREGIVER_ACCESS && (
          <Button
            onClick={() => {
              logButtonClickEvent("Become a caregiver to");
              goToForms(CaregiverFormType.AddChild);
            }}
            variant="tertiary"
          >
            {t("addChild")}
          </Button>
        )}

        <Text variant="title3">{t("accessListTitle")}</Text>

        {showCaregiver ? (
          <div
            css={[
              layout.paddingVertical("standard"),
              layout.flexVertical,
              layout.spacedChildrenVertical("standard"),
            ]}
          >
            {caregiverInvited.map((caregiver) => (
              <div
                key={caregiver.id}
                css={[layout.flexVertical, layout.spacedChildrenVertical("standard")]}
              >
                {generateCaregiverItem(caregiver, true, ActionType.PENDINGINVITE)}
              </div>
            ))}

            {caregiverGranted.map((caregiver) => (
              <div
                key={caregiver.id}
                css={[layout.flexVertical, layout.spacedChildrenVertical("standard")]}
              >
                {generateCaregiverItem(caregiver, false, undefined, false, true)}
              </div>
            ))}
          </div>
        ) : (
          <Text
            css={layout.margin("condensed", "none", "standard", "none")}
            variant="body2"
            color="textSecondary"
          >
            {t("emptyAccessSubtitle")}
          </Text>
        )}
        {!caregiverMode && (
          <Button
            onClick={() => {
              logButtonClickEvent("Add another caregiver");
              goToForms(CaregiverFormType.InviteCaregiver);
            }}
            variant="tertiary"
          >
            {t("shareRecord")}
          </Button>
        )}
      </div>
    </>
  );
};

export default CaregiverAccessList;
