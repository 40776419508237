import React from "react";

import { Text } from "/component/base";
import { layout } from "/styles";

import { Props } from "./ConsumerPharmacyItem.types";

const ConsumerPharmacyItem = ({ className, name, address, formattedPhone }: Props) => {
  return (
    <div className={className} css={layout.flexLTR}>
      <div
        css={[
          layout.flexVertical,
          layout.padding("standard", "skip", "standard", "skip"),
          layout.flexItemAuto,
        ]}
      >
        <Text variant="body1Bold">{name}</Text>
        {address && (
          <Text color="textSecondary">
            {address.address1}
            <br />
            {[address.city, address.stateCode, address.zipCode].filter(Boolean).join(", ")}
            <br />
            {[formattedPhone]}
          </Text>
        )}
      </div>
    </div>
  );
};

export default ConsumerPharmacyItem;
