import styled from "styled-components";

import { Button } from "/component/base";
import Text from "/component/base/Text";
import BackButtonBase from "/component/partial/BackButton";
import { layout } from "/styles";

export const Base = styled.div(layout.absoluteFill, layout.flexCenterHorizontal, ({ theme }) => ({
  backgroundColor: theme.colors.backgroundDark,
  color: theme.colors.textInverse,
  overflowY: "auto",
  zIndex: 4,
}));

export const BackButton = styled(BackButtonBase)(({ theme }) => ({
  border: "none",
  position: "absolute",
  top: 44,
  left: 16,
  [theme.media.desktop]: {
    top: 48,
    left: 40,
  },
}));

export const Content = styled.div(layout.flexVerticalCenter, layout.padding("standard", "gutter"), {
  margin: "auto",
  maxWidth: 470,
});

export const HeadlineText = styled(Text)(layout.flexItemIntrinsic, ({ theme }) => ({
  marginTop: theme.spacing.base * 13,
}));

export const ApptInfo = styled.div(layout.flexItemIntrinsic, layout.flexLTR, {
  marginTop: 59,
});

export const AddToCalButton = styled.div(layout.flexItemIntrinsic, ({ theme }) => ({
  marginTop: theme.spacing.gutter,
  [theme.media.tabletPortrait]: {
    alignSelf: "auto",
  },
}));

export const DoneButton = styled(Button)(layout.flexItemIntrinsic, ({ theme }) => ({
  marginTop: 127,
  [theme.media.tabletPortrait]: {
    maxWidth: 327,
  },
}));
