import React from "react";
import { Route, RouteProps } from "react-router-dom";

import MainLayout from "/component/layout/MainLayout";

const AppRoute = ({
  layout: Layout = MainLayout,
  component: Component,
  ...routeProps
}: { layout?: React.ElementType; component: React.ElementType } & RouteProps) => {
  return (
    <Route
      {...routeProps}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

export default AppRoute;
