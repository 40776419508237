import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "/component/base";
import OnboardingModal from "/component/modal/OnboardingModal";
import { AnalyticsEvent, ButtonClickParams, logEvent } from "/util/firebase.util";

const SignUpSignInButton = ({ analyticsParams }: { analyticsParams?: ButtonClickParams }) => {
  const [showModal, setShowModal] = useState(false);

  const { t } = useTranslation("partial-signUpSignInButtton");

  const logButtonClickEvent = (buttonName: string) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      ...analyticsParams,
      button_name: buttonName,
    } as ButtonClickParams);
  };

  return (
    <>
      <Button
        variant="tertiary"
        onClick={() => {
          logButtonClickEvent("Sign up / Log in");
          setShowModal(true);
        }}
      >
        {t("signInSignUp")}
      </Button>
      {showModal && <OnboardingModal onClose={() => setShowModal(false)} />}
    </>
  );
};

export default SignUpSignInButton;
