import { AccordionItem, AccordionItemButton, AccordionItemPanel } from "react-accessible-accordion";
import styled from "styled-components";

export const AccordionItemWrapper = styled(AccordionItem)<{ $hideLastChildBorder?: boolean }>(
  ({ theme, $hideLastChildBorder }) => ({
    borderBottom: `${$hideLastChildBorder ? 0 : 1}px solid ${theme.colors.objectSubtle}`,
    paddingLeft: theme.spacing.gutter,
    paddingRight: theme.spacing.gutter,
    "&:hover": {
      backgroundColor: theme.colors.backgroundSurfaceHover,
    },
    "&:active": {
      backgroundColor: theme.colors.backgroundSurfaceActive,
    },
  }),
);

export const AccordionHeadingContent = styled(AccordionItemButton)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  justifyItems: "center",
  paddingBottom: theme.spacing.gutter,
  paddingTop: theme.spacing.gutter,
  cursor: "pointer",
  lineHeight: theme.lineHeights.body1,
}));

export const AccordionPanelContent = styled(AccordionItemPanel)(({ theme }) => ({
  paddingBottom: theme.spacing.expanded,
  paddingTop: theme.spacing.base,
  lineHeight: theme.lineHeights.body1,
  color: theme.colors.textSecondary,
}));
