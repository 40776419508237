import React from "react";

import * as Icons from "/component/base/Icon";
import Text from "/component/base/Text";
import { layout } from "/styles";
import { formatDate } from "/util/date";

import { ContentWrapper, TextContent, TitleContent } from "./MedicationListItem.styles";

interface Props {
  medication: {
    medicationName: string;
    datePrescribed: string;
  };
}

const MedicationListItem = ({ medication }: Props) => {
  const { medicationName, datePrescribed } = medication;
  const subtitle = `Added on ${formatDate(datePrescribed, "MMMM dd, yyyy")}`;

  return (
    <div
      css={[
        layout.flexCenterHorizontal,
        layout.margin("standard", "none"),
        layout.spacedChildrenHorizontal("standard"),
      ]}
    >
      <div>
        <Icons.Medication
          color="brandSecondary"
          background={{ size: 48, color: "brandSecondaryLight" }}
        />
      </div>
      <ContentWrapper>
        <TextContent>
          <TitleContent>
            <Text color={"textPrimary"} variant="body1Bold">
              {medicationName}
            </Text>
          </TitleContent>

          {datePrescribed && (
            <Text color="textSecondary" variant="body2">
              {subtitle}
            </Text>
          )}
        </TextContent>
      </ContentWrapper>
    </div>
  );
};

export default MedicationListItem;
