import { useMutation } from "@apollo/client";

import updatePreferredPharmacy from "/apollo/mutation/updatePreferredPharmacy";

export type PharmacyUpdateType = {
  ncpdpId: string;
  facilityId: string;
};

const usePharmacyDetailModal = ({
  failure,
  success,
}: {
  failure?: () => void;
  success?: () => void;
}) => {
  const [updatePharmacy, mutationState] = useMutation(updatePreferredPharmacy);

  const submitUpdatedPharmacy = async ({ ncpdpId, facilityId }: PharmacyUpdateType) => {
    try {
      await updatePharmacy({
        variables: {
          data: { facilityId, ncpdpId },
        },
      });

      success?.();
    } catch (err: any) {
      failure?.();
    }
  };

  return { ...mutationState, submitUpdatedPharmacy };
};

export default usePharmacyDetailModal;
