import styled from "styled-components";

import { ChevronRight as ChevronRightIcon } from "/component/base/Icon";
import Text from "/component/base/Text";
import { card, layout } from "/styles";

// ========================================================================
// The DashboardCard will appear as a Card on screen sizes >= tablet
// and will be rendered as a ListItem on smaller screens. I am not
// switching the rendered component in JS (from a Card to a <ListItem />)
// because it's easy enough to do in CSS.
// ========================================================================

export const Base = styled.li(({ theme }) => ({
  [theme.media.tablet]: {
    flexGrow: 0,
    flex: `0 0 ${(theme.sizes.mainContent - theme.spacing.standard * 2) / 3}px`,
  },
}));

export const TextWrapper = styled.div(({ theme }) => ({
  // Stretch the text to fill the "ListItem" on mobile
  [theme.media.max.tabletPortrait]: {
    flex: "1 1 auto",
    margin: `0 ${theme.spacing.standard}px`,
    display: "inline-block",
  },
}));

// TODO: need to add focus/active/hover state
export const Link = styled.button<{ isDisabled: boolean }>(({ theme, isDisabled }) => ({
  textAlign: "left",
  opacity: isDisabled ? 0.5 : 1,
  // Horizontally align the children on small screens to appear as a ListItem
  [theme.media.max.tabletPortrait]: {
    ...layout.flexCenterHorizontal,
    width: "100%",
    padding: `${theme.spacing.base * 5}px 0`,
    borderBottom: `1px solid ${theme.colors.objectSubtle}`,

    // On mobile we don't want the icon or chevron to flex at all. Use their intrinsic sizing
    [`> :not(${TextWrapper})`]: {
      flex: "0 0 auto",
    },

    "&:active": {
      opacity: "0.65",
    },
  },

  [theme.media.tablet]: {
    ...card.interactive({ theme }),
    borderRadius: 20,
    display: "block",
    paddingTop: theme.spacing.expanded,
    paddingRight: theme.spacing.gutter,
    paddingBottom: theme.spacing.gutter,
    paddingLeft: theme.spacing.gutter,
    width: "100%",
    height: "170px",
  },
}));

export const Title = styled(Text)(({ theme }) => ({
  marginBottom: theme.spacing.base,
  display: "block",
  [theme.media.tablet]: {
    marginTop: theme.spacing.standard,
  },
}));

export const ChevronRight = styled(ChevronRightIcon)(({ theme }) => ({
  [theme.media.tablet]: {
    display: "none",
  },
}));

// Set the IconWrapper to inline-block so that it doesn't fill the card horizontally, so that
// the tooltip arrow points to the icon itself
export const IconWrapper = styled.div({ display: "inline-block" });
