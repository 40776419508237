// Modules
import React from "react";
import { ThemeProvider as BaseProvider } from "styled-components";

// Config
import defaultTheme from "/theme/default";

// Local Components
import GlobalStyles from "./GlobalStyles";
import { Props } from "./ThemeProvider.types";

// Declare our theme so that our styled components can access the type
// This lets us use `${({theme}) => theme.colors.accent1}` and feel safe
// that we aren't mistyping a variable name
declare module "styled-components" {
  type Theme = typeof defaultTheme;
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends Theme {}
}

const ThemeProvider = ({ children }: Props): JSX.Element => {
  return (
    <BaseProvider theme={defaultTheme}>
      <React.Fragment>
        <GlobalStyles />
        {children}
      </React.Fragment>
    </BaseProvider>
  );
};

export default ThemeProvider;
