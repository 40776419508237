import styled from "styled-components";

import Alert from "/component/base/Alert";
import FormContent from "/component/partial/FormContent";
import { layout } from "/styles";

export const FormContentContainer = styled(FormContent)(layout.marginVertical("gutter"), {
  maxWidth: 580,
  width: "100%",
});

export const FeedbackAlert = styled(Alert)({
  maxWidth: 700,
  width: "100%",
});

export const IconWrapper = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: 98,
  height: 98,
  borderRadius: 49,
  backgroundColor: theme.colors.brandSecondary,
  marginBottom: theme.spacing.gutter,
}));
