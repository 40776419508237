import { gql, useQuery } from "@apollo/client";

import { UseContactInfoQuery } from "/apollo/schema";

export const contactSupportFragment = gql`
  fragment contactSupportFragment on ContactInfo {
    name
    phoneNumber
    faxNumber
    emailAddress
  }
`;

export const useContactInfoQuery = gql`
  ${contactSupportFragment}
  query UseContactInfoQuery {
    contactInfo {
      ...contactSupportFragment
    }
  }
`;

const useContactInfo = () => {
  const result = useQuery<UseContactInfoQuery>(useContactInfoQuery);

  const contactByName = (name: string) =>
    result.data?.contactInfo.find((contact) => contact.name === name);

  return { ...result, contactByName };
};

export default useContactInfo;
