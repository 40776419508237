import React from "react";

import { Colors } from "/theme/default";

import { BaseButton, BaseDiv, BaseLink, Chevron } from "./ListItem.styles";
import { ButtonProps, LinkProps, Props } from "./ListItem.types";

const ListItem = ({
  showBorder = true,
  showChevron = true,
  children,
  className,
  to,
  onClick,
}: LinkProps) => {
  return (
    <BaseLink to={to} className={className} $showBorder={showBorder} onClick={onClick}>
      {/* Setting the children since they could possible be divs */}
      {children}

      {showChevron && <Chevron size={16} color="brandSecondary" />}
    </BaseLink>
  );
};

ListItem.Div = ({ showBorder = true, showChevron = true, children, className }: Props) => {
  return (
    <BaseDiv className={className} $showBorder={showBorder}>
      {children}
      {showChevron && <Chevron size={16} color={"brandSecondary"} />}
    </BaseDiv>
  );
};

ListItem.Button = ({
  showBorder = true,
  showChevron = true,
  disabled,
  children,
  onClick,
  className,
  type = "button",
  chevronVariant,
}: ButtonProps) => {
  let color = disabled ? "objectPrimary" : "brandSecondary";
  if (chevronVariant === "grey") {
    color = disabled ? "textDisabled" : "greyDark";
  }

  return (
    <BaseButton
      className={className}
      disabled={disabled}
      onClick={onClick}
      $showBorder={showBorder}
      type={type}
    >
      {/* Setting the children since they could possible be divs */}
      {children}

      {showChevron && <Chevron size={16} color={color as Colors} />}
    </BaseButton>
  );
};

export default ListItem;
