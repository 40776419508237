/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useReactiveVar } from "@apollo/client";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React from "react";
import { Trans } from "react-i18next";
import { useHistory } from "react-router-dom";

import { auth, scheduling } from "/apollo/client/local";
import AddToCalendar from "/component/base/AddToCalendar";
import DateBadge from "/component/base/DateBadge";
import { CheckCircle } from "/component/base/Icon";
import Text from "/component/base/Text";
import MainContent from "/component/layout/MainContent";
import useHandleBackWhenBooking from "/component/page/Scheduling/hook/useHandleBackWhenBooking";
import formatTimeSlotDateTime from "/component/page/Scheduling/util/formatTimeSlotDate";
import EmptyStateCta from "/component/partial/EmptyStateCta";
import routes from "/constant/url.constant";
import { useTranslation } from "/hook";
import { layout } from "/styles";
import { reactPlugin } from "/util/appInsights.util";
import { AnalyticsEvent, AnalyticsSource, ButtonClickParams, logEvent } from "/util/firebase.util";

import {
  AddToCalButton,
  ApptInfo,
  BackButton,
  Base,
  Content,
  DoneButton,
  HeadlineText,
} from "./BookingConfirmed.styles";

const BookingConfirmed = () => {
  const history = useHistory();
  const { t } = useTranslation("bookingConfimed");
  const { booking, analyticsFlow } = useReactiveVar(scheduling.var);
  const { isAuthenticated } = useReactiveVar(auth.var);

  const handleBack = useHandleBackWhenBooking();

  const exit = () => {
    scheduling.reset();
    if (isAuthenticated) {
      history.push(routes.root);
    }
  };

  const logButtonClickEvent = (buttonName: string) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: analyticsFlow,
      source: AnalyticsSource.BOOKING_CONFIRMED,
      button_name: buttonName,
    } as ButtonClickParams);
  };

  if (!booking || !booking?.reservation || !booking?.timeSlot || !booking?.availability) {
    return (
      <MainContent backButton={<MainContent.BackButton onClick={() => handleBack()} />}>
        <EmptyStateCta
          title={t("loadingError")}
          subtitle={t("bookingNotFound.subtitle")}
          ctaText={t("bookingNotFound.cta")}
          onClickCta={handleBack}
        />
      </MainContent>
    );
  }

  return (
    <Base>
      <BackButton
        variant="inverse"
        icon="close"
        onClick={() => {
          logButtonClickEvent("Close (icon)");
          exit();
        }}
      />
      <Content>
        <CheckCircle css={layout.margin("expanded", "none", "none")} size={40} color="textGreen" />

        <HeadlineText align="center" color="textInverse" element="h1" variant="headline">
          {t("bookingConfirmed", "Your appointment is confirmed")}
        </HeadlineText>
        {/* `booking` should never be falsey in practice, but just to make sure I am adding this conditional */}
        {booking && (
          <ApptInfo>
            <DateBadge
              css={layout.margin("skip", "standard", "skip", "skip")}
              date={new Date(`${booking.timeSlot.date}T${booking.timeSlot.startTime}`)}
            />
            <Text color="textInverse">
              <div>
                <Trans
                  components={{ bold: <Text color="textInverse" variant="body1Bold" /> }}
                  i18nKey="description.when"
                  t={t}
                  values={{
                    date: formatTimeSlotDateTime(booking.timeSlot, "EEEE, MMMM do"),
                  }}
                />
              </div>
              <div>
                <Trans
                  components={{ bold: <Text color="textInverse" variant="body1Bold" /> }}
                  i18nKey="description.who"
                  t={t}
                  values={{
                    providerName: booking.availability.providerDetails.nameWithDesignations,
                  }}
                />
              </div>

              <div>
                <Trans
                  components={{ bold: <Text color="textInverse" variant="body1Bold" /> }}
                  i18nKey="description.where"
                  t={t}
                  values={{
                    providerAddress: booking.availability.providerDetails.shortAddress,
                  }}
                />
              </div>
            </Text>
          </ApptInfo>
        )}
        <AddToCalButton>
          <AddToCalendar
            btnLabel={t("addToCalendar")}
            name={t("appointmentConfirmed")}
            options={["Apple", "Google", "iCal"]}
            location="World Wide Web"
            startDate={booking.timeSlot.date}
            endDate={booking.timeSlot.date}
            startTime={booking.timeSlot.startTime}
            endTime={booking.timeSlot.endTime}
            timeZone="America/Los_Angeles"
          ></AddToCalendar>
        </AddToCalButton>
        <DoneButton
          fullWidth="percent"
          onClick={() => {
            logButtonClickEvent("Done");
            exit();
          }}
        >
          {t("closeCta", "Go Home")}
        </DoneButton>
      </Content>
    </Base>
  );
};

export default withAITracking(reactPlugin, BookingConfirmed, undefined, "ai-tracking");
