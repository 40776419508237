import { makeVar } from "@apollo/client";

interface FreshlyInvitedCaregiverState {
  inviteID?: string;
  freshInvite: boolean;
}

export const initialValues = {
  inviteID: null,
  freshInvite: false,
};
const CareGiverAccessInviteKey = "caregiver_access_invite";

const storedCaregiverState = localStorage.getItem(CareGiverAccessInviteKey);
const initialValue = storedCaregiverState ? JSON.parse(storedCaregiverState) : initialValues;

const reactiveVar = makeVar<FreshlyInvitedCaregiverState>(initialValue);

export default {
  var: reactiveVar,
  update(value: Partial<FreshlyInvitedCaregiverState>) {
    const currentVal = reactiveVar();
    const newValue = { ...currentVal, ...value };
    localStorage.setItem(CareGiverAccessInviteKey, JSON.stringify(newValue));
    return reactiveVar(newValue);
  },
  read() {
    return reactiveVar();
  },
};
