import awsdk from "awsdk";
import { Form, Formik, FormikHelpers } from "formik";
import React, { useEffect, useState } from "react";
import { AlertCircle } from "react-feather";
import * as Yup from "yup";

import { Button, Loading, Radio } from "/component/base";
import InlineMessage from "/component/base/InlineMessage";
import Text from "/component/base/Text";
import FormContent from "/component/partial/FormContent";
import PickerField from "/component/partial/formik/PickerField";
import RadioGroupField from "/component/partial/formik/RadioGroupField";
import TextField from "/component/partial/formik/TextField";
import {
  InsuranceDetailProps,
  useAmwellPrevisitContext,
} from "/component/provider/AmwellPrevisitProvider";
import { month, PrevisitForms } from "/constant/telehealth.constant";
import { useOutages, useTranslation } from "/hook";
import { useAmwellInsurance } from "/hook/useAmwellInsurance/useAmwellInsurance";
import { layout } from "/styles";
import theme from "/theme/default";

import EligibilityFailedModal from "../../../modal/EligibilityFailedModal";
import { Title } from "../Telehealth.styles";
import { TelehealthFormProps } from "../Telehealth.types";

const InsuranceDetail = ({
  handleError,
  handleSubmit,
  initialValues,
}: TelehealthFormProps<InsuranceDetailProps>) => {
  const { t } = useTranslation("form-telehealth-previsit");
  const { data, loading } = useAmwellInsurance();

  const { consumer, sdk, visitContext } = useAmwellPrevisitContext();
  const outage = useOutages("TelehealthInsuranceDetail");
  const [visitCost, setVisitCost] = React.useState<awsdk.AWSDKVisitCost | undefined>();
  const [formValues, setFormValues] = useState<InsuranceDetailProps>(initialValues);
  const [suffixRequired, setSuffixRequired] = useState<boolean>(false);
  const [eligibilityFailed, setEligibilityFailed] = useState<boolean>(false);

  const isPrimarySubscriberYup = Yup.string().when("isPrimarySubscriber", {
    is: (isPrimarySubscriber) => isPrimarySubscriber === "NO",
    then: Yup.string().required(t("error.required")),
  });

  const InsuranceSchema = Yup.object().shape({
    insuranceProvider: Yup.string().required(t("error.required")),
    subscriberId: Yup.string().required(t("error.required")),
    subscriberSuffix: suffixRequired ? Yup.string().required(t("error.required")) : Yup.string(),
    isPrimarySubscriber: Yup.string(),
    primarySubscriberFirstName: isPrimarySubscriberYup,
    primarySubscriberLastName: isPrimarySubscriberYup,
    month: isPrimarySubscriberYup,
    day: isPrimarySubscriberYup,
    year: isPrimarySubscriberYup,
    relationship: isPrimarySubscriberYup,
  });

  useEffect(() => {
    const initForm = () => {
      const _formValues = initialValues;
      const subscription = data?.subscription;
      if (subscription) {
        if (subscription.healthPlan && subscription.healthPlan.name) {
          _formValues.insuranceProvider = subscription.healthPlan.name;
          setSuffixRequired(subscription.healthPlan.usesSuffix);
        }
        if (subscription.subscriberId) {
          _formValues.subscriberId = subscription.subscriberId;
        }
        if (subscription.subscriberSuffix) {
          _formValues.subscriberSuffix = subscription.subscriberSuffix;
        }
        if (subscription.relationshipToSubscriberCode) {
          _formValues.relationship = subscription.relationshipToSubscriberCode.displayName;
          if (subscription.relationshipToSubscriberCode.displayName === "SUBSCRIBER") {
            _formValues.isPrimarySubscriber = "YES";
          } else {
            _formValues.isPrimarySubscriber = "NO";
            _formValues.primarySubscriberFirstName = subscription.primarySubscriberFirstName;
            _formValues.primarySubscriberLastName = subscription.primarySubscriberLastName;
            const dob = subscription.primarySubscriberDateOfBirth;
            const month = dob.getUTCMonth();
            const day = dob.getUTCDate();
            const year = dob.getUTCFullYear();
            _formValues.month = month.toString();
            _formValues.day = day.toString();
            _formValues.year = year.toString();
          }
        }
      }
      setFormValues(_formValues);
    };
    if (data) {
      // Note: Timeout here doesn't appear to be working properly like on other telehealth screens
      initForm();
    }
  }, [data]);

  const handlePlanNotListed = async () => {
    if (consumer && sdk) {
      await sdk.consumerService.removeInsuranceSubscription(consumer);
      handleSubmit(undefined, PrevisitForms.VISIT_COST);
    }
  };

  const submitForm = async (
    values: InsuranceDetailProps,
    actions: FormikHelpers<InsuranceDetailProps>,
  ) => {
    try {
      if (sdk && consumer && data) {
        const insurance = sdk.consumerService.newVisitSubscriptionUpdate();
        insurance.subscriberId = values.subscriberId;
        insurance.subscriberSuffix = values.subscriberSuffix ?? "";

        const healthPlan = data.healthPlans.find(
          (healthPlan) => healthPlan.name === values.insuranceProvider,
        );
        if (healthPlan) {
          insurance.healthPlan = healthPlan;
          setSuffixRequired(insurance.healthPlan.usesSuffix);
        }

        //if there is no primary subscriber set form values
        if (values.isPrimarySubscriber === "NO" && values.relationship) {
          const relationShip = data.relationships.find(
            (relationShip) => relationShip.displayName === values.relationship,
          );

          if (relationShip) insurance.relationshipToSubscriberCode = relationShip;
          if (values.primarySubscriberFirstName)
            insurance.primarySubscriberFirstName = values.primarySubscriberFirstName;
          if (values.primarySubscriberLastName)
            insurance.primarySubscriberLastName = values.primarySubscriberLastName;
          if (values.year && values.month && values.day) {
            insurance.primarySubscriberDateOfBirth = new Date(
              parseInt(values.year),
              parseInt(values.month),
              parseInt(values.day),
            );
          }
        } else {
          const relationShip = data.relationships.find(
            (relationShip) => relationShip.displayName === "SUBSCRIBER",
          );
          insurance.primarySubscriberFirstName = consumer.firstName;
          insurance.primarySubscriberLastName = consumer.lastName;
          insurance.primarySubscriberDateOfBirth = consumer.dob;
          if (relationShip) insurance.relationshipToSubscriberCode = relationShip;
        }

        await sdk.consumerService.updateInsuranceSubscription(consumer, insurance);

        if (visitContext) {
          const _visit = await sdk.visitService.createOrUpdateVisit(visitContext);
          const _visitCost = await sdk.visitService.waitForVisitCostCalculationToFinish(_visit);
          setVisitCost(_visitCost.cost);
          if (
            _visitCost.cost.eligibilityRequestStatus ===
              awsdk.AWSDKEligibilityRequestStatus.FAILED ||
            _visitCost.cost.eligibilityRequestStatus ===
              awsdk.AWSDKEligibilityRequestStatus.REQUEST_VALIDATION_RESPONSE
          ) {
            setEligibilityFailed(true);
          } else {
            handleSubmit(values);
          }
        }
      }
    } catch (e: any) {
      if (e["__errorCode"] === "inaccurateSubscriberInfo") {
        setEligibilityFailed(true);
      }
      handleError(e, actions, values);
    }
  };

  // the built-in isValid Formik variable doesn't appear to be entirely
  // working when the form is initialized with values
  const isDisabled = (values: InsuranceDetailProps) => {
    if (values.isPrimarySubscriber === "YES") {
      if (
        !values.insuranceProvider ||
        !values.subscriberId ||
        (!values.subscriberSuffix && suffixRequired)
      ) {
        return true;
      }
    } else if (values.isPrimarySubscriber === "NO") {
      if (
        !values.insuranceProvider ||
        !values.subscriberId ||
        (!values.subscriberSuffix && suffixRequired) ||
        !values.primarySubscriberFirstName ||
        !values.primarySubscriberLastName ||
        !values.month ||
        !values.day ||
        !values.year ||
        !values.relationship
      ) {
        return true;
      }
    }
    return false;
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={formValues}
      validationSchema={InsuranceSchema}
      onSubmit={submitForm}
    >
      {({ values, isSubmitting }) => (
        <Form>
          <FormContent css={layout.margin("gutter", "skip")}>
            {outage && (
              <InlineMessage
                css={layout.margin("skip", "skip", "gutter", "skip")}
                icon={<AlertCircle />}
                variant="primary"
              >
                <Text variant="body2">{outage.message}</Text>
              </InlineMessage>
            )}
            <Title>{t("insuranceDetail.title")}</Title>
            <Text variant="body1" color="textSecondary">
              {t("insuranceDetail.detail")}
            </Text>
            <PickerField
              css={layout.flexVertical}
              name="insuranceProvider"
              label={t("insuranceDetail.insuranceProvider")}
              onChange={({ value }) => {
                const healthPlan = data?.healthPlans?.find((plan) => plan.name === value);
                setSuffixRequired(!!healthPlan?.usesSuffix);
              }}
              options={
                data?.healthPlans?.map((healthPlan: awsdk.AWSDKHealthPlan) => {
                  return { label: healthPlan.name, value: healthPlan.name };
                }) || []
              }
            />
            <FormContent.InnerFields>
              <TextField label={t("insuranceDetail.subscriberId")} name="subscriberId" />
              <TextField label={t("insuranceDetail.suffix")} name="subscriberSuffix" />
            </FormContent.InnerFields>
            <RadioGroupField
              name="isPrimarySubscriber"
              label={t("insuranceDetail.primarySubscriber")}
              inputWrapperVariant="bold-label"
              css={[{ marginTop: theme.spacing.expanded }]}
            >
              <div
                css={[
                  layout.flexLTR,
                  layout.spacedChildrenHorizontal(),
                  { marginTop: theme.spacing.standard },
                ]}
              >
                <RadioGroupField.Option value={"YES"}>
                  {({ checked }) => (
                    <Radio
                      label={
                        <RadioGroupField.Label variant="body1">
                          {t("insuranceDetail.yes")}
                        </RadioGroupField.Label>
                      }
                      checked={checked}
                      onChange={() => null}
                      labelBefore={false}
                    />
                  )}
                </RadioGroupField.Option>
                <RadioGroupField.Option value={"NO"}>
                  {({ checked }) => (
                    <Radio
                      label={
                        <RadioGroupField.Label variant="body1">
                          {t("insuranceDetail.no")}
                        </RadioGroupField.Label>
                      }
                      checked={checked}
                      onChange={() => null}
                      labelBefore={false}
                    />
                  )}
                </RadioGroupField.Option>
              </div>
            </RadioGroupField>
            {values.isPrimarySubscriber === "NO" && (
              <FormContent css={layout.margin("gutter", "skip")}>
                <Text variant="body1Bold">
                  {t("primarySubscriberDetail.primarySubscriberName")}
                </Text>
                <FormContent.InnerFields>
                  <TextField
                    label={t("primarySubscriberDetail.firstName")}
                    name="primarySubscriberFirstName"
                  />
                  <TextField
                    label={t("primarySubscriberDetail.lastName")}
                    name="primarySubscriberLastName"
                  />
                </FormContent.InnerFields>
                <Text variant="body1Bold">
                  {t("primarySubscriberDetail.primarySubscriberDateOfBirth")}
                </Text>
                <FormContent.InnerFields>
                  <PickerField
                    css={layout.flexVertical}
                    name="month"
                    label={t("primarySubscriberDetail.month")}
                    options={month}
                  />
                  <TextField
                    css={layout.margin("skip", "skip")}
                    label={t("primarySubscriberDetail.day")}
                    name="day"
                  />
                  <TextField
                    css={layout.margin("skip", "skip")}
                    label={t("primarySubscriberDetail.year")}
                    name="year"
                  />
                </FormContent.InnerFields>
                <PickerField
                  css={layout.flexVertical}
                  name="relationship"
                  label={t("primarySubscriberDetail.relationship")}
                  options={
                    data?.relationships
                      ?.filter((relationship) => relationship.displayName !== "SUBSCRIBER")
                      ?.map((relationship) => {
                        return {
                          label: relationship.localizedDisplayName,
                          value: relationship.displayName,
                        };
                      }) || []
                  }
                />
              </FormContent>
            )}

            <Button
              css={[layout.flexItemAuto, { marginTop: theme.spacing.expanded }]}
              fullWidth="percent"
              isDisabled={isDisabled(values)}
              isLoading={isSubmitting}
              type="submit"
              variant="primary"
            >
              {t("primarySubscriberDetail.continue")}
            </Button>
            <Button
              css={layout.flexItemAuto}
              fullWidth="percent"
              isLoading={isSubmitting}
              onClick={handlePlanNotListed}
              type="button"
              variant="secondary"
            >
              {t("primarySubscriberDetail.planNotListed")}
            </Button>
          </FormContent>
          <EligibilityFailedModal
            isOpen={eligibilityFailed}
            close={() => setEligibilityFailed(false)}
            insurance={values}
            onEditInsurance={() => setEligibilityFailed(false)}
            onContinue={() => {
              setEligibilityFailed(false);
              handleSubmit(values);
            }}
            visitCost={visitCost?.expectedConsumerCopayCost || 79}
          />
        </Form>
      )}
    </Formik>
  );
};
export default InsuranceDetail;
