import { format } from "date-fns";

import { ProviderAvailabilitiesListFragment_availabilities_pages_timeSlots as TimeSlot } from "/apollo/schema/types";

/**
 * Time slots have 'date', 'startTime', and 'endTime' string properties, which are all relative to
 * provider's timezone. This function is a light wrapper around `date-fns`'s `format` function,
 * so you can apply some basic formatting to those dates. Since these properties are agnostic of a
 * timezone, do not attempt to use this function with format strings that return timezone information.
 * @param timeSlot
 * @param formatString
 * @param timeSlotTime Either 'startTime' or 'endTime'. Defaults to 'startTime'
 */
const formatTimeSlotDateTime = (
  timeSlot: TimeSlot,
  formatString: string,
  timeSlotTime?: "startTime" | "endTime",
) => {
  if (formatString.match(/.*Z./)) {
    throw new Error("formatTimeSlotStart does not support time zone formatting");
  }
  // we build a date from 'date' and 'startTime'. In this case, a time zone is not provided
  // the datestring, so Date fills the gap and applies the local timezone, which is exactly
  // what we need for formatting purposes.
  const date = new Date(`${timeSlot.date}T${timeSlot[timeSlotTime ?? "startTime"]}`);

  return format(date, formatString);
};

export default formatTimeSlotDateTime;
