import { gql, MutationHookOptions } from "@apollo/client";

import { useB2CMutation } from "/b2c/client";
import { useToastContext } from "/component/provider/ToastProvider";
import useMounted from "/hook/useMounted";
import useTranslation from "/hook/useTranslation";

import { GenerateOnlineCheckInUrl, GenerateOnlineCheckInUrlVariables } from "../schema";

const GENERATE_ONLINE_CHECK_IN_URL = gql`
  mutation GenerateOnlineCheckInUrl($appointmentId: Int!, $departmentId: Int!) {
    appointment {
      generateOnlineCheckInUrl(appointmentId: $appointmentId, departmentId: $departmentId) {
        url
      }
    }
  }
`;

export const useGenerateOnlineCheckInUrl = (
  options: MutationHookOptions<GenerateOnlineCheckInUrl, GenerateOnlineCheckInUrlVariables>,
) => {
  const { t } = useTranslation("hook-useEpionPrevisit");
  const { showToast } = useToastContext();
  const mountedRef = useMounted();

  const mutationOptions = {
    onCompleted: (data: GenerateOnlineCheckInUrl) => {
      if (!mountedRef.current) return;

      const url = data?.appointment?.generateOnlineCheckInUrl?.url;
      if (typeof url === "string" && url.length > 0) {
        window.open(url, "_blank");
      } else {
        showToast({
          message: t("previsitSessionError"),
        });
      }
    },
    onError: () => {
      showToast({
        message: t("previsitSessionError"),
        type: "error",
      });
    },
    ...options,
  };

  return useB2CMutation<GenerateOnlineCheckInUrl, GenerateOnlineCheckInUrlVariables>(
    GENERATE_ONLINE_CHECK_IN_URL,
    mutationOptions,
  );
};
