import { Form, Formik } from "formik";
import { times } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Button, Text } from "/component/base";
import { CrossFilled } from "/component/base/Icon";
import FormContent from "/component/partial/FormContent";
import TextField from "/component/partial/formik/TextField";
import {
  InviteGuestsProps,
  useAmwellPrevisitContext,
} from "/component/provider/AmwellPrevisitProvider";
import { layout } from "/styles";

import { EmailContainer, Title } from "../Telehealth.styles";
import { TelehealthFormProps } from "../Telehealth.types";

const MAX_GUEST_COUNT = 4;

const InviteGuests = ({
  initialValues,
  handleSubmit,
  handleError,
}: TelehealthFormProps<InviteGuestsProps>) => {
  const { t } = useTranslation("form-telehealth-previsit");
  const [emailCount, setEmailCount] = useState(0);
  const { setVisit, visit } = useAmwellPrevisitContext();

  const inviteGuestsSchema = Yup.object({
    emails: Yup.array(Yup.string().email(t("error.emailInvalid"))).max(MAX_GUEST_COUNT),
  });

  const handleAddEmail = () => {
    setEmailCount(emailCount + 1);
  };

  const removeEmail = () => {
    setEmailCount(emailCount - 1);
  };

  const submitForm = (values: InviteGuestsProps) => {
    if (emailCount === 0) {
      handleAddEmail();
    } else {
      try {
        if (visit && values.emails && values.emails.length > 0) {
          visit.guestInvitationEmails = values.emails;
          setVisit(visit);
        }
        handleSubmit(values);
      } catch (e: any) {
        handleError(e);
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      validationSchema={inviteGuestsSchema}
    >
      {({ isValid, values, setFieldValue }) => (
        <Form>
          <FormContent css={layout.margin("gutter", "skip")}>
            <Title>{t("inviteGuests.title")}</Title>
            <Text variant="body1" color="textPrimary">
              {t("inviteGuests.guestLabel")}
            </Text>
            <EmailContainer>
              {times(emailCount).map((emailId) => (
                <TextField
                  key={emailId}
                  name={`emails[${emailId}]`}
                  placeholder={t("inviteGuests.addGuestPlaceholder")}
                  after={
                    <button
                      type="button"
                      onClick={() => {
                        if (values?.emails) {
                          const newEmails = [
                            ...values.emails.slice(0, emailId),
                            ...values.emails.slice(emailId + 1),
                          ];
                          setFieldValue("emails", newEmails);
                          removeEmail();
                        }
                      }}
                    >
                      <CrossFilled color="brandActionBlue" />
                    </button>
                  }
                />
              ))}
            </EmailContainer>

            {emailCount > 0 && emailCount < MAX_GUEST_COUNT && (
              <div>
                <Button type="button" onClick={handleAddEmail} variant="borderBottom">
                  {t("inviteGuests.addAnotherGuest")}
                </Button>
              </div>
            )}
            <FormContent.ButtonGroup>
              <Button
                variant="primary"
                isDisabled={!isValid}
                onClick={() => {
                  if (emailCount > 0) submitForm(values);
                  else handleAddEmail();
                }}
                type="button"
              >
                {emailCount > 0 ? t("continue") : t("inviteGuests.addGuest")}
              </Button>
              {emailCount === 0 && (
                <Button
                  variant="secondary"
                  isDisabled={!isValid}
                  onClick={() => handleSubmit({ emails: [] })}
                  type="button"
                >
                  {t("notNow")}
                </Button>
              )}
            </FormContent.ButtonGroup>
          </FormContent>
        </Form>
      )}
    </Formik>
  );
};

export default InviteGuests;
