import { Form, Formik, useField } from "formik";
import React, { useEffect } from "react";

import { Button, Modal } from "/component/base";
import DatePickerField from "/component/partial/formik/DatePickerField";
import { useTranslation } from "/hook";
import { date } from "/util";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  FilterParams,
  logEvent,
  ScreenViewParams,
} from "/util/firebase.util";

import { Inputs } from "./DateRangeModal.styles";
import { FieldProps, Props, Range } from "./DateRangeModal.types";

const Fields = ({ startLabel, endLabel, startHasError }: FieldProps) => {
  // Grab the values and update functions from formik so that we can manually update the values if
  // we need to.
  const [{ value: startValue }] = useField("start");
  const [{ value: endValue }] = useField("end");

  return (
    <Inputs>
      <DatePickerField
        maxDate={endValue}
        name="start"
        label={startLabel}
        inputWrapperVariant="bold-label"
      />
      <DatePickerField
        disabled={startHasError}
        minDate={startValue}
        name="end"
        label={endLabel}
        inputWrapperVariant="bold-label"
      />
    </Inputs>
  );
};

/**
 * The DateRangeModal renders two DatePickers that represent a start and end date. State is stored
 * via formik and returned to the caller when the `Filter` button is hit.
 */
const DateRangeModal = ({ className, close, isOpen, range, onSubmit }: Props) => {
  const { t } = useTranslation("modal-dateRangeModal");

  const handleSubmit = (values: Range) => {
    logEvent(AnalyticsEvent.FILTER, {
      user_flow: AnalyticsUserFlow.APPOINTMENTS,
      source: AnalyticsSource.APPOINTMENT_FILTER,
      start_date: date.formatDate(values.start ?? "", "MM/dd/yyyy"),
      end_date: date.formatDate(values.end ?? "", "MM/dd/yyyy"),
      filter_type: "appointment",
    } as FilterParams);

    values.start && values.start.setHours(0, 0, 0, 0);
    values.end && values.end.setHours(23, 59, 59, 999);
    onSubmit(values);
    close && close();
  };

  useEffect(() => {
    if (isOpen) {
      logEvent(AnalyticsEvent.SCREEN_VIEW, {
        screenName: AnalyticsSource.APPOINTMENT_FILTER,
      } as ScreenViewParams);
    }
  }, [isOpen]);

  const initialValues = {
    start: range.start,
    end: range.end,
  };
  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ resetForm, errors, values, isValid }) => (
        <Modal
          className={className}
          footer={
            <Modal.Footer>
              <Button
                variant="borderBottom"
                onClick={() => {
                  onSubmit({ start: undefined, end: undefined });
                  resetForm();
                  close && close();
                }}
              >
                {t("clear")}
              </Button>
              <Button
                type="submit"
                form="date_range_modal_form"
                disabled={!isValid || !values.start || !values.end}
              >
                {t("filter")}
              </Button>
            </Modal.Footer>
          }
          header={<Modal.Header close={close} title={t("title")} />}
          close={close}
          isOpen={isOpen}
        >
          <Form id="date_range_modal_form">
            <Fields
              startHasError={!!errors.start}
              startLabel={t("startLabel")}
              endLabel={t("endLabel")}
            />
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
export default DateRangeModal;
