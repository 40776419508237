import { Countly as CountlyTools, CountlyEvent, isStorageAvailable } from "@village/tools";

import { getUtmParams } from "/util/url";

import { CountlyKey, CountlyRemoteConfigPayload, CountlySegments, RemoteConfig } from "./types";

const isIntergationTest = (): boolean => {
  // This should be implemented when we add Integration testing like Cypress or Playwright
  // return !!window.Cypress;
  return false;
};
const getCountlyParamsForIntegrationTesting = () => {
  let params = {};

  if (isIntergationTest()) {
    params = {
      // send events immediately (the next run cycle)
      interval: 0,
      // setting max_events = 1 so that countly sends all events using GET method in xhr
      max_events: 1,
    };
  }
  return params;
};

const countlyOnBeforeSend = (
  event: CountlyEvent<CountlyKey, CountlySegments>,
): CountlyEvent<string, CountlySegments> => {
  return {
    ...event,
    key: event.key, // We decided to not use prefixes
    segmentation: {
      ...event.segmentation,
      environment: process.env.NODE_ENV,
      pathname: window.location.pathname,
      origin: window.location.origin,
      appBrand: "scmd", // For all Summit & CityMD appBrand will be the same "scmd"
      platform: "web", // On Web is always "web"
      app_version: process.env.GITHUB_COMMIT_HASH,
      ...getUtmParams(),
    },
  };
};

const Countly = CountlyTools.setup<
  CountlyKey,
  CountlySegments,
  CountlyRemoteConfigPayload & Record<string, unknown>
>({
  beforeSend: countlyOnBeforeSend,
});

const initCountly = (): void => {
  Countly.init({
    app_key: process.env.COUNTLY_APP_KEY || "",
    namespace: "summit_web",
    url: process.env.COUNTLY_BASE_URL || "",
    // Enable offline mode by default
    // Disabled on IntegrationTest for testing purpose
    offline_mode: !isIntergationTest(),
    storage: isStorageAvailable(window.localStorage) ? "localstorage" : "none",
    remote_config: true, // Let's load all remote config at the init load
    ...getCountlyParamsForIntegrationTesting(),
  });
};

type UserDetails = {
  id?: string;
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  birthdate?: string | number | null;
  email: string;
};

const setCountlyUserDetails = (userData: UserDetails): void => {
  const { firstName, lastName, birthdate, email, id, phoneNumber } = userData;

  // Disable offline mode and set User.ID or User.email as device_id for countly
  if (id || email) {
    Countly.disableOfflineMode(id ?? email);
  }

  // Reference: https://support.count.ly/hc/en-us/articles/360037441932-Web-analytics-JavaScript-
  Countly.setUserDetails({
    name: firstName && lastName ? `${firstName} ${lastName}` : undefined,
    email,
    phone: phoneNumber ? phoneNumber : undefined,
    byear: birthdate ? new Date(birthdate).getFullYear() : undefined, // birth year,
    custom: {},
  });
};

// Wrapper just to enforce types
const getRemoteConfig = (): RemoteConfig => {
  return Countly.getRemoteConfig();
};

export { Countly, initCountly, setCountlyUserDetails, getRemoteConfig };
