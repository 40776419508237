import { useEffect, useState } from "react";

/**
 * useDebounce — This hook allows you to debounce any fast changing value. It
 * returns the debounced value, which will only reflect the latest value when
 * the useDebounce hook has not been called for the amount of time specified
 * by the delay.
 */
export const useDebounce = <State extends unknown>(value: State | (() => State), delay = 500) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export default useDebounce;
