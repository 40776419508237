/* eslint-disable @typescript-eslint/no-explicit-any */
import { Popover } from "@headlessui/react";
import styled from "styled-components";

import { card, layout, transition } from "/styles";

export const Panel = styled(Popover.Panel)<any>(
  card.base,
  layout.padding("gutter", "standard"),
  ({ $animateFromBottom }) => transition.accordian($animateFromBottom),
  {
    maxWidth: "fit-content",
  },
);

// `any` is needed to keep typescript happy with Popover.Button
export const PopoverButton = styled(Popover.Button)<any>({
  width: "100%",
  outline: "none",
});
