// Modules
import styled from "styled-components";

import { layout } from "/styles";

const size = 56;

export const Base = styled.time(
  ({ theme }) => ({
    backgroundColor: theme.colors.brandPrimaryLight,
    borderRadius: 16,
    flexShrink: 0,
    height: size,
    width: size,
  }),
  layout.flexVerticalCenter,
);
