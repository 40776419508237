import { gql, useQuery } from "@apollo/client";

import { GetDocuments, GetDocumentsVariables } from "/apollo/schema/types";

const GET_DOCUMENTS = gql`
  query GetDocuments($startDate: DateTime, $endDate: DateTime) {
    viewer {
      user {
        patient {
          documents(startDate: $startDate, endDate: $endDate) {
            documentId
            encounterId
            ehrApptId
            title
            description
            type
            date
            appointment {
              id
              isVirtual
              startDateTime
              endDateTime
              provider {
                name {
                  firstName
                  lastName
                }
                nameWithDesignations
                primarySpecialty
              }
              location {
                id
                facilityNameShort
              }
            }
            providerName
            documentNumber
            enteredAtCode
            isRead
          }
        }
      }
    }
  }
`;

// useQuery is fully typed and return value is inferred.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useQueryDocuments = (variables: GetDocumentsVariables) =>
  useQuery<GetDocuments, GetDocumentsVariables>(GET_DOCUMENTS, {
    variables,
  });
