import { Listbox } from "@headlessui/react";
import React, { forwardRef, Fragment } from "react";
import { createPortal } from "react-dom";

import DropdownChevron from "/component/partial/DropdownChevron";
import { useModalContext } from "/component/provider/ModalProvider";
import Transition from "/component/util/Transition";
import usePopover from "/hook/usePopover";
import { layout } from "/styles";

import {
  AfterWrapper,
  BeforeWrapper,
  LabelText,
  ListboxButton,
  ListItem,
  ListWrapper,
  PickerText,
  Radio,
  Title,
} from "./Picker.styles";
import { PickerType } from "./Picker.types";

const Picker: PickerType = forwardRef(
  (
    {
      after,
      before,
      className,
      disabled,
      error,
      onChange,
      options,
      placeholder,
      portalTarget,
      title,
      value,
      variant,
      placement = "bottom-start",
      hideRadio = false,
    },
    ref,
  ) => {
    const {
      setAnchorElement,
      setPopoverElement,
      popoverStyles,
      updatePopoverPosition,
      popoverState,
    } = usePopover({
      strategy: "fixed",
      placement,
      modifiers: [{ name: "offset", options: { offset: [0, 0] } }],
    });

    const { modalEl } = useModalContext();

    const getButtonRef = (_ref: HTMLButtonElement | null) => {
      setAnchorElement(_ref);

      // Assign forwarded ref, if present. This allows ref usage on the Picker
      // itself, (EG: `<Picker ref={someOuterRef} />`), while still allowing us
      // to use the same ref inside the component.
      if (ref) {
        if (typeof ref === "function") {
          ref(_ref);
        } else {
          ref.current = _ref;
        }
      }
    };

    return (
      <Listbox disabled={disabled} onChange={onChange} value={value}>
        {({ disabled, open }) => (
          <>
            <ListboxButton
              variant={variant}
              $error={error}
              $focused={open}
              className={className}
              disabled={disabled}
              ref={getButtonRef}
            >
              {before && <BeforeWrapper>{before}</BeforeWrapper>}
              <PickerText color={value ? "textPrimary" : "textSecondary"}>
                {value ? value.label : placeholder}
              </PickerText>
              <AfterWrapper>{after}</AfterWrapper>

              <DropdownChevron
                $open={open}
                aria-hidden
                color="objectPrimary"
                css={layout.flexItemIntrinsic}
              />
            </ListboxButton>

            {createPortal(
              <div ref={setPopoverElement} style={popoverStyles}>
                <Transition show={open} beforeEnter={updatePopoverPosition}>
                  <ListWrapper $animateFromBottom={!!popoverState?.placement.match(/^top/)}>
                    <Title variant="body1Bold">{title}</Title>

                    <Listbox.Options
                      static
                      css={[layout.padding("standard"), { overflow: "auto" }]}
                    >
                      {options.map((option, i) => (
                        <Listbox.Option
                          as={Fragment}
                          disabled={option.disabled}
                          key={i}
                          value={option}
                        >
                          {({ active, selected }) => (
                            <ListItem $active={active}>
                              <LabelText variant="body1Bold">{option.label}</LabelText>

                              {!hideRadio && <Radio aria-hidden checked={selected} readOnly />}
                            </ListItem>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </ListWrapper>
                </Transition>
              </div>,
              portalTarget || modalEl || document.body,
            )}
          </>
        )}
      </Listbox>
    );
  },
);

export default Picker;
