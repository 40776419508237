import { Dialog } from "@headlessui/react";
import React from "react";
import styled from "styled-components";

import Text, { getTextStyles } from "/component/base/Text";
import { layout, transition } from "/styles";

import { DialogProps, DialogTextProps } from "./Alert.types";

export const closeButtonSize = 40;

// ====================================
// HeadlessUI Styled Wrappers
// ====================================

// The base Dialog.Title and Dialog.Description components accept super generic props and I was
// having trouble typing our styled-component versions of them to accept (**and enforce**) text
// props -- they would accept any prop and any type without complaining.
// That's why I'm creating these compnents that just return their Dialog counterparts.

const BaseTitle = (props: DialogProps) => <Dialog.Title {...props} />;
const BaseDescription = (props: DialogProps) => <Dialog.Description {...props} />;

export const Description = styled(BaseDescription)<DialogTextProps>(getTextStyles);
export const Title = styled(BaseTitle)<DialogTextProps>(getTextStyles, ({ theme }) => ({
  textAlign: "center",
  paddingRight: theme.spacing.gutter,
  paddingLeft: theme.spacing.gutter,
  paddingBottom: theme.spacing.condensed,
}));

// ====================================
// Base Alert Styles
// ====================================

// The Base component is the wrapper component that will be rendered in a Portal by HeadlessUI.
// It contains the overlay as well as the actual visual modal itself.
export const Base = styled(Dialog)<any>( // eslint-disable-line @typescript-eslint/no-explicit-any
  layout.absoluteFill,
  ({ theme }) => ({
    position: "fixed",
    zIndex: theme.zIndex.alert,
  }),
  layout.flexCenter,
);

export const Container = styled.div(
  ({ theme }) => ({
    paddingTop: theme.spacing.expanded,
    backgroundColor: theme.colors.backgroundPrimary,
    position: "absolute",
    overflow: "hidden",
    width: theme.sizes.modalWidth.small,
    // Max height will be the view port height with 80px of vertical margin outside the alert
    maxHeight: `calc(100vh - ${theme.spacing.base * 40}px)`,
    boxShadow: theme.shadows.base,
    borderRadius: 24,
    bottom: "unset",
  }),
  layout.flexVertical,
  transition.modalAppear,
);

export const Body = styled(Text).attrs({ variant: "body1", color: "textSecondary" })(
  ({ theme }) => ({
    overflow: "auto",
    textAlign: "center",
    padding: `0 ${theme.spacing.gutter}px ${theme.spacing.expanded}px`,
  }),
);

export const Overlay = styled(Dialog.Overlay)(
  ({ theme }) => ({
    backgroundColor: theme.colors.modalOverlay,
  }),
  layout.absoluteFill,
  transition.fade,
);

// ====================================
// Footer Styles
// ====================================

export const Footer = styled.div(
  layout.flexVertical,
  layout.spacedChildrenVertical(),
  ({ theme }) => ({
    padding: `${theme.spacing.standard}px ${theme.spacing.gutter}px ${theme.spacing.gutter}px`,
  }),
);

export const IconContainer = styled.div(({ theme }) => ({
  margin: "auto",
  marginBottom: theme.spacing.base,
}));
