import awsdk from "awsdk";
import React, { useEffect, useState } from "react";

import Button from "/component/base/Button";
import CheckBox from "/component/base/CheckBox";
import Divider from "/component/base/Divider";
import Modal from "/component/base/Modal";
import { Label } from "/component/base/Text";
import SegmentedControl from "/component/partial/SegmentedControl";
import { useAmwell, useTranslation } from "/hook";
import { layout } from "/styles";

import { Gender } from "../../page/Telehealth/TelehealthIndex.types";
import { Title } from "./TelehealthFilterModal.styles";
import { Props } from "./TelehealthFilterModal.types";

const GENDER_OPTIONS = [
  {
    label: "Any",
    value: undefined,
  },
  {
    label: "Female",
    value: "FEMALE",
  },
  {
    label: "Male",
    value: "MALE",
  },
];

const TelehealthFilterModal = ({ filter, isOpen, close, setFilter }: Props) => {
  const { t } = useTranslation("telehealth");
  const { sdk, isReady } = useAmwell();

  const [languages, setLanguages] = useState<awsdk.AWSDKLanguage[]>([]);
  const [language, setLanguage] = useState(filter.language);

  const [gender, setGender] = useState<Gender>(filter.gender);
  const [showAll, setShowAll] = useState(false);

  const retrieveSuppportedLanguages = async () => {
    if (sdk) {
      let supportedLanguages = await sdk.getLanguages();
      supportedLanguages = supportedLanguages.sort((a, b) => a.value.localeCompare(b.value));
      setLanguages(supportedLanguages);
    }
  };

  useEffect(() => {
    retrieveSuppportedLanguages();
  }, [isReady]);

  const handleDone = () => {
    setFilter({
      language,
      gender,
    });
    close();
  };

  const handleClear = () => {
    setLanguage(undefined);
    setGender(undefined);
    setFilter({ gender: undefined, language: undefined });
    close();
  };

  return (
    <Modal
      key="TelehealthFilterModal"
      isOpen={isOpen}
      close={close}
      header={<Modal.Header title={t("filter")} close={close} />}
      footer={
        <Modal.Footer>
          <Button variant="borderBottom" onClick={handleClear}>
            {t("filterModal.clear")}
          </Button>
          <Button onClick={handleDone}>{t("filterModal.done")}</Button>
        </Modal.Footer>
      }
    >
      <Title variant="title3" element="div">
        {t("filterModal.providerGender")}
      </Title>
      <SegmentedControl
        title={t("filterModal.providerGenderButton")}
        onItemSelected={(value) => setGender(value as Gender)}
        value={gender}
        options={GENDER_OPTIONS}
      />
      <Divider css={layout.margin("expanded", "skip")} />
      <Title variant="title3" element="div">
        {t("filterModal.language")}
      </Title>
      {(showAll ? languages : languages.slice(0, 3)).map((option, idx) => {
        return (
          <CheckBox
            key={idx}
            css={layout.margin("none", "none", "expanded")}
            onChange={() => setLanguage(option.value === language ? undefined : option.value)}
            labelBefore
            label={<Label variant="body1Bold">{option.value}</Label>}
            checked={option.value === language}
          />
        );
      })}

      <Button variant="borderBottom" onClick={() => setShowAll(!showAll)}>
        {showAll ? t("filterModal.hideSome") : t("filterModal.showMore")}
      </Button>
    </Modal>
  );
};

export default TelehealthFilterModal;
