import React from "react";

import Text from "../Text";
import { Arrow, Base, closeButtonSize, CloseIcon, CloseWrapper } from "./Tooltip.styles";
import { TooltipProps } from "./Tooltip.types";

/**
 * The Tooltip component represents the **visual tooltip**. It should be rendered inside of a TooltipController.
 */
const Tooltip = ({
  className,
  variant = "primary",
  children,
  arrow,
  hideClose,
  isSmall,
  placement,
}: TooltipProps) => {
  let child = children;

  // Allow us to easily and consistently render a plain text child
  if (typeof children === "string") {
    child = (
      <Text color="inherit" variant="body2" aria-live="polite">
        {children}
      </Text>
    );
  }

  return (
    <Base className={className} variant={variant} hasCloseButton={!hideClose}>
      {child}
      <Arrow
        ref={arrow.setRef}
        style={arrow.styles}
        aria-hidden
        isSmall={isSmall}
        variant={variant}
        placement={placement}
      />
      {!hideClose && (
        <CloseWrapper>
          <CloseIcon variant={variant} size={closeButtonSize} />
        </CloseWrapper>
      )}
    </Base>
  );
};

export default Tooltip;
