import React from "react";

import { CrossFilled } from "/component/base/Icon";
import Text from "/component/base/Text";
import { useTranslation } from "/hook";
import { layout } from "/styles";
import theme from "/theme/default";

import { SelectedItem, SelectedItemsContainer } from "./SelectedItems.styles";
import { Props } from "./SelectedItems.types";

const SelectedItemsList = <T,>({ items = [], onRemove, className, icon }: Props<T>) => {
  const { t } = useTranslation("selectedItemsList");
  return (
    <SelectedItemsContainer className={className}>
      {items.map((item, idx) => (
        <li key={idx}>
          <SelectedItem $hasError={!!item.error}>
            {icon && icon}
            <Text css={layout.flexItemAuto} variant="body1" truncate>
              {item.label}
            </Text>

            {onRemove && (
              <button
                type="button"
                aria-label={t("ariaLabel", { name: item.label })}
                onClick={() => onRemove(item.value)}
              >
                <CrossFilled css={{ display: "block", color: theme.colors.brandActionBlue }} />
              </button>
            )}
          </SelectedItem>
          {!!item.error && (
            <Text
              css={layout.margin("standard", "skip")}
              color="statusNegative"
              element="p"
              variant="body2"
            >
              {item.error}
            </Text>
          )}
        </li>
      ))}
    </SelectedItemsContainer>
  );
};

export default SelectedItemsList;
