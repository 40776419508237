import styled from "styled-components";

import Text from "/component/base/Text";
import { Spacings } from "/theme/default";

import { WrapperVariants } from "./InputWrapper.types";

const wrapperVariantSpacings: Record<WrapperVariants, Spacings> = {
  primary: "condensed",
  "bold-label": "condensed",
  "large-label": "standard",
  "expanded-label": "expanded",
};

export const Header = styled.div<{ variant: WrapperVariants }>(({ theme, variant }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: theme.spacing[wrapperVariantSpacings[variant]],
}));

export const Error = styled(Text)(({ theme }) => ({
  marginTop: theme.spacing.standard,
}));
