// map telehealth forms to mobile screen names
export enum PrevisitForms {
  EMPTY = "",
  PATIENT_CALLBACK = "TelehealthCallbackNumber",
  INVITE_GUESTS = "TelehealthInviteGuests",
  REASON_FOR_VISIT = "TelehealthReasonForVisit",
  CONFIRM_PATIENT_INFO = "TelehealthDemographicOverview",
  DIAGNOSED_CONDITIONS = "TelehealthMedicalConditions",
  ALLERGIES = "TelehealthAllergies",
  MEDICATIONS = "TelehealthMedications",
  VITALS = "TelehealthVitals",
  ATTACHMENTS = "TelehealthAttachments",
  CONSUMER_PHARMACIES = "TelehealthPharmacy",
  PHARMACY_SELECTION = "TelehealthPharmacySearch",
  PHARMACY_ADDRESS_CONFIRMATION = "TelehealthPharmacyAddressConfirmation",
  TELEHEALTH_INSURANCE = "TelehealthInsurance",
  INSURANCE_DETAIL = "TelehealthInsuranceDetail",
  VISIT_COST = "TelehealthVisitCost",
}

export const month = [
  { label: "January", value: "0" },
  { label: "February", value: "1" },
  { label: "March", value: "2" },
  { label: "April", value: "3" },
  { label: "May", value: "4" },
  { label: "June", value: "5" },
  { label: "July", value: "6" },
  { label: "August", value: "7" },
  { label: "September", value: "8" },
  { label: "October", value: "9" },
  { label: "November", value: "10" },
  { label: "December", value: "11" },
];

export const PrevisitStorageKey = "telehealth::previsit";
export const MinorAge = 18;
export const MaxFileSize = 1024 * 1024 * 20; //20 MB

export enum PharmacyType {
  RETAIL = "Retail",
  MAIL_ORDER = "MailOrder",
}
