import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React from "react";

import { Text } from "/component/base";
import { CaregiverNotificationForm } from "/component/form/CaregiverForms";
import MainContent from "/component/layout/MainContent";
import { getTextContentForCategory } from "/component/page/Account/page/Notifications/Notifications.util";
import { layout } from "/styles";
import { reactPlugin } from "/util/appInsights.util";

const CaregiverNotificationPreference = () => {
  const { disclaimer, title } = getTextContentForCategory("caregivers");

  return (
    <MainContent pageTitle={title} parentPage="notifications">
      {disclaimer && (
        <Text css={layout.margin("skip", "skip", "expanded")} color="textSecondary" variant="body2">
          {disclaimer}
        </Text>
      )}
      <CaregiverNotificationForm />
    </MainContent>
  );
};

export default withAITracking(
  reactPlugin,
  CaregiverNotificationPreference,
  undefined,
  "ai-tracking",
);
