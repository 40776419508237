import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router";

import background from "/asset/image/preferredPharmacy.png";
import { Loading, Text } from "/component/base";
import MainContent from "/component/layout/MainContent";
import PharmacyDetailModal from "/component/modal/PharmacyDetailModal";
import EmptyStateCta from "/component/partial/EmptyStateCta";
import PharmacyItem from "/component/partial/PharmacyItem";
import routes from "/constant/url.constant";
import { useTranslation } from "/hook";
import { useAnalytics } from "/hook/useAnalytics/useAnalytics";
import { card, layout } from "/styles";
import { reactPlugin } from "/util/appInsights.util";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  logEvent,
} from "/util/firebase.util";

import { ChangePharmacyButton } from "./PreferredPharmacy.styles";
import usePreferredPharmacy from "./usePreferredPharmacy";

const PreferredPharmacy = () => {
  const history = useHistory();
  const { t } = useTranslation("pharmacy");
  const [isOpen, setIsOpen] = useState(false);
  const { data, loading } = usePreferredPharmacy();
  const { recordAnalyticsEvent } = useAnalytics();
  const preferredPharmacyData = data?.viewer?.user?.patient?.preferredPharmacy;

  useEffect(() => {
    recordAnalyticsEvent("preferredPharmacy", { sharedModule: false });
  }, [recordAnalyticsEvent]);

  const logButtonClickEvent = (buttonName: string) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.MY_HEALTH,
      source: AnalyticsSource.PREFERRED_PHARMACY,
      button_name: buttonName,
    } as ButtonClickParams);
  };

  return (
    <MainContent parentPage="myHealth" pageTitle={t("title.preferredPharmacy")}>
      {loading && <Loading variant="page" />}
      {!loading && (
        <>
          <Helmet>
            <title>{t("title.preferredPharmacy")}</title>
          </Helmet>

          {preferredPharmacyData ? (
            <>
              <Text element="p">{t("selectedPharmacyDescription")}</Text>

              <button
                css={[
                  card.base,
                  layout.flexLTR,
                  layout.margin("expanded", "skip"),
                  layout.padding("skip", "standard"),
                  { width: "100%" },
                ]}
                onClick={() => setIsOpen(true)}
              >
                <PharmacyItem
                  address={preferredPharmacyData.address.line1}
                  name={preferredPharmacyData.name}
                />
              </button>

              <ChangePharmacyButton
                to={routes.pharmacySearch}
                onClick={() => logButtonClickEvent("Change pharmacy")}
                variant="tertiary"
              >
                {t("button.changePharmacy")}
              </ChangePharmacyButton>

              <PharmacyDetailModal
                close={() => setIsOpen(false)}
                isOpen={isOpen}
                pharmacy={preferredPharmacyData}
              />
            </>
          ) : (
            <div css={layout.flexVerticalCenter}>
              <div css={layout.marginVertical(60)}>
                <EmptyStateCta
                  ctaText={t("button.addPharmacy")}
                  imageSource={background}
                  onClickCta={() => {
                    logButtonClickEvent("Add pharmacy");
                    history.push(routes.pharmacySearch);
                  }}
                  subtitle={t("error.empty.subtitle")}
                  title={t("error.empty.title")}
                />
              </div>
            </div>
          )}
        </>
      )}
    </MainContent>
  );
};

export default withAITracking(reactPlugin, PreferredPharmacy, undefined, "ai-tracking");
