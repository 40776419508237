import { useEffect, useReducer, useState } from "react";

import { useGoogleMaps, useMounted } from "/hook";
import { reducer } from "/util";

import { Parameters, State } from "./usePlaceDetails.types";

// PlacesService cannot be created without an HTML element passed to its
// constructor fn. This element is unused for our purposes. For more info, see:
// https://developers.google.com/maps/documentation/javascript/reference/places-service#PlacesService.constructor
const div = document.createElement("div");

/**
 * usePlaceDetails — Given an Google Maps `place_id`, usually the result of a
 * place search, this hook returns a state object that contains information
 * about that place, including its address and lat/lng coordinates, using
 * response data from the GoogleMaps JS API.
 *
 * It accepts parameters as an object with keys representing a place ID string
 * and a session token ID, which is used for billing purposes. See more about
 * session tokens here:
 * https://developers.google.com/maps/documentation/places/web-service/autocomplete?hl=en#session_tokens
 **/
const usePlaceDetails = ({ placeId, sessionToken }: Parameters): State => {
  const [placesService, setPlacesService] = useState<google.maps.places.PlacesService>();
  const [state, dispatch] = useReducer(reducer.reducer, reducer.initialState);
  const googleMaps = useGoogleMaps();
  const mountedRef = useMounted();

  useEffect(() => {
    dispatch({ type: reducer.actionTypes.INIT });
  }, []);

  useEffect(() => {
    if (!mountedRef.current) {
      return;
    }

    if (!placeId) {
      dispatch({ type: reducer.actionTypes.INIT });
      return;
    }

    if (placesService) {
      dispatch({ type: reducer.actionTypes.LOADING });

      placesService.getDetails(
        {
          fields: ["address_components", "formatted_address", "geometry", "place_id"],
          placeId,
          sessionToken,
        },
        (placeDetails, status) => {
          if (mountedRef.current) {
            if (!placeDetails || status !== google.maps.places.PlacesServiceStatus.OK) {
              dispatch({ type: reducer.actionTypes.FAILURE });
            } else {
              dispatch({ payload: placeDetails, type: reducer.actionTypes.SUCCESS });
            }
          }
        },
      );
    }
  }, [placeId]);

  useEffect(() => {
    if (googleMaps && !placesService) {
      setPlacesService(new googleMaps.places.PlacesService(div));
    }
  }, [googleMaps]);

  return state as State;
};

export default usePlaceDetails;
