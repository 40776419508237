import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React from "react";
import { Helmet } from "react-helmet-async";
import { RouteComponentProps } from "react-router";

import Text from "/component/base/Text";
import MainContent from "/component/layout/MainContent";
import { useTranslation } from "/hook";
import { reactPlugin } from "/util/appInsights.util";

import useFaqQuery from "../useFaqQuery";
import { MatchParams } from "./FaqDetail.types";

const FaqDetail = ({ match }: RouteComponentProps<MatchParams>) => {
  const { t } = useTranslation("faqIndex");
  const { id } = match.params;
  const { data } = useFaqQuery();

  const { question: title, body } =
    data?.faqContent.find((content) => content.id.toString() === id) || {};

  return (
    <MainContent pageTitle={title || t("headline")} parentPage="faq">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Text variant="body1" color="textSecondary">
        {body}
      </Text>
    </MainContent>
  );
};

export default withAITracking(reactPlugin, FaqDetail, undefined, "ai-tracking");
