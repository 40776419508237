import { gql } from "@apollo/client";

export default gql`
  mutation createAmWellAuthToken($useMyAccount: Boolean) {
    createAmWellAuthToken(useMyAccount: $useMyAccount) {
      errors {
        code
        message
      }

      token
    }
  }
`;
