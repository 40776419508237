import { useReactiveVar } from "@apollo/client";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { RouteComponentProps } from "react-router-dom";

import { signUpSignInRedirect } from "/apollo/client/local";
import { Loading } from "/component/base";
import SignUpWizard from "/component/form/SignUpWizard";
import SignUpProvider from "/component/provider/SignUpProvider";
import { SignUpFormType } from "/constant/signUp.constant";
import routes from "/constant/url.constant";
import { useAuth, useTranslation } from "/hook";
import { reactPlugin } from "/util/appInsights.util";

import GeneralTutorials from "./GeneralTutorials";
import { SignUpContainer } from "./SignUp.styles";

const ENABLE_CAREGIVER_PROXY = process.env.ENABLE_CAREGIVER_PROXY === "true";

function isValidQueryFrom(value: unknown): value is SignUpFormType {
  if (typeof value !== "string") return false;
  const valid = Object.values(SignUpFormType).includes(value as SignUpFormType);
  return valid;
}

interface RedirectStateParam {
  redirectPage?: string;
}

const SignUp = ({ history, location }: RouteComponentProps) => {
  const { t } = useTranslation("sign-up");
  const { redirectPage } = (location.state as RedirectStateParam) || {};

  const signUpRedirect = useReactiveVar(signUpSignInRedirect.var);

  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const queryCode = query?.code;
  const queryForm = query?.form;
  const { loginNoMFA, isAuthenticated: isAuth, isReady } = useAuth(true);

  const [screenLoading, setScreenLoading] = useState(true);
  const [initialForm, setInitialForm] = useState<SignUpFormType>(SignUpFormType.CreateAccount);
  const [emailVerificationCode, setEmailVerificationCode] = useState("");

  const completeWizard = () => {
    if (signUpRedirect) {
      signUpSignInRedirect.update("");
      window.location.href = signUpRedirect;
      return;
    }

    ENABLE_CAREGIVER_PROXY
      ? history.push(`${routes.root}`, { promptCaregiver: true })
      : history.push(routes.root);
  };

  useEffect(() => {
    if (redirectPage) signUpSignInRedirect.update(redirectPage);
  }, [redirectPage]);

  useEffect(() => {
    if (queryCode) setEmailVerificationCode(queryCode ? String(queryCode) : "");
    if (!!queryForm && isValidQueryFrom(queryForm)) {
      setInitialForm(queryForm);
      history.replace(routes.signUp);
    }
    setScreenLoading(false);
  }, [emailVerificationCode, queryForm, queryCode]);

  return (
    <SignUpProvider
      handleError={() => {
        alert("error");
      }}
      emailVerificationCode={emailVerificationCode}
    >
      <SignUpContainer>
        <Helmet>
          <title>{t("title")}</title>
        </Helmet>
        <GeneralTutorials />

        {!isReady || screenLoading ? (
          <div css={{ position: "relative" }}>
            <Loading />
          </div>
        ) : (
          <SignUpWizard
            initialForm={initialForm}
            isAuth={isAuth}
            completeWizard={completeWizard}
            loginNoMFA={() =>
              loginNoMFA(
                `${window.location.origin}${routes.signUp}?${qs.stringify(query, {
                  addQueryPrefix: false,
                })}`,
              )
            }
          />
        )}
      </SignUpContainer>
    </SignUpProvider>
  );
};

export default withAITracking(reactPlugin, SignUp, undefined, "ai-tracking");
