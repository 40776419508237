import { add, format, isAfter, isBefore } from "date-fns";
import React, { useState } from "react";

import { GetFacility_facility_hoursOfOperation } from "/apollo/schema/types";
import { Text } from "/component/base";
import { ChevronDown, ChevronUp } from "/component/base/Icon";
import { layout } from "/styles";
import { parseDateTime } from "/util/date";

import {
  ChevronButton,
  HoursOfOperationWeeklyRowWrapper,
  HoursOfOperationWrapper,
} from "./HoursOfOperation.styles";

type Props = {
  canShowAllHours?: boolean;
  hoursOfOperation: GetFacility_facility_hoursOfOperation[] | null;
};

const HoursOfOperation: React.FC<Props> = ({ canShowAllHours = true, hoursOfOperation }) => {
  const [showAllHours, setShowAllHours] = useState(false);

  const today = new Date();
  const hoursToday = hoursOfOperation && hoursOfOperation[today.getDay()];

  const getDailyHours = (isOpen: boolean, closingDate: Date) => {
    if (isOpen) {
      return (
        <Text
          color={"textPrimary"}
          variant={canShowAllHours ? "body1" : "body2"}
        >{`| Closes ${getClosingTime(closingDate)}`}</Text>
      );
    } else {
      return (
        <Text
          color={"textPrimary"}
          variant={canShowAllHours ? "body1" : "body2"}
        >{`| Opens ${getNextOpeningTime()}`}</Text>
      );
    }
  };

  const getClosingTime = (closingDate: Date) => {
    if (closingDate.getMinutes() === 0) {
      return format(closingDate, "h aa");
    } else {
      return format(closingDate, "h:mm aa");
    }
  };

  const getNextOpeningTime = () => {
    if (!hoursOfOperation) return;

    const currentDay = today.getDay();

    const todayClosingTime = hoursOfOperation[currentDay].closingTime;
    if (todayClosingTime) {
      const todayClosingDateTime = parseDateTime(todayClosingTime);

      // default to current business day - next opening time is today's opening
      let nextDay = currentDay;
      let nextOpeningDate = today;
      // after business day - next opening time is tomorrow's opening
      if (isAfter(today, todayClosingDateTime)) {
        nextDay = (nextDay + 1) % 7;
        nextOpeningDate = add(today, { days: 1 });
      }

      const nextOpeningTimeHours = hoursOfOperation[nextDay];
      if (nextOpeningTimeHours.openingTime) {
        const hours = parseInt(nextOpeningTimeHours.openingTime?.split(":")[0], 10);
        const minutes = parseInt(nextOpeningTimeHours.openingTime?.split(":")[1], 10);

        nextOpeningDate.setHours(hours);
        nextOpeningDate.setMinutes(minutes);
        if (minutes === 0) {
          return format(nextOpeningDate, "E h aa");
        } else {
          return format(nextOpeningDate, "E h:mm aa");
        }
      }
    }

    return <></>;
  };

  const getWeeklyHours = () => {
    if (!hoursOfOperation) return;

    const rotatedHoursOfOperation = [
      ...hoursOfOperation.slice(today.getDay(), hoursOfOperation.length),
      ...hoursOfOperation.slice(0, today.getDay()),
    ];
    return (
      <div css={layout.margin("condensed", "skip", "skip", "skip")}>
        {rotatedHoursOfOperation.map((hours, index) => {
          if (!hours.openingTime || !hours.closingTime) return;

          const openingTime = format(parseDateTime(hours.openingTime), "h:mm aa");
          const closingTime = format(parseDateTime(hours.closingTime), "h:mm aa");

          return (
            <HoursOfOperationWeeklyRowWrapper>
              <Text
                color={index === 0 ? "textNavyBlue" : "textPrimary"}
                css={{ textTransform: "capitalize" }}
                variant={index === 0 ? "body1Bold" : "body1"}
              >
                {hours.dayOfWeek?.toLowerCase()}
              </Text>

              <Text
                color={index === 0 ? "textNavyBlue" : "textPrimary"}
                variant={index === 0 ? "body1Bold" : "body1"}
              >{`${openingTime} - ${closingTime}`}</Text>
            </HoursOfOperationWeeklyRowWrapper>
          );
        })}
      </div>
    );
  };

  let isOpen = false;
  if (hoursToday && hoursToday.openingTime && hoursToday.closingTime) {
    const openingDateTime = parseDateTime(hoursToday.openingTime);
    const closingDateTime = parseDateTime(hoursToday.closingTime);

    if (isAfter(today, openingDateTime) && isBefore(today, closingDateTime)) {
      isOpen = true;
    }

    return (
      <div>
        <HoursOfOperationWrapper>
          <Text
            color={isOpen ? "statusPositive" : "statusNegative"}
            css={{ marginRight: 4 }}
            variant={canShowAllHours ? "body1Bold" : "body2Bold"}
          >
            {isOpen ? "Open" : "Closed"}
          </Text>
          {!showAllHours && closingDateTime && getDailyHours(isOpen, closingDateTime)}

          {canShowAllHours && (
            <ChevronButton onClick={() => setShowAllHours(!showAllHours)} variant={"blank"}>
              {showAllHours ? (
                <ChevronUp color="textPrimary" size={16} />
              ) : (
                <ChevronDown color="textPrimary" size={16} />
              )}
            </ChevronButton>
          )}
        </HoursOfOperationWrapper>
        {showAllHours && getWeeklyHours()}
      </div>
    );
  }

  return <></>;
};

export default HoursOfOperation;
