import * as React from "react";

import { Spinner } from "/component/base";

import { CustomLoaderContainer, Subtitle, Title } from "./CustomLoader.styles";
import { Props } from "./CustomLoader.types";

const CustomLoader = ({ title, subtitle }: Props) => {
  return (
    <CustomLoaderContainer>
      <Spinner size={48} />
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </CustomLoaderContainer>
  );
};

export default CustomLoader;
