import styled from "styled-components";

import ButtonBase from "/component/base/Button";
import { card, layout } from "/styles";

export const Card = styled.div(card.base, layout.margin("standard"));

export const Button = styled(ButtonBase)(({ theme }) => ({
  marginTop: theme.spacing[40],
  [theme.media.desktop]: {
    marginTop: 80,
  },
}));
