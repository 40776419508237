import { useField } from "formik";
import React from "react";

import InputWrapper from "/component/base/InputWrapper";
import FormContent from "/component/partial/FormContent";
import TextField from "/component/partial/formik/TextField";
import { useTranslation } from "/hook";

import { Props } from "./DOBField.types";

/**
 * An input component that accepts date of birth's month, day, and year
 * in separate text inputs. This component utilizes Formik's `useField` hook
 * and is meant to be used in Formik forms. This component is meant to be
 * used in conjunciton with the DOBSchemaFactory utility to generate an appropriate
 * validation schema for a field of the name prop.
 */
const DOBField = ({ variant, className, name, inputWrapperVariant = "primary" }: Props) => {
  const { t } = useTranslation("partial-dobField");
  const dob = useField(name);
  const month = useField(`${name}.month`);
  const day = useField(`${name}.day`);
  const year = useField(`${name}.year`);

  return (
    <InputWrapper
      label={t("label.main")}
      variant={inputWrapperVariant}
      error={dob[1].error}
      touched={month[1].touched && day[1].touched && year[1].touched}
    >
      <FormContent.InnerFields className={className}>
        <TextField
          variant={variant}
          maxLength={2}
          name={`${name}.month`}
          label={t("label.month")}
          placeholder={t("placeholder.month")}
        />
        <TextField
          variant={variant}
          maxLength={2}
          name={`${name}.day`}
          label={t("label.day")}
          placeholder={t("placeholder.day")}
        />
        <TextField
          variant={variant}
          css={{ flex: 2 }}
          maxLength={4}
          name={`${name}.year`}
          label={t("label.year")}
          placeholder={t("placeholder.year")}
        />
      </FormContent.InnerFields>
    </InputWrapper>
  );
};

export default DOBField;
