import { MutationHookOptions, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import CREATE_PATIENT from "/apollo/mutation/createPatient";
import { createPatient, createPatientVariables } from "/apollo/schema/types";
import { FormValues as DemographicWizardFormValues } from "/component/form/DemographicFormWizard/DemographicFormWizard.types";
import { useToastContext } from "/component/provider/ToastProvider";
import routes from "/constant/url.constant";

const useDemographics = (options: MutationHookOptions<createPatient, createPatientVariables>) => {
  const history = useHistory();
  const { t } = useTranslation("demographics");
  const { showToast } = useToastContext();

  const [createPatient] = useMutation<createPatient, createPatientVariables>(
    CREATE_PATIENT,
    options,
  );
  const handleSubmit = async ({
    firstName,
    lastName,
    sex,
    address,
  }: DemographicWizardFormValues) => {
    const res = await createPatient({
      variables: {
        data: {
          firstName,
          lastName,
          gender: sex,
          address,
        },
      },
    });

    if (!res.data?.createPatient?.errors.length) {
      history.push(routes.root);
      showToast({
        icon: "checkmark",
        message: t("accountCreatedToast", "Account created"),
      });
    }

    return res;
  };

  return { handleSubmit };
};

export default useDemographics;
