export const sizes = {
  nav: 348,
  navSmall: 88,
  mainContent: 836,
  /**
   * `mobileHeader` is the top bar that contains the hamburger menu on mobile screen sizes
   */
  mobileHeader: 64,
  avatar: {
    xSmall: 24,
    small40: 40,
    small: 48,
    large: 64,
    xLarge: 80,
  },
  modalWidth: {
    small: 327,
    large: 712,
  },
  mobileModalMaxHeight: "80vh",
} as const;
