import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Button, Radio, Text } from "/component/base";
import RadioGroupField from "/component/partial/formik/RadioGroupField";
import { CaregiverFormType, CaregiverType } from "/constant/caregiver.constant";
import { layout } from "/styles";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  logEvent,
} from "/util/firebase.util";

import { CaregiverFormProps } from "..";
import { Form, FormContentContainer, SubTitle, Title } from "../CaregiverForms.styles";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AddCaregiver = ({ handleSubmit }: CaregiverFormProps<any>) => {
  const { t } = useTranslation("form-addCaregiver");

  const selectCargiverSchema = Yup.object().shape({
    caregiver: Yup.string().required(),
  });

  const logButtonClickEvent = (buttonName: string, buttonDescription?: string) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.CAREGIVER,
      source: AnalyticsSource.CAREGIVER_ACCESS_TYPE,
      button_name: buttonName,
      button_description: buttonDescription,
    } as ButtonClickParams);
  };

  return (
    <Formik
      initialValues={{
        caregiver: CaregiverType.ADULT,
      }}
      validationSchema={selectCargiverSchema}
      onSubmit={({ caregiver }) => {
        if (caregiver === CaregiverType.ADULT) {
          logButtonClickEvent("Continue", "adult");
          handleSubmit(CaregiverFormType.InviteCaregiver);
        } else if (caregiver === CaregiverType.CHILD) {
          logButtonClickEvent("Continue", "child");
          handleSubmit(CaregiverFormType.AddChild);
        }
      }}
    >
      {({ isSubmitting, isValid }) => (
        <Form>
          <FormContentContainer>
            <Title>{t("title")}</Title>
            <RadioGroupField css={layout.margin("gutter", "none", "none", "none")} name="caregiver">
              <div css={layout.spacedChildrenVertical("gutter")}>
                <RadioGroupField.Option value={CaregiverType.ADULT}>
                  {({ checked }) => (
                    <Radio
                      label={
                        <Text variant="body1Bold">
                          {t("adult.title")}
                          <SubTitle>{t("adult.detail")}</SubTitle>
                        </Text>
                      }
                      checked={checked}
                      onChange={() => null}
                      labelBefore={false}
                    />
                  )}
                </RadioGroupField.Option>
                <RadioGroupField.Option value={CaregiverType.CHILD}>
                  {({ checked }) => (
                    <Radio
                      label={
                        <Text variant="body1Bold">
                          {t("child.title")}
                          <SubTitle>{t("child.detail")}</SubTitle>
                        </Text>
                      }
                      checked={checked}
                      onChange={() => null}
                      labelBefore={false}
                    />
                  )}
                </RadioGroupField.Option>
              </div>
            </RadioGroupField>
            <FormContentContainer.ButtonGroup>
              <Button
                variant="primary"
                type="submit"
                isDisabled={!isValid}
                isLoading={isSubmitting}
              >
                {t("continue")}
              </Button>
            </FormContentContainer.ButtonGroup>
          </FormContentContainer>
        </Form>
      )}
    </Formik>
  );
};
export default AddCaregiver;
