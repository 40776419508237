import React, { useContext, useRef, useState } from "react";
import AnimateHeight from "react-animate-height";
import Truncate from "react-truncate";
import { ThemeContext } from "styled-components";

import { Button, Text } from "/component/base";
import { useTranslation } from "/hook";
import { layout } from "/styles";

import { ButtonText } from "./ReadMore.styles";
import { Props } from "./ReadMore.types";

/**
 * ReadMore allows you to render an arbitrarily large string and have it automatically collapse with ellipses
 * and a "Read More" button that the user can hit to expand the view to read the whole body.
 *
 * By default it will render the base `body1` text variant, but you can control that with a prop, as well
 * as the number of lines you would like to be visible before truncating.
 *
 * **Note:** If the string is short enough to fit on a single line (it is not actually truncated with "..."), the
 * Read More button will not show up.
 */
const ReadMore = ({ text, className, lines = 2, textVariant = "body1" }: Props) => {
  const { t } = useTranslation("readMore");

  // The `showAll` state determines if our container is animated to its fully opened, or truncated position.
  // We will strategically update the `truncated` state variable to ensure there is no UI jank during animations.
  const [showAll, setShowAll] = useState(false);

  // Text will be truncated when `truncated` is true.
  const [truncated, setTruncated] = useState(true);

  // We will measure the number of lines in the truncated text one time so that we can animate to the
  // correct height when the text is being truncated.
  const [numLines, setNumLines] = useState<number>(0);

  const { lineHeightsInPixels } = useContext(ThemeContext);

  // I was having a really hard time getting this ref typed correctly, so using `any` here.
  const truncateRef = useRef<any>(); // eslint-disable-line @typescript-eslint/no-explicit-any

  // If we are transitioning to showing all text (truncated -> show all) we want to immediately
  // set truncated to false so that while the animation is occuring the text is in it's full form.
  const handleToggle = () => {
    if (!showAll) {
      setTruncated(false);
    }

    setShowAll(!showAll);
  };

  // When the `handleTruncate` callback is fired we will measure the truncated text to figure out
  // how many lines Truncate is rendering. If we multiply this by the lineHeight of the Text we will
  // get the height of the container when collapsed (truncated)
  //
  // Note: if the string only contains one line, and therefore does not need to be truncated, `isTruncated`
  // will be false. This will result in `setNumLines` never being set, which defaults to showing the
  // one line and keeping the `Read More` button hidden.
  const handleTruncate = (isTruncated: boolean) => {
    if (isTruncated && !numLines && truncateRef.current) {
      setNumLines(truncateRef.current.getLines().length);
    }
  };

  // If we are hiding the text (show all -> truncated) we want to wait until the animation finishes
  // to truncate the text. If we immediately truncated we will see a white box as the animation is
  // happening since the height of the container is be greater than that of the truncated text.
  const handleAnimationEnd = () => {
    if (!showAll) {
      setTruncated(true);
    }
  };

  // Get the height of a single line of text, which we will use to calculate the height of
  // the container when the text is being truncated.
  const lineHeight = lineHeightsInPixels[textVariant];

  // By setting the height to "auto" AnimateHeight will be able to show the whole value.
  const height = showAll ? "auto" : lineHeight * (numLines || 1);

  // We need to include <br>s in order to get new lines from our text, otherwise JS will
  // squish them all together into a single blob of text.
  // This block copied from [react-truncate](https://github.com/pablosichert/react-truncate#usage)
  const textWithNewLines = text.split("\n").map((line, i, arr) => {
    const lineText = <span key={i}>{line}</span>;

    if (i === arr.length - 1) {
      return lineText;
    } else {
      return [lineText, <br key={i + "br"} />];
    }
  });

  // This serves as the aria-label for the collapsed state. The `Truncate`
  // component is aria-hidden, as the text nodes are duplicated in a way that
  // causes redundant announcements in screen readers.
  const collapsedLabel = text.split("\n")[0] || "";

  return (
    <div className={className}>
      <Text aria-expanded={!truncated} element="div" variant={textVariant}>
        <AnimateHeight height={height} onAnimationEnd={handleAnimationEnd}>
          {!truncated ? (
            textWithNewLines
          ) : (
            <p aria-label={collapsedLabel}>
              <Truncate aria-hidden lines={lines} onTruncate={handleTruncate} ref={truncateRef}>
                {textWithNewLines}
              </Truncate>
            </p>
          )}
        </AnimateHeight>
      </Text>

      {numLines > 0 && (
        <Button
          variant="unstyled"
          onClick={handleToggle}
          css={layout.margin("standard", "skip", "skip")}
          aria-label={showAll ? t("ariaLabel.expanded") : t("ariaLabel.collapsed")}
        >
          <ButtonText color={"brandPrimary"} variant="body2Bold">
            {showAll ? t("expanded") : t("collapsed")}
          </ButtonText>
        </Button>
      )}
    </div>
  );
};

export default ReadMore;
