import { screenSizes } from "./breakpoints";
import { colors } from "./colors";
import { spacing } from "./spacing";

export const TEXT_VARIANTS = [
  "body1",
  "body1Medium",
  "body1Bold",
  "body1Italic",
  "body1BoldItalic",
  "body2",
  "body2Medium",
  "body2Bold",
  "body2Italic",
  "body2BoldItalic",
  "body3",
  "body3Bold",
  "body3Italic",
  "body3BoldItalic",
  "headline",
  "navigation",
  "navigationBold",
  "title1",
  "title2",
  "title3",
  "buttonTitle",
] as const;

export type Colors = keyof typeof colors;
export type LineHeights = TextVariants;
export type ScreenSizes = keyof typeof screenSizes;
export type Spacings = keyof typeof spacing;
export type TextVariants = (typeof TEXT_VARIANTS)[number];
