import { gql, useQuery } from "@apollo/client";

import { GetFeedbackTopics } from "/apollo/schema/types";

const GET_FEEDBACK_TOPICS = gql`
  query GetFeedbackTopics {
    feedbackTopics {
      id
      title
      tag
    }
  }
`;

// useQuery is fully typed and return value is inferred.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useFeedbackTopicsQuery = () => useQuery<GetFeedbackTopics>(GET_FEEDBACK_TOPICS);
