import styled from "styled-components";

import { Spinner as BaseSpinner } from "/component/base";
import { transitionKeys } from "/constant/animation.constant";
import { layout } from "/styles";

export const Spinner = styled(BaseSpinner)(layout.margin("skip", "skip", "skip", "condensed"), {
  [`$&.${transitionKeys.start}`]: { opacity: 0 },
  [`$&.${transitionKeys.finish}`]: { opacity: 1 },
  [`$&.${transitionKeys.transition}`]: {
    transition: "opacity 350ms ease",
    transitionDelay: "300ms",
  },
});
