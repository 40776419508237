import React, { createContext, useContext, useState } from "react";

const DEFAULT_VALUE: {
  modalEl?: HTMLDivElement;
  unshiftModalEl: (el: HTMLDivElement) => void;
  removeModalEl: (el?: HTMLDivElement) => void;
} = {
  modalEl: undefined,
  unshiftModalEl: () => undefined,
  removeModalEl: () => undefined,
};

const ModalProviderContext = createContext(DEFAULT_VALUE);

export const useModalContext = () => useContext(ModalProviderContext);

/**
 * ModalProvider adds context values for setting and reading the most-recently
 * rendered modal DOM node. This is useful, as certain custom inputs may require
 * a specific portal node to render into, in order to function correctly while
 * maintaining the Modal's focus trap.
 */
const ModalProvider = (props: { children?: React.ReactNode }): JSX.Element => {
  const [modalEls, setModalEls] = useState<HTMLDivElement[]>([]);

  const unshiftModalEl = (el: HTMLDivElement) => {
    setModalEls([el, ...modalEls]);
  };

  const removeModalEl = (el?: HTMLDivElement) => {
    if (!el) {
      return;
    }

    setModalEls(modalEls.filter((modal) => modal !== el));
  };

  return (
    <ModalProviderContext.Provider
      value={{ modalEl: modalEls[0], removeModalEl, unshiftModalEl }}
      {...props}
    />
  );
};

export default ModalProvider;
