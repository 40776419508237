import styled from "styled-components";

import { Text } from "/component/base";

export const Error = styled(Text).attrs({ variant: "body1" })(({ theme }) => ({
  color: theme.colors.statusNegative,
  fontSize: theme.fontSizes.body2,
  paddingTop: theme.spacing.standard,
}));

export const SupportEmail = styled(Text).attrs({ variant: "body1" })(({ theme }) => ({
  color: theme.colors.brandPrimary,
  fontSize: theme.fontSizes.body2,
}));
