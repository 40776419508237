import React, { forwardRef } from "react";
import { useHistory } from "react-router-dom";

import routes from "/constant/url.constant";

import {
  AccessoryWrapper,
  BackButton,
  Base,
  ContentColumn,
  Header,
  Title,
} from "./MainContent.styles";
import { Props } from "./MainContent.types";

const MainContent = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      parentPage,
      pageTitle,
      headerAccessory,
      renderAccessoryBelowTitleOnMobile = false,
      backButton,
      className,
      truncateTitle = false,
    },
    ref,
  ) => {
    const history = useHistory();

    // TODO can we be a bit more graceful if the page is refreshed?
    // eg: going from /account -> /account/personal-information then *refreshing* the browser
    // makes you lose the `history.action`, so goBack() isn't called. I didn't like how it
    // felt to replace the route, I liked just pushing a new route instead
    const handleBack = () => {
      if (history.action === "PUSH") {
        history.goBack();
      } else if (parentPage) {
        history.replace(routes[parentPage]);
      }
    };

    const hasCustomBackButton = !!backButton;
    const hasBackButton = !!parentPage;
    const hasHeader = !!(pageTitle || headerAccessory);

    return (
      <Base className={className} ref={ref}>
        <ContentColumn hasBackButton={hasBackButton || hasCustomBackButton}>
          {hasBackButton && !hasCustomBackButton && <BackButton onClick={handleBack} />}
          {hasCustomBackButton && backButton}

          {!!hasHeader && (
            <Header>
              {!!pageTitle && (
                <Title
                  element={typeof pageTitle === "string" ? "h1" : "div"}
                  variant="title1"
                  truncate={truncateTitle}
                >
                  {pageTitle}
                </Title>
              )}
              {!!headerAccessory && (
                <AccessoryWrapper $renderBelowTitleOnMobile={renderAccessoryBelowTitleOnMobile}>
                  {headerAccessory}
                </AccessoryWrapper>
              )}
            </Header>
          )}
          {children}
        </ContentColumn>
      </Base>
    );
  },
);

// eslint-disable-next-line
// @ts-ignore
MainContent.BackButton = BackButton;

export default MainContent as typeof MainContent & { BackButton: typeof BackButton };
