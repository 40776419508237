import awsdk from "awsdk";
import { useField } from "formik";
import React from "react";

import { CheckBox, Divider, Text } from "/component/base";
import FileInput from "/component/base/FileInput";
import TextField from "/component/partial/formik/TextField";
import { useAmwell, useTranslation } from "/hook";
import { layout } from "/styles";

import { TextArea } from "./MessageForm.styles";

const MessageForm = () => {
  const { t } = useTranslation("composeMessage");
  const [, attachmentMeta, attachmentHelpers] = useField<awsdk.AWSDKUploadAttachment | undefined>(
    "attachment",
  );
  const [messageField, messageMeta] = useField("message");
  const [summaryField] = useField("includeSummary");
  const { sdk, isReady } = useAmwell();
  const acceptedFiletypes = sdk?.getSystemConfiguration().mimeTypeWhitelist.join(",");

  return (
    <>
      <TextField
        css={layout.padding("skip", "skip", "gutter")}
        name="subject"
        placeholder={t("placeholder.subject")}
      />

      {attachmentMeta.error && attachmentMeta.touched && (
        <Text
          color="statusNegative"
          css={[layout.flexLTR, layout.padding("skip", "skip", "condensed")]}
          id="attachment-error"
          variant="body2"
        >
          {attachmentMeta.error}
        </Text>
      )}

      <FileInput
        accept={acceptedFiletypes}
        aria-describedby={"attachment-error"}
        file={attachmentMeta.value}
        disabled={!isReady}
        onAdd={(file, { name }) => {
          if (sdk) {
            const uploadAttachment = sdk?.secureMessageService.newUploadAttachment();
            uploadAttachment.name = name;
            uploadAttachment.data = file;

            attachmentHelpers.setValue(uploadAttachment);
            attachmentHelpers.setTouched(true);
          }
        }}
        onRemove={() => {
          attachmentHelpers.setValue(undefined);
        }}
      />

      <CheckBox
        css={layout.padding("gutter", "skip", "skip")}
        label={t("label.includeHealthSummary")}
        labelBefore={false}
        {...summaryField}
      />

      <Divider css={layout.margin("gutter", "none", "standard")} />

      {messageMeta.error && messageMeta.touched && (
        <Text
          color="statusNegative"
          css={[layout.flexLTR, layout.padding("skip", "skip", "condensed")]}
          id="message-error"
          variant="body2"
        >
          {messageMeta.error}
        </Text>
      )}

      <TextArea
        aria-describedby="message-error"
        aria-label={t("message.label")}
        placeholder={t("message.placeholder")}
        {...messageField}
      />
    </>
  );
};

export default MessageForm;
