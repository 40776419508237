import React from "react";

import { Button, Divider, Text } from "/component/base";
import Modal, { ModalProps } from "/component/base/Modal";
import { InsuranceDetailProps } from "/component/provider/AmwellPrevisitProvider";
import { useTranslation } from "/hook";

import {
  ContentContainer,
  FormContentContainer,
  InfoLabelContainer,
  SectionContainer,
  Title,
} from "./EligibilityFailedModal.styles";

interface Props {
  insurance: InsuranceDetailProps;
  onEditInsurance: () => void;
  onContinue: () => void;
  visitCost: number;
}

const EligibilityFailedModal: React.FC<ModalProps & Props> = ({
  close,
  isOpen,
  insurance,
  onEditInsurance,
  onContinue,
  visitCost,
}) => {
  const { t } = useTranslation("modal-telehealthEligibilityFailed");
  const visitCostFormatted = visitCost ? visitCost.toFixed(2) : "79.00";

  return (
    <Modal
      isOpen={isOpen}
      header={<Modal.Header close={close} />}
      footer={
        <Modal.FooterWithLargeButton>
          <FormContentContainer.ButtonGroup>
            <Button variant="secondary" onClick={onEditInsurance} type="button">
              {t("editInsurance")}
            </Button>
            <Button variant="primary" onClick={onContinue} type="button">
              {t("selfPay")}
            </Button>
          </FormContentContainer.ButtonGroup>
        </Modal.FooterWithLargeButton>
      }
    >
      <ContentContainer>
        <Title>{t("title")}</Title>
        <SectionContainer>
          <Text>{t("description")}</Text>
          <Text variant="title3">{t("reviewTitle")}</Text>
          <InfoLabelContainer>
            <Text variant="body1Bold">{t("insurance")}</Text>
            <Text>{insurance.insuranceProvider}</Text>
          </InfoLabelContainer>
          <InfoLabelContainer>
            <Text variant="body1Bold">{t("subscriberId")}</Text>
            <Text>{insurance.subscriberId}</Text>
          </InfoLabelContainer>
          <InfoLabelContainer>
            <Text variant="body1Bold">{t("suffix")}</Text>
            <Text>{insurance.subscriberSuffix || "-"}</Text>
          </InfoLabelContainer>
        </SectionContainer>
        <Divider />
        <SectionContainer>
          <Text variant="title3">{t("selfPaymentTitle")}</Text>
          <Text>{t("selfPaymentDescription")}</Text>
          <Text variant="body1Bold" color="textNavyBlue">
            {t("cost", { amount: visitCostFormatted })}
          </Text>
        </SectionContainer>
      </ContentContainer>
    </Modal>
  );
};

export default EligibilityFailedModal;
