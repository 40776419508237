import { useContext } from "react";

import { AnalyticsContext } from "/component/provider/AnayticsProvider";
import { AnalyticsContextProps } from "/component/provider/AnayticsProvider/AnalyticsContext";

const useAnalytics = (): AnalyticsContextProps => {
  const context = useContext(AnalyticsContext);

  if (context === undefined) {
    throw new Error("useAnalyticsEvent must be used within a AnalyticsProvider");
  }

  return context;
};

export { useAnalytics };
