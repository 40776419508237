import styled from "styled-components";

import { layout } from "/styles";

export const ButtonContainer = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  "> *": {
    flex: 1,
  },
  marginTop: 40,
  [theme.media.max.tabletPortrait]: {
    ...layout.flexVertical,
    ...layout.spacedChildrenHorizontal("none")({ theme }),
    "& > *": {
      padding: theme.spacing[12],
      width: "100%",
      marginTop: theme.spacing.condensed,
    },
  },
}));

export const CaregiverItemsContainer = styled.div(
  layout.spacedChildrenVertical("standard"),
  layout.flexVerticalAlignStart,
  layout.padding("expanded"),
  () => ({
    borderRadius: 12,
    backgroundColor: "white",
    overflowY: "auto",
  }),
);

export const SpinnerContainer = styled.div(layout.flexVerticalCenter, layout.padding("gutter"), {
  flex: 1,
});
