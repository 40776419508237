import { gql } from "@apollo/client";

export default gql`
  mutation UpdateLinkedPhoneNumber($data: UpdateLinkedPhoneNumberInput!) {
    updateLinkedPhoneNumber(data: $data) {
      errors {
        message
      }
    }
  }
`;
