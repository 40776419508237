import { gql } from "@apollo/client";

export default gql`
  mutation createPatient($data: CreatePatientInput!) {
    createPatient(data: $data) {
      errors {
        code
        message
      }
      patient {
        id
      }
    }
  }
`;
