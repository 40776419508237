export const formatCurrency = (
  decimalValue: number,
  stripTrailingZeroes = true,
  locales: string[] = ["en-US"],
  currency = "USD",
): string => {
  const formatter = Intl.NumberFormat(locales, { style: "currency", currency });
  const formattedValue = formatter.format(decimalValue);
  const last3 = formattedValue.slice(-3);

  return stripTrailingZeroes && (last3 === ".00" || last3 === ",00")
    ? formattedValue.slice(0, -3)
    : formattedValue;
};
