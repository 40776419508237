import {
  GeoCoordinates,
  GoogleApiResponse,
  GooglePlace,
  GooglePlaceQueryInputType,
  Place,
} from "./types";

/**
 * This "find best match" algorithm has the following rules:
 * - Calculates the score for the match on address and name
 * - A full address match is +1
 *   - If the full address DOES NOT MATCH, partial address matching is +0.2 for every address part (i.e. address, city === +0.4)
 * - A full name match is +1.5
 *   - If the full name DOES NOT MATCH, partial name matching is +1 for every name segement
 */
export const getBestPlace = (
  candidates: GooglePlace[],
  addressParts: AddressParts = {},
  name?: string | null,
): Place | null => {
  const { address, city, state, zip } = addressParts;
  // Join the address parts into a single string
  const normalizedAddress = formatAddress(addressParts).toLowerCase().trim();
  const validAddressParts = [address, city, state, zip]
    .filter(Boolean)
    .map((part) => part?.trim().toLowerCase() || "");

  // Normalize the name by splitting on special characters and spaces, then joining
  const normalizedName = name?.toLowerCase();

  // eslint-disable-next-line  no-useless-escape
  const normalizedNameParts = name?.split(/[\s\/#]+/).map((part) => part.toLowerCase());

  // Helper function to calculate the match score for a candidate
  const getMatchScore = (candidate: GooglePlace) => {
    const candidateAddress = candidate.formatted_address.toLowerCase();
    const candidateName = candidate.name.toLowerCase();
    const addressScore = calculateAddressScore(candidateAddress);
    const nameScore = calculateNameScore(candidateName);
    return addressScore + nameScore;
  };

  const calculateAddressScore = (candidateAddress: string) => {
    if (normalizedAddress && candidateAddress.includes(normalizedAddress)) {
      return 1;
    }
    return validAddressParts.reduce((score, part) => {
      return score + (candidateAddress.includes(part) ? 0.2 : 0);
    }, 0);
  };

  const calculateNameScore = (candidateName: string) => {
    if (normalizedName && candidateName.includes(normalizedName)) {
      return 1.5;
    }
    return (
      normalizedNameParts?.reduce((score, part) => {
        return score + (candidateName.includes(part) ? 1 : 0);
      }, 0) || 0
    );
  };

  // Find the candidate with the highest score
  const bestCandidate = candidates.reduce<GooglePlace | null>((best, candidate) => {
    const candidateScore = getMatchScore(candidate);
    const bestScore = best ? getMatchScore(best) : 0;
    return candidateScore > bestScore ? candidate : best;
  }, null);

  if (!bestCandidate) {
    return null;
  }

  const { geometry, place_id } = bestCandidate;

  return {
    geometry: geometry,
    placeId: place_id,
  };
};

export const getGooglePlaces = async (
  googleApiKey: string,
  searchInput: string,
  inputType: GooglePlaceQueryInputType = "textquery",
): Promise<GooglePlace[]> => {
  try {
    const query = `https://maps.googleapis.com/maps/api/place/findplacefromtext/json?key=${googleApiKey}&input=${encodeURIComponent(
      searchInput,
    )}&inputtype=${inputType}&fields=geometry,place_id,name,formatted_address`;
    const response = await fetch(query);
    const data = (await response.json()) as GoogleApiResponse;
    if (data.status === "OK" && data.candidates.length > 0) {
      return data.candidates as GooglePlace[];
    } else {
      console.log("No coordinates found for the address:", searchInput);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
  return [];
};

export const getGooglePlacesUsingSDK = (
  googlePlaces: google.maps.places.PlacesService,
  searchInput: string,
  inputType: GooglePlaceQueryInputType = "textquery",
): Promise<GooglePlace[]> => {
  return new Promise((resolve, _) => {
    const toGooglePlace = (e: google.maps.places.PlaceResult): GooglePlace => {
      return {
        geometry: {
          location: e.geometry?.location?.toJSON() || { lat: 0, lng: 0 },
          viewport: {
            northeast: e.geometry?.viewport?.getNorthEast().toJSON() || { lat: 0, lng: 0 },
            southwest: e.geometry?.viewport?.getSouthWest().toJSON() || { lat: 0, lng: 0 },
          },
        },
        name: e.name || "",
        formatted_address: e.formatted_address || "",
        place_id: e.place_id || "",
      };
    };

    if (inputType === "textquery") {
      const request = {
        fields: ["name", "geometry", "formatted_address", "place_id"],
        query: searchInput,
      };
      googlePlaces.findPlaceFromQuery(request, (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && results && results.length > 0) {
          const candidates: GooglePlace[] = results.map(toGooglePlace);
          resolve(candidates);
        } else {
          console.log("No coordinates found for the address:", searchInput);
          resolve([]);
        }
      });
    } else if (inputType === "phonenumber") {
      const request = {
        fields: ["name", "geometry", "formatted_address", "place_id"],
        phoneNumber: searchInput,
      };
      googlePlaces.findPlaceFromPhoneNumber(request, (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && results && results.length > 0) {
          const candidates: GooglePlace[] = results.map(toGooglePlace);
          resolve(candidates);
        } else {
          console.log("No coordinates found for the address:", searchInput);
          resolve([]);
        }
      });
    }
  });
};

export const formatZip = (zip?: string | null): string | null | undefined => {
  return zip && zip?.length > 5 ? `${zip.slice(0, 5)}-${zip.slice(5)}` : zip;
};

type AddressParts = {
  address?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
};
export const formatAddress = ({ address, city, state, zip }: AddressParts): string => {
  return [address, city, state]
    .filter(Boolean)
    .join(", ")
    .concat(zip ? ` ${formatZip(zip)}` : "");
};

type DirectionsLinkParams = {
  address: string;
  coordinate?: GeoCoordinates;
  placeId?: string | null;
  placeName?: string | null;
};
export const getGoogleDirectionsLink = ({
  address,
  coordinate,
  placeId,
  placeName,
}: DirectionsLinkParams): string => {
  /**
   * Returns a Google maps link which gives directions to the specified location.
   * The 1st of the following cases which is fulfilled will be used(best-to-worse):
   * 1. placeId & placeName: It points to the exact location and on the marker we will see the name
   * 2. placeId & coordinate: It points to the exact location but marker might not have the name on it
   * 3. placeName & address: It might point to exact location but not 100% sure. Marker will have the name on it if is the exact location.
   * 4. address only: It will mark close location and marker will have the address as the name
   */
  if (placeId && placeName) {
    return `https://www.google.com/maps/search/?api=1&query_place_id=${placeId}&query=${encodeURIComponent(
      `${placeName}`,
    )}`;
  }
  if (placeId && coordinate) {
    return `https://www.google.com/maps/search/?api=1&query_place_id=${placeId}&query=${coordinate.latitude},${coordinate.longitude}`;
  }
  if (placeName) {
    return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      `${placeName} ${address}`,
    )}`;
  }

  return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(`${address}`)}`;
};
