import styled from "styled-components";

import Button from "/component/base/Button";
import Link from "/component/base/Link";
import Text from "/component/base/Text";
import ContactUs from "/component/partial/ContactUs";
import ProviderAvatarList from "/component/partial/ProviderAvatarList";
import { layout } from "/styles";

import { Props } from "./UpcomingVisits.types";

type StyleProps = Pick<Props, "isLoading">;

export const Base = styled.article<StyleProps>(({ theme, isLoading }) => ({
  textAlign: isLoading ? "center" : "initial",
  [theme.media.max.tabletPortrait]: {
    marginTop: theme.spacing.base * 12,
  },
}));

export const Header = styled.div<StyleProps>(
  ({ theme, isLoading }) => ({
    marginBottom: isLoading ? theme.spacing.base * 20 : theme.spacing.expanded,
  }),
  layout.flexSpaceHorizontal,
);

export const ProviderListHeader = styled(Text)({
  // Give the element a maxWidth so that the text wraps if there's a bunch of text
  maxWidth: 430,
  display: "inline-block",
});

export const ProviderList = styled(ProviderAvatarList)(({ theme }) => ({
  [theme.media.max.tabletPortrait]: {
    marginTop: theme.spacing.base * 3,
  },
  [theme.media.tablet]: {
    marginTop: theme.spacing.gutter,
  },
}));

export const LoginWrapper = styled.article(({ theme }) => ({
  marginTop: theme.spacing.condensed,
  [theme.media.tablet]: {
    marginTop: theme.spacing.standard,
  },
  marginBottom: theme.spacing.base * 12,
}));

export const LoginButton = styled(Button)(({ theme }) => ({
  [theme.media.max.tabletPortrait]: {
    width: "100%",
  },
  [theme.media.tablet]: {
    paddingLeft: theme.spacing.base * 14,
    paddingRight: theme.spacing.base * 14,
  },
}));

export const Contact = styled(ContactUs)(({ theme }) => ({
  marginTop: theme.spacing.base * 12,
  justifyContent: "flex-start",
}));

// On large screens we will show a link in the "Upcoming Visits" header
export const ViewAllLink = styled(Link)(({ theme }) => ({
  [`${theme.media.max.tabletPortrait}`]: {
    display: "none",
  },
}));

// On mobile the Link is hidden, and instead a full width Button is shown below the content
export const ViewAllButton = styled(Link.Button)(({ theme }) => ({
  marginTop: theme.spacing.standard,
  width: "100%",

  [`${theme.media.tablet}`]: {
    display: "none",
  },
}));
