import { TFunctionResult } from "i18next";
import React from "react";

export const WRAPPER_VARIANTS = ["primary", "large-label", "bold-label", "expanded-label"] as const;

export type WrapperVariants = (typeof WRAPPER_VARIANTS)[number];

export type Props = {
  label?: TFunctionResult;
  htmlFor?: string;
  accessoryText?: string;
  children: React.ReactElement;
  className?: string;
  error?: string | TFunctionResult;
  touched?: boolean;
  variant?: WrapperVariants;
  isHTML?: boolean;
};
