import { ApolloError, gql } from "@apollo/client";

import { useB2CQuery } from "../client";
import {
  GET_PHARMACY_DEFAULT,
  GET_PHARMACY_DEFAULT_patient_defaultPharmacy,
} from "../schema/types";

export const defaultPharmacyQuery = gql`
  query GET_PHARMACY_DEFAULT {
    patient {
      defaultPharmacy {
        pharmacyType
        address
        state
        city
        zip
        phoneNumber
        clinicalProviderName
        clinicalProviderId
      }
    }
  }
`;

const useDefaultPharmacy = (
  enabled = true,
): {
  error: ApolloError | undefined;
  loading: boolean;
  data: GET_PHARMACY_DEFAULT_patient_defaultPharmacy | null | undefined;
} => {
  const { data, error, loading } = useB2CQuery<GET_PHARMACY_DEFAULT>(defaultPharmacyQuery, {
    skip: !enabled,
  });

  return {
    error,
    loading,
    data: data?.patient?.defaultPharmacy,
  };
};

export default useDefaultPharmacy;
