import { Options } from "@popperjs/core";
import { useState } from "react";
import { usePopper } from "react-popper";

import { UsePopover } from "./usePopover.types";

const usePopover = (popperOptions: Options): UsePopover => {
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const [popoverElement, setPopoverElement] = useState<HTMLElement | null>(null);
  const { styles, update, state } = usePopper(anchorElement, popoverElement, popperOptions);

  const updatePopoverPosition = () => {
    if (update) {
      update();
    }
  };

  return {
    anchorElement,
    popoverElement,
    setAnchorElement,
    setPopoverElement,
    updatePopoverPosition,
    popoverStyles: styles.popper,
    popoverState: state,
  };
};

export default usePopover;
