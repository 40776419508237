import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";

import UrgentCareFacility from "./page/UrgentCareFacility";
import UrgentCareIndex from "./page/UrgentCareIndex";

const UrgentCareRoutes = ({ match }: RouteComponentProps) => {
  return (
    <Switch>
      <Route exact path={match.url} component={UrgentCareIndex} />
      <Route exact path={`${match.url}/:facilityId`} component={UrgentCareFacility} />
    </Switch>
  );
};

export default UrgentCareRoutes;
