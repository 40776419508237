import { useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import * as Yup from "yup";

import { CreateCaregiverAccess, CreateCaregiverAccessVariables } from "/apollo/schema/types";
import caregiverAdultAdded from "/asset/image/adult-invite-success.png";
import { Button, Text } from "/component/base";
import CaregiverEndModal from "/component/modal/CaregiverEndModal";
import TextField from "/component/partial/formik/TextField";
import { useToastContext } from "/component/provider/ToastProvider";
import { layout } from "/styles";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  logEvent,
} from "/util/firebase.util";

import { CaregiverFormProps } from "..";
import { FormContentContainer, Title } from "../CaregiverForms.styles";
import CreateCaregiverAccessGql from "../createCaregiverAccess";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const InviteCaregiver = ({ handleSubmit }: CaregiverFormProps<any>) => {
  const { t } = useTranslation("form-inviteCaregiver");

  const { showToast } = useToastContext();
  const [showEndModal, setShowEndModal] = useState(false);
  const [inviteeName, setInviteeName] = useState("");

  const [createCaregiverAccessMutation] = useMutation<
    CreateCaregiverAccess,
    CreateCaregiverAccessVariables
  >(CreateCaregiverAccessGql);

  const inviteCaregiverSchema = Yup.object().shape({
    firstName: Yup.string().required(t("error.required")),
    lastName: Yup.string().required(t("error.required")),
    email: Yup.string().email(t("error.invalidEmail")).required(t("error.required")),
    confirmEmail: Yup.string()
      .email(t("error.invalidEmail"))
      .required(t("error.required"))
      .test(
        "confirmEmailValidation",
        t("error.confirmEmailError"),
        function (value: string | null | undefined): boolean {
          const contextEmail = this?.parent?.email;
          return value && value !== contextEmail ? false : true;
        },
      ),
  });

  const logButtonClickEvent = (buttonName: string) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.CAREGIVER,
      source: AnalyticsSource.CAREGIVER_ACCESS_INVITE,
      button_name: buttonName,
    } as ButtonClickParams);
  };

  return (
    <>
      <Formik
        validateOnMount
        initialValues={{ email: "", confirmEmail: "", firstName: "", lastName: "" }}
        validationSchema={inviteCaregiverSchema}
        onSubmit={async (values) => {
          logButtonClickEvent("Invite");
          try {
            const response = await createCaregiverAccessMutation({
              variables: {
                data: {
                  firstName: values.firstName,
                  lastName: values.lastName,
                  email: values.email,
                  addChildren: false,
                  children: null,
                },
              },
            });

            if (
              response?.data?.createCaregiverAccess.errors &&
              response?.data?.createCaregiverAccess.errors.length > 0
            ) {
              showToast({
                title: t("error.inviteTitle"),
                icon: "alert",
                ttl: 5000,
                message: t("error.inviteMessage"),
              });
            } else {
              logEvent(AnalyticsEvent.CAREGIVER_INVITE_SEND);
              setInviteeName(values.firstName);
              setShowEndModal(true);
            }
          } catch (error) {
            showToast({
              title: t("error.inviteTitle"),
              icon: "alert",
              ttl: 5000,
              message: t("error.inviteError"),
            });
          }
        }}
      >
        {({ isValid, isSubmitting }) => (
          <Form css={{ width: "100%" }}>
            <FormContentContainer css={layout.spacedChildrenVertical("standard")}>
              <Title>{t("title")}</Title>
              <TextField
                label={t("firstName")}
                placeholder={t("firstName")}
                name="firstName"
                type="text"
              />
              <TextField
                label={t("lastName")}
                placeholder={t("lastName")}
                name="lastName"
                type="text"
              />
              <TextField
                label={t("email")}
                placeholder={t("emailPlaceHolder")}
                name="email"
                type="text"
              />
              <TextField
                label={t("confirmEmail")}
                placeholder={t("confirmEmailPlaceHolder")}
                name="confirmEmail"
                type="text"
              />
              <FormContentContainer.ButtonGroup>
                <Button
                  variant="primary"
                  isDisabled={!isValid}
                  type="submit"
                  isLoading={isSubmitting}
                >
                  {t("invite")}
                </Button>
              </FormContentContainer.ButtonGroup>
            </FormContentContainer>
          </Form>
        )}
      </Formik>
      <CaregiverEndModal
        title={t("completeModal.title")}
        subtitle={
          <Trans
            components={{ bold: <Text color="textSecondary" variant="body2Bold" /> }}
            i18nKey="completeModal.subtitle"
            t={t}
            values={{ success: inviteeName }}
          />
        }
        isOpen={showEndModal}
        imageSource={caregiverAdultAdded}
        close={() => {
          setShowEndModal(false);
          handleSubmit();
        }}
        caregiverType={"adult"}
      />
    </>
  );
};

export default InviteCaregiver;
