import { gql } from "@apollo/client";

import { useB2CLazyQuery } from "../client";
import { GET_DEMOGRAPHICS } from "../schema/types";

export const demographicsQuery = gql`
  query GET_LAZY_DEMOGRAPHICS {
    patient {
      demographics {
        address1
        address2
        city
        state
        zipCode
      }
    }
  }
`;

const useLazyDemographics = () => useB2CLazyQuery<GET_DEMOGRAPHICS>(demographicsQuery);

export default useLazyDemographics;
