import React from "react";

import { Badge, IconWrapper, Label, Link, LinkWrapper } from "./NavLink.styles";
import { Props } from "./NavLink.types";

const NavLink = ({ to, text, isActive, icon, activeIcon, onClick, showBadge }: Props) => (
  <LinkWrapper onClick={onClick}>
    <Link exact to={to} aria-label={text}>
      <IconWrapper>
        {isActive ? activeIcon : icon}
        {showBadge && <Badge />}
      </IconWrapper>
      <Label variant="title3" color={isActive ? "brandPrimary" : "textSecondary"}>
        {text}
      </Label>
    </Link>
  </LinkWrapper>
);

export default NavLink;
