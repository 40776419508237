import { TFunction } from "i18next";

import { GET_INSURANCE_patient_insurances } from "/b2c/schema";
import { isPast } from "/util/date";

export enum InsuranceType {
  PRIMARY = 1,
  SECONDARY,
  TERTIARY,
}

export const convertToPolicyOrderString = (t: TFunction, order?: number | null): string => {
  if (order === 1) return t("primaryPolicy", { ns: "insurance" });
  if (order === 2) return t("secondaryPolicy", { ns: "insurance" });
  if (order === 3) return t("tertiaryPolicy", { ns: "insurance" });
  return "";
};

export const getFilteredInsurances = (
  insurances: GET_INSURANCE_patient_insurances[],
  sequenceNumbers: InsuranceType[] = [
    InsuranceType.PRIMARY,
    InsuranceType.SECONDARY,
    InsuranceType.TERTIARY,
  ],
): GET_INSURANCE_patient_insurances[] => {
  const sortByEligibilityDate = (
    a: GET_INSURANCE_patient_insurances,
    b: GET_INSURANCE_patient_insurances,
  ) => {
    const dateA = a.eligibilityLastChecked ? new Date(a.eligibilityLastChecked).getTime() : 0;
    const dateB = b.eligibilityLastChecked ? new Date(b.eligibilityLastChecked).getTime() : 0;
    return dateB - dateA;
  };
  const validInsurances = insurances
    .filter((insurance) => !insurance.cancelled)
    .sort(sortByEligibilityDate);
  const selectedInsurances = validInsurances.filter((insurance) => {
    const index = sequenceNumbers.indexOf(insurance.sequenceNumber as InsuranceType);
    if (index !== -1) {
      sequenceNumbers.splice(index, 1);
      return true;
    }
    return false;
  });
  return selectedInsurances.sort((a, b) => a.sequenceNumber! - b.sequenceNumber!);
};

export const isInsuranceExpired = (item: GET_INSURANCE_patient_insurances) => {
  if (item.eligibilityStatus === "Ineligible") {
    return true;
  }
  if (item.expirationDate && isPast(item.expirationDate)) {
    return true;
  }
  if (item.cancelled) {
    return true;
  }
  return false;
};
