// Modules
import React from "react";

// Styles
import {
  FilledRadioDot,
  Input,
  InputWrapper,
  Label,
  LabelWrapper,
  RadioWrapper,
} from "./Radio.styles";
import { Props } from "./Radio.types";

const Radio = React.forwardRef<HTMLInputElement, Props>(
  ({ label: labelProp, labelBefore = true, className, ...rest }, ref) => {
    const checkbox = (
      <InputWrapper>
        <Input {...rest} type="radio" ref={ref} />
        <RadioWrapper>
          <FilledRadioDot />
        </RadioWrapper>
      </InputWrapper>
    );

    if (!labelProp) {
      return checkbox;
    }

    const label = <LabelWrapper>{labelProp}</LabelWrapper>;

    return (
      <Label className={className}>
        {!!labelBefore && label}
        {checkbox}
        {!labelBefore && label}
      </Label>
    );
  },
);

export default Radio;
