import React, { createContext, useContext, useState } from "react";

import { Context } from "./SignUpProvider.types";

const INITIAL_STATE = {
  phone: "",
  email: "",
  id: "",
  isEmailVerificationCodeSent: false,
};

const SignUpProviderContext = createContext<Context>({
  setEmail: () => "",
  setPhone: () => "",
  setId: () => "",
  setEmailVerificationCodeSent: () => undefined,
  phone: "",
  email: "",
  id: "",
  emailVerificationCode: "",
  isEmailVerificationCodeSent: false,
});

const SignUpProvider = (props: {
  children: React.ReactNode;
  handleError: () => void;
  emailVerificationCode: string;
}): JSX.Element => {
  const [state, setCurrentState] = useState(INITIAL_STATE);

  const setEmail = (email: string) => {
    setCurrentState({
      ...state,
      email,
    });
  };
  const setPhone = (phone: string) => {
    setCurrentState({
      ...state,
      phone,
    });
  };
  const setId = (id: string) => {
    setCurrentState({
      ...state,
      id,
    });
  };
  const setEmailVerificationCodeSent = (isSent: boolean) => {
    setCurrentState({
      ...state,
      isEmailVerificationCodeSent: isSent,
    });
  };

  return (
    <SignUpProviderContext.Provider
      value={{
        setEmail,
        setPhone,
        setId,
        setEmailVerificationCodeSent,
        emailVerificationCode: props.emailVerificationCode,
        ...state,
      }}
    >
      {props.children}
    </SignUpProviderContext.Provider>
  );
};

export const useSignUpProvider = () => useContext(SignUpProviderContext);
export default SignUpProvider;
