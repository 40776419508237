import { gql, MutationHookOptions, QueryHookOptions, useMutation, useQuery } from "@apollo/client";

import {
  GetBillingAccountSettings,
  SetBillingAccountSettings,
  SetBillingAccountSettingsVariables,
} from "/apollo/schema";

const GET_BILLING_ACCOUNT_SETTINGS = gql`
  query GetBillingAccountSettings {
    viewer {
      user {
        billingAccounts {
          entity
          patientMrn
          settings {
            email
            paperMail
            phoneCall
            phoneText
            ringlessCall
          }
        }
      }
    }
  }
`;

const SET_BILLING_ACCOUNT_SETTINGS = gql`
  mutation SetBillingAccountSettings($input: UpdateCedarSettingsInput!) {
    updateCedarSettings(data: $input) {
      errors {
        code
        message
      }
      settings {
        email
        paperMail
        phoneCall
        phoneText
        ringlessCall
      }
    }
  }
`;

export const useBillingAccountSettings = (options?: QueryHookOptions<GetBillingAccountSettings>) =>
  useQuery<GetBillingAccountSettings>(GET_BILLING_ACCOUNT_SETTINGS, options);

export const useSetBillingAccountSetting = (
  options?: MutationHookOptions<SetBillingAccountSettings, SetBillingAccountSettingsVariables>,
) =>
  useMutation<SetBillingAccountSettings, SetBillingAccountSettingsVariables>(
    SET_BILLING_ACCOUNT_SETTINGS,
    options,
  );
