import styled from "styled-components";

import { card, layout } from "/styles";

export const MyHealthItem = styled.div(
  layout.flexCenterHorizontal,
  layout.padding(20, "skip"),
  layout.spacedChildrenHorizontal(),
  {
    flexGrow: 1,
  },
);

export const ListContainer = styled.div(card.wrapper);

export const HeaderContainer = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  [theme.media.max.tablet]: {
    justifyContent: "space-between",
  },
}));

export const IconContainer = styled.div({
  height: 54,
  width: 54,
});

export const TextContent = styled.div(layout.flexSpaceHorizontal, {
  width: "100%",
});

export const BadgeContainer = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  width: theme.spacing.expanded,
  height: theme.spacing.expanded,
  backgroundColor: theme.colors.brandSecondary,
  borderRadius: 20,
  marginRight: theme.spacing.standard,
}));
