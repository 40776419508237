import BaseContentEditable from "react-contenteditable";
import styled from "styled-components";

import { getTextStyles } from "/component/base/Text";

// The below usage of `!important` in the styles for child selectors is to
// compensate for inline styles in the message detailʼs HTML string.
export const ContentEditable = styled(BaseContentEditable)(
  ({ theme }) => getTextStyles({ color: "textPrimary", theme, variant: "body1" }),
  ({ theme }) => ({
    background: "none",
    border: "none",
    maxHeight: 800,
    margin: 0,
    outline: "none",
    padding: 0,
    width: "100%",

    "& span": {
      color: `${theme.colors.textPrimary} !important`,
      fontFamily: `${theme.fontFamilies.CeraProRegular} !important`,
    },
    "& b": {
      fontFamily: `${theme.fontFamilies.CeraProBold} !important`,
      fontWeight: theme.fontWeights.body1Bold,
    },
    "& hr": {
      background: theme.colors.objectSubtle,
      border: "none",
      borderBottom: `1px solid ${theme.colors.objectSubtle}`,
      margin: `${theme.spacing.standard}px 0`,
    },

    "& > .scmd-quote": {
      borderLeft: `2px solid ${theme.colors.objectSubtle}`,
      paddingLeft: theme.spacing.standard,
    },
  }),
);
