import { useReactiveVar } from "@apollo/client";
import React from "react";
import { matchPath, useLocation } from "react-router";
import { Link } from "react-router-dom";

import { cedarPayments } from "/apollo/client/local";
import * as Icons from "/component/base/Icon";
import NavLink from "/component/layout/NavLink";
import routes, { homeRoutes } from "/constant/url.constant";
import { useTranslation } from "/hook";
import useWindowSize from "/hook/useWindowSize";
import { layout } from "/styles";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  logEvent,
} from "/util/firebase.util";

import { Base, CloseButton, CtaCard, Divider, Header, Logo } from "./Nav.styles";
import { Props } from "./Nav.types";

const Nav = React.forwardRef<HTMLDivElement, Props>(
  ({ className, isAuthenticated, logout, onClose }, ref) => {
    const { pathname } = useLocation();
    const { t } = useTranslation("nav-links");
    const { isTablet } = useWindowSize();
    const { pendingInvoiceCount } = useReactiveVar(cedarPayments.var);

    const logButtonClickEvent = (buttonName: string) => {
      logEvent(AnalyticsEvent.BUTTON_CLICK, {
        user_flow: AnalyticsUserFlow.MY_ACCOUNT,
        source: AnalyticsSource.MY_ACCOUNT,
        button_name: buttonName,
      } as ButtonClickParams);
    };

    return (
      <Base className={className} ref={ref}>
        <Header>
          <Link to={routes.root} aria-label={t("home")} onClick={onClose}>
            <Logo />
          </Link>
          <CloseButton variant="unstyled" onClick={onClose}>
            <Icons.Cross size={32} />
          </CloseButton>
        </Header>

        <ul aria-label={t("mainMenu", "Main Menu")}>
          <NavLink
            to={routes.root}
            isActive={!!matchPath(pathname, { path: homeRoutes, exact: false })}
            text={t("home")}
            icon={<Icons.NewHome />}
            activeIcon={<Icons.NewHome color="brandPrimary" />}
            onClick={onClose}
          />
          <NavLink
            to={routes.myHealth}
            isActive={!!matchPath(pathname, routes.myHealth)}
            text={t("health")}
            icon={<Icons.MyHealth />}
            activeIcon={<Icons.MyHealth color="brandPrimary" />}
            onClick={onClose}
          />
          <NavLink
            to={routes.account}
            isActive={!!matchPath(pathname, routes.account)}
            text={t("account")}
            icon={<Icons.MyAccount />}
            activeIcon={<Icons.MyAccount color="brandPrimary" />}
            onClick={onClose}
            showBadge={!!pendingInvoiceCount}
          />
        </ul>

        <Divider ariaHidden />

        <ul
          aria-label={t("secondaryMenu", "Secondary Menu")}
          css={layout.margin("skip", "skip", "standard")}
        >
          {isTablet && (
            <NavLink
              to={routes.faq}
              isActive={!!matchPath(pathname, routes.faq)}
              text={t("faq")}
              icon={<Icons.Faq />}
              activeIcon={<Icons.Faq color="brandPrimary" />}
              onClick={() => {
                logButtonClickEvent("Help & FAQ");
                onClose && onClose();
              }}
            />
          )}
          {isAuthenticated && (
            <NavLink
              to="#"
              isActive={false}
              text={t("logout")}
              icon={<Icons.Logout />}
              onClick={() => {
                logButtonClickEvent("Log out");
                logout();
                onClose && onClose();
              }}
            />
          )}
        </ul>

        <CtaCard
          title={t("faqCta.title")}
          subtitle={t("faqCta.subtitle")}
          links={[{ title: t("faqCta.link"), to: routes.faq, variant: "secondary" }]}
        />
      </Base>
    );
  },
);

export default Nav;
