import { transparentize } from "polished";

const red = "#CB443F";
const blue = "#60a5ec9e";

const brandActionBlue = "#2355EC";
const brandActionBlueLight = transparentize(0.7, brandActionBlue);
const brandLightBlue = "#E5EFFF";
const brandRed = "#D80032";
const brandRedLight = "#FFF1F1";
const brandNavy = "#0F1E89";
const brandNavyLight = "#9EA9D4";

const white = "#FFF";
const black = "#000";
const lightBlue = "#F6F9FF";

const grey = "#F3F4F6";
const greyDark = "#6B6B6B";
const greyDarkest = "#1A1A1A";
const greyLight = "#F1F3F4";
const homeGrey = "#909090";
const greyDisabled = "#AAAAAA";

const navy = "#132A53";
const darkNavy = "#060B34";
const yellow = "#FFDA3E";

const utilityRed = brandRed;
const utilityAmber = "#FFBE00";
const utilityDarkGreen = "#14853D";

// b2c shared module brand colors;
const backgroundSurfaceActive = "#F1F2F4";
const backgroundSurfaceHover = "#F8F8F9";
const backgroundAbnormalFlag = "#FFF2CC";
const textAbnormalFlag = "#664C00";
const iconDisabled = "#A1A2A3";

export const colors = {
  // Rebranding
  // Colors related to brand identity, used throughout the app.
  brandPrimary: brandActionBlue,
  brandPrimaryLight: brandLightBlue,
  brandSecondary: brandRed,
  brandSecondaryLight: brandRedLight,

  // Colors used for text & icon content.
  textInverse: white,
  textPrimary: greyDarkest,
  textSecondary: greyDark,
  textGreen: utilityDarkGreen,
  textRed: red,
  textHomeGrey: homeGrey,
  textNavyBlue: brandNavy,
  textNavyBlueLight: brandNavyLight,

  textTitle: brandNavy,
  textTitleSecondary: brandNavyLight,
  textTitleInverse: darkNavy,
  textDisabled: greyDisabled,

  // Colors used as screen backgrounds.
  backgroundInverse: navy,
  backgroundDark: darkNavy,
  backgroundPrimary: white,
  backgroundSecondary: grey,
  backgroundSecondaryLight: lightBlue,

  // Rebranding
  // Colors used broadly for many UI elements
  objectInverse: white,
  objectOverlay: transparentize(0.4, navy),
  objectOverlayLight: transparentize(0.7, navy),
  modalOverlay: transparentize(0.6, navy),
  objectPrimary: greyDarkest,
  objectSecondary: greyDark,
  objectSubtle: greyLight,

  // Colors used to indicate a status change in the UI
  // (eg. in form validation, errors, etc.)
  statusNegative: utilityRed,
  statusPositive: utilityDarkGreen,
  statusWarning: utilityAmber,
  statusPressed: brandNavy,

  progressBar: utilityDarkGreen,
  nrcStar: yellow,
  ratingStar: yellow,
  transparent: "transparent",

  white,
  black,

  brandActionBlue,
  brandActionBlueLight,
  brandLightBlue,
  brandRed,
  brandRedLight,
  brandNavy,
  brandNavyLight,
  grey,
  greyDark,
  greyDarkest,
  greyLight,
  homeGrey,

  focus: blue,

  // b2c shared module brand colors
  backgroundSurfaceActive,
  backgroundSurfaceHover,
  backgroundAbnormalFlag,
  textAbnormalFlag,
  iconDisabled,
} as const;
