import { gql, useQuery } from "@apollo/client";

import { GetFacility, GetFacilityVariables } from "/apollo/schema/types";

const GET_URGENT_CARE_FACILITY = gql`
  query GetFacility($id: ID!) {
    facility(id: $id) {
      address {
        line1
        line2
        city
        state
        postalCode
      }
      alertUrl
      clinical
      contactInfo {
        email
        faxNumber
        phoneNumber
      }
      coordinates {
        lat
        lng
      }
      facilityNameLong
      facilityNameShort
      hoursOfOperation {
        closingTime
        dayOfWeek
        open
        openingTime
      }
      id
      imageUrl
      org
      supportedServices
      urgentCareWalkIn
      waitTimeSeconds
      ehrLocationIds
    }
  }
`;

// useQuery is fully typed and return value is inferred.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useQueryUrgentCareFacility = (id: string) =>
  useQuery<GetFacility, GetFacilityVariables>(GET_URGENT_CARE_FACILITY, {
    variables: { id },
    skip: !id,
  });
