import styled from "styled-components";

import ModalBase, { styles as ModalStyles } from "/component/base/Modal";
import PickerFieldBase from "/component/partial/formik/PickerField";

export const Modal = styled(ModalBase)({
  [`& > ${ModalStyles.Body} > *`]: {
    width: "100%",
  },
});

export const PickerField = styled(PickerFieldBase)({
  "& > *": {
    width: "100%",
  },
});
