import React from "react";

import providerPlaceholderImage from "/asset/image/providerPlaceholder.png";
import summitLogoImage from "/asset/image/summit-logo-small.png";
import Note from "/component/base/Note";
import { useTranslation } from "/hook";

type Props = {
  note: string;
  provider?: {
    imageUrl?: string;
    displayName?: string;
    specialty?: string;
  };
  isCityMd?: boolean;
};
const MedicationProviderNote = ({ provider, note, isCityMd = false }: Props) => {
  const { t } = useTranslation("partial-medicationProviderNote");

  return isCityMd ? (
    <Note avatar={summitLogoImage} title={t("providerNote")} body={note} />
  ) : (
    <Note
      avatar={provider?.imageUrl || providerPlaceholderImage}
      title={provider?.displayName || t("providerNote")}
      subTitle={provider?.specialty}
      body={note}
    />
  );
};
export default MedicationProviderNote;
