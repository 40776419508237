import { computeDistanceBetween, LatLngLike } from "spherical-geometry-js";

const getMetresInMiles = (metres: number) =>
  Math.round((metres / 1609) * 100 + Number.EPSILON) / 100;

/**
 * Given two sets of latitude/longitude coordinates, this function returns the
 * distance between them as a number in miles.
 */
export default function getDistance(to: LatLngLike, from: LatLngLike): number {
  return getMetresInMiles(computeDistanceBetween(to, from));
}
