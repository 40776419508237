import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React from "react";
import { Helmet } from "react-helmet-async";

import { Button, Text } from "/component/base";
import MainContent from "/component/layout/MainContent";
import CtaCard from "/component/partial/CtaCard/CtaCard";
import { useTranslation } from "/hook";
import { reactPlugin } from "/util/appInsights.util";

import { ButtonVerticalContainer } from "./AthenaBilling.styles";

const ATHENA_PATIENT_PORTAL_LINK = process.env.ATHENA_PATIENT_PORTAL_LINK || "";
const ATHENA_STATEMENT_CODE_LINK = process.env.ATHENA_STATEMENT_CODE_LINK || "";

const AthenaBilling = () => {
  const { t } = useTranslation("page-Account-page-AthenaBilling");

  return (
    <MainContent parentPage="billingAccounts" pageTitle={"Bills and Payment"}>
      <Helmet>
        <title>{t("title")}</title>
      </Helmet>

      <Text color="textSecondary">{t("description")}</Text>

      <ButtonVerticalContainer>
        <Button
          onClick={() => window.open(ATHENA_PATIENT_PORTAL_LINK, "_blank")}
          variant="primary"
          title={t("payWithPatientPortal")}
        />
        <Button
          onClick={() => window.open(ATHENA_STATEMENT_CODE_LINK, "_blank")}
          variant="secondary"
          title={t("payWithStatementCode")}
        />
      </ButtonVerticalContainer>

      <CtaCard
        title={t("faqCta.title")}
        subtitle={t("faqCta.subtitle")}
        links={[
          {
            title: t("faqCta.link"),
            to: `/faq/category/1`,
            variant: "borderBottom",
          },
        ]}
      />
    </MainContent>
  );
};

export default withAITracking(reactPlugin, AthenaBilling, undefined, "ai-tracking");
