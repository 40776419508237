import styled from "styled-components";

import { ChevronDown } from "/component/base/Icon";
import { baseTransitionTime } from "/constant/animation.constant";

const DropdownChevron = styled(ChevronDown)<{ $open: boolean }>(({ $open }) => ({
  transition: `transform ${baseTransitionTime}ms ease`,
  transform: $open ? "rotate(-180deg)" : "none",
}));

export default DropdownChevron;
