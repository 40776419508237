import styled from "styled-components";

import Button from "/component/base/Button";
import { ChevronDown } from "/component/base/Icon";
import { layout } from "/styles";

export const ChevronDownIcon = styled(ChevronDown)(({ theme }) => ({
  marginLeft: theme.spacing.condensed,
}));

export const HeaderContainer = styled.div(layout.flexVertical, ({ theme }) => ({
  marginTop: theme.spacing.base * 11,
}));

export const SetAddressButton = styled(Button)({
  display: "inline-flex",
  alignItems: "center",
});

export const UrgentCareNearbyContainer = styled.div(
  layout.flexVertical,
  layout.marginVertical("expanded"),
  ({ theme }) => ({
    "& > * ": {
      paddingTop: theme.spacing.base,
    },
  }),
);
