import { gql, useQuery } from "@apollo/client";

import { PhoneNumber } from "/apollo/schema/types";

const GET_PHONE_NUMBER = gql`
  query PhoneNumber {
    viewer {
      user {
        account {
          phoneNumber
        }
      }
    }
  }
`;

// useQuery is fully typed and return value is inferred.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useQueryPhoneNumber = () =>
  useQuery<PhoneNumber>(GET_PHONE_NUMBER, {
    fetchPolicy: "network-only",
  });
