import { gql } from "@apollo/client";

export default gql`
  mutation StartDecisionSupport($input: StartDecisionSupportInput!) {
    startDecisionSupport(input: $input) {
      errors {
        attribute
        code
        message
        thirdPartyCode
        type
        validationType
      }
      flowResponse {
        calendarIds {
          selected
          recommended
        }
        elementSet {
          elementSetRef
          elements {
            dateRestrictionType
            elementName
            elementRef
            elementSetRef
            elementType
            falseLabel
            fieldName
            inputType
            isNoOptionSelectionEnabled
            isRequired
            maxLength
            maxValue
            messageText
            minLength
            minValue
            noOptionSelectionDisplayName
            orderIndex
            questionText
            selectOptions {
              fieldName
              label
              value
            }
            trueLabel
            valueType
          }
          title
        }
        enableUrgentCare
        flowSessionId
        hasValidationErrors
        isEstablishedPatient
        isFlowFinished
        kickoutMessage
        finishedDueToKickout
        reasonForVisit
        validationMessages
      }
    }
  }
`;
