import { MutableRefObject } from "react";

import useOnArrowPressed, { Arrow } from "/hook/useOnArrowPressed";

const useKeyBoardNavigation = (
  dayButtonSelector: string,
  calRef: MutableRefObject<HTMLDivElement | null>,
) => {
  /**
   * Updates the focus on a sequential list of date buttons. For example, if the date button for the 8th was
   * currently in focus, an offset of 1 would set the focus on the next selectable date button, which would
   * be the 9th. If the offset is outside the bounds of selectable buttons (e.g. when the 31st is in focus),
   * then this function has no effect on focus. If there are no buttons current in focus, a positive offset
   * will move the focus to the first available button, while a negative offset will move the focus to the
   * last available button.
   */
  const updateFocusByOffset = (offset: number) => {
    if (calRef.current === null) {
      return;
    }
    const e = document.activeElement;

    // Potentially optimize later on. This only needs to be called on calendar month change
    const buttons: NodeListOf<HTMLButtonElement> =
      calRef.current.querySelectorAll(dayButtonSelector);

    let matchFound = -1;
    buttons.forEach((el, i) => {
      if (el == e) {
        matchFound = i;
      }
    });

    if (matchFound >= 0) {
      const newNodeKey = matchFound + offset;
      if (newNodeKey <= buttons.length - 1 && newNodeKey >= 0) {
        buttons[newNodeKey].focus();
      }
    } else if (matchFound === -1 && buttons.length > 0) {
      // If we can't find any matching buttons, it means we are trying to navigate
      // to one of the date buttons, but none are currently in focus. Postive
      // offsets will focus at the start, while negative offsets will focus to
      // the end (the last selectable date)
      if (offset > 0) {
        buttons[0].focus();
      } else {
        buttons[buttons.length - 1].focus();
      }
    }
  };

  useOnArrowPressed(Arrow.RIGHT, () => {
    // Go to tomorrow
    updateFocusByOffset(1);
  });
  useOnArrowPressed(Arrow.LEFT, () => {
    // Go to yesterday
    updateFocusByOffset(-1);
  });
  useOnArrowPressed(Arrow.DOWN, () => {
    // Go backwards one week
    updateFocusByOffset(7);
  });
  useOnArrowPressed(Arrow.UP, () => {
    // Go forwards one week
    updateFocusByOffset(-7);
  });
};

export default useKeyBoardNavigation;
