import { loadStripe, Stripe, StripeElementsOptions } from "@stripe/stripe-js";

export const stripeOptions: StripeElementsOptions = {
  fonts: [
    {
      family: "DMSans-Regular-Alt",
      src: 'url("/assets/DMSans-Regular-Alt.otf")',
    },
  ],
};

export const stripePromise: Promise<Stripe | null> = loadStripe(
  process.env.STRIPE_PUBLISHABLE_KEY as string,
);
