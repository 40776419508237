import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React from "react";
import Lottie from "react-lottie";

import { Link, Text } from "/component/base";
import routes from "/constant/url.constant";
import { useTranslation } from "/hook";
import { layout } from "/styles";
import { reactPlugin } from "/util/appInsights.util";

import { ContentWrapper, Wrapper } from "./NotFound.styles";
import * as animationData from "./sad-animation.json";

const options = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const NotFoundPage = () => {
  const { t } = useTranslation("page-NotFound-404");

  return (
    <Wrapper pageTitle={t("title")}>
      <ContentWrapper>
        <Lottie options={options} height={165} width={165} />

        <Text css={layout.margin(60, "skip", "skip")} variant="title2">
          {t("pageNotFound")}
        </Text>

        <Link.Button
          variant="tertiary"
          to={routes.root}
          css={layout.margin("expanded", "skip", "skip")}
        >
          {t("ctaText")}
        </Link.Button>
      </ContentWrapper>
    </Wrapper>
  );
};

export default withAITracking(reactPlugin, NotFoundPage, undefined, "ai-tracking");
