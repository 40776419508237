import styled from "styled-components";

import { Text } from "/component/base";
import { layout } from "/styles";

// Width is enforced to ensure centre column does not shrink or stretch beyond
// bounds of the root element when preview text is set to `white-space: nowrap`.
const ICON_SIZE = 16;
const widthWithIconOffset = { width: `calc(100% - ${ICON_SIZE}px)` };

export const Base = styled.div(
  layout.flexSpaceHorizontal,
  layout.padding("standard", "skip"),
  widthWithIconOffset,
);

export const TextWrapper = styled.div(
  layout.flexVertical,
  layout.padding("none", "standard", "none", "condensed"),
  widthWithIconOffset,
);

export const DateSenderWrapper = styled.div(layout.flexSpaceHorizontal);

// Move date right by two icon widths, to align above `ListItem`'s right
// chevron icon.
export const DateText = styled(Text)({ marginRight: -(ICON_SIZE * 2) });
