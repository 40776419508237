import { useEffect, useReducer } from "react";

import { useAmwell, useMounted } from "/hook";
import { reducer } from "/util";

import { State } from "./useAmwellMessageContacts.types";

/**
 * This hook allows for the retrieval of an array of contacts that an
 * authenticated user has previously interacted with.
 */
export const useAmwellMessageContacts = () => {
  const [state, dispatch] = useReducer(reducer.reducer, reducer.initialState);
  const mountedRef = useMounted();
  const { consumer, sdk, isReady } = useAmwell();

  useEffect(() => {
    dispatch({ type: reducer.actionTypes.INIT });
  }, []);

  const getMessageDetails = async () => {
    try {
      if (sdk && isReady && consumer) {
        const contacts = await sdk.secureMessageService.getContacts(consumer);
        dispatch({ payload: contacts, type: reducer.actionTypes.SUCCESS });
      }
    } catch (e: any) {
      console.error("Unable to fetch Amwell Message Details", e);
      dispatch({ type: reducer.actionTypes.FAILURE });
    }
  };

  useEffect(() => {
    if (!mountedRef.current || !isReady) {
      return;
    }

    dispatch({ type: reducer.actionTypes.LOADING });
    getMessageDetails();
  }, [isReady]);

  return state as State;
};

export default useAmwellMessageContacts;
