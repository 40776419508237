import { gql } from "@apollo/client";

export default gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(data: $input) {
      errors {
        message
        code
      }
      azureId
    }
  }
`;
