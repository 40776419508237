import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { omit } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import {
  GetBillingAccountSettings_viewer_user_billingAccounts,
  GetBillingAccountSettings_viewer_user_billingAccounts_settings,
} from "/apollo/schema";
import noMessages from "/asset/image/secureMessagingNoMessages.png";
import { InlineMessage, List, Loading, Spinner, Text, Toggle } from "/component/base";
import { AlertCircleOpen } from "/component/base/Icon";
import MainContent from "/component/layout/MainContent";
import {
  getDisablePaperMail,
  getFormattedOptions,
  StoreObject,
} from "/component/page/Account/page/Notifications/Notifications.util";
import EmptyStateCta from "/component/partial/EmptyStateCta";
import { useToastContext } from "/component/provider/ToastProvider";
import Transition from "/component/util/Transition";
import { useDebounce } from "/hook";
import { card, layout, transition } from "/styles";
import { reactPlugin } from "/util/appInsights.util";
import { getEntityName } from "/util/scmd";

import { ListContainer } from "./NotificationBillingPreference.styles";
import {
  useBillingAccountSettings,
  useSetBillingAccountSetting,
} from "./useNotificationBillingPreference";

const IMAGE_SIZE = 274;

const NotificationBillingPreference = () => {
  const { client, data, error, loading } = useBillingAccountSettings();
  const { showToast } = useToastContext();
  const { t } = useTranslation("notifications");
  const [editedAccounts, setEditedAccounts] = useState<{ [key: string]: boolean }>({});
  const billingAccounts = data?.viewer?.user?.billingAccounts;
  const debouncedEdited = useDebounce(editedAccounts, 1000);
  const isInitialLoad = !data && !error && loading;
  const pendingMutations = useRef({});
  const [updateBillingAccountSetting] = useSetBillingAccountSetting({
    onError: () => {
      showToast({ icon: "alert", message: t("error.updateNotification"), type: "error" });
    },
  });

  const modifyBillingAccountCache = (
    billingAccount: GetBillingAccountSettings_viewer_user_billingAccounts,
    updatedSettings?: Partial<GetBillingAccountSettings_viewer_user_billingAccounts_settings> | null,
  ) => {
    client.cache.modify({
      fields: {
        settings(cachedSettings) {
          const nextSettings = { ...cachedSettings, ...(updatedSettings || {}) };
          const setPaperMail = getDisablePaperMail(nextSettings);

          return {
            ...nextSettings,
            paperMail: setPaperMail || nextSettings.paperMail,
          };
        },
      },
      id: client.cache.identify(billingAccount as StoreObject),
    });
  };

  const handleChangeToggle = ({
    billingAccount,
    type,
    value,
  }: {
    billingAccount: GetBillingAccountSettings_viewer_user_billingAccounts;
    type: string;
    value: boolean;
  }) => {
    modifyBillingAccountCache(billingAccount, { [type]: value });
    setEditedAccounts({ ...editedAccounts, [billingAccount.patientMrn]: true });
  };

  useEffect(() => {
    data?.viewer?.user.billingAccounts?.map((billingAccount) => {
      const { entity, patientMrn, settings } = billingAccount;

      if (
        debouncedEdited[billingAccount.patientMrn] &&
        entity &&
        !pendingMutations.current[billingAccount.patientMrn]
      ) {
        const setBillingAccountSetting = async () => {
          pendingMutations.current[billingAccount.patientMrn] = true;

          const result = await updateBillingAccountSetting({
            variables: {
              input: { mrn: patientMrn, org: entity, settings: omit(settings, "__typename") },
            },
          });
          modifyBillingAccountCache(billingAccount, result.data?.updateCedarSettings.settings);
          setEditedAccounts({ ...editedAccounts, [billingAccount.patientMrn]: false });

          pendingMutations.current[billingAccount.patientMrn] = false;
        };

        setBillingAccountSetting();
      }
    });
  }, [debouncedEdited, data]);

  return (
    <MainContent pageTitle={t("title.billing")} parentPage="notifications">
      {isInitialLoad && <Loading variant="page" />}

      {!loading && error && (
        <EmptyStateCta
          imageHeight={IMAGE_SIZE}
          imageWidth={IMAGE_SIZE}
          imageSource={noMessages}
          subtitle={t("error.subtitle")}
          title={t("error.title")}
        />
      )}

      {!isInitialLoad && !error && (
        <>
          <InlineMessage css={layout.margin("skip", "skip", "expanded")} icon={<AlertCircleOpen />}>
            <Text variant="body2">
              <Trans
                components={{ bold: <Text color="brandPrimary" variant="body2Bold" /> }}
                i18nKey="disclaimer.paperStatements"
                t={t}
              />
            </Text>
          </InlineMessage>
        </>
      )}

      {!isInitialLoad &&
        billingAccounts?.map((billingAccount) => {
          const { entity, patientMrn, settings } = billingAccount;
          const disablePaperMail = getDisablePaperMail(settings);
          const mutating = !!pendingMutations.current[billingAccount.patientMrn];

          return (
            <ListContainer key={patientMrn}>
              <Text color="textSecondary" element="h2" variant="body1Bold">
                {t("title.billingAccount", { entityName: getEntityName(entity) })}
              </Text>

              <List
                as="Divs"
                data={getFormattedOptions(settings)}
                hideLastChildBorder
                renderItem={(options) => {
                  const { label, type, value } = options || {};

                  return (
                    <label
                      css={[
                        layout.flexItemAuto,
                        layout.flexSpaceHorizontal,
                        layout.padding("standard", "skip"),
                      ]}
                    >
                      <Text color={mutating ? "textSecondary" : "textPrimary"}>{label}</Text>

                      <Toggle
                        checked={!!value}
                        disabled={mutating || (type === "paperMail" && disablePaperMail)}
                        onChange={({ target }) => {
                          handleChangeToggle({ billingAccount, type, value: target.checked });
                        }}
                      />
                    </label>
                  );
                }}
                showChevron={false}
              />

              <Transition show={mutating}>
                <div css={[layout.absoluteCenter, layout.flexCenter, transition.fadeDown]}>
                  <span css={[card.base, layout.padding("standard")]}>
                    <Spinner />
                  </span>
                </div>
              </Transition>
            </ListContainer>
          );
        })}
    </MainContent>
  );
};

export default withAITracking(reactPlugin, NotificationBillingPreference, undefined, "ai-tracking");
