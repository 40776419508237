import awsdk from "awsdk";
import { merge } from "lodash";
import React, { createContext, useContext, useEffect, useState } from "react";

import { StateCode } from "/apollo/schema/types";
import { useToastContext } from "/component/provider/ToastProvider";
import { PrevisitForms, PrevisitStorageKey } from "/constant/telehealth.constant";
import { useAmwell, useTranslation } from "/hook";

import { Context, PropValueTypes } from "./AmwellPrevisitProvider.types";

const INITIAL_STATE = {
  [PrevisitForms.PATIENT_CALLBACK]: {
    phone: "",
  },
  [PrevisitForms.INVITE_GUESTS]: {
    emails: [] as string[],
  },
  [PrevisitForms.REASON_FOR_VISIT]: {
    reason: "",
  },
  [PrevisitForms.CONFIRM_PATIENT_INFO]: {
    shareSummaryHealthAccepted: true,
    acknowledgePrivacyChecked: false,
  },
  [PrevisitForms.DIAGNOSED_CONDITIONS]: {
    conditions: {},
  },
  [PrevisitForms.ALLERGIES]: {
    allergicList: [],
  },
  [PrevisitForms.MEDICATIONS]: {
    medications: [],
  },
  [PrevisitForms.VITALS]: {
    weight: null,
    heightMajor: null,
    heightMinor: null,
    temperature: null,
    systolic: null,
    diastolic: null,
  },
  [PrevisitForms.ATTACHMENTS]: {
    files: [],
  },
  [PrevisitForms.CONSUMER_PHARMACIES]: {
    anotherPharmacy: false,
  },
  [PrevisitForms.PHARMACY_SELECTION]: {
    pharmacyName: "",
  },
  [PrevisitForms.PHARMACY_ADDRESS_CONFIRMATION]: {
    address: {
      line1: "",
      line2: "",
      city: "",
      state: StateCode.AK,
      postalCode: "",
    },
  },
  [PrevisitForms.TELEHEALTH_INSURANCE]: {
    hasInsurance: false,
  },
  [PrevisitForms.INSURANCE_DETAIL]: {
    insuranceProvider: "",
    subscriberId: "",
    subscriberSuffix: "",
    isPrimarySubscriber: "YES",
    primarySubscriberFirstName: "",
    primarySubscriberLastName: "",
    month: "",
    day: "",
    year: "",
    relationship: "",
  },
};

const AmwellPrevisitProviderContext = createContext<Context>({
  handleSubmit: () => undefined,
  resetForm: () => undefined,
  formValues: INITIAL_STATE,
  consumer: undefined,
  setConsumer: () => undefined,
  practice: undefined,
  setupPractice: () => undefined,
  provider: undefined,
  setProvider: () => undefined,
  createVisit: async () => true,
  visitContext: undefined,
  setVisitContext: () => undefined,
  visit: undefined,
  setVisit: () => undefined,
  sdk: undefined,
  rejoin: false,
  setRejoin: () => undefined,
  recoverPrevisit: () => undefined,
});

const AmwellPrevisitProvider = (props: {
  children: React.ReactNode;
  handleError: () => void;
}): JSX.Element => {
  const { consumer: amwellConsumer, sdk, isReady: sdkReady, activeVisit } = useAmwell();

  const [state, setCurrentState] = useState(INITIAL_STATE);
  const [consumer, setConsumer] = useState<awsdk.AWSDKConsumer | undefined>();
  const [practice, setPractice] = useState<awsdk.AWSDKPractice | undefined>();
  const [provider, setProvider] = useState<awsdk.AWSDKProvider | undefined>();

  const [visitContext, setVisitContext] = useState<awsdk.AWSDKVisitContext | undefined>();
  const [visit, setVisit] = useState<awsdk.AWSDKVisit | undefined>();
  const [rejoin, setRejoin] = useState(false);

  const { t, ready } = useTranslation("telehealth-previsit");
  const { showToast } = useToastContext();

  const previsitJsonString = localStorage.getItem(PrevisitStorageKey);

  useEffect(() => {
    if (activeVisit) {
      setConsumer(amwellConsumer);
      setProvider(activeVisit.assignedProvider);
      setVisit(activeVisit);
      setRejoin(true);
    }
  }, [activeVisit]);

  useEffect(() => {
    if (amwellConsumer) {
      setConsumer(amwellConsumer);
      setupPractice();
    }
  }, [amwellConsumer]);

  const setupPractice = async () => {
    if (amwellConsumer && sdk) {
      const practices = await sdk.practiceService.getPractices(amwellConsumer);
      setPractice(practices.practices[0]);
    }
  };

  const createVisit = async (
    providerOnDemand: awsdk.AWSDKProvider | awsdk.AWSDKOnDemandSpecialty,
    isProvider: boolean,
  ) => {
    try {
      if (sdkReady && sdk && amwellConsumer) {
        let _context = await sdk.visitService.getVisitContext(amwellConsumer, providerOnDemand);
        _context.modalityType = awsdk.AWSDKVisitModalityType.VIDEO;

        let _visit = undefined;
        if (!isProvider) {
          //Find first available provider context
          const firstAvailVisit = (await sdk.visitService.findFirstAvailable(
            _context,
          )) as awsdk.AWSDKVisit;

          //Rebuild the visit context given the first available provider
          _context = await sdk.visitService.getVisitContext(
            amwellConsumer,
            firstAvailVisit.firstAvailableData.provider,
          );
          _context.modalityType = awsdk.AWSDKVisitModalityType.VIDEO;
        }

        _visit = await sdk.visitService.createOrUpdateVisit(_context);

        setVisitContext(_context);
        setVisit(_visit);
        setProvider(_visit.assignedProvider);

        return true;
      }
    } catch (e: any) {
      showToast({
        icon: "alert",
        message: t("startVisitError"),
        type: "error",
      });
      console.warn(e);
    }
    return false;
  };

  const recoverPrevisit = (rejoin = false) => {
    try {
      if (previsitJsonString && ready) {
        const backupState = JSON.parse(previsitJsonString);

        // If the user is not rejoining an active visit in progress,
        // then we want to inform them that we recovered any prior
        // data entered during the last previsit flow
        if (!rejoin) {
          showToast({
            icon: "checkmark",
            title: t("recoveryTitle"),
            message: t("recoveryMessage"),
            ttl: 2500,
          });
        }
        setCurrentState(merge(state, backupState));

        if (visitContext) {
          visitContext.callbackNumber = state.TelehealthCallbackNumber.phone;
        }
      }
    } catch (e: any) {
      localStorage.removeItem(PrevisitStorageKey);
      console.warn("Parsing data error");
    }
  };

  const resetForm = () => {
    setCurrentState(INITIAL_STATE);
    localStorage.removeItem(PrevisitStorageKey);
  };

  const handleSubmit = (formName: string, values: PropValueTypes) => {
    const newState = {
      ...state,
      [formName]: values,
    };
    setCurrentState(newState);
    localStorage.setItem(PrevisitStorageKey, JSON.stringify(newState));
  };

  return (
    <AmwellPrevisitProviderContext.Provider
      value={{
        consumer,
        setConsumer,
        practice,
        provider,
        setProvider,
        formValues: state,
        resetForm,
        handleSubmit,
        createVisit,
        visitContext,
        setVisitContext,
        setupPractice,
        visit,
        setVisit: (updateVisit: awsdk.AWSDKVisit | undefined) => {
          if (updateVisit && visit && visit.guestInvitationEmails)
            //maintain the guest invitiation emails
            updateVisit.guestInvitationEmails = visit.guestInvitationEmails || [];
          setVisit(updateVisit);
        },
        sdk,
        rejoin,
        setRejoin: (rejoinValue: boolean) => setRejoin(rejoinValue),
        recoverPrevisit,
      }}
    >
      {props.children}
    </AmwellPrevisitProviderContext.Provider>
  );
};

export const useAmwellPrevisitContext = () => useContext(AmwellPrevisitProviderContext);
export default AmwellPrevisitProvider;
