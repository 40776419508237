import React from "react";
import { useLocation } from "react-router";

import { Button } from "/component/base";
import Alert from "/component/base/Alert";
import { useRouterDispatch } from "/component/provider/RedirectListenerProvider";
import routes from "/constant/url.constant";
import { useTranslation } from "/hook";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  logEvent,
} from "/util/firebase.util";

import { Props } from "./CustomPrompt.types";

const CustomPrompt = ({ setConfirm, confirmCallback }: Props) => {
  const { t } = useTranslation("partial-customPrompt");
  const { dispatch } = useRouterDispatch();
  const location = useLocation();

  const logButtonClickEvent = (buttonName: string) => {
    let source = "";
    if (location.pathname.includes(routes.decisionSupport)) {
      source = AnalyticsSource.DECISION_SUPPORT_FORM;
    } else if (location.pathname.includes(routes.availabilitiesSelected)) {
      source = AnalyticsSource.PROVIDER_AVAILABILITIES;
    } else if (
      location.pathname.includes(routes.availabilities) &&
      !location.pathname.includes(routes.availabilitiesSelected)
    ) {
      source = AnalyticsSource.PROVIDER_AVAILABILITY_SEARCH;
    }

    if (source) {
      logEvent(AnalyticsEvent.BUTTON_CLICK, {
        user_flow: AnalyticsUserFlow.SCHEDULE_VISIT,
        source: source,
        button_name: buttonName,
      } as ButtonClickParams);
    }
  };

  const allowTransition = () => {
    logButtonClickEvent("Yes, exit flow");
    setConfirm(false);
    confirmCallback(true);
    dispatch(true);
  };

  const blockTransition = () => {
    logButtonClickEvent("No, I'll stay");
    setConfirm(false);
    confirmCallback(false);
    dispatch(false);
  };

  return (
    <Alert
      close={() => undefined}
      footer={
        <>
          <Button fullWidth="flex" onClick={allowTransition}>
            {t("confirmLabel")}
          </Button>
          <Button variant="secondary" fullWidth="flex" onClick={blockTransition}>
            {t("cancelLabel")}
          </Button>
        </>
      }
      title={t("title")}
      isOpen={true}
    >
      {t("subtitle")}
    </Alert>
  );
};

export default CustomPrompt;
