import qs from "qs";
import React from "react";
import { useHistory } from "react-router";

import { Button, Link } from "/component/base";
import ProviderDetailsModalBase from "/component/modal/ProviderDetailsModal/ProviderDetailsModalBase";
import routes from "/constant/url.constant";
import { useTranslation } from "/hook";
import { layout } from "/styles";
import { formatText, languages } from "/util";

import { Props } from "./SchedulingProviderDetailsModal.types";
import useSchedulingProviderDetailsModal from "./useSchedulingProviderDetailsModal";

const callCenterName = "summitCallCenter";

const SchedulingProviderDetailsModal = ({
  hideCta = false,
  goBackOnCtaClick,
  close,
  isOpen,
  providerId,
}: Props) => {
  const { t } = useTranslation("modal-schedulingProviderDetails");

  const { data, loading } = useSchedulingProviderDetailsModal(providerId, !isOpen);
  const history = useHistory();

  const provider = data?.provider;

  const callCenterContact = data?.contactInfo?.find(({ name }) => name === callCenterName);

  // Depending on the provider's state we will either link the user to book through decision support,
  // or we will provider a `tel:` link that the user can call to book
  const getCta = () => {
    if (hideCta) return null;
    // If the user clicks on a provider after completing decision support, clicking the CTA
    // should close the modal, since the provider they clicked on has availability options
    if (goBackOnCtaClick) {
      return (
        <Button
          variant="secondary"
          onClick={close}
          aria-label={t("cta.ariaLabel.goBack")}
          css={layout.paddingHorizontal(40)}
        >
          {t("cta.viewAvailability")}
        </Button>
      );
    }

    // Start decision support, with some initial params, if the provider has an experianFlowId
    if (provider?.experianFlowId) {
      const handleViewAvailability = () => {
        const to = `${routes.scheduling}${routes.decisionSupport}/${provider.experianFlowId}`;
        const search = qs.stringify({
          athenaId: provider.athenaId,
        });
        history.push(`${to}?${search}`);
      };

      return (
        <Button
          variant="secondary"
          onClick={handleViewAvailability}
          css={layout.paddingHorizontal(40)}
        >
          {t("cta.viewAvailability")}
        </Button>
      );
    }

    // If the provider cannot be booked through decision support, link to call to manually book an appointment
    if (callCenterContact) {
      return (
        // TODO: Cleanup Link.Button when we need it to behave like a plain Anchor.
        <Link.Button as="a" variant="secondary" href={`tel:${callCenterContact.phoneNumber}`} to="">
          {t("cta.callToBook")}
        </Link.Button>
      );
    }

    return null;
  };

  // ================================================
  // Build the qualifications array
  // ================================================
  const qualifications: { label: string; values: string[] }[] = [];
  provider?.qualifications?.forEach((qualification) => {
    if (qualification?.type && qualification.description) {
      qualifications.push({
        label: qualification.type,
        values: qualification.description.split("\n"),
      });
    }
  });

  const providerLanguages = provider?.languages?.map(languages.getLanguageName);

  return (
    <ProviderDetailsModalBase
      close={close}
      loading={loading}
      isOpen={isOpen}
      primarySpecialty={provider?.primarySpecialty}
      nameWithDesignations={provider?.nameWithDesignations}
      avatarUrl={provider?.photoUrl}
      initials={formatText.parseInitials(provider?.name)}
      cta={getCta()}
      bio={provider?.bio}
      qualifications={qualifications || []}
      languages={providerLanguages || []}
    />
  );
};

export default SchedulingProviderDetailsModal;
