import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Trans } from "react-i18next";

import { AdministrativeGender } from "/apollo/schema/types";
import { Link, Loading } from "/component/base";
import List from "/component/base/List";
import Text from "/component/base/Text";
import MainContent from "/component/layout/MainContent";
import ProfileAddressModal from "/component/modal/ProfileAddressModal";
import { useCaregiverProvider } from "/component/provider/CaregiverProvider";
import { useTranslation } from "/hook";
import { useAnalytics } from "/hook/useAnalytics/useAnalytics";
import { layout } from "/styles";
import { date, formatText } from "/util";
import { reactPlugin } from "/util/appInsights.util";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  logEvent,
} from "/util/firebase.util";

import {
  AddressesSubtitle,
  AddressText,
  InfoListItem,
  ListContainer,
  PersonalInformationSubtitle,
} from "./PersonalInformation.styles";
import { buildAddressValue } from "./PersonalInformation.utils";
import usePersonalInformationQuery from "./usePersonalInformationQuery";

const PersonalInformation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { data, loading } = usePersonalInformationQuery();
  const { t } = useTranslation("personalInformation");
  const { recordAnalyticsEvent } = useAnalytics();
  const callCenter = data?.contactInfo?.find(({ name }) => name === "summitCallCenter");
  const patient = data?.viewer?.user.patient;
  const { caregiverMode, caregiver } = useCaregiverProvider();

  useEffect(() => {
    recordAnalyticsEvent("viewProfile", { sharedModule: false });
  }, [recordAnalyticsEvent]);

  const getStringForLegalSex = (legalSex: AdministrativeGender | null) => {
    switch (legalSex) {
      case "MALE":
        return t("sexMale", "Male");
      case "FEMALE":
        return t("sexFemale", "Female");
      case "OTHER":
        return t("sexOther", "Other");
      case "UNKNOWN":
      default:
        return t("sexUnknown", "Unknown");
    }
  };

  const logButtonClickEvent = (buttonName: string) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.MY_ACCOUNT,
      source: AnalyticsSource.PERSONAL_INFO,
      button_name: buttonName,
    } as ButtonClickParams);
  };
  const EditHome = () => {
    setIsOpen(true);
    logButtonClickEvent("Edit home address");
  };

  if (loading) {
    return (
      <MainContent parentPage="account" pageTitle={t("title")}>
        <Helmet>
          <title>{t("title")}</title>
        </Helmet>

        <Loading />
      </MainContent>
    );
  }

  if (patient) {
    const birthDate = date.removeOffset(patient.birthDate);
    const details = [
      { label: t("firstName"), value: patient?.name?.firstName },
      { label: t("lastName"), value: patient?.name?.lastName },
      { label: t("legalSex"), value: getStringForLegalSex(patient.gender) },
      {
        label: t("dateOfBirth"),
        value: birthDate && date.formatDate(birthDate, "MMMM d, yyyy"),
      },
    ];

    // Only displaying the user's home address for now. This could be
    // updated in the future to show multiple user addresses.
    const addresses = [{ label: t("home") }].map(({ label }) => ({
      label,
      stringValue: buildAddressValue(patient?.homeAddress),
    }));

    return (
      <MainContent parentPage="account" pageTitle={t("title")}>
        <Helmet>
          <title>{t("title")}</title>
        </Helmet>

        <PersonalInformationSubtitle color="textSecondary">
          <Trans
            components={{
              anchor: (
                <Link.Anchor
                  href={`tel:${callCenter?.phoneNumber}`}
                  variant="secondary"
                  onClick={() => {
                    logButtonClickEvent("Call center");
                  }}
                />
              ),
            }}
            i18nKey="subtitle"
            t={t}
            tOptions={{ context: callCenter?.phoneNumber ? "hasNumber" : undefined }}
            values={{ phoneNumber: formatText.parsePhone(callCenter?.phoneNumber) }}
          />
        </PersonalInformationSubtitle>
        <ListContainer>
          <List
            as="Divs"
            data={details}
            showChevron={false}
            hideLastChildBorder
            renderItem={({ label, value }) => (
              <InfoListItem>
                <Text>{label}</Text>
                <Text color="textSecondary">{value}</Text>
              </InfoListItem>
            )}
          />
        </ListContainer>
        <AddressesSubtitle variant="body1Bold" color="textSecondary">
          {t("addresses")}
        </AddressesSubtitle>
        <ListContainer>
          <List
            as="Buttons"
            data={addresses}
            showChevron={caregiverMode && !caregiver.isMinor ? false : true}
            onClick={() => (caregiverMode && !caregiver.isMinor ? null : EditHome())}
            hideLastChildBorder
            renderItem={({ label, stringValue }) => (
              <InfoListItem css={layout.margin("skip", "condensed", "skip", "skip")}>
                <Text>{label}</Text>
                <AddressText color="textSecondary">{stringValue}</AddressText>
              </InfoListItem>
            )}
          />
        </ListContainer>
        <ProfileAddressModal
          isOpen={isOpen}
          close={() => setIsOpen(false)}
          initialAddress={patient.homeAddress}
        />
      </MainContent>
    );
  }

  // TODO: Handle errors
  return <Text>{t("personalInfoError")}</Text>;
};

export default withAITracking(reactPlugin, PersonalInformation, undefined, "ai-tracking");
