import { useReactiveVar } from "@apollo/client";
import React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";

import { auth } from "/apollo/client/local";
import { hasFeatureFlagEnabled, hasFeatureFlagLegacy } from "/util/featureFlags/featureFlags";

import AccountIndex from "./page/AccountIndex";
import AthenaBilling from "./page/AthenaBilling";
import Caregiver from "./page/CaregiverIndex";
import CaregiverDeprecated from "./page/CaregiverIndex/CaregiverDeprecated";
import CaregiverDetails from "./page/CaregiverIndex/page/CaregiverDetails";
import CaregiverNotificationPreference from "./page/CaregiverNotificationPreference";
import Feedback from "./page/Feedback";
import Insurance from "./page/Insurance";
import InsuranceB2C from "./page/InsuranceB2C";
import Message from "./page/Message";
import Messages from "./page/Messages";
import NotificationBillingPreference from "./page/NotificationBillingPreference";
import NotificationPreference from "./page/NotificationPreference";
import Notifications from "./page/Notifications";
import PersonalInformation from "./page/PersonalInformation";
import PersonalInformationB2C from "./page/PersonalInformationB2C";
import Settings from "./page/Settings";
const ENABLE_CAREGIVER_PROXY = process.env.ENABLE_CAREGIVER_PROXY === "true";

const AccountRoutes = ({ match }: RouteComponentProps) => {
  const { isAuthenticated } = useReactiveVar(auth.var);
  return (
    <Switch>
      <Route exact path={match.url} component={AccountIndex} />
      {isAuthenticated ? (
        <Switch>
          {hasFeatureFlagEnabled("personal_info_enabled") && (
            <Route
              exact
              path={`${match.url}/personal-information`}
              component={PersonalInformationB2C}
            />
          )}
          {hasFeatureFlagLegacy("personal_info_enabled") && (
            <Route
              exact
              path={`${match.url}/personal-information`}
              component={PersonalInformation}
            />
          )}
          {hasFeatureFlagEnabled("insurance_enabled") && (
            <Route exact path={`${match.url}/insurance`} component={InsuranceB2C} />
          )}
          {hasFeatureFlagLegacy("insurance_enabled") && (
            <Route exact path={`${match.url}/insurance`} component={Insurance} />
          )}
          {process.env.FEATURE_ENABLE_ATHENA_BILLING === "true" && (
            <Route path={`${match.url}/billing-accounts`} component={AthenaBilling} />
          )}
          <Route exact path={`${match.url}/messages`} component={Messages} />
          <Route exact path={`${match.url}/messages/sent`} component={Messages} />
          <Route exact path={`${match.url}/messages/:id`} sensitive component={Message} />
          <Route exact path={`${match.url}/messages/sent/:id`} sensitive component={Message} />
          <Route exact path={`${match.url}/settings`} component={Settings} />
          <Route exact path={`${match.url}/notifications`} component={Notifications} />
          <Route
            exact
            path={`${match.url}/notifications/billing`}
            component={NotificationBillingPreference}
          />
          <Route
            exact
            path={`${match.url}/notifications/caregivers`}
            component={CaregiverNotificationPreference}
          />
          <Route
            exact
            path={`${match.url}/notifications/:category`}
            component={NotificationPreference}
          />

          {process.env.FEATURE_ENABLE_FEEDBACK === "true" && (
            <Route exact path={`${match.url}/feedback`} component={Feedback} />
          )}
          <Switch>
            <Route
              exact
              path={`${match.url}/caregiver`}
              component={ENABLE_CAREGIVER_PROXY ? Caregiver : CaregiverDeprecated}
            />
            <Route exact path={`${match.url}/caregiver/details`} component={CaregiverDetails} />
          </Switch>
        </Switch>
      ) : (
        <Redirect to={`${match.url}`} />
      )}
    </Switch>
  );
};

export default AccountRoutes;
