// Modules
import React from "react";

// Styles
import { CheckWrapper, Icon, Input, InputWrapper, Label, LabelWrapper } from "./CheckBox.styles";
import { Props } from "./CheckBox.types";

const CheckBox = ({
  label: labelProp,
  labelBefore = true,
  type = "checkbox",
  className,
  ...rest
}: Props) => {
  const checkbox = (
    <InputWrapper>
      <Input {...rest} type={type} />
      <CheckWrapper>
        <Icon size={18} />
      </CheckWrapper>
    </InputWrapper>
  );

  if (!labelProp) {
    return checkbox;
  }

  const label = <LabelWrapper>{labelProp}</LabelWrapper>;

  return (
    <Label className={className}>
      {!!labelBefore && label}
      {checkbox}
      {!labelBefore && label}
    </Label>
  );
};

export default CheckBox;
