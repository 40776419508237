import { useMutation } from "@apollo/client";
import React from "react";

import updateHomeAddress from "/apollo/mutation/updateHomeAddress";
import { StateCode } from "/apollo/schema/types";
import { HandleAddressFormSubmitType } from "/component/form/AddressForm/AddressForm.types";

const useProfileAddressModal = (
  close: () => void,
  setServerError: React.Dispatch<React.SetStateAction<string>>,
) => {
  const [updateAddress] = useMutation(updateHomeAddress);

  const submitAddress: HandleAddressFormSubmitType = async ({
    address: { line1, line2, city, state, postalCode },
  }) => {
    try {
      await updateAddress({
        variables: {
          input: {
            line1,
            line2,
            city,
            state: state as StateCode,
            postalCode,
          },
        },
      });
      // TODO: display success alert
      close();
    } catch (err: any) {
      setServerError(err.message);
    }
  };

  return { submitAddress };
};

export default useProfileAddressModal;
