import React from "react";

import Modal from "/component/base/Modal";
import Text from "/component/base/Text";
import { useTranslation } from "/hook";
import { layout } from "/styles";

import { Props } from "./AthenaBillingLearnMoreModal.types";

const AddressModal = ({ close, isOpen, dismissable = true }: Props) => {
  const { t } = useTranslation("modal-AthenaBillingLearnMore");

  return (
    <Modal
      key="AthenaBillingLearnMoreModal"
      header={
        <Modal.Header title={t("modal.header")} hideHeaderLeft={!dismissable} close={close} />
      }
      isOpen={isOpen}
      close={dismissable ? close : undefined}
    >
      <div css={layout.flexVertical}>
        <Text>{t("header")}</Text>
        <Text css={layout.marginVertical("gutter")}>{t("firstParagraph")}</Text>
        <Text>{t("secondParagraph")}</Text>
      </div>
    </Modal>
  );
};

export default AddressModal;
