import { Popover } from "@headlessui/react";
import styled from "styled-components";

import { Colors } from "/theme/default";

import { CrossFilled } from "../Icon";
import { PopoverPanelProps, TooltipVariant } from "./Tooltip.types";

export const closeButtonSize = 18;
export const borderRadius = 16;

// ====================================
// Style-specific types
// ====================================

type VariantStyles = Record<
  TooltipVariant,
  { backgroundColor: Colors; textColor: Colors; iconColor: string }
>;

type ArrowProps = { variant: TooltipVariant; isSmall: boolean; placement: string };
type BaseProps = { variant: TooltipVariant; hasCloseButton: boolean };
type CloseProps = { variant: TooltipVariant };

const variants: VariantStyles = {
  primary: {
    backgroundColor: "brandPrimary",
    textColor: "textInverse",
    iconColor: "rgba(255,255,255,0.6)",
  },
  secondary: {
    backgroundColor: "statusWarning",
    textColor: "textPrimary",
    iconColor: "objectSecondary",
  },
};

export const Base = styled.div<BaseProps>(({ theme, variant, hasCloseButton }) => {
  const background = theme.colors[variants[variant].backgroundColor];
  const color = theme.colors[variants[variant].textColor];

  return {
    backgroundColor: background,
    color,
    borderRadius,
    paddingTop: theme.spacing.condensed,
    paddingBottom: theme.spacing.condensed,
    paddingLeft: theme.spacing.base * 3,
    paddingRight: hasCloseButton
      ? closeButtonSize + theme.spacing.standard
      : theme.spacing.condensed,
    maxWidth: 236,
  };
});

export const Arrow = styled.div<ArrowProps>(({ theme, isSmall, variant, placement }) => {
  const background = theme.colors[variants[variant].backgroundColor];

  // We are using a CSS triangle to render the arrow. Depending on the border styles it will
  // change which direction the arrow is pointing. We position the arrow outside of the box using
  // the negative positioning values.
  let placementStyles = {};

  // Match the **start** of the string to ensure our arrow goes in the correct spot. For example
  // both placement = top and top-start should have the arrow pointing downwards.
  if (placement.match(/^top/)) {
    placementStyles = {
      borderColor: `${background} transparent transparent transparent`,
      borderWidth: "11px 11px 0 11px",
      bottom: -11,
    };
  } else if (placement.match(/^right/)) {
    placementStyles = {
      borderColor: `transparent ${background} transparent transparent`,
      borderWidth: "11px 11px 11px 0",
      // For left/right tooltips we want to shift the arrow into the tooltip a bit. This is because
      // the tooltip has rounded borders and the arrow has a flat edge so it can't sit flush to
      // the tooltip if the tooltip is too short.
      left: isSmall ? -8 : -11,
    };
  } else if (placement.match(/^left/)) {
    placementStyles = {
      borderColor: `transparent transparent transparent ${background}`,
      borderWidth: "11px 0 11px 11px",
      right: isSmall ? -8 : -11,
    };
  } else if (placement.match(/^bottom/)) {
    placementStyles = {
      borderColor: `transparent transparent ${background} transparent`,
      borderWidth: "0 11px 11px 11px",
      top: -11,
    };
  }

  return {
    width: 0,
    height: 0,
    borderStyle: "solid",

    ...placementStyles,
  };
});

export const CloseWrapper = styled(Popover.Button)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing.condensed,
  right: theme.spacing.condensed,
}));

export const CloseIcon = styled(CrossFilled)<CloseProps>(({ theme, variant }) => {
  const colorValue = variants[variant].iconColor;

  const color = theme.colors[colorValue] ?? colorValue;

  return { color };
});

// ====================================
// HeadlessUI Styled Wrappers
// ====================================

// HeadlessUI + StyledComponents + TypeScript do not play well with eachother, and I can't figure out
// how to get the proptypes from HeadlessUI to pass them to our StyledComponent.

export const PopoverButton = styled(Popover.Button)({});
export const PopoverPanel = styled(Popover.Panel)<PopoverPanelProps>({
  // Hide the tooltip if the anchor scrolls off screen
  "&[data-popper-reference-hidden='true']": {
    visibility: "hidden",
    pointerEvents: "none",
  },
});

// We don't need to do any fancy typing with PopverWrapper since it doesn't accept any custom props
export const PopoverWrapper = styled(Popover)<{ $showOnHover: boolean }>(({ $showOnHover }) => {
  if ($showOnHover) {
    return {
      [`& ${PopoverPanel}`]: {
        visibility: "hidden",
      },
      [`& ${PopoverButton}:hover + ${PopoverPanel}`]: {
        visibility: "visible",
      },
    };
  }

  return {};
});
