// Modules
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React from "react";
import { Helmet } from "react-helmet-async";
import { RouteComponentProps } from "react-router";

import { Text } from "/component/base";
import Accordion from "/component/base/Accordion";
import { Phone } from "/component/base/Icon";
import MainContent from "/component/layout/MainContent";
import CtaCard from "/component/partial/CtaCard";
import routes from "/constant/url.constant";
import { useTranslation } from "/hook";
import { layout } from "/styles";
import { reactPlugin } from "/util/appInsights.util";

import useFaqQuery from "../useFaqQuery";
import { CardContainer, FaqCategoryTitle } from "./FaqCategory.styles";
import { MatchParams } from "./FaqCategory.types";

const FaqCategory = ({ match }: RouteComponentProps<MatchParams>) => {
  const { t } = useTranslation("faqIndex");
  const { id } = match.params;
  const { data } = useFaqQuery();

  const faqCategory = data?.faqCategories.find((category) => category.id.toString() === id);
  const title = faqCategory?.title;

  /**
   * Filter out all the data by category and then set the "to"
   * attribute so that the List of Links knows what happens when
   * the item is clicked on
   **/
  const faqContents = data?.faqContent
    .filter((content) => content.categories?.find((category) => category.id.toString() === id))
    .map((faq) => ({
      ...faq,
      to: `${routes.faq}${routes.faqDetail}/${faq.id}`,
    }));

  const ctaCardLinks: { title: string; number: string; href: string }[] = [];
  if (faqCategory?.ctaContact && faqCategory.ctaName) {
    ctaCardLinks.push({
      title: faqCategory.ctaName,
      number: faqCategory.ctaContact,
      href: `tel:${faqCategory.ctaContact.replace(/-/g, "")}`,
    });
  }
  if (faqCategory?.ctaSecondaryContact && faqCategory.ctaSecondaryName) {
    ctaCardLinks.push({
      title: faqCategory.ctaSecondaryName,
      number: faqCategory.ctaSecondaryContact,
      href: `tel:${faqCategory.ctaSecondaryContact.replace(/-/g, "")}`,
    });
  }
  const accordionSections = faqContents?.map((item) => ({
    title: item.question,
    content: item.body,
  }));
  return (
    <MainContent pageTitle={title || t("headline")} parentPage="faq">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <FaqCategoryTitle variant="body2Bold" color="textSecondary">
        {t("category.faq")}
      </FaqCategoryTitle>

      {accordionSections && (
        <CardContainer>
          <Accordion accordionSections={accordionSections} />
        </CardContainer>
      )}

      {faqCategory && faqCategory.ctaHeader && faqCategory.ctaSubHeader && ctaCardLinks.length && (
        <CtaCard
          css={layout.margin("expanded", "none", "none")}
          title={faqCategory.ctaHeader}
          subtitle={
            <>
              <Text
                color="textSecondary"
                css={layout.margin("skip", "skip", "standard")}
                element="p"
              >
                {faqCategory.ctaSubHeader}
              </Text>

              <ul css={layout.spacedChildrenVertical("standard")}>
                {ctaCardLinks.map((cardLink) => (
                  <li css={[layout.flexVertical, layout.spacedChildrenVertical("condensed")]}>
                    <Text variant="title3">{cardLink.title}</Text>

                    <a
                      css={[
                        layout.flexCenterHorizontal,
                        layout.spacedChildrenHorizontal("condensed"),
                      ]}
                      href={cardLink.href}
                    >
                      <Phone color="brandSecondary" size={16} />

                      <Text
                        variant="body1Bold"
                        color="brandPrimary"
                        css={{ textDecoration: "underline" }}
                      >
                        {cardLink.number}
                      </Text>
                    </a>
                  </li>
                ))}
              </ul>
            </>
          }
        />
      )}
    </MainContent>
  );
};

export default withAITracking(reactPlugin, FaqCategory, undefined, "ai-tracking");
