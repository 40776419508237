// Modules
import InputMask from "react-input-mask";
import styled from "styled-components";

import { layout } from "/styles";

import { Props } from "./MaskedInput.types";

export const Input = styled(InputMask)<Props<string>>(layout.padding("none"), ({ theme }) => ({
  fontFamily: theme.fontVariants.body1,
  fontSize: theme.fontSizes.body1,
  lineHeight: theme.lineHeights.body1,
  color: theme.colors.textPrimary,
  alignSelf: "stretch",
  flex: 1,

  // Resets
  backgroundColor: "transparent",
  border: 0,
  outline: "none",

  // Add padding to the before/after elements if they exist
  "* + &": {
    marginLeft: theme.spacing.condensed,
  },

  "& + *": {
    marginLeft: theme.spacing.condensed,
  },
}));

export const Wrapper = styled.div<Omit<Props<string>, "mask"> & { hasFocus: boolean }>(
  layout.padding("none", "standard"),
  ({ hasFocus, theme, hasError }) => {
    let backgroundColor: string = theme.colors.brandPrimaryLight;
    let borderColor = "transparent";

    if (hasError) {
      borderColor = theme.colors.brandSecondary;
      backgroundColor = theme.colors.brandSecondaryLight;
    } else if (hasFocus) {
      borderColor = theme.colors.objectOverlay;
    }

    return {
      alignItems: "center",
      backgroundColor,
      border: `solid 1.5px ${borderColor}`,
      borderRadius: 30,
      display: "flex",
      height: 48,
      overflow: "hidden",
    };
  },
);
