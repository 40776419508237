import awsdk from "awsdk";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import Button from "/component/base/Button";
import CheckBox from "/component/base/CheckBox";
import List from "/component/base/List";
import Loading from "/component/base/Loading";
import Text from "/component/base/Text";
import FormContent from "/component/partial/FormContent";
import {
  DiagnosedConditionProps,
  useAmwellPrevisitContext,
} from "/component/provider/AmwellPrevisitProvider";
import { useTranslation } from "/hook";
import useAmwellConditions from "/hook/useAmwellConditions";
import { layout } from "/styles";

import { ButtonContainer, SkipButton, Title } from "../Telehealth.styles";
import { TelehealthFormProps } from "../Telehealth.types";

const DiagnosedConditions = ({
  handleError,
  handleSubmit,
  initialValues,
}: TelehealthFormProps<DiagnosedConditionProps>) => {
  const { t } = useTranslation("form-telehealth-previsit");

  const { data, loading } = useAmwellConditions();
  const { consumer, sdk, setConsumer } = useAmwellPrevisitContext();
  const [selectedConditions, setSelectedConditions] = useState<Record<number, boolean>>({});

  const conditionsSchema = Yup.object({
    conditions: Yup.object(),
  });

  useEffect(() => {
    if (!loading && data && data.length > 0) {
      const currentConditions = data.reduce((conditionObj, condition) => {
        conditionObj[condition.termId] = condition.isCurrent;
        return conditionObj;
      }, initialValues.conditions);

      setSelectedConditions(currentConditions);
    }
  }, [data, loading, initialValues]);

  const submitForm = async (values: DiagnosedConditionProps) => {
    try {
      if (consumer && sdk && data) {
        const conditions = data.map((condition) => {
          condition.isCurrent = values.conditions[condition.termId];
          return condition;
        });
        const consumerConditions = await sdk.consumerService.updateConditions(consumer, conditions);
        setConsumer(consumerConditions);
        handleSubmit(values);
      }
    } catch (e: any) {
      handleError(e);
    }
  };

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={{ conditions: selectedConditions }}
      validationSchema={conditionsSchema}
      onSubmit={submitForm}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form>
          <FormContent css={layout.margin("gutter", "skip")}>
            <Title>{t("diagnosedConditions.title")}</Title>
            {loading ? (
              <Loading />
            ) : (
              <>
                <div css={layout.margin(40, "skip", 40)}>
                  <List
                    as="Divs"
                    showChevron={false}
                    showBorder={true}
                    hideLastChildBorder={true}
                    data={data || []}
                    renderItem={(item: awsdk.AWSDKCondition) => {
                      const isChecked = values.conditions[item.termId] || false;
                      return (
                        <CheckBox
                          css={[layout.paddingVertical("standard"), { flex: 1 }]}
                          labelBefore={false}
                          checked={isChecked}
                          onChange={(e) => {
                            setFieldValue("conditions", {
                              ...values.conditions,
                              [item.termId]: e.currentTarget.checked,
                            });
                          }}
                          label={<Text variant="body1Bold">{item.displayName}</Text>}
                        />
                      );
                    }}
                  />
                </div>
                <ButtonContainer>
                  <Button
                    css={layout.flexItemAuto}
                    variant="primary"
                    type="submit"
                    isLoading={isSubmitting}
                  >
                    {t("continue")}
                  </Button>
                  <SkipButton onClick={() => handleSubmit()} variant="borderBottom">
                    {t("skip")}
                  </SkipButton>
                </ButtonContainer>
              </>
            )}
          </FormContent>
        </Form>
      )}
    </Formik>
  );
};

export default DiagnosedConditions;
