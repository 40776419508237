import { useReactiveVar } from "@apollo/client";
import React, { useEffect } from "react";
import { useHistory } from "react-router";

import { auth } from "/apollo/client/local";
import { Loading, ProgressBar, Text } from "/component/base";
import { useToastContext } from "/component/provider/ToastProvider";
import { SignUpFormType } from "/constant/signUp.constant";
import routes from "/constant/url.constant";
import { useAuth, useTranslation } from "/hook";
import { layout } from "/styles";
import { AnalyticsEvent, logEvent, ScreenViewParams } from "/util/firebase.util";

import {
  AccountCreated,
  CreateAccount,
  EmailVerification,
  PhoneVerification,
  VerificationStatus,
} from "../SignUpForms";
import { SignUpFormProps } from "../SignUpForms/SignUpForms.types";
import {
  ProgressBarContainer,
  SignInButton,
  SignInContainer,
  SignUpContainer,
} from "./SignUpWizard.styles";

interface SignUpFormsConfigProps {
  [SignUpForms: string]: {
    index: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    form: (props: SignUpFormProps<any>) => JSX.Element;
  };
}

export const SignUpFormsConfig = {
  [SignUpFormType.CreateAccount]: {
    index: 1,
    form: CreateAccount,
  },
  [SignUpFormType.AccountCreated]: {
    index: 2,
    form: AccountCreated,
  },
  [SignUpFormType.PhoneVerification]: {
    index: 3,
    form: PhoneVerification,
  },
  [SignUpFormType.EmailVerification]: {
    index: 4,
    form: EmailVerification,
  },
  [SignUpFormType.VerificationStatus]: {
    index: 5,
    form: VerificationStatus,
  },
};

interface Props {
  initialForm: SignUpFormType;
  completeWizard: () => void;
  formConfig?: SignUpFormsConfigProps;
  loginNoMFA: () => void;
  isAuth: boolean;
}

const SignUpWizard = ({
  initialForm,
  formConfig = SignUpFormsConfig,
  completeWizard,
  isAuth,
  loginNoMFA,
}: Props) => {
  const { isAuthenticated } = useReactiveVar(auth.var);
  const { login, logout } = useAuth();
  const { t } = useTranslation("sign-up");
  const history = useHistory();
  const { showToast } = useToastContext();
  const [currentForm, setCurrentForm] = React.useState<SignUpFormType>(initialForm);
  const [screenLoading, setScreenLoading] = React.useState(true);
  const { index, form: SignupForm } = formConfig[currentForm];

  useEffect(() => {
    logEvent(AnalyticsEvent.SCREEN_VIEW, {
      screenName: currentForm,
    } as ScreenViewParams);
  }, [currentForm]);

  const handleError = (e: Error) => {
    showToast({
      icon: "alert",
      message: e.message,
      type: "error",
    });
  };

  const setForm = (newFormName: string) => {
    setCurrentForm(newFormName as SignUpFormType);
  };

  const formSubmit = (nextForm?: SignUpFormType) => {
    if (nextForm) setForm(nextForm);
    else completeWizard();
  };

  const signIn = async () => {
    if (!isAuthenticated) {
      // This will redirect.
      await login(`${window.location.origin}${routes.root}`);
    }
  };

  React.useEffect(() => {
    if (!currentForm) return;

    if (isAuth) {
      if (
        currentForm !== SignUpFormType.AccountCreated &&
        currentForm !== SignUpFormType.CreateAccount
      ) {
        setScreenLoading(false);
      } else {
        history.push(routes.root);
      }
    } else {
      if (
        currentForm !== SignUpFormType.AccountCreated &&
        currentForm !== SignUpFormType.CreateAccount
      ) {
        loginNoMFA();
      } else {
        setScreenLoading(false);
      }
    }
  }, [isAuth, currentForm]);

  const totalScreens = Object.keys(SignUpFormsConfig).length;

  return (
    <SignUpContainer>
      <div css={[layout.flexVerticalCenter, layout.spacedChildrenVertical("condensed")]}>
        {!isAuthenticated && (
          <ProgressBarContainer>
            <div css={{ display: "flex", justifyContent: "flex-end" }}>
              <SignInContainer>
                <Text variant="body1">{t("haveAccount")}</Text>
                <SignInButton onClick={signIn} variant="unstyled">
                  {t("signIn")}
                </SignInButton>
              </SignInContainer>
            </div>
          </ProgressBarContainer>
        )}
        <ProgressBarContainer>
          <ProgressBar
            close={async () => {
              if (isAuthenticated) await logout();
              history.replace("/home");
            }}
            icon="close"
            completedScreens={index}
            totalScreens={totalScreens}
          />
        </ProgressBarContainer>
      </div>
      <div
        css={[
          layout.flexVerticalCenter,
          layout.margin("expanded", "skip", "skip", "skip"),
          {
            width: "100%",
          },
        ]}
      >
        {screenLoading ? (
          <div css={{ position: "relative" }}>
            <Loading />
          </div>
        ) : (
          <SignupForm formName={currentForm} handleError={handleError} handleSubmit={formSubmit} />
        )}
      </div>
    </SignUpContainer>
  );
};

export default SignUpWizard;
