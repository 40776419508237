import { useMutation } from "@apollo/client";
import React from "react";

import resendVerificationGql from "/apollo/mutation/sendVerification";
import { SendVerification_sendVerification, SendVerificationVariables } from "/apollo/schema";
import { Alert } from "/component/base";
import Button from "/component/base/Button";
import { useToastContext } from "/component/provider/ToastProvider";
import { useTranslation } from "/hook";
import { layout } from "/styles";

import { Props } from "./EmailVerificationModal.types";

const EmailVerificationModal = ({ isOpen, email, patientName, close }: Props) => {
  const { t } = useTranslation("modal-emailVerification");
  const [resendVerification] = useMutation<
    SendVerification_sendVerification,
    SendVerificationVariables
  >(resendVerificationGql);
  const { showToast } = useToastContext();

  const resendEmail = async () => {
    await resendVerification({
      onCompleted: () => {
        showToast({
          icon: "checkmark",
          message: t("toast.emailSent"),
        });
      },
      onError: (e) => {
        console.log(e);
        // TODO toast?
      },
      variables: {
        name: patientName,
        type: "email",
        to: email,
      },
    });
  };

  return (
    <Alert
      title={t("header")}
      close={() => {
        if (close) {
          close();
        }
      }}
      isOpen={isOpen}
      footer={
        <div css={[layout.spacedChildrenVertical(), layout.flexVerticalCenter]}>
          <Button variant="primary" fullWidth="flex" onClick={() => resendEmail()}>
            {t("button.sendVerification")}
          </Button>
          <Button variant="borderBottom" onClick={close}>
            {t("button.doLater")}
          </Button>
        </div>
      }
    >
      {t("description")}
    </Alert>
  );
};

export default EmailVerificationModal;
