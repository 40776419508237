/*!
 * American Well Consumer Web SDK Sample App
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

import awsdk from "awsdk";
import React, { useState } from "react";

import ChatWindowComponent from "./chat/ChatWindowComponent";

interface Props {
  chatInputEnabled: boolean;
  chatReport: awsdk.AWSDKChatReport;
  handleSendMessageClick: (currentMessage: string, lastOrdinal: number) => void;
  sentMessage: awsdk.AWSDKChatItem | undefined;
}

const ChatContainer = ({
  handleSendMessageClick,
  chatReport,
  sentMessage,
  chatInputEnabled,
}: Props) => {
  const [currentMessage, setCurrentMessage] = useState<string>("");

  const _handleSendMessageClick = (e: KeyboardEvent) => {
    e.preventDefault();
    if (currentMessage) {
      handleSendMessageClick(currentMessage, chatReport.lastOrdinal);
      setCurrentMessage("");
    }
  };

  return (
    <ChatWindowComponent
      messages={[]}
      chatReport={chatReport}
      currentMessage={currentMessage}
      sentMessage={sentMessage}
      handleSendMessageClick={_handleSendMessageClick.bind(this)}
      updateCurrentMessage={(val: string) => setCurrentMessage(val)}
      chatInputEnabled={chatInputEnabled}
    />
  );
};

export default ChatContainer;
