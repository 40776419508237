import { gql } from "@apollo/client";

export default gql`
  mutation ConfirmRevokedCaregiverAccess($data: ConfirmRevokedCaregiverAccessInput!) {
    confirmRevokedCaregiverAccess(data: $data) {
      errors {
        code
        message
        type
      }
      status
    }
  }
`;
