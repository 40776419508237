import React from "react";

import { AppointmentStatus, getAppointments_patient_appointments } from "/b2c/schema/types";
import Spinner from "/component/base/Spinner";
import Text from "/component/base/Text";
import ContactUs from "/component/partial/ContactUs";
import HorizontalAppointmentList from "/component/partial/HorizontalAppointmentList";
import LoadingSkeleton from "/component/partial/LoadingSkeleton";
import { ContactUsWrapper } from "/component/partial/MainContentLoginCta/MainContentLoginCta.styles";
import SignUpSignInButton from "/component/partial/SignUpSignInButton";
import routes from "/constant/url.constant";
import { useTranslation } from "/hook";
import useAuth from "/hook/useAuth";
import { layout } from "/styles";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  IndentifierParams,
  logEvent,
} from "/util/firebase.util";

import {
  Base,
  Contact,
  Header,
  LoginButton,
  LoginWrapper,
  ViewAllButton,
  ViewAllLink,
} from "./UpcomingVisits.styles";
import { Props } from "./UpcomingVisits.types";

const SIGN_UP_V2_ENABLED = process.env.SIGN_UP_V2_ENABLED === "true";

const UpcomingVisits = ({ data, isLoading }: Props) => {
  const { t } = useTranslation("upcomingVisits");
  const { isAuthenticated, login } = useAuth();

  const logButtonClickEvent = (buttonName: string, params?: IndentifierParams) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.HOME,
      source: AnalyticsSource.HOME,
      button_name: buttonName,
      ...params,
    } as ButtonClickParams);
  };

  let upcomingAppointments: getAppointments_patient_appointments[] = data?.appointments || [];

  if (upcomingAppointments.length > 0) {
    upcomingAppointments = upcomingAppointments
      .filter((appointment) => appointment.appointmentStatus !== AppointmentStatus.CANCELLED)
      .slice(0, 2);
  }

  const hasUpcomingAppointments = !!upcomingAppointments?.length;
  const renderChild = () => {
    if (!isAuthenticated) {
      return (
        <>
          <Text color="textSecondary">{t("signInToSeeAppointments")}</Text>
          <LoginWrapper>
            {SIGN_UP_V2_ENABLED ? (
              <>
                <div css={layout.spacedChildrenVertical("condensed")}>
                  <SignUpSignInButton
                    analyticsParams={
                      {
                        user_flow: AnalyticsUserFlow.HOME,
                        source: AnalyticsSource.HOME,
                      } as ButtonClickParams
                    }
                  />
                </div>
                <div css={{ display: "flex", justifyContent: "flex-start" }}>
                  <ContactUsWrapper>
                    <ContactUs />
                  </ContactUsWrapper>
                </div>
              </>
            ) : (
              <>
                <LoginButton variant="tertiary" onClick={() => login()}>
                  {t("login")}
                </LoginButton>
                <Contact label={t("contactUsLabel")} />
              </>
            )}
          </LoginWrapper>
        </>
      );
    } else if (hasUpcomingAppointments) {
      return (
        <>
          {upcomingAppointments.length > 0 && (
            <HorizontalAppointmentList appointments={upcomingAppointments} />
          )}
        </>
      );
    } else if (isLoading) {
      return <Spinner />;
    }

    return (
      <>
        <Text color="textSecondary">{t("noUpcomingNoProviders")}</Text>
        <LoadingSkeleton animate={false} />
      </>
    );
  };

  const stateLinkValue = !hasUpcomingAppointments ? "past" : "upcoming";
  const viewAllLink = `${routes.appointments}?state=${stateLinkValue}`;

  return (
    <Base isLoading={isLoading}>
      <Header isLoading={isLoading}>
        <Text variant="title3" element="h2">
          {t("headline")}
        </Text>
        <ViewAllLink onClick={() => logButtonClickEvent("View past visits")} to={viewAllLink}>
          {t("viewPastVisits")}
        </ViewAllLink>
      </Header>
      {renderChild()}
      {isAuthenticated && (
        <ViewAllButton
          onClick={() => logButtonClickEvent("View all visits")}
          variant="tertiary"
          to={viewAllLink}
        >
          {t("viewAllVisits")}
        </ViewAllButton>
      )}
    </Base>
  );
};

export default UpcomingVisits;
