import { Form, useField } from "formik";
import { TFunction } from "i18next";
import * as React from "react";
import * as Yup from "yup";

import { StateCode } from "/apollo/schema";
// Components
import { Button, InputWrapper, Text } from "/component/base";
// Styles
import {
  FormContent,
  Title,
} from "/component/form/DemographicFormWizard/DemographicFormWizard.styles";
import AddressCombobox from "/component/partial/AddressCombobox";
import TextField from "/component/partial/formik/TextField";
import { useTranslation } from "/hook";
import { googlePlaces } from "/util";

import { HiddenButton, StatePicker } from "./AddressForm.styles";
import { Props } from "./AddressForm.types";

export const formAddressSchema = (t: TFunction) => {
  return Yup.object().shape({
    address: Yup.object().shape({
      line1: Yup.string()
        .required(t("error.address.line1.required"))
        .matches(/^((?![*&%$#@?]).)*$/, t("error.address.invalidChar")),
      line2: Yup.string()
        .nullable()
        .matches(/^((?![*&%$#@?]).)*$/, t("error.address.invalidChar")),
      city: Yup.string()
        .required(t("error.address.city.required"))
        .matches(/^((?![*&%$#@?]).)*$/, t("error.address.invalidChar")),
      state: Yup.string().required(t("error.address.state.required")),
      postalCode: Yup.string()
        .required(t("error.address.postalCode.required"))
        .matches(/^\d{5}(-\d{4})?$/, t("error.address.postalCode.format")),
    }),
  });
};

const AddressForm = ({ ctaTitle, formTitle, isSubmitting, isValid, serverError }: Props) => {
  const { t } = useTranslation("form-Address");
  const [field, meta, helpers] = useField("address");
  const [line1Field, line1Meta, line1Helper] = useField("address.line1");

  return (
    <Form>
      {formTitle && (
        <Title element="h1" variant="title1">
          {formTitle}
        </Title>
      )}
      <FormContent>
        <InputWrapper
          error={line1Meta.error}
          htmlFor={line1Field.name}
          label={t("label.address.line1")}
          touched={meta.touched}
        >
          <AddressCombobox
            formattedAddress={field.value?.line1 || ""}
            showIcon={false}
            onBlur={() => {
              line1Helper.setTouched(true, true);
            }}
            hasError={!!line1Meta.error && line1Meta.touched}
            onChangeText={(input) => {
              line1Helper.setValue(input);
            }}
            onSelectAddress={(placeDetails: google.maps.places.PlaceResult) => {
              const { line1, city, state, postalCode } = googlePlaces.extractAddress(
                placeDetails?.address_components || [],
              );
              if (line1 && city && state && postalCode) {
                helpers.setValue({ line1, line2: "", city, state: state as StateCode, postalCode });
              }
            }}
          />
        </InputWrapper>
        <TextField label={t("label.address.line2")} name="address.line2" type="text" />
        <FormContent.InnerFields>
          <TextField label={t("label.address.city")} name="address.city" type="text" />
          <StatePicker
            label={t("label.address.state")}
            name="address.state"
            options={Object.keys(StateCode).map((code) => ({ label: code, value: code }))}
            hideRadio
          />
          <TextField label={t("label.address.postalCode")} name="address.postalCode" type="text" />
        </FormContent.InnerFields>
        {!!serverError && (
          <Text color="statusNegative" variant="body2">
            {serverError}
          </Text>
        )}
        {ctaTitle && (
          <FormContent.ButtonGroup>
            <Button variant="primary" isDisabled={!isValid} type="submit" isLoading={isSubmitting}>
              {ctaTitle}
            </Button>
          </FormContent.ButtonGroup>
        )}
        {!ctaTitle && <HiddenButton />}
      </FormContent>
    </Form>
  );
};

export default AddressForm;
