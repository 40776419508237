import styled from "styled-components";

import { styles as ButtonStyles } from "/component/base/Button";
import Modal from "/component/base/Modal";
import Text from "/component/base/Text";
import { layout } from "/styles";

export const HeaderSection = styled.div<{ $hasCta: boolean }>(
  layout.flexVerticalCenter,
  ({ theme, $hasCta }) => ({
    margin: `0 auto ${$hasCta ? theme.spacing.expanded : 0}px`,

    [theme.media.tablet]: {
      marginBottom: $hasCta ? theme.spacing[40] : 0,
    },
    maxWidth: 320,

    [ButtonStyles.Base]: {
      alignSelf: "stretch",
    },
  }),
);

export const Header = styled(Text).attrs({
  color: "textSecondary",
  variant: "body2Bold",
  element: "h3",
})(layout.margin("standard", "skip", "skip", "skip"));

export const QualificationHeader = styled(Text).attrs({
  color: "textSecondary",
  variant: "body2",
  element: "h4",
})(layout.margin("skip", "skip", "base"));

export const Name = styled(Modal.Title)(layout.margin("base", "skip", "gutter"), {
  textAlign: "center",
});
