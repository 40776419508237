import { useReactiveVar } from "@apollo/client";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { addMonths, parseISO } from "date-fns";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Trans } from "react-i18next";
import { Redirect, useHistory } from "react-router";

import { address, scheduling } from "/apollo/client/local";
import { ProviderAvailabilitiesListFragment_availabilities_pages as Availability } from "/apollo/schema";
import appointmentsNoResults from "/asset/image/appointmentsNoResults.png";
import { Link, Loading, Text } from "/component/base";
import TextDivider from "/component/base/TextDivider";
import MainContent from "/component/layout/MainContent";
import { SchedulingProviderDetailsModal } from "/component/modal/ProviderDetailsModal";
import ProviderFiltersModal from "/component/modal/ProviderFiltersModal";
import useAppointmentBooking from "/component/page/Scheduling/hook/useAppointmentBooking";
import EmptyStateCta from "/component/partial/EmptyStateCta";
import FilterButton from "/component/partial/FilterButton";
import InlineEmptyState from "/component/partial/InlineEmptyState";
import ProviderAvailabilitiesListItem from "/component/partial/ProviderAvailabilitiesListItem";
import SetAddress from "/component/partial/SetAddress";
import routes from "/constant/url.constant";
import { useTranslation } from "/hook";
import { layout } from "/styles";
import { reactPlugin } from "/util/appInsights.util";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  IndentifierParams,
  logEvent,
  ScreenViewParams,
} from "/util/firebase.util";

import { formatDateRange, getAllFilterCount, getQueryVariables } from "../Availabilities.utils";
import AvailabilitiesBackButton from "../component/AvailabilitiesBackButton";
import ProviderAvailabilitiesList from "../component/ProviderAvailabilitiesList";
import AvailabilitiesPrompt from "../component/SchedulingPrompt";
import SectionHeader from "../component/SectionHeader";
import { FilterHeader } from "./AllAvailabilities.styles";
import useAvailabilities from "./useAllAvailabilities";

const inlineEmptyStateWidth = 360;
const IMAGE_HEIGHT = 244;
const IMAGE_WIDTH = 376;

const AllAvailabilities = () => {
  const { t } = useTranslation("availabilities-all");
  const history = useHistory();

  const { beginBooking } = useAppointmentBooking();

  const [refetching, setRefetching] = useState(false);
  const [selectedProviderId, setSelectedProviderId] = useState<string>();
  const [showProviderDetails, setShowProviderDetails] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [isReserving, setIsReserving] = useState(false);

  const variables = useReactiveVar(scheduling.var);
  const isEstablishedPatient = variables.isEstablishedPatient;
  const displayUrgentCare = variables.enableUrgentCare;

  const radius = variables.filters.radius;
  const { lat, lng } = useReactiveVar(address.var);
  const geo =
    typeof radius === "number" && typeof lat === "number" && typeof lng === "number"
      ? { radius, lat, lng }
      : undefined;

  const queryVariables = {
    ...getQueryVariables(variables),
    geo,
  };

  const { data, loading, error, fetchMore, refetch } = useAvailabilities(queryVariables);

  const goToSelectedViewConfig = {
    pathname: routes.scheduling + routes.availabilitiesSelected,
    state: {
      canGoBack: true,
    },
  };

  if (!variables.flowSessionId) {
    return <Redirect to={routes.scheduling} />;
  }

  const handleTimeslotClick = async (availabilityId: string, availability: Availability) => {
    const timeSlot = availability.timeSlots.find((ts) => ts.availabilityId === availabilityId);

    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.SCHEDULE_VISIT,
      source: AnalyticsSource.PROVIDER_AVAILABILITY_SEARCH,
      button_name: "Availability",
      button_description: timeSlot?.startAtUtc,
      availability_id: availabilityId,
      calendar_id: timeSlot?.calendarId,
      provider_id: availability.providerDetails.id,
    } as ButtonClickParams);

    setIsReserving(true);
    await beginBooking({ availability, availabilityId });
    setIsReserving(false);
  };

  const handleRefetch = async () => {
    setRefetching(true);
    try {
      await refetch(queryVariables);
    } finally {
      setRefetching(false);
    }
  };

  const handleProviderClick = (providerId: string) => {
    logButtonClickEvent("Provider", { provider_id: providerId });
    if (providerId) {
      setSelectedProviderId(providerId);
      setShowProviderDetails(true);
    }
  };

  const handleNoAvailabilityClick = () => {
    logButtonClickEvent("Show times in the next 6 months");

    // Move `minStartDate` ahead by 6 months, to search a new timeframe
    const oldMinStartDate = parseISO(variables.filters.minStartDate || new Date().toISOString());
    const newMinStartDate = addMonths(oldMinStartDate, 6).toISOString();
    scheduling.updateFilters({ minStartDate: newMinStartDate });

    history.push(goToSelectedViewConfig);
  };

  const logButtonClickEvent = (buttonName: string, params?: IndentifierParams) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.SCHEDULE_VISIT,
      source: AnalyticsSource.PROVIDER_AVAILABILITY_SEARCH,
      button_name: buttonName,
      ...params,
    } as ButtonClickParams);
  };

  const handleFilterClick = (buttonName: string) => {
    logButtonClickEvent(buttonName);
    setShowFilterModal(true);
  };

  // Render the "My Provider" header if the decision support response calls for it
  const renderHeader = () => {
    if (!(variables.querySelectedCalendarIds && data?.selectedCalendarAvailabilities)) {
      return null;
    }

    const availabilities = data?.selectedCalendarAvailabilities.pages;

    return (
      <aside css={layout.flexVertical} key="myProviderHeader">
        <Text variant="title1" css={layout.margin("skip", "skip", "standard")}>
          {t("myProvider.title")}
        </Text>
        {!!availabilities.length && (
          <>
            <ul css={layout.spacedChildrenVertical("gutter")}>
              {availabilities.map((availability, i) => (
                <li key={availability.timeSlots[0]?.availabilityId || i}>
                  <SectionHeader date={availability.date || ""} />
                  <ProviderAvailabilitiesListItem
                    availabilitySlotsDisabled={isReserving}
                    availability={availability}
                    onClickProvider={handleProviderClick}
                    onClickAvailabilitySlot={handleTimeslotClick}
                  />
                </li>
              ))}
            </ul>
            <Link.Button
              variant="tertiary"
              css={[
                { width: inlineEmptyStateWidth },
                layout.margin("gutter", "skip", "skip"),
                layout.padding("skip", 40),
              ]}
              to={goToSelectedViewConfig}
              onClick={() => logButtonClickEvent("Show more times for my provider")}
            >
              {t("myProvider.moreTimes.cta")}
            </Link.Button>
          </>
        )}

        {!availabilities.length && (
          <InlineEmptyState
            width={inlineEmptyStateWidth}
            title={t("myProvider.noAvailability.title")}
            subtitle={
              <Trans
                components={{ bold: <Text color="textSecondary" variant="body2Bold" /> }}
                i18nKey="myProvider.noAvailability.message"
                t={t}
                values={formatDateRange(variables.filters.minStartDate)}
              />
            }
            ctaText={t("myProvider.noAvailability.cta")}
            ctaOnClick={handleNoAvailabilityClick}
          />
        )}
        <Text variant="title1" css={layout.padding("expanded", "skip", "standard")}>
          {t("otherProviders")}
        </Text>
      </aside>
    );
  };

  // Figure out what children to render based on loading, error, and data states
  const renderChild = () => {
    if (loading || refetching) {
      return <Loading />;
    }

    if (error) {
      return (
        <InlineEmptyState
          width={inlineEmptyStateWidth}
          title={t("error.title")}
          subtitle={t("error.subtitle")}
          ctaText={t("error.cta")}
          ctaOnClick={handleRefetch}
        />
      );
    }

    // The `renderHeader` function will check to see if we need to render the header
    const children = [renderHeader()];

    // There are no results
    if (!data?.availabilities.pages.length) {
      // If we are rendering the "My provider" we will show an inline empty state below the
      // "Other providers" header
      if (variables.querySelectedCalendarIds) {
        children.push(
          <InlineEmptyState
            width={inlineEmptyStateWidth}
            key="error"
            title={t("emptyList.multipleProviders.small.title")}
            subtitle={
              <Trans
                components={{ bold: <Text color="textSecondary" variant="body2Bold" /> }}
                i18nKey="emptyList.multipleProviders.small.subtitle"
                t={t}
                values={formatDateRange(variables.filters.minStartDate)}
              />
            }
            ctaText={t("emptyList.multipleProviders.small.cta")}
            ctaOnClick={() => handleFilterClick("Update your filters")}
          />,
        );
      } else {
        // Otherwise, if we are *not* rendering the "My Provider" header, we can show the
        // fullscreen EmptyStateCta
        children.push(
          <EmptyStateCta
            key="error"
            imageSource={appointmentsNoResults}
            imageHeight={IMAGE_HEIGHT}
            imageWidth={IMAGE_WIDTH}
            title={t("emptyList.multipleProviders.large.title")}
            subtitle={t("emptyList.multipleProviders.large.subtitle")}
            ctaText={t("emptyList.multipleProviders.large.cta")}
            onClickCta={() => handleFilterClick("Update your filters")}
          />,
        );
      }
      if (displayUrgentCare) {
        const urgentCareCss = {
          flex: 1,
          maxWidth: variables.querySelectedCalendarIds ? inlineEmptyStateWidth : "480px",
        };
        children.push(
          <div css={variables.querySelectedCalendarIds ? {} : layout.flexCenter}>
            <div css={[layout.flexVertical, urgentCareCss]}>
              <TextDivider label="or" />
              <Link.Button
                css={[layout.padding("condensed"), { flex: 1 }]}
                to={routes.telehealth}
                variant="tertiary"
                onClick={() => logButtonClickEvent("Get virtual urgent care")}
              >
                {isEstablishedPatient ? t("virtualUrgentCare") : t("virtualUrgentCareInstead")}
              </Link.Button>
            </div>
          </div>,
        );
      }
    } else {
      // We have results, render the list of availabilities
      children.push(
        <ProviderAvailabilitiesList
          key="list"
          availabilities={data?.availabilities}
          availabilitiesDisabled={isReserving}
          fetchMore={fetchMore}
          onTimeslotClick={handleTimeslotClick}
          onProviderClick={handleProviderClick}
        />,
      );
    }

    return children;
  };

  return (
    <MainContent
      backButton={
        <AvailabilitiesBackButton analyticsSource={AnalyticsSource.PROVIDER_AVAILABILITY_SEARCH} />
      }
    >
      <Helmet>{t("title")}</Helmet>

      <AvailabilitiesPrompt when={!error} />

      <FilterHeader>
        <SetAddress
          header={t("setAddressHeader", { radius: variables.filters.radius })}
          variant="unstyled"
          textVariant="title2"
          buttonStyle="unstyled"
          textColor="textPrimary"
          analyticsParams={
            {
              user_flow: AnalyticsUserFlow.SCHEDULE_VISIT,
              source: AnalyticsSource.PROVIDER_AVAILABILITY_SEARCH,
            } as ScreenViewParams
          }
        />
        <FilterButton
          onClick={() => handleFilterClick("Filter (icon)")}
          count={getAllFilterCount(variables.filters)}
        />
      </FilterHeader>
      {renderChild()}

      {selectedProviderId && (
        <SchedulingProviderDetailsModal
          isOpen={showProviderDetails}
          close={() => setShowProviderDetails(false)}
          providerId={selectedProviderId}
          goBackOnCtaClick
          hideCta
        />
      )}

      <ProviderFiltersModal isOpen={showFilterModal} close={() => setShowFilterModal(false)} />
    </MainContent>
  );
};

export default withAITracking(reactPlugin, AllAvailabilities, undefined, "ai-tracking");
