import { Link } from "react-router-dom";
import styled from "styled-components";

import {
  getTextStyleForButtonVariant,
  getVariantStyle,
} from "/component/base/Button/Button.styles";
import { StyleProps as ButtonStyleProps } from "/component/base/Button/Button.types";
import { getTextStyles } from "/component/base/Text";
import { layout, link } from "/styles";

import { LinkVariants } from "./Link.types";

export const Base = styled(Link)<{ $variant: LinkVariants }>(
  link.base,
  ({ theme }) => getTextStyles({ variant: "body1", theme }),
  ({ $variant, theme }) => ({
    // The borderBottom is defined in `link.base`, so setting this `borderBottom` to `undefined`
    // will use the border bottom defined in the shared style. Setting it to `none` for other
    // link variants since only the `primary` variant should have the border bottom
    borderBottom: $variant !== "primary" ? "none" : undefined,
    color: theme.colors.brandPrimary,
    fontSize: $variant === "tertiary" ? "inherit" : undefined,
    lineHeight: $variant === "tertiary" ? "inherit" : undefined,
    textDecoration: $variant === "secondary" ? "underline" : "none",
  }),
);

export const LinkButton = styled(Link)<ButtonStyleProps>(
  layout.flexCenter,
  {
    display: "inline-flex",
    textDecoration: "none",
  },
  getTextStyleForButtonVariant,
  getVariantStyle,
);
