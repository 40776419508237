import { isValid, subYears } from "date-fns";
import * as Yup from "yup";

import { DateObj } from "./DOBField.types";
import { parseDate } from "./DOBField.utils";

const someFieldsEntered = (dateObj: DateObj) => !!dateObj.year || !!dateObj.month || !!dateObj.day;

const invalidDateTest = (date: DateObj | null | undefined) => {
  if (date && someFieldsEntered(date)) {
    return isValid(parseDate(date));
  } else {
    // if no fields have been entered, then we consider the empty date valid.
    return true;
  }
};

const dateIsInPastTest = (date: DateObj | null | undefined) => {
  if (date && someFieldsEntered(date)) {
    return parseDate(date) < new Date();
  } else {
    // if no fields have been entered, then we consider the empty date valid.
    return true;
  }
};

const yearHas4digits = (date: DateObj | null | undefined) => {
  if (date && date.year) {
    return date.year.length === 4;
  } else {
    // if year has not been filled out, consider empty year valid.
    return true;
  }
};

/**
 * Opitional validation test for requiring the DOB. Use this over yup's `required`
 * which doesn't work as well for object types with nested fields. The required validation
 * passes if at least one field has been entered, so this only fails when no field has been
 * entered.
 * e.g. `.test('requiredDOB', "Required", required)`
 */
export const requiredTest = (date: DateObj | null | undefined) => {
  return !!date && someFieldsEntered(date);
};

/**
 * Optional validation test for minimum age. Import this function and append
 * to the dobSchema like so
 * `.test('minAge', "<Your error message when validation fails>", minAgeTest(18))`
 */
export const minAgeTest = (minAge: number) => (date: DateObj | null | undefined) => {
  if (date && someFieldsEntered(date)) {
    return parseDate(date) < subYears(new Date(), minAge);
  } else {
    return true;
  }
};

/**
 * Creates a schema for the `dob` field in your formik form which tests for the
 * validity of the date created by the DOB field inputs and that the date is
 * not in the future.
 */
const dobSchemaFactory = (errorMsgs: {
  isValid: string;
  dateIsInPast: string;
  yearIsValid: string;
}) =>
  Yup.object()
    .shape({
      month: Yup.string(),
      day: Yup.string(),
      year: Yup.string(),
    })
    .test("isValid", errorMsgs.isValid, invalidDateTest)
    .test("dateIsInPast", errorMsgs.dateIsInPast, dateIsInPastTest)
    .test("yearHas4Digits", errorMsgs.yearIsValid, yearHas4digits);

export default dobSchemaFactory;
