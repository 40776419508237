import { gql, useQuery } from "@apollo/client";

import { GetCaregiverAccessWithParams } from "/apollo/schema/types";
export interface GetCaregiverAccessVaribles {
  includeRevoked?: boolean;
  useMyAccount?: boolean;
}

const GET_CAREGIVER_ACCOUNT = gql`
  query GetCaregiverAccessWithParams($includeRevoked: Boolean, $useMyAccount: Boolean) {
    viewer {
      user {
        caregiverAccess(includeRevoked: $includeRevoked, useMyAccount: $useMyAccount) {
          id
          firstName
          lastName
          birthDate
          gender
          isMinor
          hasAccount
          address {
            line1
            line2
            state
            city
            postalCode
          }
          statusDetails {
            status
            statusName
            createdDate
            acceptedDate
            revokedDate
          }
          contact {
            phone
            email
          }
        }
      }
    }
  }
`;

// useQuery is fully typed and return value is inferred.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useCaregiverAccess = (variables: GetCaregiverAccessVaribles) =>
  useQuery<GetCaregiverAccessWithParams, GetCaregiverAccessVaribles>(GET_CAREGIVER_ACCOUNT, {
    fetchPolicy: "no-cache",
    variables,
  });
