import styled from "styled-components";

import { card, layout } from "/styles";

export const Base = styled.div(card.base, layout.flexCenterHorizontal, layout.padding("standard"), {
  width: "100%",
  flex: 1,
  border: 0,
});

export const Metadata = styled.div(
  layout.margin("none", "none", "none", "standard"),
  layout.flexVerticalAlignStart,
);
