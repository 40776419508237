import React from "react";

import { Text } from "/component/base";
import { Sliders } from "/component/base/Icon";
import { useTranslation } from "/hook";
import theme from "/theme/default";

import { Base, CountIndicator, IconWrapper } from "./FilterButton.styles";
import { Props } from "./FilterButton.types";

/**
 * `FilterButton` gives us a consistent way to render a stateful button that presents a modal for filtering,
 * and indicates how many filters are currently applied.
 */
const FilterButton = ({ className, count = 0, onClick }: Props) => {
  const { t } = useTranslation("partial-filter-button");

  const hasCount = count > 0;
  const text = hasCount ? t("filter.withCount") : t("filter.none");

  return (
    <Base
      className={className}
      variant={"blank"}
      onClick={onClick}
      aria-label={`${count} ${t("ariaLabel")}`}
      style={
        hasCount
          ? { backgroundColor: theme.colors.brandSecondary }
          : { backgroundColor: theme.colors.brandSecondaryLight }
      }
    >
      <IconWrapper $hasCount={hasCount}>
        <Sliders size={24} color={hasCount ? "white" : "brandSecondary"} />
      </IconWrapper>
      <Text variant="body1Bold" color={hasCount ? "white" : "textPrimary"}>
        {text}
        {hasCount && (
          <CountIndicator variant="body1Bold" color={hasCount ? "white" : "textPrimary"}>
            {` • ${count}`}
          </CountIndicator>
        )}
      </Text>
    </Base>
  );
};

export default FilterButton;
