import React from "react";

import { Button, Modal, Text } from "/component/base";
import { Cash, InsuranceBadge } from "/component/base/Icon";
import { ModalProps as Props } from "/component/base/Modal";
import { useTranslation } from "/hook";
import { useAmwellInsurance } from "/hook/useAmwellInsurance/useAmwellInsurance";

import {
  ContentContainer,
  Description,
  DescriptionContainer,
  SelfPayDescription,
  Subtitle,
  Title,
} from "./InsuranceInfoModal.styles";

const InsuranceInfoModal = ({ isOpen, close }: Props) => {
  const { data } = useAmwellInsurance();
  const { t } = useTranslation("telehealth");
  return (
    <Modal
      isOpen={isOpen}
      header={<Modal.Header title={""} close={close} />}
      footer={
        <Modal.FooterWithLargeButton>
          <Button fullWidth="percent" variant="primary" onClick={close}>
            {t("insuranceModal.ok")}
          </Button>
        </Modal.FooterWithLargeButton>
      }
    >
      <Title color="textNavyBlue">{t("insuranceModal.title")}</Title>

      <Subtitle>
        <Text variant="body1">{t("insuranceModal.subtitle")}</Text>
      </Subtitle>

      <ContentContainer>
        <DescriptionContainer>
          <InsuranceBadge background={{ color: "backgroundSecondary" }} color="textNavyBlue" />
          <Description css={{ paddingTop: 12 }}>
            <Text variant="body1Bold">{t("insuranceModal.healthInsurance")}</Text>
          </Description>
        </DescriptionContainer>

        <Text color="textSecondary" variant="body2Bold">
          {t("insuranceModal.acceptedInsurance")}
        </Text>
        <div>
          <Text color="textSecondary" variant="body1">
            {data?.healthPlans.map((healthPlan) => healthPlan.name).join(", ") || ""}
          </Text>
        </div>

        <SelfPayDescription>
          <Cash background={{ color: "backgroundSecondary" }} color="textNavyBlue" />
          <Description>
            <Text variant="body1Bold">{t("insuranceModal.selfPayment")}</Text>
            <Text variant="body2" color="textSecondary">
              {t("insuranceModal.visitFee")}
            </Text>
          </Description>
        </SelfPayDescription>

        <div>
          <Text color="textSecondary" variant="body1">
            {t("insuranceModal.description")}
          </Text>
        </div>
      </ContentContainer>
    </Modal>
  );
};

export default InsuranceInfoModal;
