import styled from "styled-components";

import { Button } from "/component/base";
import { layout } from "/styles";

import { BackButtonVariant } from "./BackButton.types";

export const ButtonStyled = styled(Button)<{ $variant: BackButtonVariant }>(
  layout.flexCenter,
  ({ $variant = "primary", theme }) => {
    const baseStyles = {
      width: 40,
      height: 40,
      borderRadius: 16,
      backgroundColor: "transparent",
    };
    if ($variant === "primary") {
      return baseStyles;
    } else {
      return {
        ...baseStyles,
        backgroundColor: theme.colors.textRed,
        border: "none",
        "&:focus, &:hover": {
          border: `solid 1px ${theme.colors.objectInverse}`,
        },
        "& > *": {
          // The underlying button style will shrink the icon down to fix in the 40x40 box.
          // This prevents it.
          flex: "0 0 auto",
        },
      };
    }
  },
);
