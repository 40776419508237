import { ApolloError, ApolloQueryResult, gql } from "@apollo/client";

import { useB2CQuery } from "../client";
import { GET_INSURANCE } from "../schema/types";

export const insuranceQuery = gql`
  query GET_INSURANCE {
    patient {
      insurances {
        lastUpdatedBy
        lastUpdated
        ircName
        relationshipToInsured
        eligibilityStatus
        confidentialityCode
        insuranceId
        insurancePlanName
        insuranceType
        insurancePackageId
        sequenceNumber
        ircId
        relationshipToInsuredId
        policyNumber
        insuranceIdNumber
        insurancePolicyHolder
        insurancePolicyHolderSex
        expirationDate
        eligibilityLastChecked
        cancelled
      }
    }
  }
`;

const useInsurance = (): {
  error: ApolloError | undefined;
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<GET_INSURANCE>>;
  data: GET_INSURANCE | undefined;
} => {
  const { data, error, loading, refetch } = useB2CQuery<GET_INSURANCE>(insuranceQuery);

  return {
    error,
    loading,
    refetch,
    data: data,
  };
};

export default useInsurance;
