/**
 * This fn returns a notes array for a given observation, where notes that are
 * identical to either the already given name or value of the observation are
 * filtered out. This allows us to determine if an observation has unique notes
 * that should be viewable separately.
 */
export const getContentfulNotes = ({
  name,
  notes,
  value,
  valueString,
}: {
  name?: string | null;
  notes?: string[] | null;
  value?: string | number | null;
  valueString?: string | null;
}) => {
  if (!notes) {
    return [];
  }

  const contentfulNotes = notes?.filter((note) => !!note) || [];
  const firstNote = contentfulNotes[0];

  if (contentfulNotes.length === 1 && [`${value}`, valueString, name].indexOf(firstNote) >= 0) {
    return [];
  }

  return contentfulNotes;
};
