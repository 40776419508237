import styled from "styled-components";

import Text from "/component/base/Text";
import { card, layout } from "/styles";

export const PersonalInformationSubtitle = styled(Text)(
  layout.margin("standard", "skip", "expanded"),
  () => ({
    display: "block",
  }),
);

export const AddressesSubtitle = styled(Text)(
  layout.margin("expanded", "skip", "condensed"),
  () => ({
    display: "block",
  }),
);

export const AddressText = styled(Text)({
  whiteSpace: "pre-wrap",
  textAlign: "right",
});

export const InfoListItem = styled.div(
  layout.flexSpaceHorizontal,
  layout.padding("standard", "skip"),
  () => ({
    width: "100%",
  }),
);

export const SpinnerContainer = styled.div(layout.flexCenter, () => ({
  width: "100%",
}));
export const ListContainer = styled.div(card.wrapper);
