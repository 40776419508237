import { withAITracking } from "@microsoft/applicationinsights-react-js";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as html2pdf from "html2pdf.js";
import { Base64 } from "js-base64";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";

import noResultsFound from "/asset/image/noResultsFound.png";
import { ErrorCode, getErrorCode } from "/b2c/client/utils";
import useDocuments from "/b2c/query/useDocuments.b2c";
import { GET_LETTERS_patient_letters_items } from "/b2c/schema";
import { List } from "/component/base";
import { AlertCircleOpen } from "/component/base/Icon";
import MainContent from "/component/layout/MainContent";
import EmptyStateCta from "/component/partial/EmptyStateCta";
import { useToastContext } from "/component/provider/ToastProvider";
import { useTranslation } from "/hook";
import { useAnalytics } from "/hook/useAnalytics/useAnalytics";
import { layout } from "/styles";
import { reactPlugin } from "/util/appInsights.util";
import { replaceHtmlImgTag } from "/util/documents.util";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  logEvent,
} from "/util/firebase.util";

import LoadingSkeleton from "../../../../partial/LoadingSkeleton";
import DocumentListItem from "./components/DocumentListItem";
import { DocsWrapper, ListContainer } from "./DocumentsB2C.styles";

const IMAGE_HEIGHT = 261;
const IMAGE_WIDTH = 183;

const Documents = () => {
  const { t } = useTranslation("documents");
  const { showToast } = useToastContext();
  const { recordAnalyticsEvent } = useAnalytics();

  const { data, error, loading: isLoading } = useDocuments();
  const documents = data?.patient.letters;
  const sortedDocuments = [...(documents?.items ?? [])].sort((a, b) => {
    return new Date(b.createdDatetime).getTime() - new Date(a.createdDatetime).getTime();
  });

  const handlePressDocument = (letter: GET_LETTERS_patient_letters_items) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.MY_HEALTH,
      source: AnalyticsSource.DOCUMENTS,
      button_name: "Document",
    } as ButtonClickParams);

    recordAnalyticsEvent("viewDocument");

    const documentTitle = letter?.paperformName || "";

    if (letter.html) {
      const downloadLink = document.createElement("a");
      downloadLink.href = `data:application/pdf;base64,${letter.html}`;
      downloadLink.download = `${documentTitle}.pdf`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      downloadLink.remove();
    } else if (letter.letterText) {
      // decode base64 data
      let htmlString = Base64.decode(letter.letterText || "");
      // replace unknown img with SummitHealth logo
      htmlString = replaceHtmlImgTag(htmlString);

      html2pdf()
        .set({
          fileName: documentTitle,
          margin: 10,
          html2canvas: { scale: 2, width: 825 },
        })
        .from(htmlString)

        .toPdf()
        .get("pdf")
        .then(function (pdf: { output: (arg0: string) => string | URL | undefined }) {
          window.open(pdf.output("bloburl"), "_blank");
        });
    } else {
      handleDocumentError();
    }
  };

  const handleDocumentError = () => {
    showToast({
      message: t("documentLoadError"),
      icon: "alert",
      type: "error",
    });
  };

  const showEmptyState = (documents?.totalCount || 0) <= 0;

  useEffect(() => {
    if (!isLoading) {
      recordAnalyticsEvent("viewDocuments", { noData: showEmptyState });
    }
  }, [recordAnalyticsEvent, showEmptyState, isLoading]);

  if (isLoading)
    return (
      <MainContent pageTitle={t("pageTitle")} parentPage="myHealth">
        <LoadingSkeleton skeletonVariant="list-item" />
      </MainContent>
    );

  if (error && getErrorCode(error, data) !== ErrorCode.PARTIAL_RESPONSE) {
    return (
      <MainContent pageTitle={t("pageTitle")} parentPage="myHealth">
        <Helmet>
          <title>{t("pageTitle")}</title>
        </Helmet>
        <div css={layout.flexVerticalCenter}>
          <div css={layout.marginVertical(80)}>
            <EmptyStateCta
              icon={<AlertCircleOpen color="brandPrimary" size={48} />}
              title={t("error.fetchError.title")}
              subtitle={t("error.fetchError.subtitle")}
            />
          </div>
        </div>
      </MainContent>
    );
  }

  if (showEmptyState) {
    return (
      <MainContent pageTitle={t("pageTitle")} parentPage="myHealth">
        <Helmet>
          <title>{t("pageTitle")}</title>
        </Helmet>

        <div css={layout.flexVerticalCenter}>
          <div css={layout.marginVertical(60)}>
            <EmptyStateCta
              imageHeight={IMAGE_HEIGHT}
              imageWidth={IMAGE_WIDTH}
              imageSource={noResultsFound}
              title={t("emptyState.title")}
              subtitle={t("emptyState.visitDocs.subtitle")}
            />
          </div>
        </div>
      </MainContent>
    );
  }

  return (
    <MainContent pageTitle={t("pageTitle")} parentPage="myHealth">
      <Helmet>
        <title>{t("pageTitle")}</title>
      </Helmet>

      <DocsWrapper>
        <ListContainer>
          <List
            as="Buttons"
            chevronVariant="grey"
            hideLastChildBorder
            onClick={handlePressDocument}
            data={sortedDocuments}
            renderItem={(document) => (
              <DocumentListItem
                document={{
                  title: document.paperformName || "",
                  providerName: document.provider?.displayName || "",
                  dateString: document.createdDatetime,
                }}
              />
            )}
          />
        </ListContainer>
      </DocsWrapper>
    </MainContent>
  );
};

export default withAITracking(reactPlugin, Documents, undefined, "ai-tracking");
