export type Gender = "MALE" | "FEMALE" | "UNKNOWN" | undefined;

export interface AddressProps {
  line1?: string | undefined;
  city?: string | undefined;
  state?: string | undefined;
  postalCode?: string | undefined;
}
export interface FilterProps {
  language?: string;
  gender?: Gender;
}

export enum ProviderStatus {
  Available = "availableNow",
  Busy = "availableSoon",
  Offline = "offline",
  WaitingRoom = "amountWaiting",
}
