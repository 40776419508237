import React from "react";

import { Button, Text } from "/component/base";
import * as Icons from "/component/base/Icon";
import { ModalProps } from "/component/base/Modal";
import { useTranslation } from "/hook";
import { layout } from "/styles";

import { Modal } from "./TransferPrescriptionsModal.styles";

const TransferPrescriptionModal = ({ isOpen, close }: ModalProps) => {
  const { t } = useTranslation("modal-transferPrescriptionsModal");

  return (
    <Modal
      isOpen={isOpen}
      close={close}
      header={<Modal.Header close={close} />}
      footer={
        <Modal.FooterWithLargeButton>
          <Button fullWidth="percent" onClick={close}>
            {t("ok")}
          </Button>
        </Modal.FooterWithLargeButton>
      }
    >
      <div
        css={[
          layout.flexVerticalCenter,
          layout.paddingVertical("gutter"),
          layout.spacedChildrenVertical("standard"),
        ]}
      >
        <Icons.HouseArrow color="brandSecondary" size={48} background={{ color: "transparent" }} />
        <Text variant="body1Bold">{t("title")}</Text>
        <Text>{t("description")}</Text>
      </div>
    </Modal>
  );
};

export default TransferPrescriptionModal;
