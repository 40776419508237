import React from "react";
import ContentLoader from "react-content-loader";

import { Base } from "./ResultLoadingCardHeader.styles";

const backgroundColor = "#e3e5e9";
const foregroundColor = "#f0f0f0";

const ResultLoadingCardHeader = ({ animate = true, ...loaderProps }) => {
  return (
    <Base>
      <ContentLoader
        speed={2}
        height={140}
        viewBox="0 0 798 140"
        preserveAspectRatio="none"
        backgroundColor={backgroundColor}
        foregroundColor={animate ? foregroundColor : backgroundColor}
        animate={animate}
        style={{ borderWidth: 1 }}
        {...loaderProps}
      >
        <rect x="0" y="0" rx="4" ry="4" width="798" height="16" />
        <rect x="0" y="32" rx="4" ry="4" width="97" height="16" />
        <rect x="0" y="64" rx="4" ry="4" width="205" height="16" />
        <rect x="0" y="116" rx="4" ry="4" width="165" height="16" />
      </ContentLoader>
    </Base>
  );
};

export default ResultLoadingCardHeader;
