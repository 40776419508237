import { gql, LazyQueryHookOptions } from "@apollo/client";

import { useB2CLazyQuery } from "../client";
import { GET_IMAGING_RESULT_PAGE, GET_IMAGING_RESULT_PAGEVariables } from "../schema/types";

export const imagingResultPageQuery = gql`
  query GET_IMAGING_RESULT_PAGE($imagingResultId: Int!, $pageId: Int!) {
    patient {
      imagingResultPage(imagingResultId: $imagingResultId, pageId: $pageId)
    }
  }
`;

export const useImagingResultPage = (
  options: LazyQueryHookOptions<GET_IMAGING_RESULT_PAGE, GET_IMAGING_RESULT_PAGEVariables>,
) =>
  useB2CLazyQuery<GET_IMAGING_RESULT_PAGE, GET_IMAGING_RESULT_PAGEVariables>(
    imagingResultPageQuery,
    options,
  );
