import { withAITracking } from "@microsoft/applicationinsights-react-js";
import awsdk from "awsdk";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { RouteComponentProps } from "react-router";

import { Button, Spinner, Text } from "/component/base";
import { Video } from "/component/base/Icon";
import TelehealthCallbackModal from "/component/modal/TelehealthCallbackModal";
import TelehealthCancelVisitModal from "/component/modal/TelehealthCancelVisitModal";
import { useAmwellPrevisitContext } from "/component/provider/AmwellPrevisitProvider";
import { useCaregiverProvider } from "/component/provider/CaregiverProvider";
import routes from "/constant/url.constant";
import { useTranslation } from "/hook";
import { useAnalytics } from "/hook/useAnalytics/useAnalytics";
import { layout } from "/styles";
import theme from "/theme/default";
import { reactPlugin } from "/util/appInsights.util";
import { AnalyticsEvent, logEvent } from "/util/firebase.util";

import ChatContainer from "./ChatContainer";
import ProviderRoomCard from "./component/ProviderRoomCard";
import { Base, TelehealthWaitingContainer } from "./TelehealthWaitingRoom.styles";

const TelehealthWaitingRoom = ({ history }: RouteComponentProps) => {
  const { t } = useTranslation("telehealth");
  const {
    sdk,
    consumer,
    provider,
    visitContext,
    setVisit,
    visit,
    rejoin,
    setRejoin,
    resetForm,
    recoverPrevisit,
  } = useAmwellPrevisitContext();
  const { caregiverMode } = useCaregiverProvider();
  const { recordAnalyticsEvent } = useAnalytics();

  const ref = React.createRef();
  const [isCancelling, setIsCancelling] = useState(false);
  const [showCancelVisitModal, setShowCancelVisitModal] = useState(false);
  const [isInit, setIsInit] = useState(false);
  const [providerDetails, setProviderDetails] = useState<awsdk.AWSDKProviderDetails | undefined>();
  const [showCallbackModal, setShowCallbackModal] = useState(false);
  const [showVisitVideo, setShowVisitVideo] = useState(false);
  const [enableTextNotification, setEnableTextNotification] = useState(false);
  const [chatReport, setChatReport] = useState<awsdk.AWSDKChatReport>();
  const [sentMessage, setSentMessage] = useState<awsdk.AWSDKChatItem>();
  const [updatedVisit, setUpdatedVisit] = useState<awsdk.AWSDKVisit>();
  const [chatInputEnabled, setChatInputEnabled] = useState(true);

  let waitingRoomTimeout: NodeJS.Timeout;

  const beginWaiting = () => {
    waitingRoomTimeout = setTimeout(async () => {
      if (sdk && provider && consumer) {
        setProviderDetails(await sdk.providerService.getProviderDetails(provider, consumer));
      }
      if (rejoin) beginWaiting();
    }, 1000);
  };

  const handleCancel = async () => {
    setShowCancelVisitModal(false);
    setIsCancelling(true);
    if (sdk && visit) {
      await sdk.visitService.cancelVisit(visit);
      if (waitingRoomTimeout) clearTimeout(waitingRoomTimeout);

      const visitConsole = ref.current;
      if (visitConsole) {
        // eslint-disable-next-line
        // @ts-ignore
        visitConsole.remove();
      }
      history.push(routes.root);
    }
    setIsCancelling(false);
  };

  const handleTextMe = () => {
    if (sdk && visit && visitContext) {
      const _enableTextNotification = !enableTextNotification;
      setEnableTextNotification(_enableTextNotification);
      sdk?.visitService.setupWaitingRoomAlerts(
        visit,
        visitContext.callbackNumber,
        _enableTextNotification,
      );
    }
  };

  const handleCallbackSubmit = async (callbackNumber: string) => {
    if (sdk && visitContext) {
      visitContext.callbackNumber = callbackNumber;
      setVisit(await sdk.visitService.createOrUpdateVisit(visitContext));
    }
  };

  const waitForVisitToStart = async () => {
    if (sdk && visit && ref) {
      await sdk.visitService.waitForProviderToJoinVisit(visit, (updatedVisit: awsdk.AWSDKVisit) => {
        setUpdatedVisit(updatedVisit);
      });
      await beginVideoVisit();
    }
  };

  // update chat report only if new items exist when compared to state
  useEffect(() => {
    if (updatedVisit) {
      if (!chatReport || updatedVisit.chatReport.chatItems.length > chatReport.chatItems.length) {
        setChatReport(updatedVisit.chatReport);
      }
    }
  }, [updatedVisit]);

  // enable/disable chat TextInput based on last message type
  useEffect(() => {
    if (chatReport && chatReport.chatItems.length > 0) {
      const lastMessage = chatReport?.chatItems[chatReport.chatItems.length - 1];
      if (lastMessage.messageType === "AssistantExit") {
        setChatInputEnabled(false);
      } else {
        setChatInputEnabled(true);
      }
    }
  }, [chatReport]);

  const rejoinVideoVisit = async () => {
    logEvent(AnalyticsEvent.AMWELL_VISIT_REJOIN, {
      is_caregiver: caregiverMode ? "true" : "false",
    });
    recordAnalyticsEvent("amwellVisitRejoin");
    if (process.env.TELEHEALTH_RECOVER_VISIT === "true") recoverPrevisit(true);
    if (providerDetails) {
      if (providerDetails.waitingRoomCount > 0) {
        waitForVisitToStart();
        setRejoin(false);
      } else {
        beginVideoVisit();
      }
    }
  };

  const beginVideoVisit = async () => {
    if (sdk && visit) {
      resetForm();
      setRejoin(false);
      setShowVisitVideo(true);
      setIsInit(true);
    }
  };

  useEffect(() => {
    beginWaiting();
  }, [provider]);

  useEffect(() => {
    if (sdk && visit && !rejoin) {
      waitForVisitToStart();
    }
  }, [sdk]);

  useEffect(() => {
    const visitConsole = ref.current;
    if (visitConsole && sdk && visit && isInit) {
      setIsInit(false);
      // eslint-disable-next-line
      // @ts-ignore
      visitConsole.initialize(sdk, {
        visit: visit,
        disableHeader: true,
      });
      // eslint-disable-next-line
      // @ts-ignore
      visitConsole.addEventListener("visitEnd", () => {
        logEvent(AnalyticsEvent.AMWELL_VISIT_END, {
          is_caregiver: caregiverMode ? "true" : "false",
        });
        recordAnalyticsEvent("amwellVisitEnd");
        // eslint-disable-next-line
        // @ts-ignore
        visitConsole.remove();
        history.push(routes.root);
      });
    }
  }, [ref, visit, isInit]);

  const waitingRoomContent = () => (
    <>
      <Spinner size={48} />
      <Text color="textNavyBlue" variant="title1">
        {t("waitingRoom.begin")}
      </Text>

      <Button
        variant="unstyled"
        isDisabled={isCancelling || !visitContext?.callbackNumber}
        onClick={() => setShowCallbackModal(true)}
        css={[
          layout.flexLTR,
          layout.spacedChildrenHorizontal("condensed"),
          layout.margin("none", "none", 40, "none"),
          {
            display: "inline-block",
          },
        ]}
      ></Button>

      {!providerDetails ? (
        <Spinner />
      ) : (
        <ProviderRoomCard
          isDisabled={isCancelling}
          enableTextNotification={enableTextNotification}
          onClick={handleTextMe}
          providerDetails={providerDetails}
        />
      )}
      <Text variant="body2" color="textSecondary">
        {t("waitingRoom.additionalPeople")}
      </Text>
      <Button
        css={[layout.paddingHorizontal(60), { backgroundColor: theme.colors.objectInverse }]}
        isDisabled={isCancelling}
        onClick={() => setShowCancelVisitModal(true)}
        variant="secondary"
      >
        {t("waitingRoom.cancel")}
      </Button>
    </>
  );

  const rejoinContent = () => (
    <>
      <Video color="brandSecondary" size={48} />
      <Text variant="title1">{t("waitingRoom.inProgress")}</Text>
      <Text css={layout.marginVertical("expanded")} variant="body1" color="textSecondary">
        {t("waitingRoom.rejoinText")}
      </Text>
      {!providerDetails ? (
        <Spinner />
      ) : (
        <ProviderRoomCard
          isDisabled={isCancelling}
          enableTextNotification={enableTextNotification}
          providerDetails={providerDetails}
        />
      )}
      <div
        css={[
          layout.flexSpaceHorizontal,
          layout.spacedChildrenHorizontal(),
          { width: "100%", maxWidth: 712 },
        ]}
      >
        <Button
          isDisabled={isCancelling}
          fullWidth="percent"
          onClick={rejoinVideoVisit}
          variant="primary"
        >
          {t("waitingRoom.rejoin")}
        </Button>
        <Button
          isDisabled={isCancelling}
          fullWidth="percent"
          onClick={() => setShowCancelVisitModal(true)}
          variant="secondary"
        >
          {t("waitingRoom.cancel")}
        </Button>
      </div>
    </>
  );

  const addChatMessage = (message: string, lastOrdinal: number) => {
    // No spinner on chat
    if (sdk && visit) {
      logEvent(AnalyticsEvent.AMWELL_CHAT_SEND);
      sdk.visitService
        .addChatMessage(visit, message, lastOrdinal)
        .then((chatReport) => {
          setSentMessage(chatReport.chatItems[0]);
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <>
      <Base>
        {/* eslint-disable-next-line */}
        {/*@ts-ignore */}
        <amwell-visit-console
          style={{
            display: !showVisitVideo ? "none" : "block",
            height: "100vh",
          }}
          ref={ref}
        />
        {!showVisitVideo && (
          <TelehealthWaitingContainer>
            <Helmet>
              <title>{t("waitingRoom.title")}</title>
            </Helmet>

            {!showVisitVideo && (
              <div css={[layout.flexVerticalCenter, layout.spacedChildrenVertical("expanded")]}>
                {rejoin ? rejoinContent() : waitingRoomContent()}
              </div>
            )}

            <TelehealthCancelVisitModal
              close={() => setShowCancelVisitModal(false)}
              isOpen={showCancelVisitModal}
              handleSubmit={handleCancel}
            />

            <TelehealthCallbackModal
              callbackNumber={visitContext ? visitContext.callbackNumber : ""}
              isOpen={showCallbackModal}
              handleSubmit={handleCallbackSubmit}
              close={() => setShowCallbackModal(false)}
            />

            {chatReport && (
              <ChatContainer
                handleSendMessageClick={addChatMessage}
                chatReport={chatReport}
                sentMessage={sentMessage}
                chatInputEnabled={chatInputEnabled}
              />
            )}
          </TelehealthWaitingContainer>
        )}
      </Base>
    </>
  );
};

export default withAITracking(reactPlugin, TelehealthWaitingRoom, undefined, "ai-tracking");
