import { useReactiveVar } from "@apollo/client";
import awsdk from "awsdk";
import React, { useEffect, useState } from "react";

import { address } from "/apollo/client/local";
import { List, Loading } from "/component/base";
import { Search } from "/component/base/Icon";
import Text from "/component/base/Text";
import TextInput from "/component/base/TextInput";
import { Title } from "/component/form/Telehealth/Telehealth.styles";
import FormContent from "/component/partial/FormContent";
import PharmacyItem from "/component/partial/PharmacyItem";
import SegmentedControl from "/component/partial/SegmentedControl";
import SetAddress from "/component/partial/SetAddress";
import {
  PharmacySelectionProps,
  useAmwellPrevisitContext,
} from "/component/provider/AmwellPrevisitProvider";
import { PharmacyType, PrevisitForms } from "/constant/telehealth.constant";
import { useAmwellPharmacies, useTranslation } from "/hook";
import { layout } from "/styles";
import { AnalyticsUserFlow, ScreenViewParams } from "/util/firebase.util";

import { TelehealthFormProps } from "../Telehealth.types";

const PharmacySelection = ({
  handleError,
  handleSubmit,
}: TelehealthFormProps<PharmacySelectionProps>) => {
  const { t } = useTranslation("form-telehealth-previsit");
  const addressStored = useReactiveVar(address.var);
  const lat = addressStored.lat;
  const lng = addressStored.lng;
  const zipCode = addressStored.postalCode || undefined;
  const { loading, data } = useAmwellPharmacies({ lat, lng, radius: 10, zipCode });
  const { sdk, visitContext, setVisit, consumer } = useAmwellPrevisitContext();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pharmacies, setPharmacies] = useState<awsdk.AWSDKPharmacy[]>([]);
  const [filteredSearchResults, setFilteredSearchResults] = useState<awsdk.AWSDKPharmacy[]>([]);
  const [pharmacyType, setPharmacyType] = useState<string>(PharmacyType.RETAIL);
  const [searchTerm, setSearchTerm] = useState("");

  const SEGMENT_OPTIONS = [
    {
      label: t("pharmacySelection.pickUpSegment"),
      value: PharmacyType.RETAIL,
    },
    {
      label: t("pharmacySelection.deliverySegment"),
      value: PharmacyType.MAIL_ORDER,
    },
  ];

  useEffect(() => {
    if (data) {
      data.sort((a, b) => a.distance - b.distance);
      setPharmacies(data);

      const _filteredSearchResults = data.filter((pharmacy) => pharmacy.type === pharmacyType);
      setFilteredSearchResults(_filteredSearchResults);
    }
  }, [data]);

  const handleToggle = async (type: string) => {
    setPharmacyType(type);
    clearPharmacySearchTerm();

    const _filteredSearchResults = pharmacies.filter((pharmacy) => pharmacy.type === type);

    if (type === PharmacyType.MAIL_ORDER) {
      _filteredSearchResults.sort((a, b) => (a.name < b.name ? -1 : 1));
    }
    setFilteredSearchResults(_filteredSearchResults);
  };

  const performFilteredSearch = (text: string) => {
    let _filteredSearchResults;
    if (text.length > 0) {
      _filteredSearchResults = pharmacies.filter((pharmacy) => {
        const itemData = pharmacy.name.toUpperCase();
        const textData = text.toUpperCase();
        return itemData.startsWith(textData, 0) === true && pharmacy.type === pharmacyType;
      });
    } else {
      _filteredSearchResults = pharmacies.filter((pharmacy) => {
        return pharmacy.type === pharmacyType;
      });
    }

    setFilteredSearchResults(_filteredSearchResults);
  };

  const clearPharmacySearchTerm = () => {
    setSearchTerm("");
    performFilteredSearch("");
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (!pharmacies) return;
    performFilteredSearch(value);
  };

  const submitForm = async (pharmacy: awsdk.AWSDKPharmacy) => {
    setIsSubmitting(true);
    try {
      if (sdk && consumer && pharmacy && visitContext) {
        await sdk.pharmacyService.updatePreferredPharmacy(consumer, pharmacy);
        const visit = await sdk.visitService.createOrUpdateVisit(visitContext);
        setVisit(visit);
        if (pharmacy.type === PharmacyType.RETAIL) {
          handleSubmit({ pharmacyName: pharmacy.name });
        } else {
          handleSubmit(undefined, PrevisitForms.PHARMACY_ADDRESS_CONFIRMATION);
        }
      }
    } catch (e: any) {
      handleError(e);
    }
    setIsSubmitting(false);
  };

  return (
    <FormContent css={layout.margin("gutter", "skip")}>
      <div style={layout.flexSpaceHorizontal}>
        <Title>{t("pharmacySelection.title")}</Title>

        <SetAddress
          variant="unstyled"
          textVariant="title2"
          buttonStyle="unstyled"
          textColor="textPrimary"
          analyticsParams={
            {
              user_flow: AnalyticsUserFlow.GET_VIRTUAL_URGENT_CARE,
              source: "TelehealthPharmacySearch",
            } as ScreenViewParams
          }
        />
      </div>

      <TextInput
        style={{ height: 48 }}
        css={layout.margin("skip", "skip", 40)}
        placeholder={t("pharmacySelection.placeholder")}
        value={searchTerm}
        name="pharmacySearchTerm"
        before={<Search />}
        onChange={handleChange}
      />
      <SegmentedControl
        title={t("pharmacySelection.pharmacySegmentOptions")}
        onItemSelected={(value) => handleToggle(value as string)}
        value={pharmacyType}
        options={SEGMENT_OPTIONS}
      />
      {(isSubmitting || loading) && (
        <>
          <div css={{ marginTop: 50 }}></div>
          <Loading variant="list" />
        </>
      )}
      {!isSubmitting && !loading && filteredSearchResults && filteredSearchResults.length > 0 && (
        <List
          as="Buttons"
          data={filteredSearchResults}
          hideLastChildBorder
          renderItem={({ name, address }: awsdk.AWSDKPharmacy) => (
            <PharmacyItem name={name} address={address.address1} />
          )}
          onClick={(pharmacy) => {
            submitForm(pharmacy);
          }}
        />
      )}
      {!isSubmitting && !loading && filteredSearchResults && filteredSearchResults.length === 0 && (
        <div css={{ textAlign: "center", marginTop: 50 }}>
          <Text variant="body1Bold">{t("pharmacySelection.noPharmacies")}</Text>
        </div>
      )}
    </FormContent>
  );
};

export default PharmacySelection;
