import { useAmwellQuery } from "/hook";
import useAmwell from "/hook/useAmwell";

export const useAmwellInsurance = () => {
  const { sdk, consumer, isReady } = useAmwell();
  const fetch = async () => {
    if (sdk && consumer && isReady) {
      const healthPlans = await sdk.getHealthPlans();
      const relationships = await sdk.getRelationshipsToSubscriber();
      const subscription = await sdk.consumerService.getInsuranceSubscription(consumer);
      return {
        healthPlans,
        relationships,
        subscription,
      };
    }
    return;
  };
  return useAmwellQuery(fetch);
};

export default useAmwellInsurance;
