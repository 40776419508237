import styled from "styled-components";

import { layout } from "/styles";

export const TableBody = styled.tbody(({ theme }) => ({
  "& > tr:not(:last-of-type)": {
    borderBottom: `1px solid ${theme.colors.objectSubtle}`,
  },
}));

export const TableCell = styled.td<{ $align?: "end" | "start" }>(
  layout.flexItemIntrinsic,
  layout.flexVertical,
  ({ $align }) =>
    $align === "end"
      ? { ...layout.flexEndHorizontal, maxWidth: "50%", textAlign: "end" }
      : { maxWidth: "50%", textAlign: "start" },
);
