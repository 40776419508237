import { MutationHookOptions, useMutation } from "@apollo/client";

import UpdateLinkedPhoneNumbergql from "/apollo/mutation/updateLinkedPhoneNumber";
import { UpdateLinkedPhoneNumber, UpdateLinkedPhoneNumberVariables } from "/apollo/schema";

export const useUpdateLinkedPhoneNumber = (
  options?: MutationHookOptions<UpdateLinkedPhoneNumber, UpdateLinkedPhoneNumberVariables>,
) =>
  useMutation<UpdateLinkedPhoneNumber, UpdateLinkedPhoneNumberVariables>(
    UpdateLinkedPhoneNumbergql,
    options,
  );
