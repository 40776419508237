import * as Icons from "react-feather";

import { createIconComponent, HelpCircle } from "./Icon";
import { IconNameProps } from "./Icon.types";

/**
 * Given a `name` string, check to see if that icon exists in the Feather icon set.
 * If it doesn't exist we will default to `HelpCircle`. This Icon will function exactly
 * the same as the Icons that are exported individually from './Icon'.
 */
export const FeatherIcon = ({ name, ...svgProps }: IconNameProps) => {
  // Icon names are coming in as all lowercase when react-feather shows it beginning with an uppercase
  // ex. calendar translates to Calendar, credit-card translates to CreditCard
  const iconCase = name
    .split("-")
    .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
    .join("");
  const Icon = Icons[iconCase];

  return Icon ? createIconComponent(Icon)(svgProps) : HelpCircle(svgProps);
};
