import { gql, useQuery } from "@apollo/client";

import { UserAccount } from "/apollo/schema/types";

const GET_USER_ACCOUNT = gql`
  query UserAccount($isAuthenticated: Boolean = false, $useMyAccount: Boolean) {
    auth @client {
      isAuthenticated @export(as: "isAuthenticated")
    }
    viewer @include(if: $isAuthenticated) {
      user {
        account(useMyAccount: $useMyAccount) {
          firstName
          lastName
          email
          phoneNumber
          verificationStatus
          emailVerificationStatus
          phoneVerificationStatus
          gender
          line1
          line2
          city
          state
          postalCode
        }
      }
    }
  }
`;

// useQuery is fully typed and return value is inferred.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useUserAccount = (variables?: { useMyAccount: boolean }) =>
  useQuery<UserAccount>(GET_USER_ACCOUNT, {
    fetchPolicy: "no-cache",
    variables,
  });
