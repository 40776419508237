import styled from "styled-components";

import { layout } from "/styles";

export const DateRangeWrapper = styled.div(
  layout.spacedChildrenVertical(),
  layout.flexVertical,
  ({ theme }) => ({
    [theme.media.tablet]: {
      flexDirection: "row",
      justifyContent: "space-between",

      "> *": { marginTop: 0 },
    },
  }),
);
