import { TFunction } from "i18next";

export type GetSubtitle = (
  selectedOptions: {
    email?: boolean | null;
    emailNotification?: boolean | null;
    paperMail?: boolean | null;
    phoneCall?: boolean | null;
    phoneText?: boolean | null;
    ringlessCall?: boolean | null;
    smsNotification?: boolean | null;
  } | null,
  t: TFunction,
) => string;

export const preferenceKeys = {
  appointmentReminders: "appointmentReminders",
  dealsAndPromotions: "dealsAndPromotions",
  patientInformation: "patientInformation",
  prescriptions: "prescriptions",
  referrals: "referrals",
  caregivers: "caregivers",
} as const;

export type PreferenceKey =
  | typeof preferenceKeys.appointmentReminders
  | typeof preferenceKeys.dealsAndPromotions
  | typeof preferenceKeys.patientInformation
  | typeof preferenceKeys.prescriptions;

export interface PreferenceGroup {
  subtitle: string;
  title: string;
  to: string;
}
