import { useField } from "formik";
import React, { forwardRef } from "react";

import InputWrapper from "/component/base/InputWrapper";
import Picker from "/component/base/Picker";

import { PickerFieldType } from "./PickerField.types";

const PickerField: PickerFieldType = forwardRef(
  (
    {
      className,
      options,
      label,
      inputWrapperVariant = "primary",
      name,
      before,
      placeholder,
      onChange: onChangeProp,
      hideRadio = false,
      isHTML,
      variant,
    },
    ref,
  ) => {
    const [field, meta, helpers] = useField(name);

    return (
      <InputWrapper
        variant={inputWrapperVariant}
        className={className}
        error={meta.error}
        htmlFor={field.name}
        label={label}
        isHTML={isHTML}
        touched={meta.touched}
      >
        <Picker
          variant={variant}
          before={before}
          options={options}
          value={options.find(({ value }) => value === field.value)}
          onChange={(changedValue) => {
            helpers.setValue(changedValue.value);
            if (onChangeProp) {
              onChangeProp(changedValue);
            }
          }}
          ref={ref}
          placeholder={placeholder}
          hideRadio={hideRadio}
        />
      </InputWrapper>
    );
  },
);

export default PickerField;
