/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";

import { Radio } from "/component/base";
import RadioGroupField from "/component/partial/formik/RadioGroupField";

import { InputProps } from "../../DecisionSupportForm.types";
import { OptionsWrapper } from "./BoolField.styles";

const BoolField = ({ element, isHTML }: InputProps) => {
  return (
    <RadioGroupField
      isHTML={isHTML}
      name={element.fieldName!}
      label={element.questionText!}
      inputWrapperVariant="expanded-label"
    >
      <OptionsWrapper>
        <RadioGroupField.Option value={"true"}>
          {({ checked }) => (
            <Radio
              label={
                <RadioGroupField.Label variant="body1Bold">
                  {element.trueLabel!}
                </RadioGroupField.Label>
              }
              checked={checked}
              onChange={() => null}
              labelBefore={false}
            />
          )}
        </RadioGroupField.Option>
        <RadioGroupField.Option value={"false"}>
          {({ checked }) => (
            <Radio
              label={
                <RadioGroupField.Label variant="body1Bold">
                  {element.falseLabel!}
                </RadioGroupField.Label>
              }
              checked={checked}
              onChange={() => null}
              labelBefore={false}
            />
          )}
        </RadioGroupField.Option>
      </OptionsWrapper>
    </RadioGroupField>
  );
};

export default BoolField;
