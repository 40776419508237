import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import useLazyDemographics from "/b2c/query/useLazyDemographics.b2c";
import { useCaregiverProvider } from "/component/provider/CaregiverProvider";
import routes from "/constant/url.constant";
import { useAmwell, useAuth, useCedarPendingInvoiceCount, useGoogleMaps } from "/hook";
import { initializeAddress } from "/util/address.util";
import { SIGN_UP_V2_ENABLED } from "/util/auth.util";

const useAppInit = () => {
  const { logout: amwellLogout } = useAmwell();
  const { isAuthenticated, login, loginWithPopup, logout, isReady: authIsReady } = useAuth(true);
  const [loadPatientFailed, setLoadPatientFailed] = useState(false);
  const [getPatientData] = useLazyDemographics();
  const { clearCaregiver } = useCaregiverProvider();
  const history = useHistory();
  const { updateInvoiceCount } = useCedarPendingInvoiceCount();
  const googleMaps = useGoogleMaps();

  // Update pending Cedar invoice count on app boot
  useEffect(() => {
    updateInvoiceCount();
  }, [updateInvoiceCount]);

  // When a user authenticates, check if they have a patient record. If they
  // don't, push to the demographic form page.
  const [patientExistsIsReady, setPatientExistsIsReady] = useState(false);
  const [patientExists, setPatientExists] = useState(false);

  const handleLogout = async () => {
    clearCaregiver();
    await logout();
    amwellLogout();
  };

  useEffect(() => {
    let isCancelled = false;
    const maybeShowDemographicForm = async () => {
      try {
        const { data, loading, error } = await getPatientData();

        if (!(loading || error) && data?.patient) {
          setPatientExists(true);
          if (!SIGN_UP_V2_ENABLED) history.push(routes.demographics);
        }
        const demographics = data?.patient.demographics;
        await initializeAddress(
          googleMaps,
          demographics?.address1,
          demographics?.city,
          demographics?.state,
          demographics?.zipCode,
        );
        if (!isCancelled) {
          setPatientExistsIsReady(true);
        }
      } catch {
        setLoadPatientFailed(true);
      }
    };

    if (authIsReady && !isCancelled) {
      if (isAuthenticated && !patientExists) {
        if (!SIGN_UP_V2_ENABLED) maybeShowDemographicForm();
        else setPatientExistsIsReady(true);
      } else {
        // The user is not authenticated, so we already know the patient doesn't exist
        setPatientExistsIsReady(true);
      }
    }
    return () => {
      isCancelled = true;
    };
  }, [isAuthenticated, authIsReady, getPatientData]);

  return {
    isAuthenticated,
    login,
    loginWithPopup,
    logout: handleLogout,
    isReady: authIsReady && patientExistsIsReady,
    loadPatientFailed,
    setLoadPatientFailed,
  };
};

export default useAppInit;
