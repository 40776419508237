export enum CaregiverFormType {
  AddCaregiver = "ADD_CAREGIVER",
  InviteCaregiver = "INVITE_CAREGIVER",
  AddChild = "ADD_CHILD",
  ChildVerification = "CHILD_VERIFICATION",
}

export enum CaregiverType {
  ADULT = "ADULT",
  CHILD = "CHILD",
}
