import { ApolloError } from "@apollo/client";
import { OperationVariables } from "@apollo/client";
import { GraphQLError } from "graphql";

import { B2CQueryResult } from ".";

export enum ErrorCode {
  INVALID_AUTHENTICATION = "INVALID_AUTHENTICATION", // Token is invalid or expired
  NETWORK_FAILED = "NETWORK_FAILED", // User network is having some issues
  ACCOUNT_NOT_FOUND = "ACCOUNT_NOT_FOUND", // Cognito/Azure account is NOT associated with a patient account in Athena
  RESOURCES_NOT_FOUND = "RESOURCES_NOT_FOUND", // None of the request resources where found
  PARTIAL_RESPONSE = "PARTIAL_RESPONSE", // You have some data but you also have some errors
  UNKNOWN_ERROR = "UNKNOWN_ERROR", // You don't have data but we don't know error you are having
}
export const getErrorCode = (error: ApolloError, result?: any): ErrorCode => {
  const { networkError, message } = error;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (networkError?.statusCode === 401) {
    return ErrorCode.INVALID_AUTHENTICATION;
  }

  if (message === "Network request failed") {
    return ErrorCode.NETWORK_FAILED;
  }

  if (
    error.graphQLErrors.length === 1 &&
    error.graphQLErrors[0]?.path?.join("") === "patient" &&
    error.graphQLErrors[0]?.extensions?.status_code === 404
  ) {
    return ErrorCode.ACCOUNT_NOT_FOUND;
  }

  if (result && hasFoundNothing(error, result)) {
    return ErrorCode.RESOURCES_NOT_FOUND;
  }

  if (error && hasDataInResponse(result)) {
    return ErrorCode.PARTIAL_RESPONSE;
  }

  return ErrorCode.UNKNOWN_ERROR;
};

export const hasFoundNothing = (error: ApolloError, response: any, path = ""): boolean => {
  const getAllErrorPaths = (errors: GraphQLError[]) =>
    errors.map((e: GraphQLError) => e?.path?.join("."));
  const notFoundErrors = error.graphQLErrors.filter(
    (graphQLError: GraphQLError) => parseInt(graphQLError?.extensions?.status_code) === 404,
  );

  const errorPaths = getAllErrorPaths(notFoundErrors);
  const isNothingFound = Object.keys(response).every((key) => {
    const keyPath = path ? `${path}.${key}` : key;
    const value = response[key];

    if ((value === null || value === undefined) && errorPaths.includes(keyPath)) {
      return true;
    }

    if (typeof value === "object" && !Array.isArray(value) && value !== null) {
      return hasFoundNothing(error, value, keyPath);
    }

    return false;
  });

  return isNothingFound;
};

export const hasDataInResponse = (response: any): boolean => {
  let depth = 0;

  while (depth <= 2) {
    depth++;
    for (const key in response) {
      if (depth === 2 && response[key] !== null && response[key] !== undefined) {
        return true;
      }

      if (
        depth === 1 &&
        typeof response[key] === "object" &&
        !Array.isArray(response[key]) &&
        response[key] !== null
      ) {
        response = response[key];
      }
    }
  }

  return false;
};

export const filterKnownErrors = <TData, TVariables extends OperationVariables>(
  queryResult: B2CQueryResult<TData, TVariables>,
): B2CQueryResult<TData, TVariables> => {
  if (!queryResult.errors) {
    return queryResult;
  }

  const errors = queryResult.errors.filter((b2cError) => {
    return !(b2cError.extensions?.status_code === 404 && b2cError.path?.includes("provider"));
  });

  return {
    ...queryResult,
    errors: errors,
  };
};
