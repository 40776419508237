import React from "react";

import { TestResult, TestResultType } from "/b2c/query/useTestResults.b2c";
import * as Icons from "/component/base/Icon";
import Text from "/component/base/Text";
import { layout } from "/styles";
import { formatDate } from "/util/date";

import { ContentWrapper, TextContent, TitleContent } from "./ResultListItem.styles";

interface Props {
  result: TestResult;
}

const ResultCard = ({ result }: Props) => {
  const { description, date, type } = result;

  const getIcon = () => {
    return type === TestResultType.LAB ? (
      <Icons.LabResult
        color="brandSecondary"
        background={{ size: 48, color: "brandSecondaryLight" }}
      />
    ) : (
      <Icons.ImagingResult
        color="brandSecondary"
        background={{ size: 48, color: "brandSecondaryLight" }}
      />
    );
  };
  return (
    <div
      css={[
        layout.flexCenterHorizontal,
        layout.margin("standard", "none"),
        layout.spacedChildrenHorizontal("standard"),
      ]}
    >
      <div>{getIcon()}</div>
      <ContentWrapper>
        <TextContent>
          <TitleContent>
            <Text color={"textPrimary"} variant="body1Bold">
              {description}
            </Text>
          </TitleContent>

          <Text variant="body2" color="textSecondary">
            {formatDate(date, "MMMM dd, yyyy")}
          </Text>
        </TextContent>
      </ContentWrapper>
    </div>
  );
};

export default ResultCard;
