import { DateObj, useDayzed } from "dayzed";
import React, { useRef } from "react";

import { Button, Text } from "/component/base";
import { ChevronLeft, ChevronRight } from "/component/base/Icon";
import { useTranslation } from "/hook";
import { formatDate } from "/util/date";

import {
  Base,
  CalendarFooter,
  CalendarGrid,
  Day,
  DayContainer,
  DaysOfWeek,
  NavGroup,
} from "./Calendar.styles";
import { DayVariant, Props } from "./Calendar.types";
import useKeyBoardNavigation from "./useKeyBoardNavigation";

const DAY_BUTTON_CLASS = "selectable-day";

const Calendar = ({ className, ...dayzedProps }: Props) => {
  const { t } = useTranslation("base-calendar");

  // Dayzed isn't very good at handling `null` values, so convert null date/selected to undefined
  const adjustedProps = { ...dayzedProps };
  if (adjustedProps.date === null) adjustedProps.date = undefined;
  if (adjustedProps.selected === null) adjustedProps.selected = undefined;

  const startDate = adjustedProps.startDate?.toLocaleDateString();
  const endDate = adjustedProps.endDate?.toLocaleDateString();
  const { calendars, getBackProps, getForwardProps, getDateProps } = useDayzed(adjustedProps);
  const calRef = useRef<HTMLDivElement | null>(null);
  const shortWeekdayStrings = [
    t("dayOfWeek.short.0"),
    t("dayOfWeek.short.1"),
    t("dayOfWeek.short.2"),
    t("dayOfWeek.short.3"),
    t("dayOfWeek.short.4"),
    t("dayOfWeek.short.5"),
    t("dayOfWeek.short.6"),
  ];

  useKeyBoardNavigation(`.${DAY_BUTTON_CLASS}`, calRef);

  const renderDay = (dateObj: DateObj, index: number, monthKey: string) => {
    const key = `${monthKey}${dateObj.date.getDate()}${index}`;
    const { date, selected, selectable, today } = dateObj;
    let props = getDateProps({
      dateObj: dateObj,
      className: selectable ? DAY_BUTTON_CLASS : undefined,
      disabled: !selectable,
      // we use the arrow keys to navigate dates. By removing
      // the tabbing of the buttons here, we allow the user to
      // tab up to month navigation.
      tabIndex: -1,
    });
    const currentDate = date.toLocaleDateString();
    if (selected && currentDate === startDate && startDate !== endDate && endDate !== undefined) {
      props = {
        ...props,
        variant: "start" as DayVariant,
      };
    } else if (selected && currentDate === endDate && startDate !== endDate) {
      props = {
        ...props,
        variant: "end" as DayVariant,
      };
    } else if (
      selected &&
      currentDate !== startDate &&
      currentDate !== endDate &&
      startDate !== endDate
    ) {
      props = {
        ...props,
        variant: "range" as DayVariant,
      };
    } else if (selected) {
      props = {
        ...props,
        variant: "selected" as DayVariant,
      };
    } else if (today && selectable) {
      props = {
        ...props,
        variant: "today" as DayVariant,
      };
    }

    const dayContainerProps = { variant: props.variant };
    return (
      <DayContainer key={key} {...dayContainerProps}>
        <Day key={key} {...props}>
          {date.getDate()}
        </Day>
      </DayContainer>
    );
  };

  if (calendars.length) {
    return (
      <Base className={className} ref={calRef}>
        {calendars.map((calendar) => {
          const monthKey = `${calendar.month}${calendar.year}`;
          const monthString = formatDate(new Date(calendar.year, calendar.month), "MMMM Y");

          return (
            <div key={`${monthKey}`}>
              <NavGroup>
                <button {...getBackProps({ calendars, "aria-label": t("goToPrevMonth") })}>
                  <ChevronLeft />
                </button>
                <Text aria-live="polite" color="textTitle" variant="body1Bold">
                  {monthString}
                </Text>
                <button {...getForwardProps({ calendars, "aria-label": t("goToNextMonth") })}>
                  <ChevronRight />
                </button>
              </NavGroup>
              <DaysOfWeek>
                {shortWeekdayStrings.map((weekday, i) => (
                  <Text variant="body2Bold" color="textSecondary" key={`${monthKey}${weekday}${i}`}>
                    {weekday}
                  </Text>
                ))}
              </DaysOfWeek>
              <CalendarGrid firstDayOfMonth={calendar.firstDayOfMonth.getDay()}>
                {calendar.weeks.map((week) => {
                  const weekWithDateObjs = week.filter((d) => !!d) as DateObj[];
                  return weekWithDateObjs.map((dateObj, index) =>
                    renderDay(dateObj, index, monthKey),
                  );
                })}
              </CalendarGrid>
              {adjustedProps.isMultiple && (
                <CalendarFooter>
                  <Button variant="borderBottom" onClick={adjustedProps.onClear}>
                    {t("clear")}
                  </Button>
                  <Button
                    variant="borderBottom"
                    onClick={adjustedProps.onClick}
                    disabled={!startDate && !endDate ? true : false}
                  >
                    {t("ok")}
                  </Button>
                </CalendarFooter>
              )}
            </div>
          );
        })}
      </Base>
    );
  }
  return null;
};

export default Calendar;
