import { useReactiveVar } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { address } from "/apollo/client/local";
import { AddressState } from "/apollo/client/local/address/address.types";
import LogoWordMark from "/asset/image/summitCityMDLogoNew.svg";
import Button from "/component/base/Button";
import Text from "/component/base/Text";
import TextDivider from "/component/base/TextDivider";
import Tooltip from "/component/base/Tooltip";
import AddressCombobox from "/component/partial/AddressCombobox";
import { useToastContext } from "/component/provider/ToastProvider";
import routes from "/constant/url.constant";
import { useTranslation } from "/hook";
import useAuth from "/hook/useAuth";
import useGoogleMaps from "/hook/useGoogleMaps";
import { googlePlaces } from "/util";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  logEvent,
  ScreenViewParams,
} from "/util/firebase.util";

import SendPasswordResetModal from "../SendPasswordResetModal";
import {
  Description,
  Modal,
  StepOneContent,
  StepOneFooter,
  StepOneFooterVertical,
  StepTwoContent,
  StepTwoFooter,
  StepTwoHeader,
  StepTwoSubtitle,
  StepTwoTitle,
  Title,
} from "./OnboardingModal.styles";

const SIGN_UP_V2_ENABLED = process.env.SIGN_UP_V2_ENABLED === "true";

interface Props {
  onClose?: () => void;
  redirectPage?: string;
}

const OnboardingModal = ({ redirectPage, onClose }: Props) => {
  const { login } = useAuth();
  const { t } = useTranslation("onboarding");
  const { showToast } = useToastContext();
  const history = useHistory();

  const addressStored = useReactiveVar<AddressState>(address.var);

  const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);
  const [onBoardingModalStep, setOnboardingModalStep] = useState(0);
  const [onboardingModalIsOpen, setOnboardingModalIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [locationPermissionDenied, setLocationPermissionDenied] = useState<boolean | string>(false);

  const googleMaps = useGoogleMaps();

  const logButtonClickEvent = (buttonName: string) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.ONBOARDING_NEW,
      source: AnalyticsSource.ONBOARDING_SIGN_IN,
      button_name: buttonName,
    } as ButtonClickParams);
  };

  useEffect(() => {
    if (onBoardingModalStep === 0) {
      logEvent(AnalyticsEvent.SCREEN_VIEW, {
        screenName: AnalyticsSource.ONBOARDING_SIGN_IN,
      } as ScreenViewParams);
    } else {
      logEvent(AnalyticsEvent.SCREEN_VIEW, {
        screenName: AnalyticsSource.ONBOARDING_LOCATION,
      } as ScreenViewParams);
    }
  }, [onBoardingModalStep]);

  const updateAddress = (placeDetails: google.maps.places.PlaceResult) => {
    const extractedAddress = googlePlaces.extractAddress(placeDetails?.address_components || []);
    setIsLoading(false);
    if (extractedAddress && placeDetails?.place_id) {
      googlePlaces.updateAddress(extractedAddress, placeDetails);
      setOnboardingModalIsOpen(false);
    }
  };

  const grabCurrentLocation = async () => {
    if (googleMaps) {
      setIsLoading(true);
      try {
        const placeDetails = await googlePlaces.getAddressCurrentLocation(googleMaps);

        if (placeDetails) {
          updateAddress(placeDetails);
        }
      } catch (error: any) {
        const { err, friendlyMessage } = error as {
          err: GeolocationPositionError;
          friendlyMessage: string;
        };

        if (err.code) {
          switch (err.code) {
            case err.PERMISSION_DENIED:
              setLocationPermissionDenied(friendlyMessage);
              break;
            case err.POSITION_UNAVAILABLE:
            case err.TIMEOUT:
              showToast({
                icon: "alert",
                message: friendlyMessage as string,
                type: "error",
              });
              setIsLoading(false);
              break;
          }
        } else {
          throw err;
        }
      }
    }
  };

  const onClickSkip = () => {
    logButtonClickEvent("Skip for now");
    if (addressStored.formattedAddress) {
      setOnboardingModalIsOpen(false);
      onClose && onClose();
    } else {
      setOnboardingModalStep(1);
    }
  };

  return (
    <>
      <SendPasswordResetModal
        isOpen={showPasswordResetModal}
        close={() => setShowPasswordResetModal(false)}
      />

      <Modal isOpen={onboardingModalIsOpen && !showPasswordResetModal}>
        {onBoardingModalStep === 0 ? (
          <StepOneContent>
            <Title>
              <Text
                css={{ fontSize: 37, letterSpacing: -0.3 }}
                variant="headline"
                color="textTitle"
              >
                {t("stepOneTitle")}
              </Text>
              <br />
              <div style={{ width: "100%", height: "auto" }}>
                <img src={LogoWordMark} alt="Summit Health+CityMD Logo" />
              </div>
            </Title>
            <Description>
              <Text color="textSecondary">{t("stepOneSubtitle")}</Text>
            </Description>
            {SIGN_UP_V2_ENABLED ? (
              <StepOneFooterVertical>
                <Button
                  onClick={() => {
                    logButtonClickEvent("Create account");
                    history.push(routes.signUp, { redirectPage });
                  }}
                >
                  {t("stepOneSignUp")}
                </Button>
                <TextDivider label={t("or")} />
                <Button
                  variant="secondary"
                  onClick={() => {
                    logButtonClickEvent("Sign in");
                    login(redirectPage);
                  }}
                >
                  {t("stepOneSignIn")}
                </Button>

                <div css={{ display: "flex", justifyContent: "space-between", marginTop: 42 }}>
                  <Button
                    onClick={() => {
                      logButtonClickEvent("Forgot password");
                      setShowPasswordResetModal(true);
                    }}
                    variant="borderBottom"
                  >
                    {t("forgotPassword")}
                  </Button>
                  <Button onClick={onClickSkip} variant="borderBottom">
                    {t("stepOneSkip")}
                  </Button>
                </div>
              </StepOneFooterVertical>
            ) : (
              <StepOneFooter>
                <Button onClick={() => login()}>{t("stepOneSignInSignUp")}</Button>
                <Button onClick={onClickSkip} variant="borderBottom">
                  {t("stepOneSkip")}
                </Button>
              </StepOneFooter>
            )}
          </StepOneContent>
        ) : (
          <StepTwoContent>
            <StepTwoHeader>
              <Title>
                <StepTwoTitle variant="title1" color="textTitle">
                  {t("stepTwoTitle")}
                </StepTwoTitle>
              </Title>

              <Description>
                <StepTwoSubtitle color="textPrimary">{t("stepTwoSubtitle")}</StepTwoSubtitle>
              </Description>
            </StepTwoHeader>
            <StepTwoFooter>
              <div css={{ position: "relative" }}>
                <Button isDisabled={isLoading} fullWidth="percent" onClick={grabCurrentLocation}>
                  {locationPermissionDenied
                    ? t("stepTwoLocationPermissionDenied")
                    : t("stepTwoLocation")}
                </Button>

                {locationPermissionDenied && (
                  <Tooltip
                    anchor={
                      <Tooltip.Anchor
                        // This explictly has to use `width: '100%', height: '100%',
                        // or it doesn't work. That is, `layout.absoluteFill` does
                        // not work for this case
                        css={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    }
                    location="top"
                  >
                    {locationPermissionDenied}
                  </Tooltip>
                )}
              </div>
              <TextDivider label={t("or")} />
              <AddressCombobox
                placeholder={t("addressPlaceholder")}
                onSelectAddress={(selectedAddress) => {
                  updateAddress(selectedAddress);
                }}
              />
            </StepTwoFooter>
          </StepTwoContent>
        )}
      </Modal>
    </>
  );
};

export default OnboardingModal;
