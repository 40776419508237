import styled from "styled-components";

import BaseSpinner from "/component/base/Spinner";

export const desktopIconSize = 32;
export const mobileIconSize = 54;

export const Base = styled.ul(({ theme }) => ({
  [theme.media.tablet]: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));

export const Spinner = styled(BaseSpinner)<{ $isMobile: boolean }>(({ $isMobile }) => {
  // Ensure that the spinner is the same size as the icon it replaces, which are different
  // sizes on mobile and desktop
  if ($isMobile) {
    return {
      width: mobileIconSize,
      objectFit: "contain",
    };
  } else {
    return {
      height: desktopIconSize,
      objectFit: "contain",
    };
  }
});
