import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";

import { AdministrativeGender, StateCode } from "/apollo/schema/types";
import { Modal } from "/component/base";
import Button from "/component/base/Button";
import { ModalProps } from "/component/base/Modal";
import AddressForm from "/component/form/AddressForm";
import { FormValues as DemographicWizardFormValues } from "/component/form/DemographicFormWizard/DemographicFormWizard.types";
import { useUserAccount } from "/component/layout/MainLayout/useUserAccount";
import useDemographics from "/component/page/Demographics/useDemographics";
import PickerField from "/component/partial/formik/PickerField";
import TextField from "/component/partial/formik/TextField";
import { useToastContext } from "/component/provider/ToastProvider";
import { useTranslation } from "/hook";
import { layout } from "/styles";
import { reactPlugin } from "/util/appInsights.util";

import { FormContentContainer, SubTitle, Title } from "./DemographicModal.styles";

const DemographicModal = ({ isOpen, close }: ModalProps) => {
  const { t } = useTranslation("confirmDemographics");
  const { data } = useUserAccount();
  const { handleSubmit } = useDemographics({});
  const { showToast } = useToastContext();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submitForm = async (values: DemographicWizardFormValues) => {
    const res = await handleSubmit(values);
    if (res.data?.createPatient.errors?.[0]) {
      showToast({
        icon: "alert",
        message: res.data?.createPatient.errors[0].message,
        type: "error",
      });
    } else {
      close && close();
    }
  };

  const demographicFormSchema = Yup.object({
    firstName: Yup.string().required(t("error.required")).min(2, t("error.minLength")),
    lastName: Yup.string().required(t("error.required")).min(2, t("error.minLength")),
    sex: Yup.string().required("Required"),
    address: Yup.object().shape({
      line1: Yup.string()
        .required(t("error.required"))
        .matches(/^((?![*&%$#@?]).)*$/, t("error.address.invalidChar")),
      line2: Yup.string(),
      city: Yup.string()
        .required(t("error.required"))
        .matches(/^((?![*&%$#@?]).)*$/, t("error.address.invalidChar")),
      state: Yup.string().required(t("error.required")),
      postalCode: Yup.string()
        .required(t("error.required"))
        .matches(/^\d{5}(-\d{4})?$/, t("error.address.postalCode.format")),
    }),
  });

  const accountFirstName = data?.viewer?.user.account.firstName || "";
  const accountLastName = data?.viewer?.user.account.lastName || "";
  const accountGender = data?.viewer?.user.account.gender?.toUpperCase();
  const accountAddressLine1 = data?.viewer?.user.account.line1;
  const accountAddressLine2 = data?.viewer?.user.account.line2;
  const accountState = data?.viewer?.user.account.state;
  const accountPostalCode = data?.viewer?.user.account.postalCode;
  const accountCity = data?.viewer?.user.account.city;

  return (
    <Modal isOpen={isOpen} close={close} header={<Modal.Header close={close} />}>
      <div style={layout.flexCenter}>
        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{
            firstName: accountFirstName,
            lastName: accountLastName,
            sex: accountGender as AdministrativeGender,
            address: {
              line1: accountAddressLine1 || "",
              line2: accountAddressLine2 || "",
              city: accountCity || "",
              state: accountState as StateCode,
              postalCode: accountPostalCode || "",
            },
          }}
          validationSchema={demographicFormSchema}
          onSubmit={submitForm}
        >
          {({ isSubmitting, isValid }) => (
            <FormContentContainer>
              <Form>
                <div css={layout.flexVerticalCenter}>
                  <Title>{t("title")}</Title>
                  <SubTitle>{t("subtitle")}</SubTitle>
                </div>
                <div css={[layout.spacedChildrenVertical("standard")]}>
                  <TextField
                    label={t("firstName")}
                    placeholder={t("firstName")}
                    name="firstName"
                    type="text"
                  />
                  <TextField
                    label={t("lastName")}
                    placeholder={t("lastName")}
                    name="lastName"
                    type="text"
                  />
                  <PickerField
                    css={layout.flexVertical}
                    label={t("patientIdentity.legalSex")}
                    placeholder={t("patientIdentity.legalSexPlaceholder")}
                    name="sex"
                    options={[
                      {
                        label: "Female",
                        value: "FEMALE",
                      },
                      {
                        label: "Male",
                        value: "MALE",
                      },
                    ]}
                  />
                  <AddressForm />
                </div>
                <FormContentContainer.ButtonGroup>
                  <Button
                    variant="primary"
                    isDisabled={!isValid}
                    type="submit"
                    isLoading={isSubmitting}
                  >
                    {t("continue")}
                  </Button>
                </FormContentContainer.ButtonGroup>
              </Form>
            </FormContentContainer>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default withAITracking(reactPlugin, DemographicModal, undefined, "ai-tracking");
