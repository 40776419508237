import styled from "styled-components";

import { layout } from "/styles";

export const ContentWrapper = styled.div(layout.flexLTR, {
  width: "100%",
});

export const TextContent = styled.div(layout.flexVertical, layout.spacedChildrenVertical("base"), {
  width: "100%",
});

export const TitleContent = styled.div(layout.flexSpaceHorizontal);
