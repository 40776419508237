import { makeVar } from "@apollo/client";

import { AddressState, AddressStorageKey } from "./address.types";
export * as Types from "./address.types";

const addressInitialValue = {
  city: undefined,
  lat: 0,
  line1: undefined,
  lng: 0,
  state: undefined,
  postalCode: undefined,
  placeId: undefined,
  formattedAddress: undefined,
};

function createAddress(initialValue?: AddressState) {
  if (!initialValue) {
    const addressStored = localStorage.getItem(AddressStorageKey);
    if (addressStored) initialValue = JSON.parse(addressStored);
  }

  const reactiveVar = makeVar<AddressState>(initialValue || addressInitialValue);

  return {
    var: reactiveVar,
    update(value: Partial<AddressState>) {
      const currentVal = reactiveVar();
      const newValue = { ...currentVal, ...value };
      localStorage.setItem(AddressStorageKey, JSON.stringify(newValue));
      return reactiveVar(newValue);
    },
    read(): AddressState {
      return reactiveVar();
    },
  };
}

export default createAddress();
