import { makeVar } from "@apollo/client";

interface CedarPaymentsState {
  pendingInvoiceCount?: number;
}

const reactiveVar = makeVar<CedarPaymentsState>({});

export default {
  var: reactiveVar,
  update(value: Partial<CedarPaymentsState>) {
    const currentVal = reactiveVar();
    return reactiveVar({ ...currentVal, ...value });
  },
  read() {
    return reactiveVar();
  },
};
