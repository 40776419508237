import { withAITracking } from "@microsoft/applicationinsights-react-js";
import awsdk from "awsdk";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Route, RouteComponentProps, Switch } from "react-router-dom";

import { Button } from "/component/base";
import { Edit } from "/component/base/Icon";
import MainContent from "/component/layout/MainContent";
import ComposeMessageModal from "/component/modal/ComposeMessageModal";
import Inbox from "/component/page/Account/page/Inbox";
import Outbox from "/component/page/Account/page/Outbox";
import TopTabBar from "/component/partial/TopTabBar";
import routes from "/constant/url.constant";
import { useAmwellMessageContacts, useTranslation } from "/hook";
import { reactPlugin } from "/util/appInsights.util";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  IndentifierParams,
  logEvent,
} from "/util/firebase.util";

const Messages = ({ match }: RouteComponentProps) => {
  const [showCompose, setShowCompose] = useState(false);
  const [selectedContact, setSelectedContact] = useState<awsdk.AWSDKSecureMessageContact>();
  const { data: contacts, loading } = useAmwellMessageContacts();
  const { t } = useTranslation("messages");
  const providerContacts = contacts?.filter(({ acceptsSecureMessage }) => acceptsSecureMessage);

  const logButtonClickEvent = (buttonName: string, params?: IndentifierParams) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.MESSAGES,
      source: AnalyticsSource.MESSAGES,
      button_name: buttonName,
      ...params,
    } as ButtonClickParams);
  };

  const handleCloseModal = () => {
    setShowCompose(false);
    setSelectedContact(undefined);
  };

  const handleProviderClick = (provider: { id: string }) => {
    logButtonClickEvent("Provider", { provider_id: provider.id });
    const selectedContact = providerContacts?.find(
      (providerContact) => provider.id === providerContact.id.persistentId,
    );
    setSelectedContact(selectedContact);
    setShowCompose(true);
  };

  return (
    <MainContent
      headerAccessory={
        <Button
          aria-label={t("label.composeMessage")}
          isDisabled={loading}
          onClick={() => {
            setShowCompose(true);
            logButtonClickEvent("Compose message");
          }}
          variant="unstyled"
        >
          <Edit
            aria-hidden
            background={{ color: "brandSecondary", size: 48 }}
            color="objectInverse"
          />
        </Button>
      }
      pageTitle={t("title")}
      parentPage="account"
    >
      <Helmet>
        <title>{t("title")}</title>
      </Helmet>

      <TopTabBar
        links={[
          {
            isActive: match.path === routes.messaging,
            label: t("tab.inbox"),
            to: routes.messaging,
            replace: true,
          },
          {
            isActive: match.path === routes.sentMessages,
            label: t("tab.outbox"),
            to: routes.sentMessages,
            replace: true,
          },
        ]}
      />

      <Switch>
        <Route
          exact
          path={routes.messaging}
          render={(props) => (
            <Inbox contacts={providerContacts} onProviderClick={handleProviderClick} {...props} />
          )}
        />
        <Route component={Outbox} exact path={routes.sentMessages} />
      </Switch>

      <ComposeMessageModal
        initialContactValue={selectedContact}
        contacts={providerContacts}
        isOpen={showCompose}
        close={handleCloseModal}
      />
    </MainContent>
  );
};

export default withAITracking(reactPlugin, Messages, undefined, "ai-tracking");
