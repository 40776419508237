// Modules
import React from "react";

// Styles
import { Image, ImageText } from "./Avatar.styles";
// Types
import { Props } from "./Avatar.types";

/**
 * Passing an undefined for `src` will try to convert
 * `alt` text to be first character of each word
 */
const Avatar = ({ src, alt, initials, ...rest }: Props) => {
  return !src && initials ? (
    <ImageText
      aria-label={alt}
      color="textSecondary"
      variant={rest.size === "xSmall" ? "body2Bold" : "title3"}
      {...rest}
    >
      {initials}
    </ImageText>
  ) : (
    <Image alt={alt} src={src || undefined} {...rest} />
  );
};

export default Avatar;
