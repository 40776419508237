import * as crypto from "crypto-js";

export const encryptPassword = (input: string) => {
  const keyArrayBuffer = crypto.lib.WordArray.random(32);
  const ivArrayBuffer = crypto.lib.WordArray.random(16);

  const key = keyArrayBuffer.toString(crypto.enc.Base64);
  const iv = ivArrayBuffer.toString(crypto.enc.Base64);
  const cipher = crypto.AES.encrypt(input, keyArrayBuffer, {
    iv: ivArrayBuffer,
  });
  const encryptedPass = cipher.toString();

  const passwordObject = {
    key,
    iv,
    password: encryptedPass,
  };

  return passwordObject;
};
