import React, { Fragment } from "react";

import ListItem from "../ListItem";
import { BaseLi, ListDivider } from "./List.styles";
import { ButtonProps, DivProps, LinkProps, ListItemWrapperProps } from "./List.types";

/**
 * This ListItemWrapper component provides the semantic li
 * elements of the list.
 */
const ListItemWrapper = ({ children, $hideResponsiveStates = false }: ListItemWrapperProps) => (
  <BaseLi $hideResponsiveStates={$hideResponsiveStates}>{children}</BaseLi>
);

/**
 * The List component should be used to render a standard list of ListItems, whether they are
 * Buttons or Link variants, controlled by the `as` prop. This component also handles the Divider
 * usage and visiblity.
 */
const List: <T>(props: ButtonProps<T> | DivProps<T> | LinkProps<T>) => React.ReactElement = (
  props,
) => {
  let children: React.ReactNode[] = [];

  const isLastItemInList = (index: number): boolean => {
    return !!props.hideLastChildBorder && index === props.data.length - 1;
  };

  // Figure out if we are rendering a list of Links, Buttons, or Divs. Note: we cannot destructure the props
  // above; if we do destructure TypeScript cannot relate the `as` prop to the `data` prop, and we
  // lose the inference that determines what our data type is.
  if (props.as === "Links") {
    children = props.data.map((item, index) => (
      <Fragment key={index}>
        <ListItemWrapper>
          <ListItem to={item.to} showBorder={props.showBorder} showChevron={props.showChevron}>
            {props.renderItem(item, index)}
          </ListItem>
        </ListItemWrapper>
        {isLastItemInList(index) ? null : <ListDivider />}
      </Fragment>
    ));
  } else if (props.as === "Buttons") {
    children = props.data.map((item, index) => (
      <Fragment key={index}>
        <ListItemWrapper>
          <ListItem.Button
            onClick={() => props.onClick(item)}
            disabled={props.isDisabled && props.isDisabled(item)}
            showBorder={props.showBorder}
            showChevron={props.showChevron}
            chevronVariant={props.chevronVariant}
          >
            {props.renderItem(item, index)}
          </ListItem.Button>
        </ListItemWrapper>
        {isLastItemInList(index) ? null : <ListDivider />}
      </Fragment>
    ));
  } else if (props.as === "Divs") {
    children = props.data.map((item, index) => (
      <Fragment key={index}>
        <ListItemWrapper $hideResponsiveStates>
          <ListItem.Div showBorder={props.showBorder} showChevron={props.showChevron}>
            {props.renderItem(item, index)}
          </ListItem.Div>
        </ListItemWrapper>
        {isLastItemInList(index) ? null : <ListDivider $isListItemClickable={false} />}
      </Fragment>
    ));
  }

  return <ul className={props.className}>{children}</ul>;
};

export default List;
