import React from "react";

import { Cross } from "/component/base/Icon";
import BackButton from "/component/partial/BackButton";
import { useTranslation } from "/hook";

import { closeButtonSize, Header as Base, HeaderTitle } from "./Modal.styles";

export type Props = {
  title?: string;
  hideHeaderLeft?: boolean;
  headerLeft?: React.ReactNode;
  headerRight?: React.ReactNode;
  close?: () => void;
};

const Header = ({
  hideHeaderLeft,
  headerLeft: headerLeftProp,
  title,
  headerRight,
  close,
}: Props) => {
  const { t } = useTranslation("modalHeader");
  let headerLeft: React.ReactNode;

  if (!hideHeaderLeft) {
    headerLeft = headerLeftProp ?? (
      <BackButton aria-label={t("close")} onClick={close} icon="close">
        <Cross
          aria-hidden
          background={{ color: "objectInverse", size: closeButtonSize }}
          color="brandSecondary"
        />
      </BackButton>
    );
  }

  return (
    <Base padRight={!!(headerLeft && !headerRight)} hasTitle={!!title}>
      {headerLeft}
      {title && <HeaderTitle variant="title3">{title}</HeaderTitle>}
      {headerRight}
    </Base>
  );
};

export default Header;
