import { useMutation } from "@apollo/client";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { RouteComponentProps } from "react-router";
import * as Yup from "yup";

import createFeedbackGql from "/apollo/mutation/createFeedback";
import { CreateFeedback, CreateFeedbackVariables } from "/apollo/schema";
import { Button } from "/component/base";
import { Check } from "/component/base/Icon";
import Text from "/component/base/Text";
import MainContent from "/component/layout/MainContent";
import FormContent from "/component/partial/FormContent";
import PickerField from "/component/partial/formik/PickerField";
import TextAreaField from "/component/partial/formik/TextAreaField";
import { useToastContext } from "/component/provider/ToastProvider";
import routes from "/constant/url.constant";
import { useTranslation } from "/hook";
import { layout } from "/styles";
import { reactPlugin } from "/util/appInsights.util";

import { FeedbackAlert, FormContentContainer, IconWrapper } from "./Feedback.styles";
import { useFeedbackTopicsQuery } from "./useFeedbackTopicsQuery";

const Feedback = ({ history }: RouteComponentProps) => {
  const { t } = useTranslation("feedback");
  const { showToast } = useToastContext();

  const [createFeedback] = useMutation<CreateFeedback, CreateFeedbackVariables>(createFeedbackGql);

  const [isFeedbackSubmittedAlertOpen, setFeedbackSubmittedAlert] = useState(false);

  const { data } = useFeedbackTopicsQuery();
  const topics = data?.feedbackTopics || [];
  const topicOptions = topics.map((topic) => {
    return { label: topic.title, value: topic.tag };
  });

  const feedbackSchema = Yup.object({
    topic: Yup.string().required("Required"),
    feedback: Yup.string().required("Required"),
  });

  const submitForm = async (values: { topic: string; feedback: string }) => {
    const _topic = topicOptions.find((t) => t.value === values.topic)?.label;

    try {
      await createFeedback({
        variables: {
          data: {
            topic: _topic || values.topic,
            feedback: values.feedback,
            platform: "web",
          },
        },
      });
      setFeedbackSubmittedAlert(true);
    } catch (e: any) {
      showToast({
        icon: "alert",
        message: t("toast.error"),
      });
    }
  };

  return (
    <MainContent pageTitle={t("headline")} parentPage="account">
      <Helmet>
        <title>{t("title")}</title>
      </Helmet>

      <Formik
        validateOnMount
        enableReinitialize
        initialValues={{ topic: "", feedback: "" }}
        validationSchema={feedbackSchema}
        onSubmit={submitForm}
      >
        {({ isSubmitting, isValid, values, setFieldValue, setTouched }) => (
          <Form>
            <FormContentContainer css={layout.spacedChildrenVertical("expanded")}>
              <div>
                <Text variant="title3">{t("topic.title")}</Text>
                <PickerField
                  css={[layout.flexVertical, layout.margin("expanded", "none", "none", "none")]}
                  placeholder={t("topic.placeholder")}
                  name="topic"
                  options={topicOptions}
                />
              </div>
              <div>
                <Text variant="title3">{t("feedback.title")}</Text>
                <TextAreaField
                  rows={4}
                  label={" "}
                  placeholder={t("feedback.placeholder")}
                  maxLength={500}
                  name="feedback"
                />
              </div>

              <FormContent.ButtonGroup>
                <Button
                  variant="primary"
                  isDisabled={!isValid}
                  type="submit"
                  isLoading={isSubmitting}
                >
                  {t("submit")}
                </Button>
              </FormContent.ButtonGroup>

              <FeedbackAlert
                close={() => null}
                footer={
                  <>
                    <Button
                      css={layout.marginHorizontal(60)}
                      fullWidth="flex"
                      onClick={() => {
                        setFeedbackSubmittedAlert(false);
                        history.push(routes.root);
                      }}
                    >
                      {t("dialog.feedbackSubmitted.confirmLabel")}
                    </Button>
                    <Button
                      css={layout.marginHorizontal(60)}
                      variant="secondary"
                      fullWidth="flex"
                      onClick={() => {
                        setFieldValue("topic", "");
                        setFieldValue("feedback", "");
                        setTouched({});
                        setFeedbackSubmittedAlert(false);
                      }}
                    >
                      {t("dialog.feedbackSubmitted.cancelLabel")}
                    </Button>
                  </>
                }
                title={
                  values.topic === "bug_report"
                    ? t("dialog.feedbackSubmitted.bugReportTitle")
                    : t("dialog.feedbackSubmitted.feedbackTitle")
                }
                isOpen={isFeedbackSubmittedAlertOpen}
              >
                <div css={[layout.flexVerticalCenter, layout.margin("expanded", 60, "none", 60)]}>
                  <IconWrapper>
                    <Check color="objectInverse" size={42} />
                  </IconWrapper>
                  {t("dialog.feedbackSubmitted.subtitle")}
                </div>
              </FeedbackAlert>
            </FormContentContainer>
          </Form>
        )}
      </Formik>
    </MainContent>
  );
};

export default withAITracking(reactPlugin, Feedback, undefined, "ai-tracking");
