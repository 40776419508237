import { gql, LazyQueryHookOptions, useLazyQuery } from "@apollo/client";

import { GetDocument, GetDocumentVariables } from "/apollo/schema/types";

const GET_DOCUMENT = gql`
  query GetDocument($id: String!) {
    document(id: $id) {
      contentType
      createdDate
      file
      title
    }
  }
`;

// useLazyQuery is fully typed and return value is inferred.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useQueryDocument = (
  options: LazyQueryHookOptions<GetDocument, GetDocumentVariables>,
) => useLazyQuery<GetDocument, GetDocumentVariables>(GET_DOCUMENT, options);
