import { gql, useQuery } from "@apollo/client";

import { FacilityOrg, GetFacilities } from "/apollo/schema";

const GET_URGENT_CARE_FACILITIES = gql`
  query GetFacilities($lat: Float!, $lng: Float!, $org: FacilityOrg, $radius: Float! = 50) {
    address @client {
      lat @export(as: "lat")
      lng @export(as: "lng")
    }
    facilities(org: $org, geo: { radius: $radius, lat: $lat, lng: $lng }) {
      facilityNameLong
      waitTimeSeconds
      address {
        city
        line1
        line2
        postalCode
        state
      }
      coordinates {
        lat
        lng
      }
      id
      urgentCareWalkIn
      hoursOfOperation {
        dayOfWeek
        open
        openingTime
        closingTime
      }
    }
  }
`;

export const useUrgentCareFacilities = (org?: FacilityOrg) =>
  useQuery<GetFacilities>(GET_URGENT_CARE_FACILITIES, { variables: { org } });
