// Modules
import styled from "styled-components";

import { layout } from "/styles";

export const ProgressBarContainer = styled.div(
  layout.spacedChildrenHorizontal("condensed"),
  () => ({
    display: "flex",
    alignItems: "center",
    width: "100%",
  }),
);

export const ProgressBarMain = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.backgroundSecondary,
  height: 10,
  flexGrow: 1,
  borderRadius: 5,
}));

export const ProgressBarFill = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.progressBar,
  height: "inherit",
  borderRadius: 5,
  transition: "0.3s ease",
  transitionDelay: "0.25s",
}));
