import { useReactiveVar } from "@apollo/client";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Prompt, useHistory, useLocation, useParams } from "react-router-dom";

import { scheduling } from "/apollo/client/local";
import { Loading, Text } from "/component/base";
import DecisionSupportForm from "/component/form/DecisionSupportForm";
import MainContent from "/component/layout/MainContent";
import routes from "/constant/url.constant";
import { useTranslation } from "/hook";
import theme from "/theme/default";
import { reactPlugin } from "/util/appInsights.util";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  logEvent,
} from "/util/firebase.util";

import { BackButtonContainer, Base } from "./DecisionSupport.styles";
import KickoutAlert from "./KickoutAlert";
import useDecisionSupport from "./useDecisionSupport";

const BackButton = ({ onClick }: { onClick: () => void }) => {
  return <MainContent.BackButton onClick={onClick} icon="back" />;
};

const CloseButton = ({ onClick }: { onClick: () => void }) => {
  return <MainContent.BackButton onClick={onClick} icon="close" />;
};

const DecisionSupport = () => {
  const { t } = useTranslation("scheduling");
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const variables = useReactiveVar(scheduling.var);

  const { flowId } = useParams<{ flowId: string }>();
  const {
    elements,
    elementSetRef,
    isFlowFinished,
    isLoading,
    flowSessionId,
    hasError,
    kickOutMessage,
    title,
    validationMessages,
    handleClose,
    handlePrevious,
    handleSubmit,
  } = useDecisionSupport(
    flowId,
    query.get("athenaId"),
    variables.analyticsFlow === AnalyticsUserFlow.PROVIDER_SEARCH,
  );

  const [skipPrompt, setSkipPrompt] = useState(false);
  // skip prompt when any of these conditions are met
  const showPrompt = !(isFlowFinished || !!kickOutMessage || hasError || skipPrompt);

  const logButtonClickEvent = () => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.SCHEDULE_VISIT,
      source: AnalyticsSource.DECISION_SUPPORT_FORM,
      button_name: "Close (icon)",
    } as ButtonClickParams);
  };

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && (
        <MainContent
          parentPage="root"
          pageTitle={title ?? undefined}
          backButton={
            <BackButtonContainer>
              <BackButton
                onClick={() => {
                  setSkipPrompt(true);
                  setTimeout(() => {
                    handlePrevious();
                    setTimeout(() => {
                      setSkipPrompt(false);
                    }, 500);
                  }, 500);
                }}
              />
              <CloseButton
                onClick={() => {
                  logButtonClickEvent();
                  setSkipPrompt(false);
                  setTimeout(() => {
                    history.replace(routes.root);
                  }, 500);
                }}
              />
            </BackButtonContainer>
          }
        >
          <Helmet>
            <title>{t("title")}</title>
          </Helmet>
          <Base>
            <Prompt when={showPrompt} message={""} />
            {!!kickOutMessage && <KickoutAlert message={kickOutMessage} onClose={handleClose} />}
            {validationMessages.length !== 0 && (
              <ul aria-live="polite">
                {validationMessages.map((message) => (
                  <Text
                    css={{
                      "& > a:visited": {
                        color: theme.colors.brandSecondary,
                      },
                      "& > a": {
                        color: theme.colors.brandSecondary,
                      },
                    }}
                    dangerouslySetInnerHTML={{ __html: message || "" }}
                    element="li"
                    key={message}
                    color="textSecondary"
                  />
                ))}
              </ul>
            )}
            {flowSessionId && elementSetRef && !!elements.length && (
              <DecisionSupportForm
                flowSessionId={flowSessionId}
                elementSetRef={elementSetRef}
                elements={elements}
                handleSubmit={handleSubmit}
              />
            )}
          </Base>
        </MainContent>
      )}
    </>
  );
};

export default withAITracking(reactPlugin, DecisionSupport, undefined, "ai-tracking");
