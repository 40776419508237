import styled from "styled-components";

import { layout } from "/styles";

export const ButtonContainer = styled.div(
  layout.spacedChildrenHorizontal("standard"),
  ({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    "> *": {
      flex: 1,
    },
    [theme.media.max.tabletPortrait]: {
      ...layout.flexVertical,
      ...layout.spacedChildrenHorizontal("none")({ theme }),
      "& > *": {
        padding: theme.spacing[12],
        width: "100%",
        marginTop: theme.spacing.condensed,
      },
    },
  }),
);

export const ProviderListContainer = styled.ul(({ theme }) => ({
  marginBottom: theme.spacing.base * 12,
  marginTop: theme.spacing.base * 12,
}));
