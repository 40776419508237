/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { gql, MutationHookOptions } from "@apollo/client";

import { useB2CMutation } from "../client";
import {
  deletePreferredPharmacy,
  deletePreferredPharmacyVariables,
  preferredPharmacyUpdate,
  preferredPharmacyUpdateVariables,
  SET_DEFAULT_PHARMACY,
  SET_DEFAULT_PHARMACYVariables,
} from "../schema/types";

export const MUTATION_SET_DEFAULT_PHARMACY = gql`
  mutation SET_DEFAULT_PHARMACY($ncpdpId: String!) {
    preferredPharmacy {
      setDefault(defaultPharmacy: { ncpdpId: $ncpdpId }) {
        success
      }
    }
  }
`;

export const ADD_PREFERRED_PHARMACY = gql`
  mutation preferredPharmacyUpdate($ncpdpId: String!) {
    preferredPharmacy {
      add(preferredPharmacy: { ncpdpId: $ncpdpId }) {
        success
      }
    }
  }
`;

export const DELETE_PREFERRED_PHARMACY = gql`
  mutation deletePreferredPharmacy($clinicalProviderId: Int) {
    preferredPharmacy {
      delete(preferredPharmacy: { clinicalProviderId: $clinicalProviderId }) {
        success
      }
    }
  }
`;

export const useSetDefaultPharmacy = (
  options: MutationHookOptions<SET_DEFAULT_PHARMACY, SET_DEFAULT_PHARMACYVariables>,
) => {
  return useB2CMutation<SET_DEFAULT_PHARMACY, SET_DEFAULT_PHARMACYVariables>(
    MUTATION_SET_DEFAULT_PHARMACY,
    options,
  );
};

export const useAddPreferredPharmacy = (
  options: MutationHookOptions<preferredPharmacyUpdate, preferredPharmacyUpdateVariables>,
) =>
  useB2CMutation<preferredPharmacyUpdate, preferredPharmacyUpdateVariables>(
    ADD_PREFERRED_PHARMACY,
    options,
  );

export const useDeletePreferredPharmacy = (
  options: MutationHookOptions<deletePreferredPharmacy, deletePreferredPharmacyVariables>,
) =>
  useB2CMutation<deletePreferredPharmacy, deletePreferredPharmacyVariables>(
    DELETE_PREFERRED_PHARMACY,
    options,
  );
