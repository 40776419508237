import { isAfter, isBefore } from "date-fns";
import i18n from "i18next";

import { GetFacility_facility_hoursOfOperation } from "/apollo/schema/types";
import { parseDateTime } from "/util/date";

export const isFacilityOpen = (
  hoursOfOperation: GetFacility_facility_hoursOfOperation[] | null,
): boolean => {
  if (!hoursOfOperation) return false;

  const today = new Date();
  const hoursToday = hoursOfOperation[today.getDay()];
  let isOpen = false;
  if (hoursToday.openingTime && hoursToday.closingTime) {
    const openingDateTime = parseDateTime(hoursToday.openingTime);
    const closingDateTime = parseDateTime(hoursToday.closingTime);

    if (isAfter(today, openingDateTime) && isBefore(today, closingDateTime)) {
      isOpen = true;
    }
  }
  return isOpen;
};

export const getEstimatedWaitTime = (waitTimeSeconds: number) => {
  const waitTimeMinutes = waitTimeSeconds / 60;
  if (waitTimeMinutes < 5) {
    return i18n.t("facilityUtil-lessThan");
  } else if (waitTimeMinutes > 45) {
    return i18n.t("facilityUtil-moreThan");
  }

  return i18n.t("facilityUtil-timeMinutes", { minutes: waitTimeMinutes });
};
