import { gql } from "@apollo/client";

export default gql`
  mutation SendCaregiverReminder($data: SendCaregiverAccessReminderInput!) {
    sendCaregiverAccessReminder(data: $data) {
      errors {
        message
        code
      }
    }
  }
`;
