import { gql } from "@apollo/client";

export default gql`
  mutation bookAppointment($data: BookAppointmentInput!) {
    bookAppointment(data: $data) {
      errors {
        code
        message
      }
      appointmentReferenceId
    }
  }
`;
