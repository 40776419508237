import React from "react";
import { BrowserRouter } from "react-router-dom";

import CustomPrompt from "/component/partial/CustomPrompt";

const RouterProvider = ({ children }: { children?: React.ReactNode }) => {
  const [confirm, setConfirm] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [confirmCallback, setConfirmCallback] = React.useState<any>(null);

  // `getUserConfirmation` will be called when ever the user is prompted (via the
  // ReactRouter#Prompt component) to confirm navigating away from the page. If there are any
  // components subscribed to this event, via the `useRedirectListener` hook, they will be notified
  // with the result of the user's action.
  return (
    <BrowserRouter
      getUserConfirmation={(message, callback) => {
        setConfirmCallback(() => callback);
        setConfirm(true);
      }}
    >
      {confirm && <CustomPrompt confirmCallback={confirmCallback} setConfirm={setConfirm} />}
      {children}
    </BrowserRouter>
  );
};

export default RouterProvider;
