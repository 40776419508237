import React from "react";

import { AvailableSoon, Checkmark, MultiplePatients, SinglePatient } from "/component/base/Icon";
import Text from "/component/base/Text";
import { ProviderStatus } from "/component/page/Telehealth/TelehealthIndex.types";
import { useTranslation } from "/hook";
import { Colors } from "/theme/default";

import { Availability } from "./AvailabilityStatus.styles";
import { Props } from "./AvailabilityStatus.types";

const AvailabilityStatus = ({ status, waitingRoomCount }: Props) => {
  const { t } = useTranslation("telehealth");
  const availabilityTexts: Record<ProviderStatus, string> = {
    [ProviderStatus.Available]: t("availableNow"),
    [ProviderStatus.Busy]: t("availableSoon"),
    [ProviderStatus.Offline]: t("offline"),
    [ProviderStatus.WaitingRoom]: t("amountWaiting", { count: waitingRoomCount }),
  };
  const availabilityText = status && availabilityTexts[status];

  const availabilityColors: Record<ProviderStatus, Colors> = {
    [ProviderStatus.Available]: "statusPositive",
    [ProviderStatus.Busy]: "textNavyBlue",
    [ProviderStatus.Offline]: "textSecondary",
    [ProviderStatus.WaitingRoom]: "textSecondary",
  };
  const iconColor = status && availabilityColors[status];

  const availabilityIcons: Record<ProviderStatus, JSX.Element> = {
    [ProviderStatus.Available]: <Checkmark size={16} color={"statusPositive"} />,
    [ProviderStatus.Busy]: <AvailableSoon size={16} color={"textNavyBlue"} />,
    [ProviderStatus.Offline]: <></>,
    [ProviderStatus.WaitingRoom]:
      waitingRoomCount === 1 ? (
        <SinglePatient size={16} color={"textSecondary"} />
      ) : (
        <MultiplePatients size={16} color={"textSecondary"} />
      ),
  };

  const videoIcon = status && availabilityIcons[status];

  return (
    <Availability>
      {videoIcon}
      <Text variant="body2Medium" color={iconColor}>
        {availabilityText}
      </Text>
    </Availability>
  );
};
export default AvailabilityStatus;
