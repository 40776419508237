import React, { useState } from "react";

import { useModalContext } from "/component/provider/ModalProvider";
import Transition from "/component/util/Transition";

import Header from "./Header";
import {
  Base,
  Body,
  Description,
  Footer,
  FooterWithLargeButton,
  ModalContainer,
  Overlay,
  Title,
} from "./Modal.styles";
import { Props } from "./Modal.types";

// Normally you would want the `className` prop to be on the outer-most tag in your component.
// But modal is different, if a dev wanted to position or change the size of a Modal they would
// need to apply their class to `ModalContainer`
const Modal = ({
  className,
  children,
  isOpen,
  close,
  footer,
  header,
  isStatic,
  modalBody,
}: Props) => {
  const { unshiftModalEl, removeModalEl } = useModalContext();
  const [lastModalEl, setLastModalEl] = useState<HTMLDivElement>();

  // Set the current modalʼs containing element on the ModalProvider. Any
  // component can `useModalContext()` and then get access to the active modalʼs
  // DOM ref. This is useful for components like `Picker`s, or any others that
  // need to be rendered inside the same React Portal as their modal parent.
  const innerRef = (modalEl: HTMLDivElement) => {
    if (modalEl === null) {
      removeModalEl(lastModalEl);
    } else {
      unshiftModalEl(modalEl);
    }

    setLastModalEl(modalEl);

    return () => removeModalEl(lastModalEl);
  };

  const ModalBody = modalBody ?? Body;

  return (
    <Transition show={isOpen}>
      {() => (
        // Children are specified as a function in order to prevent the
        // Transition component from clobbering the ref value.
        <Base open={isOpen} onClose={() => close && close()} ref={innerRef} static={isStatic}>
          <Transition.Child>
            <Overlay />
          </Transition.Child>

          <Transition.Child>
            <ModalContainer className={className}>
              {header}
              <ModalBody style={{ overflow: "auto" }}>{children}</ModalBody>
              {footer}
            </ModalContainer>
          </Transition.Child>
        </Base>
      )}
    </Transition>
  );
};

// ====================================
// Sub-components
// ====================================

Modal.Description = Description;
Modal.Title = Title;

Modal.Header = Header;
Modal.Footer = Footer;
Modal.FooterWithLargeButton = FooterWithLargeButton;

export default Modal;
