// Modules
import styled from "styled-components";

import { layout } from "/styles";

const DemographicsLayout = styled.div(
  ({ theme }) => ({
    width: "100%",
    minHeight: "100%",
    paddingTop: "5vh",
    paddingBottom: "5vh",
    backgroundColor: theme.colors.backgroundSecondary,
  }),
  layout.flexCenter,
);

export default DemographicsLayout;
