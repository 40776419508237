import styled from "styled-components";

import { Button, Text } from "/component/base";
import { getVariantStyle, VariantStyleProps } from "/component/base/Button/Button.styles";
import { layout } from "/styles";

export const ActionButtons = styled.div(layout.flexVerticalCenter, ({ theme }) => ({
  marginTop: theme.spacing.base * 9,
  maxWidth: 327,
  marginLeft: "auto",
  marginRight: "auto",
}));

export const CallCenterButton = styled.a<VariantStyleProps>(
  layout.flexCenter,
  ({ theme, variant }) => getVariantStyle({ theme, $variant: variant || "primary" }),
  { width: "100%", marginTop: 68 },
);

export const SubtitleText = styled(Text)(({ theme }) => ({
  textAlign: "center",
  marginTop: theme.spacing.standard,
}));

export const IconText = styled(Text)(({ theme }) => ({
  marginLeft: theme.spacing.condensed,
}));

export const BottomButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing.standard,
}));
