export interface PersonalAddress {
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
}

export const buildAddressValue = (address: PersonalAddress | null) => {
  if (address) {
    const line1 = [address.address1, address.address2].filter((el) => el).join(", ");
    const line2 = [address.city, address.state, address.zipCode].filter((el) => el).join(", ");
    return `${line1}\n${line2}`;
  } else {
    return null;
  }
};
