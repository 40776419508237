import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { TFunction } from "i18next";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { FindCreateNotificationPreferences_viewer_user_billingAccounts } from "/apollo/schema";
import noMessages from "/asset/image/secureMessagingNoMessages.png";
import { List, Loading, Text } from "/component/base";
import MainContent from "/component/layout/MainContent";
import { useUserAccount } from "/component/layout/MainLayout/useUserAccount";
import EmailVerificationModal from "/component/modal/EmailVerificationModal/EmailVerificationModal";
import EmptyStateCta from "/component/partial/EmptyStateCta";
import routes from "/constant/url.constant";
import { layout } from "/styles";
import { reactPlugin } from "/util/appInsights.util";
import { SIGN_UP_V2_ENABLED } from "/util/auth.util";

import { ListContainer } from "./Notifications.styles";
import { GetSubtitle, PreferenceGroup, preferenceKeys } from "./Notifications.types";
import { useNotifications } from "./useNotifications";

const IMAGE_SIZE = 274;
const ENABLE_CAREGIVER_PROXY = process.env.ENABLE_CAREGIVER_PROXY === "true";
/**
 * getSubtitle — For a set of notification preferences, this fn returns a string
 * of appropriately translated list of the currently active preferences.
 */
const getSubtitle: GetSubtitle = (selectedOptions, t) => {
  if (!selectedOptions) {
    return t("preference.none");
  }

  const selections = Object.keys(selectedOptions)
    .map((key) => {
      if (!selectedOptions[key]) {
        return undefined;
      }

      switch (key) {
        case "patientInformation":
          return t("preferences.patientInformation");
        case "appointmentReminders":
          return t("preferences.appointmentReminders");
        case "prescriptions":
          return t("preferences.prescriptions");
        case "dealsAndPromotions":
          return t("preferences.dealsAndPromotions");
        case "emailNotification":
        case "email":
          return t("preference.email");
        case "smsNotification":
        case "phoneText":
          return t("preference.sms");
        case "ringlessCall":
          return t("preference.ringlessCall");
        case "phoneCall":
          return t("preference.phoneCall");
        case "paperMail":
          return t("preference.paperMail");
        default:
          return undefined;
      }
    })
    .filter((option) => !!option);

  return selections.length > 0 ? selections.join(", ") : t("preference.none");
};

/**
 * getBillingSubtitle — A user may have multiple billing accounts, but the
 * subtitle only displays a single set of active preferences. This fn reduces
 * multiple billing account settings to a single object of the active settings
 * from any billing account.
 */
const getBillingSubtitle = (
  billingAccounts: FindCreateNotificationPreferences_viewer_user_billingAccounts[],
  t: TFunction,
) => {
  const combinedAccountsSettings = billingAccounts
    .map(({ settings }) => {
      const activeKeys = settings ? Object.keys(settings).filter((key) => !!settings[key]) : [];

      return Object.fromEntries(activeKeys.map((key) => [key, true]));
    })
    .reduce(Object.assign, {});

  return getSubtitle(combinedAccountsSettings, t);
};

const Notifications = () => {
  const { t } = useTranslation("notifications");
  const { data, error, loading } = useNotifications();
  const history = useHistory();
  const translations = {
    [preferenceKeys.appointmentReminders]: t("title.appointments"),
    [preferenceKeys.dealsAndPromotions]: t("title.updates"),
    [preferenceKeys.patientInformation]: t("title.patientInfo"),
    [preferenceKeys.prescriptions]: t("title.prescriptions"),
    [preferenceKeys.referrals]: t("title.referrals"),
    [preferenceKeys.caregivers]: t("title.caregivers"),
  };

  const { data: userData, loading: userDataLoading } = useUserAccount();
  const [accountQueried, setAccountQueried] = React.useState(false);
  const [showEmailModal, setShowEmailModal] = React.useState(false);
  const emailVerification = userData?.viewer?.user?.account?.emailVerificationStatus;
  const firstName = userData?.viewer?.user.account.firstName || "";
  const lastName = userData?.viewer?.user.account.lastName || "";
  const patientName = `${firstName} ${lastName}`;
  const userEmail = userData?.viewer?.user.account.email || "";

  const preferenceGroups = Object.keys(data?.viewer?.user?.notificationPreference || [])
    .map((key) =>
      !!preferenceKeys[key] || (preferenceKeys[key] === "caregivers" && ENABLE_CAREGIVER_PROXY)
        ? {
            subtitle: getSubtitle(data?.viewer?.user?.notificationPreference?.[key], t),
            title: translations[key],
            to: `/account/notifications/${key}`,
          }
        : undefined,
    )
    .filter((group) => !!group) as PreferenceGroup[];

  const billingGroup = data?.viewer?.user?.billingAccounts?.length
    ? ({
        subtitle: getBillingSubtitle(data.viewer.user.billingAccounts, t),
        title: t("title.billing"),
        to: "/account/notifications/billing",
      } as PreferenceGroup)
    : undefined;

  const renderedGroups = billingGroup ? [...preferenceGroups, billingGroup] : preferenceGroups;
  const showEmptyState = error || renderedGroups.length < 1;

  useEffect(() => {
    if (!userDataLoading && !accountQueried) {
      setAccountQueried(true);
      if (
        SIGN_UP_V2_ENABLED &&
        emailVerification &&
        ["notVerified", "pending"].indexOf(emailVerification) >= 0
      ) {
        setShowEmailModal(true);
      }
    }
  }, [userDataLoading, accountQueried, emailVerification]);

  return (
    <MainContent pageTitle={t("title.notifications")} parentPage="account">
      {loading && showEmptyState && <Loading variant="page" />}

      {!loading && showEmptyState && (
        <EmptyStateCta
          imageHeight={IMAGE_SIZE}
          imageWidth={IMAGE_SIZE}
          imageSource={noMessages}
          subtitle={t("error.subtitle")}
          title={t("error.title")}
        />
      )}

      {!showEmptyState && (
        <ListContainer>
          <List
            as="Links"
            data={renderedGroups}
            hideLastChildBorder
            renderItem={({ subtitle, title }) => (
              <div css={[layout.flexVertical, layout.padding("standard", "skip")]}>
                <Text variant="body1Bold">{title}</Text>

                <Text color="textSecondary" variant="body2">
                  {subtitle}
                </Text>
              </div>
            )}
          />
        </ListContainer>
      )}
      <EmailVerificationModal
        isOpen={showEmailModal}
        email={userEmail}
        patientName={patientName}
        close={() => {
          setShowEmailModal(false);
          history.replace(routes.root);
        }}
      />
    </MainContent>
  );
};

export default withAITracking(reactPlugin, Notifications, undefined, "ai-tracking");
