import { TextVariants } from "./default.types";

export const fontWeights: { [Key in TextVariants | "bold" | "normal"]: "bold" | "normal" } = {
  body1: "normal",
  body1Medium: "normal",
  body1Bold: "bold",
  body1BoldItalic: "bold",
  body1Italic: "normal",
  body2: "normal",
  body2Medium: "normal",
  body2Bold: "bold",
  body2BoldItalic: "bold",
  body2Italic: "normal",
  body3: "normal",
  body3Bold: "bold",
  body3BoldItalic: "bold",
  body3Italic: "normal",
  bold: "bold",
  headline: "bold",
  navigation: "normal",
  navigationBold: "bold",
  normal: "normal",
  title1: "bold",
  title2: "bold",
  title3: "bold",
  buttonTitle: "normal",
};
