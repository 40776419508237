type AppointmentLike = {
  onlineCheckInEligibility: {
    isEligible: boolean | null;
  } | null;
  endDateTime: Date | null;
};

export const showCompleteIntake = (appt: AppointmentLike | null | undefined) => {
  if (!(appt && appt.endDateTime)) return false;
  const endDate = new Date(appt.endDateTime);
  const past = endDate.getTime() < new Date().getTime();

  return !past && appt.onlineCheckInEligibility?.isEligible;
};
