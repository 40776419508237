import {
  FacilityOrg,
  GetDiagnosticReport_diagnosticReport_observations,
  PersonalInfo_viewer_user_patient,
} from "/apollo/schema/types";
import cityMDLogo from "/asset/image/cityMDLogoNew.png";
import summitCityMDLogo from "/asset/image/summitCityMDLogoNew.svg";
import summitHealthLogo from "/asset/image/summitHealthLogoNew.png";
import theme from "/theme/default";
import { formatDate } from "/util/date";
import { formatAddress } from "/util/formatText";

export const generateResultsHTML = (
  patient: PersonalInfo_viewer_user_patient | null | undefined,
  observations: GetDiagnosticReport_diagnosticReport_observations[] | null | undefined,
  dosDateTime: string,
  resultsName: string,
  testSummary: string,
  reportLocation: string | null,
  sourceOrg: FacilityOrg | null,
  orderedBy: string | null,
): string => {
  const firstName = patient?.name?.firstName;
  const lastName = patient?.name?.lastName;
  const dob = patient?.birthDate ? formatDate(patient.birthDate, "MM/dd/yyyy") : "";
  const displayAddress = patient?.homeAddress ? formatAddress(patient.homeAddress) : null;
  let dosDateTimeString = formatDate(dosDateTime, "MMM d, yyyy") || "";

  if (observations && observations.length > 0) {
    const firstOb = observations[0];
    if (firstOb && firstOb.effectiveDate)
      dosDateTimeString = formatDate(firstOb.effectiveDate, "MMM d, yyyy hh:mm aa") || "";
  }

  return `<!doctype html>
  <html class="main">
    <head>
    <style>
      @font-face {
        font-family: "CeraPro";
        font-style: normal;
        font-weight: 400;
        src: url("https://storcl1digitalappblob.blob.core.windows.net/b2ctemplates/CeraPro-Regular.woff2")
          format("woff2"),
        url("https://storcl1digitalappblob.blob.core.windows.net/b2ctemplates/CeraPro-Regular.woff")
          format("woff");
      }

      .main {
        margin: 10px;
        font-family: 'CeraPro';
        font-size: 12px;
      }

      .h1{
        font-size:18px;
        text-transform: capitalize;
      }

      .vertical-margin {
        padding: 16px 0px;
      }

      .text-secondary {
        color: ${theme.colors.textSecondary};
      }

      #logos > :first-child  {
        margin-right: 8px;
        margin-top: 16px;
      }

      .range {
        font-weight: bold;
        color: ${theme.colors.statusNegative};
        margin-left: 10px;
      }

      .results-value {
        display:flex;
        align-items: center;
        padding: 4px 0px;
        font-weight:bold;
      }

      .elevatedSVG{
        margin-right:4px;
        margin-bottom:4px;
      }

      .elevatedSVG > svg {
        fill: ${theme.colors.textSecondary};
      }

      .headers{
        font-size:12px;
        padding: 8px 0px;
      }

      .header{
        margin-bottom: 6px;
        display:flex;
        color: ${theme.colors.textSecondary};
      }

      .line-height{
        line-height: 16px;
      }

      .header > div:first-child{
        min-width: 140px;
        font-weight: bold;
        color:${theme.colors.textPrimary};
      }

      .result-container{
        border-bottom: 1px solid ${theme.colors.objectSubtle};
        padding: 4px 0px;
        page-break-before: auto; 
        page-break-after: auto; 
        page-break-inside: avoid;
      }

      .abnormal{
        background-color: ${theme.colors.statusWarning};
      }

      .result {
        display:grid;
        grid-template-columns: 40% 30% 30%;
        column-gap: 10px;
        align-items: center;
        padding: 2px 0px;
        font-size: 12px;
      }

      .result-info {
        flex:1;
        margin-right: 10px;
        text-transform: capitalize;
      }

      .result-info > div:first-child{
        margin-bottom: 10px;
      }

      .result-details {
        text-align:left;
      }

      .results-header{
        margin: 12px 0px;
        display:grid;
        grid-template-columns: 40% 30% 30%;
        column-gap: 10px;
        align-items: center;
        color: ${theme.colors.textSecondary};
      }

      .small-text {
        font-size: 10px;
      }
      
    </style>
    </head>
    <body class="main">
        <div id="logos">
        ${generateHeaderImage(sourceOrg)}
      </div>
      <div class="vertical-margin">
        <h1 class="h1">
          ${resultsName}
        </h1>
      </div>
      ${generateHeader(
        dob || "",
        dosDateTimeString,
        firstName || "",
        lastName || "",
        displayAddress,
        reportLocation,
        orderedBy,
      )}
      <div class="headers">Result details</div>
      <div>
        ${testSummary || ""}
      </div>

      ${
        observations && observations.length > 0
          ? `<div class="results-header">
          <div class="small-text"><b>NAME</b></div>
          <div class="small-text"><b>VALUE</b></div>
          <div class="small-text"><b>REFERENCE RANGE</b></div>
        </div>
        ${buildObservationDetails(observations)}`
          : ""
      }
    </body>
  </html>`;
};

const generateHeaderImage = (sourceOrg: FacilityOrg | null) => {
  if (sourceOrg === FacilityOrg.SMG) {
    return `<img
      alt="Summit Health"
      border="0"
      data-proportionally-constrained="true"
      data-responsive="false"
      height="60"
      src=${summitHealthLogo}
      width="268"
    />`;
  } else if (sourceOrg === FacilityOrg.CMD) {
    return `<img
      alt="CityMD"
      border="0"
      data-proportionally-constrained="true"
      data-responsive="false"
      height="60"
      src=${cityMDLogo}
      width="164"
    />`;
  }

  return `<img
      alt="Summit Health + CityMD"
      border="0"
      data-proportionally-constrained="true"
      data-responsive="false"
      height="64"
      src=${summitCityMDLogo}
      width="329"
    />`;
};

const generateHeader = (
  dob: string,
  dosDateTimeString: string,
  firstName: string,
  lastName: string,
  address: string | null,
  reportLocation: string | null,
  orderedBy: string | null,
) => {
  return `
    <div class="headers">
      <div class="header">
        <div>Patient Name</div>
        <div>${firstName} ${lastName}</div>
      </div>
      <div class="header">
        <div>Patient DOB</div>
        <div>${dob}</div>
      </div>
      ${
        address
          ? `<div class="header">
      <div>Patient Address</div>
      <div>${address}</div>
      </div>
    `
          : ""
      }
      <br>
      ${
        orderedBy
          ? `<div class="header">
        <div>Ordered by</div>
        <div>${orderedBy}</div>
        </div>
      `
          : ""
      }
      <div class="header">
        <div>Date of Service</div>
        <div>${dosDateTimeString}</div>
      </div>
      <div class="header">
        ${
          reportLocation
            ? `<div>Location</div>
          <div>${reportLocation}</div>`
            : `<div>Test by</div>
        <div>Summit Health | CityMD Urgent Care - 1 Diamond Hill Rd, Berkeley Heights, NJ 07922</div>`
        } 
      </div>
      <div class="header">
        <div>Questions?</div>
        <div class="line-height" style="text-wrap:wrap">If you have questions about your results, please contact your provider's office. If you are unsure of your provider's office number, contact the Summit Health call center at 908-273-4300. If you were seen at a CityMD, call 855-624-8963.</div>
      </div>
    </div>`;
};

const buildObservationDetails = (
  obvservations?: GetDiagnosticReport_diagnosticReport_observations[] | null,
): string => {
  if (!obvservations) return "";
  return `${obvservations.map((observation) => buildObservationDetail(observation)).join("")}`;
};

const buildResults = (range: number, value: string | number) => {
  const rangeName = range === 0 ? "" : "abnormal";
  return `
      
    <div class="results-value">
      <div class="${rangeName}">
        ${
          range !== 0
            ? `<span class="elevatedSVG ${rangeName}">${
                range > 0
                  ? `<svg viewBox="0 0 16 16" height="8" width="8">
                        <path d="M 0,12 16,12 8,0 z"/>
                      </svg>`
                  : `<svg viewBox="0 0 16 16" height="8" width="8">
                        <path d="M 0,4 16,4 8,16 z"/>
                      </svg>`
              }
        </span>`
            : ""
        }
        <span>
          ${value}
        </span>
      </div>
      <b class="small-text range">${rangeName ? "Out of Range" : ""}</b>
    </div>
  `;
};

const buildObservationDetail = (
  obvservation: GetDiagnosticReport_diagnosticReport_observations,
): string => {
  const { elevated, lowered, name, notes, value, valueString, reference } = obvservation;
  const showValue = value || valueString || "";
  const showNotes =
    notes && notes.length > 0
      ? `<div class="small-text text-secondary">${notes
          .join("<br/>")
          .replace(new RegExp(`\r?\n`, "g"), "<br />")}</div>`
      : "";
  return `
  <div class="result-container">
    <div class="result">
      <div class="result-info">
        ${name}
      </div>
      <div class="result-details">
        ${buildResults(elevated ? 1 : lowered ? -1 : 0, showValue)}
      </div>
      <div class="text-secondary small-text">
          ${reference ? `Normal range: ${reference}` : ""}
      </div>
    </div>
    ${showNotes}
  </div>`;
};
