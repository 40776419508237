import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";

import Dashboard from "./page/Dashboard";

const HomeRoutes = ({ match }: RouteComponentProps) => {
  return (
    <Switch>
      <Route exact path={match.url} component={Dashboard} />
    </Switch>
  );
};

export default HomeRoutes;
