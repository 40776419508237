import React from "react";

import Text from "/component/base/Text";
import AvailabilityStatus from "/component/partial/AvailabilityStatus";
import { parseInitials } from "/util/formatText";

import {
  AvailabilityStatusContainer,
  ProviderAvatar,
  ProviderDetailsContainer,
  ProviderItemContainer,
  ProviderItemTitle,
} from "./TelehealthProviderItem.styles";
import { Props } from "./TelehealthProviderItem.types";

const TelehealthProviderItem = ({ provider, status, waitingRoomCount }: Props) => {
  const initials = parseInitials(provider);

  return (
    <ProviderItemContainer>
      <ProviderAvatar
        initials={initials}
        alt={`${provider.firstName} ${provider.lastName}`}
        src={provider.logoUrl && provider.hasImage ? provider.logoUrl : undefined}
        size={"small"}
      />

      <ProviderDetailsContainer>
        <ProviderItemTitle variant="title3">{provider.fullName}</ProviderItemTitle>
        <Text variant="body2" color="textSecondary">
          {provider.specialty.value}
        </Text>
        <AvailabilityStatusContainer>
          <AvailabilityStatus waitingRoomCount={waitingRoomCount} status={status} />
        </AvailabilityStatusContainer>
      </ProviderDetailsContainer>
    </ProviderItemContainer>
  );
};

export default TelehealthProviderItem;
