import { Listbox } from "@headlessui/react";
import styled from "styled-components";

import BaseRadio from "/component/base/Radio";
import Text from "/component/base/Text";
import { baseTransitionTime } from "/constant/animation.constant";
import { layout, transition } from "/styles";

export const AfterWrapper = styled.div(
  layout.flexCenter,
  layout.margin("skip", "skip", "skip", "standard"),
  layout.flexItemIntrinsic,
);

export const BeforeWrapper = styled.div(
  layout.flexCenter,
  layout.margin("skip", "standard", "skip", "skip"),
  layout.flexItemIntrinsic,
);

export const ListboxButton = styled(Listbox.Button)<
  {
    variant?: "primary" | "secondary";
    $error?: boolean;
    $focused?: boolean;
  } & React.HTMLProps<HTMLButtonElement>
>(layout.padding("none", "standard"), ({ variant, $error, $focused, theme }) => {
  const borderColor = $error ? theme.colors.brandSecondary : "transparent";
  const backgroundColor =
    variant === "secondary" ? theme.colors.white : theme.colors.brandPrimaryLight;
  const focusedBorderColor = $error ? theme.colors.brandSecondary : theme.colors.objectOverlay;

  return {
    outline: "none",
    alignItems: "center",
    backgroundColor: $error ? theme.colors.brandSecondaryLight : backgroundColor,
    border: `solid 2px ${$focused ? focusedBorderColor : borderColor}`,
    borderRadius: 30,
    display: "flex",
    height: 48,
    overflow: "hidden",
    transition: `border ${baseTransitionTime}ms ease`,

    "&:focus": {
      borderColor: focusedBorderColor,
    },

    "&:disabled": {
      opacity: 0.6,
    },
  };
});

export const ListItem = styled.li<{ $active: boolean }>(
  layout.flexSpaceHorizontal,
  layout.padding("condensed", "standard"),
  layout.margin("condensed", "skip"),
  ({ $active, theme }) => ({
    backgroundColor: $active ? theme.colors.brandPrimaryLight : "transparent",
    borderRadius: 10,
    cursor: "pointer",
    transition: "background 100ms ease",
  }),
);

export const ListWrapper = styled.div<{ $animateFromBottom: boolean }>(
  layout.flexVertical,
  ({ $animateFromBottom }) => transition.accordian($animateFromBottom),
  ({ theme }) => ({
    backgroundColor: theme.colors.backgroundPrimary,
    borderRadius: 16,
    boxShadow: theme.shadows.base,
    justifyContent: "flex-start",
    maxHeight: "40vh",
  }),
);

export const PickerText = styled(Text)({
  flex: "1 1 auto",
  textAlign: "start",
});

export const LabelText = styled(Text)({
  marginRight: 10,
});

export const Radio = styled(BaseRadio)(layout.margin("skip", "skip", "skip", "expanded"), {
  pointerEvents: "none",
});

export const Title = styled(Text)({ textAlign: "center" });
