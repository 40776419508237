import { withAITracking } from "@microsoft/applicationinsights-react-js";
import isEmpty from "lodash/fp/isEmpty";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import { PharmacySearch_pharmacies } from "/apollo/schema";
import { List, Loading, Text, TextInput } from "/component/base";
import { Search } from "/component/base/Icon";
import MainContent from "/component/layout/MainContent";
import PharmacyDetailModal from "/component/modal/PharmacyDetailModal";
import { SelectedPharmacy } from "/component/modal/PharmacyDetailModal/PharmacyDetailModal.types";
import PharmacyItem from "/component/partial/PharmacyItem";
import { useDebounce, useTranslation } from "/hook";
import { layout } from "/styles";
import { reactPlugin } from "/util/appInsights.util";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  logEvent,
} from "/util/firebase.util";

import usePharmacySearch from "./usePharmacySearch";

interface Pharmacy {
  pharmacy: SelectedPharmacy;
  id: string;
  facilityId: string | null;
}

const PreferredPharmacy = () => {
  const { t } = useTranslation("pharmacySearch");
  const [pharmacySearch, setPharmacySearch] = useState("");
  const [selectedPharmacy, setSelectedPharmacy] = useState<Pharmacy | null>();
  const [isOpen, setIsOpen] = useState(false);
  const [getPharmacies, { data, loading }] = usePharmacySearch();
  const debouncedSearchValue = useDebounce(pharmacySearch);

  // TODO: Replace modal with navigation to pharmacy detail screen.
  const handlePharmacyPress = ({
    address,
    contact,
    facilityId,
    id,
    name,
    pharmacyType,
  }: PharmacySearch_pharmacies) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.MY_HEALTH,
      source: AnalyticsSource.PREFERRED_PHARMACY_SEARCH,
      button_name: "Pharmacy",
      pharmacy_id: id,
      facility_id: facilityId,
    } as ButtonClickParams);

    setSelectedPharmacy({
      facilityId,
      id,
      pharmacy: {
        address,
        contact,
        name,
        pharmacyType,
      },
    });

    setIsOpen(true);
  };

  const handlePharmacyDetailClose = () => {
    setIsOpen(false);
    setSelectedPharmacy(null);
  };

  useEffect(() => {
    if (debouncedSearchValue) {
      getPharmacies({ variables: { search: debouncedSearchValue } });
    }
  }, [debouncedSearchValue]);

  const renderSearchResults = () => {
    return !isEmpty(pharmacySearch) && data && data.pharmacies.length > 0 ? (
      <List
        as="Buttons"
        onClick={handlePharmacyPress}
        data={data.pharmacies}
        renderItem={(pharmacy) => (
          <PharmacyItem name={pharmacy.name} address={pharmacy.address.line1} />
        )}
      />
    ) : (
      <Text color="textSecondary" css={[layout.flexCenter, layout.padding("expanded")]}>
        {t("noResults.title")}
      </Text>
    );
  };

  return (
    <MainContent parentPage="myHealth" pageTitle={t("title.selectPharmacy")}>
      <Helmet>
        <title>{t("title.selectPharmacy")}</title>
      </Helmet>

      <TextInput
        aria-label={t("label.search")}
        before={<Search />}
        css={layout.margin("skip", "skip", "expanded")}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setPharmacySearch(e.currentTarget.value);
        }}
        placeholder={t("placeholder.search")}
        value={pharmacySearch}
      />

      {loading ? (
        <Loading />
      ) : isEmpty(pharmacySearch) ? (
        <Text color="textSecondary" css={[layout.flexCenter, layout.padding("expanded")]}>
          {t("searchPharmacy")}
        </Text>
      ) : (
        renderSearchResults()
      )}
      {selectedPharmacy && (
        <PharmacyDetailModal
          pharmacy={selectedPharmacy.pharmacy}
          pharmacyId={selectedPharmacy.id}
          facilityId={selectedPharmacy.facilityId}
          isOpen={isOpen}
          close={handlePharmacyDetailClose}
        />
      )}
    </MainContent>
  );
};

export default withAITracking(reactPlugin, PreferredPharmacy, undefined, "ai-tracking");
