import { gql } from "@apollo/client";

export default gql`
  mutation createCedarPayment(
    $mrn: String!
    $org: ScmdOrganization!
    $paymentMethodId: String!
    $amount: String!
    $savePaymentMethod: Boolean!
  ) {
    createCedarPayment(
      data: {
        mrn: $mrn
        org: $org
        payment: {
          paymentMethodId: $paymentMethodId
          paymentMethodType: CREDIT_CARD
          amount: $amount
          savePaymentMethod: $savePaymentMethod
        }
      }
    ) {
      errors {
        code
        message
      }
      payment {
        publicId
      }
    }
  }
`;
