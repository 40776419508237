import { address } from "/apollo/client/local";
import { AddressStorageKey } from "/apollo/client/local/address/address.types";
import { googlePlaces } from "/util";

export const initializeAddress = async (
  googleMaps: typeof google.maps | undefined,
  address1?: string | null,
  city?: string | null,
  state?: string | null,
  zipCode?: string | null,
) => {
  if (!localStorage.getItem(AddressStorageKey) && address1 && city && state && zipCode) {
    const addressString = `${address1}, ${city}, ${state}, ${zipCode}`;
    if (googleMaps) {
      const result = await googlePlaces.getPlaceByAddress(googleMaps, addressString);
      if (result) {
        const addressComponents = googlePlaces.extractAddress(result.address_components);
        address.update({
          ...addressComponents,
          lat: result.geometry.location.lat(),
          lng: result.geometry.location.lng(),
        });
      }
    }
  }
};
