import styled from "styled-components";

import { Text } from "/component/base";
import FormContent from "/component/partial/FormContent";
import { layout } from "/styles";

export const FormContentContainer = styled(FormContent)(layout.marginVertical("none"), {
  maxWidth: 550,
  width: "100%",
});
export const SubTitle = styled(Text).attrs({
  variant: "body1",
  color: "textSecondary",
})(layout.padding("skip"));
