import React from "react";

import Button from "/component/base/Button";
import { ProviderStatus } from "/component/page/Telehealth/TelehealthIndex.types";
import AvailabilityStatus from "/component/partial/AvailabilityStatus";
import { useAmwellProviderDetails, useTranslation } from "/hook";
import { parseInitials } from "/util/formatText";

import ProviderDetailsModal from "../ProviderDetailsModalBase";
import { Props } from "./TelehealthProviderDetailsModal.types";
const TelehealthProviderDetailsModal = ({
  provider,
  isOpen,
  close,
  status,
  handleStartVisit,
  isLoading,
}: Props) => {
  const { loading, data: providerDetails } = useAmwellProviderDetails({ provider });
  const { t } = useTranslation("modal-telehealthProviderDetails");

  const qualifications = [];

  const bio = providerDetails?.textGreeting;
  const specialty = provider?.specialty.value || "";
  const name = provider?.fullName || "";
  const initials = provider ? parseInitials(provider) : "";

  // Retrieve qualification information
  const spokenLanguages =
    providerDetails?.spokenLanguages.map((spokenLanguage) => spokenLanguage.value) || [];
  const education =
    providerDetails?.schoolName && providerDetails.graduatingYear
      ? `${providerDetails?.schoolName} ${providerDetails.graduatingYear}`
      : undefined;
  const boardCertifications =
    providerDetails?.boardCertificates && providerDetails.boardCertificates.length > 0
      ? providerDetails.boardCertificates
      : undefined;
  const yearsExperience = providerDetails?.yearsExperience
    ? t("year", { count: providerDetails.yearsExperience })
    : undefined;

  // Populate qualification list
  if (education)
    qualifications.push({
      label: t("qualifications.professionalEducation"),
      values: [education],
    });

  if (boardCertifications)
    qualifications.push({
      label: t("qualifications.boardCertification"),
      values: boardCertifications,
    });

  if (yearsExperience)
    qualifications.push({
      label: t("qualifications.yearsExperience"),
      values: [yearsExperience],
    });

  const buildCTAButton = () => {
    return (
      <>
        <Button
          isLoading={isLoading}
          isDisabled={status === ProviderStatus.Offline}
          onClick={handleStartVisit}
          variant={"primary"}
        >
          {t("selectProvider")}
        </Button>
        <div css={{ marginTop: 20 }}>
          <AvailabilityStatus status={status} waitingRoomCount={provider?.waitingRoomCount} />
        </div>
      </>
    );
    // }
  };

  return (
    <ProviderDetailsModal
      loading={!provider || loading}
      avatarUrl={provider?.logoUrl}
      isOpen={isOpen}
      close={close}
      initials={initials}
      bio={bio}
      cta={buildCTAButton()}
      primarySpecialty={specialty}
      nameWithDesignations={name}
      qualifications={qualifications}
      languages={spokenLanguages}
    />
  );
};

export default TelehealthProviderDetailsModal;
