import { gql } from "@apollo/client";

export default gql`
  mutation ContinueDecisionSupport($input: ContinueDecisionSupportInput!) {
    continueDecisionSupport(input: $input) {
      errors {
        attribute
        code
        message
        type
      }
      flowResponse {
        calendarIds {
          selected
          recommended
        }
        elementSet {
          elementSetRef
          elements {
            dateRestrictionType
            elementName
            elementRef
            elementSetRef
            elementType
            falseLabel
            fieldName
            inputType
            isNoOptionSelectionEnabled
            isRequired
            maxLength
            maxValue
            messageText
            minLength
            minValue
            noOptionSelectionDisplayName
            orderIndex
            questionText
            selectOptions {
              fieldName
              label
              value
            }
            trueLabel
            valueType
          }
          title
        }
        enableUrgentCare
        finishedDueToKickout
        flowId
        flowSessionId
        hasValidationErrors
        isEstablishedPatient
        isFlowFinished
        kickoutMessage
        reasonForVisit
        results {
          fields {
            currentValue
            fieldName
            valueType
          }
        }
        validationMessages
      }
    }
  }
`;
