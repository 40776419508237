import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React, { useEffect } from "react";

import MainContent from "/component/layout/MainContent";
import { useUserAccount } from "/component/layout/MainLayout/useUserAccount";
import EmailVerificationModal from "/component/modal/EmailVerificationModal/EmailVerificationModal";
import { useCaregiverProvider } from "/component/provider/CaregiverProvider";
import { useTranslation } from "/hook";
import { reactPlugin } from "/util/appInsights.util";
import { SIGN_UP_V2_ENABLED } from "/util/auth.util";

import SettingsList from "./SettingsList";
const Settings = () => {
  const { t } = useTranslation("settings");

  const { data, loading } = useUserAccount();
  const [accountQueried, setAccountQueried] = React.useState(false);
  const [showEmailModal, setShowEmailModal] = React.useState(false);
  const emailVerification = data?.viewer?.user?.account?.emailVerificationStatus;
  const firstName = data?.viewer?.user.account.firstName || "";
  const lastName = data?.viewer?.user.account.lastName || "";
  const patientName = `${firstName} ${lastName}`;
  const userEmail = data?.viewer?.user.account.email || "";
  const { caregiverMode } = useCaregiverProvider();

  useEffect(() => {
    if (!loading && !accountQueried) {
      setAccountQueried(true);
      if (
        SIGN_UP_V2_ENABLED &&
        emailVerification &&
        ["notVerified", "pending"].indexOf(emailVerification) >= 0 &&
        !caregiverMode
      ) {
        setShowEmailModal(true);
      }
    }
  }, [loading, accountQueried, emailVerification, caregiverMode]);

  return (
    <MainContent parentPage="account" pageTitle={t("title")}>
      <SettingsList />
      <EmailVerificationModal
        isOpen={showEmailModal}
        email={userEmail}
        patientName={patientName}
        close={() => {
          setShowEmailModal(false);
        }}
      />
    </MainContent>
  );
};

export default withAITracking(reactPlugin, Settings, undefined, "ai-tracking");
