import React, { useState } from "react";

import { GET_LAB_DETAILS_patient_labResult_analytes } from "/b2c/schema/types";
import { Button, Divider, Text } from "/component/base";
import { ChevronDown, ChevronUp } from "/component/base/Icon";
import { layout } from "/styles";

import { AbnormalFlagWrapper } from "./ResultListItem.styles";

interface Props {
  analyte: GET_LAB_DETAILS_patient_labResult_analytes;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ResultListItem = ({ analyte }: Props) => {
  const referenceRange = `Normal range: ${analyte.referenceRange || ""} ${analyte.units || ""}`;
  const getAnalyteFlag = () => {
    const shouldShowFlag = analyte.abnormalFlag && analyte.abnormalFlag.toLowerCase() !== "normal";

    return shouldShowFlag ? analyte.abnormalFlag : null;
  };

  const flag = getAnalyteFlag();
  return (
    <div>
      <div
        css={[layout.flexVertical, layout.paddingVertical(20), layout.spacedChildrenVertical(12)]}
      >
        <div css={layout.flexSpaceHorizontal}>
          <Text css={{ textTransform: "capitalize" }} variant="body1Bold">
            {analyte.analyteName || ""}
          </Text>
          <Text css={{ textTransform: "capitalize" }} variant="body1Bold">
            {analyte.value || ""}
          </Text>
        </div>
        {flag && (
          <AbnormalFlagWrapper>
            <Text color="textAbnormalFlag" variant="body2Bold">
              {flag.charAt(0).toUpperCase() + flag.slice(1).toLowerCase()}
            </Text>
          </AbnormalFlagWrapper>
        )}
        {analyte.referenceRange && (
          <Text color="textSecondary" variant="body2">
            {referenceRange}
          </Text>
        )}
        {analyte.note && analyte.note?.length > 0 && <ViewMoreText text={analyte.note} />}
      </div>
      <Divider />
    </div>
  );
};

interface ViewMoreTextProps {
  text: string;
}

const ViewMoreText: React.FC<ViewMoreTextProps> = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => setIsExpanded(!isExpanded);

  return (
    <>
      {isExpanded && <Text variant="body2">{text}</Text>}
      <Button
        css={[layout.flexCenterHorizontal, { alignSelf: "baseline" }]}
        variant={"unstyled"}
        onClick={toggleExpanded}
      >
        <Text
          css={[layout.margin("skip", "base", "skip", "skip"), { textDecoration: "underline" }]}
          variant="body2"
        >
          {isExpanded ? "View less" : "View more"}
        </Text>
        {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
      </Button>
    </>
  );
};

export default ResultListItem;
