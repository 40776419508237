// Modules
import styled from "styled-components";

import { CheckBox as CheckBoxBase } from "/component/base";

export const CheckBox = styled(CheckBoxBase)<{ hasFocus: boolean }>(
  ({ theme, hasFocus, checked }) => {
    let borderColor;
    if (hasFocus) {
      borderColor = theme.colors.objectOverlay;
    } else if (checked) {
      borderColor = "transparent";
    } else {
      borderColor = theme.colors.brandPrimaryLight;
    }
    return {
      padding: `${theme.spacing.base * 3}px ${theme.spacing.base * 4}px ${
        theme.spacing.base * 3
      }px ${theme.spacing.base * 5}px`,
      backgroundColor: checked ? theme.colors.brandPrimaryLight : "transparent",
      borderRadius: 16,
      border: `solid 1.5px ${borderColor}`,
    };
  },
);
