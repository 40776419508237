import styled from "styled-components";

import PickerField from "/component/partial/formik/PickerField";

export const StatePicker = styled(PickerField)({
  flex: `0 0 100px`,
  "& > button": {
    width: "100%",
  },
});

export const HiddenButton = styled.button({ visibility: "hidden" });
