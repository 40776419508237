import { useEffect, useState } from "react";

import { useDebounce } from "/hook";

type ViewportSize = { height: number | undefined; width: number | undefined };

/**
 * useViewportSize - This hook returns an object containing the height and
 * width values for the current size of the viewport. If the user resizes the
 * browser window, it will return updated values. These values are debounced
 * to avoid unnecessarily frequent updates.
 */
export const useViewportSize = (): ViewportSize => {
  const { innerHeight, innerWidth } = window;
  const [height, setHeight] = useState<undefined | number>(innerHeight);
  const [width, setWidth] = useState<undefined | number>(innerWidth);
  const debouncedHeight = useDebounce(height, 300);
  const debouncedWidth = useDebounce(width, 300);

  const handleResize = () => {
    setHeight(window.innerHeight);
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return { height: debouncedHeight, width: debouncedWidth };
};

export default useViewportSize;
