import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Trans } from "react-i18next";
import { RouteComponentProps } from "react-router";

import EmptyStateImage from "/asset/image/secureMessagingNoMessages.png";
import { Alert, Avatar, Button, Divider, Link, Loading, Text } from "/component/base";
import EditableMessage from "/component/base/EditableMessage";
import { CornerUpLeft, Trash } from "/component/base/Icon";
import MainContent from "/component/layout/MainContent";
import ReplyMessageModal from "/component/modal/ReplyMessageModal";
import EmptyStateCta from "/component/partial/EmptyStateCta";
import MessageAttachmentsList from "/component/partial/MessageAttachmentsList";
import { useToastContext } from "/component/provider/ToastProvider";
import { useAmwell, useAmwellMessageDetails, useTranslation } from "/hook";
import { layout } from "/styles";
import { date, formatText } from "/util";
import { reactPlugin } from "/util/appInsights.util";

import { useMessageQuery } from "./useMessageQuery";

const IMAGE_SIZE = 274;

const Message = ({ history, match }: RouteComponentProps<{ id: string }>) => {
  const { t } = useTranslation("message");
  const messageId = match.params.id;

  const { showToast } = useToastContext();
  const { consumer, sdk } = useAmwell();
  const { data, error, loading } = useAmwellMessageDetails({
    messageId,
    inbox: !(match.path.indexOf("/messages/sent/") > 0),
  });
  const { message, messageDetails } = data || {};
  const isInitialLoad = (loading && !messageDetails) || (!error && !messageDetails);

  const [isDeleting, setIsDeleting] = useState(false);
  const [showReply, setShowReply] = useState(false);
  const handleCancelDelete = () => setIsDeleting(false);
  const handleCancelReply = () => setShowReply(false);
  const handleComposeReply = () => setShowReply(true);

  const { contactByName } = useMessageQuery();
  const aftercarePhoneNumber = contactByName("cityMdAftercare")?.phoneNumber;
  const aftercarePhoneNumberFormatted =
    aftercarePhoneNumber && formatText.parsePhone(aftercarePhoneNumber);
  const callCenterPhoneNumber = contactByName("summitCallCenter")?.phoneNumber;
  const callCenterPhoneNumberFormatted =
    callCenterPhoneNumber && formatText.parsePhone(callCenterPhoneNumber);

  const handleConfirmDelete = () => {
    setIsDeleting(true);
  };

  const handleDelete = async () => {
    if (consumer && message) {
      try {
        await sdk?.secureMessageService.removeMessage(consumer, message);
        history.goBack();
      } catch (e: any) {
        showToast({ icon: "alert", message: t("alert.deleteError"), type: "error" });
        setIsDeleting(false);
      }
    } else {
      setIsDeleting(false);
    }
  };

  useEffect(() => {
    if (consumer && messageDetails) {
      try {
        sdk?.secureMessageService.updateMessageRead(consumer, messageDetails);
      } catch (e: any) {
        // We don't need to alert the user to this error, we can just try
        // again next time they view this message.
        console.error("Unable to mark message as read", e);
      }
    }
  }, [consumer, messageDetails]);

  return (
    <MainContent
      parentPage="account"
      pageTitle={messageDetails ? messageDetails.subject : ""}
      headerAccessory={
        messageDetails ? (
          <Button
            aria-label={t("button.trashMessage")}
            isDisabled={isDeleting}
            onClick={handleConfirmDelete}
            variant="unstyled"
          >
            <Trash aria-hidden background={{ size: 48 }} color="brandPrimary" />
          </Button>
        ) : undefined
      }
    >
      <Helmet>
        <title>{messageDetails ? messageDetails.subject : t("pageTitle")}</title>
      </Helmet>

      {isInitialLoad && <Loading />}

      {!isInitialLoad && error && (
        <EmptyStateCta
          imageHeight={IMAGE_SIZE}
          imageWidth={IMAGE_SIZE}
          imageSource={EmptyStateImage}
          subtitle={t("alert.fetchError.subtitle")}
          title={t("alert.fetchError.title")}
        />
      )}

      {!isInitialLoad && !error && messageDetails && (
        <article>
          <section css={[layout.padding("none", "none", "standard"), layout.flexLTR]}>
            <Avatar
              alt={messageDetails.sender.fullName}
              initials={formatText.parseInitials(messageDetails.sender)}
            />

            <div css={[layout.flexVertical, layout.padding("none", "none", "none", "standard")]}>
              <Text variant="body1Bold">{messageDetails.sender.fullName}</Text>

              <Text color="textSecondary" variant="body2">
                {`${date.formatDate(messageDetails.date, "LLL d, yyyy")} at ${date.formatDate(
                  messageDetails.date,
                  "h:mm aa ",
                )}`}
              </Text>
            </div>
          </section>

          <Divider css={layout.margin("skip", "skip", "expanded")} />

          <EditableMessage disabled html={messageDetails.body} />

          <MessageAttachmentsList attachments={messageDetails.attachments} />

          <aside
            css={[layout.padding("expanded", "none"), layout.spacedChildrenVertical("condensed")]}
          >
            <Text color="textSecondary" element="p" variant="body2">
              <Trans
                components={{
                  aftercareAnchor: (
                    <Link.Anchor href={`tel:${aftercarePhoneNumber}`} variant="tertiary" />
                  ),
                  callCenterAnchor: (
                    <Link.Anchor href={`tel:${callCenterPhoneNumber}`} variant="tertiary" />
                  ),
                }}
                i18nKey="disclaimer.expandingService"
                t={t}
                values={{
                  aftercarePhoneNumber: aftercarePhoneNumberFormatted,
                  callCenterPhoneNumber: callCenterPhoneNumberFormatted,
                }}
              />
            </Text>

            <Text color="textSecondary" element="p" variant="body2">
              {t("disclaimer.notForEmergencies")}
            </Text>
          </aside>

          <section css={layout.padding("expanded", "none", "none")}>
            {messageDetails.canReply ? (
              <Button onClick={handleComposeReply} variant="primary">
                <div css={layout.flexCenterHorizontal}>
                  <CornerUpLeft
                    css={layout.margin("none", "condensed", "none", "none")}
                    size={16}
                  />

                  <Text color="textInverse" variant="body1Bold">
                    {t("button.reply")}
                  </Text>
                </div>
              </Button>
            ) : (
              <Button isDisabled variant="tertiary">
                {t("button.noReply")}
              </Button>
            )}
          </section>
        </article>
      )}

      <Alert
        close={handleCancelDelete}
        footer={
          <div css={[layout.flexVerticalCenter, layout.spacedChildrenVertical()]}>
            <Button fullWidth="flex" onClick={handleDelete}>
              {t("button.delete")}
            </Button>

            <Link.Anchor onClick={handleCancelDelete} variant="primary">
              {t("button.cancelDelete")}
            </Link.Anchor>
          </div>
        }
        isOpen={isDeleting}
        title={t("alert.confirmDelete.title")}
      />

      {messageDetails?.canReply && (
        <ReplyMessageModal
          close={handleCancelReply}
          isOpen={showReply}
          messageDetails={messageDetails}
        />
      )}
    </MainContent>
  );
};

export default withAITracking(reactPlugin, Message, undefined, "ai-tracking");
