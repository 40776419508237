import { gql, useQuery } from "@apollo/client";

import { Dashboard } from "/apollo/schema";

const useDashboard = () => {
  const res = useQuery<Dashboard>(
    // Use the `isAuthenticated` reactive var in the `include` directive to only try making this
    // query if the user is logged in. If they are not logged in it would return null anyways.
    //
    // Note: the default value on `$isAuthenticated` is necessary to be able to use the var in the directive
    gql`
      query Dashboard(
        $isAuthenticated: Boolean = false
        $lat: Float!
        $lng: Float!
        $radius: Float! = 50
      ) {
        auth @client {
          isAuthenticated @export(as: "isAuthenticated")
        }
        address @client {
          lat @export(as: "lat")
          lng @export(as: "lng")
        }

        smgFacilities: facilities(org: SMG, geo: { radius: $radius, lat: $lat, lng: $lng }) {
          id
        }

        cmdFacilities: facilities(org: CMD, geo: { radius: $radius, lat: $lat, lng: $lng }) {
          id
        }
      }
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
    },
  );

  return res;
};

export default useDashboard;
