import styled from "styled-components";

import { card, layout } from "/styles";

export const ProviderRoomCardContainer = styled.div(
  layout.padding("standard"),
  layout.spacedChildrenVertical(),
  card.base,
  { width: "100%", maxWidth: 712 },
);
