import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React, { useState } from "react";

import { Loading } from "/component/base";
import MessageList from "/component/partial/MessageList";
import { useAmwellOutbox, useTranslation } from "/hook";
import { reactPlugin } from "/util/appInsights.util";

const INITIAL_PAGE_SIZE = 10;

const Outbox = () => {
  const [pageSize, setPageSize] = useState(INITIAL_PAGE_SIZE);
  const { t } = useTranslation("messages");
  const { data: outbox, error, loading } = useAmwellOutbox({ maxResults: pageSize });
  const isInitialLoad = (loading && !outbox) || (!error && !outbox);

  const fetchMore = () => {
    if (outbox) {
      setPageSize(INITIAL_PAGE_SIZE + outbox.messages.length);
    }
  };

  return isInitialLoad ? (
    <Loading />
  ) : (
    <MessageList
      emptyStateSubtitle={t("error.sentEmpty.subtitle")}
      emptyStateTitle={t("error.sentEmpty.title")}
      error={error}
      errorStateSubtitle={t("error.fetchError.subtitle")}
      errorStateTitle={t("error.fetchError.title")}
      getIconForMessage={() => "send"}
      inbox={false}
      messages={outbox?.messages}
      onFetchMore={fetchMore}
      total={outbox?.total}
    />
  );
};

export default withAITracking(reactPlugin, Outbox, undefined, "ai-tracking");
