import React from "react";
import { Trans } from "react-i18next";

import { Link, Text } from "/component/base";
import { useMessageQuery } from "/component/page/Account/page/Message/useMessageQuery";
import { useTranslation } from "/hook";
import { formatText } from "/util";

type Props = {
  className?: string;
};

/**
 * This is a disclaimer that will be rendered at the bottom of all Telehealth compose modals
 */
const MessageDisclaimer = ({ className }: Props) => {
  const { t } = useTranslation("composeMessage");
  const { contactByName } = useMessageQuery();

  const callCenterPhone = contactByName("cityMdAftercare")?.phoneNumber;

  return (
    <Text color="textSecondary" element="p" variant="body2" className={className}>
      <Trans
        components={{
          anchor: <Link.Anchor href={`tel:${callCenterPhone}`} variant="tertiary" />,
        }}
        i18nKey="composeMessageDisclaimer"
        t={t}
        tOptions={callCenterPhone ? { context: "hasNumber" } : undefined}
        values={{ phoneNumber: formatText.parsePhone(callCenterPhone) }}
      />
    </Text>
  );
};

export default MessageDisclaimer;
