import styled from "styled-components";

import { Button, Text } from "/component/base";
import { layout } from "/styles";

export const Base = styled(Button)(layout.flexCenterHorizontal, ({ theme }) => ({
  boxShadow: theme.shadows.small,
  borderRadius: theme.spacing.expanded,
  padding: theme.spacing.base * 3,
}));

// pseudo elements don't seem to work with SVGs so I'm using a div here
export const IconWrapper = styled.div<{ $hasCount: boolean }>(({ theme, $hasCount }) => ({
  position: "relative",
  marginRight: theme.spacing.condensed,

  // Set display: block so that the wrapping div has the correct height (same height as its svg child)
  svg: {
    display: "block",
  },

  // Render the little dot indicator on the icon
  "&::after": {
    content: "''",
    display: $hasCount ? "block" : "none",
    position: "absolute",
    top: -5,
    right: -4,
    width: 6,
    height: 6,
    borderRadius: 3,
    backgroundColor: theme.colors.textInverse,
  },
}));

export const CountIndicator = styled(Text)(({ theme }) => ({
  display: "none",
  [theme.media.tabletPortrait]: {
    display: "inline",
  },
}));
