import { gql, useQuery } from "@apollo/client";

import { GetDecisionSupportIndex } from "/apollo/schema/types";
import { ProviderAvatarListFragment } from "/component/partial/ProviderAvatarList";

const GET_DECISION_SUPPORT_FLOWS = gql`
  query GetDecisionSupportIndex($isAuthenticated: Boolean = false) {
    decisionSupportFlows {
      id
      title
      experianUrl
      useExperianUrl
    }

    auth @client {
      isAuthenticated @export(as: "isAuthenticated")
    }

    viewer @include(if: $isAuthenticated) {
      user {
        patient {
          providers {
            ...ProviderAvatarListFragment
          }
        }
      }
    }
  }

  ${ProviderAvatarListFragment}
`;

const useQueryDecisionSupportFlows = () =>
  useQuery<GetDecisionSupportIndex>(GET_DECISION_SUPPORT_FLOWS);

export default useQueryDecisionSupportFlows;
