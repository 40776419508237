import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";

import { GET_PHARMACY_patient_preferredPharmacies } from "/b2c/schema/types";
import { Button, Text } from "/component/base";
import { CornerUpRight } from "/component/base/Icon";
import Map from "/component/base/Map";
import useMapLocation from "/component/base/Map/useMapLocation";
import { formatAddress, getGoogleDirectionsLink } from "/component/base/Map/utils";
import MainContent from "/component/layout/MainContent";
import routes from "/constant/url.constant";
import { useTranslation } from "/hook";
import { useAnalytics } from "/hook/useAnalytics/useAnalytics";
import { layout } from "/styles";
import { formatText } from "/util";
import { reactPlugin } from "/util/appInsights.util";
import { getHumanPharmacyType } from "/util/pharmacyUtils";

import { PharmacyButton } from "./PreferredPharmacyB2C.styles";

const PreferredPharmacy = () => {
  const { t } = useTranslation("preferredPharmacy");
  const history = useHistory();
  const { recordAnalyticsEvent } = useAnalytics();

  useEffect(() => {
    recordAnalyticsEvent("preferredPharmacy");
  }, [recordAnalyticsEvent]);

  const location = useLocation<{
    pharmacy: GET_PHARMACY_patient_preferredPharmacies;
  }>();
  const { pharmacy } = location.state || {};

  const {
    address,
    city,
    state,
    zip,
    clinicalProviderName,
    clinicalProviderId,
    phoneNumber,
    pharmacyType,
  } = pharmacy;

  const { location: pharmacyLocation } = useMapLocation({
    address,
    city,
    state,
    zip,
    phoneNumber,
    placeName: clinicalProviderName,
  });
  const mapCenter = `${pharmacyLocation?.coordinate.latitude},${pharmacyLocation?.coordinate.longitude}`;

  const directionsLink = getGoogleDirectionsLink({
    address: formatAddress({ address, city, state, zip }),
    placeId: pharmacyLocation?.placeId,
    coordinate: pharmacyLocation?.coordinate,
    placeName: clinicalProviderName,
  });

  const handleChangePreferredPharmacy = () => {
    history.push(routes.pharmacySearch, { data: clinicalProviderId });
  };
  const handleGetPharmacyDirections = () => {
    recordAnalyticsEvent("pharmacyDirections");
  };

  return (
    <MainContent parentPage="myHealth" pageTitle={t("title.preferredPharmacy")}>
      <div css={layout.spacedChildrenVertical("gutter")}>
        <Map alt={t("button.directions")} link={directionsLink} center={mapCenter} />

        <div css={layout.flexVerticalCenter}>
          <Text color="textSecondary" variant="body2">
            {getHumanPharmacyType(pharmacyType)}
          </Text>
          <Text css={layout.margin("condensed", "skip", "skip", "skip")} variant="title2">
            {clinicalProviderName}
          </Text>
        </div>

        <div>
          <Text variant="body1Bold">{t("address")}</Text>

          <p css={layout.flexVertical}>
            <Text variant="body1">{address}</Text>
            <Text variant="body1">{`${city}, ${state} ${zip}`}</Text>
          </p>
        </div>

        {phoneNumber && (
          <div>
            <Text variant="body1Bold">{t("phone")}</Text>
            <p css={layout.flexVertical}>
              <Text variant="body1"> {formatText.parsePhone(phoneNumber)}</Text>
            </p>
          </div>
        )}

        <div css={[layout.flexVerticalCenter, layout.spacedChildrenVertical()]}>
          <PharmacyButton
            href={directionsLink}
            onClick={handleGetPharmacyDirections}
            target="_blank"
            variant="secondary"
          >
            <CornerUpRight
              aria-hidden
              css={layout.margin("skip", "condensed", "skip")}
              size={16}
              color="brandPrimary"
            />

            <Text color="brandPrimary" variant="title3">
              {t("button.directions")}
            </Text>
          </PharmacyButton>
        </div>
        <div css={[layout.flexVertical, layout.spacedChildrenVertical("base")]}>
          <Text variant="body1Bold">{t("preferredPharmacy.title", "Preferred pharmacy")}</Text>
          <Text variant="body1">{t("preferredPharmacy.subtitle")}</Text>
          <Text variant="body1">{t("preferredPharmacy.note")}</Text>
        </div>
        <Button fullWidth="percent" onClick={handleChangePreferredPharmacy}>
          {t("button.changePreferredPharmacy")}
        </Button>
      </div>
    </MainContent>
  );
};

export default withAITracking(reactPlugin, PreferredPharmacy, undefined, "ai-tracking");
