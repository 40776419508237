// Modules
import React from "react";

import { layout } from "/styles";

import Text from "../Text";
import { Divider } from "./TextDivider.styles";
import { Props } from "./TextDivider.types";

const TextDivider = ({ label }: Props) => (
  <div css={[layout.flexCenterHorizontal, layout.marginVertical("gutter")]}>
    <Divider />
    <Text css={layout.marginHorizontal("standard")} variant="body2" color="textSecondary">
      {label}
    </Text>
    <Divider />
  </div>
);

export default TextDivider;
