import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
  NormalizedCacheObject,
  useMutation,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import UPDATE_USER_PASSWORD from "/apollo/mutation/updateUserPassword";
import { UpdateUserPassword, UpdateUserPasswordVariables } from "/apollo/schema/types";
import { encryptPassword } from "/util/password";

const httpLink = createHttpLink({
  uri: `${process.env.API_URL}/graphql`,
});

const authLink = (token: string) => {
  return setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  });
};

const passwordResetClient = (token: string): ApolloClient<NormalizedCacheObject> => {
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: from([authLink(token), httpLink]),
    connectToDevTools: process.env.NODE_ENV === "development",
  });
};

const useUpdateUserPassword = (token?: string) => {
  const [UpdateUserPassword] = useMutation<UpdateUserPassword, UpdateUserPasswordVariables>(
    UPDATE_USER_PASSWORD,
    token
      ? {
          client: passwordResetClient(token),
        }
      : undefined,
  );

  const handleSubmit = async (password: string) => {
    const passwordObj = encryptPassword(password);
    return await UpdateUserPassword({
      variables: {
        data: {
          password: passwordObj.password,
          keyArrayBuffer: passwordObj.key,
          ivArrayBuffer: passwordObj.iv,
        },
      },
    });
  };

  return { handleSubmit };
};

export default useUpdateUserPassword;
