import styled from "styled-components";

import { card, layout } from "/styles";

export const ImmunizationsWrapper = styled.div(
  layout.flexVertical,
  layout.margin("expanded", "none", "none", "none"),
);

export const ListContainer = styled.div(card.wrapper);
