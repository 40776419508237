import styled from "styled-components";

import { Button } from "/component/base";
import { layout } from "/styles";

export const ProgressBarContainer = styled.div({
  maxWidth: 550,
  width: "100%",
});

export const SignUpContainer = styled.div(
  {
    display: "grid",
    gridTemplateRows: "50px auto",
    overflow: "auto",
  },
  layout.padding("standard"),
);

export const SignInContainer = styled.div(
  layout.spacedChildrenHorizontal("condensed"),
  ({ theme }) => ({
    paddingTop: theme.spacing[60],
  }),
);

export const SignInButton = styled(Button)(({ theme }) => ({
  color: theme.colors.brandPrimary,
  display: "inline-block",
  textDecoration: "underline",
  borderBottom: "none",
  fontSize: theme.fontSizes.body1Bold,
}));
