import styled from "styled-components";

import { layout } from "/styles";
import { ThemeType } from "/theme/default";

const linkStyleFn = ({ theme }: ThemeType) => ({
  color: theme.colors.brandPrimary,
  textDecoration: "none",
  fontFamily: theme.fontVariants.body1Bold,
});

export const Anchor = styled.a(linkStyleFn);

export const EmptyStateContainer = styled.div(layout.flexVerticalCenter, ({ theme }) => ({
  maxWidth: 480,
  textAlign: "center",
  "& > button": {
    marginTop: `${theme.spacing.standard}px`,
    width: "100%",
  },
}));
