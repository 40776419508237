import { gql } from "@apollo/client";

export default gql`
  mutation UpdateMFAPhoneNumber($data: UpdateMFAPhoneNumberInput!) {
    updateMFAPhoneNumber(data: $data) {
      errors {
        message
      }
    }
  }
`;
