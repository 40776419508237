import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import { Button, CheckBox, InputWrapper, Loading } from "/component/base";
import Text, { Label } from "/component/base/Text";
import FormContent from "/component/partial/FormContent";
import {
  PatientInfoConfirmProps,
  useAmwellPrevisitContext,
} from "/component/provider/AmwellPrevisitProvider";
import { useTranslation } from "/hook";
import useAmwell from "/hook/useAmwell";
import { layout } from "/styles";
import { formatISODateTimeString } from "/util/date";
import { formatTelehealthAddress } from "/util/formatText";

import {
  CheckboxContainer,
  IdentificationContainer,
  NameContainer,
  NoticePrivacyLink,
  Title,
} from "../Telehealth.styles";
import { TelehealthFormProps } from "../Telehealth.types";

const PatientInfoConfirm = ({
  handleError,
  handleSubmit,
  initialValues,
}: TelehealthFormProps<PatientInfoConfirmProps>) => {
  const { t } = useTranslation("form-telehealth-previsit");

  const { consumer, isReady } = useAmwell();
  const [loading, setLoading] = useState(true);
  const { sdk, setVisit, visitContext, setVisitContext } = useAmwellPrevisitContext();

  const patientInfoSchema = Yup.object({
    acknowledgePrivacyChecked: Yup.boolean().oneOf([true]),
  });

  useEffect(() => {
    if (isReady || consumer) {
      setLoading(false);
    }
  }, [consumer, isReady]);

  if (loading) {
    return <Loading />;
  }

  const submitForm = async (values: PatientInfoConfirmProps) => {
    try {
      if (visitContext && sdk) {
        visitContext.shareHealthSummary = true;
        const _visit = await sdk.visitService.createOrUpdateVisit(visitContext);
        setVisitContext(visitContext);
        setVisit(_visit);
        handleSubmit(values);
      }
    } catch (e: any) {
      handleError(e);
    }
  };

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={initialValues}
      validationSchema={patientInfoSchema}
      onSubmit={submitForm}
    >
      {({ isSubmitting, isValid, setFieldValue, values }) => (
        <>
          <Form>
            <FormContent css={layout.margin("gutter", "skip")}>
              <Title>{t("patientConfirm.title")}</Title>
              <div css={layout.margin(40, "skip", 40)}>
                <IdentificationContainer>
                  <NameContainer>
                    <InputWrapper label={t("patientConfirm.firstName")}>
                      <Text>{consumer?.firstName}</Text>
                    </InputWrapper>
                    <InputWrapper label={t("patientConfirm.lastName")}>
                      <Text>{consumer?.lastName}</Text>
                    </InputWrapper>
                  </NameContainer>
                  <InputWrapper label={t("patientConfirm.dateOfBirth")}>
                    <Text>
                      {consumer?.dob
                        ? formatISODateTimeString(consumer?.dob.toISOString(), "MM/dd/yyyy")
                        : undefined}
                    </Text>
                  </InputWrapper>
                  <InputWrapper label={t("patientConfirm.address")}>
                    <Text>{formatTelehealthAddress(consumer?.address)}</Text>
                  </InputWrapper>
                </IdentificationContainer>
                <CheckboxContainer>
                  <CheckBox
                    label={
                      <Label>
                        {t("patientConfirm.acknowledgeReceipt")}
                        <NoticePrivacyLink
                          target="_blank"
                          href={process.env.AMWELL_PRIVACY_PRACTICE_URL}
                        >
                          {t("patientConfirm.noticesOfPrivacy")}
                        </NoticePrivacyLink>
                      </Label>
                    }
                    labelBefore={false}
                    checked={values.acknowledgePrivacyChecked}
                    onChange={(e) => {
                      setFieldValue("acknowledgePrivacyChecked", e.currentTarget.checked);
                    }}
                  />
                </CheckboxContainer>
              </div>
              <FormContent.ButtonGroup>
                <Button type="submit" isDisabled={!isValid} isLoading={isSubmitting}>
                  {t("continue")}
                </Button>
              </FormContent.ButtonGroup>
            </FormContent>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default PatientInfoConfirm;
