import { gql } from "@apollo/client";

export default gql`
  mutation AbandonDecisionSupport($flowSessionId: ID!) {
    abandonDecisionSupport(flowSessionId: $flowSessionId) {
      errors {
        attribute
        code
        message
        type
      }
    }
  }
`;
