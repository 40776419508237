import styled from "styled-components";

import { Text } from "/component/base";
import { layout } from "/styles";

export const Title = styled(Text).attrs({
  variant: "title1",
  element: "h2",
})(layout.padding("skip"));

export const Subtitle = styled.div(({ theme }) => ({
  marginTop: theme.spacing.condensed,
}));

export const ContentContainer = styled.div(layout.margin("gutter", "none", "none", "none"));

export const DescriptionContainer = styled.div(layout.marginVertical("condensed"), () => ({
  display: "flex",
}));

export const Description = styled.div(layout.flexVertical, ({ theme }) => ({
  marginLeft: theme.spacing[12],
}));

export const SelfPayDescription = styled.div(() => ({
  display: "flex",
  marginBottom: 8,
  marginTop: 20,
}));
