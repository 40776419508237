import { withAITracking } from "@microsoft/applicationinsights-react-js";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as html2pdf from "html2pdf.js";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

import { ErrorCode, getErrorCode } from "/b2c/client/utils";
import useDemographics from "/b2c/query/useDemographics.b2c";
import { useImagingResultPage } from "/b2c/query/useImagingResultPage.b2c";
import { useLabResultPage } from "/b2c/query/useLabResultPage.b2c";
import { TestResult, TestResultType } from "/b2c/query/useTestResults.b2c";
import {
  GET_DEMOGRAPHICS_patient_demographics,
  GET_IMAGING_DETAILS_patient_imagingResult,
  GET_LAB_DETAILS_patient_labResult,
  GET_LAB_DETAILS_patient_labResult_analytes,
} from "/b2c/schema/types";
import { Button, Text } from "/component/base";
import { AlertCircleOpen } from "/component/base/Icon";
import MainContent from "/component/layout/MainContent";
import EmptyStateCta from "/component/partial/EmptyStateCta";
import LoadingSkeleton from "/component/partial/LoadingSkeleton";
import { useToastContext } from "/component/provider/ToastProvider";
import { useTranslation } from "/hook";
import useWindowSize from "/hook/useWindowSize";
import { layout } from "/styles";
import { date } from "/util";
import { reactPlugin } from "/util/appInsights.util";

import useTestResultDetails from "../../../../../b2c/query/useTestResultDetails.b2c";
import LabResultDetails from "./components/LabResultDetails";
import NegativeResultGuide from "./components/NegativeResultGuide";
import ResultLoadingCard from "./components/ResultLoadingCard";
import ResultLoadingCardHeader from "./components/ResultLoadingCardHeader";
import TestResultsProviderNote from "./components/TestResultsProviderNote";
import { generateResultsHTML, TestResultData } from "./ResultB2C.util";

export interface MatchParams {
  result: TestResult;
}
const Result = () => {
  const { t } = useTranslation("result");
  const { showToast } = useToastContext();

  const location = useLocation<{
    testResult: TestResult;
  }>();
  const { testResult } = location.state || {};

  const [loadingPageData, setLoadingPageData] = useState(true);
  const [canDownloadResults, setCanDownloadResults] = useState(false);
  const [generatingPDF, setGeneratingPDF] = useState(false);

  const { data: demographicsData, loading: demographicsLoading } = useDemographics();
  const demographics = demographicsData?.patient
    .demographics as GET_DEMOGRAPHICS_patient_demographics;

  const { data: details, error, loading: detailsLoading } = useTestResultDetails(testResult);
  const [analytes, setAnalytes] = useState<GET_LAB_DETAILS_patient_labResult_analytes[]>([]);
  const [shouldShowNegativeResultGuide, setShouldShowNegativeResultGuide] = useState(false);

  const [queryLabResultPage, { data: labResultPageData }] = useLabResultPage({
    onCompleted: () => {
      setLoadingPageData(false);
    },
  });
  const [queryImagingResultPage, { data: imagingResultPageData }] = useImagingResultPage({
    onCompleted: () => {
      setLoadingPageData(false);
    },
  });

  const { isMobile } = useWindowSize();

  useEffect(() => {
    // wait for details to load
    if (details && details.pages) {
      const pageId = details.pages[0]?.pageId;
      // page data available to fetch
      if (pageId) {
        if (testResult.type === TestResultType.LAB) {
          queryLabResultPage({
            variables: {
              labResultId: testResult.id,
              pageId,
            },
          });
        } else if (testResult.type === TestResultType.IMAGING) {
          queryImagingResultPage({
            variables: {
              imagingResultId: testResult.id,
              pageId,
            },
          });
        }
      }
      // details have loaded but no page data to fetch
      else {
        setLoadingPageData(false);
      }
    }
  }, [
    details,
    queryImagingResultPage,
    queryLabResultPage,
    setLoadingPageData,
    testResult.id,
    testResult.type,
  ]);

  // do some checks to see if we either have an attachment or
  // documentdata/analyte data for pdf generation
  // otherwise, don't allow user to download pdf
  useEffect(() => {
    if (!loadingPageData) {
      if (testResult.type === TestResultType.LAB) {
        const _details = details as GET_LAB_DETAILS_patient_labResult;
        setAnalytes(_details.analytes);
        const anyNegativeOrPositiveResults = _details.analytes.filter(
          (x) => x.value?.toLowerCase() === "positive" || x.value?.toLowerCase() === "negative",
        );
        if (anyNegativeOrPositiveResults.length > 0) setShouldShowNegativeResultGuide(true);
        if (labResultPageData?.patient.labResultPage || (_details && _details.analytes.length)) {
          setCanDownloadResults(true);
        }
      } else if (testResult.type === TestResultType.IMAGING) {
        const _details = details as GET_IMAGING_DETAILS_patient_imagingResult;
        if (
          imagingResultPageData?.patient.imagingResultPage ||
          (_details && _details.documentData)
        ) {
          setCanDownloadResults(true);
        }
      }
    }
  }, [
    loadingPageData,
    details,
    testResult.type,
    setCanDownloadResults,
    labResultPageData?.patient.labResultPage,
    imagingResultPageData?.patient.imagingResultPage,
  ]);

  const handlePDFDownload = () => {
    // dont' allow user to view pdf if page data is loading,
    // otherwise they would see generated pdf instead of the available attachment
    if (loadingPageData) return;

    if (testResult.type === TestResultType.LAB) {
      const fileName = details?.description
        ? details?.description.replace(/[/\\?%*:|"<>]/g, "")
        : "Test Results";
      if (labResultPageData?.patient.labResultPage) {
        openAttachment(fileName, labResultPageData?.patient.labResultPage);
      } else {
        setGeneratingPDF(true);
        const _details = details as GET_LAB_DETAILS_patient_labResult;
        const data = {
          analytes: _details.analytes,
          labResultDatetime: testResult.date || _details.labResultDatetime,
          description: _details.description,
          performingLab: {
            name: _details.performingLabName,
            address: _details.performingLabAddress1,
            city: _details.performingLabCity,
            state: _details.performingLabState,
            zip: _details.performingLabZip,
          },
          providerName: _details.provider?.displayName,
        };

        generatePDF(fileName, demographics, data);
      }
    } else if (testResult.type === TestResultType.IMAGING) {
      const fileName = details?.description
        ? details?.description.replace(/[/\\?%*:|"<>]/g, "")
        : "Test Results";
      if (imagingResultPageData?.patient.imagingResultPage) {
        openAttachment(fileName, imagingResultPageData?.patient.imagingResultPage);
      } else {
        setGeneratingPDF(true);
        const _details = details as GET_IMAGING_DETAILS_patient_imagingResult;
        const data = {
          documentdata: _details.documentData,
          labResultDatetime: testResult.date || _details.createdDatetime,
          description: _details.description,
          performingLab: {
            name: _details?.performingFacility?.name,
            address: _details?.performingFacility?.address,
            city: _details?.performingFacility?.city,
            state: _details?.performingFacility?.state,
            zip: _details?.performingFacility?.zipCode,
          },
          providerName: _details.provider?.displayName,
        };

        generatePDF(fileName, demographics, data);
      }
    }
  };

  const openAttachment = (fileName: string, data: string) => {
    const downloadLink = document.createElement("a");
    downloadLink.href = data;
    downloadLink.download = `${fileName}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    downloadLink.remove();
  };

  const generatePDF = (
    fileName: string,
    demographics: GET_DEMOGRAPHICS_patient_demographics,
    data: TestResultData,
  ) => {
    const html = generateResultsHTML(demographics, data);

    html2pdf()
      .set({
        fileName: fileName,
        margin: 10,
        html2canvas: { scale: 2, width: 825 },
      })
      .from(html)
      .toPdf()
      .get("pdf")
      .then(function (pdf: { output: (arg0: string) => string | URL | undefined }) {
        window.open(pdf.output("bloburl"), "_blank");
      })
      .catch(() => {
        showToast({
          icon: "alert",
          message: t("toast.error"),
          type: "error",
        });
      })
      .finally(() => {
        setGeneratingPDF(false);
      });
  };

  if (demographicsLoading || detailsLoading || loadingPageData)
    return (
      <MainContent parentPage="myHealth">
        <LoadingSkeleton skeletonVariant="list-item">
          <ResultLoadingCardHeader />
          <ResultLoadingCard />
          <ResultLoadingCard />
          <ResultLoadingCard />
          <ResultLoadingCard />
          <ResultLoadingCard />
        </LoadingSkeleton>
      </MainContent>
    );

  if (error && getErrorCode(error, details) !== ErrorCode.PARTIAL_RESPONSE) {
    return (
      <MainContent pageTitle={t("pageTitle")} parentPage="myHealth">
        <Helmet>
          <title>{t("pageTitle")}</title>
        </Helmet>
        <div css={layout.flexVerticalCenter}>
          <div css={layout.marginVertical(60)}>
            <EmptyStateCta
              icon={<AlertCircleOpen color="brandPrimary" size={48} />}
              title={t("error.fetchError.title")}
              subtitle={t("error.fetchError.subtitle")}
            />
          </div>
        </div>
      </MainContent>
    );
  }

  return (
    <MainContent parentPage="results">
      <Helmet>
        <title>{t("pageTitle")}</title>
      </Helmet>

      <div css={[layout.flexVertical, layout.spacedChildrenVertical("condensed")]}>
        <Text
          css={[layout.margin("none"), { textTransform: "capitalize" }]}
          element="h1"
          variant="title1"
          color="textTitle"
        >
          {details?.description || t("headline.results")}
        </Text>

        <Text color="textSecondary">{date.formatDate(testResult.date, "MMMM d, y")}</Text>

        {details?.provider?.displayName && (
          <Text color="textSecondary">
            {t("orderedBy", {
              providerName: details?.provider?.displayName,
            })}
          </Text>
        )}
      </div>

      <TestResultsProviderNote
        provider={details?.provider}
        note={details?.patientNote}
        status={details?.status}
        brand={(details as GET_LAB_DETAILS_patient_labResult)?.department?.brand}
      />

      <LabResultDetails analytes={analytes} />

      {shouldShowNegativeResultGuide && <NegativeResultGuide />}

      {canDownloadResults && (
        <div css={[layout.marginVertical("expanded"), layout.flexCenter]}>
          <Button
            css={{ width: isMobile ? "100%" : "65%" }}
            isLoading={generatingPDF}
            fullWidth="percent"
            onClick={() => {
              handlePDFDownload();
            }}
            variant="tertiary"
          >
            {t("viewResults")}
          </Button>
        </div>
      )}
    </MainContent>
  );
};

export default withAITracking(reactPlugin, Result, undefined, "ai-tracking");
