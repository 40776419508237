import { gql, QueryHookOptions } from "@apollo/client";

import { B2CQueryResult, useB2CQuery } from "../client";
import { filterKnownErrors } from "../client/utils";
import { getAppointments, getAppointmentsVariables } from "../schema/types";

export const AppointmentsQuery = gql`
  query getAppointments($showPast: Boolean, $cacheRefresh: Boolean) {
    patient {
      appointments(
        showPast: $showPast
        showCancelled: true
        limit: 50
        cacheRefresh: $cacheRefresh
      ) {
        appointmentId
        appointmentStatus
        startDateTime
        endDateTime
        visitType
        onlineCheckInEligibility {
          isEligible
        }
        patientAppointmentTypeName
        provider {
          firstName
          lastName
          displayName
          specialty
          providerId
          imageUrl
        }
        departmentId
        department {
          name
        }
      }
    }
  }
`;

const filterPastAppointments = (
  queryResult: B2CQueryResult<getAppointments, getAppointmentsVariables>,
  showPast: getAppointmentsVariables["showPast"],
) => {
  if (showPast && queryResult.data?.patient?.appointments) {
    const appointments = queryResult.data.patient.appointments.filter((appointment) => {
      return new Date(appointment.endDateTime) < new Date();
    });

    return {
      ...queryResult,
      data: {
        ...queryResult.data,
        patient: {
          ...queryResult.data.patient,
          appointments,
        },
      },
    };
  } else {
    return queryResult;
  }
};

export const useAppointmentsIndex = (
  { showPast, cacheRefresh }: getAppointmentsVariables,
  options: Omit<
    QueryHookOptions<getAppointments, getAppointmentsVariables>,
    "query" | "variables"
  > = {},
): B2CQueryResult<getAppointments, getAppointmentsVariables> => {
  const queryResult = useB2CQuery<getAppointments>(AppointmentsQuery, {
    variables: { showPast, cacheRefresh },
    fetchPolicy: "network-only",
    ...options,
  });

  return filterKnownErrors(filterPastAppointments(queryResult, showPast));
};
