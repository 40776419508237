import awsdk from "awsdk";

import { ProviderStatus } from "./TelehealthIndex.types";

export const getProviderStatus = (
  visibility: awsdk.AWSDKProviderVisibility,
  waitingRoomCount: number,
) => {
  if (waitingRoomCount === 0) {
    if (visibility === awsdk.AWSDKProviderVisibility.WEB_AVAILABLE) {
      return ProviderStatus.Available;
    } else if (visibility === awsdk.AWSDKProviderVisibility.WEB_BUSY) {
      return ProviderStatus.Busy;
    } else if (visibility === awsdk.AWSDKProviderVisibility.OFFLINE) {
      return ProviderStatus.Offline;
    }
  }
  return ProviderStatus.WaitingRoom;
};
