import styled from "styled-components";

export const Base = styled.div({
  maxWidth: 591,
});

export const BackButtonContainer = styled.div(() => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
}));
