import { RadioGroup } from "@headlessui/react";
import { useField } from "formik";
import React from "react";

import { getLabelProps, styles as InputWrapperStyles } from "/component/base/InputWrapper";
import theme from "/theme/default";

import { Label } from "./RadioGroupField.styles";
import { Props } from "./RadioGroupField.types";

const RadioGroupField = ({
  className,
  children,
  label,
  inputWrapperVariant = "primary",
  isHTML,
  onValueChange,
  ...rest
}: Props) => {
  const [field, meta, helpers] = useField(rest);
  const labelProps = getLabelProps(inputWrapperVariant);
  return (
    <RadioGroup
      value={field.value}
      onChange={(val) => {
        onValueChange && onValueChange(val);
        helpers.setValue(val);
      }}
      className={className}
    >
      {!!label &&
        (isHTML ? (
          <InputWrapperStyles.Header variant={inputWrapperVariant}>
            <Label
              css={{
                "& > a:visited": {
                  color: theme.colors.brandSecondary,
                },
                "& > a": {
                  color: theme.colors.brandSecondary,
                },
              }}
              dangerouslySetInnerHTML={{ __html: label }}
              variant={labelProps.variant}
              color={labelProps.color}
            />
          </InputWrapperStyles.Header>
        ) : (
          <InputWrapperStyles.Header variant={inputWrapperVariant}>
            <Label variant={labelProps.variant} color={labelProps.color}>
              {label}
            </Label>
          </InputWrapperStyles.Header>
        ))}
      {children}
      {!!meta.error && meta.touched && (
        <InputWrapperStyles.Error color="statusNegative" variant="body2">
          {meta.error}
        </InputWrapperStyles.Error>
      )}
    </RadioGroup>
  );
};

RadioGroupField.Option = RadioGroup.Option;
RadioGroupField.Label = Label;
RadioGroupField.Description = RadioGroup.Description;

export default RadioGroupField;
