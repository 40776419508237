import styled from "styled-components";

import { Link } from "/component/base";
import { layout } from "/styles";

export const CallClinicButton = styled(Link.Anchor)(
  layout.padding("condensed"),
  layout.marginVertical("condensed"),
  layout.flexCenter,
  ({ theme }) => ({
    border: `2px solid ${theme.colors.objectSubtle}`,
    borderRadius: 16,
  }),
);
