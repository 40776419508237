import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";

// Components
import Button from "/component/base/Button";
import Modal from "/component/base/Modal";
import Text from "/component/base/Text";
import { ButtonGroup } from "/component/partial/FormContent/FormContent.styles";
import TextField from "/component/partial/formik/TextField";
import { useToastContext } from "/component/provider/ToastProvider";
import { useTranslation } from "/hook";

import { FormContent, Header, LogoWordmark, StepOneContent } from "./SendPasswordResetModal.styles";
import { Props } from "./SendPasswordResetModal.types";
import usePasswordReset from "./useSendPasswordReset";

const NUM_OF_STEPS = 2;

const SendPasswordResetModal = ({ isOpen, close }: Props) => {
  const { t } = useTranslation("send-password-reset");
  const { showToast } = useToastContext();

  const [step, setStep] = useState(0);
  const { handleSubmit } = usePasswordReset({});
  const [serverError, setServerError] = useState("");
  const [email, setEmail] = useState("");

  const sendPasswordResetSchema = Yup.object({
    email: Yup.string()
      .matches(/^\S+@\S+\.\S+$/, {
        message: t("error.email.invalid"),
      })
      .required(t("error.email.required")),
  });

  const handleEmailSubmit = async (values: { email: string }) => {
    // Clear error message on resubmit
    setServerError("");
    try {
      /**
       * Show the toast here
       *
       */
      const res = await handleSubmit(values);
      if (res?.data?.sendPasswordReset.errors?.length) {
        res?.data?.sendPasswordReset.errors.forEach((e) => {
          setServerError(e.message);
        });
      } else {
        if (!res.data?.sendPasswordReset?.errors.length) {
          showToast({
            icon: "checkmark",
            message: t("toast.emailSent"),
          });
          setEmail("");
        }

        if (step < NUM_OF_STEPS - 1) {
          setStep(step + 1);
        }
      }
    } catch (e: any) {
      setServerError(e.message);
    } finally {
      setEmail(values.email);
    }
  };

  const maskedEmail = (emailToMask: string) => {
    if (emailToMask) {
      const length = emailToMask.indexOf("@");
      if (length > 6) {
        return emailToMask.slice(0, 3) + "***" + emailToMask.slice(length - 2, emailToMask.length);
      } else {
        return "***" + emailToMask.slice(length, emailToMask.length);
      }
    } else {
      return "";
    }
  };

  const closeModal = () => {
    setStep(0);
    close();
  };

  return (
    <Modal header={<Modal.Header close={close} />} isOpen={isOpen} close={closeModal}>
      <Header>
        <LogoWordmark />
      </Header>
      {step === 0 ? (
        <StepOneContent>
          <Modal.Title>
            <Text variant="title1">{t("heading.emailStep")}</Text>
          </Modal.Title>
          <Modal.Description>
            <Text color="textSecondary">{t("description.emailStep")}</Text>
          </Modal.Description>
          <Formik
            validateOnMount
            enableReinitialize
            initialValues={{
              email,
            }}
            validationSchema={sendPasswordResetSchema}
            onSubmit={handleEmailSubmit}
          >
            {({ isSubmitting, isValid }) => (
              <Form>
                <FormContent>
                  <TextField
                    label={t("label.email")}
                    placeholder="name@email.com"
                    name="email"
                    type="text"
                  />
                  {!!serverError && (
                    <Text color="statusNegative" variant="body2">
                      {serverError}
                    </Text>
                  )}
                  <FormContent.ButtonGroup>
                    <Button
                      variant="primary"
                      isDisabled={!isValid}
                      type="submit"
                      isLoading={isSubmitting}
                    >
                      {t("submitText.emailStep")}
                    </Button>
                  </FormContent.ButtonGroup>
                </FormContent>
              </Form>
            )}
          </Formik>
        </StepOneContent>
      ) : (
        <StepOneContent>
          <Modal.Title>
            <Text variant="title1">{t("heading.resetLinkStep")}</Text>
          </Modal.Title>
          <Modal.Description>
            <Text color="textSecondary">
              {t("description.resetLinkStep", { email: maskedEmail(email) })}
            </Text>
          </Modal.Description>
          <Formik
            validateOnMount
            enableReinitialize
            initialValues={{
              email,
            }}
            validationSchema={sendPasswordResetSchema}
            onSubmit={handleEmailSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <FormContent>
                  <ButtonGroup>
                    <Button
                      variant="secondary"
                      isDisabled={false}
                      type="submit"
                      isLoading={isSubmitting}
                      fullWidth="percent"
                    >
                      {t("submitText.resendEmail")}
                    </Button>
                  </ButtonGroup>
                </FormContent>
              </Form>
            )}
          </Formik>
        </StepOneContent>
      )}
    </Modal>
  );
};

export default SendPasswordResetModal;
