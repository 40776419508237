import styled from "styled-components";

import logoSmall from "/asset/image/logo-small.svg";
import logoHorizontal from "/asset/image/logo-wordmark-horizontal.svg";
import Button from "/component/base/Button";
import BaseDivider from "/component/base/Divider";
import CtaCardBase from "/component/partial/CtaCard";
import { layout } from "/styles";

export const Base = styled.nav(({ theme }) => ({
  backgroundColor: theme.colors.backgroundPrimary,
  boxShadow: theme.shadows.base,
  backgroundSize: "cover",
  bottom: 0,
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  left: 0,
  overflowY: "auto",
  padding: `0 ${theme.spacing.gutter}px`,
  position: "fixed",
  top: 0,

  borderRadius: "0px 30px 30px 0px",

  // Thin icon-only navbar for tablet screens
  [theme.media.range.medium]: {
    padding: theme.spacing.expanded,
    width: theme.sizes.navSmall,
    flexBasis: theme.sizes.navSmall,
  },

  // Full navbar for desktop screens (icon + label)
  [theme.media.desktop]: {
    width: theme.sizes.nav,
    flex: `0 0 ${theme.sizes.nav}px`,
    padding: theme.spacing[40],
  },
}));

// Logo sizes were grabbed from Figma. Switch out which SVG is rendered based on media queries
export const Logo = styled.div(({ theme }) => ({
  backgroundImage: `url(${logoSmall})`,
  width: 30,
  height: 30,

  [theme.media.tabletPortrait]: {
    marginBottom: theme.spacing.base * 16,
  },

  [theme.media.desktop]: {
    backgroundImage: `url(${logoHorizontal})`,
    width: 288,
    height: 58,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  },
}));

export const Divider = styled(BaseDivider)(({ theme }) => ({
  margin: `${theme.spacing[40]}px 0`,
}));

export const Header = styled.div(layout.flexSpaceHorizontal, ({ theme }) => ({
  [theme.media.max.mobile]: {
    padding: `${theme.spacing.mobileNavTop}px 0`,
    marginBottom: theme.spacing.expanded,
  },
}));

export const CloseButton = styled(Button)(({ theme }) => ({
  [theme.media.tabletPortrait]: {
    display: "none",
  },
}));

export const CtaCard = styled(CtaCardBase)(({ theme }) => ({
  alignSelf: "flex-end",
  marginTop: "auto",

  // Hidden unless we are at desktop resolution
  [theme.media.max.tablet]: {
    display: "none",
  },
}));
