import React from "react";

import { Button, ListItem, Text } from "/component/base";
import { Check, MyAccountComplete } from "/component/base/Icon";
import { useUserAccount } from "/component/layout/MainLayout/useUserAccount";
import { SignUpFormType } from "/constant/signUp.constant";
import { useTranslation } from "/hook";
import { layout } from "/styles";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  logEvent,
} from "/util/firebase.util";

import { FormContentContainer } from "../SignUpForms.styles";
import { SignUpFormProps } from "../SignUpForms.types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const VerificationStatus = (props: SignUpFormProps<any>) => {
  const { t } = useTranslation("form-signUp");
  const { data: userData } = useUserAccount();

  const emailVerification = userData?.viewer?.user?.account?.emailVerificationStatus;
  const isEmailVerified = emailVerification === "approved";

  const continueForm = () => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.ONBOARDING_NEW,
      source: AnalyticsSource.ONBOARDING_STATUS,
      button_name: "Finish",
    } as ButtonClickParams);

    props.handleSubmit();
  };

  const goBack = () => props.handleSubmit(SignUpFormType.EmailVerification);

  return (
    <FormContentContainer>
      <div css={[layout.flexCenter, layout.marginVertical("standard")]}>
        <MyAccountComplete color="brandPrimary" size={80} />
      </div>

      <ListItem.Div
        showChevron={false}
        showBorder={true}
        css={[layout.flexSpaceHorizontal, layout.padding("condensed", "skip")]}
      >
        <Text variant="body1Bold" color="statusPositive">
          {t("verificationStatus.createAccount")}
        </Text>
        <Check color="statusPositive" />
      </ListItem.Div>
      <ListItem.Div
        showChevron={false}
        showBorder={true}
        css={[layout.flexSpaceHorizontal, layout.padding("condensed", "skip")]}
      >
        <Text variant="body1Bold" color="statusPositive">
          {t("verificationStatus.secureAccount")}
        </Text>
        <Check color="statusPositive" />
      </ListItem.Div>
      <ListItem.Div
        showChevron={false}
        showBorder={true}
        css={[layout.flexSpaceHorizontal, layout.padding("condensed", "skip")]}
      >
        <Text variant="body1Bold" color={isEmailVerified ? "statusPositive" : "objectSubtle"}>
          {t("verificationStatus.verifyEmail")}
        </Text>
        {isEmailVerified && <Check color="statusPositive" />}
      </ListItem.Div>

      <FormContentContainer.ButtonGroup>
        <Button onClick={continueForm} variant="primary">
          {isEmailVerified ? t("finish") : t("continue")}
        </Button>
        {!isEmailVerified && (
          <Button onClick={goBack} variant="secondary">
            {t("back")}
          </Button>
        )}
      </FormContentContainer.ButtonGroup>
    </FormContentContainer>
  );
};

export default VerificationStatus;
