import { useMutation } from "@apollo/client";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { omit } from "lodash";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { RouteComponentProps } from "react-router";

import { auth } from "/apollo/client/local";
import {
  CaregiverStatus,
  ConfirmCaregiverAccess,
  ConfirmCaregiverAccessVariables,
  GetCaregiverAccess_viewer_user_caregiverAccess,
} from "/apollo/schema/types";
import { Alert, Button, Divider, Link, Spinner, Text } from "/component/base";
import MainContent from "/component/layout/MainContent";
import CaregiverCard from "/component/partial/CaregiverCard";
import { useCaregiverProvider } from "/component/provider/CaregiverProvider";
import { useToastContext } from "/component/provider/ToastProvider";
import { CaregiverFormType } from "/constant/caregiver.constant";
import routes from "/constant/url.constant";
import { useAmwell, useContactInfo, useTranslation } from "/hook";
import { layout } from "/styles";
import { reactPlugin } from "/util/appInsights.util";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  AppModalParams,
  ButtonClickParams,
  logEvent,
} from "/util/firebase.util";

import confirmRevokedCaregiverAccessGql from "../../../../page/Account/page/CaregiverIndex/confirmRevokedCaregiverAccess";
import { CaregiverStatusType, CaregiverType } from "../../../../provider/CaregiverProvider";
import {
  ButtonContainer,
  CaregiverItemsContainer,
  SpinnerContainer,
} from "./TelehealthPatientSelection.styles";

const MAX_CAREGIVER_ACCESS = 10;
interface CaregiverAccess {
  firstName: string | null;
  lastName: string | null;
  id: string | null;
  isMinor: boolean;
}

const TelehealthPatientSelection = ({ history }: RouteComponentProps) => {
  const { t } = useTranslation("telehealth");

  const { contactByName } = useContactInfo();
  const { showToast } = useToastContext();

  const logButtonClickEvent = (buttonName: string, buttonDescription?: string) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.GET_VIRTUAL_URGENT_CARE,
      source: AnalyticsSource.TELEHEALTH_PATIENT_SELECTION,
      button_name: buttonName,
      button_description: buttonDescription,
    } as ButtonClickParams);
  };

  const {
    caregiver,
    clearCaregiver,
    setCaregiver,
    setCaregiverToggle,
    caregiverAccess: { caregiverAccounts, numCaregiverAccounts, caregiverAccountsRefetch },
    userAccount,
  } = useCaregiverProvider();
  const { renewAuth, toggleDependent, isReady } = useAmwell();
  const caregiverAccesses = caregiverAccounts?.viewer?.user.caregiverAccess || [];
  const [revokedUser, setRevokedUser] = useState<CaregiverAccess>();
  const [isAccessRevokedAlertOpen, setAccessRevokedAlert] = useState(false);
  const closeAccessRevokedAlert = () => setAccessRevokedAlert(false);

  const handleContinue = () => {
    history.push(routes.telehealthProviders);
  };

  const handleAddAccount = () => {
    history.push(routes.caregiver, {
      initialFlow: CaregiverFormType.AddChild,
    });
  };

  const switchMyAccount = async () => {
    // switching to already selected account - no need for code below
    if (!caregiver.id) {
      return;
    }

    logButtonClickEvent("Account", "My account");

    clearCaregiver();
    auth.setCaregiver(null);
    renewAuth();
  };

  const switchCaregiver = async (user: GetCaregiverAccess_viewer_user_caregiverAccess) => {
    // switching to already selected account - no need for code below
    if (user.id === caregiver.id) {
      return;
    }

    logButtonClickEvent("Account", "Caregiver account");

    if (user.statusDetails?.status === CaregiverStatus.REVOKED) {
      setRevokedUser({
        firstName: user.firstName,
        lastName: user.lastName,
        id: user.id,
        isMinor: !!user.isMinor,
      });
      setAccessRevokedAlert(true);
      return;
    }
    const _caregiver = omit(user, "__typename") as CaregiverType;
    _caregiver.statusDetails = omit(user.statusDetails, "__typename") as CaregiverStatusType;
    setCaregiver(_caregiver);
    setCaregiverToggle(true);
    auth.setCaregiver(user.id);

    if (user.isMinor && user.birthDate)
      await toggleDependent({
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        dob: new Date(user.birthDate),
        gender: user.gender?.toString() || "",
      });
    else await renewAuth();
  };

  const renderAccessRevokedAlert = () => {
    const complianceEmail = contactByName("compliance")?.emailAddress;

    let contactButton;
    if (revokedUser?.isMinor) {
      if (complianceEmail) {
        contactButton = (
          <Link.Anchor target="#" href={`mailto:${complianceEmail}`}>
            {t("dialog.accessRevoked.contactPrivacy")}
          </Link.Anchor>
        );
      }
    } else {
      contactButton = (
        <Link.Anchor target="#" href={`mailto:AppSupport@summithealth.com`}>
          {t("dialog.accessRevoked.contactSupport")}
        </Link.Anchor>
      );
    }

    return (
      <Alert
        close={closeAccessRevokedAlert}
        footer={
          <Button fullWidth="flex" onClick={confirmRevokedCaregiverAccess}>
            {t("dialog.accessRevoked.continue")}
          </Button>
        }
        title={t("dialog.accessRevoked.title", {
          name: `${revokedUser?.firstName} ${revokedUser?.lastName} `,
        })}
        isOpen={isAccessRevokedAlertOpen}
      >
        <Text css={{ whiteSpace: "pre-line" }} variant="body1" color="textSecondary">
          {t("dialog.accessRevoked.subtitle")}
        </Text>
        {contactButton}
      </Alert>
    );
  };

  const confirmRevokedCaregiverAccess = async () => {
    closeAccessRevokedAlert();
    if (!revokedUser?.id) return;
    const response = await confirmInviteCaregiverAccess({
      variables: {
        data: {
          caregiverAccessId: revokedUser.id,
        },
      },
    });
    if (response.errors?.length || response.data?.confirmInviteCaregiverAccess?.errors?.length) {
      showToast({
        message: t("error"),
        icon: "alert",
      });
    }
    caregiverAccountsRefetch();
  };

  const [confirmInviteCaregiverAccess] = useMutation<
    ConfirmCaregiverAccess,
    ConfirmCaregiverAccessVariables
  >(confirmRevokedCaregiverAccessGql);

  useEffect(() => {
    if (isAccessRevokedAlertOpen) {
      logEvent(AnalyticsEvent.APP_MODAL_VIEW, {
        user_flow: AnalyticsUserFlow.CAREGIVER,
        modal_name: AnalyticsSource.MODAL_CAREGIVER_ACCESS_REVOKED,
      } as AppModalParams);
    }
  }, [isAccessRevokedAlertOpen]);

  if (!isReady) {
    return (
      <SpinnerContainer>
        <Spinner size={48} />
        <Text
          css={layout.margin("expanded", "skip", "gutter", "skip")}
          color="brandNavy"
          variant="title2"
        >
          {t("oneMomentPlease")}
        </Text>
        <Text align="center" variant="body1">
          {t("loadingNote")}
        </Text>
      </SpinnerContainer>
    );
  }

  return (
    <MainContent
      parentPage={"telehealth"}
      pageTitle={t("patientSelection.headline", "Choose the patient for your visit")}
    >
      <Helmet>
        <title>{t("patientSelection.title", "Patient Selection")}</title>
      </Helmet>

      <CaregiverItemsContainer>
        <CaregiverCard
          isSelected={!caregiver.id}
          name={userAccount?.viewer?.user?.account || { firstName: "", lastName: "" }}
          onClick={switchMyAccount}
        />
        {caregiverAccesses.map((proxyProp, idx) => (
          <React.Fragment key={idx}>
            <Divider />
            <CaregiverCard
              key={idx}
              isSelected={caregiver.id === proxyProp.id}
              name={{
                firstName: proxyProp.firstName,
                lastName: proxyProp.lastName,
              }}
              onClick={() => switchCaregiver(proxyProp)}
            />
          </React.Fragment>
        ))}
        {numCaregiverAccounts < MAX_CAREGIVER_ACCESS && (
          <>
            <Divider />
            <CaregiverCard isAddition={true} onClick={handleAddAccount} />
          </>
        )}
      </CaregiverItemsContainer>

      <ButtonContainer>
        <Button onClick={handleContinue} variant="primary">
          {t("patientSelection.continue", "Continue")}
        </Button>
      </ButtonContainer>
      {renderAccessRevokedAlert()}
    </MainContent>
  );
};

export default withAITracking(reactPlugin, TelehealthPatientSelection, undefined, "ai-tracking");
