import { useReactiveVar } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { address } from "/apollo/client/local";
import { Button, Modal, Text } from "/component/base";
import { CornerUpRight, Phone } from "/component/base/Icon";
import Map from "/component/base/Map";
import usePreferredPharmacy from "/component/page/MyHealth/page/PreferredPharmacy/usePreferredPharmacy";
import { useToastContext } from "/component/provider/ToastProvider";
import { useGoogleMaps, useTranslation } from "/hook";
import { useAnalytics } from "/hook/useAnalytics/useAnalytics";
import { layout } from "/styles";
import { formatText, googlePlaces } from "/util";
import getDistance from "/util/distance.util";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  logEvent,
  ScreenViewParams,
} from "/util/firebase.util";

import { PharmacyButton } from "./PharmacyDetailModal.styles";
import { Props } from "./PharmacyDetailModal.types";
import usePharmacyDetailModal from "./usePharmacyDetailModal";

const PharmacyDetail = ({
  close,
  dismissable = true,
  facilityId,
  isOpen,
  pharmacy,
  pharmacyId,
}: Props) => {
  const { t } = useTranslation("pharmacyDetailModal");
  const { showToast } = useToastContext();
  const history = useHistory();
  const { refetch } = usePreferredPharmacy();
  const { recordAnalyticsEvent } = useAnalytics();
  const [refetching, setRefetching] = useState(false);

  const { loading, submitUpdatedPharmacy } = usePharmacyDetailModal({
    success: () => {
      showToast({ icon: "alert", message: t("alert.updateSuccess") });
      fetchUpdatedPharmacyData();
      recordAnalyticsEvent("preferredPharmacyChanged", { sharedModule: false });
    },
    failure: () => showToast({ icon: "alert", message: t("alert.updateFailure"), type: "error" }),
  });
  const [pharmacyAddressData, setPharmacyAddressData] = useState<google.maps.places.PlaceResult>();
  const googleMaps = useGoogleMaps();
  const storedAddress = useReactiveVar(address.var);
  const pharmacyCoords = {
    lat: pharmacyAddressData?.geometry?.location?.lat() || 0,
    lng: pharmacyAddressData?.geometry?.location?.lng() || 0,
  };
  const center = `${pharmacyCoords.lat},${pharmacyCoords.lng}`;

  const {
    address: { line1, line2, postalCode, state, city },
    name,
    contact: { phoneNumber },
  } = pharmacy;
  const addressString = `${line1} ${line2 || ""} ${city}, ${state} ${postalCode}`;

  const fetchUpdatedPharmacyData = async () => {
    setRefetching(true);
    try {
      await refetch();
    } finally {
      setRefetching(false);
      history.goBack();
    }
  };

  // Calculate mile distances between user location and pharmacy address
  const getPharmacyDistance = () => {
    const showMiles =
      storedAddress.lat && storedAddress.lng && pharmacyCoords.lat && pharmacyCoords.lng;
    return (
      showMiles &&
      getDistance(
        {
          lat: storedAddress.lat as number,
          lng: storedAddress.lng as number,
        },
        {
          lat: pharmacyCoords.lat,
          lng: pharmacyCoords.lng,
        },
      )
    );
  };

  const handlePharmacyUpdate = () => {
    if (pharmacyId && facilityId) {
      logButtonClickEvent("Add as preferred pharmacy");
      submitUpdatedPharmacy({ ncpdpId: pharmacyId, facilityId });
    }
  };

  useEffect(() => {
    if (googleMaps && !pharmacyAddressData) {
      const getPharmacyCoordinates = async () => {
        if (googleMaps) {
          const result = await googlePlaces.getPlaceByAddress(googleMaps, addressString);
          if (result) {
            setPharmacyAddressData(result);
          }
        }
      };

      getPharmacyCoordinates();
    }
  }, [googleMaps, pharmacyAddressData]);

  useEffect(() => {
    if (isOpen) {
      logEvent(AnalyticsEvent.SCREEN_VIEW, {
        screenName: AnalyticsSource.PREFERRED_PHARMACY_DETAILS,
      } as ScreenViewParams);
    }
  }, [isOpen]);

  const logButtonClickEvent = (buttonName: string) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.MY_HEALTH,
      source: AnalyticsSource.PREFERRED_PHARMACY_DETAILS,
      button_name: buttonName,
      pharmacy_id: pharmacyId,
      facility_id: facilityId,
    } as ButtonClickParams);
  };

  const handleGetPharmacyDirections = () => {
    logButtonClickEvent("Get directions in maps");
    recordAnalyticsEvent("pharmacyDirections", { sharedModule: false });
  };

  const handleCallPharmacy = () => {
    logButtonClickEvent("Phone number");
    recordAnalyticsEvent("pharmacyCall", { sharedModule: false });
  };

  return (
    <Modal
      close={dismissable ? close : undefined}
      header={
        <Modal.Header hideHeaderLeft={!dismissable} close={close} title={name || undefined} />
      }
      isOpen={isOpen}
      key="PharmacyDetailModal"
    >
      <Map
        alt={t("button.directions")}
        center={center}
        css={layout.margin("skip", "skip", "expanded")}
      />

      <Text color="textSecondary" element="h3" variant="body2Bold">
        {t("label.address")}
      </Text>

      <p css={layout.flexVertical}>
        <Text variant="body1">{line1}</Text>
        <Text variant="body1">{line2 || ""}</Text>
        <Text variant="body1">{`${city}, ${state} ${postalCode}`}</Text>
      </p>

      <Text color="textSecondary" element="p" variant="body2">
        {t("milesAway", { miles: getPharmacyDistance() })}
      </Text>

      <div
        css={[
          layout.flexVerticalCenter,
          layout.marginVertical("standard"),
          layout.spacedChildrenVertical(),
        ]}
      >
        {pharmacyId && facilityId && (
          <Button
            fullWidth="flex"
            isDisabled={loading || refetching}
            isLoading={loading || refetching}
            onClick={handlePharmacyUpdate}
          >
            <Text color="objectInverse" variant="title3">
              {t("button.addPharmacy")}
            </Text>
          </Button>
        )}

        <PharmacyButton
          href={`https://www.google.com/maps/search/?api=1&query=${center}`}
          onClick={handleGetPharmacyDirections}
          target="_blank"
          variant="secondary"
        >
          <CornerUpRight
            aria-hidden
            css={layout.margin("skip", "condensed", "skip")}
            size={16}
            color="brandPrimary"
          />

          <Text color="brandPrimary" variant="title3">
            {t("button.directions")}
          </Text>
        </PharmacyButton>

        {phoneNumber && (
          <PharmacyButton
            aria-label={t("button.callPharmacy", {
              phoneNumber: formatText.parsePhone(phoneNumber),
            })}
            href={`tel:+1${phoneNumber.replace(/[-() ]/g, "")}`}
            target="_blank"
            variant="secondary"
            onClick={handleCallPharmacy}
          >
            <Phone
              aria-hidden
              color="brandPrimary"
              css={layout.margin("skip", "condensed", "skip")}
              size={16}
            />

            <Text color="brandPrimary" variant="title3">
              {formatText.parsePhone(phoneNumber)}
            </Text>
          </PharmacyButton>
        )}
      </div>
    </Modal>
  );
};

export default PharmacyDetail;
