// Modules
import React from "react";

import { Base } from "./TextArea.styles";
import { Props } from "./TextArea.types";

const TextArea = React.forwardRef<HTMLTextAreaElement, Props>(
  ({ $hasError, className, rows, ...rest }, ref) => (
    <Base ref={ref} $hasError={$hasError} className={className} rows={rows || 3} {...rest} />
  ),
);

export default TextArea;
