import React from "react";

import { Avatar, Text } from "/component/base";
import { layout } from "/styles";
import { formatText } from "/util";

import { Base } from "./ProviderItem.styles";
import { Props } from "./ProviderItem.types";

const ProviderItem = ({ className, photoUrl, name, title, subtitle }: Props) => {
  return (
    <Base className={className}>
      <Avatar
        css={layout.flexItemIntrinsic}
        src={photoUrl}
        initials={formatText.parseInitials(name)}
        alt={title || ""}
      />
      <div
        css={[
          layout.flexVertical,
          layout.padding("skip", "skip", "skip", "standard"),
          layout.flexItemAuto,
        ]}
      >
        <Text
          element="div"
          color="textTitle"
          variant="body1Bold"
          css={layout.margin("none", "none", "base")}
        >
          {title}
        </Text>
        <Text element="div" variant="body2" color="textSecondary">
          {subtitle}
        </Text>
      </div>
    </Base>
  );
};

export default ProviderItem;
