import awsdk from "awsdk";
import { useField } from "formik";
import React from "react";
import { Trans } from "react-i18next";

import { Avatar, Link, List, Text } from "/component/base";
import { useMessageQuery } from "/component/page/Account/page/Message/useMessageQuery";
import { useTranslation } from "/hook";
import { layout } from "/styles";
import { formatText } from "/util";
import { parseInitials } from "/util/formatText";

import { DisclaimerInnerContainer, DisclaimerOuterContainer } from "./MessageContactsForm.styles";
import { Props } from "./MessageContactsForm.types";

const MessageContactsForm = ({ contacts = [], name }: Props) => {
  const [, , helpers] = useField({ name });
  const { t } = useTranslation("composeMessage");
  const handleSetContact = (contact: awsdk.AWSDKSecureMessageContact) => {
    helpers.setValue(contact);
  };

  const { contactByName } = useMessageQuery();
  const aftercarePhoneNumber = contactByName("cityMdAftercare")?.phoneNumber;
  const aftercarePhoneNumberFormatted =
    aftercarePhoneNumber && formatText.parsePhone(aftercarePhoneNumber);

  const callCenterPhoneNumber = contactByName("summitCallCenter")?.phoneNumber;
  const callCenterPhoneNumberFormatted =
    callCenterPhoneNumber && formatText.parsePhone(callCenterPhoneNumber);

  return (
    <>
      {contacts?.length ? (
        <List
          as="Buttons"
          data={contacts}
          onClick={handleSetContact}
          renderItem={(contact) => (
            <div css={[layout.flexCenterHorizontal, layout.padding("standard", "skip")]}>
              <Avatar aria-hidden alt="" initials={parseInitials(contact)} />

              <Text css={layout.padding("skip", "skip", "skip", "standard")} variant="body1Bold">
                {`${contact.firstName} ${contact.lastName}`}
              </Text>
            </div>
          )}
        />
      ) : undefined}

      <DisclaimerOuterContainer>
        {contacts?.length === 0 && (
          <Text
            align="center"
            css={[layout.margin("expanded", "skip", "skip", "skip")]}
            variant="body1Bold"
          >
            {t("noProvidersAvailable")}
          </Text>
        )}

        <DisclaimerInnerContainer>
          <Text color="textSecondary" element="p" variant="body2">
            <Trans
              components={{
                aftercareAnchor: (
                  <Link.Anchor href={`tel:${aftercarePhoneNumber}`} variant="tertiary" />
                ),
                callCenterAnchor: (
                  <Link.Anchor href={`tel:${callCenterPhoneNumber}`} variant="tertiary" />
                ),
              }}
              i18nKey="disclaimer.expandingService"
              t={t}
              values={{
                aftercarePhoneNumber: aftercarePhoneNumberFormatted,
                callCenterPhoneNumber: callCenterPhoneNumberFormatted,
              }}
            />
          </Text>

          <Text
            color="textSecondary"
            css={layout.padding("condensed", "skip", "skip")}
            element="p"
            variant="body2"
          >
            {t("disclaimer.notForEmergencies")}
          </Text>
        </DisclaimerInnerContainer>
      </DisclaimerOuterContainer>
    </>
  );
};

export default MessageContactsForm;
