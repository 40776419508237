import styled from "styled-components";

import { Button, Text } from "/component/base";
import { layout } from "/styles";
import { ThemeType } from "/theme/default";

export const SegmentButton = styled(Button)(
  ({ theme, isSelected }: ThemeType & { isSelected: boolean }) => ({
    backgroundColor: isSelected ? theme.colors.objectInverse : "transparent",
    border: 0,
    borderRadius: 12,
    margin: theme.spacing.base,
    width: "100%",
    flex: "1 1 auto",
  }),
);

export const ControlContainer = styled.div(layout.flexSpaceHorizontal, ({ theme }) => ({
  backgroundColor: theme.colors.brandPrimaryLight,
  borderRadius: 12,
}));

export const ActiveBadge = styled.div(({ theme }) => ({
  borderRadius: 50,
  background: theme.colors.brandPrimary,
  width: 6,
  height: 6,
}));

export const InlineText = styled(Text)(({ isSelected }: ThemeType & { isSelected: boolean }) => ({
  opacity: isSelected ? "100%" : "30%",
  display: "inline-flex",
}));
