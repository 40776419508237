import awsdk from "awsdk";
import { FormikHelpers } from "formik";
import { startCase } from "lodash";
import React, { useEffect } from "react";

import {
  Allergies,
  Attachments,
  ConsumerPharmacy,
  DiagnosedConditions,
  InsuranceDetail,
  InviteGuests,
  Medications,
  PatientCallback,
  PatientInfoConfirm,
  PharmacyAddressConfirmation,
  PharmacySelection,
  ReasonForVisit,
  TelehealthFormProps,
  TelehealthInsurance,
  VisitCost,
  Vitals,
} from "/component/form/Telehealth";
import { useAmwellPrevisitContext } from "/component/provider/AmwellPrevisitProvider";
import { PropValueTypes } from "/component/provider/AmwellPrevisitProvider/AmwellPrevisitProvider.types";
import { useToastContext } from "/component/provider/ToastProvider";
import { PrevisitForms } from "/constant/telehealth.constant";
import { useTranslation } from "/hook";

interface FormConfigProps {
  [PrevisitForms: string]: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Form: (props: TelehealthFormProps<any>) => JSX.Element;
    nextForm: PrevisitForms;
  };
}

export const FormsConfig = {
  [PrevisitForms.REASON_FOR_VISIT]: {
    Form: ReasonForVisit,
    nextForm: PrevisitForms.ATTACHMENTS,
  },
  [PrevisitForms.ATTACHMENTS]: {
    Form: Attachments,
    nextForm: PrevisitForms.CONFIRM_PATIENT_INFO,
  },
  [PrevisitForms.CONFIRM_PATIENT_INFO]: {
    Form: PatientInfoConfirm,
    nextForm: PrevisitForms.DIAGNOSED_CONDITIONS,
  },
  [PrevisitForms.DIAGNOSED_CONDITIONS]: {
    Form: DiagnosedConditions,
    nextForm: PrevisitForms.ALLERGIES,
  },
  [PrevisitForms.ALLERGIES]: {
    Form: Allergies,
    nextForm: PrevisitForms.MEDICATIONS,
  },
  [PrevisitForms.MEDICATIONS]: {
    Form: Medications,
    nextForm: PrevisitForms.VITALS,
  },
  [PrevisitForms.VITALS]: {
    Form: Vitals,
    nextForm: PrevisitForms.CONSUMER_PHARMACIES,
  },
  [PrevisitForms.CONSUMER_PHARMACIES]: {
    Form: ConsumerPharmacy,
    nextForm: PrevisitForms.PHARMACY_SELECTION,
  },
  [PrevisitForms.PHARMACY_SELECTION]: {
    Form: PharmacySelection,
    nextForm: PrevisitForms.PATIENT_CALLBACK,
  },
  [PrevisitForms.PHARMACY_ADDRESS_CONFIRMATION]: {
    Form: PharmacyAddressConfirmation,
    nextForm: PrevisitForms.PATIENT_CALLBACK,
  },
  [PrevisitForms.PATIENT_CALLBACK]: {
    Form: PatientCallback,
    nextForm: PrevisitForms.INVITE_GUESTS,
  },
  [PrevisitForms.INVITE_GUESTS]: {
    Form: InviteGuests,
    nextForm: PrevisitForms.TELEHEALTH_INSURANCE,
  },
  [PrevisitForms.TELEHEALTH_INSURANCE]: {
    Form: TelehealthInsurance,
    nextForm: PrevisitForms.INSURANCE_DETAIL,
  },
  [PrevisitForms.INSURANCE_DETAIL]: {
    Form: InsuranceDetail,
    nextForm: PrevisitForms.VISIT_COST,
  },
  [PrevisitForms.VISIT_COST]: {
    Form: VisitCost,
    nextForm: PrevisitForms.EMPTY,
  },
};

interface Props {
  currentForm: string;
  setForm: (formName: PrevisitForms) => void;
  completeWizard: () => void;
  formConfig?: FormConfigProps;
  handleBack: () => void;
}

const PrevisitFormWizard = ({
  currentForm,
  setForm,
  completeWizard,
  formConfig = FormsConfig,
  handleBack,
}: Props) => {
  const { t } = useTranslation("telehealth-previsit");
  const { showToast } = useToastContext();
  const { handleSubmit, formValues, recoverPrevisit } = useAmwellPrevisitContext();
  const { Form: TelehealthForm, nextForm } = formConfig[currentForm];

  useEffect(() => {
    if (process.env.TELEHEALTH_RECOVER_VISIT === "true") recoverPrevisit();
  }, []);

  const handleError = (
    e: awsdk.AWSDKValidationErrors | awsdk.AWSDKError,
    actions?: FormikHelpers<PropValueTypes>,
    values?: PropValueTypes,
  ) => {
    let errorString: string[] = [];
    if (e.errors) {
      if (values && actions) {
        e.errors.forEach(({ fieldName, recoverySuggestion }) => {
          const fieldError = `${startCase(fieldName)}, ${recoverySuggestion}`;
          if (values[fieldName]) {
            actions.setFieldError(fieldName, recoverySuggestion);
          } else {
            errorString.push(fieldError);
          }
        });
      } else {
        errorString = e.errors.map(
          ({ fieldName, recoverySuggestion }) => `${startCase(fieldName)}, ${recoverySuggestion}`,
        );
      }
    }
    showToast({
      icon: "alert",
      message: errorString.length > 0 ? errorString.join[""] : t("errorOccured"),
      type: "error",
    });
  };

  const formSubmit = (values?: PropValueTypes, skipToForm?: PrevisitForms) => {
    if (values) {
      handleSubmit(currentForm, values);
    }

    if (skipToForm) {
      setForm(skipToForm);
    } else if (nextForm === PrevisitForms.EMPTY) {
      completeWizard();
    } else {
      setForm(nextForm);
    }
  };

  return (
    <TelehealthForm
      initialValues={formValues[currentForm]}
      handleError={handleError}
      handleSubmit={formSubmit}
      handleBack={handleBack}
    />
  );
};

export default PrevisitFormWizard;
