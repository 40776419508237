import { Actions, actionTypes as actions, State } from "./reducer.types";

export const initialState = {
  data: undefined,
  error: false,
  loading: false,
};

export const reducer = <T>(state: State<T> = initialState, action: Actions<T>) => {
  switch (action.type) {
    case actions.LOADING:
      return { ...state, error: false, loading: true };
    case actions.FAILURE:
      return {
        data: undefined,
        error: true,
        loading: false,
      };
    case actions.INIT:
      return {
        data: undefined,
        error: false,
        loading: false,
      };
    case actions.SUCCESS:
      return { data: action.payload, error: false, loading: false };
    default:
      return state;
  }
};
