import styled from "styled-components";

import Button from "/component/base/Button";
import { layout } from "/styles";

export const AddressButtonBlank = styled(Button)(({ theme }) => ({
  borderRadius: 8,
  paddingTop: theme.spacing.base,
  paddingRight: theme.spacing.condensed,
  paddingBottom: theme.spacing.base,
  paddingLeft: theme.spacing.base * 3,
  backgroundColor: theme.colors.brandPrimaryLight,
  display: "inline-flex",
  alignItems: "center",
  [`& > :first-child`]: {
    marginRight: theme.spacing.condensed,
  },
}));

export const AddressButtonUnstyled = styled(Button)(layout.flexCenterHorizontal);
