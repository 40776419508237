import styled from "styled-components";

import { layout } from "/styles";
import { ThemeType } from "/theme/default";

const linkStyleFn = ({ theme }: ThemeType) => ({
  color: theme.colors.brandPrimary,
  textDecoration: "none",
  fontFamily: theme.fontVariants.body1Bold,
});

export const Anchor = styled.a(linkStyleFn);

export const FormContentContainer = styled.div(layout.spacedChildrenVertical("standard"));

export const HeaderContainer = styled.div(
  layout.flexVertical,
  layout.spacedChildrenVertical("condensed"),
);
