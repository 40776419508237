import React from "react";
import styled from "styled-components";

import { CommonProps, getTextStyles, StyleProps } from "/component/base/Text";

type Props = CommonProps<"label"> & React.ComponentPropsWithoutRef<"label">;

export const Base = styled.label<StyleProps>(
  ({ $align, $color = "textPrimary", $truncate, $variant = "body1", theme }) =>
    getTextStyles({ align: $align, color: $color, truncate: $truncate, variant: $variant, theme }),
);

const Label = ({
  align,
  className,
  color,
  element,
  truncate = false,
  variant = "body1",
  ...rest
}: Props) => (
  <Base
    $align={align}
    $color={color}
    $truncate={truncate}
    $variant={variant}
    as={element}
    className={className}
    {...rest}
  />
);

export default Label;
