import { withAITracking } from "@microsoft/applicationinsights-react-js";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router-dom";

import noResultsFound from "/asset/image/noResultsFound.png";
import { ErrorCode, getErrorCode } from "/b2c/client/utils";
import { useImmunizations } from "/b2c/query/immunizations.b2c";
import { GET_IMMUNIZATIONS_patient_immunizations } from "/b2c/schema";
import { List } from "/component/base";
import { AlertCircleOpen } from "/component/base/Icon";
import MainContent from "/component/layout/MainContent";
import EmptyStateCta from "/component/partial/EmptyStateCta";
import routes from "/constant/url.constant";
import { useTranslation } from "/hook";
import { useAnalytics } from "/hook/useAnalytics/useAnalytics";
import { layout } from "/styles";
import { reactPlugin } from "/util/appInsights.util";

import LoadingSkeleton from "../../../../partial/LoadingSkeleton";
import ImmunizationListItem from "./components/ImmunizationListItem";
import { ImmunizationsWrapper, ListContainer } from "./ImmunizationsB2C.styles";

const IMAGE_HEIGHT = 261;
const IMAGE_WIDTH = 183;

const Immunizations = () => {
  const { t } = useTranslation("immunizations");
  const history = useHistory();
  const { recordAnalyticsEvent } = useAnalytics();

  const { data: immunizations, error, loading: isLoading } = useImmunizations();

  const handlePressImmunization = (immunization: GET_IMMUNIZATIONS_patient_immunizations) => {
    history.push(`${routes.immunizations}/${immunization.id}`, { immunization });
  };

  useEffect(() => {
    recordAnalyticsEvent("viewImmunizations");
  }, [recordAnalyticsEvent]);

  if (isLoading)
    return (
      <MainContent pageTitle={t("pageTitle")} parentPage="myHealth">
        <LoadingSkeleton skeletonVariant="list-item" />
      </MainContent>
    );

  if (error && getErrorCode(error, immunizations) !== ErrorCode.PARTIAL_RESPONSE) {
    return (
      <MainContent pageTitle={t("pageTitle")} parentPage="myHealth">
        <Helmet>
          <title>{t("pageTitle")}</title>
        </Helmet>
        <div css={layout.flexVerticalCenter}>
          <div css={layout.marginVertical(80)}>
            <EmptyStateCta
              icon={<AlertCircleOpen color="brandPrimary" size={48} />}
              title={t("error.fetchError.title")}
              subtitle={t("error.fetchError.subtitle")}
            />
          </div>
        </div>
      </MainContent>
    );
  }

  if (immunizations?.length === 0) {
    return (
      <MainContent pageTitle={t("pageTitle")} parentPage="myHealth">
        <Helmet>
          <title>{t("pageTitle")}</title>
        </Helmet>

        <div css={layout.flexVerticalCenter}>
          <div css={layout.marginVertical(60)}>
            <EmptyStateCta
              imageHeight={IMAGE_HEIGHT}
              imageWidth={IMAGE_WIDTH}
              imageSource={noResultsFound}
              title={t("emptyState.title")}
              subtitle={t("emptyState.subtitle")}
            />
          </div>
        </div>
      </MainContent>
    );
  }

  return (
    <MainContent pageTitle={t("pageTitle")} parentPage="myHealth">
      <Helmet>
        <title>{t("pageTitle")}</title>
      </Helmet>

      <ImmunizationsWrapper>
        <ListContainer>
          <List
            as="Buttons"
            chevronVariant="grey"
            hideLastChildBorder
            onClick={handlePressImmunization}
            data={immunizations || []}
            renderItem={(immunization) => (
              <ImmunizationListItem
                immunization={{
                  title: immunization.name || "",
                  dateString: immunization.administeredDate,
                }}
              />
            )}
          />
        </ListContainer>
      </ImmunizationsWrapper>
    </MainContent>
  );
};

export default withAITracking(reactPlugin, Immunizations, undefined, "ai-tracking");
