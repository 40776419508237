import styled from "styled-components";

import { layout } from "/styles";

export const ConfirmationContainer = styled.div(
  layout.margin("gutter", "skip", "skip", "skip"),
  layout.flexVerticalCenter,
  layout.spacedChildrenVertical("gutter"),
);

export const PreferenceContainer = styled.div(
  layout.margin("gutter", "skip", "skip", "skip"),
  layout.spacedChildrenVertical("gutter"),
);
