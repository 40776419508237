import React, { forwardRef } from "react";

import { AlertCircleOpen, Check } from "/component/base/Icon";
import Text from "/component/base/Text";
import { layout } from "/styles";

import { Base } from "./Toast.styles";
import { Props, ToastType } from "./Toast.types";

const icons = {
  alert: AlertCircleOpen,
  checkmark: Check,
} as const;

const getToastColor = (type: ToastType) => {
  switch (type) {
    case "error":
      return "brandSecondary";
    default:
      return "textNavyBlue";
  }
};

/**
 * The plain Toast component that will be controlled by the ToastProvider.
 */
const Toast = forwardRef<HTMLDivElement, Props>(
  ({ className, icon = "alert", title, message, type = "default" }, ref) => {
    const Icon = icons[icon];
    const color = getToastColor(type);

    return (
      <Base className={className} ref={ref} $type={type}>
        <div css={layout.flexCenterHorizontal}>
          <Icon color={color} size={24} />
          <div css={layout.margin("skip", "skip", "skip", "standard")}>
            {!!title && (
              <Text color={color} variant="body1Bold" element="div">
                {title}
              </Text>
            )}
            {!!message && <Text color={color}>{message}</Text>}
          </div>
        </div>
      </Base>
    );
  },
);

export default Toast;
