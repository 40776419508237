import { Form, Formik } from "formik";
import React, { ChangeEvent } from "react";
import * as Yup from "yup";

import Button from "/component/base/Button";
import Divider from "/component/base/Divider";
import InputWrapper from "/component/base/InputWrapper";
import Modal from "/component/base/Modal";
import Radio from "/component/base/Radio";
import Text from "/component/base/Text";
import TextInput from "/component/base/TextInput";
import FormContent from "/component/partial/FormContent";
import RadioGroupField from "/component/partial/formik/RadioGroupField";
import { useTranslation } from "/hook";
import { layout } from "/styles";
import { parsePhone } from "/util/formatText";

import { Props } from "./TelehealthCallbackModal.types";

const PHONE_REGEX = /^\(?([0-9]{3})\)?[ ]?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/;

const TelehealthCallbackModal = ({ isOpen, close, handleSubmit, callbackNumber }: Props) => {
  const { t } = useTranslation("modal-telehealthCallback");

  const callbackValidation = Yup.object().shape({
    callbackType: Yup.string().required(),
    callbackNumber: Yup.string().matches(PHONE_REGEX),
    newCallbackNumber: Yup.string()
      .matches(PHONE_REGEX)
      .when("callbackType", {
        is: "Different",
        then: Yup.string().matches(PHONE_REGEX).required(),
      }),
  });

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={{ callbackType: "Current", callbackNumber, newCallbackNumber: callbackNumber }}
      validationSchema={callbackValidation}
      onSubmit={({ callbackNumber, newCallbackNumber, callbackType }) => {
        if (callbackType === "Current") {
          handleSubmit(callbackNumber);
        } else if (callbackType === "Different") {
          handleSubmit(newCallbackNumber);
        } else {
          handleSubmit("");
        }
        close();
      }}
    >
      {({ submitForm, isValid, setFieldValue, errors }) => (
        <Form>
          <Modal
            isOpen={isOpen}
            key="TelehealthCallbackModal"
            close={close}
            header={<Modal.Header title="" close={close}></Modal.Header>}
            footer={
              <Modal.FooterWithLargeButton>
                <Button
                  onClick={submitForm}
                  isDisabled={!isValid}
                  type="submit"
                  variant="primary"
                  fullWidth="percent"
                >
                  {t("done")}
                </Button>
              </Modal.FooterWithLargeButton>
            }
          >
            <FormContent>
              <Modal.Title>
                <Text variant="title1">{t("title")}</Text>
              </Modal.Title>
              <Modal.Description>
                <Text variant="body2" color="textSecondary">
                  {t("description")}
                </Text>
              </Modal.Description>
              <RadioGroupField
                css={[
                  layout.spacedChildrenVertical(),
                  layout.paddingVertical("standard"),
                  layout.margin("expanded", "none", "none", "none"),
                ]}
                name="callbackType"
              >
                <RadioGroupField.Option value={"Current"}>
                  {({ checked }) => (
                    <div css={[layout.flexCenterHorizontal, layout.spacedChildrenHorizontal()]}>
                      <Radio
                        label={
                          <RadioGroupField.Label>
                            {parsePhone(callbackNumber)}
                          </RadioGroupField.Label>
                        }
                        labelBefore={false}
                        checked={checked}
                        onChange={() => null}
                      />
                    </div>
                  )}
                </RadioGroupField.Option>
                <Divider />
                <RadioGroupField.Option value={"Different"}>
                  {({ checked }) => (
                    <div css={[layout.flexCenterHorizontal, layout.spacedChildrenHorizontal()]}>
                      <Radio
                        checked={checked}
                        labelBefore={false}
                        onChange={() => null}
                        aria-label={t("selectDifferentNumber")}
                      />
                      <InputWrapper htmlFor="newCallbackNumber" label={t("differentNumber")}>
                        <TextInput
                          name="newCallbackNumber"
                          hasError={!!errors.newCallbackNumber}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setFieldValue("newCallbackNumber", e.currentTarget.value);
                          }}
                        />
                      </InputWrapper>
                    </div>
                  )}
                </RadioGroupField.Option>
                <Divider />
                <RadioGroupField.Option value={"NoCallback"}>
                  {({ checked }) => (
                    <div css={[layout.flexCenterHorizontal, layout.spacedChildrenHorizontal()]}>
                      <Radio
                        label={<RadioGroupField.Label>{t("doNotCall")}</RadioGroupField.Label>}
                        labelBefore={false}
                        checked={checked}
                        onChange={() => null}
                      />
                    </div>
                  )}
                </RadioGroupField.Option>
              </RadioGroupField>
            </FormContent>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default TelehealthCallbackModal;
