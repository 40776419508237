import { useField } from "formik";
import React from "react";

import InputWrapper from "/component/base/InputWrapper";
import SegmentedControl from "/component/partial/SegmentedControl";

import { Props } from "./SegmentedControlField.types";

const SegmentedControlField = <T,>({
  className,
  options,
  label,
  inputWrapperVariant = "primary",
  name,
}: Props<T>) => {
  const [field, meta, helpers] = useField(name);

  return (
    <InputWrapper
      variant={inputWrapperVariant}
      className={className}
      error={meta.error}
      htmlFor={field.name}
      label={label}
      touched={meta.touched}
    >
      <SegmentedControl
        options={options}
        className={className}
        value={field.value}
        onItemSelected={(changedValue) => {
          helpers.setValue(changedValue);
        }}
      />
    </InputWrapper>
  );
};

export default SegmentedControlField;
