import { Form, Formik } from "formik";
import * as React from "react";
import * as Yup from "yup";

import { Button } from "/component/base";
import {
  Description,
  FormContent,
  Title,
} from "/component/form/DemographicFormWizard/DemographicFormWizard.styles";
import TextField from "/component/partial/formik/TextField";
import { useTranslation } from "/hook";

import { Props } from "./NameForm.types";

const NameForm = ({ handleSubmit, initialValues }: Props) => {
  const { t } = useTranslation("form-Demographics");
  const formNameSchema = Yup.object({
    firstName: Yup.string().required(t("error.firstName.required")),
    lastName: Yup.string().required(t("error.lastName.required")),
  });

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={initialValues}
      validationSchema={formNameSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, isValid }) => (
        <Form>
          <Title element="h1" variant="title1">
            {t("heading.nameStep")}
          </Title>

          <Description element="div" color="textSecondary">
            {t("description.nameStep")}
          </Description>

          <FormContent>
            <TextField label={t("label.firstName")} name="firstName" type="text" />

            <TextField label={t("label.lastName")} name="lastName" type="text" />
            <FormContent.ButtonGroup>
              <Button
                variant="primary"
                isDisabled={!isValid}
                type="submit"
                isLoading={isSubmitting}
              >
                {t("submitText.nameStep")}
              </Button>
            </FormContent.ButtonGroup>
          </FormContent>
        </Form>
      )}
    </Formik>
  );
};

export default NameForm;
