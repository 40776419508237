import { ApolloError, ApolloQueryResult, gql } from "@apollo/client";

import { useB2CQuery } from "../client";
import { GET_DEMOGRAPHICS } from "../schema/types";

export const demographicsQuery = gql`
  query GET_DEMOGRAPHICS {
    patient {
      demographics {
        address1
        address2
        city
        dateOfBirth
        firstName
        lastName
        state
        zipCode
      }
    }
  }
`;

const useDemographics = (): {
  error: ApolloError | undefined;
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<GET_DEMOGRAPHICS>>;
  data: GET_DEMOGRAPHICS | undefined;
} => {
  const { data, error, loading, refetch } = useB2CQuery<GET_DEMOGRAPHICS>(demographicsQuery);

  return {
    error,
    loading,
    refetch,
    data: data,
  };
};

export default useDemographics;
