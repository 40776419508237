import awsdk from "awsdk";

import { useAmwell, useAmwellQuery } from "/hook";

/**
 * This hook allows for the retrieval of information pertaining to consumers diagnosed conditions
 */

const useAmwellConditions = () => {
  const { consumer, sdk } = useAmwell();
  const fetch = async () =>
    sdk && consumer ? await sdk.consumerService.getConditions(consumer) : undefined;

  return useAmwellQuery<awsdk.AWSDKCondition[]>(fetch);
};

export default useAmwellConditions;
