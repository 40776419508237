import styled from "styled-components";

import Button from "/component/base/Button";
import { layout } from "/styles";

export const HoursOfOperationWrapper = styled.div(layout.flexCenterHorizontal);
export const HoursOfOperationWeeklyRowWrapper = styled.div(
  layout.flexSpaceHorizontal,
  ({ theme }) => ({
    width: "48%",
    [theme.media.max.tabletPortrait]: {
      width: "90%",
    },
    [theme.media.max.mobile]: {
      width: "100%",
    },
  }),
);

export const ChevronButton = styled(Button)(layout.flexCenter, () => ({
  marginLeft: 4,
}));
