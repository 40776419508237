const padWithZeros = (
  stringVal: string,
  length: number,
  position: "before" | "after" = "before",
) => {
  if (stringVal.length >= length) {
    return stringVal;
  } else {
    const zeros = Array.of(length - stringVal.length)
      .fill(0)
      .join("");
    if (position === "before") {
      return zeros + stringVal;
    } else {
      return stringVal + zeros;
    }
  }
};

export default padWithZeros;
