import { useMutation } from "@apollo/client";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { RouteComponentProps } from "react-router-dom";

import {
  CaregiverAccessDetails_viewer_user_caregiverAccess,
  CaregiverAccessDetails_viewer_user_caregivers,
  CaregiverStatus,
  ConfirmCaregiverAccess,
  ConfirmCaregiverAccessVariables,
  SendCaregiverReminder,
  SendCaregiverReminderVariables,
  UpdateCaregiverAccess,
  UpdateCaregiverAccessVariables,
} from "/apollo/schema";
import Loading from "/component/base/Loading";
import Text from "/component/base/Text";
import { CaregiverMetadata } from "/component/form/CaregiverForms/CaregiverForms.types";
import CaregiverWizard from "/component/form/CaregiverWizard";
import MainContent from "/component/layout/MainContent";
import CancelCaregiverInvitationModal from "/component/modal/CancelCaregiverInvitationModal";
import CancelCaregiverWizardModal from "/component/modal/CancelCaregiverWizardModal";
import CaregiverAccessConfirmationModal from "/component/modal/CaregiverAccessConfirmationModal";
import { useToastContext } from "/component/provider/ToastProvider";
import { CaregiverFormType } from "/constant/caregiver.constant";
import routes from "/constant/url.constant";
import { useTranslation } from "/hook";
import { layout } from "/styles";
import { reactPlugin } from "/util/appInsights.util";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  logEvent,
  ScreenViewParams,
} from "/util/firebase.util";

import CaregiverAccessList from "./CaregiverAccessList";
import CaregiverIntro from "./CaregiverIntro";
import confirmCaregiverAccessGql from "./confirmCaregiverAccess";
import sendCaregiverReminderGql from "./sendCaregiverReminder";
import updateCaregiverAccessGql from "./updateCaregiverAccess";
import { useCaregiverAccess } from "./useCaregiverAccess";

const CaregiverIndex = ({
  history,
  location,
}: RouteComponentProps<{ confirmAccessId?: string; initialFlow?: CaregiverFormType }>) => {
  const { t } = useTranslation("page-Caregiver");

  const {
    initialFlow,
    confirmAccessId,
    newlyCreatedAccount = false,
  } = (location.state as {
    confirmAccessId: string;
    newlyCreatedAccount: boolean;
    initialFlow?: CaregiverFormType;
  }) || {};
  const [currentForm, setCurrentForm] = useState<CaregiverFormType>(CaregiverFormType.AddCaregiver);
  const { data, loading, refetch } = useCaregiverAccess();
  const { showToast } = useToastContext();

  /*State Managements*/
  const [isEmptyState] = useState(true);
  const [showWizard, setShowWizard] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [metadata, setMetadata] = useState<CaregiverMetadata | undefined>(undefined);
  const [showCancelInvite, setShowCancelInvite] = useState<{
    id: string;
    pendingAccountCreation: boolean;
  } | null>(null);
  const [showInviteConfirmed, setShowInviteConfirmed] = useState<{
    name: string;
    caregiverAccessId: string;
  } | null>(null);

  /*Mutations*/
  const [sendCaregiverReminderMutation] = useMutation<
    SendCaregiverReminder,
    SendCaregiverReminderVariables
  >(sendCaregiverReminderGql);
  const [updateCaregiverAccessMutation] = useMutation<
    UpdateCaregiverAccess,
    UpdateCaregiverAccessVariables
  >(updateCaregiverAccessGql);
  const [confirmCaregiverAccessMutation] = useMutation<
    ConfirmCaregiverAccess,
    ConfirmCaregiverAccessVariables
  >(confirmCaregiverAccessGql);

  useEffect(() => {
    logScreenViewEvent(getFirebaseScreenSource());
  }, [currentForm, showWizard]);

  useEffect(() => {
    if (initialFlow) {
      setCurrentForm(initialFlow);
      setShowWizard(true);
    }
  }, [initialFlow]);

  const getFirebaseScreenSource = (): AnalyticsSource => {
    if (!showWizard) {
      return AnalyticsSource.CAREGIVER_ACCESS_LIST;
    } else {
      if (currentForm === CaregiverFormType.AddCaregiver) {
        return AnalyticsSource.CAREGIVER_ACCESS_TYPE;
      } else if (currentForm === CaregiverFormType.AddChild) {
        return AnalyticsSource.CAREGIVER_ACCESS_CHILD_FORM;
      } else if (currentForm === CaregiverFormType.InviteCaregiver) {
        return AnalyticsSource.CAREGIVER_ACCESS_INVITE;
      }
    }
    return AnalyticsSource.CAREGIVER_ACCESS_LIST;
  };

  const logScreenViewEvent = (screenName: string) => {
    logEvent(AnalyticsEvent.SCREEN_VIEW, {
      screenName: screenName,
    } as ScreenViewParams);
  };

  const logButtonClickEvent = (
    source: AnalyticsSource,
    buttonName: string,
    buttonDescription?: string,
  ) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.CAREGIVER,
      source: source,
      button_name: buttonName,
      button_description: buttonDescription,
    } as ButtonClickParams);
  };

  useEffect(() => {
    const confirmApprovedAccess = async (caregiverAccessId: string) => {
      const response = await confirmCaregiverAccessMutation({
        variables: {
          data: {
            isNewAccount: newlyCreatedAccount,
            caregiverAccessId,
          },
        },
      });
      if (response.data?.confirmInviteCaregiverAccess.status === CaregiverStatus.GRANTED) {
        logEvent(AnalyticsEvent.CAREGIVER_INVITE_ACCEPT);
        setShowInviteConfirmed({
          name: response.data.confirmInviteCaregiverAccess.name || "",
          caregiverAccessId,
        });
      } else if (
        response.data?.confirmInviteCaregiverAccess?.errors &&
        response.data?.confirmInviteCaregiverAccess?.errors.length > 0
      ) {
        const reponseCode = response.data.confirmInviteCaregiverAccess.errors[0].code;
        if (reponseCode === 404) {
          showErrorToast(t("toast.notFound"));
        } else if (reponseCode === 410) {
          showErrorToast(t("toast.expired"));
        } else {
          showErrorToast(t("generic"));
        }
      }
    };
    if (confirmAccessId && !loading) {
      confirmApprovedAccess(confirmAccessId);
    }
  }, [confirmAccessId, loading]);

  const initializeForm = () => {
    setCurrentForm(CaregiverFormType.AddCaregiver);
    setShowWizard(true);
  };

  const goToForms = (formName: CaregiverFormType) => {
    setCurrentForm(formName);
    setShowWizard(true);
  };

  const setForm = (newFormName: string) => {
    setCurrentForm(newFormName as CaregiverFormType);
  };
  const completeWizard = () => {
    refetch();
    setShowWizard(false);
    setCurrentForm(CaregiverFormType.AddCaregiver);
    setMetadata(undefined);
  };

  const cancelWizard = () => {
    setShowWizard(false);
    setShowCancelModal(false);
    setCurrentForm(CaregiverFormType.AddCaregiver);
    setMetadata(undefined);
  };

  const continuePending = (item: CaregiverAccessDetails_viewer_user_caregiverAccess) => {
    const phone = item?.contact?.phone;
    const email = item?.contact?.email;
    const name = `${item?.firstName} ${item?.lastName}`;
    const accessId = item.id;
    if (accessId) {
      setMetadata({
        verificationTelecom: {
          phone: phone || undefined,
          email: email || undefined,
          name,
          accessId,
        },
      });
      setCurrentForm(CaregiverFormType.ChildVerification);
      setShowWizard(true);
    }
  };

  const onClickCaregiverDetails = (
    caregiverDetails:
      | CaregiverAccessDetails_viewer_user_caregivers
      | CaregiverAccessDetails_viewer_user_caregiverAccess,
  ) => {
    history.push(routes.caregiverDetails, {
      data: caregiverDetails,
    });
  };

  const deletePendingCaregiver = async () => {
    if (!showCancelInvite) return;
    const response = await updateCaregiverAccessMutation({
      variables: {
        data: {
          caregiverAccessId: showCancelInvite.id,
          newStatus: CaregiverStatus.CANCELLED,
          isPendingAccount: showCancelInvite.pendingAccountCreation,
        },
      },
    });
    setShowCancelInvite(null);
    if (response.errors && response.errors.length > 0)
      showToast({
        message: t("error"),
        icon: "alert",
      });
    refetch();
  };

  const sendCaregiverReminder = async (
    caregiverAccessId: string,
    pendingAccountCreation: boolean,
  ) => {
    const resp = await sendCaregiverReminderMutation({
      variables: {
        data: {
          caregiverAccessId,
          isPendingAccount: pendingAccountCreation,
        },
      },
    });

    if (
      resp.data?.sendCaregiverAccessReminder?.errors &&
      resp.data?.sendCaregiverAccessReminder?.errors.length > 0
    ) {
      showToast({
        message: t("error"),
        icon: "alert",
      });
    } else
      showToast({
        message: t("reminderSent"),
        icon: "checkmark",
      });
  };

  const showErrorToast = (message: string) => {
    showToast({
      message: message,
      icon: "alert",
    });
  };

  const headerContent = (
    <div css={[layout.flexVertical, layout.spacedChildrenVertical("condensed")]}>
      <Text variant="title1">{t("title")}</Text>
      <Text variant="body1" color="textSecondary">
        {t("subtitle")}
      </Text>
    </div>
  );

  const caregiverAccess = data?.viewer?.user?.caregiverAccess || [];
  const caregivers = data?.viewer?.user?.caregivers || [];

  if (loading) return <Loading />;

  if (showWizard) {
    return (
      <MainContent css={layout.padding("none")}>
        <Helmet>
          <title>{t("title")}</title>
        </Helmet>

        <CaregiverWizard
          setForm={setForm}
          cancelWizard={() => {
            logButtonClickEvent(getFirebaseScreenSource(), "Close (icon)");
            setShowCancelModal(true);
          }}
          metadata={metadata}
          completeWizard={completeWizard}
          currentForm={currentForm}
        />

        <CancelCaregiverWizardModal
          isOpen={showCancelModal}
          onCancel={() => {
            logButtonClickEvent(getFirebaseScreenSource(), "No, I'll stay");
            setShowCancelModal(false);
          }}
          onConfirm={() => {
            logButtonClickEvent(getFirebaseScreenSource(), "Yes, exit flow");
            cancelWizard();
          }}
        />
      </MainContent>
    );
  }

  return (
    <MainContent parentPage="account" headerAccessory={isEmptyState ? "" : headerContent}>
      <Helmet>
        <title>{t("title")}</title>
      </Helmet>
      <CancelCaregiverInvitationModal
        isOpen={!!showCancelInvite}
        onCancel={() => setShowCancelInvite(null)}
        onConfirm={deletePendingCaregiver}
      />
      <CaregiverAccessConfirmationModal
        close={() => {
          setShowInviteConfirmed(null);
          history.replace(routes.caregiver);
          refetch();
        }}
        isOpen={!!showInviteConfirmed}
        name={showInviteConfirmed?.name || ""}
      />
      {caregivers.length > 0 || caregiverAccess.length > 0 ? (
        <CaregiverAccessList
          caregiverAccessList={caregiverAccess}
          caregivers={caregivers}
          deletePendingCaregiver={(caregiverAccessId: string, pendingAccountCreation: boolean) =>
            setShowCancelInvite({ id: caregiverAccessId, pendingAccountCreation })
          }
          sendCaregiverReminder={sendCaregiverReminder}
          goToForms={goToForms}
          caregiverClick={onClickCaregiverDetails}
          continuePending={continuePending}
        />
      ) : (
        <CaregiverIntro
          onClick={() => {
            logButtonClickEvent(AnalyticsSource.CAREGIVER_ACCESS_LIST, "Let's get started");
            initializeForm();
          }}
        />
      )}
    </MainContent>
  );
};

export default withAITracking(reactPlugin, CaregiverIndex, undefined, "ai-tracking");
