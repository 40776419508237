import styled from "styled-components";

import { ToastType } from "/component/base/Toast/Toast.types";
import { layout } from "/styles";

export const Base = styled.div<{ $type: ToastType }>(
  layout.flexCenter,
  layout.padding("standard"),
  ({ theme, $type }) => ({
    backgroundColor:
      $type === "error" ? theme.colors.brandSecondaryLight : theme.colors.brandPrimaryLight,
    borderRadius: 24,
    // Make the toast as wide as possible, but max out at 712
    width: "100%",
    maxWidth: 712,

    // Make sure the icon doesn't shrink
    "> svg": {
      flex: "0 0 auto",
    },
  }),
);
