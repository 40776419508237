import styled from "styled-components";

import { layout } from "/styles";

export const Link = styled.a<{ $height: number; $width: number }>(({ $height, $width }) => ({
  borderRadius: 16,
  display: "block",
  maxWidth: "100%",
  overflow: "hidden",
  paddingTop: `${($height / $width) * 100}%`,
  position: "relative",
}));

export const MapImage = styled.img(layout.absoluteCenter, { width: "100%" });

// Map Marker size is responsive — 7% of the map's width. Min and max widths
// have been added to keep it from appearing excessively small or large.
export const MarkerImage = styled.img(layout.absoluteCenter, {
  maxWidth: 88,
  minWidth: 48,
  transform: "translateY(-15%)",
  width: "7%",
});
