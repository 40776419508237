import React from "react";

import { ButtonGroup, FormContent as FormContentBase, InnerFields } from "./FormContent.styles";

const FormContent = ({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) => <FormContentBase className={className}>{children}</FormContentBase>;

FormContent.InnerFields = InnerFields;
FormContent.ButtonGroup = ButtonGroup;

export default FormContent;
