import styled from "styled-components";

import { card, layout } from "/styles";

export const Note = styled.div(layout.flexVertical, card.base, layout.padding(20), ({ theme }) => ({
  backgroundColor: theme.colors.brandPrimaryLight,
  gap: 12,
}));

export const Header = styled.div(layout.flexCenterHorizontal, () => ({
  gap: 12,
}));

export const Avatar = styled.div(layout.flexCenter);

export const HeaderContent = styled.div(layout.flexVertical, () => ({
  justifyContent: "center",
  gap: 2,
}));
