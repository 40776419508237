import { makeVar } from "@apollo/client";
import { omit } from "lodash";

import { DayOfWeek, Language, ProviderGender } from "/apollo/schema";
import {
  createReservation_createReservation_reservation as Reservation,
  ProviderAvailabilitiesListFragment_availabilities_pages as Availability,
  ProviderAvailabilitiesListFragment_availabilities_pages_timeSlots as TimeSlot,
} from "/apollo/schema/types";
import { AnalyticsUserFlow } from "/util/firebase.util";

export const miles = [1, 5, 10, 20, 50] as const;
export const defaultRadius = 50;

const SchedulingStorageKey = "scheduling";

type Booking = {
  reservation: Reservation;
  availability: Availability;
  timeSlot: TimeSlot;
};

export interface SchedulingState {
  analyticsFlow?: AnalyticsUserFlow;
  booking?: Booking;
  calendarIds?: string[];
  enableUrgentCare: boolean;
  flowSessionId?: string;
  isBookingComplete: boolean;
  isEstablishedPatient: boolean;
  querySelectedCalendarIds: boolean;
  reasonForVisit?: string;
  selectedCalendarIds?: string[];
  minStartDate: string | null;
  maxStartDate: string | null;
  filters: {
    minStartTime?: number | string | null;
    maxStartTime?: number | string | null;
    minStartDate?: string | null;
    daysOfWeek: DayOfWeek[];
    gender: ProviderGender | null;
    language: Language | null;
    radius: (typeof miles)[number];
  };
}

export const initialState: SchedulingState = {
  analyticsFlow: AnalyticsUserFlow.SCHEDULE_VISIT,
  booking: undefined,
  calendarIds: [],
  enableUrgentCare: false,
  flowSessionId: undefined,
  isBookingComplete: false,
  isEstablishedPatient: false,
  querySelectedCalendarIds: false,
  reasonForVisit: "",
  selectedCalendarIds: [],
  minStartDate: null,
  maxStartDate: null,
  filters: {
    minStartTime: null,
    maxStartTime: null,
    gender: null,
    radius: defaultRadius,
    daysOfWeek: [],
    language: null,
  },
};
const storedStateString = localStorage.getItem(SchedulingStorageKey);
const initialValue = storedStateString
  ? (JSON.parse(storedStateString) as SchedulingState)
  : initialState;

const reactiveVar = makeVar<SchedulingState>(initialValue);

export default {
  var: reactiveVar,
  read() {
    return reactiveVar();
  },
  update(value: Partial<SchedulingState>) {
    const currentVal = reactiveVar();
    const newValue = { ...currentVal, ...value };
    localStorage.setItem(SchedulingStorageKey, JSON.stringify(newValue));
    return reactiveVar(newValue);
  },
  updateFilters(value: Partial<SchedulingState["filters"]>) {
    const currentVal = reactiveVar();
    return this.update({
      filters: {
        ...currentVal.filters,
        ...value,
      },
    });
  },

  updateAnalytics(
    analyticsFlow:
      | AnalyticsUserFlow.SCHEDULE_VISIT
      | AnalyticsUserFlow.PROVIDER_SEARCH = AnalyticsUserFlow.SCHEDULE_VISIT,
  ) {
    return this.update({ analyticsFlow });
  },

  updateBooking(booking: Booking) {
    return this.update({ booking });
  },
  reset() {
    return this.update(omit(initialState, "analyticsFlow"));
  },
  resetFilters() {
    return this.update({ filters: initialState.filters });
  },
};
