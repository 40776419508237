import React, { useRef, useState } from "react";

import { Button, Text } from "/component/base";
import { Document, Link } from "/component/base/Icon";
import SelectedItemsList from "/component/base/SelectedItemsList";
import { useTranslation } from "/hook";
import { layout } from "/styles";

import { Props } from "./FileInput.types";

const FileInput: <
  T extends {
    name: string;
  },
>(
  props: Props<T>,
) => React.ReactElement = ({ className, disabled, file, onAdd, onRemove, ...otherProps }) => {
  const [inputKey, setInputKey] = useState("");
  const { t } = useTranslation("FileInput");
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOpenfileDialog = () => {
    inputRef.current?.click();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const addedFile = event.target.files?.[0];

    if (addedFile) {
      const fileReader = new FileReader();

      fileReader.onload = () => {
        if (fileReader.result) {
          onAdd?.(new Blob([fileReader.result], { type: addedFile.type }), {
            lastModified: addedFile.lastModified,
            name: addedFile.name,
            size: addedFile.size,
          });
        }
      };

      fileReader.readAsArrayBuffer(addedFile);
    }
  };

  const handlePressRemove = () => {
    if (file) {
      onRemove?.(file);
      // Set key for file input to a new string value. This disassociates
      // the next rendered file input from previous renders, treating it as a
      // newly mounted element. This resets its internally managed `files` state
      // attribute, which we're tracking via formik instead of the DOM.
      setInputKey(Date.now().toString());
    }
  };

  return (
    <>
      {file ? (
        <SelectedItemsList
          icon={<Document size={16} />}
          css={layout.padding("skip", "skip", "standard")}
          items={[{ label: file.name, value: file }]}
          onRemove={handlePressRemove}
        />
      ) : (
        <Button
          aria-label={t("button.attachFile")}
          className={className}
          isDisabled={disabled}
          onClick={handleOpenfileDialog}
          variant="tertiary"
        >
          <span aria-hidden css={[layout.flexCenterHorizontal]}>
            <Link
              aria-hidden
              color="brandPrimary"
              css={layout.margin("none", "condensed", "none", "none")}
              size={16}
            />

            <Text color="brandPrimary" variant="body1Bold">
              {t("button.attachFile")}
            </Text>
          </span>
        </Button>
      )}

      <input
        onChange={handleChange}
        {...otherProps}
        aria-hidden
        css={layout.visuallyHidden}
        disabled={disabled}
        key={inputKey}
        ref={inputRef}
        type="file"
      />
    </>
  );
};

export default FileInput;
