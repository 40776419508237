import React from "react";

import { Text } from "/component/base";
import { MapPin } from "/component/base/Icon";
import { layout } from "/styles";

import { Props } from "./PharmacyItem.types";

const PharmacyItem = ({ name, address }: Props) => {
  return (
    <div css={[layout.flexCenterHorizontal, layout.padding("standard", "skip")]}>
      <MapPin
        css={layout.flexItemIntrinsic}
        color="brandSecondary"
        background={{ color: "brandSecondaryLight" }}
        size={30}
      />

      <div
        css={[
          layout.flexVertical,
          layout.flexLTR,
          layout.padding("skip", "skip", "skip", "standard"),
        ]}
      >
        <Text css={{ textAlign: "start" }} variant="body1Bold">
          {name}
        </Text>
        <Text variant="body2" color="textSecondary">
          {address}
        </Text>
      </div>
    </div>
  );
};

export default PharmacyItem;
