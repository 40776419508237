import { useReactiveVar } from "@apollo/client";
import React, { useEffect, useState } from "react";

import diagnosticReportFilters from "/apollo/client/local/diagnosticReportFilters";
import { DiagnosticReportCategory, SortDirection } from "/apollo/schema";
import { InputWrapper } from "/component/base";
import Button from "/component/base/Button";
import CheckBox from "/component/base/CheckBox";
import DatePicker from "/component/base/DatePicker";
import Divider from "/component/base/Divider";
import Modal, { ModalProps } from "/component/base/Modal";
import Text from "/component/base/Text";
import SegmentedControl from "/component/partial/SegmentedControl";
import { useTranslation } from "/hook";
import { layout } from "/styles";
import { formatDate } from "/util/date";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  FilterParams,
  logEvent,
  ScreenViewParams,
} from "/util/firebase.util";

import { DateRangeWrapper } from "./DiagnosticReportsFilterModal.styles";

const DiagnosticReportsFilterModal = ({ isOpen, close }: ModalProps) => {
  const { t } = useTranslation("results");
  const filters = useReactiveVar(diagnosticReportFilters.var);
  // Copy values to state so we can defer re-fetching until the user confirms
  // their choices. We could update the reactiveVar directly, but it would
  // generate many unnecessary requests.
  const [filtersState, setFiltersState] = useState(filters);
  const radChecked = filtersState.categories?.includes(DiagnosticReportCategory.RAD);
  const labChecked = filtersState.categories?.includes(DiagnosticReportCategory.LAB);

  useEffect(() => {
    if (isOpen) {
      logEvent(AnalyticsEvent.SCREEN_VIEW, {
        screenName: AnalyticsSource.RESULT_FILTER,
      } as ScreenViewParams);
    }
  }, [isOpen]);

  const handleFilter = () => {
    logEvent(AnalyticsEvent.FILTER, {
      user_flow: AnalyticsUserFlow.RESULTS,
      source: AnalyticsSource.RESULT_FILTER,
      start_date: formatDate(filtersState.startDate, "MM/dd/yyyy"),
      end_date: formatDate(filtersState.endDate, "MM/dd/yyyy"),
      sort_by: filtersState.sort,
      types: filtersState.categories?.join(",") || null,
      filter_type: "result",
    } as FilterParams);

    close?.();

    const endDate = filtersState.endDate ? new Date(filtersState.endDate) : undefined;
    if (endDate) endDate.setDate(endDate.getDate() + 1);

    diagnosticReportFilters.update({
      ...filtersState,
      endDate,
    });
  };

  const handleClear = () => {
    close?.();
    diagnosticReportFilters.reset();
  };

  const handleClose = () => {
    close?.();
    // Reset local state to existing reactiveVar values, if the user decides not
    // to apply their changes.
    setFiltersState(filters);
  };

  return (
    <Modal
      close={handleClose}
      footer={
        <Modal.Footer>
          <Button variant="borderBottom" onClick={handleClear}>
            {t("button.clear")}
          </Button>

          <Button onClick={handleFilter}>{t("button.filter")}</Button>
        </Modal.Footer>
      }
      header={<Modal.Header title={t("headline.filter")} close={handleClose} />}
      isOpen={isOpen}
    >
      <Text css={layout.padding("skip", "skip", "standard")} element="h4" variant="title3">
        {t("title.sortBy")}
      </Text>

      <SegmentedControl
        onItemSelected={(sort) => {
          if (sort) {
            setFiltersState({ ...filtersState, sort });
          }
        }}
        options={[
          { label: t("label.newest", "Newest first"), value: SortDirection.DESC },
          { label: t("label.oldest", "Oldest first"), value: SortDirection.ASC },
        ]}
        title={t("title.sortBy")}
        value={filtersState.sort}
      />

      <Divider css={layout.margin("expanded", "skip")} />

      <Text css={layout.padding("skip", "skip", "standard")} element="h4" variant="title3">
        {t("title.dateRange")}
      </Text>

      <DateRangeWrapper>
        <InputWrapper label={t("label.from")} variant="bold-label">
          <DatePicker
            maxDate={filtersState.endDate}
            onChange={(startDate) => setFiltersState({ ...filtersState, startDate })}
            placeholder={t("placeholder.selectDate")}
            value={filtersState.startDate}
          />
        </InputWrapper>
        <div css={layout.margin("none", "none", "none", "standard")}></div>
        <InputWrapper label={t("label.to")} variant="bold-label">
          <DatePicker
            minDate={filtersState.startDate}
            onChange={(endDate) => setFiltersState({ ...filtersState, endDate })}
            placeholder={t("placeholder.selectDate")}
            value={filtersState.endDate}
          />
        </InputWrapper>
      </DateRangeWrapper>

      <Divider css={layout.margin("expanded", "skip")} />

      <Text css={layout.padding("skip", "skip", "expanded")} element="h4" variant="title3">
        {t("title.type")}
      </Text>

      <div css={layout.spacedChildrenVertical("expanded")}>
        <CheckBox
          checked={labChecked}
          label={<Text variant="body1Bold">{t("label.lab")}</Text>}
          onChange={() => {
            const updatedCategories = [
              labChecked ? undefined : DiagnosticReportCategory.LAB,
              radChecked ? DiagnosticReportCategory.RAD : undefined,
            ].filter((category) => !!category) as DiagnosticReportCategory[];

            setFiltersState({ ...filtersState, categories: updatedCategories });
          }}
        />

        <CheckBox
          checked={radChecked}
          label={<Text variant="body1Bold">{t("label.imaging")}</Text>}
          onChange={() => {
            const updatedCategories = [
              labChecked ? DiagnosticReportCategory.LAB : undefined,
              radChecked ? undefined : DiagnosticReportCategory.RAD,
            ].filter((category) => !!category) as DiagnosticReportCategory[];

            setFiltersState({ ...filtersState, categories: updatedCategories });
          }}
        />
      </div>
    </Modal>
  );
};

export default DiagnosticReportsFilterModal;
