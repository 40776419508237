import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router-dom";

import noResultsFound from "/asset/image/noResultsFound.png";
import { ErrorCode, getErrorCode } from "/b2c/client/utils";
import useTestResults from "/b2c/query/useTestResults.b2c";
import { List } from "/component/base";
import { AlertCircleOpen } from "/component/base/Icon";
import MainContent from "/component/layout/MainContent";
import EmptyStateCta from "/component/partial/EmptyStateCta";
import routes from "/constant/url.constant";
import { useTranslation } from "/hook";
import { layout } from "/styles";
import { reactPlugin } from "/util/appInsights.util";

import { TestResult } from "../../../../../b2c/query/useTestResults.b2c";
import LoadingSkeleton from "../../../../partial/LoadingSkeleton";
import ResultListItem from "./components/ResultListItem";
import { DocsWrapper, ListContainer } from "./ResultsB2C.styles";

const IMAGE_HEIGHT = 261;
const IMAGE_WIDTH = 183;

const Results = () => {
  const { t } = useTranslation("results");
  const history = useHistory();
  const { data, error, loading } = useTestResults();

  const handlePressResult = (testResult: TestResult) => {
    history.push(`${routes.results}/${testResult.id}`, { testResult });
  };

  const showEmptyState = (data?.length || 0) <= 0;

  if (loading)
    return (
      <MainContent pageTitle={t("pageTitle")} parentPage="myHealth">
        <LoadingSkeleton skeletonVariant="list-item" />
      </MainContent>
    );

  if (error && getErrorCode(error, data) !== ErrorCode.PARTIAL_RESPONSE) {
    return (
      <MainContent pageTitle={t("pageTitle")} parentPage="myHealth">
        <Helmet>
          <title>{t("pageTitle")}</title>
        </Helmet>
        <div css={layout.flexVerticalCenter}>
          <div css={layout.marginVertical(80)}>
            <EmptyStateCta
              icon={<AlertCircleOpen color="brandPrimary" size={48} />}
              title={t("error.fetchError.title")}
              subtitle={t("error.fetchError.subtitle")}
            />
          </div>
        </div>
      </MainContent>
    );
  }

  if (showEmptyState) {
    return (
      <MainContent pageTitle={t("pageTitle")} parentPage="myHealth">
        <Helmet>
          <title>{t("pageTitle")}</title>
        </Helmet>
        <div css={layout.flexVerticalCenter}>
          <div css={layout.marginVertical(60)}>
            <EmptyStateCta
              imageHeight={IMAGE_HEIGHT}
              imageWidth={IMAGE_WIDTH}
              imageSource={noResultsFound}
              title={t("emptyState.title")}
              subtitle={t("emptyState.subtitle")}
            />
          </div>
        </div>
      </MainContent>
    );
  }
  return (
    <MainContent pageTitle={t("pageTitle")} parentPage="myHealth">
      <Helmet>
        <title>{t("pageTitle")}</title>
      </Helmet>

      <DocsWrapper>
        <ListContainer>
          <List
            as="Buttons"
            chevronVariant="grey"
            hideLastChildBorder
            onClick={(result) => handlePressResult(result)}
            data={data || []}
            renderItem={(result: TestResult) => <ResultListItem result={result} />}
          />
        </ListContainer>
      </DocsWrapper>
    </MainContent>
  );
};

export default withAITracking(reactPlugin, Results, undefined, "ai-tracking");
