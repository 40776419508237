import React, { useEffect, useState } from "react";

import Combobox from "/component/base/Combobox";
import { MapPin } from "/component/base/Icon";
import { useGoogleMaps, usePlaceAutocomplete, usePlaceDetails } from "/hook";

import { Props } from "./AddressCombobox.types";

const AddressCombobox = ({
  onSelectAddress,
  onChangeText: onChangeTextProp,
  formattedAddress,
  hasError,
  showIcon = true,
  ...otherProps
}: Props) => {
  const [input, setInput] = useState(formattedAddress || "");
  const [selectedPlaceId, setSelectedPlaceId] = useState<string>();
  // Google places allows us to use sessions, which relate Autocomplete calls to PlaceDetails lookups.
  // It saves money by using a session, because you only get charged once (for the PlaceDetails lookup),
  // instead of being charged for each individual autocomplete call.
  const [sessionToken, setSessionToken] = useState<google.maps.places.AutocompleteSessionToken>();

  const googleMaps = useGoogleMaps();
  const { data: predictions } = usePlaceAutocomplete({ input, sessionToken });
  const { data: placeDetails } = usePlaceDetails({ placeId: selectedPlaceId, sessionToken });

  useEffect(() => {
    // When the placeDetails lookup returns a details let the parent know
    if (placeDetails) {
      onSelectAddress?.(placeDetails);
    }

    // Each time our placeDetails changes we need to get a new session token. Each session
    // is good for **one or more** autocomplete calls and **ONE** placeDetails lookup.
    if (googleMaps) {
      setSessionToken(new googleMaps.places.AutocompleteSessionToken());
    }
  }, [placeDetails]);

  useEffect(() => {
    // Once we have our googleMaps instance, generate a sessionToken
    if (googleMaps && !sessionToken) {
      setSessionToken(new googleMaps.places.AutocompleteSessionToken());
    }
  }, [googleMaps, sessionToken]);

  useEffect(() => {
    if (formattedAddress) {
      setInput(formattedAddress);
    }
  }, [formattedAddress]);

  const filteredPredictions = predictions?.filter(({ place_id: placeId }) => !!placeId);

  const handleSelectItem = (item?: google.maps.places.QueryAutocompletePrediction | null) => {
    if (onSelectAddress && item?.place_id) {
      setSelectedPlaceId(item?.place_id);
    }
  };

  const handleChange = (changedText?: string) => {
    setInput(changedText || "");
    if (onChangeTextProp) {
      onChangeTextProp(changedText || "");
    }
  };

  return (
    <Combobox
      {...otherProps}
      before={showIcon ? <MapPin /> : null}
      itemToString={(item) => item?.description || ""}
      items={filteredPredictions}
      onChangeSelectedItem={handleSelectItem}
      onChangeText={handleChange}
      inputValue={input}
      hasError={hasError}
    />
  );
};

export default AddressCombobox;
