import { gql, useQuery } from "@apollo/client";

import { FindCreateNotificationPreferences } from "/apollo/schema";

export const NotificationPreferenceOptionFragment = gql`
  fragment NotificationPreferenceOptionFragment on NotificationPreferenceOption {
    emailNotification
    phoneCall
    smsNotification
  }
`;

const FIND_CREATE_NOTIFICATION_PREFERENCES = gql`
  query FindCreateNotificationPreferences {
    viewer {
      user {
        billingAccounts {
          entity
          patientMrn
          settings {
            email
            paperMail
            phoneCall
            phoneText
            ringlessCall
          }
        }
        notificationPreference {
          appointmentReminders {
            ...NotificationPreferenceOptionFragment
          }
          patientInformation {
            ...NotificationPreferenceOptionFragment
          }
          prescriptions {
            emailNotification
            smsNotification
          }
          dealsAndPromotions {
            emailNotification
            smsNotification
          }
          caregivers {
            appointmentReminders
            patientInformation
            prescriptions
            dealsAndPromotions
          }
        }
      }
    }
  }

  ${NotificationPreferenceOptionFragment}
`;

export const useNotifications = () =>
  useQuery<FindCreateNotificationPreferences>(FIND_CREATE_NOTIFICATION_PREFERENCES, {
    fetchPolicy: "network-only",
  });
