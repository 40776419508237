import { Datadog } from "@village/tools";

const { datadogLogs, initLogs } = Datadog.setupDatadogLogs();
const { datadogRum, initRum } = Datadog.setupDatadogRum();

const initDatadog = () => {
  const service = "scmd-web";

  initLogs({
    clientToken: process.env.DATADOG_LOG_CLIENT_TOKEN as string,
    service: service,
    env: process.env.APP_ENV,
    version: process.env.GITHUB_COMMIT_HASH,
    silentMultipleInit: true,
  });

  initRum({
    applicationId: process.env.DATADOG_RUM_APPLICATION_ID as string,
    clientToken: process.env.DATADOG_RUM_CLIENT_TOKEN as string,
    silentMultipleInit: true,
    service: service,
    env: process.env.APP_ENV,
    version: process.env.GITHUB_COMMIT_HASH,
    sessionReplaySampleRate: 0,
    sessionSampleRate: 20,
    telemetrySampleRate: 0,
    allowedTracingUrls: [process.env.API_URL as string, process.env.SERVICE_LAYER as string],
    trackResources: true,
    enableSessionRecording: false,
  });
};

export { initDatadog, datadogLogs, datadogRum };
