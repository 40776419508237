import { Form, Formik } from "formik";
import * as React from "react";
import * as Yup from "yup";

import { AdministrativeGender } from "/apollo/schema";
import { Button, Text } from "/component/base";
import {
  Description,
  FormContent,
  Title,
} from "/component/form/DemographicFormWizard/DemographicFormWizard.styles";
import RadioGroupField from "/component/partial/formik/RadioGroupField";
import { useTranslation } from "/hook";

import { CheckBox } from "./SexForm.styles";
import { Props } from "./SexForm.types";

const SexForm = ({ handleSubmit, initialValues }: Props) => {
  const { t } = useTranslation("form-Demographics");
  const formSexSchema = Yup.object().shape({
    sex: Yup.string().required(t("error.sex.required")),
  });

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={initialValues}
      validationSchema={formSexSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, isValid }) => (
        <Form>
          <Title element="h1" variant="title1">
            {t("heading.sexStep")}
          </Title>

          <Description element="div" color="textSecondary">
            {t("description.sexStep")}
          </Description>
          <FormContent>
            <RadioGroupField name="sex">
              <FormContent>
                <RadioGroupField.Option value={AdministrativeGender.MALE}>
                  {({ checked, active }) => (
                    <CheckBox
                      label={
                        <RadioGroupField.Label>
                          <Text variant={checked ? "body1Bold" : "body1"}>{t("label.male")}</Text>
                        </RadioGroupField.Label>
                      }
                      hasFocus={active}
                      checked={checked}
                      onChange={() => null}
                    />
                  )}
                </RadioGroupField.Option>
                <RadioGroupField.Option value={AdministrativeGender.FEMALE}>
                  {({ checked, active }) => (
                    <CheckBox
                      label={
                        <RadioGroupField.Label>
                          <Text variant={checked ? "body1Bold" : "body1"}>{t("label.female")}</Text>
                        </RadioGroupField.Label>
                      }
                      hasFocus={active}
                      checked={checked}
                      onChange={() => null}
                    />
                  )}
                </RadioGroupField.Option>
              </FormContent>
            </RadioGroupField>
            <FormContent.ButtonGroup>
              <Button
                variant="primary"
                type="submit"
                isDisabled={!isValid}
                isLoading={isSubmitting}
              >
                {t("submitText.sexStep")}
              </Button>
            </FormContent.ButtonGroup>
          </FormContent>
        </Form>
      )}
    </Formik>
  );
};

export default SexForm;
