/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ContinueDecisionSupport_continueDecisionSupport_flowResponse_elementSet_elements_selectOptions as DSSelectOption } from "/apollo/schema";
import ComboboxField from "/component/partial/formik/ComboboxField";

import { PickerField } from "./SelectionField.styles";
import { Props } from "./SelectionField.types";

const SelectionField = ({ variant = "primary", element, placeholder, isHTML }: Props) => {
  const { t } = useTranslation("selectionField");
  const [inputValue, setInputValue] = useState("");
  const showCombobox = element.selectOptions!.length >= 15;
  const filteredPredictions = inputValue
    ? element.selectOptions!.filter(({ label }) =>
        label?.toLowerCase().includes(inputValue?.toLowerCase()),
      )
    : element.selectOptions!;

  const handleSelectItem = (option?: DSSelectOption | null) => {
    setInputValue(option?.label || "");
  };

  const handleChangeText = (changedText?: string) => {
    setInputValue(changedText || "");
  };

  const getFormikValueForItem = (item?: DSSelectOption | null) => item?.value;
  const getStringForItem = (item?: DSSelectOption | null) => item?.label || "";
  const getItemForInputValue = (changedText?: string) =>
    element.selectOptions!.find((item) => item?.label === changedText);
  return showCombobox ? (
    <ComboboxField
      variant={variant}
      getItemForInputValue={getItemForInputValue}
      inputValue={inputValue}
      inputWrapperVariant="expanded-label"
      itemToFormikValue={getFormikValueForItem}
      itemToString={getStringForItem}
      items={filteredPredictions}
      isHTML={isHTML}
      label={element.questionText!}
      name={element.fieldName!}
      onChangeSelectedItem={handleSelectItem}
      onChangeText={handleChangeText}
      onFocus={(_, { openMenu, isOpen }) => {
        if (!isOpen) {
          openMenu();
        }
      }}
      placeholder={placeholder || t("placeholder", "Select an option")}
    />
  ) : (
    <PickerField
      variant={variant}
      isHTML={isHTML}
      label={element.questionText!}
      name={element.fieldName!}
      options={element.selectOptions!}
      inputWrapperVariant="expanded-label"
      placeholder={placeholder}
    />
  );
};

export default SelectionField;
