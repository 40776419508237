import { address } from "/apollo/client/local";
import i18n from "/i18n";

const getAddressComponent = (
  addressComponents: google.maps.GeocoderAddressComponent[],
  firstType: string,
): google.maps.GeocoderAddressComponent | undefined => {
  return addressComponents.find((c) => c.types[0] === firstType);
};

/**
 * Given an address string returns a geocoded place
 */
export const getPlaceByAddress = async (
  googleMaps: typeof google.maps,
  address: string,
): Promise<google.maps.GeocoderResult | undefined> => {
  const geoCoderService = new googleMaps.Geocoder();
  return new Promise((resolve) => {
    geoCoderService.geocode({ address }, (prediction: google.maps.GeocoderResult[] | null) => {
      if (prediction && prediction.length > 0) {
        resolve(prediction[0]);
      } else {
        resolve(undefined);
      }
    });
  });
};

/**
 * Given the google maps object and a call back object to predict
 * the users location depending on the users coordinates via the browser
 */
export const getAddressCurrentLocation = (
  googleMaps: typeof google.maps,
): Promise<google.maps.GeocoderResult | undefined> => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const autocompleteService = new googleMaps.Geocoder();
        autocompleteService.geocode(
          {
            location: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          },
          (prediction: google.maps.GeocoderResult[] | null) => {
            if (prediction && prediction.length > 0) {
              resolve(prediction[0]);
            } else {
              resolve(undefined);
            }
          },
        );
      },
      (err) => {
        let friendlyMessage;
        switch (err.code) {
          case err.PERMISSION_DENIED:
            friendlyMessage = i18n.t("geolocation-error.permissionDenied");
            break;
          case err.POSITION_UNAVAILABLE:
            friendlyMessage = i18n.t("geolocation-error.positionUnavailable");
            break;
          case err.TIMEOUT:
            friendlyMessage = i18n.t("geolocation-error.timeout");
            break;
        }

        reject({ err, friendlyMessage });
      },
      {
        timeout: 30000,
      },
    );
  });
};

/**
 * Given an array of AddressComponents from the Google Places API, returns a
 * completed address object in the shape used throughout address forms in this
 * application.
 */
export const extractAddress = (
  addressComponents: google.maps.GeocoderAddressComponent[],
): { line1?: string; city?: string; state?: string; postalCode?: string } => {
  const streetNumber = getAddressComponent(addressComponents, "street_number")?.short_name;
  const streetName = getAddressComponent(addressComponents, "route")?.short_name;

  const line1 = streetNumber && streetName ? `${streetNumber} ${streetName}` : undefined;
  const city =
    getAddressComponent(addressComponents, "sublocality_level_1")?.short_name ||
    getAddressComponent(addressComponents, "locality")?.short_name ||
    getAddressComponent(addressComponents, "administrative_area_level_1")?.long_name;
  const state = getAddressComponent(addressComponents, "administrative_area_level_1")?.short_name;
  const postalCode = getAddressComponent(addressComponents, "postal_code")?.short_name;

  return {
    line1,
    city,
    state,
    postalCode,
  };
};

/**
 * Given a GooglePlaces result update the `address` reactive var in the local apollo cache
 */
export function updateAddress(
  extractedAddress: { line1?: string; city?: string; state?: string; postalCode?: string },
  placeDetails: google.maps.places.PlaceResult,
) {
  address.update({
    ...extractedAddress,
    formattedAddress: placeDetails?.formatted_address,
    placeId: placeDetails?.place_id,
    lat: placeDetails?.geometry?.location?.lat(),
    lng: placeDetails?.geometry?.location?.lng(),
  });
}
