import React from "react";

import { StarFilled } from "/component/base/Icon";

import { Base, RatingText } from "./RatingWidget.styles";
import { Props } from "./RatingWidget.types";

const RatingWidget = ({ className, rating, size = 14, text }: Props) => {
  return (
    <Base className={className}>
      <StarFilled size={size} color="nrcStar" />
      <RatingText>
        {rating.toFixed(1)} {text && text}
      </RatingText>
    </Base>
  );
};

export default RatingWidget;
