import { withAITracking } from "@microsoft/applicationinsights-react-js";
import awsdk from "awsdk";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { RouteComponentProps } from "react-router-dom";

import PrevisitFormWizard from "/component/form/PrevisitFormWizard";
import MainContent from "/component/layout/MainContent";
import BackButtonBase from "/component/partial/BackButton";
import { PrevisitForms } from "/constant/telehealth.constant";
import routes from "/constant/url.constant";
import { useTranslation } from "/hook";
import { reactPlugin } from "/util/appInsights.util";
import { AnalyticsEvent, logEvent, ScreenViewParams } from "/util/firebase.util";

interface TelehealthPrevisitState {
  provider?: awsdk.AWSDKProvider | awsdk.AWSDKOnDemandSpecialty;
  form?: PrevisitForms;
}

const TelehealthPrevisit = ({ history, location }: RouteComponentProps) => {
  const { t } = useTranslation("telehealth-previsit");

  const previsitState = location.state as TelehealthPrevisitState;
  const provider = previsitState ? previsitState.provider : undefined;
  const [currentForm, setCurrentForm] = React.useState<PrevisitForms>(
    PrevisitForms.REASON_FOR_VISIT,
  );
  const [priorForms, setPriorForms] = React.useState<PrevisitForms[]>([]);

  useEffect(() => {
    logEvent(AnalyticsEvent.SCREEN_VIEW, {
      screenName: currentForm,
    } as ScreenViewParams);
  }, [currentForm]);

  useEffect(() => {
    if (!provider?.id) {
      backToTelehealthIndex();
    }
  }, [provider]);

  const backToTelehealthIndex = () => {
    history.replace(routes.telehealth);
  };

  const handleBack = () => {
    const lastForm = priorForms.pop();
    if (lastForm) setCurrentForm(lastForm);
    else backToTelehealthIndex();
  };

  const setForm = (newFormName: string) => {
    setPriorForms([...priorForms, currentForm]);
    setCurrentForm(newFormName as PrevisitForms);
  };

  const completeWizard = () => {
    history.push(routes.telehealthWaitingRoom);
  };

  return (
    <MainContent
      backButton={<BackButtonBase onClick={handleBack} icon="back" />}
      parentPage={"telehealth"}
    >
      <Helmet>
        <title>{t("title")}</title>
      </Helmet>
      <PrevisitFormWizard
        currentForm={currentForm}
        setForm={setForm}
        completeWizard={completeWizard}
        handleBack={handleBack}
      />
    </MainContent>
  );
};

export default withAITracking(reactPlugin, TelehealthPrevisit, undefined, "ai-tracking");
