// TODO this file is no longer used - delete?
import { useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";

import createPatientGql from "/apollo/mutation/createPatient";
import {
  AdministrativeGender,
  createPatient,
  createPatientVariables,
  StateCode,
} from "/apollo/schema/types";
import { Button, ListItem, Text } from "/component/base";
import AddressForm from "/component/form/AddressForm";
import { useAccountQuery } from "/component/page/Account/page/Settings/useSettings";
import AddressCombobox from "/component/partial/AddressCombobox";
import PickerField from "/component/partial/formik/PickerField";
import { SignUpFormType } from "/constant/signUp.constant";
import { useTranslation } from "/hook";
import { layout } from "/styles";
import { googlePlaces } from "/util";

import { FormContentContainer, SubTitle, Title } from "../SignUpForms.styles";
import { SignUpFormProps } from "../SignUpForms.types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PatientIdentity = ({ handleSubmit }: SignUpFormProps<any>) => {
  const { t } = useTranslation("form-signUp");
  const { data } = useAccountQuery();

  const [selectedAddress, setSelectedAddress] = useState<{
    line1: string;
    line2: string;
    city: string;
    state: string;
    postalCode: string;
  }>({
    line1: "",
    line2: "",
    city: "",
    state: "",
    postalCode: "",
  });
  const [addressManual, setAddressManual] = useState(false);

  const [createPatientMutation] = useMutation<createPatient, createPatientVariables>(
    createPatientGql,
  );

  const phoneNumberSchema = Yup.object({
    legalSex: Yup.string().required("Required"),
    selectedAddress: Yup.object().shape({
      line1: Yup.string().required("Required"),
      line2: Yup.string(),
      city: Yup.string().required("Required"),
      state: Yup.string().required("Required"),
      postalCode: Yup.string().required("Required"),
    }),
  });

  const showManualForm = () => {
    setAddressManual(true);
  };

  const submitForm = async (values: {
    legalSex: string;
    selectedAddress: {
      line1: string;
      line2: string;
      state: string;
      city: string;
      postalCode: string;
    };
  }) => {
    if (data?.viewer?.user?.account && values.selectedAddress && values.legalSex) {
      const { data: mutationData } = await createPatientMutation({
        variables: {
          data: {
            firstName: data.viewer.user.account.firstName as string,
            lastName: data.viewer.user.account.lastName as string,
            gender: values.legalSex as AdministrativeGender,
            address: {
              line1: values.selectedAddress.line1,
              line2: values.selectedAddress.line2,
              state: values.selectedAddress.state as StateCode,
              city: values.selectedAddress.city,
              postalCode: values.selectedAddress.postalCode,
            },
          },
        },
      });

      if (mutationData?.createPatient) {
        const errors = mutationData.createPatient.errors;
        if (!errors || (errors && errors.length == 0)) {
          handleSubmit(SignUpFormType.VerificationStatus);
        }
      }
    }
  };

  const addressFormSchema = Yup.object().shape({
    address: Yup.object().shape({
      line1: Yup.string()
        .required(t("error.required"))
        .matches(/^((?![*&%$#@?]).)*$/, t("error.address.invalidChar")),
      line2: Yup.string(),
      city: Yup.string()
        .required(t("error.required"))
        .matches(/^((?![*&%$#@?]).)*$/, t("error.address.invalidChar")),
      state: Yup.string().required(t("error.required")),
      postalCode: Yup.string()
        .required(t("error.required"))
        .matches(/^\d{5}(-\d{4})?$/, t("error.address.postalCode.format")),
    }),
  });

  if (addressManual) {
    return (
      <Formik
        validateOnMount
        initialValues={{
          address: selectedAddress,
        }}
        validationSchema={addressFormSchema}
        onSubmit={(values) => {
          setSelectedAddress(values.address);
          setAddressManual(false);
        }}
      >
        {({ isValid, isSubmitting }) => (
          <FormContentContainer>
            <Form>
              <Title>{t("patientIdentity.title")}</Title>
              <SubTitle>{t("patientIdentity.subtitle")}</SubTitle>
              <AddressForm />

              <FormContentContainer.ButtonGroup>
                <Button
                  variant="primary"
                  isDisabled={!isValid}
                  type="submit"
                  isLoading={isSubmitting}
                >
                  {t("continue")}
                </Button>
              </FormContentContainer.ButtonGroup>
            </Form>
          </FormContentContainer>
        )}
      </Formik>
    );
  }

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={{
        legalSex: "",
        selectedAddress: {
          line1: "",
          line2: "",
          city: "",
          state: "",
          postalCode: "",
        },
      }}
      validationSchema={phoneNumberSchema}
      onSubmit={submitForm}
    >
      {({ values, isSubmitting, isValid, setFieldValue }) => (
        <FormContentContainer>
          <Form>
            <Title>{t("patientIdentity.title")}</Title>
            <SubTitle>{t("patientIdentity.subtitle")}</SubTitle>
            <div css={[layout.spacedChildrenVertical("standard")]}>
              <PickerField
                css={layout.flexVertical}
                label={t("patientIdentity.legalSex")}
                placeholder={t("patientIdentity.legalSexPlaceholder")}
                name="legalSex"
                options={[
                  {
                    label: "Male",
                    value: "MALE",
                  },
                  {
                    label: "Female",
                    value: "FEMALE",
                  },
                ]}
              />
              <AddressCombobox
                formattedAddress={values.selectedAddress.line1 || ""}
                placeholder={t("modal.addressPlaceholder")}
                onSelectAddress={(googleAddress) => {
                  const { line1, city, state, postalCode } = googlePlaces.extractAddress(
                    googleAddress?.address_components || [],
                  );

                  if (line1 && city && state && postalCode) {
                    setFieldValue("selectedAddress", {
                      line1,
                      line2: "",
                      city,
                      state,
                      postalCode,
                    });
                  }
                }}
              />
              <ListItem.Button
                css={layout.padding("standard", "skip")}
                showBorder={true}
                showChevron={true}
                onClick={showManualForm}
              >
                <Text>{t("patientIdentity.manualAddress")}</Text>
              </ListItem.Button>
            </div>
            <FormContentContainer.ButtonGroup>
              <Button
                variant="primary"
                isDisabled={!isValid}
                type="submit"
                isLoading={isSubmitting}
              >
                {t("continue")}
              </Button>
            </FormContentContainer.ButtonGroup>
          </Form>
        </FormContentContainer>
      )}
    </Formik>
  );
};

export default PatientIdentity;
