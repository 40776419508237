import React, { forwardRef, useState } from "react";
import { ReactInputMask } from "react-input-mask";

import { Input as InputMask, Wrapper } from "./MaskedInput.styles";
import { Props } from "./MaskedInput.types";

export const MaskedInput = forwardRef<ReactInputMask, Props<string>>(
  ({ after, before, className, hasError, onBlur, onFocus, maskChar, mask, ...rest }, ref) => {
    const [focused, setFocused] = useState(false);

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      setFocused(false);
      onBlur && onBlur(e);
    };

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      setFocused(true);
      onFocus && onFocus(e);
    };

    return (
      <Wrapper hasFocus={focused} hasError={hasError} className={className}>
        {before}
        <InputMask
          maskChar={maskChar || null}
          mask={mask}
          type="text"
          {...rest}
          onFocus={handleFocus}
          onBlur={handleBlur}
          ref={ref}
        />
        {after}
      </Wrapper>
    );
  },
);

export default MaskedInput;
