import styled from "styled-components";

import { layout } from "/styles";

export const Pill = styled.div(
  layout.padding("standard"),
  layout.margin("condensed", "skip", "expanded", "skip"),
  ({ theme }) => ({
    backgroundColor: theme.colors.backgroundSecondary,
    borderRadius: 24,
  }),
);
