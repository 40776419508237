import styled from "styled-components";

import ctaCardBg from "/asset/image/ctaCardBackground.svg";
import Text from "/component/base/Text";
import { card, layout } from "/styles";

export const HeaderContainer = styled.div(layout.flexSpaceHorizontal, ({ theme }) => ({
  marginTop: theme.spacing.expanded,
}));

export const ProviderCtaContainer = styled.aside(
  layout.flexVertical,
  layout.marginVertical("expanded"),
  card.base,
  ({ theme }) => ({
    backgroundImage: `url(${ctaCardBg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "100% 100%",
    padding: theme.spacing.gutter,
  }),
);

export const ProviderCtaContainerText = styled(Text)(({ theme }) => ({
  marginTop: theme.spacing.condensed,
  marginBottom: theme.spacing.standard,
}));

export const SetAddressContainer = styled.div(
  layout.flexVertical,
  layout.padding("skip", "condensed", "skip", "skip"),
);
