import { useReactiveVar } from "@apollo/client";
import React, { useState } from "react";

import { address } from "/apollo/client/local";
import { AddressState } from "/apollo/client/local/address/address.types";
import { ButtonVariants } from "/component/base/Button";
import { ChevronDown } from "/component/base/Icon";
import Text from "/component/base/Text";
import { TextColors } from "/component/base/Text/Text.types";
import AddressModal from "/component/modal/AddressModal";
import { useTranslation } from "/hook";
import { layout } from "/styles";
import { TextVariants } from "/theme/default";
import { ScreenViewParams } from "/util/firebase.util";

import { AddressButtonBlank, AddressButtonUnstyled } from "./SetAddress.styles";
interface Props {
  buttonStyle?: "unstyled" | "blank";
  variant?: ButtonVariants;
  textVariant?: TextVariants;
  header?: string;
  textColor?: TextColors;
  handleDone?: () => void;
  analyticsParams?: ScreenViewParams;
}

const SetAddress = ({
  header,
  buttonStyle,
  variant = "blank",
  textVariant = "body1Bold",
  textColor = "textPrimary",
  handleDone,
  analyticsParams,
}: Props) => {
  const { t } = useTranslation("partial-address");
  const addressStored = useReactiveVar<AddressState>(address.var);

  const [isOpen, setIsOpen] = useState(false);
  const addressString = addressStored.placeId && (addressStored.line1 || addressStored.city);
  const buttonAriaLabel = addressString ? t("address", { addressString }) : t("addressPlaceholder");

  const AddressButton = buttonStyle === "blank" ? AddressButtonBlank : AddressButtonUnstyled;

  return (
    <div>
      {header && (
        <Text variant="body2" color="textSecondary" css={{ whiteSpace: "nowrap" }}>
          {header}
        </Text>
      )}
      <AddressButton
        aria-label={buttonAriaLabel}
        key="AddressButton"
        onClick={() => setIsOpen(true)}
        variant={variant}
      >
        <Text variant={textVariant} color={textColor} align="left" css={{ whiteSpace: "nowrap" }}>
          {addressStored.line1 || addressStored.city
            ? addressStored.line1 || addressStored.city
            : t("addressPlaceholder")}
        </Text>
        <ChevronDown
          color="brandSecondary"
          size={16}
          css={layout.margin("skip", "skip", "skip", "condensed")}
        />
      </AddressButton>
      <AddressModal
        handleDone={handleDone}
        isOpen={isOpen}
        close={() => setIsOpen(false)}
        analyticsParams={analyticsParams}
      />
    </div>
  );
};

export default SetAddress;
