import { transparentize } from "polished";
import styled from "styled-components";

import { layout, link } from "/styles";

export const Base = styled.div(layout.flexCenter);

export const ContactUsLink = styled.a(link.base, ({ theme }) => ({
  marginLeft: theme.spacing.condensed,
  borderBottom: `solid 1.5px ${transparentize(0.7, theme.colors.brandPrimary)}`,
}));
