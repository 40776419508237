import { bannedTopicKeys } from "/constant/amwell.constant";
import { useAmwell, useAmwellQuery } from "/hook";

/**
 * This hook allows for the retrieval of the Amwell topics for Secure Messaging.
 */
export const useAmwellTopics = () => {
  const { sdk, isReady } = useAmwell();
  const fetch = async () => {
    if (isReady && sdk) {
      const topics = await sdk.getTopicTypes();

      // There are no options for configuring message topics in Amwell's SDK, so
      // we manually filter the ones we want to omit here. See DA-695 for details.
      // TODO: Update implementation after Amwell updates their SDK to allow this.
      return topics.filter((topic) => !bannedTopicKeys.includes(topic.key));
    }

    return;
  };

  return useAmwellQuery(fetch);
};
