import React from "react";

import { Button, Modal } from "/component/base";
import { CaregiverNotificationForm } from "/component/form/CaregiverForms";
import { useTranslation } from "/hook";

import { FormContentContainer } from "./ChildNotificationModal.styles";
import { Props } from "./ChildNotificationModal.types";

const ChildNotificationModal = ({ isOpen, close }: Props) => {
  const { t } = useTranslation("modal-childNotification");
  return (
    <Modal
      isOpen={isOpen}
      header={<Modal.Header hideHeaderLeft title={t("title")} close={close} />}
      footer={
        <Modal.FooterWithLargeButton>
          <FormContentContainer.ButtonGroup>
            <Button variant="primary" onClick={close}>
              {t("continue")}
            </Button>
          </FormContentContainer.ButtonGroup>
        </Modal.FooterWithLargeButton>
      }
    >
      <CaregiverNotificationForm />
    </Modal>
  );
};

export default ChildNotificationModal;
