import { useReactiveVar } from "@apollo/client";
import React, { useEffect, useState } from "react";

import { address } from "/apollo/client/local";
import { AddressState } from "/apollo/client/local/address/address.types";
import { Button, Spinner } from "/component/base";
import { Navigation as NavigationIcon } from "/component/base/Icon";
import Modal from "/component/base/Modal";
import AddressCombobox from "/component/partial/AddressCombobox";
import { useToastContext } from "/component/provider/ToastProvider";
import { useTranslation } from "/hook";
import useAmwell from "/hook/useAmwell";
import useAmwellLocation from "/hook/useAmwellLocation";
import useGoogleMaps from "/hook/useGoogleMaps";
import { googlePlaces } from "/util";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  logEvent,
  ScreenViewParams,
} from "/util/firebase.util";

import { AddressText, CurrentLocationButton } from "./AddressModal.styles";
import { Props } from "./AddressModal.types";

const AddressModal = ({
  close,
  isOpen,
  dismissable = true,
  handleDone,
  initialValue,
  analyticsParams,
}: Props) => {
  const { t } = useTranslation("partial-address");
  const { showToast } = useToastContext();

  //Variable storage
  const addressStored = useReactiveVar<AddressState>(address.var);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<google.maps.places.PlaceResult>();

  //Hooks
  const { consumer, sdk, login, isReady, updateConsumer } = useAmwell();
  const { data: country } = useAmwellLocation();
  const googleMaps = useGoogleMaps();

  useEffect(() => {
    const setupLocations = async () => {
      if (initialValue && isReady && googleMaps) {
        const location = await googlePlaces.getPlaceByAddress(googleMaps, initialValue);
        const extractedAddress = googlePlaces.extractAddress(location?.address_components || []);

        if (extractedAddress) {
          setSelectedAddress(location);

          if (consumer && isReady && sdk?.consumerService && country) {
            const state = country.states.find((state) => state.code === extractedAddress.state);

            if (!state) return;

            if (!consumer.legalResidence || consumer.legalResidence.code !== state.code) {
              const consumerUpdate = sdk.consumerService.newConsumerUpdate();
              consumerUpdate.legalResidence = state.code;
              updateConsumer(await sdk.consumerService.updateConsumer(consumer, consumerUpdate));
            }
          }
        }
      }
    };
    const locationSetup = setTimeout(() => {
      setupLocations();
    }, 200);

    return () => clearTimeout(locationSetup);
  }, [initialValue, isReady]);

  useEffect(() => {
    // When we open the modal reset the `selectedAddress` to empty
    if (isOpen) {
      logEvent(AnalyticsEvent.SCREEN_VIEW, {
        screenName: AnalyticsSource.LOCATION,
        ...analyticsParams,
      } as ScreenViewParams);
      setSelectedAddress(undefined);
    }
  }, [isOpen]);

  //Helper functions
  const handleSubmit = async () => {
    const extractedAddress = googlePlaces.extractAddress(selectedAddress?.address_components || []);

    close();

    if (extractedAddress && selectedAddress?.place_id) {
      googlePlaces.updateAddress(extractedAddress, selectedAddress);
      if (consumer && isReady && sdk?.consumerService && country) {
        const state = country.states.find((state) => state.code === extractedAddress.state);

        if (!state) return;

        if (!consumer.legalResidence || consumer.legalResidence.code !== state.code) {
          await login();
          const consumerUpdate = sdk.consumerService.newConsumerUpdate();
          consumerUpdate.legalResidence = state.code;
          updateConsumer(await sdk.consumerService.updateConsumer(consumer, consumerUpdate));
        }
      }
    }

    if (handleDone) handleDone();
    else close();
  };

  const grabCurrentLocation = async () => {
    logButtonClickEvent("Use current location");
    if (googleMaps) {
      setIsLoading(true);

      try {
        const selectedAddress = await googlePlaces.getAddressCurrentLocation(googleMaps);

        if (selectedAddress) {
          setSelectedAddress(selectedAddress);
        }
      } catch (error: any) {
        const { friendlyMessage } = error as {
          err: GeolocationPositionError;
          friendlyMessage: string;
        };

        showToast({
          icon: "alert",
          message: friendlyMessage,
          type: "error",
        });
      }

      setIsLoading(false);
    }
  };

  const logButtonClickEvent = (buttonName: string) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.LOCATION,
      source: AnalyticsSource.LOCATION,
      button_name: buttonName,
    } as ButtonClickParams);
  };

  return (
    <Modal
      key="AddressModal"
      header={
        <Modal.Header title={t("modal.header")} hideHeaderLeft={!dismissable} close={close} />
      }
      footer={
        <Modal.FooterWithLargeButton>
          <Button fullWidth="percent" onClick={handleSubmit} isDisabled={isLoading}>
            {t("submit")}
          </Button>
        </Modal.FooterWithLargeButton>
      }
      isOpen={isOpen}
      close={dismissable ? close : undefined}
    >
      <AddressCombobox
        formattedAddress={
          initialValue || selectedAddress?.formatted_address || addressStored.formattedAddress
        }
        placeholder={t("modal.addressPlaceholder")}
        onSelectAddress={(selectedAddress) => {
          setSelectedAddress(selectedAddress);
        }}
      />

      <ul>
        <li>
          <CurrentLocationButton disabled={isLoading} onClick={grabCurrentLocation}>
            <NavigationIcon />
            {isLoading ? (
              <Spinner />
            ) : (
              <AddressText variant="body1Bold">{t("modal.currentLocation")}</AddressText>
            )}
          </CurrentLocationButton>
        </li>
      </ul>
    </Modal>
  );
};

export default AddressModal;
