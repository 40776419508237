import React, { forwardRef } from "react";

import { Base, ButtonSpinner } from "./Button.styles";
import { ButtonVariants, Props } from "./Button.types";

const getSpinnerColorForVariant = (variant: ButtonVariants) => {
  switch (variant) {
    case "primary":
      return "objectInverse";
    // case "blank":
    // case "secondary":
    // case "unstyled":
    //   return "brandSecondary";
    default:
      return "brandPrimary";
  }
};

const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      children,
      className,
      fullWidth,
      isDisabled,
      isLoading,
      title,
      variant = "primary",
      size = "default",
      ...rest
    },
    ref,
  ) => (
    <Base
      $fullWidth={fullWidth}
      $loading={isLoading}
      $variant={variant}
      $size={size}
      className={className}
      disabled={isLoading || isDisabled}
      {...rest}
      ref={ref}
    >
      {children || title}
      {isLoading && <ButtonSpinner color={getSpinnerColorForVariant(variant)} />}
    </Base>
  ),
);

export default Button;
