import React from "react";

import Text, { Label } from "/component/base/Text";
import theme, { Colors, TextVariants } from "/theme/default";

import { Error, Header } from "./InputWrapper.styles";
import { Props, WrapperVariants } from "./InputWrapper.types";

export const getLabelProps = (
  variant: WrapperVariants,
): { variant: TextVariants; color: Colors } => {
  switch (variant) {
    case "primary":
      return { variant: "body2", color: "textSecondary" };
    case "large-label":
    case "expanded-label":
      return { variant: "title3", color: "textPrimary" };
    case "bold-label":
      return { variant: "body1Bold", color: "textPrimary" };
  }
};

const InputWrapper = ({
  children,
  error,
  touched,
  label,
  htmlFor,
  accessoryText,
  className,
  variant = "primary",
  isHTML = false,
}: Props) => {
  const labelProps = getLabelProps(variant);

  return (
    <div className={className}>
      {!!(label || accessoryText) && (
        <Header variant={variant}>
          {!!label &&
            (isHTML ? (
              <Label
                css={{
                  "& > a:visited": {
                    color: theme.colors.brandSecondary,
                  },
                  "& > a": {
                    color: theme.colors.brandSecondary,
                  },
                }}
                dangerouslySetInnerHTML={{ __html: label.toString() }}
                variant={labelProps.variant}
                color={labelProps.color}
                htmlFor={htmlFor}
              />
            ) : (
              <Label variant={labelProps.variant} color={labelProps.color} htmlFor={htmlFor}>
                {label}
              </Label>
            ))}

          {!!accessoryText && (
            <Text variant="body2" color="textSecondary">
              {accessoryText}
            </Text>
          )}
        </Header>
      )}
      {React.cloneElement(children, { id: htmlFor, ariaLabel: label })}
      {!!error && touched && (
        <Error color="statusNegative" variant="body2">
          {error}
        </Error>
      )}
    </div>
  );
};

export default InputWrapper;
