import { Form as FormBase } from "formik";
import styled from "styled-components";

import Text from "/component/base/Text";
import FormContent from "/component/partial/FormContent";
import { layout } from "/styles";

export const Container = styled.div(layout.marginVertical("gutter"), {
  width: "100%",
});

export const Form = styled(FormBase)(({ theme }) => ({
  width: "inherit",
  paddingLeft: theme.spacing.expanded,
}));

export const FormContentContainer = styled(FormContent)(layout.marginVertical("gutter"), {
  width: "100%",
});

export const Title = styled(Text).attrs({
  variant: "title2",
  element: "h2",
})(layout.margin("skip", "skip", "skip"));

export const SubTitle = styled(Text).attrs({
  variant: "body1",
  color: "textSecondary",
})(layout.padding("skip"));
