import { gql, useQuery } from "@apollo/client";

import { Prescription, PrescriptionVariables } from "/apollo/schema/types";

export const prescriptionQuery = gql`
  query Prescription($id: ID!, $mrn: String!) {
    prescription(id: $id, mrn: $mrn) {
      active
      createdDateTime
      documentDescription
      dosageDurationUnit
      dosageFrequencyUnit
      futureSubmitDate
      pharmacy {
        name
        address {
          line1
          line2
          city
          state
          postalCode
        }
        pharmacyType
        contact {
          phoneNumber
        }
      }
      prescriptionId
      provider {
        nameWithDesignations
      }
      quantityUnit
      quantityValue
      refillsAllowed
      sig
      status
    }
  }
`;

const usePrescription = (prescriptionId: number, mrn: string) => {
  const id = `${prescriptionId}`;
  return useQuery<Prescription, PrescriptionVariables>(prescriptionQuery, {
    variables: { id, mrn },
  });
};

export default usePrescription;
