import { useReactiveVar } from "@apollo/client";
import React, { createContext, useContext, useEffect, useState } from "react";

import { auth } from "/apollo/client/local";
import { CaregiverStatus } from "/apollo/schema";
import { useUserAccount } from "/component/layout/MainLayout/useUserAccount";
import { setCountlyUserDetails } from "/util/countly";

import { CaregiverType, Context } from "./CaregiverProvider.types";
import { useCaregiverAccess } from "./useCaregiverAccessWithParams";

const INITIAL_STATE = {
  id: null,
  firstName: "",
  lastName: "",
  birthDate: "",
  isMinor: false,
  statusDetails: {
    status: CaregiverStatus.UNKNOWN,
    statusName: "",
    createdDate: "",
    acceptedDate: "",
    revokedDate: "",
  },
  contact: {
    phone: "",
    email: "",
  },
};

const CaregiverProviderContext = createContext<Context>({
  caregiver: INITIAL_STATE,
  caregiverMode: false,
  setCaregiver: () => null,
  setCaregiverToggle: () => null,
  clearCaregiver: () => null,
  caregiverAccess: {
    caregiverAccounts: undefined,
    filteredCaregiverAccounts: undefined,
    numCaregiverAccounts: 0,
    caregiverAccountsRefetch: () => null,
  },
  userAccount: undefined,
  userAccountLoading: false,
  userAccountError: undefined,
});

const CaregiverProvider = (props: { children: React.ReactNode }): JSX.Element => {
  const { isAuthenticated } = useReactiveVar(auth.var);

  const [caregiver, setCaregiver] = useState<CaregiverType>(INITIAL_STATE);
  const clearCaregiver = () => {
    setCaregiverMode(false);
    setCaregiver({ ...INITIAL_STATE, id: "" });
  };
  const [caregiverMode, setCaregiverMode] = useState<boolean>(false);

  const { data: caregiverAccounts, refetch } = useCaregiverAccess({
    includeRevoked: true,
    useMyAccount: true,
  });
  const caregiverAccesses = caregiverAccounts?.viewer?.user.caregiverAccess || [];
  const filteredCaregiverAccounts =
    caregiverAccesses.filter(
      (item) =>
        item.statusDetails.status === CaregiverStatus.GRANTED ||
        (item.statusDetails.status === CaregiverStatus.INVITED && item.isMinor && !item.hasAccount),
    ) || [];
  const numCaregiverAccounts = filteredCaregiverAccounts.length;

  const {
    data: userAccount,
    error: userAccountError,
    refetch: refetchUserAccount,
    loading: userAccountLoading,
  } = useUserAccount({
    useMyAccount: true,
  });

  useEffect(() => {
    if (isAuthenticated) {
      refetch();
      if (!userAccount || Object.entries(userAccount).length === 0) {
        refetchUserAccount();
      }
      if (userAccount?.viewer?.user?.account) {
        const { firstName, lastName, email, phoneNumber } = userAccount?.viewer?.user.account;
        setCountlyUserDetails({
          firstName,
          lastName,
          email: email ?? "",
          phoneNumber,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, userAccount]);

  useEffect(() => {
    if (caregiver.id) {
      refetchUserAccount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caregiver.id]);

  const setCaregiverToggle = (mode: boolean) => {
    setCaregiverMode(mode);
  };

  return (
    <CaregiverProviderContext.Provider
      value={{
        caregiver,
        caregiverMode,
        clearCaregiver,
        setCaregiver,
        setCaregiverToggle,
        caregiverAccess: {
          caregiverAccounts,
          filteredCaregiverAccounts,
          numCaregiverAccounts,
          caregiverAccountsRefetch: () => refetch(),
        },
        userAccount,
        userAccountLoading,
        userAccountError,
      }}
    >
      {props.children}
    </CaregiverProviderContext.Provider>
  );
};

export const useCaregiverProvider = () => useContext(CaregiverProviderContext);
export default CaregiverProvider;
