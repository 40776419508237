import React from "react";

import Text from "/component/base/Text";

import { InsuranceItemContainer } from "./InsuranceItem.styles";

interface Props {
  companyName: string;
  policyOrder?: string;
  isAddPolicy?: boolean;
}

const InsuranceItem = ({ companyName, policyOrder, isAddPolicy = false }: Props) => {
  return (
    <InsuranceItemContainer aria-label={`${companyName}, ${policyOrder}`}>
      <Text variant="body1">{policyOrder}</Text>
      <Text
        variant={isAddPolicy ? "body1Bold" : "body1"}
        color={isAddPolicy ? "textPrimary" : "textSecondary"}
      >
        {companyName}
      </Text>
    </InsuranceItemContainer>
  );
};

export default InsuranceItem;
