import { lighten, transparentize } from "polished";
import styled from "styled-components";

import Spinner from "/component/base/Spinner";
import { getTextStyles } from "/component/base/Text";
import { layout, link } from "/styles";
import { ThemeType } from "/theme/default";

import { Props, StyleProps } from "./Button.types";

export type VariantStyleProps = Pick<Props, "variant" | "disabled">;

export const getTextStyleForButtonVariant = ({
  theme,
  $variant,
  $size,
}: ThemeType & StyleProps) => {
  const smallButtonFont = getTextStyles({ theme, variant: "body1Bold" });

  if ($size === "small") return smallButtonFont;

  switch ($variant) {
    case "tertiarySmall":
      return getTextStyles({ theme, color: "textNavyBlue", variant: "body2Medium" });
    case "borderBottom":
      return getTextStyles({ theme, color: "brandPrimary", variant: "body1Bold" });
    case "borderBottomSecondary":
      return getTextStyles({ theme, color: "brandSecondary", variant: "body1Bold" });
    case "inverse":
    case "tertiary":
      return smallButtonFont;
    default:
      return getTextStyles({ theme, variant: "title3" });
  }
};

export const getVariantStyle = ({
  theme,
  $loading,
  $variant = "primary",
  disabled,
}: ThemeType & StyleProps) => {
  const baseVariantStyle = {
    outline: "none",
    height: 48,
    borderRadius: 30,
    padding: `0 ${theme.spacing.standard}px`,
  };

  const nonPrimaryActive = {
    backgroundColor: theme.colors.brandPrimaryLight,
    borderColor: "transparent",
    color: theme.colors.brandPrimary,
  };

  const baseTertiaryStyles = {
    outline: "none",
    borderRadius: 30,
    padding: `0 ${theme.spacing.standard}px`,
    border: `solid 1px ${theme.colors.objectSubtle}`,
    backgroundColor: theme.colors.backgroundPrimary,
    color: theme.colors.brandPrimary,

    "&:disabled": {
      opacity: 0.65,
    },

    "&:not(:disabled)": {
      "&:hover, &:focus": {
        backgroundColor: theme.colors.objectInverse,
      },

      "&:active": {
        ...nonPrimaryActive,
      },
    },
  };

  switch ($variant) {
    case "primary":
      return {
        ...baseVariantStyle,
        backgroundColor: theme.colors.brandPrimary,
        color: theme.colors.textInverse,
        boxSizing: "border-box",

        "&:not(:disabled)": {
          "&:hover, &:focus": {
            backgroundColor: lighten(0.05, theme.colors.brandPrimary),
          },

          "&:active": {
            backgroundColor: theme.colors.statusPressed,
          },
        },
      } as const;

    case "secondary":
      return {
        ...baseVariantStyle,
        border: `solid 1.5px ${transparentize(0.7, theme.colors.brandPrimary)}`,
        backgroundColor: theme.colors.backgroundPrimary,
        color: disabled ? theme.colors.brandPrimary : theme.colors.brandPrimary,

        "&:not(:disabled)": {
          "&:hover, &:focus": {
            backgroundColor: theme.colors.objectInverse,
          },

          "&:active": {
            ...nonPrimaryActive,
          },
        },
      };

    case "tertiary":
      return {
        ...baseTertiaryStyles,
        height: 40,
      };

    case "tertiarySmall":
      return {
        ...baseTertiaryStyles,
        height: 32,
      };

    case "inverse":
      return {
        outline: "none",
        borderRadius: 30,
        height: 40,
        padding: `0 ${theme.spacing.standard}px`,
        border: `solid 1px ${theme.colors.objectInverse}`,
        backgroundColor: theme.colors.brandPrimary,
        color: theme.colors.textInverse,

        "&:disabled": {
          opacity: 0.65,
        },

        "&:not(:disabled)": {
          "&:hover, &:focus": {
            backgroundColor: lighten(0.05, theme.colors.brandPrimary),
          },

          "&:active": {
            ...nonPrimaryActive,
          },
        },
      };

    case "blank":
      return {
        outline: "none",
        backgroundColor: theme.colors.objectInverse,

        "&:disabled": {
          opacity: 0.65,
        },

        "&:not(:disabled)": {
          "&:hover, &:focus": {
            boxShadow: `inset 0px 0px 0px 2px ${theme.colors.objectOverlay}`,
          },

          "&:active": {
            boxShadow: "none",
            ...nonPrimaryActive,
          },
        },
      };

    case "borderBottom":
      return {
        ...link.base,
        ...($loading ? { borderColor: "transparent" } : {}),
        textDecoration: "none",
        borderBottom: `solid 1.5px ${transparentize(0.7, theme.colors.brandPrimary)}`,
      };
    case "borderBottomSecondary":
      return {
        ...link.base,
        ...($loading ? { borderColor: "transparent" } : {}),
        textDecoration: "none",
        borderBottom: `solid 1.5px ${transparentize(0.7, theme.colors.brandSecondary)}`,

        "&:active": {
          opacity: 0.65,
        },
      };

    // Let the children control the styles. This is useful to retain all the
    // utility built into the Button component, for example accessibility, while
    // allowing massive flexibility for "button-like" elements
    case "unstyled":
      return {};
  }
};

export const getButtonSizeStyle = ({ $size }: StyleProps) => {
  if ($size === "small") return { height: 40 };
  if ($size === "big") return { height: 78 };

  return {};
};

export const ButtonSpinner = styled(Spinner)(layout.absoluteCenter);

export const Base = styled.button<StyleProps>(
  getTextStyleForButtonVariant,
  getVariantStyle,
  getButtonSizeStyle,
  ({ $fullWidth, $variant }) => ({
    alignSelf: $fullWidth === "flex" ? "stretch" : undefined,
    display: "block",
    outline: "none",
    position: "relative",
    width: $fullWidth === "percent" ? "100%" : undefined,

    // Make sure child clicks don't register clicks
    "& *": {
      pointerEvents: "none",
    },

    "&:disabled": {
      cursor: "not-allowed",
      opacity: $variant === "primary" ? 0.5 : 0.65,
    },
  }),
  ({ $loading }) => {
    if (!$loading) {
      return {};
    }

    // Visually hide the button's non-spinner children when `isLoading`. This
    // enables us to preserve the button's existing size and avoid layout
    // effects while showing the spinner.
    return {
      color: "transparent",

      [`& > *:not(${ButtonSpinner})`]: { opacity: 0 },
    };
  },
);
