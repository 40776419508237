// Modules
import styled from "styled-components";

import { layout } from "/styles";

import { Props } from "./TextInput.types";

export const Input = styled.input<Props<string>>(layout.padding("none"), ({ theme }) => ({
  fontFamily: theme.fontVariants.body1,
  fontSize: theme.fontSizes.body1,
  lineHeight: theme.lineHeights.body1,
  color: theme.colors.textPrimary,
  alignSelf: "stretch",
  flex: 1,

  // Resets
  backgroundColor: "transparent",
  border: 0,
  outline: "none",

  // Add padding to the before/after elements if they exist
  "* + &": {
    marginLeft: theme.spacing.condensed,
  },

  "& + *": {
    marginLeft: theme.spacing.condensed,
  },
}));

export const Wrapper = styled.div<Props<string> & { hasFocus: boolean }>(
  layout.padding("none", "standard"),
  ({ hasFocus, theme, hasError, variant }) => {
    let borderColor = "transparent";
    let backgroundColor: string =
      variant === "secondary" ? theme.colors.white : theme.colors.brandPrimaryLight;

    if (hasError) {
      borderColor = theme.colors.brandSecondary;
      backgroundColor = theme.colors.brandSecondaryLight;
    } else if (hasFocus) {
      borderColor = theme.colors.objectOverlay;
    }
    const fillColor = variant === "secondary" ? theme.colors.white : theme.colors.brandPrimaryLight;
    return {
      "& input:autofill": {
        "background-color": `${fillColor} !important`,
        "-webkit-box-shadow": `0 0 0 50px ${fillColor} inset`,
      },
      "& input:-webkit-autofill": {
        "background-color": `${fillColor} !important`,
        "-webkit-box-shadow": `0 0 0 50px ${fillColor} inset`,
      },
      alignItems: "center",
      backgroundColor,
      border: `solid 1.5px ${borderColor}`,
      borderRadius: 30,
      display: "flex",
      height: 48,
      overflow: "hidden",
    };
  },
);
