import { useReactiveVar } from "@apollo/client";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Trans } from "react-i18next";
import { Redirect, useLocation } from "react-router";

import { scheduling } from "/apollo/client/local";
import { ProviderAvailabilitiesListFragment_availabilities_pages as Availability } from "/apollo/schema";
import appointmentsNoResults from "/asset/image/appointmentsNoResults.png";
import { Link, Loading, Text, TextDivider } from "/component/base";
import MainContent from "/component/layout/MainContent";
import { SchedulingProviderDetailsModal } from "/component/modal/ProviderDetailsModal";
import ProviderFiltersModal from "/component/modal/ProviderFiltersModal";
import useAppointmentBooking from "/component/page/Scheduling/hook/useAppointmentBooking";
import EmptyStateCta from "/component/partial/EmptyStateCta";
import FilterButton from "/component/partial/FilterButton";
import InlineEmptyState from "/component/partial/InlineEmptyState";
import routes from "/constant/url.constant";
import { useTranslation } from "/hook";
import { layout } from "/styles";
import { reactPlugin } from "/util/appInsights.util";
import {
  AnalyticsEvent,
  AnalyticsSource,
  ButtonClickParams,
  IndentifierParams,
  logEvent,
} from "/util/firebase.util";

import {
  formatDateRange,
  getQueryVariables,
  getSelectedFilterCount,
} from "../Availabilities.utils";
import AvailabilitiesBackButton from "../component/AvailabilitiesBackButton";
import ProviderAvailabilitiesList from "../component/ProviderAvailabilitiesList";
import SchedulingPrompt from "../component/SchedulingPrompt";
import { VirtualUrgentCareContainer } from "./SelectedCalendars.styles";
import useSelectedCalendars from "./useSelectedCalendars";

const IMAGE_HEIGHT = 244;
const IMAGE_WIDTH = 376;

const SelectedCalendars = () => {
  const { t } = useTranslation("availabilities-selectedCalendars");

  const location = useLocation<{ canGoBack: boolean }>();
  const { canGoBack } = location.state || {};

  const { beginBooking } = useAppointmentBooking();
  const variables = useReactiveVar(scheduling.var);
  const isEstablishedPatient = variables.isEstablishedPatient;
  const displayUrgentCare = variables.enableUrgentCare;

  const [refetching, setRefetching] = useState(false);
  const [selectedProviderId, setSelectedProviderId] = useState<string>();
  const [showProviderDetails, setShowProviderDetails] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [isReserving, setIsReserving] = useState(false);

  const queryVariables = {
    ...getQueryVariables(variables),
    calendarIds: variables.selectedCalendarIds,
    gender: null,
    language: null,
    geo: null,
  };

  const { data, loading, error, fetchMore, refetch } = useSelectedCalendars(queryVariables);

  if (!variables.flowSessionId) {
    return <Redirect to={routes.scheduling} />;
  }

  const availabilities = data?.availabilities;

  const handleTimeslotClick = async (availabilityId: string, availability: Availability) => {
    const timeSlot = availability.timeSlots.find((ts) => ts.availabilityId === availabilityId);

    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: variables.analyticsFlow,
      source: AnalyticsSource.PROVIDER_AVAILABILITIES,
      button_name: "Availability",
      button_description: timeSlot?.startAtUtc,
      availability_id: availabilityId,
      calendar_id: timeSlot?.calendarId,
      provider_id: availability.providerDetails.id,
    } as ButtonClickParams);

    setIsReserving(true);
    await beginBooking({ availability, availabilityId });
    setIsReserving(false);
  };

  const handleRefetch = async () => {
    setRefetching(true);
    try {
      await refetch(queryVariables);
    } finally {
      setRefetching(false);
    }
  };

  const handleProviderClick = (providerId: string) => {
    logButtonClickEvent("Provider", { provider_id: providerId });
    if (providerId) {
      setSelectedProviderId(providerId);
      setShowProviderDetails(true);
    }
  };

  const providerName = availabilities?.pages[0]?.providerDetails.nameWithDesignations;

  const logButtonClickEvent = (buttonName: string, params?: IndentifierParams) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: variables.analyticsFlow,
      source: AnalyticsSource.PROVIDER_AVAILABILITIES,
      button_name: buttonName,
      ...params,
    } as ButtonClickParams);
  };

  const handleFilterClick = (buttonName: string) => {
    logButtonClickEvent(buttonName);
    setShowFilterModal(true);
  };

  // Figure out what children to render based on loading, error, and data states
  const renderChild = () => {
    if (loading || refetching) {
      return <Loading />;
    }

    if (error) {
      return (
        <InlineEmptyState
          title={t("error.title")}
          subtitle={t("error.subtitle")}
          ctaText={t("error.cta")}
          ctaOnClick={handleRefetch}
        />
      );
    }

    // If there are no results render an empty state
    if (!availabilities?.pages.length) {
      return (
        <>
          <EmptyStateCta
            imageSource={appointmentsNoResults}
            imageHeight={IMAGE_HEIGHT}
            imageWidth={IMAGE_WIDTH}
            title={t("emptyList.title")}
            subtitle={
              <Trans
                components={{ bold: <Text color="textSecondary" variant="body2Bold" /> }}
                i18nKey="emptyList.subtitle"
                t={t}
                values={formatDateRange(variables.minStartDate)}
              />
            }
            ctaText={t("emptyList.cta")}
            onClickCta={() => handleFilterClick("Update your filters")}
          />
          {displayUrgentCare && (
            <VirtualUrgentCareContainer>
              <TextDivider label="or" />
              <Link.Button
                css={[layout.margin("condensed"), { flex: 1 }]}
                to={routes.telehealth}
                variant="tertiary"
                onClick={() => logButtonClickEvent("Get virtual urgent care")}
              >
                {isEstablishedPatient ? t("virtualUrgentCare") : t("virtualUrgentCareInstead")}
              </Link.Button>
            </VirtualUrgentCareContainer>
          )}
        </>
      );
    }

    return (
      <ProviderAvailabilitiesList
        availabilitiesDisabled={isReserving}
        availabilities={availabilities}
        fetchMore={fetchMore}
        onTimeslotClick={handleTimeslotClick}
        onProviderClick={handleProviderClick}
      />
    );
  };

  return (
    <MainContent
      backButton={
        <AvailabilitiesBackButton
          canGoBack={canGoBack}
          analyticsSource={AnalyticsSource.PROVIDER_AVAILABILITIES}
        />
      }
      pageTitle={providerName || t("headline")}
      headerAccessory={
        <FilterButton
          onClick={() => handleFilterClick("Filter (icon)")}
          count={getSelectedFilterCount(variables.filters)}
        />
      }
    >
      <Helmet>{t("title")}</Helmet>

      <SchedulingPrompt when={!error} />

      {renderChild()}

      {selectedProviderId && (
        <SchedulingProviderDetailsModal
          isOpen={showProviderDetails}
          close={() => setShowProviderDetails(false)}
          providerId={selectedProviderId}
          goBackOnCtaClick
          hideCta
        />
      )}

      <ProviderFiltersModal
        isOpen={showFilterModal}
        close={() => setShowFilterModal(false)}
        hideGender
        hideLanguage
      />
    </MainContent>
  );
};

export default withAITracking(reactPlugin, SelectedCalendars, undefined, "ai-tracking");
