import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";

import { Button, CheckBox, Divider, Text } from "/component/base";
import EditableMessage from "/component/base/EditableMessage";
import FileInput from "/component/base/FileInput";
import { Send } from "/component/base/Icon";
import Modal from "/component/base/Modal";
import TextField from "/component/partial/formik/TextField";
import { useToastContext } from "/component/provider/ToastProvider";
import { useAmwell, useTranslation } from "/hook";
import { layout } from "/styles";

import MessageDisclaimer from "../ComposeMessageModal/MessageDisclaimer";
import { ComposeFormValues, Props } from "./ReplyMessageModal.types";

const BYTES_PER_MEGABYTE = 1048576;

const ReplyMessageModal = ({ close, isOpen, messageDetails, ...modalProps }: Props) => {
  const [sending, setSending] = useState(false);
  const { sdk, isReady, consumer } = useAmwell();
  const { showToast } = useToastContext();
  const { t } = useTranslation("composeMessage");
  const acceptedFiletypes = sdk?.getSystemConfiguration().mimeTypeWhitelist.join(",");
  const initialMessage = `<br/><br/><div class="scmd-quote">${messageDetails.body}</div>`;
  const initialSubject =
    messageDetails.subject.indexOf("RE: ") === 0
      ? messageDetails.subject
      : `RE: ${messageDetails.subject}`;

  const validationSchema = yup.object().shape({
    attachment: yup
      .mixed()
      .test("is-file-too-large", t("error.attachmentSize"), ({ data } = {}) =>
        data?.size ? data.size / BYTES_PER_MEGABYTE <= 20 : true,
      ),
    includeSummary: yup.boolean().required(t("error.required")),
    message: yup
      .string()
      .trim()
      .min(1, t("error.empty", { field: t("field.message") }))
      .required(t("error.required")),
    subject: yup
      .string()
      .trim()
      .min(1, t("error.empty", { field: t("field.subject") }))
      .required(t("error.required")),
  });

  const handleSubmit = async (
    { attachment, includeSummary, message, subject }: ComposeFormValues,
    { resetForm }: { resetForm: () => void },
  ) => {
    if (consumer && sdk) {
      setSending(true);
      const messageDraft = sdk.secureMessageService.getReplyMessageDraft(messageDetails);

      if (subject) {
        messageDraft.subject = subject;
      }

      if (message) {
        messageDraft.body = message;
      }

      if (typeof includeSummary === "boolean") {
        messageDraft.attachHealthSummary = includeSummary;
      }

      if (attachment) {
        messageDraft.uploadAttachment = attachment;
      }

      try {
        await sdk.secureMessageService.sendMessage(consumer, messageDraft);
        setSending(false);
        close?.();
        resetForm();
        showToast({ icon: "checkmark", message: t("alert.sent") });
      } catch (e: any) {
        setSending(false);
        showToast({
          icon: "alert",
          message: t("alert.sendError.subtitle"),
          title: t("alert.sendError.title"),
          type: "error",
        });
      }
    }
  };

  return (
    <Formik
      initialValues={{
        attachment: undefined,
        includeSummary: false,
        message: initialMessage,
        subject: initialSubject,
      }}
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleReset,
        isSubmitting,
        isValid,
        setFieldTouched,
        setFieldValue,
        submitForm,
        touched,
        values,
      }) => {
        const handleClose = () => {
          handleReset();
          close?.();
        };

        return (
          <Form>
            <Modal
              {...modalProps}
              close={handleClose}
              header={
                <Modal.Header
                  close={handleClose}
                  headerRight={
                    <Button
                      isDisabled={!isValid || sending}
                      isLoading={isSubmitting}
                      onClick={submitForm}
                      type="submit"
                      variant="unstyled"
                      aria-label={t("button.send")}
                    >
                      <Send
                        aria-hidden
                        color="textInverse"
                        background={{ color: "brandSecondary", size: 40 }}
                      />
                    </Button>
                  }
                  title={messageDetails.sender.fullName}
                />
              }
              isOpen={isOpen}
            >
              <TextField
                css={layout.padding("skip", "skip", "standard")}
                label={t("label.subject")}
                name="subject"
                disabled={sending}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.subject}
              />

              {errors.attachment && touched.attachment && (
                <Text
                  color="statusNegative"
                  css={[layout.flexLTR, layout.padding("skip", "skip", "condensed")]}
                  id="attachment-error"
                  variant="body2"
                >
                  {errors.attachment}
                </Text>
              )}

              <FileInput
                accept={acceptedFiletypes}
                aria-describedby={"attachment-error"}
                disabled={!isReady || sending}
                file={values.attachment}
                onAdd={(blob, { name }) => {
                  if (sdk) {
                    const uploadAttachment = sdk.secureMessageService.newUploadAttachment();
                    uploadAttachment.name = name;
                    uploadAttachment.data = blob;

                    setFieldValue("attachment", uploadAttachment);
                    setFieldTouched("attachment", true);
                  }
                }}
                onRemove={() => setFieldValue("attachment", undefined)}
              />

              <CheckBox
                checked={values.includeSummary}
                css={layout.padding("standard", "skip", "skip")}
                disabled={sending}
                label={t("label.includeHealthSummary")}
                labelBefore={false}
                name="includeSummary"
                onBlur={handleBlur}
                onChange={handleChange}
              />

              <Divider css={layout.margin("standard", "none")} />

              {errors.message && touched.message && (
                <Text
                  color="statusNegative"
                  css={[layout.flexLTR, layout.padding("skip", "skip", "condensed")]}
                  id="message-error"
                  variant="body2"
                >
                  {errors.message}
                </Text>
              )}

              <EditableMessage
                aria-describedby="message-error"
                aria-label={t("message.label")}
                disabled={sending}
                html={values.message}
                onBlur={() => setFieldTouched("message", true)}
                onChange={({ target }) => {
                  setFieldValue("message", target.value);
                  setFieldTouched("message", true);
                }}
              />
              <MessageDisclaimer css={layout.margin(40, "skip", "skip")} />
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ReplyMessageModal;
