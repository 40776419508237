import styled from "styled-components";

import { Button, Text } from "/component/base";
import { layout } from "/styles";

export const Label = styled(Text).attrs({
  variant: "body1Bold",
  color: "textPrimary",
  element: "h3",
})(layout.margin("skip", "skip", "standard"));

export const CancelButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing[60],

  [theme.media.tablet]: {
    marginTop: theme.spacing[60],
  },
}));
