import { gql } from "@apollo/client";

export default gql`
  mutation UpdateUserPassword($data: UpdateB2CUserPasswordInput!) {
    updateUserPassword(data: $data) {
      errors {
        message
        code
      }
    }
  }
`;
