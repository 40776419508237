import { useReactiveVar } from "@apollo/client";
import { sortBy } from "lodash";
import React, { createContext, useContext, useEffect, useState } from "react";

import { auth } from "/apollo/client/local";
import { DocumentType, GetDocuments_viewer_user_patient_documents } from "/apollo/schema/types";
import { useQueryDocuments } from "/component/page/MyHealth/page/Documents/useQueryDocuments";

import { useCaregiverProvider } from "../CaregiverProvider";
import { Context } from "./DocumentProvider.types";

const DocumentProviderContext = createContext<Context>({
  numUnreadDocuments: 0,
  setNumUnreadDocuments: () => null,
  documents: [],
  refetchDocuments: () => null,
  updateDocumentReadStatus: () => null,
});

const DocumentProvider = (props: { children: React.ReactNode }): JSX.Element => {
  const { isAuthenticated } = useReactiveVar(auth.var);

  const { caregiver } = useCaregiverProvider();
  const { data: documentData, refetch: refetchDocuments } = useQueryDocuments({});

  const [documents, setDocuments] = useState<GetDocuments_viewer_user_patient_documents[]>([]);
  const [numUnreadDocuments, setNumUnreadDocuments] = useState(0);

  useEffect(() => {
    if (isAuthenticated) {
      refetchDocuments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caregiver.id, isAuthenticated]);

  useEffect(() => {
    if (documentData) {
      let _documents = documentData?.viewer?.user.patient?.documents;
      if (_documents) {
        _documents = sortBy(_documents, ["date"]).reverse();
        _documents = _documents.filter((doc) => doc.type !== DocumentType.OTHER);
        setDocuments(_documents);

        const _numUnreadDocuments = _documents?.filter((doc) => !doc.isRead)?.length;
        setNumUnreadDocuments(_numUnreadDocuments);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentData]);

  const updateDocumentReadStatus = (documentId: string) => {
    const _documents = [...documents];
    const documentIdx = _documents.findIndex(
      (doc: GetDocuments_viewer_user_patient_documents) => doc.documentId === documentId,
    );
    const document = { ..._documents[documentIdx] };
    document.isRead = true;
    _documents[documentIdx] = document;

    setDocuments(_documents);
    setNumUnreadDocuments(numUnreadDocuments - 1);
  };

  return (
    <DocumentProviderContext.Provider
      value={{
        numUnreadDocuments,
        setNumUnreadDocuments,
        documents,
        refetchDocuments,
        updateDocumentReadStatus,
      }}
    >
      {props.children}
    </DocumentProviderContext.Provider>
  );
};

export const useDocumentProvider = () => useContext(DocumentProviderContext);
export default DocumentProvider;
