import styled from "styled-components";

import { layout } from "/styles";

export const AbnormalFlagWrapper = styled.div(
  layout.flexStartHorizontal,
  layout.padding("base", "condensed", "base", "condensed"),
  ({ theme }) => ({
    backgroundColor: theme.colors.backgroundAbnormalFlag,
    alignSelf: "flex-start",
    borderRadius: 4,
  }),
);
