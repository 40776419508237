/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";

import {
  ContinueDecisionSupport_continueDecisionSupport_flowResponse_elementSet_elements as Element,
  DSInputType,
} from "/apollo/schema/types";
import DOBField from "/component/partial/formik/DOBField";
import TextField from "/component/partial/formik/TextField";
import i18n from "/i18n";
import theme from "/theme/default";

import BoolField from "./input/BoolField";
import SelectionField from "./input/SelectionField";

const inputPropByType = {
  [DSInputType.TIME]: { type: "time" },
  [DSInputType.DATE_TIME]: { type: "datetime-local" },
  [DSInputType.DATE]: { type: "date" },
  [DSInputType.PHONE_NUMBER]: { type: "tel" },
  [DSInputType.EMAIL]: { type: "email" },
  [DSInputType.INTEGER]: { type: "number" },
  [DSInputType.DECIMAL]: { type: "number", step: 0.01 },
  [DSInputType.CURRENCY]: { type: "number", step: 0.01 },
};

const backgroundStyle = { backgroundColor: theme.colors.objectInverse };

export const getInput = (element: Element) => {
  // Handle special cases here
  if (element.fieldName === "DateOfBirth") {
    return (
      <DOBField
        variant="secondary"
        key={element.elementRef}
        name={element.fieldName}
        inputWrapperVariant="large-label"
      />
    );
  }

  // Customize the placeholder of the "select your provider" combobox
  if (element.fieldName === "SelectedProviderNpi") {
    return (
      <SelectionField
        variant="secondary"
        key={element.elementRef}
        element={element}
        isHTML={true}
        placeholder={i18n.t("yourProviderSelect", { ns: "schedulingInputs" })}
      />
    );
  }

  switch (element.inputType) {
    case DSInputType.BOOL:
      return <BoolField isHTML={true} key={element.elementRef} element={element} />;
    case DSInputType.SELECTION:
      return (
        <SelectionField
          variant="secondary"
          isHTML={true}
          key={element.elementRef}
          element={element}
        />
      );
    case DSInputType.DATE:
    case DSInputType.DATE_TIME:
    case DSInputType.TIME:
    case DSInputType.MULTI_LINE_TEXT:
    case DSInputType.EMAIL:
    case DSInputType.INTEGER:
    case DSInputType.PHONE_NUMBER:
    case DSInputType.TEXT:
      return (
        <TextField
          variant="secondary"
          isHTML={true}
          style={backgroundStyle}
          {...(inputPropByType[element.inputType] ?? {})}
          key={element.elementRef}
          name={element.fieldName!}
          label={element.questionText!}
          inputWrapperVariant="expanded-label"
          maxLength={element.maxLength ?? undefined}
          minLength={element.minLength ?? undefined}
        />
      );

    default:
      return null;
  }
};
