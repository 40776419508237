import { gql, useQuery } from "@apollo/client";

import { CaregiverAccessDetails } from "/apollo/schema/types";

const GET_CAREGIVER_ACCESS_DETAILS = gql`
  query CaregiverAccessDetails {
    viewer {
      user {
        caregiverAccess {
          id
          firstName
          lastName
          birthDate
          gender
          isMinor
          hasAccount
          address {
            line1
            line2
            state
            city
            postalCode
          }
          statusDetails {
            status
            statusName
            createdDate
            acceptedDate
            revokedDate
          }
          contact {
            phone
            email
          }
        }
        caregivers {
          id
          firstName
          lastName
          birthDate
          hasAccount
          gender
          address {
            line1
            line2
            state
            city
            postalCode
          }
          pendingAccountCreation
          statusDetails {
            status
            statusName
            createdDate
            acceptedDate
            revokedDate
          }
          contact {
            phone
            email
          }
        }
      }
    }
  }
`;

// useQuery is fully typed and return value is inferred.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useCaregiverAccess = () =>
  useQuery<CaregiverAccessDetails>(GET_CAREGIVER_ACCESS_DETAILS, {
    fetchPolicy: "no-cache",
  });
