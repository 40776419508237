import { useAmwell, useAmwellQuery } from "/hook";

import { Variables } from "./useAmwellLocation.types";

const US_COUNTRY_CODE = "US";

/**
 * This hook allows for the retrieval of information pertianing to Amwells Location
 *
 * A countryCode parameter can be passed in that allows the searching per country.
 * Otherwise it does default to US.
 */
export const useAmwellLocation = (
  { countryCode }: Variables = { countryCode: US_COUNTRY_CODE },
) => {
  const { sdk, isReady } = useAmwell();
  const fetch = async () => {
    if (sdk && isReady) {
      const countries = await sdk.getCountries();
      return countries.find(({ code }) => code === countryCode);
    }

    return;
  };

  return useAmwellQuery(fetch, [countryCode]);
};

export default useAmwellLocation;
