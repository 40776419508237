// This code is from
// https://stackoverflow.com/questions/11415665/save-base64-string-as-pdf-at-client-side-with-javascript

const dataURItoBlob = (dataURI: string) => {
  const byteString = atob(dataURI);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([int8Array], { type: "application/pdf" });
  return blob;
};

/**
 * Open a base64 encoded blob as a pdf in a new tab
 */
export default (base64Blob: string) => {
  const blob = dataURItoBlob(base64Blob);
  const url = URL.createObjectURL(blob);

  // to open the PDF in a new window
  window.open(url, "_blank");
};
