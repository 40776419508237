import { gql, useQuery } from "@apollo/client";

import { GetAllAvailabilities, GetAllAvailabilitiesVariables } from "/apollo/schema/types";

import {
  ProviderAvailabilitiesListFragment,
  ProviderAvailabilitiesListItemFragment,
} from "../component/ProviderAvailabilitiesList";

const GET_AVAILABILITIES = gql`
  query GetAllAvailabilities(
    $after: String
    $daysOfWeek: [DayOfWeek!]
    $flowSessionId: ID
    $gender: ProviderGender
    $language: Language
    $maxStartTime: DateTime
    $minStartDate: DateTime
    $minStartTime: DateTime
    $geo: GeoCircleInput
    $querySelectedCalendarIds: Boolean!
    $selectedCalendarIds: [ID!]
    $calendarIds: [ID!]
  ) {
    selectedCalendarAvailabilities: availabilities(
      daysOfWeek: $daysOfWeek
      gender: null
      language: null
      maxStartTime: $maxStartTime
      minStartDate: $minStartDate
      minStartTime: $minStartTime
      geo: null
      firstAvailableDatePerCalendarOnly: true
      flowSessionId: $flowSessionId
      calendarIds: $selectedCalendarIds
    ) @include(if: $querySelectedCalendarIds) {
      pages {
        ...ProviderAvailabilitiesListItemFragment
      }
    }

    ...ProviderAvailabilitiesListFragment
  }

  ${ProviderAvailabilitiesListFragment}
  ${ProviderAvailabilitiesListItemFragment}
`;

export default (variables: GetAllAvailabilitiesVariables) => {
  return useQuery<GetAllAvailabilities, GetAllAvailabilitiesVariables>(GET_AVAILABILITIES, {
    variables,
  });
};
