import React from "react";

import caregiverAssign from "/asset/image/caregiver-assign.png";
import Button from "/component/base/Button";
import { useTranslation } from "/hook";

import {
  CaregiverIntroContainer,
  IMAGE_HEIGHT,
  IMAGE_WIDTH,
  SubtitleAbove,
  SubtitleBelow,
  Title,
} from "./CaregiverIntro.styles";
import { Props } from "./CaregiverIntro.types";

const CaregiverIntro = ({ onClick }: Props) => {
  const { t } = useTranslation("caregiverIntro");

  return (
    <CaregiverIntroContainer>
      <Title variant="title2" color="brandNavy">
        {t("title")}
      </Title>

      <SubtitleAbove variant="body1" color="textSecondary">
        {t("subtitleAbove")}
      </SubtitleAbove>

      <img
        src={caregiverAssign}
        width={IMAGE_WIDTH}
        height={IMAGE_HEIGHT}
        alt="caregiver Intro"
        aria-hidden
      />

      <SubtitleBelow variant="body1" color="textSecondary">
        {t("subtitleBelow")}
      </SubtitleBelow>

      <Button onClick={onClick} variant={"primary"}>
        {t("getStarted")}
      </Button>
    </CaregiverIntroContainer>
  );
};

export default CaregiverIntro;
