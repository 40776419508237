import React, { FC, PropsWithChildren } from "react";

import { Loading } from "/component/base";
import useWindowSize from "/hook/useWindowSize";

import LoadingCard from "../LoadingCard";
import LoadingListItem from "../LoadingListItem";
import { LoadingCardsWrapper, LoadingListItemsWrapper } from "./LoadingSkeleton.styles";

interface Props {
  skeletonVariant?: "card" | "list-item";
  animate?: boolean;
}

/**
 * LoadingSkeleton component is used to display a loading state for a section of the UI.
 * It supports two variants: "card" and "list-item". By default, it renders a set of
 * loading placeholders if no children are provided.
 *
 * If the viewport is mobile-sized, the loading spinner is used instead. This is due to styling
 * issues when the skeleton is used on mobile-sized screens.
 *
 * @param {"card" | "list-item"} [props.skeletonVariant="card"] - The variant of the skeleton to display.
 * @param {boolean} [props.animate=true] - Optional param to determine animation.
 * @param {ReactNode} [props.children] - Optional children to display instead of the default skeletons.
 * @returns {JSX.Element} The rendered LoadingSkeleton component.
 */
const LoadingSkeleton: FC<PropsWithChildren<Props>> = ({
  skeletonVariant = "card",
  animate = true,
  children,
}) => {
  const { isMobile } = useWindowSize();

  if (isMobile && animate) {
    return <Loading />;
  }

  if (skeletonVariant === "card") {
    return (
      <LoadingCardsWrapper aria-hidden>
        {children || (
          <>
            {[...Array(2)].map((_, index) => (
              <LoadingCard animate={animate} key={index} />
            ))}
          </>
        )}
      </LoadingCardsWrapper>
    );
  }

  return (
    <LoadingListItemsWrapper aria-hidden>
      {children || (
        <>
          {[...Array(6)].map((_, index) => (
            <LoadingListItem animate={animate} key={index} />
          ))}
        </>
      )}
    </LoadingListItemsWrapper>
  );
};

export default LoadingSkeleton;
