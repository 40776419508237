import styled from "styled-components";

import Text from "/component/base/Text";
import { layout } from "/styles";

export const EmptyStateContainer = styled.div(layout.flexVerticalCenter, ({ theme }) => ({
  maxWidth: 480,
  textAlign: "center",
  "& > button": {
    marginTop: `${theme.spacing.condensed}px`,
    width: "100%",
  },
}));

export const SubtitleText = styled(Text)(layout.padding("condensed", "gutter"));

export const imageSize = 220;
