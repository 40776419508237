import styled from "styled-components";

import Text from "/component/base/Text";
import { layout } from "/styles";

export const CustomLoaderContainer = styled.div(
  layout.absoluteFill,
  layout.flexVerticalCenter,
  layout.spacedChildrenVertical(),
);

export const Title = styled(Text).attrs({
  variant: "title1",
})(layout.margin(40, "skip", "skip"));

export const Subtitle = styled(Text).attrs({
  variant: "body1",
})(layout.margin("standard", "skip", "skip"));
