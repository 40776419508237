import { withAITracking } from "@microsoft/applicationinsights-react-js";
import isEmpty from "lodash/fp/isEmpty";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

import { usePharmacySearch } from "/b2c/query/usePharmacySearch.b2c";
import { PHARMACY_SEARCH_pharmacies } from "/b2c/schema/types";
import { Button, List, Loading, Text, TextInput } from "/component/base";
import { AlertCircleOpen, Cross, Search } from "/component/base/Icon";
import MainContent from "/component/layout/MainContent";
import PharmacyDetailModalB2C from "/component/modal/PharmacyDetailModalB2C";
import EmptyStateCta from "/component/partial/EmptyStateCta";
import PharmacyItem from "/component/partial/PharmacyItem";
import { useDebounce, useTranslation } from "/hook";
import { layout } from "/styles";
import { reactPlugin } from "/util/appInsights.util";

const PharmacySearch = () => {
  const { t } = useTranslation("pharmacySearch");
  const [pharmacySearch, setPharmacySearch] = useState("");
  const [selectedPharmacy, setSelectedPharmacy] = useState<PHARMACY_SEARCH_pharmacies | null>();
  const [isOpen, setIsOpen] = useState(false);
  const [getPharmacies, { data, loading }] = usePharmacySearch({});
  const debouncedSearchValue = useDebounce(pharmacySearch);

  const location = useLocation<{
    clinicalProviderId: number | undefined;
  }>();
  const { clinicalProviderId } = location.state || {};

  const handlePharmacyPress = (pharmacy: PHARMACY_SEARCH_pharmacies) => {
    setSelectedPharmacy(pharmacy);
    setIsOpen(true);
  };

  const handlePharmacyDetailClose = () => {
    setIsOpen(false);
    setSelectedPharmacy(null);
  };

  useEffect(() => {
    if (debouncedSearchValue) {
      getPharmacies({ variables: { search: debouncedSearchValue } });
    }
  }, [debouncedSearchValue]);

  const renderSearchResults = () => {
    return !isEmpty(pharmacySearch) && data && data.pharmacies && data.pharmacies?.length > 0 ? (
      <List
        as="Buttons"
        onClick={handlePharmacyPress}
        data={data.pharmacies}
        renderItem={(pharmacy) => (
          <PharmacyItem
            name={pharmacy.name}
            address={`${pharmacy.address}, ${pharmacy.city}, ${pharmacy.state} ${pharmacy.zip}`}
          />
        )}
        chevronVariant="grey"
      />
    ) : (
      <div css={layout.flexVerticalCenter}>
        <div css={layout.marginVertical(80)}>
          <EmptyStateCta
            icon={<AlertCircleOpen color="brandPrimary" size={48} />}
            title={t("noResults.title")}
            subtitle={t("noResults.subtitle")}
          />
        </div>
      </div>
    );
  };

  const onPressClearSearch = () => {
    setPharmacySearch("");
  };

  return (
    <MainContent parentPage="myHealth" pageTitle={t("title.selectPharmacy")}>
      <Helmet>
        <title>{t("title.selectPharmacy")}</title>
      </Helmet>

      <TextInput
        aria-label={t("label.search")}
        before={<Search />}
        after={
          pharmacySearch && (
            <Button variant="unstyled" onClick={onPressClearSearch}>
              <Cross css={layout.flexVerticalCenter} color="textSecondary" />
            </Button>
          )
        }
        css={layout.margin("skip", "skip", "expanded")}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setPharmacySearch(e.currentTarget.value);
        }}
        placeholder={t("placeholder.search")}
        value={pharmacySearch}
      />

      {loading ? (
        <Loading />
      ) : isEmpty(pharmacySearch) ? (
        <Text color="textSecondary" css={[layout.flexCenter, layout.marginVertical(80)]}>
          {t("searchPharmacy")}
        </Text>
      ) : (
        renderSearchResults()
      )}
      {selectedPharmacy && (
        <PharmacyDetailModalB2C
          preferredPharmacyClinicalProviderId={clinicalProviderId}
          pharmacy={selectedPharmacy}
          isOpen={isOpen}
          close={handlePharmacyDetailClose}
          variant={"search"}
        />
      )}
    </MainContent>
  );
};

export default withAITracking(reactPlugin, PharmacySearch, undefined, "ai-tracking");
