import { gql, useQuery } from "@apollo/client";

import { ContactSupportQuery } from "/apollo/schema";

export const contactSupportFragment = gql`
  fragment contactSupportFragment on ContactInfo {
    name
    phoneNumber
    faxNumber
    emailAddress
  }
`;

export const contactSupportQuery = gql`
  ${contactSupportFragment}
  query ContactSupportQuery {
    contactInfo {
      ...contactSupportFragment
    }
  }
`;

export const useMessageQuery = () => {
  const result = useQuery<ContactSupportQuery>(contactSupportQuery);

  const contactByName = (name: string) =>
    result.data?.contactInfo.find((contact) => contact.name === name);

  return { ...result, contactByName };
};
