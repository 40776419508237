import styled from "styled-components";

import { card, layout } from "/styles";

export const InfoListItem = styled.div(
  layout.flexSpaceHorizontal,
  layout.padding("standard", "skip"),
  () => ({
    width: "100%",
  }),
);

export const ListContainer = styled.div(card.wrapper);
