import { MutationHookOptions, useMutation } from "@apollo/client";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import CANCEL_DECISION_SUPPORT from "/apollo/mutation/abandonDecisionSupport";
import CONTINUE_DECISION_SUPPORT from "/apollo/mutation/continueDecisionSupport";
import PREVIOUS_DECISION_SUPPORT from "/apollo/mutation/previousDecisionSupport";
import START_DECISION_SUPPORT from "/apollo/mutation/startDecisionSupport";
import {
  AbandonDecisionSupport,
  AbandonDecisionSupportVariables,
  ContinueDecisionSupport,
  ContinueDecisionSupportVariables,
  DSValueType,
  PreviousDecisionSupport,
  PreviousDecisionSupportVariables,
  StartDecisionSupport,
  StartDecisionSupportVariables,
} from "/apollo/schema/types";

export const useStartDecisionSupport = (
  options: MutationHookOptions<StartDecisionSupport, StartDecisionSupportVariables>,
) =>
  useMutation<StartDecisionSupport, StartDecisionSupportVariables>(START_DECISION_SUPPORT, options);

export const useContinueDecisionSupport = (
  options: MutationHookOptions<ContinueDecisionSupport, ContinueDecisionSupportVariables>,
) =>
  useMutation<ContinueDecisionSupport, ContinueDecisionSupportVariables>(
    CONTINUE_DECISION_SUPPORT,
    options,
  );

export const usePreviousDecisionSupport = (
  options: MutationHookOptions<PreviousDecisionSupport, PreviousDecisionSupportVariables>,
) =>
  useMutation<PreviousDecisionSupport, PreviousDecisionSupportVariables>(
    PREVIOUS_DECISION_SUPPORT,
    options,
  );

export const useAbandonDecisionSupport = (
  options: MutationHookOptions<AbandonDecisionSupport, AbandonDecisionSupportVariables>,
) =>
  useMutation<AbandonDecisionSupport, AbandonDecisionSupportVariables>(
    CANCEL_DECISION_SUPPORT,
    options,
  );

/**
 * Send an abandon signal to the backend for analytics tracking.
 * A user is considered to have abandon DS if they leave the flow
 * session without being kicked out or completing.
 */
export const useAbandonOnRouteChange = (
  flowSessionId: string | null | undefined,
  isKickedOut: boolean,
  isFlowFinished: boolean,
) => {
  const history = useHistory();
  const [abandonDS] = useAbandonDecisionSupport({});

  useEffect(() => {
    return history.listen(() => {
      if (!(isKickedOut || isFlowFinished) && flowSessionId) {
        // At the time of writing, the abandon api has not beeng implemented
        // by experian, so this call may return an error to the console.
        abandonDS({ variables: { flowSessionId } });
      }
    });
  }, [flowSessionId, isKickedOut, isFlowFinished]);
};

export const getStartingFields = (athenaId?: string | null, fromSearch = false) =>
  athenaId
    ? [
        {
          fieldName: "SelectedProviderNpi",
          currentValue: athenaId,
          valueType: DSValueType.STRING,
        },
        {
          fieldName: "IsEstablishedPatient",
          currentValue: fromSearch ? null : "true",
          valueType: DSValueType.BOOL,
        },
      ]
    : [];
