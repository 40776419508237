import styled from "styled-components";

import { layout } from "/styles";

export const CaregiverDetailContainer = styled.div(
  layout.padding("standard", "condensed", "standard", "none"),
  { display: "flex", justifyContent: "space-between", flex: 1 },
);

export const BottonContainer = styled.div(
  layout.padding("standard", "skip", "skip", "skip"),
  layout.flexStart,
);
