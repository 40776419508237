import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Button, Modal } from "/component/base";
import { layout } from "/styles";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  logEvent,
  ScreenViewParams,
} from "/util/firebase.util";

import { FormContentContainer, SubTitle, Title } from "./CaregiverEndModal.styles";
import { Props } from "./CaregiverEndModal.types";

const CaregiverEndModal = ({
  title,
  subtitle,
  imageSource,
  isOpen,
  close,
  caregiverType,
}: Props) => {
  const { t } = useTranslation("modal-caregiverEndModal");

  useEffect(() => {
    logEvent(AnalyticsEvent.SCREEN_VIEW, {
      screenName: AnalyticsSource.CAREGIVER_ACCESS_END,
    } as ScreenViewParams);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      footer={
        <Modal.FooterWithLargeButton>
          <FormContentContainer.ButtonGroup>
            <Button
              variant="primary"
              onClick={() => {
                logEvent(AnalyticsEvent.BUTTON_CLICK, {
                  user_flow: AnalyticsUserFlow.CAREGIVER,
                  source: AnalyticsSource.CAREGIVER_ACCESS_END,
                  button_name: "Finish",
                  button_description: caregiverType,
                } as ButtonClickParams);
                close && close();
              }}
            >
              {t("finish")}
            </Button>
          </FormContentContainer.ButtonGroup>
        </Modal.FooterWithLargeButton>
      }
    >
      <div css={[layout.flexVerticalCenter, layout.spacedChildrenVertical("expanded")]}>
        <Title>{title}</Title>
        <img width={375} src={imageSource} alt="Caregiver complete image" aria-hidden />
        <SubTitle>{subtitle}</SubTitle>
      </div>
    </Modal>
  );
};

export default CaregiverEndModal;
