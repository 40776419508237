import styled from "styled-components";

import ctaCardBg from "/asset/image/ctaCardBackground.svg";
import { card, layout } from "/styles";

export const Base = styled.aside(card.base, layout.padding("gutter", "gutter", "expanded"), {
  width: "100%",
  backgroundImage: `url(${ctaCardBg})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "right 0px bottom 12px",
});

export const Links = styled.ul(
  layout.flexVertical,
  layout.spacedChildrenVertical("standard"),
  ({ theme }) => ({
    width: "100%",
    maxWidth: theme.spacing.base * 70,
  }),
);
