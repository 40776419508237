import { ApolloError, gql } from "@apollo/client";

import { useB2CQuery } from "../client";
import {
  GET_IMAGING_RESULTS,
  GET_IMAGING_RESULTS_patient_imagingResults_items,
  GET_LABS,
  GET_LABS_patient_labResults_items,
} from "../schema/types";

export type TestResult = {
  id: number;
  description: string;
  date: string;
  type: TestResultType;
};

export enum TestResultType {
  LAB = "lab",
  IMAGING = "imaging",
}

export const labResultsQuery = gql`
  query GET_LABS {
    patient {
      labResults {
        items {
          description
          labResultId
          portalPublishedDatetime
        }
      }
    }
  }
`;

export const imagingResultsQuery = gql`
  query GET_IMAGING_RESULTS {
    patient {
      imagingResults {
        items {
          imagingResultId
          documentDescription
          createdDatetime
        }
      }
    }
  }
`;

const useTestResults = (): {
  error: ApolloError | undefined;
  loading: boolean;
  data: TestResult[] | null | undefined;
} => {
  const {
    data: imagingResultsData,
    error: imagingResultsError,
    loading: imagingResultsLoading,
  } = useB2CQuery<GET_IMAGING_RESULTS>(imagingResultsQuery);

  const {
    data: labResultsData,
    error: labResultsError,
    loading: labResultsLoading,
  } = useB2CQuery<GET_LABS>(labResultsQuery);

  const data = mergeAndSortResults(
    labResultsData?.patient.labResults?.items ?? [],
    imagingResultsData?.patient.imagingResults?.items ?? [],
  );

  const error = labResultsError || imagingResultsError;
  const loading = labResultsLoading || imagingResultsLoading;

  return {
    error,
    loading,
    data: data,
  };
};

const mergeAndSortResults = (
  labResultsItems: GET_LABS_patient_labResults_items[],
  imagingResultsItems: GET_IMAGING_RESULTS_patient_imagingResults_items[],
): TestResult[] => {
  const labResults: TestResult[] = labResultsItems.map((item) => {
    return {
      id: item.labResultId,
      description: item.description || "",
      date: item.portalPublishedDatetime,
      type: TestResultType.LAB,
    };
  });

  const imagingResults: TestResult[] = imagingResultsItems
    .map((item) => {
      if (item.imagingResultId !== null && item.documentDescription !== null) {
        return {
          id: item.imagingResultId,
          description: item.documentDescription,
          date: item.createdDatetime,
          type: TestResultType.IMAGING,
        };
      } else {
        return undefined;
      }
    })
    .filter((item): item is TestResult => item !== undefined);

  return [...labResults, ...imagingResults]
    .filter((item) => item.date !== null)
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
};

export default useTestResults;
