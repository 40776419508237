import libphonenumber from "google-libphonenumber";

// Note: you can pass in a more complex object than just a {firstName, lastName}. For example
// you may pass in: a complete `AWSDKProvider` or GraphQL `Provider.name`, both of which contain
// lastName and firstName fields along with other fields.
type Name = {
  firstName: string | null;
  lastName: string | null;
};

/**
 * Given an object that contains `firstName` and `lastName` fields return a string of the initials: "PD".
 */
export const parseInitials = (name: Name | undefined | null) => {
  if (!name) return "";
  const firstInitial = name.firstName?.[0] || "";
  const lastInitial = name.lastName?.[0] || "";

  return [firstInitial, lastInitial].join("");
};

/**
 * A very simple formatter that expects a 10 or 11 digit string, generally
 * provided by a `contactInfo` query, and returns it as a readable phone number,
 * with proper hyphenation and spacing.
 */
export const parsePhone = (phone: string | null | undefined): string | null | undefined => {
  if (phone?.length === 11) {
    return phone?.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "$1 ($2) $3-$4");
  }

  if (phone?.length === 10) {
    return phone?.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }

  return phone;
};

export const formatPhone = (phone: string | null | undefined): string | null | undefined => {
  if (!phone) return null;
  const matchPhone = phone.match(/(\+1)(\d{3})(\d{3})(\d{4})/);

  //Original input = matchPhone[0]
  //Remove the original input
  matchPhone && matchPhone.shift();
  return matchPhone ? `${matchPhone.shift()} ${matchPhone?.join("-")}` : phone;
};

export const formatPhoneNumber = (phoneNumber?: string | null): string | null => {
  const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
  try {
    const number = phoneNumber ? phoneUtil.parse(phoneNumber, "US") : null;
    const formattedPhoneNumber =
      number && phoneUtil.isValidNumber(number)
        ? phoneUtil.formatInOriginalFormat(number, "US")
        : null;
    return formattedPhoneNumber;
  } catch (ex) {
    return null;
  }
};

export const formatPhoneNumberE164 = (phoneNumber?: string | null): string | null => {
  const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
  const { E164 } = libphonenumber.PhoneNumberFormat;
  try {
    const number = phoneNumber ? phoneUtil.parse(phoneNumber, "US") : null;
    const formattedPhoneNumber =
      number && phoneUtil.isValidNumber(number) ? phoneUtil.format(number, E164) : null;
    return formattedPhoneNumber;
  } catch (ex) {
    return null;
  }
};

type TelehealthAddress = {
  address1: string;
  city: string;
  stateCode: string;
  postalCode?: string;
};

/**
 * Given an object that contains `address1`, `city`, `stateCode`, `postalCode` (optional) fields return comma seperated address.
 */
export const formatTelehealthAddress = (address?: TelehealthAddress): string => {
  if (address) {
    const street = address.address1;
    const city = address.city;
    const state = address.stateCode;
    const postalCode = address.postalCode;
    return [street, city, state, postalCode].filter(Boolean).join(", ");
  }
  return "";
};

/**
 * Format an address returned from our GraphQL API
 */
export type Address = {
  line1?: string | null;
  line2?: string | null;
  city?: string | null;
  state?: string | null;
  postalCode?: string | null;
};

export const formatAddress = (address?: Address | null) => {
  if (!address) return "";

  return [address.line1, address.line2, address.city, address.state, address.postalCode]
    .filter(Boolean)
    .join(", ");
};
