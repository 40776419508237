import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import caregiverAccessConfirmationImage from "/asset/image/caregiver-access-confirmation.png";
import { Button, Modal } from "/component/base";
import { ModalProps } from "/component/base/Modal";
import Text from "/component/base/Text";
import { CaregiverNotificationForm } from "/component/form/CaregiverForms";
import { AnalyticsEvent, AnalyticsSource, logEvent, ScreenViewParams } from "/util/firebase.util";

import {
  ConfirmationContainer,
  PreferenceContainer,
} from "./CaregiverAccessConfirmationModal.styles";

const IMAGE_WIDTH = 375;
const IMAGE_HEIGHT = 278;

const CaregiverAccessConfirmationModal = ({
  isOpen,
  close,
  name,
}: ModalProps & { name: string }) => {
  const { t } = useTranslation("modal-caregiverAccessConfirmationModal");

  const [showPreferences, setShowPreferences] = useState(false);

  useEffect(() => {
    logEvent(AnalyticsEvent.SCREEN_VIEW, {
      screenName: AnalyticsSource.CAREGIVER_INVITE_ACCEPT,
    } as ScreenViewParams);
  }, []);

  useEffect(() => {
    if (showPreferences)
      logEvent(AnalyticsEvent.SCREEN_VIEW, {
        screenName: AnalyticsSource.CAREGIVER_NOTIFICATION_SETTING,
      } as ScreenViewParams);
  }, [showPreferences]);

  const getPreferenceBody = () => (
    <PreferenceContainer>
      <Text variant="title1" color="textNavyBlue">
        {t("preferences.title")}
      </Text>
      <CaregiverNotificationForm />
    </PreferenceContainer>
  );

  const handleSubmit = () => {
    close && close();
    setShowPreferences(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      footer={
        <Modal.FooterWithLargeButton>
          <Button
            fullWidth="percent"
            onClick={() => {
              showPreferences ? handleSubmit() : setShowPreferences(true);
            }}
          >
            {showPreferences ? t("finish") : t("continue")}
          </Button>
        </Modal.FooterWithLargeButton>
      }
    >
      {showPreferences ? (
        getPreferenceBody()
      ) : (
        <ConfirmationContainer>
          <img
            height={IMAGE_HEIGHT}
            width={IMAGE_WIDTH}
            src={caregiverAccessConfirmationImage}
            alt={t("confirmationImage")}
          />
          <Text variant="title1" color="textNavyBlue">
            {t("title")}
          </Text>

          <Text color="textSecondary" css={{ textAlign: "center" }}>
            <Trans
              components={{ bold: <Text color="textSecondary" variant="body2Bold" /> }}
              i18nKey="caregiverMessage"
              values={{ name }}
              t={t}
            />
          </Text>
        </ConfirmationContainer>
      )}
    </Modal>
  );
};

export default CaregiverAccessConfirmationModal;
