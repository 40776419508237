// Modules
import React from "react";

// Styles
import { Separator } from "./Divider.styles";
import { Props } from "./Divider.types";

const Divider = ({ className, ariaHidden }: Props) => (
  <Separator className={className} aria-hidden={ariaHidden} />
);

export default Divider;
