import styled from "styled-components";

import PickerField from "/component/partial/formik/PickerField";
import { layout } from "/styles";

export const StatePicker = styled(PickerField)({
  flex: `0 0 100px`,
  "& > button": {
    width: "100%",
  },
});

export const HiddenButton = styled.button({ visibility: "hidden" });

export const CheckBoxContainer = styled.div(
  layout.flexVertical,
  layout.spacedChildrenVertical("standard"),
  layout.margin("gutter", "skip", "condensed"),
);

export const AddressDiv = styled.div(layout.padding("base", "skip", "skip"));
