import React from "react";

import { ContentEditable } from "./EditableMessage.styles";
import { Props } from "./EditableMessage.types";

const EditableMessage = ({ html, onChange, ...otherProps }: Props) => (
  <ContentEditable
    html={html?.replace("#msgHorizontalDivider", "<hr/>") || ""}
    onChange={onChange || (() => undefined)}
    {...otherProps}
  />
);

export default EditableMessage;
