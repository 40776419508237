import React from "react";

import { Text } from "/component/base";
import { date as dateUtil } from "/util";

import { Props } from "./SectionHeader.types";

const SectionHeader = ({ date, className }: Props) => (
  <Text className={className} variant="body2Bold" color="textSecondary">
    {dateUtil.formatDate(date, "EEEE, MMMM d")}
  </Text>
);

export default SectionHeader;
