import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import {
  GET_MEDICATIONS_patient_activeMedications,
  GET_MEDICATIONS_patient_historicalMedications,
  MedicationType,
} from "/b2c/schema/types";
import { Button, Divider, ListItem, Text } from "/component/base";
import MainContent from "/component/layout/MainContent";
import PharmacyDetailModalB2C from "/component/modal/PharmacyDetailModalB2C";
import TransferPrescriptionModal from "/component/modal/TransferPrescriptionsModal";
import { useAnalytics } from "/hook/useAnalytics/useAnalytics";
import { layout } from "/styles";
import { reactPlugin } from "/util/appInsights.util";

import MedicationProviderNote from "./components/MedicationProviderNote";
import { InactiveWrapper, InstructionsWrapper, SectionWrapper } from "./MedicationB2C.styles";

const Medication = () => {
  const { t } = useTranslation("medication");
  const { recordAnalyticsEvent } = useAnalytics();

  useEffect(() => {
    recordAnalyticsEvent("viewIndividualMedication");
  }, [recordAnalyticsEvent]);

  const location = useLocation<{
    medication:
      | GET_MEDICATIONS_patient_activeMedications
      | GET_MEDICATIONS_patient_historicalMedications;
  }>();
  const { medication } = location.state || {};

  const pharmacy = medication.pharmacyFacility;

  const pageTitle = medication.medicationName ? medication.medicationName : "Medication";

  const [isTransferPrescriptionsModalOpen, setTransferPrescriptionsModalOpen] = useState(false);
  const [isPharmacyDetailModalOpen, setPharmacyDetailModalOpen] = useState(false);

  const onPressPharmacy = () => {
    recordAnalyticsEvent("medicationPharmacy");
    setPharmacyDetailModalOpen(true);
  };

  const onPressTransferPrescription = () => {
    setTransferPrescriptionsModalOpen(true);
  };

  const getPharmacy = () => {
    if (pharmacy) {
      return (
        <div>
          <ListItem.Button
            css={layout.margin("skip", "skip", "standard", "skip")}
            chevronVariant="grey"
            onClick={onPressPharmacy}
          >
            <div css={layout.flexVertical}>
              <Text variant="body1Bold">{pharmacy.name}</Text>
              <Text color="textSecondary" variant="body2">
                {pharmacy.address}
              </Text>
              <Text
                color="textSecondary"
                variant="body2"
              >{`${pharmacy.city}, ${pharmacy.state} ${pharmacy.zip}`}</Text>
            </div>
          </ListItem.Button>
          <Divider />
          <Button
            css={layout.margin("standard", "skip", "skip", "skip")}
            variant="borderBottom"
            onClick={onPressTransferPrescription}
          >
            {t("transferPrescription")}
          </Button>
        </div>
      );
    }

    return <Text variant="body1">{t("noAssociatedPharmacy")}</Text>;
  };

  return (
    <MainContent parentPage="prescriptions" pageTitle={pageTitle}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div css={layout.flexVertical}>
        {medication.quantity && medication.quantityUnit && (
          <Text color="textSecondary">{`${medication.quantity} ${medication.quantityUnit}(s)`}</Text>
        )}
        {medication.medicationType === MedicationType.HISTORICAL && (
          <InactiveWrapper>
            <Text variant="body2Bold">{t("inactive")}</Text>
          </InactiveWrapper>
        )}
      </div>
      {medication.unstructuredSig && (
        <SectionWrapper>
          <Text variant="body1Bold">{t("instructions")}</Text>
          {medication.patientNote ? (
            <Text>{medication.unstructuredSig}</Text>
          ) : (
            <InstructionsWrapper>
              <Text>{medication.unstructuredSig}</Text>
            </InstructionsWrapper>
          )}
        </SectionWrapper>
      )}
      {medication.patientNote && (
        <SectionWrapper>
          <MedicationProviderNote note={medication.patientNote} />
        </SectionWrapper>
      )}
      <SectionWrapper>
        <Text variant="body1Bold">{t("pharmacy")}</Text>
        {getPharmacy()}
      </SectionWrapper>
      <SectionWrapper>
        <Text variant="body1Bold">{t("refills.title")}</Text>
        <Text>{t("refills.description")}</Text>
      </SectionWrapper>
      <TransferPrescriptionModal
        isOpen={isTransferPrescriptionsModalOpen}
        close={() => setTransferPrescriptionsModalOpen(false)}
      />
      {pharmacy && (
        <PharmacyDetailModalB2C
          pharmacy={pharmacy}
          isOpen={isPharmacyDetailModalOpen}
          close={() => setPharmacyDetailModalOpen(false)}
          variant={"medication"}
        />
      )}
    </MainContent>
  );
};

export default withAITracking(reactPlugin, Medication, undefined, "ai-tracking");
