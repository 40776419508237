import { gql } from "@apollo/client";

export default gql`
  mutation createCedarPaymentSchedule(
    $mrn: String!
    $org: ScmdOrganization!
    $installmentAmount: String!
    $totalAmount: String!
    $paymentMethodId: String!
    $savePaymentMethod: Boolean!
    $dateOfFirstPayment: DateTime!
    $frequency: PaymentScheduleFrequency!
  ) {
    createCedarPaymentSchedule(
      data: {
        mrn: $mrn
        org: $org
        paymentSchedule: {
          installmentAmount: $installmentAmount
          totalAmount: $totalAmount
          paymentMethodId: $paymentMethodId
          paymentMethodType: CREDIT_CARD
          savePaymentMethod: $savePaymentMethod
          dateOfFirstPayment: $dateOfFirstPayment
          frequency: $frequency
        }
      }
    ) {
      errors {
        code
        message
      }
      paymentSchedule {
        publicId
      }
    }
  }
`;
