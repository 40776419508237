import styled from "styled-components";

import { card, layout } from "/styles";

export const SectionWrapper = styled.div(
  layout.flexVertical,
  layout.margin("gutter", "skip", "skip", "skip"),
  layout.spacedChildrenVertical(12),
);

export const InactiveWrapper = styled.div(
  layout.flexStartHorizontal,
  layout.padding("base", "condensed", "base", "condensed"),
  layout.margin("standard", "skip", "skip", "skip"),
  ({ theme }) => ({
    backgroundColor: theme.colors.backgroundSurfaceActive,
    alignSelf: "flex-start",
    borderRadius: 12,
  }),
);

export const InstructionsWrapper = styled.div(
  card.base,
  layout.padding("standard"),
  ({ theme }) => ({
    backgroundColor: theme.colors.brandPrimaryLight,
  }),
);
