import { ApolloError, gql } from "@apollo/client";

import { useB2CQuery } from "../client";
import {
  GET_IMAGING_DETAILS,
  GET_IMAGING_DETAILS_patient_imagingResult,
  GET_LAB_DETAILS,
  GET_LAB_DETAILS_patient_labResult,
} from "../schema/types";
import { TestResult, TestResultType } from "./useTestResults.b2c";

export const labDetailsQuery = gql`
  query GET_LAB_DETAILS($labResultId: Int!) {
    patient {
      labResult(labResultId: $labResultId) {
        description
        labResultDatetime
        labResultId
        patientNote
        portalPublishedDatetime
        analytes {
          abnormalFlag
          analyteName
          note
          referenceRange
          units
          value
        }
        pages {
          contentType
          pageId
          pageOrdering
        }
        provider {
          displayName
          firstName
          lastName
          specialty
          imageUrl
        }
        performingLabName
        performingLabAddress1
        performingLabCity
        performingLabState
        performingLabZip
        status
        department {
          brand
        }
      }
    }
  }
`;

export const imagingDetailsQuery = gql`
  query GET_IMAGING_DETAILS($imagingResultId: Int!) {
    patient {
      imagingResult(imagingResultId: $imagingResultId) {
        createdDatetime
        createdUser
        description
        documentData
        imagingResultId
        portalPublishedDatetime
        providerUsername
        patientNote
        status
        provider {
          displayName
          firstName
          lastName
          specialty
          imageUrl
        }
        pages {
          pageOrdering
          pageId
          contentType
        }
        performingFacility {
          name
          address
          city
          state
          zipCode
        }
      }
    }
  }
`;

const useTestResultDetails = (
  testResult: TestResult,
): {
  error: ApolloError | undefined;
  loading: boolean;
  data:
    | GET_LAB_DETAILS_patient_labResult
    | GET_IMAGING_DETAILS_patient_imagingResult
    | null
    | undefined;
} => {
  const {
    data: labResultDetails,
    error: labResultError,
    loading: labResultLoading,
  } = useB2CQuery<GET_LAB_DETAILS>(labDetailsQuery, {
    variables: { labResultId: testResult.id },
    skip: testResult.type !== TestResultType.LAB,
  });

  const {
    data: imagingResultDetails,
    error: imagingResultError,
    loading: imagingResultLoading,
  } = useB2CQuery<GET_IMAGING_DETAILS>(imagingDetailsQuery, {
    variables: { imagingResultId: testResult.id },
    skip: testResult.type !== TestResultType.IMAGING,
  });

  const error = labResultError || imagingResultError;
  const loading = labResultLoading || imagingResultLoading;

  const testResultDetails =
    testResult.type === TestResultType.LAB
      ? labResultDetails?.patient.labResult
      : imagingResultDetails?.patient.imagingResult;

  return {
    error,
    loading,
    data: testResultDetails,
  };
};

export default useTestResultDetails;
