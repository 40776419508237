import styled from "styled-components";

import { layout } from "/styles";

export const PasswordValidationContainer = styled.div(layout.spacedChildrenVertical());

export const ErrorListContainer = styled.ul(
  layout.spacedChildrenVertical("condensed"),
  ({ theme }) => ({
    listStyle: "none",
    paddingLeft: theme.spacing.standard,
  }),
);
