/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useReactiveVar } from "@apollo/client";
import * as msal from "@azure/msal-browser";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { isEqual } from "lodash";
import { useEffect, useRef } from "react";

import { auth as authVar } from "/apollo/client/local";
import { useToastContext } from "/component/provider/ToastProvider";
import { auth } from "/util";
import { setUserId, setUserProperty } from "/util/firebase.util";

import useTranslation from "./useTranslation";

const ERROR_CODE_CANCELLED_FLOW = "AADB2C90091";
const ERROR_CODE_PASSWORD_RESET_REQUESTED = "AADB2C90118";
const SIGN_UP_V2_ENABLED = process.env.SIGN_UP_V2_ENABLED === "true";
interface AccountInfo extends Omit<msal.AccountInfo, "idTokenClaims"> {
  idTokenClaims: {
    email: string;
    linkedPhoneNumber: string;
  };
}

/**
 * The useAuth hook will attempt to log the user in if they have a previous session. It returns
 * utilities such as login and logout functions, as well as values that tell us the current state of
 * the user's authentication.
 *
 * @param shouldTryLogin Some components may want to use parts of the `useAuth` hook without actually
 * trying to authenticate. Only one mounted instance of `shouldTryLogin` should ever try to login,
 * at the moment the only case where we want to try to login is from `useAppInit`
 *
 * @returns data
 */
const useAuth = (shouldTryLogin = false) => {
  const { t } = useTranslation("authentication");

  // The `accounts` reference returned from `instance.getAllAccounts()` is triggering a lot of updates
  // even though the value of the accounts doesn't change. We will store the accounts in a ref and
  // use `_.isEqual` to do a deep comparison on the accounts array when it triggers `useEffect` to run
  // to avoid unnecessay updates.
  const accountRef = useRef<msal.AccountInfo[] | null>(null);

  // We want to show toast messages when certain actions (password reset, email update, etc) succeed or fail.
  const { showToast } = useToastContext();

  // Give us direct access to the MSAL client
  const { accounts, inProgress, instance } = useMsal();
  const account = accounts[0] as AccountInfo;

  // We won't prompt the use to login unless we explicitly need to. By using Silent here we are
  // saying that by default we will try logging in by checking the app's session for an auth token first
  const {
    result,
    login: baseLogin,
    error,
  } = useMsalAuthentication(msal.InteractionType.Silent, {
    scopes: auth.scopes,
    // If the user gets into a strange state where they have more than one access token in localstorage
    // this login hint helps msal login to the correct account
    loginHint: account?.idTokenClaims.email,
  });

  // Use a ref here similarly to accountRef so that we limit the amount of updates we send to Firebase
  const { isAuthenticated, loading } = useReactiveVar(authVar.var);
  const isAuthenticatedRef = useRef<boolean>(isAuthenticated);

  // const { login: baseLoginNoMfa } = useMsalAuthentication(
  //   msal.InteractionType.Silent,
  //   auth.policies.authorities.signUpSignIn,
  // );

  const loginNoMFA = (redirectStartPage?: string) => {
    const options = {
      scopes: auth.scopes,
    };

    // I'm being really defensive checking the type of `redirectStartPage` because depending on how
    // types are defined, if you pass the `login` function directly into a button onClick you will get
    // the button event as the argument, which prevents users from logging in.
    if (redirectStartPage && typeof redirectStartPage === "string") {
      options["redirectStartPage"] = redirectStartPage;
    }
    const authority = auth.policies.authorities.signUpSignIn;

    //return //baseLoginNoMfa(msal.InteractionType.Redirect, options);
    return instance.loginRedirect({
      ...authority,
      redirectStartPage,
    });
  };

  /**
   * login by redirecting the user to the B2C login page.
   *
   * If the `redirectStartPage` is provided that will be the page that the user is directed back to
   * after logging in. By default MSAL will take the user back to the page they were on when the
   * login request was intiated.
   *
   * **Note:** `redirectStartPage` should be the full URL, not a relative react-router path. Eg:
   * http://localhost:8080/availabilities/confirm
   */
  const login = (redirectStartPage?: string) => {
    const options = {
      scopes: auth.scopes,
    };

    // I'm being really defensive checking the type of `redirectStartPage` because depending on how
    // types are defined, if you pass the `login` function directly into a button onClick you will get
    // the button event as the argument, which prevents users from logging in.
    if (redirectStartPage && typeof redirectStartPage === "string") {
      options["redirectStartPage"] = redirectStartPage;
    }

    return baseLogin(msal.InteractionType.Redirect, options);
  };

  /**
   * login by showing the B2C popup over top of the app. No redirect will happen and as such no
   * local state will be lost.
   */
  const loginWithPopup = () => {
    baseLogin(msal.InteractionType.Popup, { scopes: auth.scopes });
  };

  /**
   * Clear the user's auth token and remove their auth information from the local cache (via MSAL)
   */
  const logout = async () => {
    await instance.logoutPopup();
    authVar.setToken(null);
  };

  /**
   * Triggers the "Forgot Password" flow in a popup.
   */
  const forgotPassword = async () => {
    await triggerPopup(
      "forgotPassword",
      t("forgotPasswordSuccessMessage"),
      t("forgotPasswordErrorMessage"),
    );
  };

  /**
   * Triggers the "change phone number" flow in a popup.
   */
  const changePhoneNumber = async () => {
    const response = await triggerPopup(
      "changePhoneNumber",
      t("phoneNumberChangeSuccessMessage"),
      t("phoneNumberChangeErrorMessage"),
    );

    let newPhoneNumber = undefined;
    if (response?.idTokenClaims) {
      const claims = response.account as AccountInfo;
      newPhoneNumber = claims.idTokenClaims.linkedPhoneNumber;
    }
    return newPhoneNumber;
  };

  const changeEmail = async () => {
    const response = await triggerPopup(
      "changeEmail",
      t("emailChangeSuccessMessage"),
      t("emailChangeErrorMessage"),
    );
    const newEmail = (response?.account as AccountInfo)?.idTokenClaims?.email;
    return newEmail;
  };

  const triggerPopup = async (
    authorityName: keyof typeof auth.policies.names,
    successMessageString: string,
    errorMessageString: string,
  ) => {
    const authority = auth.policies.authorities[authorityName];
    const policy = auth.policies.names[authorityName]!.toLocaleLowerCase();

    try {
      const res = await instance.loginPopup(authority);
      const acr = (res.idTokenClaims["acr"] as string).toLocaleLowerCase();
      if (acr === policy) {
        showToast({
          icon: "checkmark",
          message: successMessageString,
        });
        return res;
      } else {
        throw new Error("Policy not part of token claims.");
      }
    } catch (e: any) {
      const { errorCode, errorMessage } = e;
      const userCancelledFlow =
        (errorMessage && errorMessage.indexOf(ERROR_CODE_CANCELLED_FLOW) > -1) ||
        errorCode === "user_cancelled";
      if (!userCancelledFlow) {
        showToast({
          message: errorMessageString,
          type: "error",
        });
      }
      return null;
    }
    return null;
  };

  // Redirect password flow only used when users click the forgot password link
  // in the login screen.
  const forgotPasswordRedirect = async () => {
    const authority = auth.policies.authorities["forgotPassword"];
    instance.loginRedirect(authority);
  };

  // When the user returns from the password reset redirect we can inspect the
  // result to determine where they came from.
  useEffect(() => {
    if (
      shouldTryLogin &&
      result &&
      result.authority.indexOf(auth.policies.names["forgotPassword"]!.toLocaleLowerCase()) > -1
    ) {
      showToast({
        icon: "checkmark",
        message: t("forgotPasswordSuccessMessage"),
      });
    }
  }, [result]);

  useEffect(() => {
    if (
      error &&
      error.errorMessage &&
      error?.errorMessage?.indexOf(ERROR_CODE_PASSWORD_RESET_REQUESTED) > -1
    ) {
      // If the user clicks the forgot password link they are directed back to the application
      // with a specific error code. We can check for that here and start the password reset flow.
      forgotPasswordRedirect();
    } else if (
      shouldTryLogin &&
      error &&
      error.errorMessage &&
      error.errorMessage.indexOf(ERROR_CODE_CANCELLED_FLOW) > -1
    ) {
      // If the user returns to the application with an error message from msal we can't be sure which flow
      // they were going through so we display a general error message.
      showToast({
        message: t("generalUpdateErrorMessage"),
        type: "error",
      });
      authVar.setLoading(false);
    } else if (error) {
      // If a different error occurs just turn off the loader
      authVar.setLoading(false);
    }
  }, [error]);

  /**
   * Silent login will check the application cache to see if there a token exists. If it finds a token,
   * which has expired, it will try to refresh it
   */
  const acquireTokenSilent = async (msalInstance: msal.IPublicClientApplication) => {
    let response: msal.AuthenticationResult;
    try {
      // Attempt to silently get the users token. This will succeed if their access/refresh token are
      // not expired, and will attempt to silently get a new refresh token (via an invisible iframe)
      // if the token *is* expired. But if it cannot get the access token for some reason it will
      // throw and the catch block will take over, prompting the user to enter their credentials.
      response = await msalInstance.acquireTokenSilent({
        account: {
          ...account,
          // Pass the email in as the username so that MSAL knows which account to log the user in
          // with in the case that they accidentally log into more than one.
          username: account.idTokenClaims.email,
        },
        scopes: auth.scopes,
      });
    } catch (e: any) {
      // Leaving this log here to help with debugging because I think there's a chance we can optimize this flow
      console.log("Error acquiring token silently:", e);

      // If the `acquireTokenSilent` call fails we need to fallback to having the user enter their
      // credentials into the B2C flow again. More information in this section of the MSAL docs:
      //
      // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/acquire-token.md#acquiring-an-access-token
      if (!SIGN_UP_V2_ENABLED) {
        msalInstance.acquireTokenRedirect({
          account,
          scopes: auth.scopes,
          loginHint: account.idTokenClaims.email,
        });
      } else {
        authVar.setLoading(false);
      }
      return;
    }

    // Check to see if theres an `nbf` (not before) claim on the JWT. If there is we need to avoid
    // using the JWT before that time. We can accomplish this by getting the diff between `now()` and
    // `nbf` and sleeping for that duration.
    const nbf = response.idTokenClaims["nbf"] || 0;
    const diff = Date.now().valueOf() - nbf * 1000;

    // We only need to sleep immediately after **logging in**. If we are re-authenticating from a previous
    // session the `nbf` will be in the past, from when the token was generated, in which case
    // diff will be greater than 0.
    if (diff < 0) {
      const sleepFor = Math.abs(diff);
      console.log(`Sleeping for ${sleepFor}ms due to JWT nbf claim`);
      await new Promise((resolve) => window.setTimeout(() => resolve(null), sleepFor));
    }

    if (response.expiresOn) {
      // Figure out how many miliseconds it is until the users token expires; we will try to refresh
      // their token at that time. Remove 5sec from the time to give us time for the round trip
      const refreshTimer = response.expiresOn.valueOf() - new Date().valueOf() - 5000;

      window.setTimeout(() => {
        acquireTokenSilent(msalInstance);
      }, refreshTimer);
    }

    // Once the sleep is finished (if we had to sleep) we will set the auth token reactive variable
    // which will immediately fire off any requests that are pending on `authVar.isAuthenticated`.
    authVar.setToken(response.accessToken);
    authVar.setLoading(false);
  };

  useEffect(() => {
    if (
      shouldTryLogin &&
      inProgress === "none" &&
      accounts.length > 0 &&
      !isEqual(accounts, accountRef.current)
    ) {
      accountRef.current = accounts as msal.AccountInfo[];
      acquireTokenSilent(instance);
    }
  }, [inProgress, accounts, instance]);

  // Set Firebase user properties and id based on auth status
  useEffect(() => {
    if (!loading) {
      // This comparison limits the number of updates to Firebase
      // since this useAuth hook is defined in multiple palces
      if (isAuthenticated !== isAuthenticatedRef.current) {
        isAuthenticatedRef.current = isAuthenticated;
        if (isAuthenticated) {
          setUserProperty("authenticated", "true");
          if (account && account.localAccountId) {
            setUserId(account.localAccountId);
          }
        } else {
          setUserProperty("authenticated", "false");
        }
      }
    }
  }, [loading, isAuthenticated]);

  return {
    changeEmail,
    changePhoneNumber,
    forgotPassword,
    isAuthenticated,
    // Use the global `loading` state to determine if the authentication call has finished, which
    // keeps the `isReady` value consistent across multiple uses of the `useAuth` hook. It does
    // not reflect whether or not the user is logged in successfully; it means that we have attempted to
    // log them in, and if `isAuthenticated` is false, the user needs to log in.
    isReady: !loading,
    login,
    loginNoMFA,
    loginWithPopup,
    logout,
  };
};

export default useAuth;
