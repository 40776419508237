import { gql } from "@apollo/client";

export default gql`
  mutation CreateCaregiverAccess($data: CreateCaregiverAccessInput!) {
    createCaregiverAccess(data: $data) {
      errors {
        code
        message
        type
      }
      telecom {
        phone
        email
      }
      results {
        success {
          firstName
          lastName
        }
        failed {
          firstName
          lastName
        }
      }
      accessId
      status
    }
  }
`;
