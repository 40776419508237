import { gql, useMutation } from "@apollo/client";

import {
  ConfirmChildCaregiverAccess,
  ConfirmChildCaregiverAccessVariables,
} from "/apollo/schema/types";

const ConfirmChildGql = gql`
  mutation ConfirmChildCaregiverAccess($data: ConfirmChildCaregiverAccessInput!) {
    confirmChildCaregiverAccess(data: $data) {
      errors {
        code
        message
        type
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useConfirmChildCaregiverAccess = () =>
  useMutation<ConfirmChildCaregiverAccess, ConfirmChildCaregiverAccessVariables>(ConfirmChildGql);
