import styled from "styled-components";

import { ListItem } from "/component/base";

export const CaregiverItemCard = styled(ListItem.Button)(({ theme }) => ({
  backgroundColor: theme.colors.backgroundPrimary,
  borderRadius: 16,
  boxShadow: theme.shadows.base,
  padding: 10,
}));
