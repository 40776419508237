import { makeVar } from "@apollo/client";

const SignUpSignInRedirectKey = "pre-register-redirect";

const storedCaregiverState = localStorage.getItem(SignUpSignInRedirectKey);
const initialValue = storedCaregiverState ? storedCaregiverState : "";

const reactiveVar = makeVar<string>(initialValue);

export default {
  var: reactiveVar,
  update(value: Partial<string>) {
    localStorage.setItem(SignUpSignInRedirectKey, value);
    return reactiveVar(value);
  },
  read() {
    return reactiveVar();
  },
};
