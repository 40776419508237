import styled from "styled-components";

import { InlineMessage as InlineMessageBase, Text } from "/component/base";
import CtaCardBase from "/component/partial/CtaCard";
import EmptyStateCtaBase from "/component/partial/EmptyStateCta";
import { card } from "/styles";

export const ItemContent = styled(Text)(({ theme }) => ({
  paddingTop: theme.spacing.base * 5,
  paddingBottom: theme.spacing.base * 5,
}));

export const InlineMessage = styled(InlineMessageBase)(({ theme }) => ({
  marginTop: theme.spacing.expanded,
  [theme.media.desktop]: {
    marginTop: theme.spacing[40],
  },
}));

export const Title = styled(Text).attrs({ variant: "title1", element: "div" })(({ theme }) => ({
  marginTop: theme.spacing.standard,
  [theme.media.desktop]: {
    marginTop: theme.spacing.base * 18,
  },
}));

export const EmptyStateCta = styled(EmptyStateCtaBase)(({ theme }) => ({
  marginTop: theme.spacing.base * 40,
}));

export const CtaCard = styled(CtaCardBase)(({ theme }) => ({
  marginTop: theme.spacing.base * 12,
  // Don't show on desktop, as this should be shown in the sidebar
  [theme.media.desktop]: {
    display: "none",
  },
}));

export const FlowContainer = styled.div(card.wrapper);

export const HeaderContainer = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  [theme.media.max.tablet]: {
    justifyContent: "space-between",
  },
  [theme.media.max.tablet]: {
    position: "absolute",
    top: "35px",
    right: "25px",
  },
  [theme.media.max.mobile]: {
    position: "absolute",
    top: "90px",
    right: "25px",
  },
}));
