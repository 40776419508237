import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React, { useState } from "react";

import { Loading } from "/component/base";
import MessageList from "/component/partial/MessageList";
import ProviderAvatarList from "/component/partial/ProviderAvatarList";
import { useAmwellInbox, useTranslation } from "/hook";
import { reactPlugin } from "/util/appInsights.util";

import { ProvidersTitle } from "./Inbox.styles";
import { Props } from "./Inbox.types";

const INITIAL_PAGE_SIZE = 10;

const Inbox = ({ contacts, onProviderClick }: Props) => {
  const [pageSize, setPageSize] = useState(INITIAL_PAGE_SIZE);
  const { data: inbox, error, loading } = useAmwellInbox({ maxResults: pageSize });
  const { t } = useTranslation("messages");
  const isInitialLoad = (loading && !inbox) || (!error && !inbox);
  const providerContacts = contacts?.map((contact) => ({
    id: contact.id.persistentId,
    nameWithDesignations: `${contact.firstName} ${contact.lastName}`,
    // TODO: Resolve an Amwell provider from an Amwell Contact, in order to
    // get an avatar. Unclear documentation and the mobile app doesn't support
    // this. This empty string exists to satisfy type constraints.
    photoUrl: "",
    name: {
      firstName: contact.firstName,
      lastName: contact.lastName,
    },
    primarySpecialty: null,
  }));
  const showContacts = !isInitialLoad && !!contacts?.length;

  const fetchMore = () => {
    if (inbox) {
      setPageSize(INITIAL_PAGE_SIZE + inbox.messages.length);
    }
  };

  return isInitialLoad ? (
    <Loading />
  ) : (
    <>
      <MessageList
        emptyStateSubtitle={t("error.inboxEmpty.subtitle")}
        emptyStateTitle={t("error.inboxEmpty.title")}
        error={error}
        errorStateSubtitle={t("error.fetchError.subtitle")}
        errorStateTitle={t("error.fetchError.title")}
        getIconForMessage={({ isUnread }) => (isUnread ? "unread" : "open")}
        messages={inbox?.messages}
        onFetchMore={fetchMore}
        total={inbox?.total}
      />

      {providerContacts && showContacts && (
        <>
          <ProvidersTitle color="textSecondary" variant="body1Bold">
            {t("label.providers")}
          </ProvidersTitle>

          <ProviderAvatarList onProviderClick={onProviderClick} providers={providerContacts} />
        </>
      )}
    </>
  );
};

export default withAITracking(reactPlugin, Inbox, undefined, "ai-tracking");
