// Modules
import { Formik } from "formik";
import React, { useState } from "react";

import AddressForm, { formAddressSchema } from "/component/form/AddressForm";
import { useTranslation } from "/hook";

import { Base } from "./DemographicFormWizard.styles";
import { BackButton, Header, LogoWordmark } from "./DemographicFormWizard.styles";
import { HandleStepSubmit, Props } from "./DemographicFormWizard.types";
import NameForm from "./NameForm";
import SexForm from "./SexForm";

const NUM_OF_STEPS = 3;

const DemographicFormWizard = ({ initialValues, handleSubmit, className }: Props) => {
  const { t } = useTranslation("form-Demographics");
  const { t: addressformTranslations } = useTranslation("form-Address");
  const addressValidationSchema = formAddressSchema(addressformTranslations);
  const [step, setStep] = useState(0);
  const [formValues, setFormValues] = useState(initialValues);
  const [serverError, setServerError] = useState("");

  const handleStepSubmit: HandleStepSubmit = async (values) => {
    // Clear error message on resubmit
    setServerError("");
    const updatedValues = {
      ...formValues,
      ...values,
    };

    if (step < NUM_OF_STEPS - 1) {
      setFormValues(updatedValues);
      setStep(step + 1);
    } else {
      try {
        const res = await handleSubmit(updatedValues);
        if (res.data?.createPatient.errors?.length) {
          res.data?.createPatient.errors.forEach((e) => {
            setServerError(e.message);
          });
        }
      } catch (e: any) {
        if (e instanceof Error) {
          setServerError(e.message);
        }
      } finally {
        // By setting form values after submit, we prevent the last form
        // from re-rendering.
        setFormValues(updatedValues);
      }
    }
  };
  const handleBack = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  return (
    <Base className={className}>
      <Header>
        <BackButton
          title={t("backButton.title")}
          show={step !== 0}
          type="button"
          onClick={handleBack}
        />
        <LogoWordmark />
      </Header>
      {step === 0 && (
        <NameForm
          handleSubmit={handleStepSubmit}
          initialValues={{ firstName: formValues.firstName, lastName: formValues.lastName }}
        />
      )}
      {step === 1 && (
        <SexForm handleSubmit={handleStepSubmit} initialValues={{ sex: formValues.sex }} />
      )}
      {step === 2 && (
        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{ address: formValues.address }}
          validationSchema={addressValidationSchema}
          onSubmit={handleStepSubmit}
        >
          {({ isSubmitting, isValid }) => (
            <AddressForm
              ctaTitle={t("submitText.addressStep", { ns: "form-Demographics" })}
              formTitle={t("heading.addressStep", { ns: "form-Demographics" })}
              isSubmitting={isSubmitting}
              isValid={isValid}
              serverError={serverError}
            />
          )}
        </Formik>
      )}
    </Base>
  );
};

export default DemographicFormWizard;
