import React from "react";

import { Text } from "/component/base";
import { MailFilled, MailOpen, Send } from "/component/base/Icon";
import { toTimeAgo } from "/util/date";

import { Base, DateSenderWrapper, DateText, TextWrapper } from "./MessageListItem.styles";
import { MessageListIcons, Props } from "./MessageListItem.types";
import { parseBodyPreview } from "./MessageListItem.util";

const getRenderedIcon = (icon: MessageListIcons) => {
  switch (icon) {
    case "open":
      return <MailOpen color="objectSubtle" size={16} />;
    case "send":
      return <Send color="objectSubtle" size={16} />;
    case "unread":
    default:
      return <MailFilled color="brandSecondary" size={16} />;
  }
};

/**
 * MessageListItem renders content that represents an inbox message for a
 * `ListItem` component.
 */
const MessageListItem = ({ preview, date, icon = "unread", senderName, subject }: Props) => (
  <Base>
    {getRenderedIcon(icon)}
    <TextWrapper>
      <DateSenderWrapper>
        <Text variant="body1Bold">{senderName}</Text>

        <DateText color="textSecondary" variant="body2">
          {toTimeAgo(date)}
        </DateText>
      </DateSenderWrapper>

      <Text>{subject}</Text>

      <Text color="textSecondary" truncate>
        {parseBodyPreview(preview)}
      </Text>
    </TextWrapper>
  </Base>
);

export default MessageListItem;
