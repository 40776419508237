import React from "react";
import { Trans } from "react-i18next";

import { Modal, Text } from "/component/base";
import { useTranslation } from "/hook";
import { layout } from "/styles";

import { Props } from "./ResultSummaryModal.types";

const ResultSummaryModal = ({ close, isOpen, testSummary }: Props) => {
  const { t } = useTranslation("result");

  return (
    <Modal
      header={
        <Modal.Header
          close={close}
          title={testSummary ? t("title.noteFromProvider") : t("title.noteOnResults")}
        />
      }
      isOpen={isOpen}
      close={close}
    >
      {testSummary ? (
        <Text>{testSummary}</Text>
      ) : (
        <div css={layout.spacedChildrenVertical()}>
          <Text element="p">{t("disclaimer.header")}</Text>

          <Trans
            components={{
              body: <Text element="p" />,
              notice: <Text color="brandPrimary" element="p" variant="body1Bold" />,
            }}
            i18nKey="disclaimer.body"
            t={t}
          />
        </div>
      )}
    </Modal>
  );
};

export default ResultSummaryModal;
