import { differenceInHours } from "date-fns";

export const UTM_STORAGE_KEY = "SH__UTM_PARAMS";

export interface UtmParams {
  utmTerm: string | null;
  utmCampaign: string | null;
  utmSource: string | null;
  utmContent: string | null;
  utmMedium: string | null;
}

export const getUtmParamsFromUrl = () => {
  const urlParams = new URLSearchParams(window.location.search);

  if (containsAnyUTMParameter()) {
    window.sessionStorage.setItem(
      UTM_STORAGE_KEY,
      JSON.stringify({
        timestamp: Date.now(),
        utm_term: urlParams.get("utm_term"),
        utm_campaign: urlParams.get("utm_campaign"),
        utm_source: urlParams.get("utm_source"),
        utm_content: urlParams.get("utm_content"),
        utm_medium: urlParams.get("utm_medium"),
      }),
    );
  }

  function containsAnyUTMParameter() {
    const utmParams = ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content"];

    // Check if any UTM parameter exists in the query string
    return utmParams.some((param) => urlParams.has(param));
  }
};

export const getUtmParams = (): UtmParams | null => {
  try {
    const paramsString = window.sessionStorage.getItem(UTM_STORAGE_KEY);
    if (!paramsString) {
      return null;
    }

    const params = JSON.parse(paramsString) as {
      timestamp: number;
      utm_term: string | null;
      utm_campaign: string | null;
      utm_source: string | null;
      utm_content: string | null;
      utm_medium: string | null;
    };

    if (!params || typeof params !== "object" || typeof params?.timestamp !== "number") {
      window.sessionStorage.removeItem(UTM_STORAGE_KEY);
      return null;
    }

    // When UTM params are more than 24 hours old, remove them.
    if (differenceInHours(Date.now(), params.timestamp) >= 24) {
      window.sessionStorage.removeItem(UTM_STORAGE_KEY);
      return null;
    }

    return {
      utmTerm: params.utm_term ?? null,
      utmCampaign: params.utm_campaign ?? null,
      utmSource: params.utm_source ?? null,
      utmContent: params.utm_content ?? null,
      utmMedium: params.utm_medium ?? null,
    };
  } catch {
    window.sessionStorage.removeItem(UTM_STORAGE_KEY);
    return null;
  }
};
