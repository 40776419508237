import { MutationHookOptions, useMutation } from "@apollo/client";

import SEND_PASSWORD_RESET from "/apollo/mutation/sendPasswordReset";
import { SendPasswordReset, SendPasswordResetVariables } from "/apollo/schema/types";

const useSendPasswordReset = (
  options: MutationHookOptions<SendPasswordReset, SendPasswordResetVariables>,
) => {
  const [sendPasswordReset] = useMutation<SendPasswordReset, SendPasswordResetVariables>(
    SEND_PASSWORD_RESET,
    options,
  );
  const handleSubmit = async ({ email }: { email: string }) =>
    await sendPasswordReset({
      variables: {
        email,
      },
    });

  return { handleSubmit };
};

export default useSendPasswordReset;
