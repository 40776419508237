import styled from "styled-components";

import { layout, transition } from "/styles";

export const ComboboxList = styled.ul<
  React.HTMLProps<HTMLUListElement> & { $animateFromBottom: boolean }
>(
  layout.flexVertical,
  ({ $animateFromBottom }) => transition.accordian($animateFromBottom),
  ({ theme }) => ({
    backgroundColor: theme.colors.backgroundPrimary,
    border: `1px solid ${theme.colors.objectSubtle}`,
    borderRadius: 30,
    boxShadow: theme.shadows.small,
    display: "flex",
    overflowY: "auto",
    zIndex: theme.zIndex.popover,
    maxHeight: "40vh",

    [theme.media.max.mobile]: {
      maxHeight: "16vh",
    },
  }),
);

export const ListItem = styled.li<{ $active: boolean; $last: boolean }>(
  layout.padding("condensed", "standard"),
  layout.flexSpaceHorizontal,
  ({ $active, $last, theme }) => ({
    backgroundColor: $active ? theme.colors.brandPrimaryLight : "transparent",
    cursor: "pointer",
    borderBottom: $last ? undefined : `1px solid ${theme.colors.objectSubtle}`,
    transition: "background 100ms ease",
  }),
);
