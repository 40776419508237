import React from "react";

import providerPlaceholderImage from "/asset/image/providerPlaceholder.png";
import summitLogoImage from "/asset/image/summit-logo-small.png";
import { Brand } from "/b2c/schema/types";
import Note from "/component/base/Note";
import { useTranslation } from "/hook";

import { TestResultsProviderNoteWrapper } from "./TestResultsProviderNote.styles";

type Props = {
  note?: string | null;
  provider?: {
    imageUrl?: string | null;
    displayName?: string | null;
    specialty?: string | null;
  } | null;
  status?: string | null;
  brand?: Brand;
};

const checkIsReviewed = (status?: string | null) => {
  const TEST_RESULT_REVIEWED_STATUSES = ["NOTIFY", "SUBMIT", "SUBMITTED", "CLOSED"];
  return TEST_RESULT_REVIEWED_STATUSES.includes(status || "");
};

const TestResultsProviderNote = ({ provider, note, status, brand }: Props) => {
  const { t } = useTranslation("partial-testResultsProviderNote");
  const isNotReviewed = !checkIsReviewed(status);
  const isCityMd = brand === Brand.CITYMD;

  return (
    <TestResultsProviderNoteWrapper>
      {isNotReviewed || !note ? (
        <Note
          avatar={summitLogoImage}
          title={t("resultsNotReviewed")}
          body={t("providerNotReviewed")}
        />
      ) : isCityMd ? (
        <Note avatar={summitLogoImage} title={t("cityMDCareTeam")} body={note} />
      ) : (
        <Note
          avatar={provider?.imageUrl || providerPlaceholderImage}
          title={provider?.displayName || t("summitHealthCareTeam")}
          subTitle={provider?.specialty}
          body={note}
        />
      )}
    </TestResultsProviderNoteWrapper>
  );
};
export default TestResultsProviderNote;
