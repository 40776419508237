import React from "react";

import { Alert, Button, Text } from "/component/base";
import { useTranslation } from "/hook";
import { layout } from "/styles";

import { Props } from "./CaregiverRemoveModal.types";

const CaregiverRemoveModal = ({ isOpen, onConfirm, onCancel }: Props) => {
  const { t } = useTranslation("partial-RemoveCaregiver");

  return (
    <Alert
      close={() => null}
      isOpen={isOpen}
      title={t("title")}
      footer={
        <div css={[layout.flexVerticalCenter, layout.spacedChildrenVertical("standard")]}>
          <Button onClick={onConfirm}>{t("confirm")}</Button>
          <Button onClick={onCancel} variant="borderBottom">
            {t("cancel")}
          </Button>
        </div>
      }
    >
      <Text variant="body1" color="textSecondary">
        {t("subtitle")}
      </Text>
    </Alert>
  );
};

export default CaregiverRemoveModal;
