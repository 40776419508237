import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React from "react";

import { Avatar } from "/component/base";
import Text from "/component/base/Text";
import theme from "/theme/default";
import { reactPlugin } from "/util/appInsights.util";
import { parseInitials } from "/util/formatText";

import {
  ProviderCard,
  ProviderContainer,
  ProviderDetailsContainer,
} from "./TelehealthProviderCard.styles";
import { Props } from "./TelehealthProviderCard.types";

const TelehealthProviderCard = ({ provider, after }: Props) => (
  <ProviderCard>
    <ProviderContainer>
      <Avatar
        css={{ marginRight: theme.spacing.standard }}
        src={provider.hasImage ? provider.logoUrl : undefined}
        initials={parseInitials(provider)}
        alt={provider.fullName}
      />
      <ProviderDetailsContainer>
        <Text variant="title3">
          {provider.firstName} {provider.lastName}
        </Text>
        <Text variant="body2" color="textSecondary">
          {provider.specialty.value}
        </Text>
        <Text variant="body2" color="textSecondary">
          {provider.practice.name}
        </Text>
      </ProviderDetailsContainer>
    </ProviderContainer>
    {after}
  </ProviderCard>
);

export default withAITracking(reactPlugin, TelehealthProviderCard, undefined, "ai-tracking");
