import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router";

import useDemographics from "/b2c/query/useDemographics.b2c";
import { List, Text } from "/component/base";
import * as Icons from "/component/base/Icon";
import MainContent from "/component/layout/MainContent";
import LoadingSkeleton from "/component/partial/LoadingSkeleton";
import MainContentLoginCta from "/component/partial/MainContentLoginCta";
import SetCaregiver from "/component/partial/SetCaregiver";
import { useCaregiverProvider } from "/component/provider/CaregiverProvider";
import Transition from "/component/util/Transition";
import routes from "/constant/url.constant";
import { useAmwellInbox, useAuth, useTranslation } from "/hook";
import { layout } from "/styles";
import { reactPlugin } from "/util/appInsights.util";
import { hasFeatureFlagDisabled } from "/util/featureFlags/featureFlags";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  logEvent,
} from "/util/firebase.util";

import { OptionProp } from "./AcccountIndex.types";
import {
  AccessoryWrapper,
  FullWidthAnchor,
  HeaderContainer,
  ListContainer,
  MyAccountItem,
  UnreadBadge,
} from "./AccountIndex.styles";

const ENABLE_CAREGIVER_PROXY = process.env.ENABLE_CAREGIVER_PROXY === "true";

const AccountIndex = () => {
  const { data, loading: isDemographicsLoading } = useDemographics();
  const { data: inbox } = useAmwellInbox({ maxResults: 1 });
  const history = useHistory();

  const { isAuthenticated, login } = useAuth();
  const { t } = useTranslation("account");
  const { caregiver, caregiverMode } = useCaregiverProvider();

  //Not authorized show an empty state
  if (!isAuthenticated) {
    return (
      <MainContent pageTitle={t("title")}>
        <Helmet>
          <title>{t("title")}</title>
        </Helmet>

        <MainContentLoginCta
          title={t("empty.title")}
          subtitle={t("empty.subtitle")}
          login={login}
          analyticsParams={
            {
              user_flow: AnalyticsUserFlow.MY_ACCOUNT,
              source: AnalyticsSource.MY_ACCOUNT,
            } as ButtonClickParams
          }
        />
      </MainContent>
    );
  }

  const logButtonClickEvent = (buttonName: string) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.MY_ACCOUNT,
      source: AnalyticsSource.MY_ACCOUNT,
      button_name: buttonName,
    } as ButtonClickParams);
  };

  const options: OptionProp[] = [
    {
      onClick: () => {
        logButtonClickEvent("Personal info");
      },
      Icon: Icons.User,
      title: t("personalInfo"),
      to: routes.personalInformation,
    },
    {
      onClick: () => {
        logButtonClickEvent("Bills and payment");
      },
      Icon: Icons.BillPayment,
      title: t("billPayments"),
      to: routes.billingAccounts,
    },
    {
      onClick: () => {
        logButtonClickEvent("Insurance");
      },
      Icon: Icons.Certificate,
      title: t("insurance"),
      to: routes.insurances,
      hide: hasFeatureFlagDisabled("insurance_enabled"),
    },
    {
      onClick: () => {
        logButtonClickEvent("Messages");
      },
      accessory: (
        <Transition show={!!inbox}>
          <UnreadBadge>
            <Text color="textInverse" variant="body1Bold">
              {inbox?.unread}
            </Text>
          </UnreadBadge>
        </Transition>
      ),
      Icon: Icons.NewMessage,
      title: t("messages"),
      to: routes.messaging,
    },
    {
      onClick: () => {
        logButtonClickEvent("Account settings");
      },
      Icon: Icons.NewSettings,
      title: t("accountSettings"),
      to: routes.settings,
      hide: caregiver.isMinor,
    },
    {
      onClick: () => {
        logButtonClickEvent("Notifications");
      },
      Icon: Icons.NotificationBell,
      title: t("notifications"),
      to: caregiverMode ? routes.caregiverNotifications : routes.notifications,
    },
    {
      onClick: () => {
        logButtonClickEvent("Caregiver access");
      },
      Icon: Icons.Caregiver,
      title: t("caregiver"),
      to: routes.caregiver,
    },
    {
      Icon: Icons.Smile,
      title: t("feedback"),
      to: routes.feedback,
      hide: !(process.env.FEATURE_ENABLE_FEEDBACK === "true"),
    },
    {
      Icon: Icons.Lock,
      title: t("tosPrivacy"),
      href: process.env.COMBINED_TOS_PRIVACY_URL,
      hide: !process.env.COMBINED_TOS_PRIVACY_URL,
    },
  ]
    .filter((o) => !o.hide)
    .filter((el) => el) as OptionProp[];

  return (
    <MainContent
      pageTitle={[
        data?.patient?.demographics?.firstName,
        data?.patient?.demographics?.lastName,
      ].join(" ")}
      headerAccessory={
        <HeaderContainer>
          {ENABLE_CAREGIVER_PROXY && isAuthenticated && (
            <SetCaregiver
              buttonStyle="blank"
              analyticsParams={
                {
                  user_flow: AnalyticsUserFlow.CAREGIVER,
                  source: AnalyticsSource.MY_ACCOUNT,
                } as ButtonClickParams
              }
            />
          )}
        </HeaderContainer>
      }
    >
      <Helmet>
        <title>{t("title")}</title>
      </Helmet>
      {isDemographicsLoading ? (
        <LoadingSkeleton skeletonVariant="list-item" />
      ) : (
        <ListContainer>
          <List
            as="Buttons"
            hideLastChildBorder
            onClick={({ to, href }) => {
              if (to === routes.personalInformation) {
                logButtonClickEvent("Personal info");
              } else if (to === routes.billingAccounts) {
                logButtonClickEvent("Bills and payment");
              } else if (to === routes.insurances) {
                logButtonClickEvent("Insurance");
              } else if (typeof to === "object" && to.pathname === routes.messaging) {
                logButtonClickEvent("Messages");
              } else if (typeof to === "object" && to.pathname === routes.settings) {
                logButtonClickEvent("Account settings");
              } else if (typeof to === "object" && to.pathname === routes.caregiver) {
                logButtonClickEvent("Caregiver access");
              }
              if (!href && to) {
                history.push(to);
              }
            }}
            data={options}
            renderItem={({ accessory, Icon, title, href }: OptionProp) => (
              <>
                {href ? (
                  <FullWidthAnchor href={href} rel="noreferrer" target="_blank">
                    <MyAccountItem>
                      <Icon
                        color="brandSecondary"
                        background={{ size: 54, color: "brandSecondaryLight" }}
                      />
                      <AccessoryWrapper>
                        <Text variant="body1Bold">{t("tosPrivacy")}</Text>
                      </AccessoryWrapper>
                    </MyAccountItem>
                  </FullWidthAnchor>
                ) : (
                  <MyAccountItem>
                    <Icon
                      color="brandSecondary"
                      background={{ size: 54, color: "brandSecondaryLight" }}
                    />
                    <AccessoryWrapper>
                      <Text variant="body1Bold">{title}</Text>
                      {accessory}
                    </AccessoryWrapper>
                  </MyAccountItem>
                )}
              </>
            )}
          />
        </ListContainer>
      )}
      {!!process.env.GITHUB_COMMIT_HASH && (
        <Text css={layout.margin("standard", "skip", "skip")} element="div">
          VERSION: {process.env.GITHUB_COMMIT_HASH}
        </Text>
      )}
    </MainContent>
  );
};

export default withAITracking(reactPlugin, AccountIndex, undefined, "ai-tracking");
