import { Form, Formik, FormikErrors } from "formik";
import React, { ChangeEvent, useState } from "react";
import * as Yup from "yup";

import { Button, SelectedItemsList, Text } from "/component/base";
import { Document } from "/component/base/Icon";
import FormContent from "/component/partial/FormContent";
import {
  AttachmentProps,
  useAmwellPrevisitContext,
} from "/component/provider/AmwellPrevisitProvider";
import { MaxFileSize } from "/constant/telehealth.constant";
import { useTranslation } from "/hook";
import { layout } from "/styles";

import {
  AttachmentListContainer,
  AttachmentsButtonHorizontalContainer,
  AttachmentsButtonVerticalContainer,
  Title,
} from "../Telehealth.styles";
import { TelehealthFormProps } from "../Telehealth.types";

const Attachments = ({
  initialValues,
  handleError,
  handleSubmit,
}: TelehealthFormProps<AttachmentProps>) => {
  const { t } = useTranslation("form-telehealth-previsit");

  const { sdk, consumer, visit } = useAmwellPrevisitContext();
  const inputReference = React.createRef<HTMLInputElement>();
  const [inputKey, setInputKey] = useState("");
  const uploadFile = () => {
    inputReference.current?.click();
  };

  const checkIfFilesAreTooBig = (file: File | undefined | null) => {
    if (!file) return false;
    return file.size <= MaxFileSize;
  };

  const formReasonSchema = Yup.object({
    files: Yup.array(
      Yup.mixed().test("is-big-file", t("error.tooBig"), (value) =>
        checkIfFilesAreTooBig(value as File),
      ),
    ).nullable(),
  });

  const filesToSelectedList = (
    formikFiles: File[],
    formikErrors: FormikErrors<{
      files: File[];
    }>,
  ) =>
    formikFiles.map((file: File, idx) => ({
      label: file.name,
      value: file,
      error: (formikErrors.files ? formikErrors.files[idx] : undefined) as string | undefined,
    }));

  const submitForm = async (values: AttachmentProps) => {
    try {
      if (consumer && visit) {
        await Promise.all(
          values.files.map((file) =>
            sdk?.consumerService.addHealthDocumentAttachment(
              consumer,
              file,
              file.name,
              visit,
              file.name,
            ),
          ),
        );
      }
      handleSubmit(values);
    } catch (e: any) {
      handleError(e);
    }
  };

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={initialValues}
      validationSchema={formReasonSchema}
      onSubmit={submitForm}
    >
      {({ isSubmitting, values, errors, setFieldValue, isValid }) => {
        const fileToList = filesToSelectedList(values.files as File[], errors);

        return (
          <Form>
            <FormContent css={layout.margin("gutter", "skip")}>
              <Title>{t("attachments.title")}</Title>

              <Text element="div" css={{ width: "100%" }}>
                {t("attachments.description")}
              </Text>
              <Text element="div" css={{ width: "100%" }}>
                {t("attachments.fileTypes")}
              </Text>

              <AttachmentListContainer>
                <SelectedItemsList
                  icon={<Document size={16} />}
                  items={fileToList}
                  onRemove={(fileToRemove: File) => {
                    setFieldValue(
                      "files",
                      values.files.filter((file: File) => fileToRemove.name !== file.name),
                    );
                    setInputKey(Date.now().toString());
                  }}
                />
                {values.files && values.files.length > 0 && (
                  <Button
                    onClick={uploadFile}
                    type="button"
                    css={layout.marginVertical("standard")}
                    variant="borderBottom"
                    isLoading={isSubmitting}
                  >
                    {t("attachments.attachAnother")}
                  </Button>
                )}
              </AttachmentListContainer>
              <input
                type="file"
                accept=".pdf,.doc,.jpg,.gif,.png,.bmp,.flv,.mpg,.mp4"
                hidden
                ref={inputReference}
                key={inputKey}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (e.target.files && e.target.files?.length === 1) {
                    const newFiles = [...values.files, e.target.files.item(0)];
                    setFieldValue("files", newFiles);
                  }
                }}
              />
              {values.files && values.files.length > 0 ? (
                <AttachmentsButtonHorizontalContainer>
                  <Button
                    css={layout.flexItemAuto}
                    variant="primary"
                    type="submit"
                    isLoading={isSubmitting}
                    isDisabled={!isValid}
                  >
                    {t("continue")}
                  </Button>
                </AttachmentsButtonHorizontalContainer>
              ) : (
                <AttachmentsButtonVerticalContainer>
                  <Button
                    onClick={uploadFile}
                    css={layout.flexItemAuto}
                    type="button"
                    variant="primary"
                    isLoading={isSubmitting}
                  >
                    {t("attachments.attachFile")}
                  </Button>
                  <Button
                    variant="secondary"
                    css={layout.flexItemAuto}
                    type="submit"
                    isLoading={isSubmitting}
                  >
                    {t("notNow")}
                  </Button>
                </AttachmentsButtonVerticalContainer>
              )}
            </FormContent>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Attachments;
