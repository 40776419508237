import { useReactiveVar } from "@apollo/client";
import React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";

import { auth } from "/apollo/client/local";

import Appointment from "./page/Appointment";
import AppointmentIndex from "./page/AppointmentIndex";

const AppointmentRoutes = ({ match }: RouteComponentProps) => {
  const { isAuthenticated } = useReactiveVar(auth.var);

  return (
    <Switch>
      <Route exact path={match.url} component={AppointmentIndex} />
      {isAuthenticated ? (
        <Route exact path={`${match.url}/:appointmentId`} component={Appointment} />
      ) : (
        <Redirect to={match.url} />
      )}
    </Switch>
  );
};

export default AppointmentRoutes;
