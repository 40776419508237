import styled from "styled-components";

import { layout } from "/styles";

export const FilterHeader = styled.div(({ theme }) => ({
  marginBottom: theme.spacing["40"],
  [theme.media.max.tabletPortrait]: {
    ...layout.flexVerticalAlignStart,
    ...layout.spacedChildrenVertical()({ theme }),
  },
  [theme.media.tablet]: {
    ...layout.flexCenterHorizontal,
    ...layout.spacedChildrenHorizontal(40)({ theme }),
  },
}));
