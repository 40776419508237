// Modules
import { transparentize } from "polished";
import styled from "styled-components";

// Components
import { Check } from "/component/base/Icon";
import { layout } from "/styles";

const SIZE = 24;

export const Label = styled.label(() => ({
  display: "flex",
  alignItems: "center",
}));

export const LabelWrapper = styled.div(({ theme }) => ({
  flex: "1 1 auto",
  "* + &": {
    marginLeft: theme.spacing.base * 3,
  },
}));

export const InputWrapper = styled.div(() => ({
  position: "relative" as const,
  width: SIZE,
  height: SIZE,
  flex: `0 0 ${SIZE}px`,
}));

export const Icon = styled(Check)(({ theme }) => ({
  color: theme.colors.textInverse,
  opacity: 0,
}));

export const CheckWrapper = styled.div(
  ({ theme }) => ({
    pointerEvents: "none",
    border: `solid 2px ${theme.colors.brandActionBlueLight}`,
    borderRadius: 6,
    backgroundColor: theme.colors.backgroundPrimary,

    // Change styles depending on the state of the sibling input[type=checkbox]
    "input:disabled + &": {
      borderColor: transparentize(0.7, theme.colors.objectSecondary),
    },

    "input:checked + &": {
      backgroundColor: theme.colors.brandPrimary,
      borderColor: theme.colors.brandPrimary,

      // Make the checkmark full opacity
      [Icon]: {
        opacity: 1,
      },
    },

    "input:disabled:checked + &": {
      backgroundColor: theme.colors.brandPrimaryLight,
      borderColor: "transparent",
    },
  }),
  layout.absoluteFill,
  layout.flexCenter,
);

export const Input = styled.input({
  width: "100%",
  height: "100%",
  appearance: "unset",
  margin: 0,
});
