import { withAITracking } from "@microsoft/applicationinsights-react-js";
import qs from "qs";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import InfiniteScroll from "react-infinite-scroll-component";
import { RouteComponentProps } from "react-router";

import appointmentsNoResults from "/asset/image/appointmentsNoResults.png";
import { useAppointmentsIndex } from "/b2c/query/appointments.b2c";
import { getAppointments_patient_appointments } from "/b2c/schema/types";
import { InlineMessage, Loading, Text } from "/component/base";
import { AlertCircle } from "/component/base/Icon";
import MainContent from "/component/layout/MainContent";
import DateRangeModal, { Range } from "/component/modal/DateRangeModal";
import AppointmentCard from "/component/partial/AppointmentCard";
import EmptyStateCta from "/component/partial/EmptyStateCta";
import FilterButton from "/component/partial/FilterButton";
import MainContentLoginCta from "/component/partial/MainContentLoginCta";
import TopTabBar from "/component/partial/TopTabBar";
import { useAuth, useTranslation } from "/hook";
import { layout } from "/styles";
import { reactPlugin } from "/util/appInsights.util";
import { AnalyticsSource, AnalyticsUserFlow, ButtonClickParams } from "/util/firebase.util";

import { LoadMoreSpinner } from "./AppointmentsIndex.styles";

const IMAGE_WIDTH = 376;
const IMAGE_HEIGHT = 244;

const AppointmentIndex = ({ location }: RouteComponentProps) => {
  const [selectedDateRange, setSelectedDateRange] = useState<Range>({});
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  let filterCount = 0;
  if (selectedDateRange?.start) filterCount++;
  if (selectedDateRange?.end) filterCount++;

  const { t } = useTranslation("appointmentIndex");
  const { isAuthenticated, login } = useAuth();

  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const showPast = query?.state !== "upcoming";

  const { data, loading, errors } = useAppointmentsIndex({ showPast });

  const appointments: getAppointments_patient_appointments[] = data?.patient?.appointments || [];

  const links = [
    {
      to: "?state=upcoming",
      label: t("upcoming"),
      isActive: !showPast,
      replace: true,
    },
    {
      to: "?state=past",
      label: t("past"),
      isActive: showPast,
      replace: true,
    },
  ];

  const getChild = () => {
    if (!isAuthenticated) {
      return <MainContentLoginCta login={() => login()} />;
    }

    if (loading) {
      return <Loading />;
    }

    if (errors && errors.length > 0) {
      return (
        <InlineMessage icon={<AlertCircle />} variant="important">
          <Text variant="body2">{showPast ? t("pastError") : t("error")}</Text>
        </InlineMessage>
      );
    }

    if (appointments.length) {
      return (
        <InfiniteScroll
          hasChildren
          hasMore={false}
          dataLength={appointments.length}
          loader={<LoadMoreSpinner />}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          next={() => {}}
          /* Setting overflow: visible so the card shadows are visible */
          style={{ overflow: "visible" }}
        >
          <ul>
            {appointments.map((appointment) => (
              <AppointmentCard
                key={appointment.appointmentId}
                css={layout.margin("skip", "skip", "standard")}
                appointment={appointment}
                analyticsParams={
                  {
                    user_flow: AnalyticsUserFlow.APPOINTMENTS,
                    source: AnalyticsSource.APPOINTMENTS,
                  } as ButtonClickParams
                }
              />
            ))}
          </ul>
        </InfiniteScroll>
      );
    }

    // Empty state
    return (
      <EmptyStateCta
        imageSource={appointmentsNoResults}
        imageHeight={IMAGE_HEIGHT}
        imageWidth={IMAGE_WIDTH}
        title={t("noAppointmentsHeader")}
        subtitle={showPast ? t("noPastAppointments") : t("noAppointments")}
      />
    );
  };

  return (
    <MainContent pageTitle={t("headline")} parentPage="root">
      <Helmet>
        <title>{t("title")}</title>
      </Helmet>

      {/* TODO: open filter modal when button is pressed */}
      {isAuthenticated && (
        <TopTabBar
          css={layout.margin("skip", "skip", "expanded")}
          links={links}
          after={
            showPast ? (
              <FilterButton count={filterCount} onClick={() => setIsFilterOpen(true)} />
            ) : null
          }
        />
      )}

      {getChild()}

      <DateRangeModal
        range={selectedDateRange}
        isOpen={isFilterOpen}
        onSubmit={setSelectedDateRange}
        close={() => setIsFilterOpen(false)}
      />
    </MainContent>
  );
};

export default withAITracking(reactPlugin, AppointmentIndex, undefined, "ai-tracking");
