import { gql } from "@apollo/client";

export default gql`
  mutation UpdateHomeAddress($input: UpdateHomeAddressInput!) {
    updateHomeAddress(data: $input) {
      viewer {
        user {
          patient {
            homeAddress {
              line1
              line2
              city
              state
              postalCode
            }
          }
        }
      }

      errors {
        code
        message
      }
    }
  }
`;
