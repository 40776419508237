/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";

import { QuestionResponseInput } from "/apollo/schema/types";
import { Button, Text } from "/component/base";
import { DateObj, dobSchemaFactory, requiredTest } from "/component/partial/formik/DOBField";
import { useTranslation } from "/hook";
import theme from "/theme/default";
import padWithZeros from "/util/padWithZeros";

import { FormContent } from "./DecisionSupportForm.styles";
import { Props } from "./DecisionSupportForm.types";
import { getInput } from "./DecisionSupportForm.util";

const DecisionSupportForm = ({ elements, elementSetRef, flowSessionId, handleSubmit }: Props) => {
  const { t } = useTranslation("form-shared");
  const questions = elements?.filter((element) => element.fieldName && element.questionText);
  const messages = elements?.filter((element) => !!element.messageText);
  const validations = {};
  questions.forEach((question) => {
    if (question.isRequired && question.fieldName) {
      validations[question.fieldName] = Yup.string().required(t("required"));
    }
    // Handle special cases
    if (question.fieldName === "DateOfBirth") {
      delete validations[question.fieldName];
      const dobSchema = dobSchemaFactory({
        isValid: t("invalidDate"),
        dateIsInPast: t("dobNotInFuture"),
        yearIsValid: t("dobYearNotValid"),
      });
      validations[question.fieldName] = dobSchema;
      if (question.isRequired) {
        validations[question.fieldName].test("requiredDOB", t("required"), requiredTest);
      }
    }
  });
  // We need to reset the initValues so that `validateOnMount` will get
  // rerun
  const initValues = {};
  questions.forEach((question) => {
    // Handle special case
    if (question.fieldName === "DateOfBirth") {
      initValues[question.fieldName] = {
        [`DateOfBirth.month`]: "",
        [`DateOfBirth.day`]: "",
        [`DateOfBirth.year`]: "",
      };
    }
    if (question.fieldName) {
      initValues[question.fieldName] = "";
    }
  });
  const validationSchema = Yup.object(validations);

  const handleFormSubmit = async (values: Record<string, string>) => {
    const responses: Array<QuestionResponseInput> = questions?.map((element) => {
      if (element.fieldName === "DateOfBirth") {
        const dateObj = values[element.fieldName] as unknown as DateObj;
        const month = padWithZeros(dateObj.month, 2);
        const day = padWithZeros(dateObj.day, 2);
        const year = dateObj.year;
        return {
          elementRef: element.elementRef,
          responseValue: `${year}-${month}-${day}`,
        };
      }
      return {
        elementRef: element.elementRef,
        responseValue: element.fieldName ? values[element.fieldName] : "",
      };
    });
    return handleSubmit(flowSessionId, responses, elementSetRef);
  };
  return (
    <Formik
      initialValues={initValues}
      enableReinitialize
      validateOnMount
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      {({ isSubmitting, isValid }) => (
        <Form>
          <FormContent>
            {messages && messages.length > 0 && (
              <ul>
                {messages.map((element) => {
                  return (
                    <Text
                      css={{
                        "& > a:visited": {
                          color: theme.colors.brandSecondary,
                        },
                        "& > a": {
                          color: theme.colors.brandSecondary,
                        },
                        "& > ul": {
                          listStyle: "disc",
                          listStylePosition: "inside",
                        },
                        "& > *": {
                          lineHeight: "1rem",
                        },
                      }}
                      dangerouslySetInnerHTML={{ __html: element.messageText || "" }}
                      element="li"
                      key={element.elementRef!}
                      color="textSecondary"
                    ></Text>
                  );
                })}
              </ul>
            )}

            {questions?.map((element) => getInput(element))}

            <FormContent.ButtonGroup>
              <Button type="submit" isDisabled={!isValid} isLoading={isSubmitting}>
                {t("continue")}
              </Button>
            </FormContent.ButtonGroup>
          </FormContent>
        </Form>
      )}
    </Formik>
  );
};

export default DecisionSupportForm;
