/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useReactiveVar } from "@apollo/client";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { differenceInMinutes } from "date-fns/esm";
import React, { useState } from "react";

import { scheduling } from "/apollo/client/local";
import DateBadge from "/component/base/DateBadge";
import Text from "/component/base/Text";
import MainContent from "/component/layout/MainContent";
import useAppointmentBooking from "/component/page/Scheduling/hook/useAppointmentBooking";
import useHandleBackWhenBooking from "/component/page/Scheduling/hook/useHandleBackWhenBooking";
import { Button } from "/component/page/Scheduling/page/ConfirmDetails/ConfirmDetails.styles";
import formatTimeSlotDateTime from "/component/page/Scheduling/util/formatTimeSlotDate";
import EmptyStateCta from "/component/partial/EmptyStateCta";
import { useTranslation } from "/hook";
import { card, layout } from "/styles";
import { reactPlugin } from "/util/appInsights.util";
import { AnalyticsEvent, AnalyticsSource, ButtonClickParams, logEvent } from "/util/firebase.util";

import SchedulingPrompt from "../Availabilities/component/SchedulingPrompt";

const ConfirmDetails = () => {
  const { t } = useTranslation("bookingConfirmDetails");
  const [isBooking, setIsBooking] = useState(false);
  const { reasonForVisit, booking, analyticsFlow } = useReactiveVar(scheduling.var);
  const { bookAppointment } = useAppointmentBooking();

  const handleBack = useHandleBackWhenBooking();

  const handleConfirm = async () => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: analyticsFlow,
      source: AnalyticsSource.CONFIRM_DETAILS,
      button_name: "Schedule this appointment",
    } as ButtonClickParams);
    setIsBooking(true);
    await bookAppointment();
    setIsBooking(false);
  };

  // Note: I don't think this case should ever happen, it would only happen if data got corrupted
  // in localstorage or localstate
  if (
    !booking ||
    !booking?.reservation ||
    !booking?.timeSlot ||
    !reasonForVisit ||
    !booking?.availability
  ) {
    return (
      <MainContent backButton={<MainContent.BackButton onClick={() => handleBack()} />}>
        <EmptyStateCta
          title={t("loadingError")}
          subtitle={t("bookingNotFound.subtitle")}
          ctaText={t("bookingNotFound.cta")}
          onClickCta={handleBack}
        />
      </MainContent>
    );
  }

  const renderApptCard = () => (
    <div css={[card.base, layout.padding("standard"), layout.flexLTR]}>
      <DateBadge
        css={layout.margin("skip", "standard", "skip", "skip")}
        date={new Date(`${booking.timeSlot.date}T${booking.timeSlot.startTime}`)}
      />
      <div css={[layout.spacedChildrenVertical("base")]}>
        <Text element="div" variant="title3" color="brandNavy">
          {booking!.availability.providerDetails.nameWithDesignations}
        </Text>
        <Text element="div" variant="body2" color="textSecondary">
          {booking!.availability.providerDetails.primarySpecialtyName}
        </Text>
        <Text element="div" variant="body2" color="textSecondary">
          {formatTimeSlotDateTime(booking!.timeSlot, "h:mm a")} -
          {formatTimeSlotDateTime(booking!.timeSlot, " h:mm a", "endTime")}
          {t("minutes", {
            mins: differenceInMinutes(
              new Date(booking!.timeSlot.endAtUtc),
              new Date(booking!.timeSlot.startAtUtc),
            ),
          })}
        </Text>
        <Text element="div" variant="body2" color="textSecondary">
          {booking!.availability.providerDetails.shortAddress}
        </Text>
      </div>
    </div>
  );

  return (
    <MainContent
      pageTitle={t("title")}
      backButton={<MainContent.BackButton onClick={() => handleBack()} />}
    >
      <SchedulingPrompt when={true} />
      <div css={layout.spacedChildrenVertical("gutter")}>{renderApptCard()}</div>

      <Button fullWidth="percent" isLoading={isBooking} onClick={handleConfirm}>
        {t("confirm")}
      </Button>
    </MainContent>
  );
};

export default withAITracking(reactPlugin, ConfirmDetails, undefined, "ai-tracking");
