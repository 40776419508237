import React from "react";

import { Text } from "/component/base";
import { CityMD, Logo } from "/component/base/Icon";
import { layout } from "/styles";

import { Props } from "./LocationItem.types";

const LocationItem = ({ className, title, icon = "pin", address }: Props) => {
  const Icon = icon === "pin" ? CityMD : Logo;
  return (
    <div className={className} css={layout.flexLTR}>
      <Icon
        background={icon === "pin" ? {} : { color: "backgroundPrimary", size: 48 }}
        size={36}
        color="brandPrimary"
      />
      <div
        css={[
          layout.flexVertical,
          layout.padding("skip", "skip", "skip", "standard"),
          layout.flexItemAuto,
        ]}
      >
        <Text variant="body1Bold" color="textTitle">
          {title}
        </Text>
        {address && (
          <Text color="textSecondary">
            {address.line1}
            <br />
            {[address.city, address.state, address.postalCode].filter(Boolean).join(", ")}
          </Text>
        )}
      </div>
    </div>
  );
};

export default LocationItem;
