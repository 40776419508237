import React, { forwardRef, useState } from "react";

import { Input, Wrapper } from "./TextInput.styles";
import { Props } from "./TextInput.types";

export const TextInput = forwardRef<HTMLInputElement, Props<string>>(
  ({ variant, after, before, className, hasError, onBlur, onFocus, ...rest }, ref) => {
    const [focused, setFocused] = useState(false);

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      setFocused(false);
      onBlur && onBlur(e);
    };

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      setFocused(true);
      onFocus && onFocus(e);
    };

    return (
      <Wrapper variant={variant} hasFocus={focused} hasError={hasError} className={className}>
        {before}
        <Input type="text" {...rest} onFocus={handleFocus} onBlur={handleBlur} ref={ref} />
        {after}
      </Wrapper>
    );
  },
);

export default TextInput;
