import styled from "styled-components";

import { styles as ButtonStyles } from "/component/base/Button";
import { layout } from "/styles";

export const InnerFields = styled.div(layout.spacedChildrenHorizontal(), {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  "> *": {
    flex: 1,
  },
});

export const ButtonGroup = styled.div(layout.spacedChildrenHorizontal(), ({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  paddingTop: theme.spacing.base * 7,
  [`> ${ButtonStyles.Base} + ${ButtonStyles.Base}`]: {
    marginLeft: theme.spacing.standard,
  },
  [`> ${ButtonStyles.Base}`]: {
    flex: 1,
  },
}));

export const FormContent = styled.div(layout.spacedChildrenVertical(), {
  display: "flex",
  flexDirection: "column",
  "> *": {
    flex: 0,
  },
  "& input": {
    minWidth: 0,
    width: "100%",
  },
});

export default FormContent;
