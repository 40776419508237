import styled from "styled-components";

import { layout } from "/styles";

export const ProgressBarContainer = styled.div({
  width: "100%",
});

export const CaregiverContainer = styled.div(
  {
    display: "grid",
    gridTemplateRows: "50px auto",
    overflow: "auto",
  },
  layout.paddingHorizontal("standard"),
);
