import React from "react";

import { Base } from "./Text.styles";
import { Props } from "./Text.types";

const Text = ({
  align,
  className,
  color,
  element,
  truncate = false,
  variant = "body1",
  ...rest
}: Props) => (
  <Base
    $align={align}
    $color={color}
    $truncate={truncate}
    $variant={variant}
    as={element}
    className={className}
    {...rest}
  />
);

export default Text;
