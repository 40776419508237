import React, { createContext, useContext, useLayoutEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

import Toast, { Props as ToastProps } from "/component/base/Toast";
import Transition from "/component/util/Transition";
import { transition } from "/styles";

import { useModalContext } from "../ModalProvider";
import { ToastContainer } from "./ToastProvider.styles";

export interface ToastConfig extends Pick<ToastProps, "icon" | "title" | "message" | "type"> {
  /**
   * A duration, in milliseconds, that represents how long the Toast will be visible
   * on the screen before disappearing
   *
   * @default 6000
   */
  ttl?: number;
}

type ContextValue = {
  showToast: (config: ToastConfig) => void;
};

const ToastProviderContext = createContext<ContextValue>({
  showToast: () => null,
});

export const useToastContext = () => useContext(ToastProviderContext);

/**
 * The ToastProvider stores a queue of Toast objects and will handle animating toasts in and out of
 * view.
 *
 * If there are additional toasts on the queue when one is popped off the next in line will be animated
 * into view once the original one has fully animated off screen.
 *
 * ### Dismissing
 * Toasts will be automatically dismissed after 6 seconds; this delay can be customized by passing a
 * number of milliseconds into the `ttl` prop when you call `showToast()`. Users can also click
 * on an active toast to dismiss it immediately.
 *
 * ### Accessibility
 * When a toast appears it will be announced by screen readers via the `aria-live` prop, but there
 * are no focus traps associated with the toast. It is assumed that a user who is using a
 * screen reader will hear the toast be announced, and then take no more actions with the toast. Even
 * though toasts can be clicked to be dismissed, you won't be able to navigate to the toast to dismiss it
 * without using a mouse. It's a balance game between accessibility and familiar/easy patterns for sighted users.
 *
 * **Note:** Because of this, you should never include actions inside your toasts, for example there
 * should never be a "retry" button in a toast. If you would like to provide additional functionality
 * inside a toast you should use another component (eg: <Alert />) or show the action inline.
 */
const ToastProvider = ({ children }: { children?: React.ReactNode }) => {
  const [toasts, setToasts] = useState<ToastConfig[]>([]);
  const [isShown, setIsShown] = useState(false);

  const timeoutRef = useRef<number>();

  // Add a new toast element onto the toast stack
  const showToast = (config: ToastConfig) => {
    setToasts([...toasts, config]);
  };

  // There will only be one queue mounted in the DOM at a time.
  const currentToast = toasts[0];

  // Use `useLayoutEffect` here to give a modal time to unmount itself before we show the toast. In the
  // case where we are showing a toast at the same time as we are dismissing a modal this prevents
  // the toast from initially being rendered inside the modal portal and instead waits until the modal
  // dismisses and the toast is then rendered as a child of `document.body`.
  useLayoutEffect(() => {
    if (currentToast) {
      // When we get a currentToast, or when it changes, we will animate it into view immediately.
      setIsShown(true);

      // After the `ttl` delay we will set `isShown` to false which will trigger the transition
      // to remove the toast from the screen. Once the animation finished the `handleLeaveAnimation`
      // function will actually remove the toast from the queue.
      timeoutRef.current = window.setTimeout(() => {
        setIsShown(false);
      }, currentToast.ttl || 6000);
    }
  }, [currentToast]);

  // We want to keep the toast mounted until the `leave` transition finishes, otherwise as we animate
  // it out of view there will be no content to render. Because of this we leave the toast on the queue
  // while the `leave` transition is running. After that transition finishes we can pop the
  // current toast off the queue.
  const handleLeaveAnimation = () => {
    setToasts(toasts.slice(1));
  };

  const handleToastClick = () => {
    // Immediately remove the toast from view if the user clicks it. After the transition finishes
    // the `handleLeaveAnimation` function will remove the toast from the queue.
    setIsShown(false);

    // Cleanup the existing timeout since the user manually dismissed the toast.
    window.clearTimeout(timeoutRef.current);
  };

  // Render the toast in the modal portal if there is an open modal
  const { modalEl } = useModalContext();

  return (
    <ToastProviderContext.Provider value={{ showToast }}>
      {children}
      {createPortal(
        <ToastContainer onClick={handleToastClick} aria-live="assertive" role="status">
          <Transition show={isShown} afterLeave={handleLeaveAnimation}>
            {currentToast && <Toast css={transition.fadeDown} {...currentToast} />}
          </Transition>
        </ToastContainer>,
        modalEl || document.body,
      )}
    </ToastProviderContext.Provider>
  );
};

export default ToastProvider;
