import React from "react";

import { Text } from "/component/base";
import { Check } from "/component/base/Icon";

import { ErrorListContainer, PasswordValidationContainer } from "./PasswordValidation.styles";

interface Props {
  title: string;
  validationMappings: Array<string>;
  validations: Array<boolean>;
}

const PasswordValidations = ({ title, validationMappings, validations }: Props) => (
  <PasswordValidationContainer>
    <Text color="textPrimary">{title}</Text>
    <ErrorListContainer>
      {validationMappings.map((titleValidation, idx) => {
        const isChecked = validations[idx];
        return (
          <li key={idx}>
            <Text variant="body2" color={isChecked ? "statusPositive" : "textPrimary"}>
              {isChecked ? <Check size={12} /> : "• "} {titleValidation}
            </Text>
          </li>
        );
      })}
    </ErrorListContainer>
  </PasswordValidationContainer>
);

export default PasswordValidations;
