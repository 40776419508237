import styled from "styled-components";

import { Text } from "/component/base";
import { layout } from "/styles";

export const HeaderIconContainer = styled.div(layout.flexVerticalCenter);

export const Title = styled(Text).attrs({
  variant: "title1",
  element: "h2",
  color: "textTitle",
})(layout.marginVertical("expanded"));

export const ContentContainer = styled.div(({ theme }) => ({
  ...layout.flexStartHorizontal,
  ...layout.marginVertical(40),
  [theme.media.max.mobile]: {
    flexDirection: "column",
    marginTop: 20,
    marginBottom: 20,
  },
}));

export const CommonDisclaimer = styled.div(({ theme }) => ({
  display: "flex",
  flex: 1,
  marginTop: theme.spacing[12],
}));

export const BandageFilledContainer = styled.div(() => ({
  flex: 0.2,
}));

export const CommonConditionsSubtitle = styled.div(layout.flexStartHorizontal);

export const InsuranceDisclaimer = styled.div(() => ({
  display: "flex",
  flex: 1,
}));

export const Description = styled.div(layout.flexVertical, ({ theme }) => ({
  marginLeft: theme.spacing[12],
}));

export const AcceptedInsurances = styled.div(layout.flexVertical, ({ theme }) => ({
  marginTop: theme.spacing[12],
}));

export const ButtonContainer = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  "> *": {
    flex: 1,
  },
  marginTop: 40,
  [theme.media.max.tabletPortrait]: {
    ...layout.flexVertical,
    ...layout.spacedChildrenHorizontal("none")({ theme }),
    "& > *": {
      padding: theme.spacing[12],
      width: "100%",
      marginTop: theme.spacing.condensed,
    },
  },
}));
