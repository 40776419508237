import { useField } from "formik";
import React from "react";

import DatePicker from "/component/base/DatePicker";
import InputWrapper from "/component/base/InputWrapper";
import { useTranslation } from "/hook";

import { Props } from "./DatePickerField.types";

/**
 * The DatePickerField is a DatePicker that is meant to be used in Formik forms.
 * Specify the `name` of the field, and formik should take care of the rest. This
 * component also includes props for label and the variant input wrapper style.
 * It will render errors setup in your Formik validations. Furthermore, if the
 * the text input of the DatePicker is in the incorrect format (after blur), an
 * error will be shown.
 */
const DatePickerField = ({
  className,
  disabled = false,
  minDate,
  maxDate,
  inputWrapperVariant = "primary",
  label,
  name,
  ...rest
}: Props) => {
  const { t } = useTranslation("partial-datePickerField");

  const [field, meta, helpers] = useField(name);

  return (
    <InputWrapper
      variant={inputWrapperVariant}
      className={className}
      error={meta.error}
      htmlFor={field.name}
      label={label}
      touched={meta.touched}
    >
      <DatePicker
        {...field}
        {...rest}
        disabled={disabled}
        hasError={!!meta.error && meta.touched}
        minDate={minDate}
        maxDate={maxDate}
        onChange={(date, inputText) => {
          helpers.setTouched(true);
          if (!date && !!inputText) {
            helpers.setError(t("incorrectFormat"));
          }
          helpers.setValue(date);
        }}
      />
    </InputWrapper>
  );
};

export default DatePickerField;
