import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React from "react";
import { RouteComponentProps } from "react-router-dom";

import otherServices from "/asset/image/otherServices.png";
import { Text } from "/component/base";
import { Phone } from "/component/base/Icon";
import MainContent from "/component/layout/MainContent";
import EmptyStateCta from "/component/partial/EmptyStateCta";
import routes from "/constant/url.constant";
import { useContactInfo, useTranslation } from "/hook";
import { formatText } from "/util";
import { reactPlugin } from "/util/appInsights.util";

import {
  ActionButtons,
  BottomButton,
  CallCenterButton,
  IconText,
  SubtitleText,
} from "./MoreService.styles";

const IMAGE_WIDTH = 486;
const IMAGE_HEIGHT = 244;

const MoreService = ({ history }: RouteComponentProps) => {
  const { t } = useTranslation("scheduling");
  const { contactByName } = useContactInfo();
  const phoneNumber = contactByName("summitCallCenter")?.phoneNumber;

  const handleBack = () => {
    history.push(routes.scheduling);
  };

  return (
    <MainContent backButton={<MainContent.BackButton onClick={() => handleBack()} />}>
      <EmptyStateCta
        imageSource={otherServices}
        imageHeight={IMAGE_HEIGHT}
        imageWidth={IMAGE_WIDTH}
        title={""}
      />

      <ActionButtons>
        <Text variant="title3" color="textPrimary">
          {t("moreServicesComing.title")}
        </Text>
        <SubtitleText variant="body1" color="textSecondary">
          {t("moreServicesComing.description")}
        </SubtitleText>

        {phoneNumber && (
          <CallCenterButton
            aria-label={t("button.callCenter", {
              phoneNumber: formatText.parsePhone(phoneNumber),
            })}
            href={`tel:${phoneNumber}`}
            target="_blank"
            variant="secondary"
          >
            <>
              <Phone background={{ size: 16, color: "transparent" }} />
              <IconText color="brandPrimary" variant="title3">
                {formatText.parsePhone(phoneNumber)}
              </IconText>
            </>
          </CallCenterButton>
        )}
        <BottomButton fullWidth="flex" onClick={() => handleBack()} variant="primary">
          {t("moreServicesComing.done")}
        </BottomButton>
      </ActionButtons>
    </MainContent>
  );
};

export default withAITracking(reactPlugin, MoreService, undefined, "ai-tracking");
