import { gql } from "@apollo/client";

export default gql`
  mutation SendVerification($type: String!, $to: String!, $name: String!) {
    sendVerification(type: $type, to: $to, name: $name) {
      verification {
        id
        status
        to
        type
      }
    }
  }
`;
