import styled from "styled-components";

import { layout } from "/styles";

export const VirtualUrgentCareContainer = styled.div(
  layout.flexVertical,
  layout.margin("skip", "auto"),
  () => ({
    maxWidth: 480,
  }),
);
