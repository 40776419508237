import { addDays, addMonths, parseISO } from "date-fns";

import { defaultRadius, SchedulingState } from "/apollo/client/local/scheduling";
import { date as dateUtil } from "/util";

/**
 * Format the date range which will be rendered in the InlineEmptyState
 */
export const formatDateRange = (minStartDate: string | null | undefined) => {
  const minDate = parseISO(minStartDate || new Date().toISOString());
  const maxDate = addDays(addMonths(minDate, 6), -1).toISOString();

  return {
    startDate: dateUtil.formatDate(minDate, "MMMM do, yyyy"),
    endDate: dateUtil.formatDate(maxDate, "MMMM do, yyyy"),
  };
};

/**
 * Given the scheduling reactive var, pull out any variables we need to pass to the availabilities query
 */
export const getQueryVariables = ({ filters, ...variables }: SchedulingState) => {
  let minStartDate;

  // Strip timezone information from the minStartDate before sending to the API
  if (filters.minStartDate) {
    const formattedDate = dateUtil.formatDate(filters.minStartDate, "yyyy-MM-dd");
    minStartDate = `${formattedDate}T00:00:00.000Z`;
  }

  // TODO: we could do this a bit more gracefully, picking out only the
  // fields that are needed by the query
  return {
    ...variables,
    ...filters,
    minStartDate,
  };
};

/**
 * Count how many filters have been applied to the Multiple Provider view
 */
export const getAllFilterCount = (filters: SchedulingState["filters"]) => {
  let count = 0;
  if (filters.daysOfWeek.length) count += 1;
  if (filters.gender) count += 1;
  if (filters.language !== null) count += 1;
  if (filters.radius !== defaultRadius) count += 1;
  if (filters.minStartTime) count += 1;
  if (filters.minStartDate) count += 1;

  return count;
};

/**
 * Count how many filters have been applied to the Single Provider view
 */
export const getSelectedFilterCount = (filters: SchedulingState["filters"]) => {
  let count = 0;
  if (filters.daysOfWeek.length) count += 1;
  if (filters.minStartTime) count += 1;
  if (filters.minStartDate) count += 1;

  return count;
};
