import { gql, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";

import { ProviderDetailsQuery, ProviderDetailsQueryVariables } from "/apollo/schema/types";

export const providerDetailsQuery = gql`
  query ProviderDetailsQuery($providerId: String!) {
    contactInfo {
      name
      phoneNumber
    }
    provider(id: $providerId) {
      athenaId
      bio
      experianFlowId
      facilities {
        address {
          city
          line1
          line2
          postalCode
          state
        }
        coordinates {
          lat
          lng
        }
        facilityNameLong
        id
      }
      id
      languages
      name {
        firstName
        lastName
      }
      nameWithDesignations
      photoUrl
      primarySpecialty
      qualifications {
        description
        type
      }
    }
  }
`;

const useSchedulingProviderDetailsModal = (providerId: string, skipProp: boolean) => {
  const [skip, setSkip] = useState(skipProp);

  // We only want to skip initially so we don't fetch the data prematurely. After that we can read
  // the value from the FE cache, so no need to skip.
  useEffect(() => {
    if (!skipProp) {
      setSkip(false);
    }
  }, [skipProp]);

  return useQuery<ProviderDetailsQuery, ProviderDetailsQueryVariables>(providerDetailsQuery, {
    variables: { providerId },
    skip,
  });
};

export default useSchedulingProviderDetailsModal;
