// Modules
import {
  ApolloClient as BaseApolloClient,
  createHttpLink,
  from,
  NormalizedCacheObject,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import { errorLink } from "/util/error";

import { auth, cache as localCache } from "./local";

const httpLink = createHttpLink({
  uri: `${process.env.API_URL}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${auth.read().token}`,
      caregiver: headers?.caregiver ? headers.caregiver : `${auth.read().caregiver}`,
    },
  };
});

export const createClient = (): BaseApolloClient<NormalizedCacheObject> => {
  const client = new BaseApolloClient({
    cache: localCache,
    link: from([errorLink, authLink, httpLink]),
    connectToDevTools: process.env.NODE_ENV === "development",
  });

  return client;
};

const client = createClient();

export default client;
export type ApolloClient = typeof client;
