/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Transition as HeadlessTransition } from "@headlessui/react";
import React, { Fragment } from "react";

import { transitionKeys as keys } from "/constant/animation.constant";

import { TransitionChildProps, TransitionProps } from "./Transition.types";

const Transition = ({ children, ...rest }: TransitionProps) => {
  let as;
  // If our Transition only has a single child, **and it's not a Transition.Child**, we want to render
  // it in a Fragment as the child should be the direct child of our Transition.
  //
  // Note: There isn't a reason to pass a single Transition.Child component to Transition. This case handles it just incase.
  //
  // @ts-ignore TS complains about `type` not being defined on string. We will still get the desired result if `children` is a string
  if (React.Children.count(children) === 1 && children?.type !== Child) {
    as = Fragment;
  }

  return (
    <HeadlessTransition
      {...rest}
      as={as}
      enter={[keys.transition, keys.enter].join(" ")}
      leave={[keys.transition, keys.leave].join(" ")}
      enterFrom={keys.start}
      enterTo={keys.finish}
      leaveFrom={keys.finish}
      leaveTo={keys.start}
    >
      {children}
    </HeadlessTransition>
  );
};

const Child = (props: TransitionChildProps) => {
  return (
    <HeadlessTransition.Child
      {...props}
      as={Fragment}
      enter={[keys.transition, keys.enter].join(" ")}
      leave={[keys.transition, keys.leave].join(" ")}
      enterFrom={keys.start}
      enterTo={keys.finish}
      leaveFrom={keys.finish}
      leaveTo={keys.start}
    />
  );
};

Transition.Child = Child;

export default Transition;
