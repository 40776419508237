import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { layout } from "/styles";

const borderWidth = 2;

export const Base = styled.div(layout.flexCenterHorizontal, layout.spacedChildrenHorizontal(), {
  // Ensure the height of the tabbar is always 40px regardless of the size of any before/after elements
  height: 40,

  // Keep the nav left aligned but let it fill available space
  nav: {
    flex: "1 1 auto",
  },
});

export const LinkItem = styled.li(({ theme }) => ({
  display: "inline-block",

  "& + &": {
    marginLeft: theme.spacing.gutter,
  },
}));

export const Link = styled(NavLink)<{ $isActive: boolean }>(
  layout.padding("condensed", "skip"),
  ({ theme, $isActive }) => ({
    color: $isActive ? theme.colors.textTitle : theme.colors.textTitleSecondary,
    display: "block",
    position: "relative",

    "&::after": {
      backgroundColor: theme.colors.textPrimary,
      bottom: 0,
      content: "''",
      height: borderWidth,
      left: 0,
      position: "absolute",
      right: 0,
      transform: $isActive ? "none" : "scaleX(0)",
      transformOrigin: "center",
      transition: "transform 300ms ease",
    },
  }),
);

export const TabButton = styled.button<{ $isActive: boolean }>(
  layout.padding("condensed", "skip"),
  ({ theme, $isActive }) => ({
    minWidth: 50,
    color: $isActive ? theme.colors.brandPrimary : theme.colors.textSecondary,
    display: "block",
    position: "relative",

    "&::after": {
      backgroundColor: theme.colors.brandPrimary,
      bottom: 0,
      content: "''",
      height: borderWidth,
      left: 0,
      position: "absolute",
      right: 0,
      transform: $isActive ? "none" : "scaleX(0)",
      transformOrigin: "center",
      transition: "transform 300ms ease",
    },
  }),
);
