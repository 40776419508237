import awsdk from "awsdk";
import React, { ChangeEvent, useEffect } from "react";
import { AlertCircle } from "react-feather";
import { useHistory } from "react-router";

import {
  Button,
  CheckBox,
  InlineMessage,
  InputWrapper,
  ListItem,
  Loading,
  Spinner,
  TextInput,
} from "/component/base";
import { CheckCircle } from "/component/base/Icon";
import Text, { Label } from "/component/base/Text";
import TelehealthErrorModal from "/component/modal/TelehealthErrorModal";
import { ErrorModalVariants } from "/component/modal/TelehealthErrorModal/TelehealthErrorModal.types";
import TelehealthPaymentModal, { PaymentMethod } from "/component/modal/TelehealthPaymentModal";
import FormContent from "/component/partial/FormContent";
import { useAmwellPrevisitContext } from "/component/provider/AmwellPrevisitProvider";
import { useCaregiverProvider } from "/component/provider/CaregiverProvider";
import { useToastContext } from "/component/provider/ToastProvider";
import { useTranslation } from "/hook";
import { useAnalytics } from "/hook/useAnalytics/useAnalytics";
import { layout } from "/styles";
import { colors } from "/theme/default/colors";
import { AnalyticsEvent, logEvent } from "/util/firebase.util";

import {
  Container,
  CouponButton,
  CreditCardNoteContainer,
  PaymentContainer,
  VisitCostCheckboxContainer,
} from "../Telehealth.styles";
import { TelehealthFormProps } from "../Telehealth.types";
import { useShowAmwellCoupon } from "./useShowAmwellCoupon";

const VisitCost = ({ handleSubmit, handleBack }: TelehealthFormProps<undefined>) => {
  const { t } = useTranslation("form-telehealth-previsit");
  const history = useHistory();
  const { showToast } = useToastContext();
  const { recordAnalyticsEvent } = useAnalytics();

  const { formValues, visitContext, setVisit, visit, consumer, sdk } = useAmwellPrevisitContext();
  const { caregiverMode } = useCaregiverProvider();
  const { data: flag, loading: flagLoading } = useShowAmwellCoupon();

  const [showPayment, setShowPayment] = React.useState(false);
  const [applyingCoupon, setApplyingCoupon] = React.useState(false);
  const [couponApplied, setCouponApplied] = React.useState(false);
  const [couponCode, setCouponCode] = React.useState("");
  const [visitCost, setVisitCost] = React.useState<awsdk.AWSDKVisitCost | undefined>();
  const [paymentMethod, setPaymentMethod] = React.useState<awsdk.AWSDKPaymentMethod | undefined>();
  const [loading, setLoading] = React.useState(true);
  const [ccLoading, setCCLoading] = React.useState(false);
  const [visitStarted, setVisitStarted] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const [errorModalVariant, setErrorModalVariant] = React.useState<ErrorModalVariants>();
  const [eligibilityFailed, setEligibilityFailed] = React.useState(true);

  const hasInsurance = !!formValues?.TelehealthInsurance.hasInsurance;

  const visitCostFormatted = visitCost ? visitCost.expectedConsumerCopayCost.toFixed(2) : "0.00";

  const shouldShowCouponCode = !flagLoading && flag?.amwellCouponEnabled === "true";

  // the user can't start a visit if there is no payment method (or expired) and there is a visit cost
  // if the visit cost is $0 (fully-covered or coupon), it doesn't matter if there is a payment method or it's expired
  const isDisabled =
    ((!paymentMethod || paymentMethod?.isExpired) &&
      (!visitCost ||
        visitCost?.expectedConsumerCopayCost > 0 ||
        visitCost?.deferredBillingInEffect)) ||
    !checked;

  useEffect(() => {
    const getVisitCost = async () => {
      if (sdk && visitContext) {
        try {
          const _visit = await sdk.visitService.createOrUpdateVisit(visitContext);
          const _visitCost = await sdk.visitService.waitForVisitCostCalculationToFinish(_visit);
          setVisitCost(_visitCost.cost);
          if (
            _visitCost.cost.eligibilityRequestStatus ===
              awsdk.AWSDKEligibilityRequestStatus.FAILED ||
            _visitCost.cost.eligibilityRequestStatus ===
              awsdk.AWSDKEligibilityRequestStatus.REQUEST_VALIDATION_RESPONSE
          ) {
            setEligibilityFailed(true);
          }

          setCCLoading(true);
          const paymentMethod = await sdk.consumerService.getPaymentMethod(consumer);
          setCCLoading(false);
          setPaymentMethod(paymentMethod);
        } catch (e: any) {
          if (e["__errorCode"] === "providerNotAvailable") {
            setErrorModalVariant("providerUnavailable");
            setShowErrorModal(true);
          } else if (e["__errorCode"] === "inaccurateSubscriberInfo") {
            setEligibilityFailed(true);
          } else if (e["__errorCode"] !== "noPaymentInformationFound") {
            showToast({
              message: t("unknownError"),
              icon: "alert",
              type: "error",
            });
          }
        }
        setLoading(false);
        setCCLoading(false);
      }
    };

    getVisitCost();
  }, [sdk, visitContext]);

  const handleStartVisit = async () => {
    if (visit) {
      setVisitStarted(true);
      const visitCalc = await sdk?.visitService.waitForVisitCostCalculationToFinish(visit);
      if (visitCalc) {
        const isCaregiver = caregiverMode ? "true" : "false";
        if (visit.firstAvailableData) {
          logEvent(AnalyticsEvent.AMWELL_VISIT_FFA_START, { is_caregiver: isCaregiver });
          recordAnalyticsEvent("amwellVisitFFAStart");
        } else {
          logEvent(AnalyticsEvent.AMWELL_VISIT_START, { is_caregiver: isCaregiver });
          recordAnalyticsEvent("amwellVisitStart");
        }

        setVisit(await sdk?.visitService.startVisit(visitCalc));
      }

      handleSubmit();
      setVisitStarted(false);
    }
  };

  const handlePayment = async (payment: PaymentMethod) => {
    const paymentRequest = await sdk?.consumerService.newPaymentRequest();
    if (paymentRequest && consumer && sdk) {
      setShowPayment(false);
      paymentRequest.address1 = payment.address1;
      paymentRequest.address2 = payment.address2;
      paymentRequest.city = payment.city;
      paymentRequest.state = payment.state;
      paymentRequest.country = "US";
      paymentRequest.nameOnCard = payment.billingName;
      const [expirationMonth, expirationYear] = payment.expirationDate.split("/");
      paymentRequest.creditCardMonth = parseInt(expirationMonth);
      paymentRequest.creditCardYear = parseInt(`20${expirationYear}`);
      paymentRequest.creditCardZip = payment.zip;
      paymentRequest.creditCardSecCode = payment.securityCode.toString();
      paymentRequest.creditCardNumber = payment.cardNumber;
      setCCLoading(true);
      await sdk?.consumerService.updatePaymentMethod(consumer, paymentRequest);
      const updatedPaymentMethod = await sdk.consumerService.getPaymentMethod(consumer);
      setPaymentMethod(updatedPaymentMethod);
      setCCLoading(false);
    }
  };

  const handleApplyCoupon = async () => {
    if (sdk && visit) {
      setApplyingCoupon(true);
      try {
        await sdk?.visitService.waitForVisitCostCalculationToFinish(visit);
        await sdk.visitService.applyCouponCode(visit, couponCode);
        const visitCalc = await sdk?.visitService.waitForVisitCostCalculationToFinish(visit);
        if (visitCalc) {
          setVisitCost(visitCalc.cost);
          setCouponApplied(true);
        }
      } catch (e: any) {
        showToast({
          message: e.message,
          icon: "alert",
          type: "error",
        });
        setCouponApplied(false);
      }
      setApplyingCoupon(false);
    }
  };

  const getCheckboxText = () => {
    if (visitCost && !visitCost.eligibilityRequestStatus) {
      return t("visitCost.checkbox.notCovered", { fee: visitCost?.expectedConsumerCopayCost });
    } else if (
      visitCost &&
      visitCost.eligibilityRequestStatus === awsdk.AWSDKEligibilityRequestStatus.COVERED
    )
      return t("visitCost.checkbox.covered");
    else return t("visitCost.checkbox.failed");
  };

  if (loading) {
    return <Loading />;
  }

  // fully-covered insurance, no coupon code
  if (
    visitCost?.expectedConsumerCopayCost === 0 &&
    !visitCost?.deferredBillingInEffect &&
    !couponApplied
  ) {
    return (
      <FormContent css={layout.margin("gutter", "skip")}>
        <div css={[layout.flexCenterHorizontal, layout.margin("skip", "skip", "gutter", "skip")]}>
          <CheckCircle color="textNavyBlue" />

          <Text
            css={layout.margin("skip", "skip", "skip", "condensed")}
            color="textNavyBlue"
            variant="title2"
          >
            {t("visitCost.planAccepted.title")}
          </Text>
        </div>

        <Text variant="body2">{t("visitCost.planAccepted.subtitle")}</Text>

        <VisitCostCheckboxContainer>
          <CheckBox
            label={<Label>{getCheckboxText()}</Label>}
            labelBefore={false}
            checked={checked}
            onChange={() => setChecked(!checked)}
          />
        </VisitCostCheckboxContainer>

        <FormContent.ButtonGroup>
          <Button
            variant="primary"
            isDisabled={isDisabled}
            isLoading={visitStarted}
            onClick={handleStartVisit}
            type="submit"
          >
            {t("startVisit")}
          </Button>
        </FormContent.ButtonGroup>
      </FormContent>
    );
  }

  return (
    <>
      <FormContent css={layout.margin("gutter", "skip")}>
        <Text color="textNavyBlue" variant="title2">
          {t("visitCost.title", { amount: visitCostFormatted })}
        </Text>

        {couponApplied && (
          <InlineMessage
            css={[layout.padding("standard"), layout.margin("expanded", "none", "none", "none")]}
            icon={<CheckCircle />}
          >
            <Text variant="body2Bold">{t("visitCost.couponApplied")}</Text>
          </InlineMessage>
        )}

        {hasInsurance && eligibilityFailed && (
          <InlineMessage
            css={[layout.padding("standard"), layout.margin("expanded", "none", "none", "none")]}
          >
            <div css={layout.flexVertical}>
              <Text css={layout.margin("none", "none", "condensed", "none")} variant="body2Bold">
                {t("visitCost.eligbilityFailedTitle")}
              </Text>
              <Text variant="body2">{t("visitCost.eligbilityFailedSubtitle")}</Text>
            </div>
          </InlineMessage>
        )}

        {shouldShowCouponCode && visitCost?.canApplyCouponCode && (
          <Container>
            <InputWrapper label={t("visitCost.couponLabel")}>
              <div css={layout.flexLTR}>
                <TextInput
                  disabled={couponApplied}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setCouponCode(e.target.value)}
                  css={{ flexGrow: 1 }}
                  value={couponCode}
                ></TextInput>
                <CouponButton
                  isDisabled={couponApplied}
                  isLoading={applyingCoupon}
                  type="button"
                  variant="tertiary"
                  onClick={handleApplyCoupon}
                >
                  {couponApplied ? (
                    <Text color="statusPositive" variant="body1Bold">
                      {t("applied")}
                    </Text>
                  ) : (
                    <Text color="brandPrimary" variant="body1Bold">
                      {t("apply")}
                    </Text>
                  )}
                </CouponButton>
              </div>
            </InputWrapper>
          </Container>
        )}

        <div css={layout.margin(60, "none", "none", "none")}>
          {paymentMethod?.isExpired && (
            <InlineMessage
              variant="important"
              css={[layout.padding("standard"), layout.margin("none", "none", "standard", "none")]}
              icon={<AlertCircle />}
            >
              <Text variant="body2">{t("error.creditCardExpired")}</Text>
            </InlineMessage>
          )}

          {ccLoading ? (
            <Spinner />
          ) : (
            <>
              <ListItem.Button showBorder={true} onClick={() => setShowPayment(true)}>
                <PaymentContainer>
                  <Text variant="body2" color="textSecondary">
                    {paymentMethod
                      ? t("visitCost.paymentMethodLabel")
                      : t("visitCost.noPaymentMethodLabel")}
                  </Text>
                  <Text variant="body1Bold">
                    {paymentMethod
                      ? `${paymentMethod?.type} - ${paymentMethod?.lastDigits}`
                      : t("visitCost.addCreditCard")}
                  </Text>
                </PaymentContainer>
              </ListItem.Button>
              <CreditCardNoteContainer>
                <AlertCircle
                  color={colors.textSecondary}
                  size={20}
                  css={layout.margin("none", "condensed", "none", "none")}
                />
                <Label align="center" variant="body2">
                  {t("visitCost.creditCardNote")}
                </Label>
              </CreditCardNoteContainer>
            </>
          )}
        </div>

        <VisitCostCheckboxContainer>
          <CheckBox
            label={<Label>{getCheckboxText()}</Label>}
            labelBefore={false}
            checked={checked}
            onChange={() => setChecked(!checked)}
          />
        </VisitCostCheckboxContainer>

        <FormContent.ButtonGroup>
          <Button
            variant="primary"
            isDisabled={isDisabled}
            isLoading={visitStarted}
            onClick={handleStartVisit}
            type="submit"
          >
            {t("startVisit")}
          </Button>
        </FormContent.ButtonGroup>
      </FormContent>

      <TelehealthPaymentModal
        isOpen={showPayment}
        close={() => setShowPayment(false)}
        handleSubmit={handlePayment}
        paymentMethod={paymentMethod}
        cost={visitCostFormatted}
      />

      <TelehealthErrorModal
        variant={errorModalVariant}
        isOpen={showErrorModal}
        primaryButtonCallback={() => {
          setShowErrorModal(false);
          if (errorModalVariant === "providerUnavailable") {
            history.replace("/telehealth");
          } else if (errorModalVariant === "eligibilityFailed") {
            handleBack();
          }
        }}
        secondaryButtonCallback={() => {
          if (errorModalVariant === "eligibilityFailed") {
            setShowErrorModal(false);
          }
        }}
      />
    </>
  );
};

export default VisitCost;
