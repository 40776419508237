import { useField } from "formik";
import React from "react";

import { Text } from "/component/base";
import CheckBox from "/component/base/CheckBox";
import InputWrapper from "/component/base/InputWrapper";
import { layout } from "/styles";

import { Props } from "./CheckListField.types";

/**
 * The CheckListField will render a list of Checkboxes that is meant to be used in Formik forms.
 * The type of the Formik field should be an array of values that match the value type of the `options`
 * array - T[]. All states are computed, based on the `options` and `field.value`.
 */
const CheckListField = <T,>({
  name,
  label,
  inputWrapperVariant = "primary",
  className,
  options,
  checkboxLabelBefore,
}: Props<T>) => {
  const [field, meta, helpers] = useField(name);

  return (
    <InputWrapper
      variant={inputWrapperVariant}
      className={className}
      error={meta.error}
      htmlFor={field.name}
      label={label}
      touched={meta.touched}
    >
      <ul css={layout.spacedChildrenVertical("expanded")}>
        {options.map((option, i) => {
          const isChecked = field.value.includes(option.value);

          // If the label is a string render it in a consistent `body1Bold` font. Otherwise render
          // whatever is passed in.
          const labelChild =
            typeof option.label === "string" ? (
              <Text variant="body1Bold">{option.label}</Text>
            ) : (
              option.label
            );

          return (
            <li key={i}>
              <CheckBox
                disabled={option.disabled}
                labelBefore={checkboxLabelBefore}
                checked={isChecked}
                onChange={(e) => {
                  if (e.target.checked) {
                    // Check if this value is already inside the field values array to avoid adding duplicates
                    if (!isChecked) {
                      helpers.setValue([...field.value, option.value]);
                    }
                  } else {
                    helpers.setValue(field.value.filter((val: T) => val !== option.value));
                  }
                }}
                label={labelChild}
              />
            </li>
          );
        })}
      </ul>
    </InputWrapper>
  );
};

export default CheckListField;
