import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import caregiverOptIn from "/asset/image/caregiver-optIn.png";
import { Button, Modal } from "/component/base";
import { layout } from "/styles";
import theme from "/theme/default";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  logEvent,
  ScreenViewParams,
} from "/util/firebase.util";

import { FormContentContainer, SubTitle, Title } from "./CaregiverOptInModal.styles";
import { Props } from "./CaregiverOptInModal.types";

const CaregiverOptInModal = ({ isOpen, close, addCaregiver }: Props) => {
  const { t } = useTranslation("modal-caregiverOptIn");

  useEffect(() => {
    logEvent(AnalyticsEvent.SCREEN_VIEW, {
      screenName: AnalyticsSource.CAREGIVER_ACCESS_OPT_IN,
    } as ScreenViewParams);
  }, []);

  const logButtonClickEvent = (buttonName: string) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.CAREGIVER,
      source: AnalyticsSource.CAREGIVER_ACCESS_OPT_IN,
      button_name: buttonName,
    } as ButtonClickParams);
  };

  return (
    <Modal
      isOpen={isOpen}
      footer={
        <Modal.FooterWithLargeButton
          css={[layout.padding("none", theme.spacing["80"], "gutter", theme.spacing["80"])]}
        >
          <FormContentContainer.ButtonGroup>
            <Button
              variant="primary"
              onClick={() => {
                logButtonClickEvent("Let's get started");
                addCaregiver();
              }}
            >
              {t("getStarted")}
            </Button>
          </FormContentContainer.ButtonGroup>
          <FormContentContainer.ButtonGroup>
            <Button
              variant="secondary"
              onClick={() => {
                logButtonClickEvent("Add later");
                close && close();
              }}
            >
              {t("addLater")}
            </Button>
          </FormContentContainer.ButtonGroup>
        </Modal.FooterWithLargeButton>
      }
    >
      <div
        css={[
          layout.margin(theme.spacing["60"], "skip", "skip", "skip"),
          layout.flexVerticalCenter,
          layout.spacedChildrenVertical("standard"),
        ]}
      >
        <img src={caregiverOptIn} alt="empty state" aria-hidden />
        <Title>{t("title")}</Title>
        <SubTitle>{t("description")}</SubTitle>
      </div>
    </Modal>
  );
};
export default CaregiverOptInModal;
