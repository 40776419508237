import React, { forwardRef } from "react";

import { Base } from "./Spinner.styles";
import { Props, SpinnerColors } from "./Spinner.types";
import spinnerActionBlue from "./spinner_action_blue.png";
import spinnerWhite from "./spinner-white.png";

const getSrcForColor = (color: SpinnerColors) => {
  switch (color) {
    case "objectInverse":
      return spinnerWhite;
    default:
      return spinnerActionBlue;
  }
};

const Spinner = forwardRef<HTMLImageElement, Props>(
  ({ color = "brandPrimary", size = 24, ...otherProps }, ref) => (
    <Base alt="loading" ref={ref} {...otherProps} $size={size} src={getSrcForColor(color)} />
  ),
);

export default Spinner;
