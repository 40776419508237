import { Link } from "react-router-dom";
import styled from "styled-components";

import { ChevronRight } from "/component/base/Icon";
import { layout } from "/styles";

import { ButtonProps, LinkProps, Props } from "./ListItem.types";

const interactiveStyles = {
  // TODO: can we come up with a better focus state? Currently defaults to default browser outline
  "&:active": {
    opacity: 0.6,
  },
};

const baseStyles = () => {
  return {
    backgroundColor: "transparent",
    justifyContent: "space-between",
    alignItems: "center",
    textDecoration: "none",
  };
};

export const BaseButton = styled.button<ButtonProps & { $showBorder?: boolean }>(
  baseStyles,
  layout.flexCenterHorizontal,
  () => ({
    // Override button borders
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    width: "100%",
    textAlign: "start",
  }),
);

export const BaseLink = styled(Link)<LinkProps & { $showBorder?: boolean }>(
  baseStyles,
  interactiveStyles,
  layout.flexCenterHorizontal,
  () => ({
    color: "inherit",
  }),
);

export const BaseDiv = styled.div<Props & { $showBorder?: boolean }>(
  baseStyles,
  layout.flexCenterHorizontal,
  () => ({
    width: "100%",
  }),
);

// Chevron Icon Wrapper
export const Chevron = styled(ChevronRight)({
  flexShrink: 0,
});
