import React from "react";

import { Alert, Button, Text } from "/component/base";
import { useTranslation } from "/hook";
import { layout } from "/styles";

import { Props } from "./CancelCaregiverWizardModal.types";

const CancelCaregiverWizardModal = ({ isOpen, onConfirm, onCancel }: Props) => {
  const { t } = useTranslation("partial-CancelCaregiverWizard");

  return (
    <Alert
      close={() => null}
      isOpen={isOpen}
      title={t("title")}
      footer={
        <div css={[layout.flexVerticalCenter, layout.spacedChildrenVertical("standard")]}>
          <Button fullWidth="flex" onClick={onCancel} variant="secondary">
            {t("cancel")}
          </Button>
          <Button fullWidth="flex" onClick={onConfirm} variant="primary">
            {t("confirm")}
          </Button>
        </div>
      }
    >
      <Text variant="body1" color="textSecondary">
        {t("subtitle")}
      </Text>
    </Alert>
  );
};

export default CancelCaregiverWizardModal;
