import { useMutation } from "@apollo/client";
import { Form, Formik, FormikHelpers } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import sendVerificationGql from "/apollo/mutation/sendVerification";
import updateMfaPhoneNumberGql from "/apollo/mutation/updateMFAPhoneNumber";
import updateVerificationGql from "/apollo/mutation/updateVerification";
import {
  SendVerification_sendVerification,
  SendVerificationVariables,
  UpdateMFAPhoneNumber,
  UpdateMFAPhoneNumberVariables,
  UpdateVerification,
} from "/apollo/schema";
import { Button } from "/component/base";
import { Smartphone } from "/component/base/Icon";
import { useAccountQuery } from "/component/page/Account/page/Settings/useSettings";
import TextField from "/component/partial/formik/TextField";
import { SignUpFormType } from "/constant/signUp.constant";
import { useTranslation } from "/hook";
import { layout } from "/styles";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  logEvent,
} from "/util/firebase.util";

import { FormContentContainer, SubTitle, Title } from "../SignUpForms.styles";
import { SignUpFormProps } from "../SignUpForms.types";

type PhoneVerificationValues = { code: string };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PhoneVerification = ({ handleSubmit }: SignUpFormProps<any>) => {
  const { t } = useTranslation("form-signUp");

  const [sendVerification] = useMutation<
    SendVerification_sendVerification,
    SendVerificationVariables
  >(sendVerificationGql);
  const [updateMfaPhoneNumber] = useMutation<UpdateMFAPhoneNumber, UpdateMFAPhoneNumberVariables>(
    updateMfaPhoneNumberGql,
  );
  const [updateVerification] = useMutation<UpdateVerification>(updateVerificationGql);

  const { data } = useAccountQuery();
  const [enterCode, setEnterCode] = useState(false);
  const currentPhoneNumber = data?.viewer?.user.account.phoneNumber;
  const [phoneEntered, setPhoneEntered] = useState("");

  useEffect(() => {
    setPhoneEntered(currentPhoneNumber || "");
  }, [currentPhoneNumber]);

  const phoneNumberSchema = Yup.object({
    phone: Yup.string().required("Required"),
  });
  const codeSchema = Yup.object({
    code: Yup.string().required("Required"),
  });

  const resendCode = async () => {
    logButtonClickEvent("Resend code");

    const { firstName, lastName } = data?.viewer?.user?.account || {};

    await sendVerification({
      variables: {
        name: `${firstName} ${lastName}`,
        type: "sms",
        to: phoneEntered,
      },
    });
  };

  const editPhoneNumber = () => {
    setEnterCode(false);
  };

  const validateCode = async (
    values: PhoneVerificationValues,
    { setErrors }: FormikHelpers<PhoneVerificationValues>,
  ) => {
    if (!phoneEntered) return;

    logButtonClickEvent("Continue", "Verification code");

    const response = await updateVerification({
      variables: {
        input: {
          type: "sms",
          code: values.code,
        },
      },
    });

    if (response.data?.updateVerification.verification?.status === "approved") {
      const { errors: mfaErrors } = await updateMfaPhoneNumber({
        variables: {
          data: {
            mfaPhoneNumber: phoneEntered,
          },
        },
      });

      if (mfaErrors) return;

      handleSubmit(SignUpFormType.EmailVerification);
    } else {
      setErrors({ code: t("error.codeInvalid") });
    }
  };

  const submitForm = async (values: { phone: string }) => {
    logButtonClickEvent("Continue", "Phone number");

    const phone = `${values.phone}`;
    setPhoneEntered(phone);
    const { firstName, lastName } = data?.viewer?.user?.account || {};

    await sendVerification({
      variables: {
        name: `${firstName} ${lastName}`,
        type: "sms",
        to: phone,
      },
    });

    setEnterCode(true);
  };

  const logButtonClickEvent = (buttonName: string, buttonDescription?: string) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.ONBOARDING_NEW,
      source: AnalyticsSource.ONBOARDING_SMS_VERIFICATION,
      button_name: buttonName,
      button_description: buttonDescription,
    } as ButtonClickParams);
  };

  if (enterCode) {
    return (
      <Formik
        validateOnMount
        enableReinitialize
        initialValues={{ code: "" }}
        validationSchema={codeSchema}
        onSubmit={validateCode}
      >
        {({ isSubmitting, isValid }) => (
          <FormContentContainer>
            <Form>
              <Title>{t("phoneVerification.title")}</Title>
              <SubTitle>{t("phoneVerification.codeSubtitle")}</SubTitle>
              <div
                css={[layout.spacedChildrenVertical("standard"), layout.marginVertical("standard")]}
              >
                <TextField
                  label={t("phoneVerification.enterCode")}
                  placeholder={t("phoneVerification.enterCodePlaceholder")}
                  name="code"
                  type="text"
                />
              </div>
              <div css={[layout.flexVertical, layout.spacedChildrenVertical()]}>
                <Button
                  variant="primary"
                  isDisabled={!isValid}
                  type="submit"
                  isLoading={isSubmitting}
                >
                  {t("continue")}
                </Button>
                <Button
                  variant="secondary"
                  type="button"
                  onClick={resendCode}
                  isLoading={isSubmitting}
                >
                  {t("resendCode")}
                </Button>
                <Button
                  variant="secondary"
                  type="button"
                  onClick={editPhoneNumber}
                  isLoading={isSubmitting}
                >
                  {t("editPhoneNumber")}
                </Button>
              </div>
            </Form>
          </FormContentContainer>
        )}
      </Formik>
    );
  }

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={{ phone: phoneEntered }}
      validationSchema={phoneNumberSchema}
      onSubmit={submitForm}
    >
      {({ isSubmitting, isValid }) => (
        <FormContentContainer>
          <Form>
            <div css={{ textAlign: "center" }}>
              <Smartphone size={80} color="brandPrimary" />
              <div css={layout.marginVertical("standard")}>
                <Title>{t("phoneVerification.title")}</Title>
                <SubTitle>{t("phoneVerification.subtitle")}</SubTitle>
              </div>
              <div css={[layout.spacedChildrenVertical("gutter"), layout.marginVertical("gutter")]}>
                <TextField
                  label={t("phoneVerification.phone")}
                  placeholder={t("phoneVerification.phonePlaceholder")}
                  name="phone"
                  type="text"
                />
              </div>
              <FormContentContainer.ButtonGroup>
                <Button
                  variant="primary"
                  isDisabled={!isValid}
                  type="submit"
                  isLoading={isSubmitting}
                >
                  {t("continue")}
                </Button>
              </FormContentContainer.ButtonGroup>
            </div>
          </Form>
        </FormContentContainer>
      )}
    </Formik>
  );
};

export default PhoneVerification;
