import styled, { css, keyframes } from "styled-components";

import { Props } from "./Spinner.types";

const animation = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

export const Base = styled.img<Omit<Props, "size"> & { $size?: number }>(
  ({ $size }) => ({
    width: $size,
    height: $size,
  }),
  css`
    animation: ${animation} 1s infinite linear;
  `,
);
