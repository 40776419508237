import { Form, getIn, useFormikContext } from "formik";
import * as React from "react";

import { StateCode } from "/apollo/schema";
import { CheckBox, InputWrapper, Text } from "/component/base";
import { Label } from "/component/base/Text";
import AddressCombobox from "/component/partial/AddressCombobox";
import FormattedTextField from "/component/partial/formik/FormattedTextField";
import PickerField from "/component/partial/formik/PickerField";
import TextField from "/component/partial/formik/TextField";
import { useTranslation } from "/hook";
import { layout } from "/styles";
import { googlePlaces } from "/util";

import { StatePicker } from "../AddressForm/AddressForm.styles";
import { FormContent } from "../DemographicFormWizard/DemographicFormWizard.styles";
import { AddressDiv, CheckBoxContainer } from "./CaregiverChildForm.styles";

const CaregiveChildForm = () => {
  const { t } = useTranslation("form-CaregiverChild");

  const { values, setFieldValue, validateForm, setFieldTouched } = useFormikContext();

  const prefixName = (name: string) => `child.${name}`;
  const getValueByName = (name: string) => getIn(values, prefixName(name));

  const [useSameAddress, setUseSameAddress] = React.useState<boolean>(true);

  const handleAddressAutocompleteSelect = async (placeDetails: google.maps.places.PlaceResult) => {
    const address = googlePlaces.extractAddress(placeDetails?.address_components || []);
    await setFieldValue(prefixName("line1"), address.line1);
    await setFieldValue(prefixName("city"), address.city);
    await setFieldValue(prefixName("state"), address.state);
    await setFieldValue(prefixName("postalCode"), address.postalCode);
    await setFieldTouched(prefixName("line1"));
    validateForm();
  };

  const toggleAddress = (value: boolean) => {
    setUseSameAddress(value);
    setFieldValue(prefixName("useDifferentAddress"), !value);
    if (value) {
      setFieldValue(prefixName("line1"), null);
      setFieldValue(prefixName("line2"), null);
      setFieldValue(prefixName("city"), null);
      setFieldValue(prefixName("state"), null);
      setFieldValue(prefixName("postalCode"), null);
      setFieldTouched(prefixName("line1"), false);
      setFieldTouched(prefixName("line2"), false);
      setFieldTouched(prefixName("city"), false);
      setFieldTouched(prefixName("state"), false);
      setFieldTouched(prefixName("postalCode"), false);
    }
  };

  const renderAddress = () => {
    return (
      <div>
        <AddressDiv>
          <Text variant="title2">{t("addressTitle")}</Text>
        </AddressDiv>
        <Form>
          <FormContent>
            <InputWrapper label={t("label.address.line1")}>
              <AddressCombobox
                showIcon={false}
                formattedAddress={getValueByName("line1")}
                onBlur={async () => {
                  await setFieldTouched(prefixName("line1"));
                }}
                onChangeText={async (newText) => {
                  await setFieldValue(prefixName("line1"), newText);
                }}
                onSelectAddress={handleAddressAutocompleteSelect}
              />
            </InputWrapper>
            <TextField label={t("label.address.line2")} name={prefixName("line2")} type="text" />
            <FormContent.InnerFields>
              <TextField label={t("label.address.city")} name={prefixName("city")} type="text" />
              <StatePicker
                label={t("label.address.state")}
                name={prefixName("state")}
                options={Object.keys(StateCode).map((code) => ({ label: code, value: code }))}
                hideRadio
              />
              <TextField
                label={t("label.address.postalCode")}
                name={prefixName("postalCode")}
                type="text"
              />
            </FormContent.InnerFields>
          </FormContent>
        </Form>
      </div>
    );
  };

  return (
    <div css={layout.spacedChildrenVertical("standard")}>
      <TextField
        label={t("firstName")}
        placeholder={t("firstName")}
        name={prefixName("firstName")}
        type="text"
      />
      <TextField
        label={t("lastName")}
        placeholder={t("lastName")}
        name={prefixName("lastName")}
        type="text"
      />
      <FormattedTextField
        label={t("dateOfBirth")}
        placeholder={t("dobPlaceholder")}
        name={prefixName("dob")}
        type="text"
        mask="99/99/9999"
      />
      <FormattedTextField
        label={t("phoneNumber")}
        placeholder={t("phonePlaceholder")}
        name={prefixName("phoneNumber")}
        type="phone"
        mask="+1 (999)-999-9999"
      />

      <PickerField
        css={layout.flexVertical}
        label={t("patientIdentity.legalSex")}
        placeholder={t("patientIdentity.legalSexPlaceholder")}
        name={prefixName("legalSex")}
        options={[
          {
            label: "Female",
            value: "FEMALE",
          },
          {
            label: "Male",
            value: "MALE",
          },
        ]}
      />
      {!useSameAddress && renderAddress()}
      <CheckBoxContainer>
        <CheckBox
          type="radio"
          checked={useSameAddress}
          label={<Label>{t("useSameAddress")}</Label>}
          labelBefore={false}
          onClick={() => toggleAddress(true)}
        />
        <CheckBox
          type="radio"
          checked={!useSameAddress}
          label={<Label>{t("useDifferentAddress")}</Label>}
          labelBefore={false}
          onClick={() => toggleAddress(false)}
        />
      </CheckBoxContainer>
    </div>
  );
};

export default CaregiveChildForm;
