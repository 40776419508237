import awsdk from "awsdk";
import { Form, Formik, FormikHelpers } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import { Button, InputWrapper, Text } from "/component/base";
import FormContent from "/component/partial/FormContent";
import TextField from "/component/partial/formik/TextField";
import { useAmwellPrevisitContext, VitalsProps } from "/component/provider/AmwellPrevisitProvider";
import { MinorAge } from "/constant/telehealth.constant";
import { useTranslation } from "/hook";
import { layout } from "/styles";

import { ButtonContainer, SkipButton, Title, VitalsHeightContainer } from "../Telehealth.styles";
import { TelehealthFormProps } from "../Telehealth.types";

const Vitals = ({ handleError, handleSubmit, initialValues }: TelehealthFormProps<VitalsProps>) => {
  const { t } = useTranslation("form-telehealth-previsit");
  const { consumer, visitContext, sdk } = useAmwellPrevisitContext();
  const [formValues, setFormValues] = useState<VitalsProps>(initialValues);

  const isMinor = consumer && parseInt(consumer.age) <= MinorAge;

  const weightSchema = Yup.number()
    .typeError(t("error.numberNan"))
    .min(0, t("error.weightMin"))
    .max(1500, t("error.weightMax"))
    .nullable(true);

  const heightMajorSchema = Yup.number()
    .typeError(t("error.numberNan"))
    .min(0, t("error.heightMajorMin"))
    .max(9, t("error.heightMajorMax"))
    .nullable(true);
  const heightMinorSchema = Yup.number()
    .typeError(t("error.numberNan"))
    .min(0, t("error.heightMinorMin"))
    .max(12, t("error.heightMinorMax"))
    .nullable(true);

  const tempSchema = Yup.number()
    .typeError(t("error.numberNan"))
    .min(0, t("error.temperatureMin"))
    .max(120, t("error.temperatureMax"))
    .nullable(true);

  const systolicSchema = Yup.number()
    .typeError(t("error.numberNan"))
    .min(0, t("error.systolicMin"))
    .max(250, t("error.systolicMax"))
    .nullable(true);
  const diastolicSchema = Yup.number()
    .typeError(t("error.numberNan"))
    .min(0, t("error.diastolicMin"))
    .max(250, t("error.diastolicMax"))
    .lessThan(Yup.ref("systolic"), t("error.diastolicWithSystolic"))
    .nullable(true);

  if (isMinor) {
    weightSchema.required(t("error.weightRequired"));
    heightMajorSchema.required(t("error.heightMajorRequired"));
    heightMinorSchema.required(t("error.heightMinorRequired"));
  }

  const vitalsSchema = Yup.object().shape({
    weight: weightSchema,
    heightMajor: heightMajorSchema,
    heightMinor: heightMinorSchema,
    temperature: tempSchema,
    systolic: systolicSchema,
    diastolic: diastolicSchema,
  });

  const submitForm = async (values: VitalsProps, actions: FormikHelpers<VitalsProps>) => {
    try {
      if (consumer && visitContext && sdk) {
        const vitals = await sdk.consumerService.getVitals(consumer);

        if (values.diastolic) vitals.diastolic = values.diastolic;
        if (values.heightMajor) vitals.heightMajor = values.heightMajor;
        if (values.heightMinor) vitals.heightMinor = values.heightMinor;
        if (values.systolic) vitals.systolic = values.systolic;
        if (values.temperature) vitals.temperature = values.temperature;
        if (values.weight) {
          vitals.weightMajor = Math.floor(values.weight);
          // convert decimal part of number from pounds to oz
          vitals.weightMinor = Math.round((values.weight % 1) * 16);
        }
        await sdk.consumerService.updateVitals(consumer, vitals);
      }
      handleSubmit(values);
    } catch (e: any) {
      handleError(e, actions, values);
    }
  };

  const getWeight = (consumerVitals: awsdk.AWSDKVitals | undefined) => {
    if (initialValues.weight) return initialValues.weight;

    let _weight;
    if (consumerVitals?.weightMajor) {
      _weight = consumerVitals.weightMajor;
      if (consumerVitals?.weightMinor) {
        _weight += consumerVitals.weightMinor;
      }
    }

    return _weight;
  };

  const setupVitals = async () => {
    //Set/retrieve vitals from initial values being restored OR from the consumer vitals
    if (consumer) {
      const consumerVitals = await sdk?.consumerService.getVitals(consumer);

      setFormValues({
        weight: getWeight(consumerVitals),
        heightMajor: initialValues.heightMajor || consumerVitals?.heightMajor || null,
        heightMinor: initialValues.heightMajor || consumerVitals?.heightMinor || null,
        temperature: initialValues.temperature || null,
        systolic: initialValues.systolic || null,
        diastolic: initialValues.diastolic || null,
      });
    }
  };
  useEffect(() => {
    setupVitals();
  }, [sdk, consumer, initialValues]);

  const renderRequiredLabel = (label: string) => (
    <>
      <Text variant="body2" color="textSecondary">
        {label}
      </Text>
      {isMinor && (
        <Text variant="body2" color="brandSecondary">
          {t("vitals.label.required")}
        </Text>
      )}
    </>
  );

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={formValues}
      validationSchema={vitalsSchema}
      onSubmit={submitForm}
    >
      {({ isSubmitting, isValid }) => (
        <Form>
          <FormContent css={layout.margin("gutter", "skip")}>
            <Title>{t("vitals.title")}</Title>
            <TextField
              label={renderRequiredLabel(t("vitals.label.weight"))}
              placeholder={t("vitals.placeholder.weight")}
              name="weight"
              type="number"
            />

            <InputWrapper label={renderRequiredLabel(t("vitals.label.height"))}>
              <VitalsHeightContainer aria-label={t("vitals.label.height")}>
                <li>
                  <TextField
                    aria-label={t("vitals.label.heightFeet")}
                    placeholder={t("vitals.placeholder.heightMajor")}
                    name="heightMajor"
                    type="number"
                  />
                </li>
                <li>
                  <TextField
                    aria-label={t("vitals.label.heightInches")}
                    placeholder={t("vitals.placeholder.heightMinor")}
                    name="heightMinor"
                    type="number"
                  />
                </li>
              </VitalsHeightContainer>
            </InputWrapper>

            <TextField
              placeholder={t("vitals.placeholder.temperature")}
              label={t("vitals.label.temperature")}
              name="temperature"
              type="number"
            />

            <InputWrapper label={t("vitals.label.bloodPressure")}>
              <ul
                aria-label={t("vitals.label.systolicDiastolic")}
                css={layout.spacedChildrenVertical()}
              >
                <li>
                  <TextField
                    placeholder={t("vitals.placeholder.systolic")}
                    name="systolic"
                    type="number"
                  />
                </li>
                <li>
                  <TextField
                    placeholder={t("vitals.placeholder.diastolic")}
                    name="diastolic"
                    type="number"
                  />
                </li>
              </ul>
            </InputWrapper>

            <ButtonContainer>
              <Button
                variant="primary"
                css={layout.flexItemAuto}
                isDisabled={!isValid}
                type="submit"
                isLoading={isSubmitting}
              >
                {t("continue")}
              </Button>
              <SkipButton onClick={() => handleSubmit()} variant="borderBottom">
                {t("skip")}
              </SkipButton>
            </ButtonContainer>
          </FormContent>
        </Form>
      )}
    </Formik>
  );
};

export default Vitals;
