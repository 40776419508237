import { rgba } from "polished";
import styled from "styled-components";

import { baseTransitionTime } from "/constant/animation.constant";
import { layout } from "/styles";

interface StyleProps {
  $checked?: boolean;
  $disabled?: boolean;
}

const toggleThumbMargin = 3;
const toggleThumbSize = 24;
const toggleHeight = toggleThumbSize + toggleThumbMargin * 2;
const toggleWidth = toggleThumbSize * 2 + toggleThumbMargin * 2;

export const Checkbox = styled.input(layout.absoluteFill, {
  appearance: "unset",
  background: "transparent",
  border: "none",
  height: "100%",
  margin: 0,
  width: "100%",
});

export const Label = styled.label<StyleProps>(({ $checked, $disabled, theme }) => {
  const activeBgColor = $checked ? theme.colors.brandPrimary : theme.colors.brandPrimaryLight;
  const diabledBgColor = $checked
    ? rgba(theme.colors.brandPrimary, 0.3)
    : theme.colors.objectSubtle;

  return {
    background: $disabled ? diabledBgColor : activeBgColor,
    borderRadius: 15,
    display: "inline-block",
    height: toggleHeight,
    position: "relative",
    transition: `background ${baseTransitionTime}ms ease`,
    width: toggleWidth,
  };
});

export const Thumb = styled.span<StyleProps>(({ $checked, theme }) => ({
  background: theme.colors.objectInverse,
  borderRadius: "50%",
  bottom: 0,
  boxShadow: theme.shadows.small,
  height: toggleThumbSize,
  left: 0,
  margin: toggleThumbMargin,
  position: "absolute",
  top: 0,
  transform: $checked ? "translateX(100%)" : "none",
  transition: `transform ${baseTransitionTime}ms ease`,
  width: toggleThumbSize,
}));
