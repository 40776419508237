import { Formik } from "formik";
import React, { useEffect, useState } from "react";

import { StateCode } from "/apollo/schema/types";
import Modal from "/component/base/Modal";
import AddressForm, { formAddressSchema } from "/component/form/AddressForm";
import { AddressFormValues } from "/component/form/AddressForm/AddressForm.types";
import { useTranslation } from "/hook";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  logEvent,
  ScreenViewParams,
} from "/util/firebase.util";

import { SubmitButton } from "./ProfileAddressModal.styles";
import { Props } from "./ProfileAddressModal.types";
import useProfileAddressModal from "./useProfileAddressModal";

const ProfileAddressModal = ({ close, isOpen, initialAddress }: Props) => {
  const { t } = useTranslation("partial-profileAddressModal");
  const { t: formTranslations } = useTranslation("form-Address");
  const validationSchema = formAddressSchema(formTranslations);
  const [serverError, setServerError] = useState("");
  const { submitAddress } = useProfileAddressModal(close, setServerError);

  useEffect(() => {
    if (isOpen) {
      logEvent(AnalyticsEvent.SCREEN_VIEW, {
        screenName: AnalyticsSource.UPDATE_HOME_ADDRESS,
      } as ScreenViewParams);
    }
  }, [isOpen]);

  const initialAddressValues: AddressFormValues = {
    address: {
      line1: initialAddress?.line1 || "",
      line2: initialAddress?.line2 || "",
      city: initialAddress?.city || "",
      state: (initialAddress?.state && StateCode[initialAddress.state]) || "",
      postalCode: initialAddress?.postalCode || "",
    },
  };

  const logSaveAddressClick = (buttonName: string) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.MY_ACCOUNT,
      source: AnalyticsSource.UPDATE_HOME_ADDRESS,
      button_name: buttonName,
    } as ButtonClickParams);
  };

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={initialAddressValues}
      validationSchema={validationSchema}
      onSubmit={submitAddress}
    >
      {({ submitForm, isSubmitting, isValid }) => (
        <Modal
          key="ProfileAddressModal"
          header={
            <Modal.Header
              title={t("modal.header", { ns: "partial-profileAddressModal" })}
              close={close}
            />
          }
          isOpen={isOpen}
          close={close}
          footer={
            <Modal.FooterWithLargeButton>
              <SubmitButton
                isDisabled={!isValid}
                type="submit"
                isLoading={isSubmitting}
                onClick={() => {
                  logSaveAddressClick("Save changes");
                  submitForm();
                }}
              >
                {t("modal.submit", { ns: "partial-profileAddressModal" })}
              </SubmitButton>
            </Modal.FooterWithLargeButton>
          }
        >
          <AddressForm serverError={serverError} />
        </Modal>
      )}
    </Formik>
  );
};

export default ProfileAddressModal;
