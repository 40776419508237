import { useReactiveVar } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";

import { scheduling } from "/apollo/client/local";
import routes from "/constant/url.constant";

/**
 * Creates a handler for the back button for the VisitReason and ConfirmDetails pages.
 * These pages can be redirected to after completing login/signup. In this situations,
 * we want the user to be able to return to the correct availabilities page.
 * `fromRedirect` is set when pushing these pages to history from useBookingReroute.
 * @returns
 */
const useHandleBackWhenBooking = () => {
  const history = useHistory();
  const location = useLocation<{ fromRedirect: boolean }>();
  const { selectedCalendarIds, calendarIds } = useReactiveVar(scheduling.var);

  const handleBack = () => {
    if (location?.state?.fromRedirect) {
      if (selectedCalendarIds?.length && !calendarIds?.length) {
        history.push(`${routes.scheduling}${routes.availabilitiesSelected}`);
      } else {
        history.push(`${routes.scheduling}${routes.availabilities}`);
      }
    } else {
      history.goBack();
    }
  };

  return handleBack;
};

export default useHandleBackWhenBooking;
