import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React from "react";

import { Avatar, Button, Text } from "/component/base";
import { Check, Message } from "/component/base/Icon";
import AvailabilityStatus from "/component/partial/AvailabilityStatus";
import { useTranslation } from "/hook";
import { layout } from "/styles";
import theme from "/theme/default";
import { reactPlugin } from "/util/appInsights.util";
import { parseInitials } from "/util/formatText";

import { getProviderStatus } from "../../../../TelehealthIndex.utils";
import { ProviderRoomCardContainer } from "./ProviderRoomCard.styles";
import { Props } from "./ProviderRoomCard.types";

const ProviderRoomCard = ({
  providerDetails,
  onClick,
  enableTextNotification,
  isDisabled,
}: Props) => {
  const { t } = useTranslation("telehealth");

  return (
    <ProviderRoomCardContainer>
      <div css={layout.flexLTR}>
        <Avatar
          size="large"
          css={{ marginRight: theme.spacing.standard }}
          src={providerDetails.logoUrl}
          initials={parseInitials(providerDetails)}
          alt={providerDetails.fullName}
        />
        <div css={[layout.flexVertical]}>
          <Text variant="body1Bold">{providerDetails.fullName}</Text>
          <Text variant="body2" color="textSecondary">
            {providerDetails.specialty.value}
          </Text>

          <AvailabilityStatus
            status={getProviderStatus(providerDetails.visibility, providerDetails.waitingRoomCount)}
            waitingRoomCount={providerDetails.waitingRoomCount}
          />
        </div>
      </div>
      {onClick && (
        <Button
          isDisabled={isDisabled}
          css={[
            layout.flexCenter,
            layout.spacedChildrenHorizontal("condensed"),

            {
              backgroundColor: theme.colors.objectInverse,
              color: enableTextNotification ? theme.colors.statusPositive : "brandSecondary",
            },
          ]}
          onClick={onClick}
          variant="tertiary"
          fullWidth="percent"
        >
          {enableTextNotification ? (
            <>
              <Check size={14} />
              <div>{t("waitingRoom.textMeEnabled")}</div>
            </>
          ) : (
            <>
              <Message size={14} />
              <div>{t("waitingRoom.textMe")}</div>
            </>
          )}
        </Button>
      )}
    </ProviderRoomCardContainer>
  );
};

export default withAITracking(reactPlugin, ProviderRoomCard, undefined, "ai-tracking");
