// Modules
import { format } from "date-fns";
import React from "react";

import Text from "/component/base/Text";

import { Base } from "./DateBadge.styles";
// Types
import { Props } from "./DateBadge.types";

const DateBadge = ({ date, className, dayTextColor = "textPrimary", ...rest }: Props) => {
  const month = format(date, "MMM").toUpperCase();
  const day = format(date, "dd");
  const formatted = date.toISOString();

  return (
    <Base className={className} dateTime={formatted} {...rest}>
      <Text variant="title2" color={dayTextColor}>
        {day}
      </Text>
      <Text variant="navigation" color="textSecondary">
        {month}
      </Text>
    </Base>
  );
};

export default DateBadge;
