import awsdk from "awsdk";

import { useAmwell, useAmwellQuery } from "/hook";

import { Variables } from "./useAmwellInbox.types";

/**
 * This hook allows for the retrieval of the currently authenticated Amwell
 * userʼs inbox messages. In addition to the response data, it also includes
 * boolean values to indicate loading and error states.
 */
export const useAmwellInbox = ({ startIndex, maxResults = 5 }: Variables) => {
  const { consumer, sdk } = useAmwell();
  const fetch = async () =>
    sdk && consumer
      ? sdk.secureMessageService.getInboxMessages(consumer, startIndex, maxResults)
      : undefined;

  return useAmwellQuery<awsdk.AWSDKInbox>(fetch, [startIndex, maxResults]);
};

export default useAmwellInbox;
