/**
 * Code is inspired (copied) from https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/performance.md
 */
import { NavigationClient } from "@azure/msal-browser";
import { MsalProvider as BaseProvider } from "@azure/msal-react";
import { History } from "history";
import React from "react";
import { useHistory } from "react-router";

import { auth } from "/util";

/**
 * Extending the default NavigationClient allows you to overwrite just navigateInternal while continuing to use the default navigateExternal function
 * If you would like to overwrite both you can implement INavigationClient directly instead
 */
class CustomNavigationClient extends NavigationClient {
  history: History;

  constructor(history: History) {
    super();
    this.history = history;
  }

  // This function will be called anytime msal needs to navigate from one page in your application to another
  async navigateInternal(url: string, options: { noHistory?: boolean }) {
    // Map an absolute URL to its relative path, which react router uses to navigate. Eg:
    // http://localhost:8080/home -> home
    const relativePath = url.replace(window.location.origin, "");
    if (options.noHistory) {
      this.history.replace(relativePath);
    } else {
      this.history.push(relativePath);
    }

    return false;
  }
}

/**
 * Override the base MsalProvider to have it use our same react-router navigation, when it needs
 * to navigate. Without this change MSAL would just change the `window.location` any time it needs
 * to redirect, example after a login, which will cause the whole page to reload, giving us a lot
 * of awkward loading states as the AppRoot/useAuth mount and unmount.
 */
const MsalProvider = ({ children }: { children?: React.ReactNode }) => {
  const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);
  auth.msalInstance.setNavigationClient(navigationClient);

  return <BaseProvider instance={auth.msalInstance}>{children}</BaseProvider>;
};

export default MsalProvider;
