import { baseTransitionTime, transitionKeys as keys } from "/constant/animation.constant";
import { ThemeType } from "/theme/default";

export const fade = () => ({
  [`&.${keys.transition}`]: {
    transition: "opacity 350ms ease",
  },

  [`&.${keys.start}`]: {
    opacity: 0,
  },

  [`&.${keys.finish}`]: {
    opacity: 1,
  },
});

export const fadeDown = ({ theme }: ThemeType) => ({
  [`&.${keys.transition}`]: {
    transition: "opacity 300ms ease, transform 350ms ease",
  },

  [`&.${keys.start}`]: {
    opacity: 0,
    transform: `translate3d(0, -${theme.spacing.standard}px, 0)`,
  },

  [`&.${keys.finish}`]: {
    opacity: 1,
    transform: "translate3d(0, 0, 0)",
  },
});

export const modalAppear = ({ theme }: ThemeType) => ({
  [`&.${keys.transition}`]: {
    transition: "all 350ms ease",
  },

  // TODO: we probably shouldn't do 80vh here. That's the max height so it'll work, but it makes
  // really short modals transition *real* fast
  [`&.${keys.start}`]: {
    transform: `translate3d(0, ${theme.sizes.mobileModalMaxHeight}, 0);`,
  },

  [`&.${keys.finish}`]: {
    transform: "translate3d(0, 0, 0);",
  },

  [theme.media.tablet]: {
    [`&.${keys.start}`]: {
      opacity: 0,
      transform: "translate3d(0, 20px, 0);",
    },

    [`&.${keys.finish}`]: {
      opacity: 1,
      transform: "translate3d(0, 0, 0);",
    },
  },
});

export const enterFromRight = {
  [`&.${keys.transition}`]: {
    transition: `opacity ${baseTransitionTime}ms ease, transform ${baseTransitionTime}ms ease`,
  },
  [`&.${keys.start}`]: {
    opacity: 0,
    transform: "translateX(50%)",
  },
  [`&.${keys.finish}`]: {
    opacity: 1,
    transform: "none",
  },
};

export const accordian = (animateFromBottom = false) => ({
  [`&.${keys.transition}`]: {
    transformOrigin: `${animateFromBottom ? "bottom" : "top"} center`,
    transition: "opacity 150ms ease, transform 200ms ease",
  },

  [`&.${keys.start}`]: {
    opacity: 0,
    transform: "rotate3d(1, 0, 0, -45deg) scale3d(1, 0.6, 1) translateY(-5%)",
  },

  [`&.${keys.finish}`]: {
    opacity: 1,
    transform: "none",
  },
});
