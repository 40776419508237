import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";

import routes from "/constant/url.constant";

import FaqCategory from "./page/FaqCategory";
import FaqDetail from "./page/FaqDetail";
import FaqIndex from "./page/FaqIndex";

const HomeRoutes = ({ match }: RouteComponentProps) => {
  return (
    <Switch>
      <Route exact path={match.url} component={FaqIndex} />
      <Route exact path={`${match.url}${routes.faqCategory}/:id`} component={FaqCategory} />
      <Route exact path={`${match.url}${routes.faqDetail}/:id`} component={FaqDetail} />
    </Switch>
  );
};

export default HomeRoutes;
