import awsdk from "awsdk";

import { useAmwell, useAmwellQuery } from "/hook";

import { Variables } from "./useAmwellPharmacies.types";

/**
 * This hook allows for the retrieval of the pharmacies according to Longitude And Latitude or ZipCode. A
 * search may only be performed when authenticated, and when an AWSDKProvider is
 * provided via parameters. In addition to the response data, it also includes
 * boolean values to indicate loading and error states.
 */
export const useAmwellPharmacies = ({ lat, lng, radius, zipCode }: Variables = {}) => {
  const { isReady, sdk } = useAmwell();

  const fetch = async () => {
    let pharmacies: awsdk.AWSDKPharmacy[] = [];

    if (isReady && sdk) {
      // Note: Default to zipCode because search by lat/lng currently isn't returning MailOrder pharmacies
      if (zipCode) {
        pharmacies = await sdk.pharmacyService.getPharmacies(
          undefined,
          undefined,
          zipCode,
          undefined,
        );
      } else if (lat && lng) {
        pharmacies = await sdk.pharmacyService.getPharmaciesByLongitudeAndLatitude(
          lng,
          lat,
          radius,
          false,
        );
      }
    }
    return pharmacies || [];
  };
  return useAmwellQuery<awsdk.AWSDKPharmacy[]>(fetch, zipCode ? [zipCode] : [lat, lng, radius]);
};

export default useAmwellPharmacies;
