import styled from "styled-components";

import { layout } from "/styles";

import { Props } from "./Icon.types";

export const Background = styled.div<Pick<Props, "background">>(
  ({ theme, background }) => ({
    width: background?.size,
    height: background?.size,
    borderRadius: 16,
    backgroundColor: theme.colors[background?.color || "brandPrimary"],
  }),
  layout.flexCenter,
);
