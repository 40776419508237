import { useReactiveVar } from "@apollo/client";
import React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";

import { auth } from "/apollo/client/local";
import routes from "/constant/url.constant";

import AllAvailabilities from "./page/Availabilities/AllAvailabilities";
import SelectedAvailabilities from "./page/Availabilities/SelectedCalendars";
import BookingConfirmed from "./page/BookingConfirmed";
import ConfirmDetails from "./page/ConfirmDetails";
import DecisionSuppport from "./page/DecisionSupport";
import MoreService from "./page/MoreService";
import SchedulingIndex from "./page/SchedulingIndex/SchedulingIndex";
import VisitReason from "./page/VisitReason";

const SchedulingRoutes = ({ match }: RouteComponentProps) => {
  const { isAuthenticated } = useReactiveVar(auth.var);
  return (
    <Switch>
      <Route exact path={match.url} component={SchedulingIndex} />
      <Route exact path={`${match.url}${routes.moreService}`} component={MoreService} />
      <Route
        exact
        path={`${match.url}${routes.decisionSupport}/:flowId`}
        component={DecisionSuppport}
      />
      <Route exact path={`${match.url}${routes.availabilities}`} component={AllAvailabilities} />
      <Route
        exact
        path={`${match.url}${routes.availabilitiesSelected}`}
        component={SelectedAvailabilities}
      />
      {isAuthenticated ? (
        <Switch>
          <Route exact path={`${match.url}${routes.visitReason}`} component={VisitReason} />
          <Route exact path={`${match.url}${routes.confirm}`} component={ConfirmDetails} />
          <Route exact path={`${match.url}${routes.confirmed}`} component={BookingConfirmed} />
        </Switch>
      ) : (
        <Redirect to={`${match.url}`} />
      )}
    </Switch>
  );
};

export default SchedulingRoutes;
