import { useReactiveVar } from "@apollo/client";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import awsdk from "awsdk";
import React, { useEffect, useState } from "react";
import { isAndroid, isIOS, isMobile } from "react-device-detect";
import { Helmet } from "react-helmet-async";
import { Trans } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";

import { address } from "/apollo/client/local";
import { AddressState } from "/apollo/client/local/address/address.types";
import { StateCode } from "/apollo/schema";
import Button from "/component/base/Button";
import { FeatherIcon, TickCircle, Video } from "/component/base/Icon";
import InlineMessage from "/component/base/InlineMessage";
import List from "/component/base/List";
import Loading from "/component/base/Loading";
import Text from "/component/base/Text";
import MainContent from "/component/layout/MainContent";
import InsuranceInfoModal from "/component/modal/InsuranceInfoModal/InsuranceInfoModal";
import { TelehealthProviderDetailsModal } from "/component/modal/ProviderDetailsModal";
import TelehealthFilterModal from "/component/modal/TelehealthFilterModal";
import EmptyStateCta from "/component/partial/EmptyStateCta";
import FilterButton from "/component/partial/FilterButton";
import SetAddress from "/component/partial/SetAddress";
import { useAmwellPrevisitContext } from "/component/provider/AmwellPrevisitProvider";
import { useCaregiverProvider } from "/component/provider/CaregiverProvider";
import { useToastContext } from "/component/provider/ToastProvider";
import { APPLE_STORE_LINK, GOOGLE_PLAY_LINK } from "/constant/appStoreLinks.constant";
import { PrevisitForms } from "/constant/telehealth.constant";
import routes from "/constant/url.constant";
import { useAmwellProviders, useGoogleMaps, useTranslation } from "/hook";
import { useAnalytics } from "/hook/useAnalytics/useAnalytics";
import { layout } from "/styles";
import { googlePlaces } from "/util";
import { reactPlugin } from "/util/appInsights.util";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  IndentifierParams,
  logEvent,
  ScreenViewParams,
} from "/util/firebase.util";

import {
  HeaderContainer,
  ProviderCtaContainer,
  ProviderCtaContainerText,
  SetAddressContainer,
} from "./TelehealthIndex.styles";
import { AddressProps, FilterProps } from "./TelehealthIndex.types";
import { getProviderStatus } from "./TelehealthIndex.utils";
import TelehealthProviderItem from "./TelehealthProviderItem";

const AMWELL_FFA_DAY_PRACTICE_SOURCE_ID = process.env.AMWELL_FFA_DAY_PRACTICE_SOURCE_ID;
const ENABLE_CAREGIVER_PROXY = process.env.ENABLE_CAREGIVER_PROXY === "true";

const TelehealthIndex = ({ history }: RouteComponentProps) => {
  const { t } = useTranslation("telehealth");
  const { recordAnalyticsEvent } = useAnalytics();

  const { sdk, consumer, practice, createVisit, setupPractice } = useAmwellPrevisitContext();
  const { caregiverMode } = useCaregiverProvider();
  const googleMaps = useGoogleMaps();
  const { showToast } = useToastContext();

  const addressStored = useReactiveVar<AddressState>(address.var);
  const [selectedProvider, setSelectedProvider] = useState<awsdk.AWSDKProvider>();
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [startingVisit, setStartingVisit] = useState(false);
  const [currentAddress, setCurrentAddress] = useState<AddressProps | undefined>(undefined);
  const [isRetrievingAddress, setIsRetrievingAddress] = useState(false);
  const [onDemandSpecialty, setOnDemandSpecialty] = useState<awsdk.AWSDKOnDemandSpecialty>();
  const [providersOnCall, setProvidersOnCall] = useState(false);
  const locationMismatch = currentAddress?.state !== addressStored.state;

  const [showInsuranceInfoModal, setShowInsuranceInfoModal] = useState(false);

  const [filter, setFilter] = useState<FilterProps>({
    gender: undefined,
    language: undefined,
  });

  const {
    data: providers,
    loading,
    error,
  } = useAmwellProviders({
    state: addressStored.state as StateCode,
    language: filter.language,
    gender: filter.gender,
  });
  const onlineProviders = providers?.filter(
    (p) => p.visibility !== awsdk.AWSDKProviderVisibility.OFFLINE,
  );

  // only render the CtaCard if there are no filters and the current practice is SCMD Day
  const renderCtaCard =
    (Object.entries(filter).length === 0 ||
      (filter.gender === undefined && filter.language === undefined)) &&
    practice?.sourceId === AMWELL_FFA_DAY_PRACTICE_SOURCE_ID &&
    providersOnCall;

  //Show filter on gender and language
  const filterCount = (filter.gender ? 1 : 0) + (filter.language ? 1 : 0);
  const showEmptyOrError = !loading && (!onlineProviders || !onlineProviders?.length);
  const showLoader =
    ((!onlineProviders || !!onlineProviders.length) && loading) ||
    isRetrievingAddress ||
    startingVisit;
  const showProviders = !loading && onlineProviders && !!onlineProviders.length;
  const providersUnavailable =
    providers?.filter(
      (p) =>
        p.visibility === awsdk.AWSDKProviderVisibility.WEB_AVAILABLE ||
        p.visibility === awsdk.AWSDKProviderVisibility.WEB_BUSY,
    ).length === 0;

  useEffect(() => {
    const getOnDemandSpecialties = async () => {
      if (consumer && sdk) {
        setStartingVisit(true);
        const practiceList = await sdk.practiceService.getPractices(consumer);

        if (practiceList.practices.length > 0) {
          const firstPractice = practiceList.practices[0];
          const onDemandSpecialty = await sdk.practiceService.getOnDemandSpecialties(
            consumer,
            firstPractice,
          );

          if (onDemandSpecialty.length > 0 && onDemandSpecialty[0].providersAvailable) {
            setOnDemandSpecialty(onDemandSpecialty[0]);
            setProvidersOnCall(onDemandSpecialty[0].providersAvailable);
          }
        }
        setStartingVisit(false);
      }
    };
    getOnDemandSpecialties();
  }, [consumer?.legalResidence.code]);

  const handleSeeNextProvider = async () => {
    logButtonClickEvent("See next provider");
    if (onDemandSpecialty) {
      await handleStartVisit(onDemandSpecialty, false);
      return;
    }
  };

  const handleProviderClick = (item: awsdk.AWSDKProvider) => {
    logButtonClickEvent("Provider", { provider_id: item.sourceId });
    setSelectedProvider(item);
    setShowDetailsModal(true);
  };

  const handleStartVisit = async (
    providerOnDemand: awsdk.AWSDKProvider | awsdk.AWSDKOnDemandSpecialty,
    isProvider: boolean,
  ) => {
    setStartingVisit(true);
    const visitCreated = await createVisit(providerOnDemand, isProvider);

    if (visitCreated) {
      history.push(
        locationMismatch ? routes.telehealthLocationVerification : routes.telehealthPrevisit,
        {
          provider: providerOnDemand,
          form: PrevisitForms.PATIENT_CALLBACK,
        },
      );
    } else {
      showToast({
        icon: "alert",
        message: t("error"),
        type: "error",
      });
    }
    setStartingVisit(false);
  };

  // Grab the the current location and compare it to the entered location address
  useEffect(() => {
    const getLoc = async () => {
      if (googleMaps) {
        setIsRetrievingAddress(true);

        try {
          const currentAddress = await googlePlaces.getAddressCurrentLocation(googleMaps);

          if (currentAddress) {
            const detailedAddress = googlePlaces.extractAddress(currentAddress?.address_components);
            setCurrentAddress(detailedAddress);
          }
        } catch ({ err, friendlyMessage }: any) {
          showToast({
            icon: "alert",
            message: friendlyMessage as string,
            type: "error",
          });
        } finally {
          setIsRetrievingAddress(false);
        }
      }
    };

    getLoc();
  }, [googleMaps]);

  useEffect(() => {
    if (isMobile) {
      recordAnalyticsEvent("amwellViewMobileAppRedirect");
    }
  }, [isMobile]);

  const handleMobileAppDownloadRedirect = () => {
    recordAnalyticsEvent("amwellClickMobileApp");
    if (isAndroid) {
      window.open(GOOGLE_PLAY_LINK, "_blank");
    } else if (isIOS) {
      window.open(APPLE_STORE_LINK, "_blank");
    }
  };

  const logButtonClickEvent = (buttonName: string, params?: IndentifierParams) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.GET_VIRTUAL_URGENT_CARE,
      source: AnalyticsSource.TELEHEALTH_PROVIDERS,
      button_name: buttonName,
      ...params,
    } as ButtonClickParams);
  };

  const renderDisclaimer = (disclaimer: string) => {
    return (
      <InlineMessage
        css={layout.margin("standard", "none")}
        variant={"primary"}
        icon={<FeatherIcon name="alertCircle" />}
      >
        <Text variant="body2">{disclaimer}</Text>
      </InlineMessage>
    );
  };

  if (isMobile) {
    return (
      <MainContent parentPage="root">
        <Helmet>
          <title>{t("title")}</title>
        </Helmet>
        <div css={layout.flexVerticalCenter}>
          <div css={layout.marginVertical(80)}>
            <EmptyStateCta
              icon={<Video color="brandPrimary" size={48} />}
              title={t("isMobile.title")}
              subtitle={t("isMobile.subtitle")}
              ctaText={t("isMobile.cta")}
              onClickCta={handleMobileAppDownloadRedirect}
              ctaVariant="primary"
            />
          </div>
        </div>
      </MainContent>
    );
  }

  if (showLoader) {
    return (
      <MainContent parentPage="root">
        <Helmet>
          <title>{t("title")}</title>
        </Helmet>
        <div css={[layout.flexVerticalCenter, layout.margin(80, "skip", "skip", "skip")]}>
          <Loading variant="list" />
          <Text css={layout.marginVertical("standard")} variant="title3">
            {t("loading.title")}
          </Text>
          <Text variant="body1">{t("loading.subtitle")}</Text>
        </div>
      </MainContent>
    );
  }

  return (
    <MainContent parentPage="root">
      <Helmet>
        <title>{t("title")}</title>
      </Helmet>

      <HeaderContainer>
        <SetAddressContainer>
          <SetAddress
            handleDone={setupPractice}
            header={t("subHeader")}
            variant="unstyled"
            textVariant="title2"
            buttonStyle="unstyled"
            textColor="textPrimary"
            analyticsParams={
              {
                user_flow: AnalyticsUserFlow.GET_VIRTUAL_URGENT_CARE,
                source: AnalyticsSource.TELEHEALTH_PROVIDERS,
              } as ScreenViewParams
            }
          />
        </SetAddressContainer>
        <FilterButton
          count={filterCount}
          onClick={() => {
            logButtonClickEvent("Filter (icon)");
            setShowFilterModal(true);
          }}
        />
      </HeaderContainer>

      {caregiverMode
        ? renderDisclaimer(t("caregiverDisclaimer"))
        : !ENABLE_CAREGIVER_PROXY && renderDisclaimer(t("ageAlert"))}

      {renderCtaCard && (
        <>
          <ProviderCtaContainer>
            <Text variant="title3">{t("providerCta.header")}</Text>
            <ProviderCtaContainerText variant="body1" color="textSecondary">
              {t("providerCta.description")}
            </ProviderCtaContainerText>
            <div>
              <Button
                isDisabled={showLoader || providersUnavailable}
                onClick={handleSeeNextProvider}
                variant="primary"
              >
                {t("providerCta.button")}
              </Button>
            </div>
          </ProviderCtaContainer>
        </>
      )}

      <div css={layout.margin("gutter", "none")}>
        <div css={{ display: "flex", flexDirection: "row" }}>
          <TickCircle css={{ marginTop: "10px" }} size={24} />
          <div css={{ paddingLeft: "12px" }}>
            <Text>
              <Trans
                components={{
                  learnMoreLink: (
                    <Button
                      variant="borderBottom"
                      onClick={() => setShowInsuranceInfoModal(true)}
                    />
                  ),
                }}
                i18nKey="learnMore"
                t={t}
              />
            </Text>
          </div>
        </div>
      </div>
      <div css={layout.margin("none", "none", "gutter", "none")}>
        <Text variant="title3">{t("selectProvider")}</Text>
      </div>

      {showEmptyOrError && (
        <InlineMessage
          variant={error ? "important" : "primary"}
          icon={<FeatherIcon name="alertCircle" />}
        >
          {error ? t("error") : t("noProviders")}
        </InlineMessage>
      )}

      {!showLoader && onlineProviders && showProviders && (
        <List
          as="Buttons"
          data={onlineProviders}
          onClick={handleProviderClick}
          showChevron={false}
          renderItem={(provider, idx) => {
            const status = getProviderStatus(provider.visibility, provider.waitingRoomCount);
            return (
              <TelehealthProviderItem
                key={idx}
                status={status}
                provider={provider}
                waitingRoomCount={provider.waitingRoomCount}
              />
            );
          }}
        />
      )}

      <TelehealthFilterModal
        filter={filter}
        setFilter={setFilter}
        isOpen={showFilterModal}
        close={() => setShowFilterModal(false)}
      />

      <TelehealthProviderDetailsModal
        isLoading={startingVisit}
        provider={selectedProvider}
        isOpen={showDetailsModal}
        close={() => setShowDetailsModal(false)}
        handleStartVisit={() => {
          if (selectedProvider) handleStartVisit(selectedProvider, true);
        }}
        status={
          selectedProvider
            ? getProviderStatus(selectedProvider.visibility, selectedProvider.waitingRoomCount)
            : undefined
        }
      />
      <InsuranceInfoModal
        isOpen={showInsuranceInfoModal}
        close={() => {
          setShowInsuranceInfoModal(false);
        }}
      />
    </MainContent>
  );
};

export default withAITracking(reactPlugin, TelehealthIndex, undefined, "ai-tracking");
