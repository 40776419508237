import React from "react";
import ContentLoader from "react-content-loader";

import { Base } from "./ResultLoadingCard.styles";

const backgroundColor = "#e3e5e9";
const foregroundColor = "#f0f0f0";

const ResultLoadingCard = ({ animate = true, ...loaderProps }) => {
  return (
    <Base>
      <ContentLoader
        speed={2}
        height={80}
        viewBox="0 0 798 80"
        preserveAspectRatio="none"
        backgroundColor={backgroundColor}
        foregroundColor={animate ? foregroundColor : backgroundColor}
        animate={animate}
        style={{ borderWidth: 1 }}
        {...loaderProps}
      >
        <rect x="0" y="0" rx="4" ry="4" width="107" height="16" />
        <rect x="760" y="0" rx="4" ry="4" width="38" height="16" />
        <rect x="0" y="32" rx="4" ry="4" width="52" height="16" />
        <rect x="0" y="64" rx="4" ry="4" width="241" height="16" />
      </ContentLoader>
    </Base>
  );
};

export default ResultLoadingCard;
