import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { format } from "date-fns";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";

import { Divider, Link, Loading, Text } from "/component/base";
import { Calendar, Check } from "/component/base/Icon";
import MainContent from "/component/layout/MainContent";
import InlineEmptyState from "/component/partial/InlineEmptyState";
import SegmentedControl from "/component/partial/SegmentedControl";
import routes from "/constant/url.constant";
import { layout } from "/styles";
import { reactPlugin } from "/util/appInsights.util";
import { toUserFriendlyStatus } from "/util/prescription.util";

import ClinicDetails from "./ClinicDetails";
import { Pill } from "./Prescription.styles";
import { Params } from "./Prescription.types";
import usePrescription from "./usePrescription";

const Prescription = ({ match, location }: RouteComponentProps<Params>) => {
  const prescriptionId = Number(match.params.prescriptionId);
  const { patientMrn } = location.state as { patientMrn: string };

  const { t } = useTranslation("prescription");
  const [selectedOption, setSelectedOption] = React.useState("drugInformation");

  const { error, data, loading, refetch } = usePrescription(prescriptionId, patientMrn);
  const prescription = data?.prescription;

  const active = prescription?.active;
  const refills = prescription?.refillsAllowed !== null ? prescription?.refillsAllowed : undefined;
  const provider = prescription?.provider?.nameWithDesignations;
  const date =
    !!prescription?.futureSubmitDate &&
    format(new Date(prescription?.futureSubmitDate), "MMMM do, yyyy");
  const pharmacy = prescription?.pharmacy;

  const menuItems = [
    {
      value: "drugInformation",
      label: t("drugInformationMenuItem"),
    },
    {
      value: "status",
      label: t("statusMenuItem"),
    },
  ];

  const handleItemSelected = (item: string | undefined) => {
    if (item) {
      setSelectedOption(item);
    }
  };

  const handleCtaClick = () => {
    refetch();
  };

  const getStatusText = () => {
    if (active && date) {
      return t("activeMessage", {
        date,
        provider,
        context: provider ? "withProvider" : "",
      });
    } else if (!active) {
      if (date) {
        return t("inactiveMessage", {
          date,
          provider,
          context: provider ? "withProvider" : "",
        });
      }
    }

    return t("scheduleAVisitPrompt");
  };

  const getStatus = () => {
    const isSuccess = prescription?.status === "CLOSED";

    return (
      <div>
        <div>
          <Text color="textSecondary" variant="body2Bold">
            {t("status")}
          </Text>
          {prescription?.status && (
            <div
              css={[
                layout.spacedChildrenHorizontal("condensed"),
                layout.flexCenterHorizontal,
                layout.marginVertical("condensed"),
              ]}
            >
              {isSuccess && <Check color="statusPositive" />}
              <Text color={isSuccess ? "statusPositive" : "textPrimary"} variant="body1Bold">
                {toUserFriendlyStatus(prescription?.status)}
              </Text>
            </div>
          )}
          <Text color="textSecondary">{getStatusText()}</Text>
          {!active && (
            <Link.Button
              to={routes.scheduling}
              variant="tertiary"
              css={[layout.flexCenter, layout.marginVertical("standard")]}
            >
              <Calendar color="brandPrimary" background={{ size: 16, color: "transparent" }} />
              <span css={layout.padding("none", "none", "none", "condensed")}>
                {t("scheduleAVisitButton")}
              </span>
            </Link.Button>
          )}
        </div>
        {pharmacy && <ClinicDetails pharmacy={pharmacy} />}
      </div>
    );
  };

  const getDrugInformation = () => {
    return (
      <div css={[layout.flexVertical, layout.flexStartHorizontal]}>
        <Text color="textSecondary" variant="body2Bold">
          {t("frequency")}
        </Text>
        <Pill>
          <Text color="brandPrimary" variant="body2Bold">
            {prescription?.dosageFrequencyUnit}
          </Text>
        </Pill>
        <Text color="textSecondary" variant="body2Bold">
          {t("refills")}
        </Text>
        <Pill>
          <Text color="brandPrimary" variant="body2Bold">
            {t("refillsLabel", { count: refills })}
          </Text>
        </Pill>
        <Divider css={layout.margin("skip", "skip", "expanded", "skip")} />
        <Text color="textSecondary" variant="body2Bold" css={layout.marginVertical("condensed")}>
          {t("instructions")}
        </Text>
        <Text>{prescription?.sig}</Text>
      </div>
    );
  };

  const getChild = () => {
    if (loading) {
      return <Loading variant="page" />;
    }

    if (error) {
      return (
        <div css={layout.flexCenter}>
          <InlineEmptyState
            ctaOnClick={handleCtaClick}
            ctaText={t("error.cta")}
            subtitle={t("error.subtitle")}
            title={t("error.title")}
          />
        </div>
      );
    }

    return (
      <>
        <SegmentedControl
          onItemSelected={handleItemSelected}
          options={menuItems}
          value={selectedOption}
        />
        <div css={layout.marginVertical("expanded")}>
          {selectedOption === "status" && getStatus()}
          {selectedOption === "drugInformation" && getDrugInformation()}
        </div>
      </>
    );
  };

  return (
    <MainContent
      parentPage="prescriptions"
      pageTitle={prescription && prescription.documentDescription}
    >
      <Helmet>
        <title>
          {t("pageTitle", {
            prescription: prescription && prescription?.documentDescription,
            context: prescription ? "hasTitle" : undefined,
          })}
        </title>
      </Helmet>
      {getChild()}
    </MainContent>
  );
};

export default withAITracking(reactPlugin, Prescription, undefined, "ai-tracking");
