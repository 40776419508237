import { useEffect, useRef } from "react";

/**
 * In React, changing the state of an unmounted component will result in an
 * warning:
 *
 * > "Warning: Can only update a mounted or mounting component. This usually
 * > means you called setState, replaceState, or forceUpdate on an unmounted
 * > component. This is a no-op."
 *
 * But in some cases (promises derived from an api call, timeouts, intervals,
 * etc.), it's difficult to know if the component is still mounted or not.
 *
 * This hook returns a ref, the `current` value of which may be checked to
 * determine the mounted state of the component.
 */
const useMounted = () => {
  const mountedRef = useRef(true);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);
  return mountedRef;
};

export default useMounted;
