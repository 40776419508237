import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import { Button, Loading, Text } from "/component/base";
import FormContent from "/component/partial/FormContent";
import FormattedTextField from "/component/partial/formik/FormattedTextField";
import {
  PatientCallbackProps,
  useAmwellPrevisitContext,
} from "/component/provider/AmwellPrevisitProvider";
import { useTranslation } from "/hook";
import { layout } from "/styles";
import { formatPhone } from "/util/formatText";

import { Title } from "../Telehealth.styles";
import { TelehealthFormProps } from "../Telehealth.types";
import { useQueryPhoneNumber } from "./useQueryPhoneNumber";

const PHONE_REGEX = /^(\+1) [0-9]{3}-[0-9]{3}-[0-9]{4}$/;

const PatientCallback = ({
  handleError,
  handleSubmit,
  initialValues,
}: TelehealthFormProps<PatientCallbackProps>) => {
  const { t } = useTranslation("form-telehealth-previsit");

  const { consumer, visitContext, setVisitContext, sdk, setProvider, setVisit } =
    useAmwellPrevisitContext();
  const { data, loading } = useQueryPhoneNumber();
  const [formValues, setFormValues] = useState<PatientCallbackProps>(initialValues);

  const callbackSchema = Yup.object({
    phone: Yup.string()
      .matches(PHONE_REGEX, {
        message: t("error.phoneInvalid"),
      })
      .required(t("error.required")),
  });

  const submitForm = async (values: PatientCallbackProps) => {
    try {
      if (visitContext && sdk && consumer) {
        visitContext.callbackNumber = values.phone;
        setVisitContext(visitContext);
        const visit = await sdk.visitService.createOrUpdateVisit(visitContext);
        setProvider(visit.assignedProvider);
        setVisit(visit);
        handleSubmit(values);
      }
    } catch (e: any) {
      handleError(e);
    }
  };

  useEffect(() => {
    // We add a little timeout when the data and initialValues is populated so that
    // formik that we could take the data and visually show the UI updated an formik
    // also render the values and validation correctly.
    setTimeout(() => {
      let usersPhone = data?.viewer?.user.account.phoneNumber;
      if (usersPhone) {
        usersPhone = formatPhone(usersPhone);
      }
      setFormValues({
        ...initialValues,
        phone: initialValues.phone || usersPhone || "",
      });
    }, 100);
  }, [data, initialValues]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={formValues}
      validationSchema={callbackSchema}
      onSubmit={submitForm}
    >
      {({ isValid, isSubmitting }) => (
        <Form>
          <FormContent css={layout.margin("gutter", "skip")}>
            <Title>{t("patientCallback.title")}</Title>
            <Text
              css={layout.margin("skip", "skip", "gutter")}
              variant="body1"
              color="textSecondary"
            >
              {t("patientCallback.subtitle")}
            </Text>
            <div css={layout.margin("skip", "skip", 40)}>
              <FormattedTextField
                css={layout.margin("skip", "skip", 40)}
                label={t("patientCallback.phone")}
                name="phone"
                type="tel"
                mask="+1 999-999-9999"
              />
            </div>
            <FormContent.ButtonGroup>
              <Button
                variant="primary"
                isDisabled={!isValid}
                isLoading={isSubmitting}
                type="submit"
              >
                {t("patientCallback.continue")}
              </Button>
            </FormContent.ButtonGroup>
          </FormContent>
        </Form>
      )}
    </Formik>
  );
};

export default PatientCallback;
