import * as msal from "@azure/msal-browser";

export const SIGN_UP_V2_ENABLED = process.env.SIGN_UP_V2_ENABLED === "true";

const url = process.env.B2C_URL;
const clientId = (
  SIGN_UP_V2_ENABLED ? process.env.B2C_CLIENT_ID_V2 : process.env.B2C_CLIENT_ID
) as string;
const b2cScope = SIGN_UP_V2_ENABLED ? process.env.B2C_SCOPE_V2 : process.env.B2C_SCOPE;
const knownAuthorities = process.env.B2C_KNOWN_AUTHORITIES as string;

const signInMFAPolicy = process.env.B2C_SIGN_IN_MFA_TWILIO_POLICY;
const signUpSignInPolicy = SIGN_UP_V2_ENABLED
  ? process.env.B2C_SIGN_IN_STANDARD_POLICY
  : process.env.B2C_SIGN_UP_SIGN_IN_POLICY;
const forgotPasswordPolicy = process.env.B2C_PASSWORD_RESET_POLICY;
const changePhoneNumberPolicy = process.env.B2C_UPDATE_PHONE_NUMBER_POLICY;
const changeEmailPolicy = process.env.B2C_UPDATE_EMAIL_ADDRESS_POLICY;

export const scopes = ["openid", "offline_access", b2cScope as string];

export const policies = {
  names: {
    signInMFA: signInMFAPolicy,
    signUpSignIn: signUpSignInPolicy,
    forgotPassword: forgotPasswordPolicy,
    changePhoneNumber: changePhoneNumberPolicy,
    changeEmail: changeEmailPolicy,
  },
  authorities: {
    signInMFA: { authority: `${url}${signInMFAPolicy}`, scopes },
    signUpSignIn: { authority: `${url}${signUpSignInPolicy}`, scopes },
    forgotPassword: { authority: `${url}${forgotPasswordPolicy}`, scopes },
    changePhoneNumber: {
      authority: `${url}${changePhoneNumberPolicy}`,
      scopes,
      prompt: "login",
    },
    changeEmail: { authority: `${url}${changeEmailPolicy}`, scopes },
  },
};

export const msalInstance = new msal.PublicClientApplication({
  auth: {
    clientId,
    authority: SIGN_UP_V2_ENABLED
      ? policies.authorities.signInMFA.authority
      : policies.authorities.signUpSignIn.authority,
    knownAuthorities: [knownAuthorities],
    redirectUri: `${location.origin}/home`,
  },
  cache: {
    // Save the token in localStorage, instead of the default sessionStorage, so that it persists across tabs:
    // https://docs.microsoft.com/en-us/azure/active-directory/develop/msal-js-sso#sso-between-browser-tabs
    cacheLocation: "localStorage",
  },
});
