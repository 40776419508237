import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React from "react";
import { Helmet } from "react-helmet-async";

import {
  AddressUse,
  AdministrativeGender,
  PatientAddressInput,
  StateCode,
} from "/apollo/schema/types";
import DemographicFormWizard from "/component/form/DemographicFormWizard";
import { FormValues as DemographicWizardFormValues } from "/component/form/DemographicFormWizard/DemographicFormWizard.types";
import { useTranslation } from "/hook";
import { reactPlugin } from "/util/appInsights.util";

import useDemographics from "./useDemographics";

const defaultAddress: PatientAddressInput = {
  line1: "",
  line2: "",
  city: "",
  state: StateCode.AK,
  postalCode: "",
  use: AddressUse.HOME,
};

const initialValues: DemographicWizardFormValues = {
  firstName: "",
  lastName: "",
  sex: "" as unknown as AdministrativeGender,
  address: defaultAddress,
};

const Demographics = () => {
  const { t } = useTranslation("demographics");
  const { handleSubmit } = useDemographics({});

  return (
    <>
      <Helmet>
        <title>{t("title")}</title>
      </Helmet>
      <DemographicFormWizard initialValues={initialValues} handleSubmit={handleSubmit} />
    </>
  );
};

export default withAITracking(reactPlugin, Demographics, undefined, "ai-tracking");
