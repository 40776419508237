import summitCityMDLogo from "/asset/image/summitCityMDLogo.png";
import {
  GET_DEMOGRAPHICS_patient_demographics,
  GET_LAB_DETAILS_patient_labResult_analytes,
} from "/b2c/schema/types";
import { formatDate } from "/util/date";

const QUESTIONS_TEXT_SUMMIT =
  "If you have questions about your results, please contact your provider's office. If you are unsure of your provider's office number, contact the Summit Health call center at 908-273-4300. If you were seen at a CityMD, call 855-624-8963.";

const PERFORMED_BY_SUMMIT =
  "Summit Health | CityMD Urgent Care - 1 Diamond Hill Rd, Berkeley Heights, NJ 07922";

type PerformingLab = {
  name: string | null | undefined;
  address: string | null | undefined;
  city: string | null | undefined;
  state: string | null | undefined;
  zip: string | null | undefined;
};

export type TestResultData = {
  analytes?: GET_LAB_DETAILS_patient_labResult_analytes[] | undefined;
  documentdata?: string | null | undefined;
  labResultDatetime: string;
  description: string | null;
  performingLab: PerformingLab;
  providerName: string | null | undefined;
};

export const generateResultsHTML = (
  demographics: GET_DEMOGRAPHICS_patient_demographics,
  data: TestResultData,
): string => {
  return `<!doctype html>
  <html class="main">
    <head>
    <style>
      @media print { * { -webkit-print-color-adjust: exact !important; } }
      * { print-color-adjust:exact !important; }
      @font-face {
        font-family: "CeraPro";
        font-style: normal;
        font-weight: 400;
        src: url("https://storcl1digitalappblob.blob.core.windows.net/b2ctemplates/CeraPro-Regular.woff2")
          format("woff2"),
        url("https://storcl1digitalappblob.blob.core.windows.net/b2ctemplates/CeraPro-Regular.woff")
          format("woff");
      }
      .main {
        margin: 10px;
        font-family: 'CeraPro';
        font-size: 12px;
      }
      .h1{
        font-size: 18px;
        text-transform: capitalize;
      }
      .vertical-margin {
        padding: 16px 0px;
      }
      .text-secondary {
        color: "#6B6B6B";
      }
      #logos > :first-child  {
        margin-right: 8px;
        margin-top: 16px;
        margin-bottom: 16px;
      }
      .range {
        font-weight: bold;
        color: "#FFBE00";
        margin-left: 10px;
      }
      .results-value {
        display:flex;
        align-items: center;
        padding: 4px 0px;
        font-weight:bold;
      }
      .elevatedSVG{
        margin-right:4px;
        margin-bottom:4px;
      }
      .elevatedSVG > svg {
        fill: "#6B6B6B";
      }
      .headers{
        font-size: 12px;
      }
      .header{
        margin-bottom: 6px;
        display: flex;
        color: "#6B6B6B";
      }
      .line-height{
        line-height: 16px;
      }
      .header > div:first-child{
        min-width: 140px;
        font-weight: bold;
        color: #1A1A1A;
      }
      .result-details-header{
        color: #1A1A1A;
        font-weight: bold;
        margin-top: 36px;
      }
      .result-no-data{
        font-size: 12px;
        margin-top: 8px;
      }
      .results-columns-header{
        margin: 16px 0px;
        display:grid;
        grid-template-columns: 40% 30% 30%;
        column-gap: 10px;
        align-items: center;
        color: #6B6B6B;
      }
      .result-container{
        border-bottom: 1px solid #EAEBEC;
        padding: 4px 0px;
        page-break-before: auto; 
        page-break-after: auto; 
        page-break-inside: avoid;
      }
      .result-container-background{
        background: rgba(217, 217, 217, 0.1);
      }
      .abnormal{
        background-color: #FFBE00;
      }
      .result {
        display:grid;
        grid-template-columns: 40% 30% 30%;
        column-gap: 10px;
        align-items: center;
        padding: 4px 0px;
        font-size: 12px;
      }
      .result-info {
        flex:1;
        margin-right: 10px;
        text-transform: capitalize;
      }
      .result-info > div:first-child{
        margin-bottom: 10px;
      }
      .result-details {
        text-align:left;
      }
    </style>
    </head>
    <body class="main">
        <div id="logos">
          ${generateHeaderImage()}
        </div>
      <div class="vertical-margin">
        <h1 class="h1">
          ${data.description}
        </h1>
      </div>
      ${generateHeader(demographics, data)}
      <div class="result-details-header">Result details</div>
      ${data.documentdata ? data.documentdata.replaceAll("\n", "<br>") : ""}
      ${
        data.analytes && data.analytes.length > 0
          ? `<div class="results-columns-header">
          <div><b>Name</b></div>
          <div><b>Value</b></div>
          <div><b>Reference range</b></div>
        </div>
        ${buildAnalytes(data.analytes)}`
          : ""
      }
      ${
        !data.documentdata && !data.analytes
          ? `<div class="result-no-data">No data available</div>`
          : ""
      }
    </body>
  </html>`;
};

const generateHeaderImage = () => {
  return `<img
      alt="Summit Health + CityMD"
      border="0"
      data-proportionally-constrained="true"
      data-responsive="false"
      height="60"
      src=${summitCityMDLogo}
      width="300"
    />`;
};

const generateHeader = (
  demographics: GET_DEMOGRAPHICS_patient_demographics,
  data: TestResultData,
) => {
  const firstName = demographics.firstName || "";
  const lastName = demographics.lastName || "";
  const dob = formatDate(demographics.dateOfBirth, "MM/dd/yyyy") || "";
  const dosDateTimeString = formatDate(data.labResultDatetime, "MM/dd/yyyy") || "";
  const orderedBy = data.providerName;
  const performingLab = data.performingLab;

  return `
    <div class="headers">
      <div class="header">
        <div>Patient Name</div>
        <div>${firstName} ${lastName}</div>
      </div>
      <div class="header">
        <div>Patient DOB</div>
        <div>${dob}</div>
      </div>
      <div class="header">
        <div>Date of service</div>
        <div>${dosDateTimeString}</div>
      </div>
      ${
        orderedBy
          ? `<div class="header">
        <div>Ordering Provider</div>
        <div>${orderedBy}</div>
        </div>
      `
          : ""
      }
      <div class="header">
        ${getPerformedBy(performingLab)} 
      </div>
      <div class="header">
        <div>Questions?</div>
        <div class="line-height" style="text-wrap:wrap">${QUESTIONS_TEXT_SUMMIT}</div>
      </div>
    </div>`;
};

const getPerformedBy = (performingLab: PerformingLab) => {
  if (
    performingLab.name &&
    performingLab.address &&
    performingLab.city &&
    performingLab.state &&
    performingLab.zip
  ) {
    return `<div>Performed by</div>
          <div>${performingLab.name}, ${performingLab.address}, ${performingLab.city}, ${performingLab.state} ${performingLab.zip}</div>`;
  }

  return `<div>Performed by</div>
  <div>${PERFORMED_BY_SUMMIT}</div>`;
};

const buildAnalytes = (analytes?: GET_LAB_DETAILS_patient_labResult_analytes[]): string => {
  if (!analytes) return "";
  return `${analytes
    .map((analyte: GET_LAB_DETAILS_patient_labResult_analytes, index) => {
      return buildAnalyte(analyte, index);
    })
    .join("")}`;
};

const buildAnalyte = (
  analyte: GET_LAB_DETAILS_patient_labResult_analytes,
  index: number,
): string => {
  const { abnormalFlag, analyteName, value, referenceRange, units } = analyte;
  const showValue = value || "";

  return `
  <div class="result-container ${index % 2 === 1 ? "result-container-background" : ""} ">
    <div class="result">
      <div class="result-info">
        ${analyteName}
      </div>
      <div class="result-details">
        ${buildResults(abnormalFlag, showValue)}
      </div>
      <div>
          ${referenceRange ? `Normal range: ${referenceRange} ${units}` : ""}
      </div>
    </div>
  </div>`;
};

const buildResults = (abnormalFlag: string | null, value: string | number) => {
  const rangeName = abnormalFlag === "normal" ? "" : abnormalFlag ? "abnormal" : "";
  return `
      
    <div class="results-value">
      <div class="${rangeName}">
        ${
          abnormalFlag && abnormalFlag !== "normal"
            ? `<span class="elevatedSVG ${rangeName}">${
                abnormalFlag === "high"
                  ? `<svg viewBox="0 0 16 16" height="8" width="8">
                        <path d="M 0,12 16,12 8,0 z"/>
                      </svg>`
                  : abnormalFlag === "low"
                  ? `<svg viewBox="0 0 16 16" height="8" width="8">
                        <path d="M 0,4 16,4 8,16 z"/>
                      </svg>`
                  : ""
              }
        </span>`
            : ""
        }
        <span>
          ${typeof value === "string" ? value.charAt(0).toUpperCase() + value.slice(1) : value}
        </span>
      </div>
      <b class="small-text range">${rangeName ? "Out of Range" : ""}</b>
    </div>
  `;
};
