import { gql, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";

import { GetOutages, GetOutages_outages } from "/apollo/schema/types";

const GET_OUTAGES = gql`
  query GetOutages {
    outages {
      id
      source
      message
      showOutageWeb
      link
    }
  }
`;

// useLazyQuery is fully typed and return value is inferred.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useQueryOutages = () => useQuery<GetOutages>(GET_OUTAGES);

const useOutages = (screenName: string): GetOutages_outages | undefined => {
  const { data: outages } = useQueryOutages();
  const [outage, setOutage] = useState<GetOutages_outages>();

  useEffect(() => {
    if (outages?.outages) {
      const _outage = outages.outages.find(
        (o: GetOutages_outages) => o.source === screenName && o.showOutageWeb === true,
      );
      if (_outage) {
        setOutage(_outage);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outages]);

  return outage;
};

export default useOutages;
