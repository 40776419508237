import styled from "styled-components";

import { layout } from "/styles";

import { Variant } from "./InlineMessage.types";

const variants = {
  primary: {
    color: "textPrimary",
    backgroundColor: "brandPrimaryLight",
    svgColor: "textNavyBlue",
  },
  important: {
    color: "textPrimary",
    backgroundColor: "brandSecondaryLight",
    svgColor: "brandSecondary",
  },
  success: {
    color: "textGreen",
    // NOTE: This one-off color was specified in the designs here:
    // https://www.figma.com/file/uSClIhaAs6ZbnSjONJ7Epj/Web-V1-V2-Screens?node-id=1083%3A33145
    backgroundColor: "#EFF9F7",
    fontSize: 300,
    svgColor: "textGreen",
  },
} as const;

export const Base = styled.div<{ variant: Variant }>(
  layout.flexCenterHorizontal,
  layout.spacedChildrenHorizontal("condensed"),
  ({ theme, variant }) => ({
    backgroundColor:
      theme.colors[variants[variant].backgroundColor] || variants[variant].backgroundColor,
    color: theme.colors[variants[variant].color],
    svg: {
      color: theme.colors[variants[variant].svgColor],
      flex: "0 0 24px",
      width: 24,
      height: 24,
    },
    borderRadius: 12,
    padding: theme.spacing.standard,
  }),
);
