import { useCallback, useEffect, useState } from "react";

import useGoogleMaps from "/hook/useGoogleMaps";
import { formatPhoneNumberE164 } from "/util/formatText";

import { GeoCoordinates, GooglePlace, Region } from "./types";
import { formatAddress, getBestPlace, getGooglePlacesUsingSDK } from "./utils";

export type useMapLocationProps = {
  address?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  phoneNumber?: string | null;
  placeName?: string | null;
  enabled?: boolean;
};
export type GeoLocation = {
  mapProvider: "google";
  coordinate: GeoCoordinates;
  region: Region;
  placeId: string;
};

export type MapLocation = {
  location?: GeoLocation;
  isLoading: boolean;
};

const useMapLocation = ({
  address,
  city,
  state,
  zip,
  phoneNumber,
  placeName,
}: useMapLocationProps): MapLocation => {
  const [mapLocation, setMapLocation] = useState<MapLocation>({
    isLoading: true,
  });
  const googleMaps = useGoogleMaps();
  const getGooglePlaceService = useCallback((): google.maps.places.PlacesService | null => {
    if (!googleMaps) return null;
    const googlePlaces = googleMaps?.places;
    if (googlePlaces) {
      return new googlePlaces.PlacesService(document.createElement("div"));
    }
    return null;
  }, [googleMaps]);

  useEffect(() => {
    const googlePlaceService = getGooglePlaceService();

    if (!googlePlaceService) return;

    async function fetchData() {
      let candidates: GooglePlace[] | undefined;
      const fullAddress = formatAddress({ address, city, state, zip });
      const phoneNumberE164 = formatPhoneNumberE164(phoneNumber);

      // try and get coordinates from google api, starting from most likely to yield results
      // to least likely
      if (googlePlaceService && placeName && fullAddress) {
        candidates = await getGooglePlacesUsingSDK(
          googlePlaceService,
          `${placeName} ${fullAddress}`,
        );
      }
      if (googlePlaceService && !candidates && phoneNumberE164) {
        // FYI: PhoneNumber should be formatted: +13125880704(from: +1 312 588 0704)
        candidates = await getGooglePlacesUsingSDK(
          googlePlaceService,
          phoneNumberE164,
          "phonenumber",
        );
      }
      if (googlePlaceService && !candidates && fullAddress) {
        candidates = await getGooglePlacesUsingSDK(googlePlaceService, fullAddress);
      }
      const place = getBestPlace(candidates || [], { address, city, state, zip }, placeName);

      if (place) {
        const { location, viewport } = place.geometry;
        const coordinates = {
          latitude: location.lat,
          longitude: location.lng,
        };
        setMapLocation({
          location: {
            mapProvider: "google",
            coordinate: coordinates,
            region: {
              ...coordinates,
              latitudeDelta: viewport.northeast.lat - viewport.southwest.lat,
              longitudeDelta: viewport.northeast.lng - viewport.southwest.lng,
            },
            placeId: place?.placeId,
          },
          isLoading: false,
        });
      } else {
        setMapLocation({
          location: undefined,
          isLoading: false,
        });
      }
    }
    fetchData();
  }, [placeName, phoneNumber, address, city, state, zip, getGooglePlaceService]);

  return mapLocation;
};

export default useMapLocation;
