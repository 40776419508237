import { gql, useQuery } from "@apollo/client";

import { GetFaqQuery } from "/apollo/schema";

const FAQ_QUERY = gql`
  query GetFaqQuery {
    faqContent {
      id
      question
      body
      isTopQuestion
      categories {
        id
      }
    }
    faqCategories {
      id
      title
      description
      icon
      ctaTile
      ctaName
      ctaHeader
      ctaSubHeader
      ctaContact
      ctaSecondaryName
      ctaSecondaryContact
    }
  }
`;

const useFaqQuery = () => useQuery<GetFaqQuery>(FAQ_QUERY);

export default useFaqQuery;
