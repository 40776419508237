import { useReactiveVar } from "@apollo/client";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { auth, scheduling } from "/apollo/client/local";
import routes from "/constant/url.constant";

const useBookingReroute = (appIsReady: boolean) => {
  const { booking, reasonForVisit, isBookingComplete } = useReactiveVar(scheduling.var);
  const { loading: authLoading, isAuthenticated } = useReactiveVar(auth.var);
  const history = useHistory();

  const bookingHasExpired = () => {
    return booking && new Date(booking.reservation.expirationTime) < new Date();
  };

  // Note, we explicitly do not pass in booking, reasonForVisit, and isBookingComplete
  // to the second param of `useEffect` because we only want to re-route upon page loading
  // with auth/patient being ready and NOT when booking changes.
  useEffect(() => {
    if (appIsReady && isAuthenticated && !isBookingComplete) {
      if (bookingHasExpired()) {
        scheduling.reset();
        history.push(`${routes.scheduling}`, { bookingExpired: true });
        return;
      }
      // Push to booking if the user was attempting to
      // book an appointment while not authenticated
      if (booking && reasonForVisit) {
        history.push(`${routes.scheduling}${routes.confirm}`, { fromRedirect: true });
      } else if (booking && !reasonForVisit) {
        history.push(`${routes.scheduling}${routes.visitReason}`, { fromRedirect: true });
      }
    }
  }, [isAuthenticated, authLoading, appIsReady]);
};

export default useBookingReroute;
