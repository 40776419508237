import { useReactiveVar } from "@apollo/client";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useHistory } from "react-router-dom";

import { diagnosticReportFilters } from "/apollo/client/local";
import { GetDiagnosticReportsVariables, SortDirection } from "/apollo/schema";
import myHealthNoResults from "/asset/image/myHealthNoResults.png";
import { Loading, Text } from "/component/base";
import MainContent from "/component/layout/MainContent";
import DiagnosticReportsFilterModal from "/component/modal/DiagnosticReportsFilterModal";
import DiagnosticReportItem from "/component/partial/DiagnosticReportItem";
import EmptyStateCta from "/component/partial/EmptyStateCta";
import FilterButton from "/component/partial/FilterButton";
import routes from "/constant/url.constant";
import { useTranslation } from "/hook";
import { layout } from "/styles";
import { reactPlugin } from "/util/appInsights.util";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  IndentifierParams,
  logEvent,
} from "/util/firebase.util";

import { useResults } from "./useResults";

const IMAGE_HEIGHT = 229;
const IMAGE_WIDTH = 331;

const getFilterCount = (filters: Omit<GetDiagnosticReportsVariables, "lat" | "lng">) => {
  const activeFilters = [
    (filters.categories || []).length < 2,
    !!filters.endDate,
    !!filters.startDate,
    filters.sort !== SortDirection.DESC,
  ].filter((filter) => filter);

  return activeFilters.length;
};

const Results = () => {
  const history = useHistory();
  const [showFilters, setShowFilters] = useState(false);
  const filters = useReactiveVar(diagnosticReportFilters.var);
  const activefilterCount = getFilterCount(filters);
  const { t } = useTranslation("results");
  const { data, error, fetchMore, loading } = useResults({
    categories: filters.categories,
    endDate: filters.endDate,
    first: filters.first,
    sort: filters.sort,
    startDate: filters.startDate,
  });
  const { edges: diagnosticReports, pageInfo } =
    data?.viewer?.user.patient?.diagnosticReports || {};

  const handleFetchMore = async () => {
    if (pageInfo?.hasNextPage) {
      fetchMore({ variables: { after: pageInfo.endCursor } });
    }
  };

  const logButtonClickEvent = (buttonName: string, params?: IndentifierParams) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.MY_HEALTH,
      source: AnalyticsSource.RESULTS,
      button_name: buttonName,
      ...params,
    } as ButtonClickParams);
  };

  if (error) {
    <MainContent parentPage="myHealth">
      <EmptyStateCta
        imageHeight={IMAGE_HEIGHT}
        imageWidth={IMAGE_WIDTH}
        imageSource={myHealthNoResults}
        title={t("error.fetchError.title")}
        subtitle={t("error.fetchError.subtitle")}
      />
    </MainContent>;
  }

  if (!error && !loading && !diagnosticReports?.length && activefilterCount === 0) {
    const hasFacilities = data?.smgFacilities && data?.smgFacilities.length > 0;

    return (
      <MainContent parentPage="myHealth">
        <div css={[layout.flexCenter, { flex: "1 1 auto" }]}>
          <EmptyStateCta
            imageHeight={IMAGE_HEIGHT}
            imageWidth={IMAGE_WIDTH}
            imageSource={myHealthNoResults}
            title={t("error.noResults.title")}
            subtitle={t("error.noResults.subtitle")}
            ctaText={t("error.noResults.ctaText")}
            onClickCta={hasFacilities ? () => history.push(routes.scheduling) : undefined}
          />
        </div>
      </MainContent>
    );
  }

  return (
    <MainContent
      headerAccessory={
        <FilterButton
          aria-label={t("label.filterButton", { count: activefilterCount })}
          count={activefilterCount}
          onClick={() => {
            logButtonClickEvent("Filter (icon)");
            setShowFilters(true);
          }}
        />
      }
      pageTitle={t("headline.results")}
      parentPage="myHealth"
    >
      <Helmet>
        <title>{t("pageTitle")}</title>
      </Helmet>

      {loading && <Loading variant="page" />}

      {!error && !loading && (
        <>
          {diagnosticReports?.length ? (
            <>
              <Text
                css={layout.padding("skip", "skip", "expanded")}
                element="h2"
                color="textSecondary"
                variant="body1Bold"
              >
                {t("title.recentResults")}
              </Text>

              <InfiniteScroll
                dataLength={diagnosticReports?.length || 0}
                hasChildren
                hasMore={!!pageInfo?.hasNextPage}
                loader={<Loading variant="list" />}
                next={handleFetchMore}
                style={{ overflow: "visible" }}
              >
                <ul css={layout.spacedChildrenVertical()}>
                  {diagnosticReports.map(({ node }) => (
                    <li key={node.id}>
                      <Link
                        to={`${routes.results}/${node.id}`}
                        onClick={() => logButtonClickEvent("Result", { result_id: node.id })}
                      >
                        <DiagnosticReportItem
                          date={node.receivedDateTime}
                          title={node.reportTitle}
                        />
                      </Link>
                    </li>
                  ))}
                </ul>
              </InfiniteScroll>
            </>
          ) : (
            <div
              css={[
                layout.flexVerticalCenter,
                layout.padding("expanded", "skip"),
                layout.spacedChildrenVertical(),
              ]}
            >
              <EmptyStateCta
                imageHeight={IMAGE_HEIGHT}
                imageWidth={IMAGE_WIDTH}
                imageSource={myHealthNoResults}
                title={t("error.filteredResults.title")}
                subtitle={t("error.filteredResults.subtitle")}
              />
            </div>
          )}
        </>
      )}

      <DiagnosticReportsFilterModal isOpen={showFilters} close={() => setShowFilters(false)} />
    </MainContent>
  );
};

export default withAITracking(reactPlugin, Results, undefined, "ai-tracking");
