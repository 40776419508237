import awsdk from "awsdk";

import { useAmwell, useAmwellQuery } from "/hook";
import { parsePersistentMessageId } from "/util/awsdk.util";

import { Payload, Variables } from "./useAmwellMessageDetails.types";

/**
 * This hook allows for the retrieval of the details for a given message.
 * In addition to the response data, it also includes boolean values to indicate
 * loading and error states.
 *
 * The Amwell SDK does not allow for the lookup of a message's details by ID,
 * so this hook takes an ID, and fetches either the user's inbox or outbox to
 * find a matching `AWSDKSecureMessage`, which we then call `getMessageDetail`
 * with. This is kind of excessive and redundant, because we sometimes already
 * have the `AWSDKSecureMessage` already, but is necessary for supporting cases
 * like directly linking to a message, or opening a message in a new tab.
 */
export const useAmwellMessageDetails = ({ inbox, messageId }: Variables) => {
  const { consumer, sdk } = useAmwell();
  const fetch = async () => {
    if (sdk && consumer && messageId) {
      const compareId = ({ id }: awsdk.AWSDKSecureMessage) =>
        parsePersistentMessageId(id.persistentId) === messageId;
      const { messages } = inbox
        ? await sdk?.secureMessageService.getInboxMessages(consumer)
        : await sdk?.secureMessageService.getSentMessages(consumer);
      const selectedMessage = messages.find(compareId);

      if (selectedMessage) {
        const messageDetails = await sdk.secureMessageService.getMessageDetail(
          consumer,
          selectedMessage,
        );

        return { message: selectedMessage, messageDetails };
      }
    }

    return { message: undefined, messageDetails: undefined };
  };

  return useAmwellQuery<Payload>(fetch, [inbox, messageId]);
};

export default useAmwellMessageDetails;
