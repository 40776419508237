import awsdk from "awsdk";

import { useAmwell, useAmwellQuery } from "/hook";

import { Variables } from "./useAmwellOutbox.types";

/**
 * This hook allows for the retrieval of the currently authenticated Amwell
 * userʼs sent messages. In addition to the response data, it also includes
 * boolean values to indicate loading and error states.
 */
export const useAmwellOutbox = ({ startIndex, maxResults = 5 }: Variables) => {
  const { consumer, sdk } = useAmwell();
  const fetch = async () =>
    sdk && consumer
      ? await sdk.secureMessageService.getSentMessages(consumer, startIndex, maxResults)
      : undefined;

  return useAmwellQuery<awsdk.AWSDKSentMessages>(fetch, [startIndex, maxResults]);
};

export default useAmwellOutbox;
