import { gql, MutationHookOptions } from "@apollo/client";

import { useB2CMutation } from "/b2c/client";

import { CancelAppointment, CancelAppointmentVariables } from "../schema";

const CANCEL_APPOINTMENT = gql`
  mutation CancelAppointment($appointmentId: Int!) {
    appointment {
      cancelAppointment(appointmentId: $appointmentId) {
        status
      }
    }
  }
`;

export const useCancelAppointment = (
  options: MutationHookOptions<CancelAppointment, CancelAppointmentVariables>,
) => useB2CMutation<CancelAppointment, CancelAppointmentVariables>(CANCEL_APPOINTMENT, options);
