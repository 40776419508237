import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Text } from "/component/base";

import { ContactUsLink } from "../ContactUs/ContactUs.styles";
import OTPComponent from "./OTPComponent";
import { Error } from "./OTPVerification.styles";

const TOTAL_RETRY_COUNT = 5;

interface Props {
  onSubmit: (passcode: string) => Promise<boolean>;
  onResendClick?: () => void;
}

const OTPVerification: React.FC<Props> = ({ onSubmit, onResendClick }) => {
  const { t } = useTranslation("partial-OTPVerification");
  const [retryCount, setRetryCount] = useState<number>(0);
  const defaultSupportEmail = `AppSupport@summithealth.com`;

  const showErrorView = () => {
    if (retryCount > 0) {
      return (
        <Error>
          {retryCount < TOTAL_RETRY_COUNT ? t("incorrectError") : t("retryExceedError")}
          {retryCount >= TOTAL_RETRY_COUNT && (
            <ContactUsLink target="#" href={`mailto:${defaultSupportEmail}`}>
              <Text color="brandPrimary" variant="body1">
                {` ${defaultSupportEmail.toLocaleLowerCase()}`}
              </Text>
            </ContactUsLink>
          )}
        </Error>
      );
    }
    return null;
  };

  return (
    <OTPComponent
      headerTitle={t("title")}
      subTitle={t("subTitle")}
      description={t("description")}
      resendTitle={t("resendTitle")}
      submitTitle={t("submitTitle")}
      errorView={retryCount > 0 ? showErrorView() : null}
      onResendClick={() => {
        setRetryCount(0);
        onResendClick && onResendClick();
      }}
      onSubmit={async (passcode) => {
        const success = await onSubmit(passcode);
        if (!success) {
          setRetryCount(retryCount + 1);
        } else {
          setRetryCount(0);
        }
      }}
    />
  );
};

export default OTPVerification;
