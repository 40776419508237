import awsdk from "awsdk";

import { useAmwell, useAmwellQuery } from "/hook";

/**
 * This hook allows for the retrieval of the pharmacies for a consumer. A
 * search may only be performed when authenticated, and when an AWSDKProvider is
 * provided via parameters. In addition to the response data, it also includes
 * boolean values to indicate loading and error states.
 */
export const useAmwellConsumerPharmacies = () => {
  const { consumer, sdk, isReady } = useAmwell();
  const fetch = async () =>
    consumer && sdk && isReady
      ? await sdk.pharmacyService.getPharmaciesForConsumer(consumer)
      : undefined;

  return useAmwellQuery<awsdk.AWSDKPharmacy[]>(fetch, [consumer]);
};

export default useAmwellConsumerPharmacies;
