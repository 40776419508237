import { getRemoteConfig } from "../countly";
import { FeatureFlagKey, FeatureStatus } from "./types";

type FeatureFlags = {
  [key in FeatureFlagKey]: FeatureStatus;
};
const defaultFeatureFlags: FeatureFlags = {
  documents_enabled: FeatureStatus.Legacy,
  care_summaries_enabled: FeatureStatus.Legacy,
  immunization_enabled: FeatureStatus.Enabled,
  insurance_enabled: FeatureStatus.Legacy,
  personal_info_enabled: FeatureStatus.Legacy,
  test_results_enabled: FeatureStatus.Legacy,
  medications_enabled: FeatureStatus.Legacy,
};

const toFeatureStatus = (value: boolean | "legacy"): FeatureStatus => {
  switch (value.toString().toLowerCase()) {
    case "legacy":
      return FeatureStatus.Legacy;
    case "true":
      return FeatureStatus.Enabled;
    case "false":
    default:
      return FeatureStatus.Disabled;
  }
};

export const getFeatureFlag = (featureFlagKey: FeatureFlagKey): FeatureStatus => {
  const remoteConfig = getRemoteConfig();
  if (featureFlagKey in remoteConfig) {
    return toFeatureStatus(remoteConfig[featureFlagKey]);
  } else if (featureFlagKey in defaultFeatureFlags) {
    return defaultFeatureFlags[featureFlagKey];
  }
  return FeatureStatus.Disabled;
};

export const hasFeatureFlagLegacy = (featureFlagKey: FeatureFlagKey): boolean => {
  return getFeatureFlag(featureFlagKey) === FeatureStatus.Legacy;
};

export const hasFeatureFlagEnabled = (featureFlagKey: FeatureFlagKey): boolean => {
  return getFeatureFlag(featureFlagKey) === FeatureStatus.Enabled;
};

export const hasFeatureFlagDisabled = (featureFlagKey: FeatureFlagKey): boolean => {
  return getFeatureFlag(featureFlagKey) === FeatureStatus.Disabled;
};
