import { transparentize } from "polished";

import theme from "/theme/default";

export const base = {
  textDecoration: "none",
  borderBottom: `solid 1.5px ${transparentize(0.7, theme.colors.brandPrimary)}`,

  "&:active": {
    opacity: 0.65,
  },
};
