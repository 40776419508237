import styled from "styled-components";

import Avatar from "/component/base/Avatar";
import Text from "/component/base/Text";
import { card, layout } from "/styles";

export const ProviderAvatar = styled(Avatar)(({ theme }) => ({
  marginRight: theme.spacing.standard,
}));

export const ProviderItemContainer = styled.div(
  card.base,
  layout.flexCenterHorizontal,
  layout.paddingVertical("condensed"),
  ({ theme }) => ({
    width: "100%",
    padding: theme.spacing.standard,
    marginBottom: theme.spacing.standard,
  }),
);

export const ProviderDetailsContainer = styled.div(
  layout.flexVertical,
  layout.paddingVertical("condensed"),
  {
    alignItems: "flex-start",
  },
);

export const ProviderItemTitle = styled(Text)(({ theme }) => ({
  paddingBottom: theme.spacing.base,
}));

export const AvailabilityStatusContainer = styled.div(
  layout.margin("base", "skip", "skip", "skip"),
);
