import { gql, useQuery } from "@apollo/client";

import { CaregiverPortalQuery } from "/apollo/schema/types";

const GET_CAREGIVER_PORTAL_QUERY = gql`
  query CaregiverPortalQuery {
    caregiverPortal
  }
`;

// useQuery is fully typed and return value is inferred.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useCaregiverPortal = () =>
  useQuery<CaregiverPortalQuery>(GET_CAREGIVER_PORTAL_QUERY, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

export default useCaregiverPortal;
