import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React, { useEffect } from "react";
import { Trans } from "react-i18next";

import { Button, Text } from "/component/base";
import { FeatherIcon } from "/component/base/Icon";
import { useContactInfo, useTranslation } from "/hook";
import theme from "/theme/default";
import { formatText } from "/util";
import { reactPlugin } from "/util/appInsights.util";
import { AnalyticsEvent, AnalyticsSource, logEvent, ScreenViewParams } from "/util/firebase.util";

// The Link styles in this Alert are one off, so we don't use
// the base component
import { Alert, Anchor } from "./KickoutAlert.styles";

type Props = {
  onClose: () => void;
  message: string;
};

const KickoutAlert = ({ onClose, message }: Props) => {
  const { t } = useTranslation("scheduling");
  const { contactByName } = useContactInfo();
  const callCenterPhone = contactByName("summitCallCenter")?.phoneNumber;

  useEffect(() => {
    if (message) {
      logEvent(AnalyticsEvent.SCREEN_VIEW, {
        screenName: AnalyticsSource.DECISION_SUPPORT_KICKOUT,
      } as ScreenViewParams);
    }
  }, [message]);

  return (
    <Alert
      icon={<FeatherIcon name="alertCircle" size={40} color="brandSecondary" />}
      close={onClose}
      footer={
        <Button fullWidth="flex" onClick={onClose}>
          {t("button.done")}
        </Button>
      }
      // For the moment, we don't show the message from experian, but copy
      // defined by the SCMD team
      title={t("decisionSupport.kickout.title")}
      isOpen={!!message}
    >
      {message ? (
        <p>
          <Text
            css={{
              "& > a:visited": {
                color: theme.colors.brandSecondary,
              },
              "& > a": {
                color: theme.colors.brandSecondary,
              },
            }}
            dangerouslySetInnerHTML={{ __html: message }}
            color="textSecondary"
            variant="body1"
          ></Text>
        </p>
      ) : (
        <p>
          <Trans
            components={{
              anchor: <Anchor href={`tel:${callCenterPhone}`} />,
            }}
            i18nKey="decisionSupport.kickout.description"
            t={t}
            values={{ phoneNumber: formatText.parsePhone(callCenterPhone) }}
          />
        </p>
      )}
    </Alert>
  );
};

export default withAITracking(reactPlugin, KickoutAlert, undefined, "ai-tracking");
