import React from "react";

import { Alert, Button, Text } from "/component/base";
import { useTranslation } from "/hook";
import { layout } from "/styles";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  logEvent,
} from "/util/firebase.util";

import { Props } from "./CancelCaregiverInvitationModal.types";

const CancelCaregiverInvitationModal = ({ isOpen, onConfirm, onCancel }: Props) => {
  const { t } = useTranslation("modal-CancelCaregiverInvitationModal");

  const logButtonClickEvent = (buttonName: string) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.CAREGIVER,
      source: AnalyticsSource.MODAL_CAREGIVER_CANCEL_INVITATION,
      button_name: buttonName,
    } as ButtonClickParams);
  };

  return (
    <Alert
      close={() => null}
      isOpen={isOpen}
      title={t("title")}
      footer={
        <div css={[layout.flexVerticalCenter, layout.spacedChildrenVertical("standard")]}>
          <Button
            fullWidth="flex"
            onClick={() => {
              logButtonClickEvent("No, don't cancel");
              onConfirm();
            }}
            variant="primary"
          >
            {t("cancel")}
          </Button>
          <Button
            onClick={() => {
              logButtonClickEvent("Cancel invitation");
              onCancel();
            }}
            variant="borderBottom"
          >
            {t("confirm")}
          </Button>
        </div>
      }
    >
      <Text variant="body1" color="textSecondary">
        {t("subtitle")}
      </Text>
    </Alert>
  );
};

export default CancelCaregiverInvitationModal;
