import styled from "styled-components";

import { card, layout } from "/styles";

export const DocsWrapper = styled.div(
  layout.flexVertical,
  layout.margin("expanded", "none", "none", "none"),
);

export const ListContainer = styled.div(card.wrapper);

export const SegmentedControlWrapper = styled.div(
  layout.margin("expanded", "none", "none", "none"),
);

export const NoPharmacyCardWrapper = styled.aside(
  card.base,
  layout.padding("gutter"),
  layout.marginVertical("gutter"),
  ({ theme }) => ({
    backgroundColor: theme.colors.brandPrimaryLight,
  }),
);
