import { useCombobox } from "downshift";
import React, { forwardRef, useContext } from "react";
import { createPortal } from "react-dom";
import { ThemeContext } from "styled-components";

import Text from "/component/base/Text";
import TextInput from "/component/base/TextInput";
import { useModalContext } from "/component/provider/ModalProvider";
import Transition from "/component/util/Transition";
import usePopover from "/hook/usePopover";

import { ComboboxList, ListItem } from "./Combobox.styles";
import { ComboboxType } from "./Combobox.types";

const Combobox: ComboboxType = forwardRef(
  (
    {
      variant,
      className,
      inputValue,
      itemToString = (item: unknown) => (item ? String(item) : ""),
      items = [],
      onChangeSelectedItem,
      onChangeText,
      onFocus: onFocusProp,
      onBlur: onBlurProp,
      portalTarget,
      ...otherProps
    },
    ref,
  ) => {
    const { spacing } = useContext(ThemeContext);
    const {
      setAnchorElement,
      setPopoverElement,
      popoverStyles,
      updatePopoverPosition,
      popoverState,
    } = usePopover({
      strategy: "absolute",
      placement: "bottom-start",
      modifiers: [{ name: "offset", options: { offset: [0, spacing.condensed] } }],
    });

    const { modalEl } = useModalContext();

    const {
      closeMenu,
      getComboboxProps,
      getInputProps,
      getItemProps,
      getMenuProps,
      highlightedIndex,
      isOpen,
      openMenu,
    } = useCombobox({
      inputValue,
      items,
      itemToString,
      onHighlightedIndexChange: (changes) => {
        if (
          (changes.type === useCombobox.stateChangeTypes.ItemClick ||
            changes.type === useCombobox.stateChangeTypes.InputKeyDownEnter) &&
          !changes.isOpen &&
          !!changes.selectedItem
        ) {
          onChangeSelectedItem && onChangeSelectedItem(changes.selectedItem);
        }
      },
    });

    return (
      <>
        <div
          {...getComboboxProps({
            ref: (e) => {
              setAnchorElement(e);
            },
          })}
        >
          <TextInput
            variant={variant}
            className={className}
            {...otherProps}
            {...getInputProps({
              ref,
              onChange: (e) => {
                onChangeText?.((e.target as HTMLInputElement).value || "");
              },
              onFocus: (e) => {
                onFocusProp?.(e, { isOpen, openMenu, closeMenu });
              },
              onBlur: (e) => {
                onBlurProp?.(e, { isOpen, openMenu, closeMenu });
              },
            })}
          />
        </div>

        {createPortal(
          <div {...getMenuProps({ ref: (e) => setPopoverElement(e) })} style={popoverStyles}>
            <Transition show={isOpen && !!items.length} beforeEnter={updatePopoverPosition}>
              <ComboboxList
                $animateFromBottom={!!popoverState?.placement.match(/^top/)}
                style={{ width: popoverState?.rects?.reference.width }}
              >
                {items.map((item, i) => (
                  <ListItem
                    $active={highlightedIndex === i}
                    $last={i === items.length - 1}
                    key={i}
                    {...getItemProps({ item, index: i })}
                  >
                    <Text color="textSecondary">{itemToString(item)}</Text>
                  </ListItem>
                ))}
              </ComboboxList>
            </Transition>
          </div>,
          portalTarget || modalEl || document.body,
        )}
      </>
    );
  },
);

export default Combobox;
