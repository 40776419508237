import styled from "styled-components";

import { getVariantStyle, VariantStyleProps } from "/component/base/Button/Button.styles";
import { layout } from "/styles";
import { ThemeType } from "/theme/default";

export const PharmacyButton = styled.a<ThemeType & VariantStyleProps>(
  layout.flexCenter,
  ({ theme, variant }) => getVariantStyle({ theme, $variant: variant || "primary" }),
  { width: "100%" },
);
