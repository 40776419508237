import styled from "styled-components";

import Text from "/component/base/Text";
import { card, layout } from "/styles";

export const CardContainer = styled.div(card.wrapper);

export const FaqCategoryTitle = styled(Text)(layout.margin("skip", "skip", "standard"));

export const FaqText = styled(Text)(layout.padding("gutter", "skip"));
