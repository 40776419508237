import React from "react";

import { Checkbox, Label, Thumb } from "./Toggle.styles";

const Toggle = React.forwardRef<HTMLInputElement, React.ComponentPropsWithoutRef<"input">>(
  ({ className, checked, disabled, ...otherProps }, ref) => (
    <Label className={className} $checked={checked} $disabled={disabled}>
      <Thumb $checked={checked} $disabled={disabled} />
      <Checkbox checked={checked} disabled={disabled} {...otherProps} ref={ref} type="checkbox" />
    </Label>
  ),
);

export default Toggle;
