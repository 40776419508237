// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";

import React, { useRef, useState } from "react";
// import Swiper core and required modules
import SwiperCore, { A11y, Navigation, Swiper as SwiperBase } from "swiper";
import { SwiperSlide } from "swiper/react";

import { ChevronLeft, ChevronRight } from "/component/base/Icon";
import formatTimeSlotDateTime from "/component/page/Scheduling/util/formatTimeSlotDate";

import { FadeOverlay, NavButton, Swiper, TimeButton } from "./TimeSlots.styles";
import { Props } from "./TimeSlots.types";
SwiperCore.use([A11y, Navigation]);

const TimeSlots = ({ availability, disabled, onClickAvailabilitySlot }: Props) => {
  const [isEnd, setIsEnd] = useState<boolean>(false);
  const [isBeginning, setIsBeginning] = useState<boolean>(false);
  const [timeSlotClicked, setTimeSlotClicked] = useState<string | null>(null);
  const swiperRef = useRef<SwiperBase | null>(null);

  const PrevNav = () => (
    <>
      <NavButton
        slot="container-start"
        $direction="start"
        variant="blank"
        onClick={() => !isBeginning && swiperRef.current?.slidePrev()}
      >
        <ChevronLeft color="brandSecondary" />
      </NavButton>
      <FadeOverlay slot="container-start" $direction="start" />
    </>
  );
  const NextNav = () => (
    <>
      <NavButton
        slot="container-end"
        $direction="end"
        variant="blank"
        onClick={() => !isEnd && swiperRef.current?.slideNext()}
      >
        <ChevronRight color="brandSecondary" />
      </NavButton>
      <FadeOverlay slot="container-end" $direction="end" />
    </>
  );

  return (
    <Swiper
      onReachEnd={() => {
        setIsEnd(true);
      }}
      onReachBeginning={() => {
        setIsBeginning(true);
      }}
      onSlideChange={(swiper) => {
        setIsBeginning(swiper.isBeginning);
        setIsEnd(swiper.isEnd);
      }}
      onSwiper={(swiper) => {
        swiperRef.current = swiper;
        setIsEnd(swiper.isEnd);
        setIsBeginning(swiper.isBeginning);
      }}
      spaceBetween={8}
      slidesPerView={"auto"}
      watchSlidesVisibility
      watchSlidesProgress
      watchOverflow
      a11y={{
        containerMessage: "Available Timeslots",
        nextSlideMessage: "Show later times",
        prevSlideMessage: "Show earlier times",
        slideLabelMessage: "Time {{index}} out of {{slidesLength}}",
      }}
    >
      {availability.timeSlots.map((slot) => (
        <SwiperSlide key={slot.availabilityId}>
          {({ isVisible }) => (
            <TimeButton
              variant="tertiarySmall"
              isDisabled={disabled}
              isLoading={timeSlotClicked === slot.availabilityId}
              onClick={async () => {
                setTimeSlotClicked(slot.availabilityId);
                await onClickAvailabilitySlot(slot.availabilityId, availability);
                setTimeSlotClicked(null);
              }}
              tabIndex={isVisible ? undefined : -1}
            >
              {formatTimeSlotDateTime(slot, "h:mm a")}
            </TimeButton>
          )}
        </SwiperSlide>
      ))}

      {!isBeginning && <PrevNav />}
      {!isEnd && <NextNav />}
    </Swiper>
  );
};

export default TimeSlots;
