import React from "react";

import Button from "/component/base/Button";
import Modal from "/component/base/Modal";
import Text from "/component/base/Text";
import FormContent from "/component/partial/FormContent";
import { useTranslation } from "/hook";

import { Props } from "./TelehealthCancelVisitModal.types";

const TelehealthCancelVisitModal = ({ isOpen, close, handleSubmit }: Props) => {
  const { t } = useTranslation("modal-telehealthCancelVisit");

  return (
    <Modal
      isOpen={isOpen}
      key="TelehealthCancelVisitModal"
      close={close}
      header={<Modal.Header title="" close={close}></Modal.Header>}
    >
      <FormContent>
        <Modal.Title>
          <Text variant="title1">{t("title")}</Text>
        </Modal.Title>
        <Modal.Description>
          <Text variant="body2" color="textSecondary">
            {t("description")}
          </Text>
        </Modal.Description>
      </FormContent>
      <FormContent.ButtonGroup>
        <Button onClick={close} variant="secondary">
          {t("buttonNo")}
        </Button>
        <Button onClick={handleSubmit} variant="primary">
          {t("buttonYes")}
        </Button>
      </FormContent.ButtonGroup>
    </Modal>
  );
};

export default TelehealthCancelVisitModal;
