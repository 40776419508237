import { makeVar } from "@apollo/client";

interface AuthState {
  /**
   * The JWT that we will pass along with requests to the API to authorize requests
   */
  token: string | null;
  /**
   * `isAuthenticated` is a boolean which just indicates whether or not `token` is falsey. We need this
   * separate boolean so that we can use the `@client` directive in our GQL queries to prevent
   * queries from running when we do not have a valid token.
   */
  isAuthenticated: boolean;
  signUpRedirect: string | null;
  /**
   * `loading` is initially set to true. Once MSAL (in useAuth.tsx) has finished attempting to log
   * the user in, whether login fails or succeeds, `loading` will be set to false. Once loading is false,
   * the values of `isAuthenticated` and `token` will accurately reflect the user's auth state.
   */
  loading: boolean;
  caregiver: string | null;
}

const reactiveVar = makeVar<AuthState>({
  token: null,
  isAuthenticated: false,
  loading: true,
  signUpRedirect: "",
  caregiver: null,
});

export default {
  var: reactiveVar,
  setToken(token: string | null) {
    const currentValue = reactiveVar();
    return reactiveVar({
      ...currentValue,
      token,
      isAuthenticated: !!token,
      loading: false,
    });
  },
  setSignUpRedirect(redirect: string | null) {
    const currentValue = reactiveVar();
    return reactiveVar({
      ...currentValue,
      signUpRedirect: redirect,
    });
  },
  setCaregiver(caregiver: string | null) {
    const currentValue = reactiveVar();
    return reactiveVar({
      ...currentValue,
      caregiver,
    });
  },
  setLoading(loading: boolean) {
    const currentValue = reactiveVar();
    return reactiveVar({
      ...currentValue,
      loading,
    });
  },
  read() {
    return reactiveVar();
  },
};
