import awsdk from "awsdk";
import React from "react";

import { Button, List } from "/component/base";
import ListItem from "/component/base/ListItem";
import Text from "/component/base/Text";
import ConsumerPharmacyItem from "/component/partial/ConsumerPharmacyItem";
import FormContent from "/component/partial/FormContent";
import { ConsumerPharmacyProps } from "/component/provider/AmwellPrevisitProvider";
import { PrevisitForms } from "/constant/telehealth.constant";
import { useTranslation } from "/hook";
import useAmwellConsumerPharmacies from "/hook/useAmwellConsumerPharmacies";
import { layout } from "/styles";

import { Title } from "../Telehealth.styles";
import { TelehealthFormProps } from "../Telehealth.types";

const ConsumerPharmacy = ({ handleSubmit }: TelehealthFormProps<ConsumerPharmacyProps>) => {
  const { t } = useTranslation("form-telehealth-previsit");
  const { data: pharmacies } = useAmwellConsumerPharmacies();

  const submitForm = (addPharmacy: boolean) => {
    handleSubmit(
      { anotherPharmacy: addPharmacy },
      addPharmacy ? undefined : PrevisitForms.PATIENT_CALLBACK,
    );
  };

  return (
    <FormContent css={layout.margin("gutter", "skip")}>
      <Title>{t("consumerPharmacy.title")}</Title>
      {pharmacies && pharmacies.length > 0 && (
        <List
          as="Buttons"
          data={[pharmacies[0]]}
          hideLastChildBorder={false}
          renderItem={({ name, address, formattedPhone }: awsdk.AWSDKPharmacy) => (
            <ConsumerPharmacyItem name={name} address={address} formattedPhone={formattedPhone} />
          )}
          onClick={() => {
            submitForm(false);
          }}
        />
      )}
      <ListItem.Button
        css={layout.padding("skip", "skip", "standard", "skip")}
        showChevron={true}
        showBorder={true}
        onClick={() => submitForm(true)}
      >
        <Text variant="body1Bold">{t("consumerPharmacy.anotherPharmacy")}</Text>
      </ListItem.Button>
      <Button
        css={[layout.margin("gutter", "none", "none"), { alignSelf: "flex-start" }]}
        variant="borderBottom"
        onClick={() => {
          submitForm(false);
        }}
      >
        {t("consumerPharmacy.skip")}
      </Button>
    </FormContent>
  );
};

export default ConsumerPharmacy;
