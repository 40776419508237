import styled from "styled-components";

import { Text } from "/component/base";
import FormContent from "/component/partial/FormContent";
import { layout } from "/styles";

export const FormContentContainer = styled(FormContent)(layout.marginVertical("gutter"), {
  maxWidth: 550,
  width: "100%",
});
export const ContentContainer = styled.div(
  layout.flexVertical,
  layout.spacedChildrenVertical("expanded"),
);
export const SectionContainer = styled.div(
  layout.flexVertical,
  layout.spacedChildrenVertical("standard"),
);

export const Title = styled(Text).attrs({
  variant: "title1",
  element: "h2",
  color: "textTitle",
  align: "center",
})(layout.padding("skip", "skip", "skip"));
export const InfoLabelContainer = styled.div(layout.spacedChildrenHorizontal("base"));
