import React from "react";

import Text from "/component/base/Text";
import { useTranslation } from "/hook";
import { formatDate } from "/util/date";

import { ContentWrapper, TextContent, TitleBadge, TitleContent } from "./DocumentCard.styles";
import { Props } from "./DocumentCard.types";

const DocumentCard = ({ document }: Props) => {
  const { t } = useTranslation("partial-documentCard");

  const title = document.title;
  const subTitle = document.providerName;
  const docDate = formatDate(document.date, "MMMM dd, yyyy");
  const isRead = document.isRead;

  return (
    <ContentWrapper>
      <TextContent>
        <TitleContent>
          <Text color={"textPrimary"} variant="body1Bold">
            {title}
          </Text>
          {!isRead && (
            <TitleBadge color={"textPrimary"} variant="body3Bold">
              {t("new")}
            </TitleBadge>
          )}
        </TitleContent>

        {subTitle && (
          <Text color="textSecondary" variant="body2">
            {subTitle}
          </Text>
        )}

        <Text variant="body2" color="textSecondary">
          {docDate}
        </Text>
      </TextContent>
    </ContentWrapper>
  );
};

export default DocumentCard;
