import styled from "styled-components";

import BaseDivider from "/component/base/Divider";
import { layout } from "/styles";

export const Divider = styled(BaseDivider)(({ theme }) => ({
  [theme.media.max.tabletPortrait]: {
    display: "none",
  },
}));

export const BaseLink = styled.a(({ theme }) => ({
  marginLeft: theme.spacing.base,
  color: theme.colors.brandNavy,
  borderBottom: `1px solid ${theme.colors.brandNavy}`,
}));

export const HeaderContainer = styled.div(
  layout.spacedChildrenHorizontal("condensed"),
  ({ theme }) => ({
    display: "flex",
    alignItems: "center",
    [theme.media.max.tablet]: {
      justifyContent: "space-between",
    },
  }),
);
