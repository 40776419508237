import { useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Trans } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";

import {
  AdministrativeGender,
  CreateCaregiverAccess,
  CreateCaregiverAccessVariables,
  StateCode,
} from "/apollo/schema/types";
import childAdded from "/asset/image/child-added.png";
import { Button, InlineMessage, Text } from "/component/base";
import { AlertCircle } from "/component/base/Icon";
import CaregiverEndModal from "/component/modal/CaregiverEndModal";
import CustomLoader from "/component/partial/CustomLoader";
import { useToastContext } from "/component/provider/ToastProvider";
import { CaregiverFormType } from "/constant/caregiver.constant";
import { useAmwell, useContactInfo, useTranslation } from "/hook";
import { layout } from "/styles";
import { formatText } from "/util";
import { maxMinorAgeTest } from "/util/date";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  logEvent,
} from "/util/firebase.util";

import CaregiveChildForm from "../../CaregiverChildForm";
import { CaregiverFormProps } from "../CaregiverForms.types";
import CreateCaregiverAccessGql from "../createCaregiverAccess";
import { Anchor, FormContentContainer, HeaderContainer } from "./AddChild.styles";
import { AddChildFormProps } from "./AddChild.types";

const CHILD_INITIAL_STATE = {
  firstName: "",
  lastName: "",
  dob: "",
  phoneNumber: "",
  legalSex: "",
  line1: null,
  line2: null,
  city: null,
  state: null,
  postalCode: null,
  useDifferentAddress: false,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AddChildren = ({ handleSubmit, setMetadata }: CaregiverFormProps<any>) => {
  const { t } = useTranslation("form-AddChild");

  const childSchema = Yup.object().shape({
    firstName: Yup.string().required(t("error.required")),
    lastName: Yup.string().required(t("error.required")),
    useDifferentAddress: Yup.boolean().required(),
    dob: Yup.string()
      .matches(/^(0[1-9]|1[012])[/](0[1-9]|[12][0-9]|3[01])[/](19|20)\d\d$/, {
        message: t("error.dobInvalid"),
      })
      .test("maxAgeTest", t("error.maxAge"), (value) => {
        return maxMinorAgeTest(value);
      })
      .required(t("error.required")),
    phoneNumber: Yup.string()
      .test("phoneNumberTest", t("error.phoneInvalid"), (value) => {
        return !!value?.replace(/[()\-\s]/g, "").match(/^(\+1)?[0-9]{10}$/);
      })
      .required(t("error.required")),
    legalSex: Yup.string().required(t("error.required")),
    line1: Yup.string()
      .nullable()
      .when("useDifferentAddress", {
        is: true,
        then: Yup.string()
          .required(t("error.required"))
          .matches(/^((?![*&%$#@?]).)*$/, t("error.invalidChar")),
      }),
    line2: Yup.string()
      .nullable()
      .when("useDifferentAddress", {
        is: true,
        then: Yup.string()
          .nullable()
          .matches(/^((?![*&%$#@?]).)*$/, t("error.invalidChar")),
      }),
    city: Yup.string()
      .nullable()
      .when("useDifferentAddress", {
        is: true,
        then: Yup.string()
          .required(t("error.required"))
          .matches(/^((?![*&%$#@?]).)*$/, t("error.invalidChar")),
      }),
    state: Yup.string()
      .nullable()
      .when("useDifferentAddress", {
        is: true,
        then: Yup.string().required(t("error.required")),
      }),
    postalCode: Yup.string()
      .nullable()
      .when("useDifferentAddress", {
        is: true,
        then: Yup.string()
          .required(t("error.required"))
          .matches(/^\d{5}(-\d{4})?$/, t("error.postalCode.format")),
      }),
  });

  const caregiverFormSchema = Yup.object().shape({
    child: childSchema,
  });

  const { showToast } = useToastContext();
  const { contactByName } = useContactInfo();
  const callCenterPhone = contactByName("summitCallCenter")?.phoneNumber;

  const [childrenSuccess, setChildrenSuccess] = useState<string>("");
  const [childrenFailed, setChildrenFailed] = useState<string>("");
  const { enrollDependent, sdk } = useAmwell();
  const [showEndModal, setShowEndModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [createCaregiverAccessMutation] = useMutation<
    CreateCaregiverAccess,
    CreateCaregiverAccessVariables
  >(CreateCaregiverAccessGql);

  const logButtonClickEvent = (buttonName: string) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.CAREGIVER,
      source: AnalyticsSource.CAREGIVER_ACCESS_CHILD_FORM,
      button_name: buttonName,
    } as ButtonClickParams);
  };

  const onSubmit = async (_values: AddChildFormProps) => {
    logButtonClickEvent("Continue");
    try {
      if (!sdk || !sdk?.initialized) {
        throw Error("Amwell SDK is not defined");
      }

      let telecom: {
        phone?: string | null;
        email?: string | null;
      } | null = null;
      let accessId: string | null | undefined = "";

      setIsSubmitting(true);
      let success: string[] = [];
      let failed: string[] = [];
      const child = {
        ..._values.child,
        firstName: _values.child.firstName.trim(),
        lastName: _values.child.lastName.trim(),
      };

      const registrationRequest = sdk.consumerService.newDependentRegistration();
      registrationRequest.sourceId = uuidv4();
      registrationRequest.firstName = child.firstName || "";
      registrationRequest.lastName = child.lastName || "";
      registrationRequest.dob = new Date(child.dob);
      registrationRequest.gender =
        child.legalSex === AdministrativeGender.MALE
          ? "M"
          : child.legalSex === AdministrativeGender.FEMALE
          ? "F"
          : "U";
      registrationRequest.performDependentCheck = false;

      const childEnrolled = await enrollDependent(registrationRequest);

      if (!childEnrolled) {
        failed = [...failed, `${child.firstName} ${child.lastName}`];
      }
      if (failed.length === 0) {
        const response = await createCaregiverAccessMutation({
          variables: {
            data: {
              email: undefined,
              addChildren: true,
              children: [
                {
                  ...child,
                  phoneNumber: child.phoneNumber,
                  sourceId: childEnrolled?.sourceId || "",
                  state: child.state as StateCode,
                  legalSex: child.legalSex as AdministrativeGender,
                },
              ],
            },
          },
        });

        if (response?.data?.createCaregiverAccess?.errors?.length || response?.errors?.length) {
          failed = [...failed, `${child.firstName} ${child.lastName}`];
        } else {
          if (response.data?.createCaregiverAccess?.telecom)
            telecom = response.data.createCaregiverAccess.telecom;
          accessId = response.data?.createCaregiverAccess.accessId;

          const childSuccess = response?.data?.createCaregiverAccess.results?.success?.[0];
          const childFailed = response?.data?.createCaregiverAccess.results?.failed?.[0];

          if (childSuccess)
            success = [...success, `${childSuccess.firstName} ${childSuccess.lastName}`];

          if (childFailed) failed = [...failed, `${childFailed.firstName} ${childFailed.lastName}`];
        }
      }
      const successString =
        success.length <= 1
          ? success?.[0]
          : `${success.slice(0, -1).join(",")} and ${success.slice(-1)}`;
      const failedString =
        failed.length <= 1
          ? failed?.[0]
          : `${failed.slice(0, -1).join(",")} and ${failed.slice(-1)}`;
      setChildrenSuccess(successString);
      setChildrenFailed(failedString);

      if (success.length > 0) {
        if (telecom)
          setMetadata({
            verificationTelecom: {
              accessId: accessId as string,
              name: successString,
              phone: telecom.phone || undefined,
              email: telecom.email || undefined,
            },
          });
        else
          setMetadata({
            verificationTelecom: { name: successString },
          });
        handleSubmit(CaregiverFormType.ChildVerification);
      } else setShowEndModal(true);
    } catch (e: any) {
      showToast({
        title: t("error.inviteTitle"),
        icon: "alert",
        ttl: 5000,
        message: t("error.inviteMessage"),
      });
    }
    setIsSubmitting(false);
  };

  if (isSubmitting) {
    return <CustomLoader title={t("loader.title")} subtitle={t("loader.subTitle")} />;
  }

  return (
    <>
      <Formik
        validateOnMount
        enableReinitialize
        initialValues={{
          child: { ...CHILD_INITIAL_STATE },
          address: {
            line1: null,
            line2: null,
            city: null,
            state: null,
            postalCode: null,
          },
        }}
        validationSchema={caregiverFormSchema}
        onSubmit={onSubmit}
      >
        {() => (
          <Form css={{ width: "100%" }}>
            <FormContentContainer>
              <HeaderContainer>
                <Text variant="title1">{t("title")}</Text>
                <InlineMessage
                  icon={<AlertCircle />}
                  variant="important"
                  css={layout.margin("gutter", "skip", "condensed")}
                >
                  <Text variant="body2">{t("alertInfo")}</Text>
                </InlineMessage>
              </HeaderContainer>
              <CaregiveChildForm />
            </FormContentContainer>
            <br />
            <Button
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              fullWidth="percent"
              variant="primary"
              type="submit"
            >
              {t("continue")}
            </Button>
          </Form>
        )}
      </Formik>
      <CaregiverEndModal
        title={childrenSuccess ? t("completeModal.title") : t("completeModal.failedTitle")}
        subtitle={
          <div css={[layout.flexVertical, layout.spacedChildrenVertical("condensed")]}>
            {!!childrenSuccess && (
              <div>
                <Trans
                  components={{ bold: <Text color="textSecondary" variant="body2Bold" /> }}
                  i18nKey="completeModal.subtitle"
                  t={t}
                  values={{ success: childrenSuccess }}
                />
              </div>
            )}
            {!!childrenFailed && (
              <div>
                <Trans
                  components={{
                    bold: <Text color="textSecondary" variant="body2Bold" />,
                    anchor: <Anchor href={`tel:${callCenterPhone}`} />,
                  }}
                  values={{
                    phoneNumber: formatText.parsePhone(callCenterPhone),
                    failed: childrenFailed,
                  }}
                  i18nKey="completeModal.failed"
                  t={t}
                />
              </div>
            )}
          </div>
        }
        isOpen={showEndModal}
        imageSource={childAdded}
        close={() => {
          setShowEndModal(false);
          handleSubmit();
        }}
        caregiverType={"child"}
      />
    </>
  );
};

export default AddChildren;
