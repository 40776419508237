import styled from "styled-components";

import { Text } from "/component/base";
import { layout } from "/styles";
import theme from "/theme/default";

export const Header = styled(Text).attrs({ variant: "body1Bold" })(({ theme }) => ({
  color: theme.colors.brandNavy,
  padding: theme.spacing.base,
}));

export const SubTitle = styled(Text).attrs({ variant: "body2" })(({ theme }) => ({
  color: theme.colors.textSecondary,
  paddingTop: theme.spacing.standard,
}));

export const inputText = {
  width: 46,
  height: 42,
  margin: theme.spacing.condensed,
  borderRadius: 8,
  borderWidth: 1,
  backgroundColor: theme.colors.brandPrimaryLight,
  borderColor: theme.colors.objectSubtle,
  fontSize: theme.fontSizes.title2,
  textAlign: "center",
  color: theme.colors.black,
};

export const Description = styled(Text).attrs({ variant: "body1" })(({ theme }) => ({
  color: theme.colors.textPrimary,
  paddingTop: theme.spacing.gutter + 4,
  paddingBottom: theme.spacing.standard,
}));

export const focusText = {
  borderRadius: 8,
  borderWidth: 2,
  borderColor: theme.colors.brandPrimary,
  backgroundColor: theme.colors.brandPrimaryLight,
};

export const errorText = {
  borderRadius: 8,
  borderWidth: 2,
  borderColor: theme.colors.statusNegative,
  backgroundColor: theme.colors.brandRedLight,
};

export const Container = styled.div(layout.flexVerticalAlignStart);

export const ButtonGroup = styled.div(
  layout.flexVerticalAlignStart,
  layout.spacedChildrenVertical(),
);
