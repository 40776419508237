import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { Button } from "/component/base";
import MainContent from "/component/layout/MainContent";
import routes from "/constant/url.constant";
import { layout } from "/styles";

import { Subtitle } from "./CaregiverDeprecated.styles";
import useCaregiverPortal from "./useCaregiverPortal";

const CaregiverDeprecated = () => {
  const { t } = useTranslation("screen-CaregiverAccessDeprecated");
  const { data } = useCaregiverPortal();
  const history = useHistory();
  const openAthenaPortal = async () => {
    const url = data?.caregiverPortal ?? "https://2983-1.portal.athenahealth.com/";

    window.open(url, "_blank");
  };

  const onPressFaq = () => {
    history.push(routes.faq);
  };

  return (
    <MainContent pageTitle={t("title")} css={layout.padding("none")}>
      <Helmet>
        <title>{t("title")}</title>
      </Helmet>
      <div css={layout.flexVertical}>
        <Subtitle variant="body1">{t("body")}</Subtitle>
        <div css={layout.spacedChildrenVertical("gutter")}>
          <Button variant="primary" onClick={openAthenaPortal}>
            {t("openAthena")}
          </Button>
          <div>
            <Button variant="borderBottom" onClick={onPressFaq}>
              {t("faqs")}
            </Button>
          </div>
        </div>
      </div>
    </MainContent>
  );
};
export default CaregiverDeprecated;
