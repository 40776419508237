import awsdk from "awsdk";

import { useAmwell, useAmwellQuery } from "/hook";

/**
 * This hook allows for searching of a list of medications
 *
 */

const useAmwellMedicationsSearch = (search?: string) => {
  const { consumer, sdk } = useAmwell();
  const fetch = async () =>
    sdk && consumer && search && search.length > 3
      ? await sdk.consumerService.searchMedications(consumer, search || "")
      : undefined;

  return useAmwellQuery<awsdk.AWSDKMedicationList>(fetch, [search]);
};

export default useAmwellMedicationsSearch;
