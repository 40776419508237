import React from "react";
import { AlertCircle } from "react-feather";

import InlineMessage from "/component/base/InlineMessage";
import List from "/component/base/List";
import Text from "/component/base/Text";
import FormContent from "/component/partial/FormContent";
import {
  TelehealthInsuranceProps,
  useAmwellPrevisitContext,
} from "/component/provider/AmwellPrevisitProvider";
import { PrevisitForms } from "/constant/telehealth.constant";
import { useOutages, useTranslation } from "/hook";
import { layout } from "/styles";

import { Title } from "../Telehealth.styles";
import { TelehealthFormProps } from "../Telehealth.types";

const TelehealthInsurance = ({ handleSubmit }: TelehealthFormProps<TelehealthInsuranceProps>) => {
  const { t } = useTranslation("form-telehealth-previsit");
  const { consumer, sdk } = useAmwellPrevisitContext();
  const outage = useOutages("TelehealthInsurance");

  const options = [
    {
      label: t("telehealthInsurance.haveInsurance"),
      hasInsurance: true,
    },
    {
      label: t("telehealthInsurance.noInsurance"),
      hasInsurance: false,
    },
  ];

  const handleOptionClicked = async (hasInsurance: boolean) => {
    if (consumer && sdk) {
      if (!hasInsurance) {
        await sdk.consumerService.removeInsuranceSubscription(consumer);
        handleSubmit({ hasInsurance }, PrevisitForms.VISIT_COST);
      } else {
        handleSubmit({ hasInsurance });
      }
    }
  };

  return (
    <FormContent css={layout.margin("gutter", "skip")}>
      {outage && (
        <InlineMessage
          css={layout.margin("skip", "skip", "gutter", "skip")}
          icon={<AlertCircle />}
          variant="primary"
        >
          <Text variant="body2">{outage.message}</Text>
        </InlineMessage>
      )}
      <Title>{t("telehealthInsurance.title")}</Title>
      <List
        as="Buttons"
        data={options}
        onClick={({ hasInsurance }) => handleOptionClicked(hasInsurance)}
        renderItem={(option) => (
          <Text variant="body1Bold" css={layout.marginVertical("gutter")}>
            {option.label}
          </Text>
        )}
      />
    </FormContent>
  );
};

export default TelehealthInsurance;
