/**
 * Convert a Prescription Status into a user-friendly version.
 * @param status string
 * @returns string
 */
export const toUserFriendlyStatus = (status: string | undefined | null): string => {
  switch (status) {
    case "CLOSED": {
      return "Sent to Pharmacy";
    }

    case "ADMINISTER": {
      return "Administer";
    }

    case "SUBMITTED": {
      return "Sent to Pharmacy";
    }

    default: {
      return "";
    }
  }
};
