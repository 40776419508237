import { gql, QueryResult } from "@apollo/client";

import { useB2CQuery } from "../client";
import { GET_LETTERS, GET_LETTERSVariables } from "../schema/types";

export const documentsQuery = gql`
  query GET_LETTERS($limit: Int, $offset: Int) {
    patient {
      letters(limit: $limit, offset: $offset, letterType: LETTER_PATIENTCORRESPONDENCE) {
        totalCount
        items {
          letterId
          createdDatetime
          html
          letterText
          paperformName
          provider {
            firstName
            lastName
            displayName
          }
        }
      }
    }
  }
`;

const useDocuments = ({ limit = 150, offset = 0 } = {}): QueryResult<
  GET_LETTERS,
  GET_LETTERSVariables
> =>
  useB2CQuery<GET_LETTERS, GET_LETTERSVariables>(documentsQuery, {
    variables: { limit, offset },
    notifyOnNetworkStatusChange: true,
  });

export default useDocuments;
