import styled from "styled-components";

import logoWordMark from "/asset/image/logo-wordmark-horizontal.svg";
import FormContentBase from "/component/partial/FormContent";
import { layout } from "/styles";

export const Header = styled.header(() => ({
  display: "block",
  position: "relative" as const,
}));

export const StepOneContent = styled.div(layout.spacedChildrenVertical(), ({ theme }) => ({
  paddingTop: theme.spacing.expanded,
}));

export const LogoWordmark = styled.img.attrs(() => ({ src: logoWordMark }))(({ theme }) => ({
  display: "none",
  [theme.media.tablet]: {
    margin: "auto",
    display: "block",
  },
}));

export const FormContent = styled(FormContentBase)(({ theme }) => ({
  marginTop: theme.spacing.base * 5,
  [theme.media.tablet]: {
    marginTop: theme.spacing.standard,
  },
}));
