import React from "react";

import Text from "/component/base/Text";
import Tooltip, { SetPopperRef } from "/component/base/Tooltip";

import { Base, ChevronRight, IconWrapper, Link, TextWrapper, Title } from "./DashboardCard.styles";
import { Props } from "./DashboardCard.types";

const DashboardCard = ({
  onClick,
  icon,
  isMobile,
  disabled,
  title,
  description,
  tooltipText,
}: Props) => {
  // Render the actual DashboardCard. This will be rendered inside a Tooltip (as the anchor) if
  // the link is disabled
  const cardContent = (
    tooltipAnchor?: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>,
  ) => (
    <>
      <IconWrapper ref={tooltipAnchor}>{icon}</IconWrapper>
      <TextWrapper>
        <Title variant="title3">{title}</Title>
        <Text variant="body2" color="textSecondary">
          {description}
        </Text>
      </TextWrapper>
      <ChevronRight size={16} color={disabled ? "objectPrimary" : "brandSecondary"} />
    </>
  );

  // The link is not disabled, so render the button directly
  if (!disabled) {
    return (
      <Base>
        <Link onClick={onClick} isDisabled={false}>
          {cardContent()}
        </Link>
      </Base>
    );
  }

  // If the link is disabled we will render the card as the anchor of a tooltip
  const anchor = (setRef: SetPopperRef) => (
    <Tooltip.Anchor forwardedAs={Link} isDisabled>
      {cardContent(setRef)}
    </Tooltip.Anchor>
  );

  return (
    <Tooltip forwardedAs={Base} location="top-start" anchor={anchor} offset={isMobile ? 0 : 8}>
      {tooltipText}
    </Tooltip>
  );
};

export default DashboardCard;
