// Modules
import React from "react";

import { ArrowLeft, Cross } from "/component/base/Icon";

import { ButtonStyled } from "./BackButton.styles";
// Types
import { Props } from "./BackButton.types";

const BackButton = ({ icon = "back", variant = "primary", ...buttonProps }: Props) => {
  const Icon = icon === "back" ? ArrowLeft : Cross;

  return (
    <ButtonStyled
      $variant={variant}
      tabIndex={0}
      aria-label="Go Back"
      {...buttonProps}
      variant={"blank"}
    >
      <Icon color={variant === "primary" ? "brandSecondary" : "backgroundPrimary"} />
    </ButtonStyled>
  );
};

export default BackButton;
