import { ApolloError, ApolloQueryResult, gql, useQuery } from "@apollo/client";

import { PatientExistsQuery } from "/apollo/schema/types";

export const patientExistsQuery = gql`
  query PatientExistsQuery {
    viewer {
      user {
        patient {
          id
          homeAddress {
            line1
            city
            state
            postalCode
          }
        }
      }
    }
  }
`;

const usePatientExistsQuery = (): {
  loadPatient: (
    variables?: Partial<Record<string, unknown>> | undefined,
  ) => Promise<ApolloQueryResult<PatientExistsQuery>>;
  patientError: ApolloError | undefined;
  patientLoading: boolean;
} => {
  const { error, loading, refetch } = useQuery<PatientExistsQuery>(patientExistsQuery, {
    skip: true,
    fetchPolicy: "network-only",
  });

  return {
    loadPatient: refetch,
    patientError: error,
    patientLoading: loading,
  };
};

export default usePatientExistsQuery;
