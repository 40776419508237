import React from "react";

import Alert from "/component/base/Alert";
import Button from "/component/base/Button";
import { useTranslation } from "/hook";

import { Props } from "./TelehealthErrorModal.types";

const TelehealthErrorModal = ({
  variant,
  isOpen,
  primaryButtonCallback,
  secondaryButtonCallback,
}: Props) => {
  const { t } = useTranslation("modal-telehealthError");

  let title = "";
  let subtitle = "";
  let key = "";
  let primaryButtonText = "";
  let secondaryButtonText = "";

  switch (variant) {
    case "providerUnavailable":
      key = "telehealthProviderUnavailableModal";
      title = t("providerUnavailable.title");
      subtitle = t("providerUnavailable.subtitle");
      primaryButtonText = t("buttonOk");
      break;
    case "eligibilityFailed":
      key = "telehealthEligibilityFailedModal";
      title = t("eligibilityFailed.title");
      subtitle = t("eligibilityFailed.subtitle");
      primaryButtonText = t("eligibilityFailed.buttonEditInsurance");
      secondaryButtonText = t("eligibilityFailed.buttonContinue");
      break;
    default:
      break;
  }

  return (
    <Alert
      title={title}
      key={key}
      close={() => undefined}
      isOpen={isOpen}
      footer={
        <>
          <Button onClick={primaryButtonCallback} variant="primary">
            {primaryButtonText}
          </Button>
          {!!secondaryButtonText && (
            <Button onClick={secondaryButtonCallback} variant="secondary">
              {secondaryButtonText}
            </Button>
          )}
        </>
      }
    >
      {subtitle}
    </Alert>
  );
};

export default TelehealthErrorModal;
