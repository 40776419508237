// Modules
import { transparentize } from "polished";
import styled from "styled-components";

import { layout } from "/styles";

const SIZE = 26;

export const Label = styled.label(() => ({
  display: "flex",
  alignItems: "center",
}));

export const LabelWrapper = styled.div(({ theme }) => ({
  flex: "1 1 auto",
  "* + &": {
    marginLeft: theme.spacing.base * 3,
  },
}));

export const InputWrapper = styled.div({
  position: "relative",
  width: SIZE,
  height: SIZE,
  flex: `0 0 ${SIZE}px`,
});

const DOT_SIZE = 16;

export const FilledRadioDot = styled.div(({ theme }) => ({
  pointerEvents: "none",
  width: DOT_SIZE,
  height: DOT_SIZE,
  borderRadius: "50%",
  backgroundColor: theme.colors.brandActionBlue,
  opacity: 0,
}));

export const RadioWrapper = styled.div(layout.absoluteFill, layout.flexCenter, ({ theme }) => ({
  pointerEvents: "none",
  border: `solid 2px ${theme.colors.brandActionBlueLight}`,
  borderRadius: "50%",
  backgroundColor: theme.colors.backgroundPrimary,

  // Change styles depending on the state of the sibling input[type=radio]
  "input:disabled + &": {
    borderColor: transparentize(0.7, theme.colors.objectSecondary),
  },

  "input:checked + &": {
    [FilledRadioDot]: {
      opacity: 1,
    },
    border: `solid 2px ${theme.colors.brandPrimary}`,
  },

  "input:disabled:checked + &": {
    borderColor: transparentize(0.7, theme.colors.objectSecondary),
    [FilledRadioDot]: {
      opacity: 1,
      backgroundColor: transparentize(0.7, theme.colors.objectSecondary),
    },
  },

  "input:active + &": {
    [FilledRadioDot]: {
      opacity: 0.3,
    },
  },
}));

export const Input = styled.input({
  width: "100%",
  height: "100%",
  appearance: "unset",
  border: 0,
  // Making interactive elements fully transparent can prevent them from being
  // used on some platforms. This prevents any missed default styles from being
  // visible without risking breaking pointer interactivity.
  opacity: 0.01,
});
